export const getArrayOfTImeWithInterval = (interval = 15) => {
  const times = []; // time array
  let tt = 0; // start time

  // loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60);
    const mm = tt % 60;
    times[i] = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
    tt = tt + interval;
  }
  return times;
};
