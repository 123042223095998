import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Selecbox from '../components/Selectbox';
import { createWaitStep, updateWaitStep } from '../actions/sequence';
import PropTypes from 'prop-types';

const useStyles = makeStyles(({ custom }) => ({
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',
    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
}));

const CreateWaitTime = (props) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    interval: 1,
    interval_unit: 'day',
    selected: { value: 1, label: 'day (s)' },
  });
  const [eventId, setEventId] = useState(false);

  useEffect(() => {
    if (props.stepcontent.id) {
      setEventId(props.stepcontent.id);
      setFormData({
        interval: props.stepcontent.trigger_interval,
        interval_unit: props.stepcontent.trigger_interval_unit,
        selected: {
          value: props.stepcontent.trigger_interval_unit,
          label: `${props.stepcontent.trigger_interval_unit} (s)`,
        },
      });
    }
  }, [props.stepcontent.id]);

  // const [toggleOpen, setToggleOpen] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(false);

  const { interval, selected } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onChangeUnit = (e) =>
    setFormData({ ...formData, interval_unit: e.value });

  const options = [
    { value: 'minute', label: 'minute(s)' },
    { value: 'hour', label: 'hour(s)' },
    { value: 'day', label: 'day(s)' },
    { value: 'week', label: 'week(s)' },
    { value: 'month', label: 'month(s)' },
  ];

  const addWaitTime = () => {
    if (eventId) {
      props.updateWaitStep(formData, props.sid, eventId, props.closeModal);
    } else {
      props.createWaitStep(
        formData,
        props.sid,
        props.closeModal,
        props.eventorder,
      );
    }
    //props.closeModal();
  };

  return (
    <div className="createEmailHeadCls">
      <div className="emailContent">
        <Row>
          <Col sm={12} md={6} lg={6}>
            <label>
              <strong>Wait for</strong>
            </label>
            <input
              type="text"
              placeholder="Days.."
              onChange={onChange}
              required
              name="interval"
              alt="Days"
              value={interval}
              style={{
                width: '100%',
                border: '1px solid rgb(219, 230, 241)',
                height: '32px',
                marginTop: '12px',
              }}
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <label>
              <strong> </strong>
            </label>
            <Selecbox
              setValue={onChangeUnit}
              options={options}
              name="interval_unit"
              selected={selected}
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button
            className={
              toggleBtn
                ? 'pl-4 pr-4 mt-5 unSelectedBtn'
                : `pl-4 pr-4 mt-5 ${classes.nextButton}`
            }
            onClick={() => addWaitTime()}
            style={{ textTransform: 'initial' }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

CreateWaitTime.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  template: state.template,
});
const mapDispatchToProps = {
  createWaitStep,
  updateWaitStep,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateWaitTime);
