import api from "../../../utils/api";

export const getSequenceSteps = (seqId, seqName, id, type, uuid) => {
  // return api.get(
  //   `/sequence/get-sequence-steps?sequenceId=${seqId}&sequenceName=${seqName}&contactId=${id}&audience_type=${type}`
  // );
  return api.get(
    `/sequence/template-against-step?sequenceId=${seqId}&sequenceName=${seqName}&contactId=${id}&audience_type=${type}&uuid=${uuid}`
  );
};

export const getJobSpecTemplates = () => {
  return api.get(`/sequence/get-jobspec-templates`);
};

export const getCvSpecTemplates = () => {
  return api.get(`/sequence/get-cvspec-templates`);
};

export const submitEnrollSequence = (data) => {
  return api.post(`/sequence/enroll-against-sequence`, data);
};

export const updateEnrollSequence = (data) => {
  return api.post(`/sequence/enroll-update`, data);
};

export const getPersonalizedTemplate = (payload) => {
  const {
    personalizedTempId,
    step_id,
    template_id,
    sequence_id,
    contact_type,
    contact_id,
    uuid,
    sequence_contact_id = 0,
  } = payload;
  return api.get(
    `sequence/personalize-template/${personalizedTempId}?step_id=${step_id}
    &template_id=${template_id}
    &sequence_id=${sequence_id}
    &contact_type=${contact_type}
    &contact_id=${contact_id}
    &uuid=${uuid}
    &sequence_contact_id=${sequence_contact_id}`
  );
};
