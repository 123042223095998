import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import SelectDropDown from '../components/SelectDropDown';
import CreateTemplate from '../facebooktemplates/CreateTemplate';

import {
  getAdsAccount,
  getCustomAudiences,
  createAddFacebookStep,
  updateFacebookStep,
} from '../actions/facebook';

import PropTypes from 'prop-types';

import TextEditor from '../utils/Editor';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  //category_id: yup.number().required('Template type is a required field'),
  //content: yup.string().required('Content is a required field'),
});

const AddFacebook = (props) => {
  const dispatch = useDispatch();

  const [toggleBtn, setToggleBtn] = useState(false);
  const [eventId, setEventId] = useState(false);
  const [customAudienceId, setCustomAudienceId] = useState(false);
  const [toggleOpen, setToggleOpen] = useState(false);
  const facebook = useSelector((state) => state.facebook);

  const { handleSubmit, setValue } = useForm({ resolver: yupResolver(schema) });

  /**
   * Call API to get ads account id
   */
  useEffect(() => {
    dispatch(getAdsAccount());
  }, []);

  /**
   * Call API to get all the audiences against the ads account
   */
  useEffect(() => {
    if (facebook.adsAccounts) {
      dispatch(getCustomAudiences(facebook.adsAccounts));
      //console.log(facebook);
    }
  }, [facebook.adsAccounts]);

  // on edit the step
  useEffect(() => {
    if (props.stepcontent.id) {
      setCustomAudienceId({
        value: props.stepcontent.audience_id,
        label: props.stepcontent.name,
      });
      setEventId(props.stepcontent.id);
      setValue('content', props.stepcontent.description);
    }
  }, [props.stepcontent.id]);

  const toggleBtnAction = (value) => {
    // console.log(value);
    setToggleBtn(!toggleBtn);
    setCustomAudienceId(value);
  };

  const toggleAudienceModal = () => {
    setToggleOpen(!toggleOpen);
  };

  const audiences = facebook.audiences ? facebook.audiences : null;
  // console.log(audiences);
  var audiencesOptions = audiences.map(function (aud) {
    return { value: aud.id, label: aud.name };
  });

  // Called on add and Update
  const addFacebook = async (value) => {
    let event = {};
    if (props.provider == 'add') {
      event = {
        type: 8,
        name: 'AddFacebook',
        descripton: 'Add to audience',
      };
    } else if (props.provider == 'remove') {
      event = {
        type: 9,
        name: 'RemoveFacebook',
        descripton: 'Remove from audience',
      };
    }

    if (customAudienceId) {
      if (eventId) {
        dispatch(
          updateFacebookStep(
            props.sid,
            eventId,
            props.closeModal,
            customAudienceId,
            event,
          ),
        );
      } else {
        dispatch(
          createAddFacebookStep(
            props.sid,
            props.closeModal,
            props.eventorder,
            customAudienceId,
            event,
          ),
        );
      }
    }
    //props.closeModal();
  };

  return (
    <div className="createEmailHeadCls">
      <div className="createEmailOptionCls">
        <p onClick={() => toggleAudienceModal()}>
          <i className="fa fa-plus"></i> Create New Custom Audience
        </p>
      </div>
      <Form onSubmit={handleSubmit(addFacebook)}>
        <div className="emailContent">
          <h3>Select the custom Audience</h3>
          <SelectDropDown
            label="Select an audience"
            setValue={toggleBtnAction}
            templateOptions={audiencesOptions}
            selected={customAudienceId}
          />
        </div>
        <div className="emailContent">
          <Button
            className={
              toggleBtn ? 'pl-4 pr-4 unSelectedBtn' : 'pl-4 pr-4 selectedBtn'
            }
            type="submit"
          >
            {eventId ? `Update` : `Add`}
          </Button>
        </div>
      </Form>
      <CreateTemplate
        modalState={toggleOpen}
        toSelect={toggleBtnAction}
        onHide={() => toggleAudienceModal()}
        adsAccounts={facebook.adsAccounts}
        page={'step'}
      />
    </div>
  );
};

export default AddFacebook;
