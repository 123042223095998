import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActivityReportTabs from './ActivityReportTabs';

const ActivityReports = (props) => {
  return <ActivityReportTabs {...props} />;
};

ActivityReports.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ActivityReports);
