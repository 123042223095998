import React, { useState, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchUsersTeams } from '../actions/users';
import { createTab } from '../actions/tabs';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAutocomplete from '../controlled-component/MuiAutocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: '24px 24px 36px 24px',
    fontFamily: 'Roboto',
  },
  header: {
    flex: '0 0 auto',
    marginBottom: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    /* backgroundColor: '##366fb7', */
    backgroundColor: theme.palette.primary,
  },
  title: {
    display: 'inline-block',
    marginLeft: '7px',
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
  },
  noMargin: {
    marginLeft: '0px',
  },
  gridListTile: {
    marginTop: '16px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const NewViewsForm = (props) => {
  const { object, handleClose, handleSave } = props;
  const classes = useStyles();
  const [teamToggle, setTeamToggle] = useState(false);
  const [userToggle, setUserToggle] = useState(false);
  const [userSelected, setUserSelected] = useState([]);
  const [teamSelected, setTeamSelected] = useState([]);
  const schema = yup.object().shape({
    name: yup.string().required('View name is a required field'),
    access_type: yup
      .number()
      .nullable()
      .required('View permission is a required field'),
    shared_with_users: userToggle
      ? yup.array().required('Users are a required field')
      : '',
    shared_with_teams: teamToggle
      ? yup.array().required('Teams are a required field')
      : '',
  });

  useEffect(() => {
    props.fetchUsersTeams();
  }, [props.fetchUsersTeams]);

  //get const from useFrom hooks
  const {
    register,
    handleSubmit,
    control,
    errors,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm({ mode: 'onSubmit', resolver: yupResolver(schema) });

  /**
   * Handle on change event of access type permission field
   * @param {*} event
   */
  const handleAccessType = (event) => {
    let selected = event.target.value;
    if (selected == 1) {
      setUserToggle(true);
      setTeamToggle(false);
    } else if (selected == 2) {
      setUserToggle(false);
      setTeamToggle(true);
    } else {
      setUserToggle(false);
      setTeamToggle(false);
    }
  };

  /**
   * Handle function on save button
   * @param {*} values
   */
  const onSubmit = (values) => {
    //props.createTab(values, filters, girdParams, history);
    handleSave(values, handleClose);
    if (typeof props.handleNewClose === 'function') {
      props.handleNewClose();
    }
  };

  //users options
  const usersData = props.users.users ? props.users.users : null;
  var usersOptions = usersData.map(function (user) {
    return { value: user.company_user_id, label: user.name };
  });

  //teams options
  const teamsData = props.users.teams ? props.users.teams : null;
  var teamsOptions = teamsData.map(function (team) {
    return { value: team.id, label: team.name };
  });

  //Subscribe user id and team id if not blank
  useEffect(() => {
    if (props.users.user_id) {
      setUserSelected([props.users.user_id]);
    }
    if (props.users.team_id) {
      setTeamSelected([props.users.team_id]);
    }
  }, [props.users.user_id, props.users.team_id]);

  return (
    <>
      {/* content here */}
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.reset}>
            <Typography variant="h6" className={classes.title}>
              {`Create a new saved view`}
            </Typography>
          </div>
          <div className={classes.filtersSelected} />
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label className="floating-label">Name</Form.Label>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field, onChange }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    onChange={onChange}
                  />
                )}
              />
              {/* <TextField
                id="name"
                name="name"
                variant="outlined"
                ref={register}
                control={control}
              /> */}
              <label className="error">{errors.name?.message}</label>
            </Form.Group>
          </Form.Row>

          <Form.Control
            type="hidden"
            name="object"
            id="object"
            value={object}
            ref={register}
          />

          <Form.Row>
            <Form.Group as={Col} controlId="access_type">
              <Form.Label>View Permission</Form.Label>
              <Form.Control
                as="select"
                name="access_type"
                custom="true"
                ref={register}
                onChange={handleAccessType}
              >
                <option value="">Select</option>
                <option value="1">Private</option>
                <option value="2">Team</option>
                <option value="3">Organisation</option>
              </Form.Control>
              <label className="error">{errors.access_type?.message}</label>
            </Form.Group>
          </Form.Row>
          {userToggle ? (
            <Form.Row>
              <Form.Group as={Col} controlId="shared_with_users">
                <Form.Label>Select users</Form.Label>
                <MuiAutocomplete
                  control={control}
                  name="shared_with_users"
                  options={usersOptions}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  getOptionLabel={(option) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      margin="normal"
                      placeholder="Select users"
                    />
                  )}
                  defaultValue={userSelected}
                  multiple={true}
                />
                <label className="error">
                  {errors.shared_with_users?.message}
                </label>
              </Form.Group>
            </Form.Row>
          ) : null}
          {teamToggle ? (
            <Form.Row>
              <Form.Group as={Col} controlId="shared_with_teams">
                <Form.Label>Select teams</Form.Label>
                <MuiAutocomplete
                  control={control}
                  name="shared_with_teams"
                  options={teamsOptions}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  getOptionLabel={(option) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      margin="normal"
                      placeholder="Select teams"
                    />
                  )}
                  defaultValue={teamSelected}
                  multiple={true}
                />

                <label className="error">
                  {errors.shared_with_teams?.message}
                </label>
              </Form.Group>
            </Form.Row>
          ) : null}
          <Form.Row>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Form.Row>
        </Form>
      </div>
    </>
  );
};

NewViewsForm.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
});
const mapDispatchToProps = {
  fetchUsersTeams,
  createTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewViewsForm);
