import { makeStyles } from '@material-ui/core/styles';

// common styling for sidebar
const useStyles = makeStyles(({ custom, transitions, ...theme }) => ({
  sliderDrawer: {
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  leftBoxIcon: {
    height: 30,
  },
  headerLogo: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
    minHeight: 55,
    height: 55,
    borderBottom: `1px solid ${custom.colorCode.blueLightShade9}`,
    '& img': {
      margin: '0 5px',
    },
  },
  root: {
    cursor: 'unset',
    overflow: 'auto',
    overflowX: 'hidden',
    'scrollbar-width': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiListItem-button:hover': {
      fontWeight: '700',
      color: custom.colorCode.white,
      '& div': {
        color: custom.colorCode.white,
      },
      '& a': {
        color: custom.colorCode.white,
      },
    },
    '& .active': {
      color: custom.colorCode.white,
      backgroundColor: custom.colorCode.blueDarkShade3,
      fontWeight: '700',
      '& div': {
        color: custom.colorCode.white,
      },
      '& a': {
        color: custom.colorCode.white,
      },
      '& svg': {
        color: custom.colorCode.white,
      },
    },
  },
  sideBarHeading: {
    display: 'flex',
    padding: '5px 30px 0 30px',
    alignItems: 'center',
    '& h2': {
      fontSize: 11,
      color: custom.colorCode.lightRedColor,
      fontWeight: 400,
      margin: 0,
      textTransform: 'none',
    },
  },
  nested: {
    padding: `6px 0 6px 30px`,
    cursor: 'pointer',
    '& .nav-link': {
      padding: 0,
    },
  },
  navListTitle: {
    fontSize: 16,
    fontWeight: '400',
    color: custom.colorCode.blueDarkShade1,
    paddingLeft: 16,
    '&:hover': {

    },
    '& .career_portal': {
      color: '#A5A6AD',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& a': {
      color: custom.colorCode.blueDarkShade1,
      padding: 0,
    },
  },
  navListChildItem: {
    color: '#9D9FA6',
    fontSize: 16,
    fontWeight: '400',
    paddingLeft: 16,
    '& .career_portal': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& a': {
      color: custom.colorCode.blueDarkShade1,
    },
    '& a.active': {
      color: custom.colorCode.blueLightShade1,
      backgroundColor: custom.colorCode.blueDarkShade3,
    },
    '&:hover': {
      color: custom.colorCode.blueLightShade1,
    },
  },
  collapseWrapper: {
    '& .MuiListItem-button': {
      paddingLeft: 32,
    },
  },
  collapseIcon: {
    height: 20,
    fill: custom.colorCode.lightGreyColorShade8,
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: transitions?.create('transform', {
      duration: transitions.duration.shortest,
    }),
  },
  collapseIconExpand: {
    transform: 'rotate(0deg)',
  },
  goBackWrapper: {
    margin: '10px 10px 10px 0',
    border: '1px solid #8ba4be',
    fontSize: 11,
    padding: '5px',
    width: '130px',
    cursor: 'pointer',
    borderLeft: 'none',
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    color: custom.colorCode.blueDarkShade2,
    '& span': {
      marginRight: 10,
      marginLeft: 5,
      '& i': {
        color: custom.colorCode.blueLightShade2,
        fill: custom.colorCode.darkBrownShade1,
      },
    },
  },
  fontRedColor: {
    color: custom.colorCode.lightRedColor,
  },
  sliderPaper: {
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    whiteSpace: 'nowrap',
    width: 230,
    overflow: 'hidden',
    backgroundColor: custom.colorCode.blueLightShade7,
    zIndex: 1000,
  },
  drawerPaperClose: {
    overflow: 'hidden',
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    width: 55,
    whiteSpace: 'nowrap',
    backgroundColor: custom.colorCode.blueLightShade7,
  },
  listItemIcon: {
    minWidth: 24,
    marginRight: 8,
    '& svg': {
      color: '#9D9FA6',
    },
  },
}));

export default useStyles;
