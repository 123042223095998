import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ProfileIntegrationList from '../profileIntegrationList'

const ProfileIntegrations = () => {
  return (
    <ProfileIntegrationList />
  )
};

ProfileIntegrations.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProfileIntegrations);

