import api from '../../utils/api';

import {
  LOAD_SKILLS,
  SKILLS_ERROR,
  CHANGE_SKILL_STATUS,
  REMOVE_SKILL,
} from '../types';

// Get skills list and set in reducer
export const fetchSkills = (params = {}) => async (dispatch) => {
  try {
    const res = await api.get('/automation/skills', { params });

    dispatch({
      type: LOAD_SKILLS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SKILLS_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Delete skill
export const deleteSkill = (skillId, showSnacker, params) => async (
  dispatch,
) => {
  try {
    await api.delete(`/automation/skills/${skillId}`).then((res) => {
      dispatch({
        type: REMOVE_SKILL,
        payload: res.data,
      });
      //onHide('addSequence');
      dispatch(fetchSkills(params));
      showSnacker('Skill removed!');
    });
  } catch (err) {
    dispatch({
      type: SKILLS_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Delete entity
export const updateStatus = (skillId, status, showSnacker, params) => async (
  dispatch,
) => {
  try {
    const values = { status: status };
    await api
      .put(`/automation/skills/update-status/${skillId}`, { values })
      .then((res) => {
        dispatch({
          type: CHANGE_SKILL_STATUS,
          payload: res.data,
        });
        //onHide('addSequence');
        dispatch(fetchSkills(params));
        showSnacker('Skill status updated!');
      });
  } catch (err) {
    dispatch({
      type: SKILLS_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
