import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiPopover from '@material-ui/core/Popover';
import MuiDrawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Drawers = ({
  className,
  trigger,
  refExit,
  hide,
  content,
  ...providedProps
}) => {
  const [isOpen, open] = useState(false);
  const anchorEl = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const shouldHide = typeof hide === 'boolean' ? hide : false;
      if (shouldHide) {
        open(false);
      }
    }
  }, [hide, isOpen, open]);

  const handleClick = (event) => {
    anchorEl.current = event.currentTarget;
    open(true);
  };

  const handleRequestClose = () => {
    open(false);
  };

  const closeIconClass = providedProps.classes.closeIcon;
  delete providedProps.classes.closeIcon; // remove non-standard class from being passed to the popover component

  const transformOriginSpecs = {
    vertical: 'top',
    horizontal: 'right',
    top: 0,
    position: 'fixed',
    right: 0,
  };

  const anchorOriginSpecs = {
    vertical: 'bottom',
    horizontal: 'right',
    top: 0,
    position: 'fixed',
    right: 0,
  };

  const handleOnExit = () => {
    if (refExit) {
      refExit();
    }
  };

  const triggerProps = {
    key: 'content',
    onClick: (event) => {
      if (trigger.props.onClick) trigger.props.onClick();
      handleClick(event);
    },
  };

  return (
    <>
      <span {...triggerProps}>{trigger}</span>
      <MuiDrawer
        elevation={2}
        open={isOpen}
        onClose={handleRequestClose}
        /* onExited={handleOnExit} */
        anchor={'right'}
        variant={'temporary'}
        /* anchorOrigin={anchorOriginSpecs}
        transformOrigin={transformOriginSpecs} */
        /* style={{ position: 'fixed', right: '0', top: '0 !important', zIndex: '1000', height:'100%' }} */
        {...providedProps}
      >
        <IconButton
          aria-label="Close"
          onClick={handleRequestClose}
          className={closeIconClass}
          style={{
            position: 'absolute',
            right: '4px',
            top: '4px',
            zIndex: '1000',
          }}
        >
          <CloseIcon />
        </IconButton>
        {content}
      </MuiDrawer>
    </>
  );
};

Drawers.propTypes = {
  refExit: PropTypes.func,
  trigger: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  hide: PropTypes.bool,
};

export default Drawers;
