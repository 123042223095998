
import React from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '../MuiAutocomplete';
import {useForm, Controller} from 'react-hook-form';


const FilterAutocomplete = ({index, field, label, optionsData, selected, handleSelection, ...providedProps}) => {
    const {control} = useForm();
    let filtersOptions = [];
    if(optionsData.length > 0){
        for(let i=0; i < optionsData.length; i+=2){
            let opt = optionsData[i].split('_');
            let optionCount = optionsData[i+1];
            let filterText = (opt.length > 1) ? opt[1] : opt[0];
            //+`(${optionCount})`
            filtersOptions.push({value:optionsData[i], label:filterText});
        }
    }
    const handleChange = (field,value) => {
        //console.log(field, value);
        handleSelection(value, index, field);
    }
    return (
      <>
        <MuiAutocomplete
            control={control}
            name={field}
            options={filtersOptions}
            getOptionSelected={(option, value) => option.value == value.value}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" margin="normal" placeholder={label}  />}
            defaultValue={selected}
            multiple={true}
            handleChange={handleChange}
        />

    </>
  );
}

export default FilterAutocomplete;
