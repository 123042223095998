import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Popover } from '@material-ui/core';
import { DateRangePicker as MUIDateRangePicker } from 'materialui-daterange-picker';

/**
 * Default Range data
 */
export const defaultDefinedRanges = [
  {
    id: 50,
    label: 'All',
    startDate: moment().add(1, 'days').toDate(),
    endDate:moment().add(1, 'days').toDate(),
  },
  {
    id: 1,
    label: 'Today',
    startDate: moment().toDate(),
    endDate: moment().toDate(),
  },
  {
    id:2,
    label: 'Yesterday',
    startDate: moment().subtract(1, 'days').toDate(),
    endDate: moment().subtract(1, 'days').toDate(),
  },
  {
    id:3,
    label: 'Last Week',
    startDate: moment().subtract(1, 'weeks').startOf('week').toDate(),
    endDate: moment().subtract(1, 'weeks').endOf('week').toDate(),
  },
  {
    id:4,
    label: 'Next Week',
    startDate: moment().add(1, 'weeks').startOf('week').toDate(),
    endDate: moment().add(1, 'weeks').endOf('week').toDate(),
  },
  {
    id: 5,
    label: 'Last 7 days',
    startDate: moment().subtract(7, 'd').toDate(),
    endDate: moment().toDate(),
  },
  {
    id: 6,
    label: 'Last 30 days',
    startDate: moment().subtract(30, 'd').toDate(),
    endDate: moment().toDate(),
  },
  {
    id: 7,
    label: 'Last 90 days',
    startDate: moment().subtract(90, 'd').toDate(),
    endDate: moment().toDate(),
  },
  {
    id: 8,
    label: 'Last 6 months',
    startDate: moment().subtract(6, 'M').toDate(),
    endDate: moment().toDate(),
  },
  {
    id: 9,
    label: 'Last 1 year',
    startDate: moment().subtract(1, 'Y').toDate(),
    endDate: moment().toDate(),
  },
];

/**
 * CustomDateRangePicker component
 */
const CustomDateRangePicker = ({
  onClose,
  open,
  anchorEl,
  onDatePickerChange,
  dateRange,
  datePickerDataOptions,
  ...otherProps
}) => {

  const [options, setOptions] = useState(null);
  const [initialRange, setInitialRange] = useState({});

  useEffect(() => {
    if (!options) {
      // filter backend default filters option
      let filterOptions = defaultDefinedRanges.filter((i) => {
        if(i.label == 'All') return true;
        return datePickerDataOptions.findIndex(item => item.value.toLowerCase() == i.label.toLowerCase()) > -1;
      });
      setOptions(filterOptions);
    }
  },[datePickerDataOptions]);

  useEffect(() => {
    // set default value or selected value
    if (dateRange.date_range !== '') {
      if (dateRange.date_range == 10) {
        setInitialRange({ startDate: dateRange?.startDate, endDate: dateRange?.endDate})
      } else {
        let selectedValue = defaultDefinedRanges.find((i) => i.id == dateRange.date_range);
        setInitialRange(selectedValue);
      }
    } else {
      setInitialRange(defaultDefinedRanges[0]);
    }
  }, [dateRange])

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
    >
      <MUIDateRangePicker
        open
        toggle={onClose}
        initialDateRange={initialRange}
        definedRanges={options}
        onChange={(range) => onDatePickerChange(range)}
        {...otherProps}
      />
    </Popover>
  );
};

export default CustomDateRangePicker;
