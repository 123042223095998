import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import CustomDrawer from '../../../components/CustomDrawer';
import DrawerFormHeader from '../../../components/DrawerFormHeader';
import CustomSnackBar from '../../../components/CustomSnackBar';
import LushaIntegrationForm from './LushaIntegrationForm';

import { english } from '../../../utils/language';

const CommonIntegrationDrawer = (props) => {
  const { openDrawer, onClose, integrationData } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);

  /**
   * Handle function to close the drawer popup
   * @param {*} data
   */
  const handleClose = (data) => {
    if (data && data?.data === 'integration') {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: `${integrationData.plugin_name} integrated successfully`,
        status: 'success',
      });
    }
    onClose();
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  const getIntegrationForm = () => {
    let formComponent = <></>;
    switch ((integrationData?.plugin_name || '').toLocaleLowerCase()) {
      case 'lusha': {
        // show Lush slider
        formComponent = (
          <Box>
            <LushaIntegrationForm closeDrawer={handleClose} />
          </Box>
        );
        break;
      }
      default: {
        break;
      }
    }

    return formComponent;
  };

  return (
    <Box>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={`${integrationData.plugin_name} Setting`}
        >
          {getIntegrationForm()}
        </DrawerFormHeader>
      </CustomDrawer>

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        handleSnackbarClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </Box>
  );
};

export default CommonIntegrationDrawer;
