import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SkillsGrid from './SkillsGrid';

// const Skills = (props) => {
//   return (
//     <div className="">
//       <div className="flex-row">
//         <aside className="sideBarMargin">
//           <SidebarNav skill="active" />
//         </aside>
//
//         <div className="flex-col content">
//           <SkillsGrid {...props} />
//         </div>
//       </div>
//     </div>
//   );
// };

const Skills = (props) => {
  return <SkillsGrid {...props} />;
};

Skills.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Skills);
