import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';

import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import Dialog from '../../../components/Dialog';
import Button from '../../../components/Button';
import CustomSnackBar from '../../../components/CustomSnackBar';

import api from '../../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  selectBoxStyle: {
    '& legend': {
      overflow: 'hidden',
    },
  },
  buttonWrapper: {
    padding: '12px 0 0 0',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const SequenceListingDialog = ({
  selectedSequenceAction,
  reasonDropdown,
  setSelectedSequenceAction,
  getSequenceList,
}) => {
  const classes = useStyles();

  const [selectedReason, setSelectedReason] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);

  useEffect(() => {
    if (selectedSequenceAction && !selectedSequenceAction.name) {
      setSelectedReason({});
      setDisableButton(false);
      // setOpenSnackBar(false);
      // setOpenSnackBarMessage(null);
    }
  }, [selectedSequenceAction]);

  /**
   * function to handle submit data to hit api
   */
  const handleSubmit = () => {
    setDisableButton(true);
    const payload = {
      sequence_contact_id: selectedSequenceAction.seqData.id,
      sequence_id: selectedSequenceAction.seqData.sequenceId,
      sequence_name: selectedSequenceAction.seqData.sequenceName,
      contact_id: selectedSequenceAction.seqData.contact_id,
      audience_type: selectedSequenceAction.audience_type,
      action_type:
        selectedSequenceAction.name === 'stop'
          ? 'complete'
          : selectedSequenceAction.name,
      status_reason: selectedReason.reason || null,
    };

    api
      .post(`/sequence/update-sequence`, payload)
      .then((resp) => {
        setOpenSnackBar(true);
        if (resp) {
          getSequenceList();
          setOpenSnackBarMessage({
            message: 'Sequence status is updated',
            status: 'success',
          });
        } else {
          setOpenSnackBarMessage({
            message: 'Something went wrong',
            status: 'error',
          });
        }
        setDisableButton(false);
        setSelectedSequenceAction(null);
      })
      .catch((error) => {
        setDisableButton(false);
        setSelectedSequenceAction(null);
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          message: error?.response?.data?.message || 'Something went wrong',
          status: 'error',
        });
      });
  };

  // this will close dialog and reset data
  const handleDialogClose = () => {
    setSelectedSequenceAction(null);
  };

  // this will close the snackbar
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    setSelectedReason({
      [name]: tag[key],
    });
  };

  // show content based on the condition and start will not have form
  const setContent = () => {
    let content = <></>;

    if (selectedSequenceAction?.name === 'start') {
      content = (
        <Box pb={1.5} pl={1.5} pr={1.5}>
          <Box mb={2}>
            <Typography variant="body1">
              Are you sure you want to {selectedSequenceAction?.name} the
              sequence?
            </Typography>
          </Box>
          <Box className={classes.buttonWrapper}>
            <Button
              variant="contained"
              disabled={disableButton}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      );
      return content;
    }

    content = (
      <ValidatorForm onSubmit={handleSubmit} style={{ padding: '0 12px 12px 12px' }}>
        <Box mb={2}>
          <Typography variant="body1">
            Are you sure you want to {selectedSequenceAction?.name} the
            sequence?
          </Typography>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormAutoCompleteSelect
                onChange={handleSelectChange('reason', 'key')}
                name="reason"
                id="reason"
                key={'reason'}
                disableClearable
                options={reasonDropdown}
                getOptionLabel={(option) => option.value || ''}
                getOptionSelected={(option, value) => option.key === value}
                value={
                  reasonDropdown
                    ? reasonDropdown?.find(
                        (c) => c.key === selectedReason?.reason,
                      ) || ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    variant="outlined"
                    label={`Choose reason`}
                    className={classes.selectBoxStyle}
                    value={selectedReason?.reason || ''}
                    validators={['required']}
                    errorMessages={['Please select reason']}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.buttonWrapper}>
          <Button variant="contained" type="submit" disabled={disableButton}>
            Confirm
          </Button>
        </Box>
      </ValidatorForm>
    );
    return content;
  };

  return (
    <>
      <Dialog
        objectId={selectedSequenceAction?.name}
        open={Boolean(selectedSequenceAction?.name)}
        draggable={false}
        title="Change Sequence"
        showAction={false}
        htmlContent={<Box>{setContent()}</Box>}
        onClose={handleDialogClose}
        showCloseIcon
      />
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        handleSnackbarClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </>
  );
};

export default SequenceListingDialog;
