import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EmailDetailPage from './DetailPageWrapper';

/* const EmailDetails = (props) => {
  return (
    <div className="">
      <div className="flex-row">
        <aside className="sideBarMargin">
          <SidebarNav template="active" />
        </aside>

        <div className="flex-col content">
          <EmailDetailPage admin {...props} />
        </div>
      </div>
    </div>
  );
}; */

const EmailDetails = (props) => {
  return <EmailDetailPage admin {...props} />;
};

EmailDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(EmailDetails);
