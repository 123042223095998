import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Spinner from '../layout/Spinner';
import AuthLayout from './AuthLayout';
import PrimaryHeader from '../includes/header';

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user = {} },
  accessRole,
  showSidebar = true,
  ...rest
}) => {
  // show spinner until user api is not completed
  if (loading) {
    return <Spinner />;
  }
  // check user is authorized user
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  // check user role to access particular route or redirect it to dashboard
  const hasRequiredRole = (user?.roles || []).some(
    (r) => accessRole.indexOf(r.role_id) >= 0,
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        hasRequiredRole ? (
          showSidebar ? (
            <Fragment>
              <PrimaryHeader />
              <AuthLayout>
                <Component {...props} />
              </AuthLayout>
            </Fragment>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/sequence" />
          // <Redirect to="/dashboard" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
