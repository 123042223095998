import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  InputBase,
  Popper,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DateFnsUtils from '@date-io/date-fns';

import Card from '../../../components/Card';
import CustomDataGrid from '../../../controlled-component/customGridComponent';
import LoadingTableBody from '../../../components/LoadingTableBody';
import DateRangePicker from '../../../components/DateTimePickers/DateRangePicker';
import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import { ActiveIcon, InActiveIcon } from '../../../components/GridIcons';

import {
  getTemplatePerformance,
  getTemplateRecipients,
} from '../../../actions/template';
import { dateFormat } from '../../../utils/applicationConstant';

const useStyles = makeStyles(({ custom }) => ({
  mainPageWrapper: {},
  selectContainerWrapper: {
    display: 'flex',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },
  buttonSelectContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
    '& .formControl-label': {
      fontSize: 14,
      color: custom.colorCode.blackShade1,
      marginRight: 20,
      marginBottom: 3,
    },
  },
  selectEmpty: {},
  selectEmptyRoot: {
    color: custom.colorCode.fontBlack,
    fontWeight: 700,
    fontSize: 14,
  },
  selectEmptyIcon: {
    color: custom.colorCode.fontBlack,
  },
  cardHeader: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',

    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title': {
      width: '70%',
      color: custom.colorCode.fontBlack,
    },
    '& .value': {
      width: '30%',
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 18,
      color: custom.colorCode.fontBlack,
    },
  },
  summaryDatePicker: {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: 120,
    '& .MuiIconButton-root': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  gridContent: {},
  gridContentData: {
    borderBottom: '1px solid #f3f4f7',
  },
  mainGrid: {
    marginTop: 27,
  },
  cardContentSummary: {
    paddingBottom: '16px !important',
  },
  mainCardSummary: {
    boxShadow: '#F5F7F9 0px 0px 0px 2px',
    height: '100%',
  },
  gridContentRowData: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0',
    borderBottom: '1px solid #f3f4f7',

    '&:last-child': {
      borderBottom: 'none',
      padding: '5px 0 0 0',
    },

    '& .title': {
      color: custom.colorCode.blackShade1,
      fontWeight: 500,
    },
  },
  tdContentEllipsis: {
    width: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 3,
  },
  enrolledByAutoComplete: {
    '& .MuiSvgIcon-root': {
      color: '#33475b',
    },
    '& .MuiAutocomplete-input': {
      minWidth: 70,
      maxWidth: 'auto',
      fontWeight: 700,
    },
  },
  actionButton: {
    height: 30,
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    '& span': {
      fontSize: 15,
    },
    '&:focus': {
      backgroundColor: custom.colorCode.blueLightShade11,
    },
    '&:hover': {
      backgroundColor: custom.colorCode.blueLightShade11,
    },
  },
  popoverStyle: {
    left: '-50px !important',
  },
  selectDatePicketText: {
    display: 'flex',
    marginBottom: 5,
    cursor: 'pointer',
  },
}));

// Status filter options
const statusOptions = [
  {
    title: 'Sent',
    value: 'sent',
    selected: true,
  },
  {
    title: 'Opened',
    value: 'opened',
    selected: false,
  },
  {
    title: 'Clicked',
    value: 'clicked',
    selected: false,
  },
  {
    title: 'Delivered',
    value: 'delivered',
    selected: false,
  },
  {
    title: 'Bounced',
    value: 'bounces',
    selected: false,
  },
];

const DetailPage = (props) => {
  const { templateData, templateId, users, dateFilterOptions } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [showDatePopOver, setShowDatePopOver] = useState(null);
  const [datePickerLabel, setDatePickerLabel] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    enrolledBy: 'all',
    enrolledDate: '',
    status: 'sent',
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    if (event.target.value != 10) {
      handleFilterChange({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  // handle custom date change
  const handleDateChange = (name) => (date) => {
    setFormData({
      ...formData,
      [name]: date,
    });
  };

  /**
   * Handle function for filter change
   * It will call the api to change email and recipient stats
   */
  const handleFilterChange = (params) => {
    //console.log('called:filter');
    dispatch(getTemplatePerformance(templateId, params));
    getRecipientsData(templateId, null, params);
  };

  useEffect(() => {
    dispatch(getTemplatePerformance(templateId));
    getRecipientsData(templateId);
    // dispatch(getTemplateRecipients(templateId, 'sent', setIsLoading));
  }, []);

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'recipient',
        label: 'Recipient Name',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = templateData.recipients[dataIndex];
            return (
              <Tooltip title={rData.recipient || ''} arrow>
                <div className={classes.tdContentEllipsis}>
                  <Link
                    to={`#`}
                    className="text-truncate td-anchor-content-ellipsis"
                  >
                    {rData.recipient}
                  </Link>
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Sent By',
        name: 'sent_by',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRender: (value) => {
            return (
              <div className={classes.tdContentEllipsis}>{value || ''}</div>
            );
          },
        },
      },
      {
        label: 'Date Sent',
        name: 'Sent_date',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({ style: { minWidth: 100 } }),
          setCellProps: () => ({ style: { minWidth: 100, maxWidth: 100 } }),
          customBodyRender: (value) => {
            return (
              <div>
                <Moment format="Do MMM YYYY">{value}</Moment>
              </div>
            );
          },
        },
      },
      {
        label: 'Total Opens',
        name: 'totalOpened',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({ style: { minWidth: 80 } }),
          setCellProps: () => ({ style: { minWidth: 80, maxWidth: 80 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Failed ',
        name: 'Event_failed',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({ style: { minWidth: 80 } }),
          setCellProps: () => ({ style: { minWidth: 80, maxWidth: 80 } }),
          customBodyRender: (value) => {
            return (
              <div>
                {value ? (
                  <ActiveIcon fontSize="small" />
                ) : (
                  <InActiveIcon fontSize="small" />
                )}
              </div>
            );
          },
        },
      },
      {
        label: 'Opened',
        name: 'Event_opened',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({ style: { minWidth: 80 } }),
          setCellProps: () => ({ style: { minWidth: 80, maxWidth: 80 } }),
          customBodyRender: (value) => {
            return (
              <div>
                {value ? (
                  <ActiveIcon fontSize="small" />
                ) : (
                  <InActiveIcon fontSize="small" />
                )}
              </div>
            );
          },
        },
      },
      {
        label: 'Clicked',
        name: 'Event_clicked',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({ style: { minWidth: 80 } }),
          setCellProps: () => ({ style: { minWidth: 80, maxWidth: 80 } }),
          customBodyRender: (value) => {
            return (
              <div>
                {value ? (
                  <ActiveIcon fontSize="small" />
                ) : (
                  <InActiveIcon fontSize="small" />
                )}
              </div>
            );
          },
        },
      },
      {
        label: 'Bounces',
        name: 'Event_bounces',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({ style: { minWidth: 80 } }),
          setCellProps: () => ({ style: { minWidth: 80, maxWidth: 80 } }),
          customBodyRender: (value) => {
            return (
              <div>
                {value ? (
                  <ActiveIcon fontSize="small" />
                ) : (
                  <InActiveIcon fontSize="small" />
                )}
              </div>
            );
          },
        },
      },
    ],
    [templateData.recipients],
  );

  const getRecipientsData = (template_id, eventFilter = null, params = {}) => {
    setIsLoading(true);
    let status = params.status || 'sent';
    dispatch(
      getTemplateRecipients(
        template_id,
        status || eventFilter,
        setIsLoading,
        params,
      ),
    );
  };

  /**
   * Handle function of filter change
   * @param {*} eventFilter
   */
  const handleStatusFilterChange = (eventFilter) => {
    setFormData({
      ...formData,
      [eventFilter.target.name]: eventFilter.target.value,
    });
    //getRecipientsData(templateId, eventFilter.target.value);
    handleFilterChange({
      ...formData,
      [eventFilter.target.name]: eventFilter.target.value,
    });
  };

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => (
      <LoadingTableBody
        loading={templateData.loading || isLoading}
        {...props}
      />
    ),
    [templateData.loading, isLoading],
  );

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name) => (event, tag) => {
    setFormData({
      ...formData,
      [name]: tag.id,
    });
    handleFilterChange({
      ...formData,
      [name]: tag.id === 'all' ? '' : tag.id,
    });
  };

  // this open range picker with pop over element
  const openDatePicker = (event) => {
    setShowDatePopOver(event.currentTarget);
  };

  // this will close range picker
  const handleDatePickerClose = () => {
    setShowDatePopOver(null);
  };

  const handleDatePickerChange = (dateData) => {
    setFormData({
      ...formData,
      enrolledDate: 10,
      startDate: dateData.startDate,
      endDate: dateData.endDate,
    });
    setDatePickerLabel(dateData?.label || '');
    handleFilterChange({
      ...formData,
      enrolledDate: 10,
      startDate: dateData.startDate,
      endDate: dateData.endDate,
    });
  };

  const emailTemplateCardDetail = [
    {
      name: 'totalSent',
      mainTitle: 'Total Sent',
      value: templateData.performance?.total_sent || 0,
      data: [],
    },
    {
      name: 'openRate',
      mainTitle: 'Open Rate',
      value: `${templateData.performance?.opened_percent || 0}`,
      data: [
        {
          name: 'Unique Opens',
          value: templateData.performance?.total_opened || 0,
        },
        {
          name: 'Total Opens',
          value: templateData.performance?.all_opened || 0,
        },
      ],
    },
    {
      name: 'clickRate',
      mainTitle: 'Click Rate',
      value: `${templateData.performance?.clicked_percent || 0}`,
      data: [
        {
          name: 'Unique Clicks',
          value: templateData.performance?.total_clicked || 0,
        },
        {
          name: 'Total Clicks',
          value: templateData.performance?.all_clicked || 0,
        },
      ],
    },
    {
      name: 'exitAnalysis',
      mainTitle: 'Failure Rate',
      value: `${templateData.performance?.bounces_percent || 0}`,
      data: [
        {
          name: 'Bounce',
          value: templateData.performance?.total_bounces || 0,
        },
        {
          name: 'Unsubscribe',
          value: templateData.performance?.total_unsubscribed || 0,
        },
      ],
    },
  ];

  const PopperMy = function (props) {
    return (
      <Popper
        {...props}
        style={{ width: 'fit-content' }}
        className={`${props.className} ${classes.popoverStyle}`}
        placement="bottom-start"
      />
    );
  };

  return (
    <div className={classes.mainPageWrapper}>
      <div className={classes.selectContainerWrapper}>
        {/*Enrolled By*/}
        <div className={classes.selectContainer}>
          <span className="formControl-label"> Enrolled By: </span>
          <FormControl className={classes.formControl}>
            <FormAutoCompleteSelect
              className={classes.enrolledByAutoComplete}
              onChange={handleSelectChange('enrolledBy')}
              name="enrolledBy"
              id="enrolledBy"
              PopperComponent={PopperMy}
              value={
                formData.enrolledBy
                  ? templateData.performance?.userList?.find(
                      (c) => c.id === formData?.enrolledBy,
                    ) || ''
                  : ''
              }
              disableClearable
              options={templateData?.performance?.userList || []}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <InputBase
                    {...params.InputProps}
                    {...rest}
                    style={{ width: 'fit-content' }}
                  />
                );
              }}
            />
          </FormControl>
        </div>

        {/*Status*/}
        <div className={classes.selectContainer}>
          <span className="formControl-label"> Status: </span>
          <FormControl className={classes.formControl}>
            <Select
              name={'status'}
              value={formData.status}
              onChange={handleStatusFilterChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              classes={{
                root: classes.selectEmptyRoot,
                select: classes.selectEmptyIcon,
                icon: classes.selectEmptyIcon,
              }}
              disableUnderline
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {statusOptions.map((option) => (
                <MenuItem key={`key-${option.value}`} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/*Enrolled Date*/}
        <div className={classes.selectContainer}>
          <span className="formControl-label"> Enrolled Date: </span>
          <Typography
            onClick={openDatePicker}
            variant="body2"
            component={'div'}
            className={classes.selectDatePicketText}
          >
            {formData?.startDate && formData?.endDate
              ? datePickerLabel
                ? datePickerLabel
                : `${moment(formData?.startDate).format(
                    dateFormat,
                  )} to ${moment(formData?.endDate).format(dateFormat)}`
              : 'Select Date'}
            <ArrowDropDownIcon fontSize="small" />
          </Typography>
          <DateRangePicker
            onClose={handleDatePickerClose}
            open={Boolean(showDatePopOver)}
            anchorEl={showDatePopOver}
            dateRange={{
              startDate: formData?.startDate,
              endDate: formData?.endDate,
            }}
            onDatePickerChange={handleDatePickerChange}
          />
        </div>
      </div>

      <Grid container spacing={2} direction="row">
        {emailTemplateCardDetail.map((seqCardData, index) => (
          <Grid item xs={6} sm={3} key={seqCardData.name + index}>
            <Card
              className={classes.mainCardSummary}
              cardContent={{
                className: classes.cardContentSummary,
                children: (
                  <>
                    <div className={classes.cardHeader}>
                      <div className="title">{seqCardData.mainTitle}</div>
                      <div className="value">{seqCardData.value}</div>
                    </div>

                    <div className={classes.gridContent}>
                      {seqCardData.data.map((cardData, sIndex) => (
                        <div
                          className={classes.gridContentRowData}
                          key={cardData.name + index + sIndex}
                        >
                          <div className={'title'}>{cardData.name}</div>
                          <div className={'value'}>{cardData.value}</div>
                        </div>
                      ))}
                    </div>
                  </>
                ),
              }}
            />
          </Grid>
        ))}
      </Grid>

      <CustomDataGrid
        columns={columns}
        data={templateData?.recipients}
        paperWidth={'287px'}
        className={classes.mainGrid}
        hideToolBar
        options={{
          pagination: Boolean(templateData?.recipients?.length),
          selectableRows: 'none',
          filterType: 'dropdown',
          responsive: 'simple',
          searchPlaceholder: 'Search',
          searchOpen: true,
          search: true,
        }}
        components={{ TableBody: BodyComponent }}
      />
    </div>
  );
};

export default DetailPage;
