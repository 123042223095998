import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CallSharpIcon from '@material-ui/icons/CallSharp';

import Button from '../../components/Button';
import Spinner from '../../layout/Spinner';
import CollapseCard from '../../components/CollapseCard';
import { dateFormat } from '../../utils/applicationConstant';
import { convertHtmlToString } from '../../utils/convertHtmlToString';

import api from '../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
  },
  upcomingTaskWrapper: {},
  upcomingTitle: {
    marginBottom: '0.7em',
  },
  fontRed: {},
  collapseCardWrapper: {
    '& .collapseCard': {
      border: 'none',
      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
  },
  filterWrapper: {
    paddingBottom: 8,
  },
  collapseEditText: {
    color: custom.colorCode.lightLightGreenColor3,
    fontSize: '14px',
    textDecoration: 'underline',
    cursor: 'pointer',
    '& svg': {
      marginLeft: 4,
    },
  },
  completedText: {
    fontSize: '14px',
    color: custom.colorCode.lightLightGreenColor3,
    cursor: 'pointer',
    '& svg': {
      fill: custom.colorCode.lightLightGreenColor3,
      marginRight: 4,
    },
  },
  cardTextContentWrapper: {
    background: custom.colorCode.white,
    // border: `1px solid ${custom.colorCode.lightGreyColorProfileShade22}`,
  },
}));

const TaskActivityCollapseItem = ({
  task,
  profileType,
  showToast,
  reFetchData,
}) => {
  const classes = useStyles();

  const [disabledMarkAsComplete, setDisabledMarkAsComplete] = useState(false);

  // mark the task as complete
  const handleMarkComplete = () => {
    if (task.status === 1 && !disabledMarkAsComplete) {
      setDisabledMarkAsComplete(true);
      api
        .put(`/automation/change-task-status/${task.taskId}`)
        .then(async () => {
          await reFetchData();
          showToast(true, {
            message: 'Task marked as completed',
            status: 'success',
          });
          setDisabledMarkAsComplete(false);
        })
        .catch((error) => {
          setDisabledMarkAsComplete(false);
          showToast(true, {
            message: error?.response?.data?.message || 'Something went wrong',
            status: 'error',
          });
        });
    }
  };

  const handleChange = (event) => {
    console.log('----handleChange----', event.target);
  };

  return (
    <CollapseCard
      showIcon
      startIcon={
        task?.taskTypeName.toLocaleLowerCase() === 'callback' ? (
          <CallSharpIcon fontSize="small" style={{ fill: '#1861fd' }} />
        ) : (
          <LinkedInIcon fontSize="small" style={{ fill: '#1861fd' }} />
        )
      }
      heading1={
        task?.taskTypeName.toLocaleLowerCase() === 'callback'
          ? 'Callback Task'
          : 'Task LinkedIn Message'
      }
      subHeading={task?.common_name}
      rightHeading={`Due ${moment(task?.due_date).format(dateFormat)} at ${
        task.due_time
      }`}
      className="collapseCard"
    >
      <Box>
        <Grid container spacing={2}>
          {profileType === 'candidate' && (
            <>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2" className={classes.typographyText}>
                  Due Date
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8}>
                <Typography variant="body2">
                  {moment(task?.due_date).format(dateFormat)}
                </Typography>
              </Grid>
            </>
          )}

          {profileType === 'contact' && (
            <Grid item xs={12} sm={12}>
              <TextField
                label=""
                onChange={handleChange}
                name="message"
                defaultValue={convertHtmlToString(task?.free_text_notes || '')}
                // value={''}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                maxrows={6}
                inputProps={{ shrink: false }}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <Box
        mt={2}
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        pb={1.2}
      >
        <Box>
          {(task?.taskTypeName || '').toLocaleLowerCase() === 'linkedin' &&
            task.status === 1 && (
              <Button
                dataTarget={`openLinkedin`}
                // onClick={linkedinClick}
                // target="_blank"
                // href={task.linkedin_profile}
              >
                Copy & Open LinkedIn
              </Button>
            )}
        </Box>

        {task.status === 1 ? (
          <Box
            // variant="span"
            className={classes.collapseEditText}
            onClick={handleMarkComplete}
          >
            {disabledMarkAsComplete ? (
              <Spinner
                style={{
                  width: 20,
                  height: 20,
                  position: 'unset',
                }}
              />
            ) : (
              <>
                Mark as complete
                <ArrowForwardIcon fontSize="small" />
              </>
            )}
          </Box>
        ) : (
          task.status === 2 && (
            <Box
              // variant="body2"
              className={classes.completedText}
              onClick={handleMarkComplete}
            >
              <CheckIcon fontSize="small" />
              COMPLETED
            </Box>
          )
        )}
      </Box>
    </CollapseCard>
  );
};

export default TaskActivityCollapseItem;
