import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormGroup, Typography, Grid, Switch } from '@material-ui/core';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.custom.colorCode.blackShade1,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.custom.colorCode.blueLightShade8,
        borderColor: theme.custom.colorCode.blueLightShade8,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.custom.colorCode.blackShade1}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles(() => ({
  mainFormGroup: {},
}));

const SimpleSwitch = (props) => {
  const {
    checked = false,
    handleChange = (event) => {},
    className,
    leftLabelText = '',
    rightLabelText = '',
    name = 'ant-switch',
    data = {},
  } = props;

  const classes = useStyles();

  const handleCheckChange = (event) => {
    handleChange(event, data);
  };

  return (
    <FormGroup className={`${classes.mainFormGroup} ${className}`}>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>{leftLabelText}</Grid>
          <Grid item>
            <AntSwitch
              checked={checked}
              onChange={handleCheckChange}
              name={name}
            />
          </Grid>
          <Grid item>{rightLabelText}</Grid>
        </Grid>
      </Typography>
    </FormGroup>
  );
};

export default SimpleSwitch;
