import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Collapse from '../../components/Collapse';

const useStyles = makeStyles(({ custom }) => ({
  collapseCardWrapper: {
    border: `1px solid ${custom.colorCode.lightGreyColorProfileShade22}`,
    backgroundColor: custom.colorCode.white,
    marginBottom: 8,
    borderRadius: 2,
    padding: 8,
    '& .collapseCardContent': {
      paddingBottom: 0,
      alignItems: 'unset',
    },
  },
  collapseHeading: {
    '& span': {
      fontWeight: 700,
    },
  },
  subTitle: {
    color: custom.colorCode.lightGreyColorProfileShade23,
  },
  collapseContentWrapper: {
    paddingLeft: 26,
  },
  collapseAction: {
    color: '#979797',
  },
  collapseActionText: {
    marginLeft: 8,
    color: '#1861fd',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  cardIcon: {
    paddingRight: 8,
  },
}));

const CollapseCard = ({
  className = '',
  showIcon,
  startIcon,
  heading1,
  heading2,
  rightHeading,
  subHeading,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box className={`${className} ${classes.collapseCardWrapper}`}>
      <Collapse
        leftIcon
        className="collapseCardContent"
        contentTitle={
          <Box width={1} display="flex">
            {showIcon && <Box className={classes.cardIcon}>{startIcon}</Box>}

            <Box width={1}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={1}
              >
                <Typography variant="body1" className={classes.collapseHeading}>
                  {heading2}
                  <span style={heading2 ? { marginLeft: 8 } : {}}>{heading1}</span>
                </Typography>
                <Typography variant="body2" className={classes.subTitle}>
                  {rightHeading}
                </Typography>
              </Box>
              <Box>
                <Box display="flex" className={classes.collapseAction}>
                  <Typography variant="body1" gutterBottom>
                    {subHeading}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        }
      >
        <Box className={classes.collapseContentWrapper}>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default CollapseCard;
