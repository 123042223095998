import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ custom }) => ({
  mainFormValidator: {
    width: 550,
  },
  formWrapper: {
    padding: '15px 0',
  },
  formHeading: {
    fontSize: 15,
    fontWeight: 600,
    paddingBottom: 10,
  },
  formFooter: {
    fontWeight: 500,
    padding: '15px 0 25px 0',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'flex-end',
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
  multipleSelectHeading: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  mainSwitchWrapper: {
    justifyContent: 'space-between',
  },
  warningMessage: {
    width: '60%',
    '& svg': {
      fill: '#c1c5cd',
    },
    '& span': {
      paddingLeft: '8px',
      color: '#6a7487',
    },
  },
}));

export default styles;
