import {
  LOAD_ENTITIES,
  ENTITIES_ERROR,
  ADD_ENTITY,
  CHANGE_ENTITY_STATUS,
  LOAD_ENTITIES_DROPDOWNS,
} from '../../actions/types';

const initialState = {
  entities: [],
  entity_id: null,
  dropdownList: [],
  loading: true,
  error: {},
};

/**
 * Entity reducer function to set/update global state variable values
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ENTITIES: {
      const data = {
        ...state,
        entities: payload,
        loading: false,
      };
      return data;
    }
    case LOAD_ENTITIES_DROPDOWNS: {
      const data = {
        ...state,
        dropdownList: payload,
        loading: false,
      };
      return data;
    }

    case ENTITIES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
