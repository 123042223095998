import {
  REGISTER_SUCCESS,
  //REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_ERROR_CLEAR,
  //LOGIN_FAIL,
  LOGOUT,
  ACCOUNT_DELETED,
  REPLACEMENT_VARIABLES
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
  errorMessage: '',
  replacementVariables : [],
  emailReplacementVariables: [],
};

const setReplacementVariables = (variables) => {
  return variables.filter((item) => item.text !== 'Unsubscribe Link (unsubscribe)');
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
  case USER_LOADED:
    return {
      ...state,
      isAuthenticated: true,
      loading: false,
      user: payload,
    };
  case REGISTER_SUCCESS:
    return {
      ...state,
      ...payload,
      isAuthenticated: true,
      loading: false,
      errorMessage: '',
    };
  case LOGIN_SUCCESS:
    return {
      ...state,
      ...payload,
      isAuthenticated: true,
      loading: false,
    };
  case LOGIN_FAIL:
    return {
      ...state,
      errorMessage: payload,
    };
  case LOGIN_ERROR_CLEAR:
    return {
      ...state,
      errorMessage: '',
    };
  case ACCOUNT_DELETED:
    return {
      ...state,
      token: null,
      isAuthenticated: false,
      loading: false,
      user: null,
    };
  case AUTH_ERROR:
  case LOGOUT:
    return {
      ...state,
      token: null,
      isAuthenticated: false,
      loading: false,
      user: null,
    };
    case REPLACEMENT_VARIABLES:
    return {
      ...state,
      emailReplacementVariables: payload,
      replacementVariables: setReplacementVariables(payload),
    };
  default:
    return state;
  }
}
