import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  InputBase,
  Badge,
  ListItemText,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import PersonIcon from '@material-ui/icons/Person';
// import AppsIcon from '@mui/icons-material/Apps';
import AppsIcon from '@material-ui/icons/Apps';
import FormatIndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { logout } from '../actions/auth';
import { openCloseSideBar } from '../actions/app';

import logo from '../../../img/logo.png';

import useStyles from './header.style';
import { setTenantHash } from '../utils/setAuthToken';

/*
  main app bar for the application
 */
const PrimaryHeader = ({ auth: { isAuthenticated, user }, logout }) => {
  const classes = useStyles();
  // create the user name
  // const name = user ? `${user.fname || ''} ${user.lname || ''}` : '';
  const name = user ? `${user.fname || ''}` : '';
  //console.log(window.location.pathname);
  const [userNameMenuElement, setUserNameMenuElement] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const { sideBarIsOpen, currentPageHeader } = useSelector(
    (state) => state.app,
  );

  // check main menu active item
  const matchActive = (pathname) => location.pathname.startsWith(pathname);
  const userNameId = 'user-name-menu-id'; // user dropdown

  // open user menu option
  const handleUserNameMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setUserNameMenuElement(event.currentTarget);
  };

  // handle input value change
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  // close user menu option
  const handleMenuClose = () => {
    setUserNameMenuElement(null);
  };

  // logout user
  const handleLogout = (event) => {
    setTenantHash();
    handleMenuClose();
    logout(event);
  };

  // add active class base on link
  const setActiveClass = (url) => (matchActive(url) ? 'active' : '');

  let locations = useLocation();
  const path = locations.pathname;

  const dispatch = useDispatch();

  const toggleSidebar = () => {
    dispatch(openCloseSideBar());
  };

  const authLinks = (
    <Toolbar
      className={classes.toolbarStyle}
      style={
        sideBarIsOpen
          ? path === '/dashboard'
            ? { marginLeft: 0 }
            : {}
          : { marginLeft: 55 }
      }
    >
      <IconButton onClick={toggleSidebar} style={{ outline: 'none' }}>
        {sideBarIsOpen ? (
          <FormatIndentDecreaseIcon style={{ color: '#060f32' }} />
        ) : (
          <FormatIndentIncreaseIcon style={{ color: '#060f32' }} />
        )}
      </IconButton>

      <Box component="div" m={1} className={classes.leftBox}>
        {/*<Link to={'/'}>*/}
        {/*  <img*/}
        {/*    src={logo}*/}
        {/*    alt=""*/}
        {/*    border="0"*/}
        {/*    className={`img-fluid rounded-circle ${classes.leftBoxIcon}`}*/}
        {/*  />*/}
        {/*</Link>*/}

        <Typography
          // className={`${classes.typography} ${setActiveClass('/dashboard')}`}
          className={`${classes.typography} `}
        >
          {/*{currentPageHeader?.title}*/}
        </Typography>
        {/* <Typography
          className={`${classes.typography} ${setActiveClass('/sequence')}`}
          component={Link}
          to={'/sequence'}
        >
          Sequence
        </Typography>
        <Typography
          className={`${classes.typography} ${setActiveClass('/template')}`}
          component={Link}
          to={'/template'}
        >
          Template
        </Typography>
        <Typography
          className={`${classes.typography} ${setActiveClass('/leads')}`}
          component={Link}
          to={'/leads'}
        >
          Leads
        </Typography> */}
      </Box>

      <Box component="div" m={1} className={classes.rightBox}>
        {/* <div className={classes.searchBox}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search here…"
            value={searchValue}
            onChange={handleSearchChange}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>

        <IconButton
          aria-label="show 4 new mails"
          color="inherit"
          className={classes.rightBoxIconButton}
        >
          <AddIcon className={classes.rightBoxIcon} />
        </IconButton>
        <IconButton
          aria-label="show 4 new mails"
          color="inherit"
          className={classes.rightBoxIconButton}
        >
          <AssessmentOutlinedIcon className={classes.rightBoxIcon} />
        </IconButton>
        <IconButton
          aria-label="show 17 new notifications"
          color="inherit"
          className={classes.rightBoxIconButton}
        >
          <Badge
            badgeContent={17}
            color="secondary"
            className={classes.rightBoxIconButtonBadge}
          >
            <NotificationsIcon className={classes.rightBoxIcon} />
          </Badge>
        </IconButton> */}

        <Tooltip title={name || ''} aria-label="add">
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={userNameId}
            aria-haspopup="true"
            onClick={handleUserNameMenuOpen}
            color="inherit"
            className={`${classes.userActionButton}`}
          >
            <Typography
              className={`${classes.typography} ${classes.userName}`}
              aria-controls={userNameId}
              aria-haspopup="true"
              component="div"
            >
              {name || ''}
            </Typography>
            <i className="fa fa-caret-down" aria-hidden="true" />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={userNameMenuElement}
          id={userNameId}
          keepMounted
          open={Boolean(userNameMenuElement)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={handleMenuClose}
            className={classes.userNameMenuItem}
            component={Link}
            to={'/sequence'}
          >
            <AppsIcon
              fontSize="small"
              className={classes.userNameMenuItemIcon}
            />
            <ListItemText
              primary="App"
              className={classes.userNameMenuItemText}
            />
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            className={classes.userNameMenuItem}
            component={Link}
            target="_blank"
            rel="noopener noreferrer"
            to={'/profile'}
          >
            <PersonIcon
              fontSize="small"
              className={classes.userNameMenuItemIcon}
            />
            <ListItemText
              primary="Profile"
              className={classes.userNameMenuItemText}
            />
          </MenuItem>
          {/*Check whether the user is admin*/}
          {user?.roles?.find((r) => r.role_id === 1) && (
            <MenuItem
              onClick={handleMenuClose}
              className={classes.userNameMenuItem}
            >
              <Link
                to={'/admin/users'}
                className={classes.userNameMenuItemLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SettingsIcon
                  fontSize="small"
                  className={classes.userNameMenuItemIcon}
                />
                <ListItemText
                  primary="Administration"
                  className={classes.userNameMenuItemText}
                />
              </Link>
            </MenuItem>
          )}
          <MenuItem onClick={handleLogout} className={classes.userNameMenuItem}>
            <PowerSettingsNewIcon
              fontSize="small"
              className={classes.userNameMenuItemIcon}
            />
            <ListItemText
              primary="Logout"
              className={classes.userNameMenuItemText}
            />
          </MenuItem>
        </Menu>
      </Box>
    </Toolbar>
  );

  //console.log(locations);
  return path.includes('sequence/steps') ? null : isAuthenticated ? (
    <AppBar className={classes.root}> {authLinks} </AppBar>
  ) : null;
};

PrimaryHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(PrimaryHeader);
