import { isArray, isEmpty } from 'lodash';
import api from '../utils/api';
/* import { setAlert } from './alert';
import { Redirect } from 'react-router-dom'; */
import { toast } from 'react-toastify';

import {
  GET_CATEGORIES,
  GET_SEQUENCES,
  GET_SEQUENCE,
  CREATE_SEQUENCE,
  CLONE_SEQUENCE,
  UPDATE_SEQUENCE,
  SEQUENCE_ERROR,
  CREATE_STEPS,
  UPDATE_STEPS,
  DELETE_STEPS,
  GET_SEQUENCE_REPORTS,
  SEQUENCE_DETAIL,
  STEPS_REPORTS,
  SEQUENCE_RECIPIENTS,
  STEP_TEMPLATES,
  UPDATE_STEP_TEMPLATE,
  GET_TWILIO_NUMBERS,
  LOAD_SEQUENCE_TABS,
  SET_SEQUENCED_DEFAULT_FILTER_DATA,
  LOAD_SEQUENCE_GRID_DATA,
  SHOW_TAB_GRID_DATA_LOADER,
  RELOAD_SEQUENCE_DATA
} from '../actions/types';

// Get current users sequence
export const fetchUsersTeams = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/sequence');

    dispatch({
      type: GET_SEQUENCES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Get tab for sequence object if any
export const fetchSequenceTabs = () => async (dispatch) => {
  try {
    const res = await api.get(
      'automation/tabs/get-tabs?object=sequence'
    );

    res.data = res.data.map((e) => ({
      ...e,
      url: e.tab_type ? e.name.toLocaleLowerCase().replace(/ /g, '-') : e.id,
    }));

    dispatch({
      type: LOAD_SEQUENCE_TABS,
      payload: {
        tabs: res.data,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

/**
 * Get filter data and its lable
 */
 const getFilterLabel = (text = '', data) => {
  let newLabel = {};
  switch (text) {
    case 'assignees': {
      newLabel = {
        type: 'assignees',
        title: 'All Assignees',
        isSingle: true,
        showName: true,
      };
      break;
    }
    case 'created_by': {
      newLabel = {
        type: 'created_by',
        title: 'Created by',
      };
      break;
    }
    case 'type': {
      newLabel = {
        type: 'type',
        title: 'Type',
      };
      break;
    }

    case 'access_level': {
      newLabel = {
        type: 'access_level',
        title: 'Access Level',
      };
      break;
    }
    case 'in_progress': {
      newLabel = {
        type: 'in_progress',
        title: 'In Progress',
      };
      break;
    }
    default: {
      newLabel = {};
      break;
    }
  }

  return newLabel;
};

/**
 * Get filters for the grid
 */
export const fetchGridFilters = (tabData) => async (dispatch) => {
  try {
    const res = await api.get('/automation/sequence-default-filters');
    let filterListData = Object.keys(res.data);
    filterListData = filterListData
      .filter(
        (e) =>
          e !== 'created_date'
      )
      .map((k) => ({
        name: k,
        data: res.data[k],
        optionLabel: 'name',
        optionValue: 'id',
        ...getFilterLabel(k, res.data),
      }));

    filterListData.push({
      name: 'createDate',
      control: 'dateRangePicker',
      isSingle: true,
      title: 'Created Date',
      type: 'createDate',
      from: 'createdFrom',
      to: 'createdTo',
    });
    dispatch({
      type: SET_SEQUENCED_DEFAULT_FILTER_DATA,
      payload: {
        id: tabData.id,
        [tabData.id]: {
          myFilterData: [...filterListData],
          filterList: res.data,
        },
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

/**
 * Get Sequence grid data
 */
export const fetchSequenceGridData = (
  filterData,
  gridParams,
  tabData,
  gridColParams,
  showLoader = true,
) => async (dispatch) => {
  try {
    if (showLoader) {
      dispatch({
        type: SHOW_TAB_GRID_DATA_LOADER,
        payload: { tabData },
      });
    }

    let res;
    if (!filterData) {
      res = await api.get(`/automation/sequence-grid?tab=${tabData.id}`);
    } else {
      if (!gridColParams && isEmpty(gridColParams)) {
        res = await api.get(
          `/automation/sequence-grid?tab=${
            tabData.id
          }&filters=${JSON.stringify(filterData)}&gridParams=${JSON.stringify(
            gridParams,
          )}`,
        );
      } else {
        res = await api.get(
          `/automation/sequence-grid?tab=${
            tabData.id
          }&filters=${JSON.stringify(filterData)}&gridParams=${JSON.stringify(
            gridParams,
          )}&columns=${JSON.stringify(gridColParams)}`,
        );
      }
    }

    if (!filterData) {
      dispatch({
        type: LOAD_SEQUENCE_GRID_DATA,
        payload: {
          [tabData.id]: {
            sequenceList: res.data.sequences,
            count: res.data.counts,
            filterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            defaultFilterState: !filterData ? isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters } : {},
            gridParams: isEmpty(res.data.grid_params)
              ? gridParams
              : { ...res.data.grid_params },
            columns: isEmpty(res.data.columns)
              ? {}
              : JSON.parse(res.data.columns),
            loading: false,
          },
        },
      });
    } else {
      dispatch({
        type: LOAD_SEQUENCE_GRID_DATA,
        payload: {
          [tabData.id]: {
            sequenceList: res.data.sequences,
            count: res.data.counts,
            filterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            gridParams: isEmpty(res.data.grid_params)
              ? gridParams
              : { ...res.data.grid_params },
            columns: isEmpty(res.data.columns)
              ? {}
              : JSON.parse(res.data.columns),
            loading: false,
          },
        },
      });
    }
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get current users sequence
export const getUserSequence = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/sequence');

    dispatch({
      type: GET_SEQUENCES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

export const getTaskCategories = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/get-task-cates');
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get current users sequence
export const getSequenceData = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/automation/sequence/${id}`);
    dispatch({
      type: GET_SEQUENCE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Create sequence
export const createSequence = (
  values,
  history,
  onHide,
  handleDisableButton,
) => async (dispatch) => {
  try {
    await api
      .post('/automation/sequence', {
        values,
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: CREATE_SEQUENCE,
            payload: res.data,
          });
          onHide('addSequence');
          handleDisableButton(false);
          history.push('/admin/sequence/steps/' + res.data.id);
          toast.success('Sequence created', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
          //<Redirect to={`/sequence/steps/${res.data.id}`} />
        }
      });
    /* console.log(res);
        dispatch({
            type: CREATE_SEQUENCE,
            payload: res.data
        });

        history.push('sequence/steps/'+res.data.id); */
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//update sequence
export const updateSequence = (
  sequenceId,
  values,
  history,
  onHide,
  page,
) => async (dispatch) => {
  try {
    await api
      .put(`/automation/sequence/${sequenceId}`, {
        values,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_SEQUENCE,
            payload: res.data,
          });
          onHide();
          //history.push('/sequence/steps/'+res.data.id);
          if (page === 'grid') {
            dispatch(getUserSequence());
          } else if (page === 'detail') {
            dispatch(getSequenceDetail(sequenceId));
          } else {
            dispatch(getSequenceData(sequenceId));
          }
          toast.success('Sequence updated', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
          //<Redirect to={`/sequence/steps/${res.data.id}`} />
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Clone sequence
export const cloneSequence = (values, history, onHide) => async (dispatch) => {
  try {
    await api
      .post('/automation/sequence/clone', {
        values,
      })
      .then((res) => {
        /* if (res.status === 201) { */
        dispatch({
          type: CLONE_SEQUENCE,
          payload: res.data,
        });
        onHide();
        dispatch(getUserSequence());
        toast.success('Sequence copied', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
        /* } */
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
    toast.error('Error in clone', {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
  }
};

//Update sequence status
export const updateSequenceStatus = (
  sequenceId,
  status,
  page,
  setOpenSnackBar,
) => async (dispatch) => {
  try {
    const values = {
      is_published: status,
    };
    await api
      .put(`/automation/sequence/update-status/${sequenceId}`, {
        values,
      })
      .then((res) => {
        //if (res.status === 200) {
        setOpenSnackBar &&
          setOpenSnackBar({ isOpen: true, message: 'Sequence status changed' });
        dispatch({
          type: UPDATE_SEQUENCE,
          payload: res.data,
        });
        //onHide('addSequence');
        if (page === 'grid') {
          dispatch(getUserSequence());
        } else if (page === 'sequence_grid') {
          dispatch({
            type: RELOAD_SEQUENCE_DATA,
          });
        } else if (page === 'sequence-detail') {
          dispatch(getSequenceDetail(sequenceId));
        } else {
          dispatch(getSequenceData(sequenceId));
        }
        toast.success('Sequence status updated', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
        //}
      });
  } catch (err) {
    setOpenSnackBar &&
      setOpenSnackBar({
        isOpen: true,
        message: err.response.statusText || 'Something went wrong',
        type: 'error',
      });
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Create sequence email step
export const createEmailStep = (
  data,
  sequenceId,
  onHide,
  eventorder,
  steps,
) => async (dispatch) => {
  try {
    const values = {
      template_id: data.value,
      template_name: data.label,
      sequence_id: sequenceId,
      type: steps.step_type,
      event: steps.step_event,
      event_order: eventorder,
      emails: steps?.emails || null,
      selectedTemplates: steps?.selectedTemplates || null,
    };
    await api
      .post('/automation/seqsteps', {
        values,
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: CREATE_STEPS,
            payload: res.data,
          });
          onHide();
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step added', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update sequence email step
export const updateEmailStep = (
  data,
  sequenceId,
  eventId,
  onHide,
  steps,
) => async (dispatch) => {
  try {
    const values = {
      template_id: data.value,
      template_name: data.label,
      sequence_id: sequenceId,
      event: steps.step_event,
      type: steps.step_type,
      emails: steps?.emails || null,
      selectedTemplates: steps?.selectedTemplates || null,
    };
    await api
      .put(`/automation/seqsteps/${eventId}`, {
        values,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_STEPS,
            payload: res.data,
          });
          onHide();
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step updated', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update sequence email step
export const updateEmailName = (name, steps) => async (dispatch) => {
  try {
    const values = {
      template_id: steps.template_id,
      template_name: name,
      sequence_id: steps.sequence_id,
      event: steps.event_type,
      type: steps.type,
    };
    await api
      .put(`/automation/seqsteps/${steps.id}`, {
        values,
      })
      .then((res) => {
        dispatch({
          type: UPDATE_STEPS,
          payload: res.data,
        });
        dispatch(getSequenceData(steps.sequence_id));
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Create sequence wait step
export const createWaitStep = (data, sequenceId, onHide, eventorder) => async (
  dispatch,
) => {
  try {
    const values = {
      template_id: null,
      template_name: 'Wait',
      sequence_id: sequenceId,
      type: 4,
      event: 'wait',
      interval: data.interval,
      interval_unit: data.interval_unit,
      event_order: eventorder,
    };
    await api
      .post('/automation/seqsteps', {
        values,
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: CREATE_STEPS,
            payload: res.data,
          });
          onHide();
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step added', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update sequence wait step
export const updateWaitStep = (data, sequenceId, eventId, onHide) => async (
  dispatch,
) => {
  try {
    const values = {
      template_id: null,
      template_name: 'Wait',
      sequence_id: sequenceId,
      type: 4,
      event: 'wait',
      interval: data.interval,
      interval_unit: data.interval_unit,
    };
    await api
      .put(`/automation/seqsteps/${eventId}`, {
        values,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_STEPS,
            payload: res.data,
          });
          onHide();
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step updated', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Delete sequence step
export const deleteSequenceStep = (eventId, sequenceId, eventOrder) => async (
  dispatch,
) => {
  try {
    const values = { event_order: eventOrder };
    await api
      .delete(`/automation/seqsteps/${eventId}`, {
        values,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: DELETE_STEPS,
            payload: res.data,
          });
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step removed', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Create sequence task step
export const createTaskStep = (
  data,
  sequenceId,
  onHide,
  eventorder,
  template,
) => async (dispatch) => {
  try {
    const values = {
      category_id: data.category_id,
      template_id: template.value,
      //template_name:'Linkedin message',
      template_name: template.label ? template.label : 'Linkedin message',
      description: data.content,
      sequence_id: sequenceId,
      type: 3,
      event: 'linkedIn',
      event_order: eventorder,
      copy_template: data.copy_template,
    };
    await api
      .post('/automation/seqsteps', {
        values,
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: CREATE_STEPS,
            payload: res.data,
          });
          onHide();
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step added', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update sequence task step
export const updateTaskStep = (
  data,
  sequenceId,
  eventId,
  onHide,
  template,
) => async (dispatch) => {
  try {
    const values = {
      category_id: data.category_id,
      template_id: template.value,
      //template_name:'Linkedin message',
      template_name: template.label ? template.label : 'Linkedin message',
      description: data.content,
      sequence_id: sequenceId,
      type: 3,
      event: 'linkedIn',
      copy_template: data.copy_template,
    };
    await api
      .put(`/automation/seqsteps/${eventId}`, {
        values,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_STEPS,
            payload: res.data,
          });
          onHide();
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step updated', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Create sequence CV Spec step
export const createCVSpecStep = (
  sequenceId,
  setToggleOpen,
  eventorder,
) => async (dispatch) => {
  try {
    const values = {
      template_id: null,
      template_name: 'CV spec',
      sequence_id: sequenceId,
      type: 2,
      event: 'cvspec',
      event_order: eventorder,
    };
    await api
      .post('/automation/seqsteps', {
        values,
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: CREATE_STEPS,
            payload: res.data,
          });
          setToggleOpen(false);
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step added', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Create sequence Callbaks step
export const createCallbackStep = (
  sequenceId,
  setToggleOpen,
  eventorder,
) => async (dispatch) => {
  try {
    const values = {
      template_id: null,
      template_name: 'Callback',
      sequence_id: sequenceId,
      type: 6,
      event: 'callback',
      event_order: eventorder,
    };
    await api
      .post('/automation/seqsteps', {
        values,
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: CREATE_STEPS,
            payload: res.data,
          });
          setToggleOpen(false);
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step added', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Common function to add steps sequence Callbaks step
export const addSequenceStep = (values, sequenceId, setToggleOpen) => async (
  dispatch,
) => {
  try {
    await api
      .post('/automation/seqsteps', {
        values,
      })
      .then((res) => {
        //if(res.status === 201){
        dispatch({
          type: CREATE_STEPS,
          payload: res.data,
        });
        setToggleOpen(false);
        dispatch(getSequenceData(sequenceId));
        toast.success('Sequence step added', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
        //}
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Update sequence step
export const updateSequenceStep = (
  values,
  sequenceId,
  eventId,
  onHide,
) => async (dispatch) => {
  try {
    await api
      .put(`/automation/seqsteps/${eventId}`, {
        values,
      })
      .then((res) => {
        dispatch({
          type: UPDATE_STEPS,
          payload: res.data,
        });
        onHide();
        dispatch(getSequenceData(sequenceId));
        toast.success('Sequence step updated', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Create End sequence step
export const createEndSequence = (
  sequenceId,
  setToggleOpen,
  eventorder,
) => async (dispatch) => {
  try {
    const values = {
      template_id: null,
      template_name: 'End Sequence',
      sequence_id: sequenceId,
      type: 5,
      event: 'endsquence',
      event_order: eventorder,
    };
    await api
      .post('/automation/seqsteps', {
        values,
      })
      .then((res) => {
        dispatch({
          type: CREATE_STEPS,
          payload: res.data,
        });
        setToggleOpen(false);
        dispatch(getSequenceData(sequenceId));
        toast.success('Sequence step added', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

/**
 * Get Sequence summary report
 */
export const getSequenceReport = (sequenceId, params = {}) => async (
  dispatch,
) => {
  try {
    await api
      .get(`/automation/sequence/stats/${sequenceId}`, { params })
      .then((res) => {
        dispatch({
          type: GET_SEQUENCE_REPORTS,
          payload: res.data,
        });
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

/**
 * Get Sequence Details and set in reducers
 */
export const getSequenceDetail = (sequenceId, setLoading = () => {}) => async (
  dispatch,
) => {
  try {
    await api.get(`/automation/sequence/detail/${sequenceId}`).then((res) => {
      setLoading(false);
      dispatch({
        type: SEQUENCE_DETAIL,
        payload: res.data,
      });
    });
  } catch (err) {
    setLoading(false);
    dispatch({
      type: SEQUENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

/**
 * Get Sequence steps grid data and set in reducer
 */
export const getSequenceStepsReport = (sequenceId, params = {}) => async (
  dispatch,
) => {
  try {
    await api
      .get(`/automation/sequence/step-report/${sequenceId}`, { params })
      .then((res) => {
        dispatch({
          type: STEPS_REPORTS,
          payload: res.data,
        });
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

/**
 * Get Sequence recipients grid data and set in reducer
 */
export const getRecipientReport = (sequenceId, params = {}) => async (
  dispatch,
) => {
  try {
    await api
      .get(`/automation/sequence/report-recipients/${sequenceId}`, { params })
      .then((res) => {
        dispatch({
          type: SEQUENCE_RECIPIENTS,
          payload: res.data,
        });
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

/**
 * Get step template list
 */
export const getStepTemplates = (stepId, sequenceId) => async (dispatch) => {
  try {
    await api
      .get(`/automation/seqsteps/templates/${stepId}/${sequenceId}`)
      .then((res) => {
        dispatch({
          type: STEP_TEMPLATES,
          payload: res.data,
          campaign: {
            stepEventId: stepId,
          },
        });
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

/**
 * Update step template status
 */
export const updateStepTemplateStatus = (
  templateId,
  params = {},
  showSnaker,
) => async (dispatch) => {
  try {
    await api
      .put(`/automation/seqsteps/template/${templateId}`, params)
      .then((res) => {
        showSnaker(false);
        dispatch({
          type: UPDATE_STEP_TEMPLATE,
          payload: {
            ...params,
            id: templateId,
          },
        });
      });
  } catch (err) {
    showSnaker(true, err.response.data.Error);
    dispatch({
      type: SEQUENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add new templates to email step
export const addTemplateToEmailStep = (
  sequenceId,
  stepId,
  onClose,
  steps,
) => async (dispatch) => {
  try {
    const values = {
      type: steps.step_type,
      event: steps.step_event,
      emails: steps?.emails || null,
      selectedTemplates: steps?.selectedTemplates || null,
    };
    await api
      .post(`/automation/seqsteps/template/${stepId}`, {
        values,
      })
      .then((res) => {
        dispatch({
          type: CREATE_STEPS,
          payload: res.data,
        });
        dispatch(getStepTemplates(stepId, sequenceId));
        onClose(true);
        toast.success('Template added in step', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/**
 * Get step template list
 */
export const getTwilioNumbers = () => async (dispatch) => {
  try {
    await api.get(`/integrations/twilio/numbers`).then((res) => {
      dispatch({
        type: GET_TWILIO_NUMBERS,
        payload: res.data,
      });
    });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
