import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(({ custom }) => ({
  spinnerRoot: {
    width: 60,
    height: 60,
    position: 'absolute',
    top: 80,
    left: 'calc(50% - 30px)',
  },
  spinnerSVG: {
    fill: custom.colorCode.blueLightShade8,
    color: custom.colorCode.blueLightShade8,
  },
}));

const Spinner = ({ style }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <CircularProgress
        style={{
          width: 60,
          height: 60,
          ...style,
        }}
        classes={{
          root: classes.spinnerRoot,
          svg: classes.spinnerSVG,
        }}
      />
    </Fragment>
  );
};

export default Spinner;
