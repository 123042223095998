import {
  GET_ADS_ACCOUNT,
  ADS_ACCOUNT_ERROR,
  GET_AUDIENCES,
  AUDIENCES_ERROR,
} from '../actions/types';

const initialState = {
  audiences: [],
  adsAccounts: null,
  loading: true,
  // content: null,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_AUDIENCES: {
      let data = {
        ...state,
        audiences: payload,
        loading: false,
      };
      return data;
    }
    case GET_ADS_ACCOUNT: {
      let data = {
        ...state,
        adsAccounts: payload.ads_account,
        loading: false,
      };
      return data;
    }
    case AUDIENCES_ERROR:
    case ADS_ACCOUNT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        // profile: null,
      };
    default:
      return state;
  }
}
