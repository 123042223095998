import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TaskTabs from './TaskTabs';

const Task = (props) => {
  return <TaskTabs {...props} />;
};

Task.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Task);
