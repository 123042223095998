import { GET_TABS, ADD_TAB, UPDATE_TAB, TAB_ERROR } from '../actions/types';

const initialState = {
  tabs: [],
  tabId: null,
  loading: true,
  error: {},
};
const updateObject = (oldObject, newValues) => {
  // Encapsulate the idea of passing a new object as the first parameter
  // to Object.assign to ensure we correctly copy data instead of mutating
  return Object.assign({}, oldObject, newValues);
};

const addNewIndex = (oldObject, newObject) => {
  let index = Object.keys(oldObject).length;
  oldObject[index] = newObject;
  return oldObject;
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_TAB: {
      const data = {
        ...state,
        tabId: payload.id,
        tabs: addNewIndex(state.tabs, {
          id: payload.id,
          name: payload.filter_name,
          user_id: payload.user_id,
          filter_type: payload.filter_type,
        }),
        loading: false,
      };
      return data;
    }

    case GET_TABS: {
      const data = {
        ...state,
        tabs: payload,
        loading: false,
      };
      return data;
    }

    case UPDATE_TAB: {
      const data = {
        ...state,
        tabId: payload.id,
        loading: false,
      };
      return data;
    }

    case TAB_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
