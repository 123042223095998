import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import DrawerFormFooter from '../../../components/DrawerFormHeader/DrawerFormFooter';
import CustomSnackBar from '../../../components/CustomSnackBar';
import Spinner from '../../../layout/Spinner';

import { saveBullhornIntegrations } from '../../../actions/admin/integration';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
}));

/**
 * Component for Bullhorn Integration form
 * @param {*} props
 * @returns
 */
const BullhornIntegrationForm = (props) => {
  const classes = useStyles();

  //Local state variables
  const [formData, setFormData] = useState({});
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const dispatch = useDispatch();

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = [
      'client_id',
      'client_secret',
      'client_data_center',
      'username',
      'password',
    ];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Handle function of Integration Twilio form
   * It call server API to save data into tables
   */
  const handleSubmit = async () => {
    if (disabledNextButton() === false) {
      setDisabledButton(true);
      const values = {
        ...formData,
        plugin_id: props.integrationData.id,
      };
      await dispatch(saveBullhornIntegrations(values, props?.closeDrawer));
      // props?.closeDrawer({ data: 'bullhornIntegration' });
      setTimeout(() => {
        setDisabledButton(false);
      }, 10);
    }
  };

  /**
   * function to handle the form element change event
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to close toaster after server side error
   */
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackBar(false);
  };

  if (disabledButton) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  return (
    <Box className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <Box className={classes.formWrapper}>
          <Box>
            <Grid container spacing={3}>
              {/*Client Id*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label={`Client ID *`}
                  onChange={handleChange}
                  name="client_id"
                  value={formData?.client_id || ''}
                  fullWidth
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter client id']}
                />
              </Grid>

              {/*Client Secret*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Client Secret *"
                  onChange={handleChange}
                  name="client_secret"
                  value={formData?.client_secret || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter client secret']}
                />
              </Grid>

              {/*Tenant Data Center */}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Data Center *"
                  onChange={handleChange}
                  name="client_data_center"
                  value={formData?.client_data_center || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter client secret']}
                />
              </Grid>

              {/*Username*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Username *"
                  onChange={handleChange}
                  name="username"
                  value={formData?.username || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter username']}
                />
              </Grid>
              {/*Password*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Password *"
                  onChange={handleChange}
                  name="password"
                  value={formData?.password || ''}
                  variant="outlined"
                  type="password"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter password']}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <DrawerFormFooter disableButton={disabledNextButton()}>
          <Box />
        </DrawerFormFooter>
      </ValidatorForm>

      <CustomSnackBar
        open={openErrorSnackBar}
        autoHideDuration={2000}
        onClose={handleErrorSnackbarClose}
        severity={'error'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  );
};

export default BullhornIntegrationForm;
