import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from 'react-bootstrap';
import { Link, Tooltip } from '@material-ui/core';
import CustomDataGrid from '../controlled-component/customGridComponent';
import SimpleSwitch from '../components/SimpleSwitch';
import ReceiptIcon from '@material-ui/icons/Receipt';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  stepMainGrid: {
    marginTop: 27,
  },
  tdContentEllipsis: {
    width: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 3,
  },
  publishSwitch: {
    marginRight: 10,
  },
});

const EmailStep = (props) => {
  const { step } = props;
  const classes = useStyles();
  let isLast = props.eventlength - props.indexkey;

  //const [publishAction, setPublishAction] = useState(false); // toggle of the status switch

  // handle the publish and un publish of sequence
  const handleSwitchChange = () => {
    /* setPublishAction(!publishAction);
    const status = sequence?.details?.is_published === 'Un-Published' ? 1 : 2;
    dispatch(
      updateSequenceStatus(
        sequence?.details?.id,
        status,
        'sequence-detail',
        dispatch,
      ),
    ); */
  };

  // columns for the step templates table grid
  const columns = useMemo(
    () => [
      {
        name: 'TemplateName',
        label: 'Template Name',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            let rowData = step.templateList[dataIndex];
            return (
              <Tooltip title={rowData.TemplateName || ''} arrow>
                <div className={classes.tdContentEllipsis}>
                  <Link to={`/email/detail/${rowData.id}`} href={`/email/detail/${rowData.id}`}>
                    {rowData.TemplateName}
                  </Link>
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Sent',
        name: 'total_sent',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 150, maxWidth: 150 },
          }),
          setCellProps: () => ({
            style: { minWidth: 150, maxWidth: 150 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value || ''} arrow>
                <div>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Open Rate',
        name: 'open_rate',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}%</div>;
          },
        },
      },
      {
        label: 'Clicked Rate',
        name: 'click_rate',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}%</div>;
          },
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <SimpleSwitch
            checked={value ? true : false}
            handleChange={handleSwitchChange}
            className={classes.publishSwitch}
            leftLabelText={''}
            rightLabelText={''}
            name={'status-switch'}
          />;
          },
        },
      },
      {
        label: 'Actions',
        name: 'id',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>
              <Tooltip title={'Edit Email'} placement={`top-start`} arrow>
                <Link onClick={() => props.editEmail(value)}>
                  <EditIcon
                    style={{
                      position: 'relative',
                      color: 'black',
                      fontSize: '20px',
                      fontWeight: 400,
                    }}
                  />
                </Link>
              </Tooltip>
              <Tooltip title={'View Report'} placement={`top-start`} arrow>
                <Link to={`/email/detail/${value}`} href={`/email/detail/${value}`} component="a">
                  <ReceiptIcon
                    style={{
                      position: 'relative',
                      color: 'black',
                      fontSize: '20px',
                      fontWeight: 400,
                    }}
                  />
                </Link>
              </Tooltip>
            </div>;
          },
        },
      },
    ],
    [step.templateList],
  );

  return (
    <>
      <div className="cardSequenceCls emailTemplateCls tooltipCard">
        <Accordion>
          <AccordionSummary
            /* expandIcon={
              <Button className="addResourcesCls" onClick={() => true}>
                {step?.templateList?.length || 0}
              </Button>
            } */
            aria-label="Expand"
            aria-controls={`step-${step.id}-content`}
            id={`step-${step.id}-header`}
          >
            <div className="tooltipActionCls">
              <Tooltip title={'Update Step'} placement={`top-start`} arrow>
                <span onClick={() => props.editstep('email', props.step)}>
                  <i className="fa fa-file editTooltipIcon"></i>
                </span>
              </Tooltip>
              <Tooltip title={'Delete Step'} placement={`top-start`} arrow>
                <span
                  onClick={() =>
                    props.delete(props.event_id, props.step.event_order)
                  }
                >
                  <i className="fa fa-trash deleteTooltipIcon"></i>
                </span>
              </Tooltip>
            </div>
            <p>
              <i
                className="fa fa-envelope mr-2 envelopeIconCls"
                aria-hidden="true"
              ></i>{' '}
              Send an email "{props.title}"
              <Button className="addResourcesCls" onClick={() => true}>
                {step?.templateList?.length || 0}
              </Button>
            </p>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Typography color="textSecondary">
              The click event of the nested action will propagate up and expand
              the accordion unless you explicitly stop it.
            </Typography> */}
            <CustomDataGrid
              columns={columns}
              data={step.templateList}
              hideToolBar={true}
              paperWidth={'290px'}
              className={classes.stepMainGrid}
              options={{
                pagination: true,
                selectableRows: 'none',
                filterType: 'dropdown',
                responsive: 'simple',
                selectToolbarPlacement: 'none',
                /* customToolbar: () => {
                  return <></>;
                }, */
              }}
              /* components={{ TableBody: BodyComponent }} */
            />
          </AccordionDetails>
        </Accordion>
        <div className="lineJointCls"></div>
      </div>
      <br />
      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(props.step.event_order)}
        >
          +
        </Button>
        {isLast ? <div className="lineBtnJointCls"></div> : null}
      </div>
      <br />
    </>
  );
};

export default EmailStep;
