import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import api from '../../../../utils/api';
import CheckboxLabel from '../../../../components/CheckboxLabel';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formHeading: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 0,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
  },
  formCheckbox: {
    '&.Mui-checked': {
      color: custom.colorCode.lightRedColor,
    },
    color: custom.colorCode.lightRedColor,
  },
  textWrapper: {
    color: '#172b4d',
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 0,
    marginTop: 20,
    '& .text': {
      color: '#60aec3',
      cursor: 'pointer',
    },
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
}));

const ResetPasswordForm = ({ userData, closeDrawer }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [newPassword, setNewPassword] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    ValidatorForm.addValidationRule('customPassword', (value) => {
      const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
      return regularExpression.test(value);
    });
    return () => {
      if (ValidatorForm.hasValidationRule('customPassword')) {
        ValidatorForm.removeValidationRule('customPassword');
      }
    };
  }, []);

  /**
   * Handle function of update password form
   * It call server API to save data into tables
   */
  const handleSubmit = () => {
    if (newPassword) {
      setDisabledButton(true);
      const values = {
        password: formData?.newPassword,
      };
      api
        .put(`/automation/users/update-password/${userData.id}`, { ...values })
        .then((res) => {
          setDisabledButton(false);
          closeDrawer({
            passwordUpdated: true,
            message: 'Password updated successfully',
            status: 'success',
          });
        })
        .catch((error) => {
          setDisabledButton(false);
          closeDrawer({
            passwordUpdated: true,
            message: error?.response?.data?.error || 'Something went wrong ',
            status: 'error',
          });
        });
    }
    if (sendEmail) {
      setDisabledButton(true);

      const payload = { username: userData.username };
      api
        .post('/password/forgot', payload)
        .then((resp) => {
          setDisabledButton(false);
          closeDrawer({
            passwordUpdated: true,
            message: 'Reset password link sent',
            status: 'success',
          });
        })
        .catch((error) => {
          setDisabledButton(false);
          closeDrawer({
            passwordUpdated: true,
            message: error?.response?.data?.error || 'Something went wrong ',
            status: 'error',
          });
        });
    }
  };

  /**
   * function to handle the form element change event
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleNewPasswordCheckBox = () => {
    setNewPassword(!newPassword);
    setSendEmail(false);
  };

  const handleSendEmailCheckBox = () => {
    setSendEmail(!sendEmail);
    setNewPassword(false);
  };

  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          <div>
            <Grid container>
              <Grid item xs={12} sm={12} className="pr-3">
                <FormGroup style={{ marginBottom: 0 }}>
                  <CheckboxLabel
                    label="Set Password"
                    checked={newPassword}
                    name="adminRole"
                    value={'newPassword'}
                    style={{ marginBottom: 0 }}
                    handleChange={handleNewPasswordCheckBox}
                  />
                </FormGroup>
              </Grid>
            </Grid>

            {newPassword && (
              <Grid container>
                {/*Team Name*/}
                <Grid item xs={12} sm={6} className="pr-3">
                  <TextValidator
                    label="New Password *"
                    onChange={handleChange}
                    name="newPassword"
                    value={formData?.newPassword || ''}
                    variant="outlined"
                    fullWidth
                    validators={['required', 'customPassword']}
                    errorMessages={[
                      'Please enter new password',
                      'Min 6 char, min 1 capital char, min 1 number and min 1 special char',
                    ]}
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              container
              spacing={3}
              style={{ marginTop: newPassword ? 12 : 0 }}
            >
              {/*Team Name*/}
              <Grid item xs={12} sm={12}>
                <FormGroup>
                  <CheckboxLabel
                    label="Send rest password email."
                    checked={sendEmail}
                    name="adminRole"
                    value={'email'}
                    handleChange={handleSendEmailCheckBox}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={classes.formFooter}>
          <div />

          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledButton}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default ResetPasswordForm;
