import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  '@global': {
    '.td-content-ellipsis': {
      width: 'max-content',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.td-anchor-content-ellipsis': {
      color: '#5780ab',
      textDecoration: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
