import {
  LOAD_ACTIVITY_REPORT,
  ACTIVITY_REPORT_ERROR,
  SET_ACTIVITY_REPORT_FILTER_DATA,
  SHOW_LOAD_ACTIVITY_REPORT_LOADER,
  LOAD_ACTIVITY_REPORT_TABS,
  LOAD_ACTIVITY_REPORT_LOADING,
  SET_ACTIVITY_REPORT_CURRENT_TAB,
} from '../../actions/types';

const initialState = {
  report_id: null,
  loading: true,
  reportTab: {
    tabs: [],
    loading: true,
  },
  currentTab: {},
  count: 0,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LOAD_ACTIVITY_REPORT_LOADER: {
      return {
        ...state,
        [payload.tabData.id]: {
          ...state[payload.tabData.id],
          loading: true,
        },
      };
    }

    case LOAD_ACTIVITY_REPORT_LOADING: {
      return {
        ...state,
        reportTab: {
          ...state.reportTab,
          loading: payload,
        },
      };
    }

    case LOAD_ACTIVITY_REPORT_TABS: {
      const tabsData = {};
      payload.tabs.forEach((e) => {
        tabsData[e.id] = {
          activityReportList: [],
          myFilterData: [],
          filterList: {},
          filterState: {},
          defaultFilterState: {},
          count: 0,
          loading: true,
        };
      });

      return {
        ...state,
        reportTab: {
          ...state.reportTab,
          ...payload,
          loading: false,
        },
        ...tabsData,
      };
    }

    case LOAD_ACTIVITY_REPORT: {
      const id = Object.keys(payload)[0];
      return {
        ...state,
        [id]: {
          ...state[id],
          ...payload[id],
        },
        count: payload[id]?.count || 0,
      };
    }

    case SET_ACTIVITY_REPORT_CURRENT_TAB: {
      return {
        ...state,
        ...payload,
      };
    }

    case SET_ACTIVITY_REPORT_FILTER_DATA: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload[payload.id],
        },
      };
    }

    case ACTIVITY_REPORT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
