import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import BreadCrumbs from '../../components/BreadCrumbs';
import SimpleSwitch from '../../components/SimpleSwitch';
import DetailPage from './components/DetailPage';
import ActionButtonMenu from './components/ActionButtonMenu';
// import EditTemplate from '../EditTemplate';
import CreateTemplate from '../CreateTemplate/CreateTemplate';

import { getTemplate, updateTemplateStatus } from '../../actions/template';

import useStyles from './style';

const DetailPageWrapper = (props) => {
  const { match } = props;
  const templateData = useSelector((state) => state.template);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [publishAction, setPublishAction] = useState(false); // toggle of the switch in header
  const [editPopupToggle, setEditPopupToggle] = useState(false);

  // did mount to call api one for one time
  useEffect(() => {
    // call the template detail api and set data in reducer
    dispatch(getTemplate(match.params.id));
  }, []);

  useEffect(() => {
    setPublishAction(templateData.detail.is_published === 'Published');
  }, [templateData.detail]);

  // handle the publish and un publish of template
  const handleSwitchChange = () => {
    setPublishAction(!publishAction);
    const status = templateData.detail.is_published === 'Un-Published' ? 1 : 2;
    dispatch(
      updateTemplateStatus(
        templateData.detail?.id,
        status,
        'email-detail-template',
        dispatch,
      ),
    );
  };

  // open edit pop up
  const handleActionClick = (event) => {
    setEditPopupToggle(!editPopupToggle);
  };

  // close edit pop up
  const handleEditPopupClose = (event) => {
    setEditPopupToggle(false);
  };

  return (
    <div>
      <BreadCrumbs
        crumbData={[
          { to: '/template', title: 'Emails' },
          // { to: '/admin/template', title: 'Emails' },
          { title: templateData.detail?.name || '' },
        ]}
      />

      <div className={classes.pageHeader}>
        <span>{templateData.detail?.name || ''}</span>

        <div className={classes.pageHeaderRight}>
          {/*<span className={classes.pageHeaderRightOwner}>*/}
          {/*  <b>Owner:</b> Test Owner*/}
          {/*</span>*/}
          <SimpleSwitch
            checked={publishAction}
            handleChange={handleSwitchChange}
            className={classes.publishSwitch}
            leftLabelText={'Deactive'}
            rightLabelText={'Active'}
            name={'publish-un-publish'}
          />

          <ActionButtonMenu
            buttonClassName={classes.actionButton}
            handleEditPopupOpen={handleActionClick}
            updateStatus={handleSwitchChange}
          />
        </div>
      </div>

      <DetailPage
        templateData={templateData}
        templateId={match.params.id}
        users={templateData?.detail?.userList || []}
        dateFilterOptions={templateData?.detail?.dateFilterOptions || []}
      />

      {editPopupToggle && (
        <CreateTemplate
          openDrawer={editPopupToggle}
          onClose={handleEditPopupClose}
          admin={props.admin ? props.admin : false}
          page={'grid'}
          history={props.history}
          getRecords={() => props.getTemplate(templateData.detail?.id)}
          templateId={templateData.detail?.id}
        />
      )}
      {/*{editPopupToggle && (*/}
      {/*  <EditTemplate*/}
      {/*    temp_id={templateData.detail?.id}*/}
      {/*    modalState={editPopupToggle}*/}
      {/*    onHide={handleEditPopupClose}*/}
      {/*    history={props.history}*/}
      {/*    getRecords={() => props.getTemplate(templateData.detail?.id)}*/}
      {/*  />*/}
      {/*)}*/}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

const mapDispatchToProps = {
  getTemplate,
};

export default connect(null, mapDispatchToProps)(DetailPageWrapper);
