import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

import CustomDrawer from '../../../components/CustomDrawer';
import DrawerFormHeader from '../../../components/DrawerFormHeader';
import CustomDataGrid from '../../../controlled-component/customGridComponent';
import PageHeader from '../../../components/PageHeader';
import LoadingTableBody from '../../../components/LoadingTableBody';
import SimpleSwitch from '../../../components/SimpleSwitch';
import { ActiveIcon, InActiveIcon } from '../../../components/GridIcons';

// material ui design styles
const useStyles = makeStyles({
  selectSliderRoot: {
    zIndex: 1100,
  },
  selectSliderPaper: {
    width: '100%',
    height: '100%',
    zIndex: 1100,
  },
  integrationGrid: {
    marginTop: 12,
  },
  tdDivLineHeight: {
    lineHeight: 3,
  },
  pageHeaderWrapper: {
    '& .heading-sub-title': {
      height: 'unset',
      width: 550,
    },
  },
  headerRightSideWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  headerTitleClassName: {
    paddingLeft: 0,
  },
});

const EditSyncSlider = (props) => {
  const classes = useStyles();

  const { toggleOpen, closeSlider, selectedSyncData = {} } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [syncList, setSyncList] = useState([]);

  const toggleSideDrawer = () => {
    closeSlider();
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setSyncList([
        {
          name: 'First Name',
          status: true,
          mappingType: 'Succeeded',
          detail: 'Full Name',
        },
      ]);
    }, 2000);
  }, []);

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Bullhorn',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = syncList[dataIndex];
            return (
              <Tooltip title={rData.name || ''} arrow>
                <div
                  className={`td-content-ellipsis ${classes.tdDivLineHeight}`}
                >
                  {rData.name}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: 'detail',
        label: 'Succede',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 300 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 300 },
          }),
          customBodyRender: (value) => {
            return value ? (
              <InActiveIcon fontSize="small" />
            ) : (
              <ActiveIcon fontSize="small" />
            );
          },
        },
      },
    ],
    [syncList],
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={isLoading} {...props} />,
    [isLoading],
  );

  return (
    <div>
      <CustomDrawer
        style={{ zIndex: 1100 }}
        anchor={'bottom'}
        open={toggleOpen}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={toggleSideDrawer}
        classes={{
          root: classes.selectSliderRoot,
          paper: classes.selectSliderPaper,
        }}
      >
        <DrawerFormHeader
          handleCloseClick={toggleSideDrawer}
          titleText={`${selectedSyncData.name} Sync`}
          formHeaderTitleClassName={classes.headerTitleClassName}
        >
          <div className="flex-col content">
            <PageHeader
              mainTitle={'Field Mapping'}
              subTitle={''}
              showCount={false}
              // subTitle={`Fields mapping connect individual fields with matching form each app. We've
              // created the default field mapping listed bellow for you.`}
              itemCount={syncList.length}
              className={classes.pageHeaderWrapper}
              showRightHeading
              rightHeadingContent={
                <div className={classes.headerRightSideWrapper}>
                  <SimpleSwitch
                    checked={true}
                    handleChange={() => {}}
                    className="sequence_header_switch"
                    leftLabelText={'Not In Sync'}
                    rightLabelText={'In Sync'}
                    name={'publish-un-publish'}
                  />
                  {/*<AddButton*/}
                  {/*  handleClick={() => {}}*/}
                  {/*  title={`Actions`}*/}
                  {/*  dataTarget={`Actions`}*/}
                  {/*/>*/}
                </div>
              }
            />

            <CustomDataGrid
              columns={columns}
              hideToolBar
              paperWidth={'0px'}
              data={syncList}
              className={classes.integrationGrid}
              options={{
                pagination: Boolean(false),
                filterType: 'dropdown',
                selectableRows: 'none',
                responsive: 'simple',
                searchPlaceholder: '',
                selectToolbarPlacement: 'none',
                searchOpen: false,
                search: false,
              }}
              components={{ TableBody: BodyComponent }}
            />
          </div>
        </DrawerFormHeader>
      </CustomDrawer>
    </div>
  );
};

export default EditSyncSlider;
