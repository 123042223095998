import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
/* import SelectDropDown from '../components/SelectDropDown'; */
import { Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InfoIcon from '@material-ui/icons/Info';
/* import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'; */
import FormAutoCompleteSelect from '../components/FormAutoCompleteSelect';

import useStyles from './CreateSequenceSlider/CreateSequenceFormStyle';
import {
  getTaskCategories,
  addSequenceStep,
  updateSequenceStep,
  getTwilioNumbers,
} from '../actions/sequence';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SmsEditor from '../utils/SmsEditor';

// Yup schema to add validation on form element
const schema = yup.object().shape({
  //category_id: yup.number().required('Template type is a required field'),
  content: yup
    .string()
    .required('Content is a required field')
    .max(1600, 'Max 1600 character allowed'),
  phone_number: yup.string().required('Number is a required field'),
});

/**
 * Component to render SMS step form
 * @param {*} props
 * @returns
 */
const CreateSms = (props) => {
  const classes = useStyles();

  /* const [toggleOpen, setToggleOpen] = useState(false); */
  const [toggleBtn, setToggleBtn] = useState(false);
  /* const [templateId, setTemplateId] = useState(false); */
  const [eventId, setEventId] = useState(false);
  //const [contents, setContents] = useState(props.template.content);

  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    // formState: { isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(schema) });
  const [formData, setFormData] = useState({});

  /**
   * Handle Did mount event
   */
  useEffect(() => {
    props.getTaskCategories();
    props.getTwilioNumbers();
    if (props.stepcontent.id) {
      setEventId(props.stepcontent.id);
      setValue('content', props.stepcontent.description);
      setValue('phone_number', props.stepcontent.phone_number);
      setFormData({
        ...formData,
        phone_number: props.stepcontent.phone_number,
      });
    }
  }, [props.getTaskCategories, props.getTwilioNumbers, props.stepcontent.id]);

  /**
   * Function to handle change event of select field
   * @param {*} name
   * @param {*} key
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    setFormData({
      ...formData,
      [name]: tag[key],
    });
    setValue('phone_number', tag[key]);
  };

  /**
   * Category data
   */
  const categories = props.sequence.categories
    ? props.sequence.categories
    : null;
  const cat_id = categories.map(function (cat) {
    return cat.id;
  });

  /**
   * Twilio number options
   */
  const twilioNumbers = props.sequence.templates
    ? props.sequence.twilioNumbers
    : null;
  var numberOptions = twilioNumbers.map(function (number) {
    return { value: number, title: number };
  });

  /**
   * Function to handle save event
   * @param {*} value
   */
  const addSms = async (value) => {
    //console.log(value);
    const values = {
      template_id: null,
      template_name: 'SMS',
      sequence_id: props.sid,
      type: 10,
      event: 'SMS',
      event_order: props.eventorder,
      description: value.content,
      category_id: value.category_id,
      phone_number: value.phone_number,
    };
    if (eventId) {
      props.updateSequenceStep(values, props.sid, eventId, props.closeModal);
    } else {
      props.addSequenceStep(values, props.sid, props.closeModal);
    }
    //props.closeModal();
  };

  return (
    <div className="createEmailHeadCls">
      <Form onSubmit={handleSubmit(addSms)}>
        <div className="emailContent">
          <Row>
            <Col sm={12} md={12} lg={12}>
              <label>
                Select number *{' '}
                <Tooltip
                  title={'User can change number during enrollement'}
                  placement={`top`}
                  arrow
                >
                  <InfoIcon
                    style={{
                      fontSize: '15px',
                    }}
                  />
                </Tooltip>
              </label>
              <Controller
                control={control}
                name="phone_number"
                defaultValue=""
                /* onChange={} */
                render={({ onChange, value }) => (
                  <FormAutoCompleteSelect
                    onChange={handleSelectChange('phone_number', 'value')}
                    name="phone_number"
                    id="phone_number"
                    disableClearable
                    options={numberOptions}
                    getOptionLabel={(option) => option.title || ''}
                    getOptionSelected={(option, val) => {
                      option.value === val;
                    }}
                    value={
                      value
                        ? numberOptions?.find(
                            (c) => c.value === formData?.phone_number,
                          ) || ''
                        : ''
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        /* label="Select twilio number *" */
                        className={classes.selectBoxStyle}
                        name="phone_number"
                        value={formData?.phone_number || ''}
                        fullWidth
                        validators={['required']}
                        /* errorMessages={['Select twilio number']} */
                      />
                    )}
                  />
                )}
              />
              {errors.phone_number && (
                <label className="error">{errors.phone_number?.message}</label>
              )}
            </Col>
          </Row>
        </div>
        <div className="emailContent">
          <Row>
            <Col sm={12} md={12} lg={12}>
              <label>
                <strong>
                  SMS Content{' '}
                  <Tooltip
                    title={
                      'Content lenght should not exceed 1600 including replacement data, \
                      otherwise SMS will deliver in multiple segment.'
                    }
                    placement={`top`}
                    arrow
                  >
                    <InfoIcon
                      /* fontSize="small" */
                      style={{
                        fontSize: '15px',
                      }}
                    />
                  </Tooltip>
                </strong>
              </label>
              <Controller
                control={control}
                name="content"
                defaultValue=""
                render={({ onChange, value }) => (
                  <SmsEditor onChange={onChange} initialvalue={value} />
                )}
              />
              {errors.content && (
                <label className="error">{errors.content?.message}</label>
              )}
              {/* <TextField
                    multiline={true}
                    rows={3}
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                    fullWidth={true}
                  /> */}
            </Col>
          </Row>
        </div>
        <div className="emailContent">
          <Form.Control
            type="hidden"
            name="category_id"
            id="category_id"
            value={cat_id}
            className="custom_input"
            ref={register}
          />
        </div>

        <div className="emailContent">
          <Button className={`${classes.nextButton} mt-5`} type="submit">
            {eventId ? `Update` : `Add`}
          </Button>
        </div>
      </Form>
    </div>
  );
};

CreateSms.propTypes = {
  auth: PropTypes.object.isRequired,
  getTaskCategories: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sequence: state.sequence,
  template: state.template,
});
const mapDispatchToProps = {
  getTaskCategories,
  addSequenceStep,
  updateSequenceStep,
  getTwilioNumbers,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateSms);
