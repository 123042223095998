import React, { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isArray } from 'lodash';

import Spinner from '../../layout/Spinner';

const useStyles = makeStyles(({ custom }) => ({
  typographyText: {
    color: custom.colorCode.lightGreyColorShade16,
  },
  showMoreText: {
    color: custom.colorCode.blueLightShade8,
    fontWeight: 700,
    cursor: 'pointer',
  },
}));

const ProfileDetail = ({ profileDetail, loading }) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner style={{ width: 20, height: 20, position: 'unset' }} />
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>

        {/*<Grid item xs={4} sm={4}>*/}
        {/*  <Typography variant="body2" className={classes.typographyText}>*/}
        {/*    Category*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={8} sm={8}>*/}
        {/*  <Typography variant="body2">*/}
        {/*    /!*Product Manager - Digital*!/*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}

        <Grid item xs={4} sm={4}>
          <Typography variant="body2" className={classes.typographyText}>
            Job Role
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Typography variant="body2">
            {isArray(profileDetail?.jobroleData) &&
              profileDetail?.jobroleData.map((jData) => jData.name).join(', ')}
          </Typography>
        </Grid>

        <Grid item xs={4} sm={4}>
          <Typography variant="body2" className={classes.typographyText}>
            Key Skills
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Typography variant="body2">
            {isArray(profileDetail?.selectedSkills) &&
              profileDetail?.selectedSkills
                .map((sData) => sData.name)
                .join(', ')}
          </Typography>
        </Grid>

        <Grid item xs={4} sm={4}>
          <Typography variant="body2" className={classes.typographyText}>
            Industries
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Typography variant="body2">
            {isArray(profileDetail?.industryData) &&
              profileDetail?.industryData.map((sData) => sData.name).join(', ')}
          </Typography>
        </Grid>

        {/*<Grid item xs={4} sm={4}>*/}
        {/*  <Typography variant="body2" className={classes.typographyText}>*/}
        {/*    Other Skills*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={8} sm={8}>*/}
        {/*  <Typography variant="body2">*/}
        {/*    /!*Return on Investment, Facebook Ads Google Campaign*!/*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}

        <Grid item xs={4} sm={4}>
          <Typography variant="body2" className={classes.typographyText}>
            Language
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Typography variant="body2">
            {isArray(profileDetail?.languageData) &&
              profileDetail?.languageData.map((lData) => lData.name).join(', ')}
          </Typography>
        </Grid>

        {/*<Grid item xs={12} sm={12}>*/}
        {/*  <Box display="flex" justifyContent="center">*/}
        {/*    <Typography variant="h6" className={classes.showMoreText}>*/}
        {/*      Show all*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*</Grid>*/}
      </Grid>
    </Box>
  );
};

export default ProfileDetail;
