import {
  LOAD_ADMIN_OFFICES,
  OFFICE_ADMIN_ERROR,
  LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES,
  LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES_ERROR,
} from '../../actions/types';

// initialize the admin office
const initialState = {
  officeList: [],
  countries: [],
  timezones: [],
  entityList: [],
  office_id: null,
  loading: true,
  error: {},
};

// admin office reducer
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ADMIN_OFFICES: {
      return {
        ...state,
        officeList: payload,
        loading: false,
      };
    }

    case OFFICE_ADMIN_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES:
      return { ...state, ...payload };

    case LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES_ERROR:
      return { ...state, ...payload };

    default:
      return state;
  }
}
