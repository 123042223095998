import React, { useEffect, useRef, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import {
  Button,
  Grid,
  FormGroup,
  Tooltip,
  Box,
  Typography,
  Menu,
  MenuItem,
  Fade,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextEditor from "../../utils/Editor";
import { english } from "../../utils/language";
import FileUpload from "./Fileupload";

const useStyles = makeStyles(({ custom }) => ({
  subjectContainer: {
    display: "flex",
  },
  outLineButton: {
    padding: "5px 15px",
    boxShadow: "unset",
    // border: `2px solid  ${custom.colorCode.blueLightShade8}`,
    // color: custom.colorCode.blueLightShade8,
    // backgroundColor: custom.colorCode.transparent,
    textTransform: "capitalize",
    width: "100%",
    height: "100%",
    marginLeft: "0px !important",
    color: "#506E91",
    border: "1px solid #8ba4be",
    borderRadius: "4px",
    backgroundColor: "#F5F7F9",
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
  menuPaper: {
    // maxHeight: 300,
    // marginTop: 8,
    // minWidth: 300,
    maxWidth: 200,
    overflowY: "unset",
    paddingTop: 0,
    paddingBottom: 0,

    // top: 232px;
    // transform-origin: 115px 53px;
    // position: absolute;
    // left: 1669px;
    // max-height: 331px;
    // overflow: hidden auto;
    // opacity: 1;
  },
  menuItemWrapper: {
    overflow: "auto",
    maxHeight: 330,
  },
}));

const EmailContent = ({
  replacementOptions,
  formState = true,
  handleSubjectChange,
  handleContentChange,
  uuid,
  emailType = 'email'
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef();
  const [selectionStart, setSelectionStart] = useState();
  const [showSubject, setShowSubject] = useState(true);

  useEffect(() => {
    if(emailType == "linkedIn") {
      setShowSubject(false);
    }
  },[]);

  const updateSelectionStart = () =>
    setSelectionStart(inputRef.current.selectionStart);

  /**
   * Handle Choosen close event
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handle Choosen open event on click
   */
  const handleBoxClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Hanle personalized field change event
   */
  const handleOnChange = (name, tag) => {
    let cursorPosition = selectionStart || formState?.subject?.length || 0;
    let value = tag.value.substring(6, tag.value.length - 6);
    let selectionPosition = value.length;
    if (formState?.subject) {
      let textBeforeCursorPosition = formState?.subject?.substring(
        0,
        cursorPosition
      );
      selectionPosition += textBeforeCursorPosition?.length;
      let textAfterCursorPosition = formState?.subject?.substring(
        cursorPosition,
        formState?.subject?.length
      );
      value = textBeforeCursorPosition + value + textAfterCursorPosition;
    }

    const subjectText = `${value}`;
    handleSubjectChange(formState?.step_id, subjectText);
    handleClose();
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.selectionStart = selectionPosition;
      inputRef.current.selectionEnd = selectionPosition;
    }, 100);
  };

  /**
   * handle the input change
   */
  const handleChange = (event) => {
    let value = event.target.value;
    handleSubjectChange(formState?.step_id, value);
  };

  /**
   * handle tinymce editor change event
   * @param name
   * @returns {function(...[*]=)}
   */
  const handleEditorChange = (name) => (value) => {
    handleContentChange(formState?.step_id, value);
  };

  return (
    <Box>
      {showSubject && <Box className={classes.subjectContainer}>
        <Grid item xs={12} sm={8} style={{ paddingRight: "10px" }}>
          <TextValidator
            label={`Subject *`}
            onChange={handleChange}
            name="subject"
            value={formState?.subject || ""}
            variant="outlined"
            fullWidth
            maxLength={"255"}
            validators={["required", "maxStringLength:255"]}
            errorMessages={[
              english.emailTemplateForm.subjectValidate,
              "Limit exceeded, Only 255 characters allowded",
            ]}
            inputRef={inputRef}
            onSelect={updateSelectionStart}
          />
        </Grid>

        <Grid item xs={2} sm={4} style={{ paddingLeft: "10px" }}>
          <Tooltip title={"Click here to personalize the subject line."}>
            <Button
              onClick={handleBoxClick}
              // dataTarget={`personalize`}
              variant="outlined"
              className={classes.outLineButton}
            >
              {`{} Personalize `}
            </Button>
          </Tooltip>
          <Menu
            id={"sequence-menu-id"}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            TransitionComponent={Fade}
            classes={{
              paper: classes.menuPaper,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {replacementOptions.length ? (
              <Box className={classes.menuItemWrapper}>
                {replacementOptions.map((option, index) => (
                  <MenuItem
                    key={`option-menu-check-${index}`}
                    value={option["id"]}
                  >
                    <Box
                      onClick={() => handleOnChange("option", option)}
                      style={{ width: "100%" }}
                    >
                      {option["text"]}
                    </Box>
                  </MenuItem>
                ))}
              </Box>
            ) : (
              <MenuItem disabled>
                <Typography>No options.</Typography>
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Box>}
      <Grid item xs={12} sm={12} style={{ paddingTop: "20px" }}>
        <FormGroup>
          <div>
            <TextEditor
              onChange={handleEditorChange("content")}
              initialvalue={formState?.content || ""}
              unsubscribe={emailType !== 'linkedIn' ? true : false}
            />
          </div>
        </FormGroup>
      </Grid>

      <Grid item xs={12} sm={12} style={{ paddingTop: "12px" }}>
        <FileUpload
          attachments={formState?.attachments}
          uuid={uuid}
          is_personalized={formState?.is_personalized}
          object_id={formState?.template_id}
        />
      </Grid>
    </Box>
  );
};

export default EmailContent;
