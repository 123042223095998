import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  filterMenuSearch: {
    width: '100%',
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: 8,
    minWidth: 300,
    overflowY: 'unset',
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuPaperList: {},
  menuItemWrapper: {
    overflow: 'auto',
    maxHeight: 230,
  },
  checkBoxLabel: {
    marginBottom: 0,
  },
  titleSelect: {
    padding: 6,
    marginRight: 12,
    cursor: 'pointer',
    borderRadius: 4,
    '& svg': {
      cursor: 'pointer',
    },
  },
  active: {
    backgroundColor: custom.colorCode.lightGreyColorShade17,
  },
}));

export default useStyles;
