import React, { useEffect, useState } from 'react';
//import { propTypes } from 'react-bootstrap/esm/Image';
import Board from 'react-trello';
//import boardDetails from '../data/data.json';
import {
  getUserTasks,
  createTasks,
  deleteTask,
  updateColumnOrder,
  arrangeTask,
  addColumn,
  deleteColumn,
  updateTaskState,
} from '../../actions/task';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyCard from './Card';
import CardForm from './NewCardForm';

const TaskBoard = (props) => {
  const [boardData, setBoardData] = useState({ lanes: props.lanes.lanes });
  //const [stateData, setstateData] = useState(props.lanes);
  const [laneId, setLaneId] = useState(null);
  const [eventBus, setEventBus] = useState(undefined);
  const components = {
    Card: MyCard,
    NewCardForm: CardForm,
  };

  /* state = {
        boardData: boardDetails
    } */
  /* var eventBus = undefined;

    const setEventBus = (handle) => {
        eventBus = handle;
        console.log('Event', handle);
    } */

  useEffect(() => {
    props.getUserTasks();
    if (props.lanes.lanes.length !== 0) {
      props.sendUpdateboardDetails(props.lanes.lanes);
      //setBoardData(props.lanes);
    }
  }, [props.getUserTasks]);

  useEffect(() => {
    if (props.lanes.lanes.length !== 0) {
      props.sendUpdateboardDetails(props.lanes.lanes);
      setBoardData({ lanes: props.lanes.lanes });
    }
  }, [props.lanes.lanes.length]);

  useEffect(() => {
    if (props.lanes.task.length !== 0) {
      //props.sendUpdateboardDetails(props.lanes.lanes);
      //console.log(props.lanes.lanes);

      let card = props.lanes.task;
      card.laneId = String(card.column_id);
      card.label = '1 second';
      //console.log(card);
      const lanes = boardData.lanes.map((data) => {
        if (data.column_id == card.column_id) {
          data.cards.push(card);
        }
        return data;
      });
      setBoardData({ lanes: lanes });
      props.sendUpdateboardDetails(lanes);
      if (card.notification_id == null) {
        addCardToLane(card.column_id, card);
      }
      props.updateTaskState();
      //props.updatedAssignCard(card, card.column_id);
      /* eventBus.publish({type: 'ADD_CARD',
        laneId:card.column_id,
        cards: {
          id: card.id, title: card.title, label: "1 sec",
          description: card.description
        }
      }); */
      //
    }
  }, [props.lanes.task.length]);

  const onCardDelete = (cardId, laneId) => {
    //console.log(boardData);
    props.deleteTask(cardId, laneId);
    eventBus.publish({
      type: 'REMOVE_CARD',
      laneId: laneId,
      cardId: cardId,
    });

    /* lanes =  boardData.lanes.filter((lane) => {
            return (lane.id != action.laneId)
        }); */

    const result = boardData.lanes.map((lane) => ({
      ...lane,
      cards: lane.cards.filter((card) => card.id != cardId),
    }));
    props.sendUpdateboardDetails(result);
    setBoardData({ lanes: result });
    //console.log(result);
  };

  const onLaneAdd = (params) => {
    //console.log(params);
    props.addColumn(params);
  };

  const onLaneDelete = (laneId) => {
    if (laneId) {
      props.deleteColumn(laneId);
    }
  };

  const handleLaneDragStart = (laneId) => {
    if (laneId) setLaneId(laneId);
  };

  const handleLaneDragEnd = (removedIndex, addedIndex, payload) => {
    props.updateColumnOrder(removedIndex, addedIndex, payload);
  };
  const handleDragEnd = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails,
  ) => {
    props.arrangeTask(
      cardId,
      sourceLaneId,
      targetLaneId,
      position,
      cardDetails,
    );
  };

  const addCardToLane = (laneId, cards) => {
    console.log('called', cards);
    if (cards !== undefined) {
      eventBus.publish({
        type: 'ADD_CARD',
        laneId: laneId,
        card: {
          id: cards.id,
          title: cards.title,
          label: '0 second',
          description: cards.description,
          task_order: cards.task_order,
          laneId: cards.column_id,
        },
      });
    }
  };

  const onCardAdd = (card, laneId) => {
    //console.log(card, laneId);
    card.column_id = laneId;
    card.notification_id = '';
    props.createTasks(card, laneId, addCardToLane);
    eventBus.publish({
      type: 'REMOVE_CARD',
      laneId: laneId,
      cardId: card.id,
    });
  };

  /* if(props.lanes.length > 0){
        setBoardData(props.lanes);
    } */

  /* useEffect(() => {
        if(props.lanes.lanes){
            setBoardData(props.lanes.lanes);
        }
    }, [props.lanes]); */

  //console.log(props.lanes);
  /* if (props.lanes.lanes.length !== 0) {
        setBoardData(props.lanes.lanes);
    } */
  //console.log(props.lanes.lanes);
  /* componentDidMount(){
        //console.log("original", this.state.boardData)
        this.props.getUserTasks();
        console.log(this.props.lanes);
        this.props.sendUpdateboardDetails(this.state.boardData.lanes);
    } */

  /* static getDerivedStateFromProps(newProps, prevState){
        console.log("newProps", newProps);
        console.log("prevState", prevState);
        if(newProps.updatedBoardData && newProps.cardId){
            let lanes = prevState.boardData;
            //lanes.lanes.push(newProps.updatedBoardData);
            console.log("lanes", lanes);
            // this.setState({
            //    boardData: lanes
            //})
            return {boardData : lanes};

        }
        else
        return null;
    } */
  //console.log(boardData);

  //render(){
  return (
    <Board
      id="board1"
      components={components}
      style={{
        background: '#F1F2F4',
      }}
      data={boardData}
      draggable
      handleLaneDragStart={handleLaneDragStart}
      handleLaneDragEnd={handleLaneDragEnd}
      canAddLanes
      onLaneAdd={onLaneAdd}
      onLaneDelete={onLaneDelete}
      handleDragEnd={handleDragEnd}
      onCardDelete={onCardDelete}
      onCardAdd={onCardAdd}
      //editLaneTitle
      editable
      eventBusHandle={setEventBus}
    />
  );
  //}
};

TaskBoard.propTypes = {
  auth: PropTypes.object.isRequired,
  getUserTasks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lanes: state.task,
});

const mapDispatchToProps = {
  getUserTasks,
  createTasks,
  deleteTask,
  updateColumnOrder,
  arrangeTask,
  addColumn,
  deleteColumn,
  updateTaskState,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskBoard);
