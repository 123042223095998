/* eslint-disable react/display-name */
import React, {
  Component,
  useState,
  useEffect,
  forwardRef,
  useMemo,
} from 'react';
import { BrowserRouter as NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchLeads, updateColumns } from '../actions/lead';
import { createTab, updateTabFilters } from '../actions/tabs';
import { fetchUsersTeams } from '../actions/users';
import MuiDataTable from '../controlled-component/MuiDataTable';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress } from '@material-ui/core';
import { debounceSearchRender } from '../controlled-component/component/NewDebounceSearchRender';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
/* import { useForm, Controller } from 'react-hook-form'; */
import SaveViews from '../controlled-component/grid-component/SaveViews';
import OfficeCustomToolbar from '../Offices/OfficesGrid/OfficeCustomToolbar';
import CustomDataGrid from '../controlled-component/customGridComponent';
import LoadingTableBody from '../components/LoadingTableBody';
import TableViewCol from '../controlled-component/grid-component/TableViewCol';
import TableToolbar from '../controlled-component/grid-component/TableToolbar';
import TableFilter from '../controlled-component/grid-component/TableFilter';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const GridData = (props) => {
  const { tabvalue, history, setTabvalue } = props;

  useEffect(() => {
    setIsLoading(true);
    props.fetchLeads(tabvalue);
    props.fetchUsersTeams();
  }, [props.fetchLeads, props.fetchUsersTeams, tabvalue]);

  /* const { register, control, errors } = useForm(); */

  const classes = useStyles();
  const [leadrecords, setLeadrecords] = useState([]);
  const [facetfields, setFacetfields] = useState([]);
  const [facetdata, setFacetdata] = useState([]);
  const [userrecords, setUserrecords] = useState([]);
  const [counts, setCounts] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [tabfilters, setTabFilters] = useState([]);
  const [gridparams, setGridparams] = useState([]);
  const [tabparams, setTabparams] = useState([]);
  const [tab, setTab] = React.useState(null);

  useEffect(() => {
    setLeadrecords(props.lead.leads);
    setCounts(props.lead.counts);
    props.setCounts(props.lead.counts);
    setFacetfields(props.lead.facet_fields);
    setFacetdata(props.lead.facet_data);
    if (Object.keys(filters).length === 0 || tab != tabvalue) {
      setTabFilters(props.lead.selected_filters);
      setFilters(props.lead.selected_filters);
      setGridparams(props.lead.grid_params);
      setTabparams(props.lead.grid_params);
      setTab(tabvalue);
    }
    setIsLoading(props.lead.loading);
  }, [props.lead.leads]);

  useEffect(() => {
    setUserrecords(props.users.users);
  }, [props.users.users]);

  /**
   * Handle function for changes like sorting, searching
   * @param {*} params
   */
  const handleGridChange = (params) => {
    setIsLoading(true);
    props.fetchLeads(tabvalue, params, filters);
  };

  /**
   * Handle function of grid column changes
   * @param {*} paramColumns
   */
  const handleColumnChange = (paramColumns) => {
    //setIsLoading(true);
    props.updateColumns(props.tabvalue, paramColumns);
  };

  /**
   * Handle reset button of save views
   * @param {*} popupclose
   */
  const handleResetFilters = () => {
    setFilters(tabfilters);
    setGridparams(tabparams);
    setIsLoading(true);
    props.fetchLeads(tabvalue, tabparams, tabfilters);
  };

  /**
   * Function to handle save new view of this grid
   * @param {*} values
   */
  const handleSaveView = (values, handleClose) => {
    const params = {
      ...values,
      gridParams: gridparams,
      filters: filters,
    };
    props.createTab(params, history, handleClose, setTabvalue);
  };

  /**
   * Handle function of update tab filters
   * @param {*} handleClose
   */
  const handleUpdateTabFilters = (handleClose) => {
    const params = {
      gridParams: gridparams,
      filters: filters,
    };
    props.updateTabFilters(tabvalue, params, handleClose);
  };

  const columns = [
    {
      label: 'Job Title',
      name: 'job_title',
      options: {
        filter: false,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          let rowData = leadrecords[dataIndex];
          return (
            <div>
              <Link to="#" className="text-truncate">
                {rowData.job_title}
              </Link>
              <span className="campaignCount">({rowData.job_id})</span>
            </div>
          );
        },
      },
    },
    {
      label: 'Consultant',
      name: 'sales_consultant_name',
      options: {
        filter: false,
        sort: true,
        width: '200px',
      },
    },
    {
      label: 'DM',
      name: 'linemanager',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: 'Created Type',
      name: 'lead_type',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: 'Current Type',
      name: 'type',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: 'City',
      name: 'city',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: 'Last Activity',
      name: 'last_activity',
      options: {
        filter: false,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          let rowData = leadrecords[dataIndex];
          return <Moment format="Do MMM YYYY">{rowData.last_activity}</Moment>;
        },
      },
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: '50px', maxWidth: '150px' },
        }),
      },
    },
    {
      label: 'Created Date',
      name: 'created_date',
      options: {
        filter: false,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          let rowData = leadrecords[dataIndex];
          return <Moment format="Do MMM YYYY">{rowData.last_activity}</Moment>;
        },
      },
    },
    {
      label: 'POC',
      name: 'notes_count',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: '50px', maxWidth: '100px' },
        }),
      },
    },
    {
      label: null,
      name: 'actions',
      options: {
        filter: false,
        setCellProps: () => ({
          style: { minWidth: '50px', maxWidth: '120px' },
        }),
        customBodyRenderLite: (dataIndex) => {
          //let rowData = leadrecords[dataIndex];
          return (
            <>
              <button
                data-target="addLead"
                type="button"
                className="ml-3 add_user_button btn btn-primary"
              >
                Add to lead
              </button>
            </>
          );
        },
      },
    },
  ];

  /**
   * function to get data from backend based on filter selected
   * @param {*} filterprops
   */
  const filterGridData = (filterprops) => {
    //console.log(filterprops);
    setIsLoading(true);
    props.fetchLeads(tabvalue, gridparams, filterprops);
  };

  /**
   * this function called on onChange filter of grid
   * It call the function which pull grid data based on filter selected
   * @param {*} changedColumn
   * @param {*} filterList
   * @param {*} type
   * @param {*} changedColumnIndex
   * @param {*} displayData
   */
  /* // eslint-disable-next-line prettier/prettier */
  const onFilter = (
    changedColumn,
    filterList,
    type,
    changedColumnIndex,
    displayData,
  ) => {
    if (filterList[changedColumnIndex].length === 0) {
      //check if changed index has no selected value then remove that index from filter
      let filter = Object.keys(filters)
        .filter((key) => key !== changedColumn)
        .reduce((obj, key) => {
          obj[key] = filters[key];
          return obj;
        }, {});
      setFilters(filter);
      filterGridData(filter);
    } else {
      //if changed index has value
      setFilters({
        ...filters,
        [changedColumn]: filterList[changedColumnIndex],
      });
      filterGridData({
        ...filters,
        [changedColumn]: filterList[changedColumnIndex],
      });
    }
  };

  /**
   * This is options section for this MUI-datatable grid
   */
  //console.log(gridparams);
  const options = {
    filterType: 'custom',
    draggableColumns: {
      enabled: true,
    },
    search: false,
    print: false,
    searchOpen: true,
    selectToolbarPlacement: 'above',
    searchPlaceholder: 'Search name, phone, email addresses, or company',
    searchText: gridparams?.searchText ? gridparams.searchText : null,
    customSearchRender: debounceSearchRender(400),
    responsive: 'standard',
    /* responsive: 'scroll', */
    jumpToPage: true,
    serverSide: true,
    count: counts,
    onTableChange: (action, tableState) => {
      //console.log(action, tableState);
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case 'changePage':
        case 'sort':
        case 'changeRowsPerPage':
        case 'search':
          setIsLoading(true);
          // eslint-disable-next-line no-case-declarations
          let sortOrder =
            Object.keys(tableState.sortOrder).length === 0
              ? null
              : tableState.sortOrder;
          // eslint-disable-next-line no-case-declarations
          const params = {
            page: tableState.page,
            sortOrder: sortOrder,
            rowsPerPage: tableState.rowsPerPage,
            searchText: tableState.searchText,
          };
          handleGridChange(params);
          //setGridparams(params);
          setGridparams({
            ...gridparams,
            page: tableState.page,
            sortOrder: sortOrder,
            rowsPerPage: tableState.rowsPerPage,
            searchText: tableState.searchText,
          });
          //this.changePage(tableState.page, tableState.sortOrder);
          //props.onTableChange(params);
          break;
        /* case 'sort':
          //this.sort(tableState.page, tableState.sortOrder);
          break; */
        case 'viewColumnsChange':
        case 'columnOrderChange':
          // eslint-disable-next-line no-case-declarations
          const paramColumns = {
            columns: tableState.columns,
            columnsOrder: tableState.columnOrder,
          };
          setGridparams({
            ...gridparams,
            columns: tableState.columns,
            columnsOrder: tableState.columnOrder,
          });
          handleColumnChange(paramColumns);
          break;
        default:
        //console.log('action not handled.');
      }
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    /* confirmFilters: true, */

    // Calling the applyNewFilters parameter applies the selected filters to the table
    /* customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
      <div style={{ marginTop: '40px' }}>
          <Button variant="contained" onClick={() =>
            console.log(currentFilterList,applyNewFilters)}>Apply Filters</Button>
      </div>
      );
    }, */
    customFilterListOptions: {
      render: (v) => v.map((l) => l.toUpperCase()),
      update: (filterList, filterPos, index) => {
        filterList[index].splice(filterPos, 1);
        return filterList;
      },
    },
    /* filterList:[], */
    filterOptions: facetfields,
    userrecords: userrecords,
    facetdata: facetdata,
    customFilterList: filters,
    gridParams: gridparams,
    selectedFilter: Object.keys(filters).length,
    onFilterChange: onFilter,
    customToolbar: () => {
      return (
        <SaveViews
          handleSave={handleSaveView}
          handleResetFilters={handleResetFilters}
          handleUpdateTabFilters={handleUpdateTabFilters}
          tabvalue={tabvalue}
          object={`lead`}
          {...props}
        />
      );
    },

    selectableRows: 'none',
    filter: true,
    download: true,
    pagination: true,
    viewColumns: true,
  };

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={isLoading} {...props} />,
    [isLoading],
  );

  return (
    <>
      <CustomDataGrid
        paperWidth={'60px'}
        columns={columns}
        data={leadrecords}
        options={options}
        count={counts}
        facetfields={facetfields}
        components={{
          TableBody: BodyComponent,
          TableToolbar: TableToolbar,
        }}
      />

      {/*<MuiDataTable*/}
      {/*  title={``}*/}
      {/*  data={leadrecords}*/}
      {/*  columns={columns}*/}
      {/*  options={options}*/}
      {/*  count={counts}*/}
      {/*  facetfields={facetfields}*/}
      {/*/>*/}
    </>
  );
};

/* const GridActions = (props) => {
    return (
        <React.Fragment>
            <Dropdown className="d-inline-block">
                <Dropdown.Toggle id="add-actions" variant="link btn-actions">
                <i className="fa fa-caret-down"></i></Dropdown.Toggle>
                <Dropdown.Menu alignRight>
                    <Dropdown.Item eventKey="1" data-target="EditTemplate" >Edit</Dropdown.Item>
                    <Dropdown.Item eventKey="3" data-target="changeTempStatus" >Change Status</Dropdown.Item>
                    <Dropdown.Item eventKey="4" data-target="shareTemplate" >Share</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
} */

GridData.propTypes = {
  auth: PropTypes.object.isRequired,
  fetchLeads: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lead: state.lead,
  users: state.users,
});

const mapDispatchToProps = {
  fetchLeads,
  fetchUsersTeams,
  updateColumns,
  createTab,
  updateTabFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(GridData);
