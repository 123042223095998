import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem, Typography, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';

// import CreateTemplate from './CreateTemplate';
import CreateTemplate from './CreateTemplate/CreateTemplate';
// import EditTemplate from './EditTemplate';
import PageHeader from '../components/PageHeader';
import CustomDataGrid from '../controlled-component/customGridComponent';
import TemplateCustomToolbar from './TemplateCustomToolbar';
import CloneTemplate from './CloneTemplate';
import LoadingTableBody from '../components/LoadingTableBody';
import { ActiveIcon, InActiveIcon } from '../components/GridIcons';

import { getJobSpecTemplate, updateTemplateStatus } from '../actions/template';

import 'react-toastify/dist/ReactToastify.css';

// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  OfficeGridWrapper: {
    padding: '10px 0 20px 0',
  },
}));

const TemplateGrid = (props) => {
  const classes = useStyles();

  const [popupToggle, setPopupToggle] = useState(false);
  const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [templateId, setTemplateId] = useState(false);
  const [clonePopupToggle, setClonePopupToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const template = useSelector((state) => state.template);

  const handlePopupOpen = () => {
    setPopupToggle(true);
    setEditPopupToggle(true);
  };

  const handleClosePopupOpen = () => {
    setPopupToggle(false);
    setEditPopupToggle(false);
    setClonePopupToggle(false);
    setTemplateId(false);
  };

  // handle the edit pop up
  const handleEditPopupOpen = (id) => {
    /* setClonePopupToggle(!clonePopupToggle);
        setSequenceId(id);
        setName(name); */
    setEditPopupToggle(!editPopupToggle);
    setTemplateId(id);
  };

  // update status with reducer
  const updateStatus = (templateId, statusText) => {
    const status = statusText === 'Un-Published' ? 1 : 2;
    dispatch(
      updateTemplateStatus(
        templateId,
        status,
        'job_spec_grid',
        props.admin ? props.admin : null,
      ),
    );
  };

  const getTemplate = async () => {
    await dispatch(getJobSpecTemplate(null, props.admin ? props.admin : null));
    setIsLoading(false);
  };

  useEffect(() => {
    // did mount
    // call the template api and set data in reducer
    getTemplate();
  }, []);

  const handleClonePopupOpen = (id) => {
    setTemplateId(id);
    setClonePopupToggle(true);
  };

  const handleNameClick = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    handleEditPopupOpen(id);
  };

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={isLoading} {...props} />,
    [isLoading],
  );

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Template Name',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = template.templates[dataIndex];
            return (
              <Tooltip title={rData.name} arrow>
                <div
                  className={`sort-by td-content-ellipsis td-anchor-content-ellipsis `}
                  onClick={(e) => handleNameClick(e, rData.id)}
                >
                  {rData.name}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: 'subject',
        label: 'Subject',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value} arrow>
                <div className={`td-content-ellipsis`}>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: 'total_delivered',
        label: 'Delivered',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        name: 'total_opened',
        label: 'Opened',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        name: 'total_clicked',
        label: 'Clicked',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        name: 'total_bounces',
        label: 'Bounced',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        name: 'created_by_user',
        label: 'Owner',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value} arrow>
                <div className={`td-content-ellipsis`}>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: 'created_at',
        label: 'Created Date',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      {
        name: 'is_published',
        label: 'Status',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return value === 'Un-Published' ? (
              <InActiveIcon fontSize="small" />
            ) : (
              <ActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = template.templates.find((tData) => tData.id === value);
            return (
              <TemplateGridActions
                temp_id={row?.id}
                handleEditPopupOpen={handleEditPopupOpen}
                handleClonePopupOpen={handleClonePopupOpen}
                status={row?.is_published}
                row={row}
                updatestatus={updateStatus}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [template.templates],
  );

  return (
    <div className={classes.templateGridMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Spec Job Templates'}
        subTitle={
          'Set up and manage Spec Job templates for all users, to help increase their efficiency.'
        }
        itemCount={template?.templates?.length || 0}
      />

      <div className={classes.OfficeGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns}
          data={template.templates}
          options={{
            pagination: Boolean(template?.templates?.length),
            selectableRows: 'multiple',
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search Templates',
            searchOpen: true,
            search: true,
            selectToolbarPlacement: 'none',
            // to hide the delete button
            customToolbarSelect: () => {},
            //selectableRowsHideCheckboxes: true,
            //selectableRowsHeader: false,
            customToolbar: () => {
              // Toolbar for search and add
              return (
                <TemplateCustomToolbar addAddTemplateClick={handlePopupOpen} />
              );
            },
          }}
          components={{ TableBody: BodyComponent }}
        />

        {/*spec job tempalte edit and create */}
        <CreateTemplate
          openDrawer={popupToggle || editPopupToggle || clonePopupToggle}
          templateId={templateId}
          onClose={handleClosePopupOpen}
          history={props.history}
          cloneTemplate={clonePopupToggle}
          admin={props.admin ? props.admin : false}
          page={'grid'}
          {...props}
        />

        {/*<EditTemplate*/}
        {/*  temp_id={templateId}*/}
        {/*  modalState={editPopupToggle}*/}
        {/*  onHide={handleEditPopupOpen}*/}
        {/*  history={props.history}*/}
        {/*  admin={props.admin ? props.admin : false}*/}
        {/*  getRecords={dispatch(getJobSpecTemplate)}*/}
        {/*  {...props}*/}
        {/*/>*/}

        {/*<CloneTemplate*/}
        {/*  temp_id={templateId}*/}
        {/*  modalState={clonePopupToggle}*/}
        {/*  onHide={handleClonePopupOpen}*/}
        {/*  history={props.history}*/}
        {/*  getRecords={dispatch(getJobSpecTemplate)}*/}
        {/*  {...props}*/}
        {/*/>*/}
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
};

// custom grid action menu
const TemplateGridActions = (props) => {
  const { auth, row } = props;
  const { user } = auth;
  const admin = props.admin ? props.admin : false;
  const [anchorElement, setAnchorElement] = useState(null);

  const editable = user.id === row.created_by;
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      case 'changeTempStatus': {
        props.updatestatus(props.temp_id, props.status);
        break;
      }
      case 'cloneTemplate': {
        props.handleClonePopupOpen(props.temp_id);
        break;
      }
      default:
        break;
    }
  };

  const menuItem =
    editable || admin
      ? [
          {
            title: 'Edit',
            itemType: 'editPopUp',
          },
          {
            title: 'Change Status',
            itemType: 'changeTempStatus',
          },
          {
            title: 'Clone',
            itemType: 'cloneTemplate',
          },
        ]
      : [
          {
            title: 'Clone',
            itemType: 'cloneTemplate',
          },
        ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default TemplateGrid;
