import {
  LOAD_CATEGORIES,
  CATEGORIES_ERROR,
  CHANGE_CATEGORY_STATUS,
  REMOVE_CATEGORY,
} from '../../actions/types';

const initialState = {
  categories: [],
  cat_id: null,
  loading: true,
  rowsPerPage: 25,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_CATEGORIES: {
      const data = {
        ...state,
        categories: payload,
        loading: false,
        rowsPerPage: payload.rowsPerPage,
      };
      return data;
    }

    case CATEGORIES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
