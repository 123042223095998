import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  radioGroup: {},
  controlLabel: {
    marginBottom: 0,
    '& .MuiIconButton-label': {},
  },
  radioChecked: {
    color: `${custom.colorCode.blueLightShade8} !important`,
  },
}));

export default useStyles;
