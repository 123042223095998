import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dropdown } from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import GridAllTabsPopup from './GridAllTabsPopup';
import MainTabs from '../../components/MainTabs';

const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  OfficeGridWrapper: {
    padding: '10px 0 20px 0',
  },
  leadsGridTabs: {
    margin: 0,
  },
  mainLeadsTabWrapper: {
    width: 'max-content',
  },
}));

const GridTabs = (props) => {
  const {
    labelId,
    onChange,
    selectionFollowsFocus,
    value,
    object,
    tabs,
  } = props;

  const classes = useStyles();

  const tabsdata = props.tabsdata ? props.tabsdata : null;
  if (tabsdata.length > 0) {
    //console.log(tabs);
    //check selected tab in first 5 tabs
    const selectedTab = tabsdata.find(
      (element) => element.id === Number(value),
    );
    //if selected tab not found in first 5 tabs then remove last tab and add selected tab
    if (selectedTab === undefined) {
      const selectedTab = tabs.tabs.find(
        (element) => element.id === Number(value),
      );
      tabsdata.pop();
      tabsdata.push(selectedTab);
    }
  }

  const handleTabActionClick = (event) => {
    event.preventDefault();
    console.log(event);
  };

  const tabitems = tabsdata.map(function (tab) {
    /* let selected = (value === tab.id) ? true: false;*/
    return (
      <Tab
        value={tab.id}
        label={tab.name}
        key={tab.id}
        index={tab.id}
        /* icon={<TabActions onClick={(event) => handleTabActionClick(event)} />}
        component={'a'}
        wrapped */
      />
    );
  });

  return (
    <>
      <div className={classes.mainLeadsTabWrapper}>
        <MainTabs
          tabsOptions={tabsdata}
          tabIndex={value}
          onTabChange={onChange}
          tabLabel="name"
          tabKey="id"
          className={classes.leadsGridTabs}
          byValue
        />
      </div>
      <div>
        <GridAllTabsPopup object={object} tabs={tabs.tabs} {...props} />
      </div>
    </>
  );
};

const TabActions = (props) => {
  return (
    <React.Fragment>
      <Dropdown className="d-inline-block">
        <Dropdown.Toggle id="add-actions" variant="link">
          <ExpandMoreIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>
          <Dropdown.Item eventKey="1" data-target="EditTemplate">
            Pin
          </Dropdown.Item>
          <Dropdown.Item eventKey="3" data-target="changeTempStatus">
            Remove
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
}

export default GridTabs;
