import React from 'react';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {},
  paper: {
    width: 620,
  },
});

// custom drawer to render dynamic component
// Note:- if yor using tiny editor in drawer this 2 prop is imp disableEnforceFocus={true} and disableAutoFocus={true}
const CustomDrawer = ({ classes, children, ...restProps }) => {
  const drawerClasses = useStyles();
  return (
    <Drawer
      anchor={'right'}
      classes={{
        root: drawerClasses.root,
        paper: drawerClasses.paper,
        ...classes,
      }}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      {...restProps}
    >
      {/*dynamic component*/}
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
