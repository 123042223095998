import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import PageHeader from '../../components/PageHeader';
import CustomDataGrid from '../../controlled-component/customGridComponent';
import AddButton from '../../components/CreateToolbarButton';
import AddTeamDrawer from './AddTeamDrawer';
import LoadingTableBody from '../../components/LoadingTableBody';
import { ActiveIcon, InActiveIcon } from '../../components/GridIcons';

import { fetchTeams, updateStatus } from '../../actions/admin/teams';
import { makeProperNamingStatement } from '../../utils';

import 'react-toastify/dist/ReactToastify.css';


// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  OfficeGridWrapper: {
    padding: '10px 0 20px 0',
  },
}));

const TeamGrid = (props) => {
  const classes = useStyles();

  const [popupToggle, setPopupToggle] = useState(false);
  const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [teamId, setTeamId] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const teams = useSelector((state) => state.teams);

  //handle function to open add team popup
  const handlePopupOpen = () => {
    setTeamId(false);
    setPopupToggle(!popupToggle);
  };

  //handle function to close add team popup
  const handlePopupClose = () => {
    setPopupToggle(false);
    setTeamId(false);
  };

  // handle the edit user pop up
  const handleEditPopupOpen = (id) => {
    /* setEditPopupToggle(!editPopupToggle); */
    setPopupToggle(!popupToggle);
    setTeamId(id);
  };

  // update status with reducer
  const changeStatus = (teamId, status) => {
    //const status = statusText === 'Un-Published' ? 1 : 2;
    dispatch(updateStatus(teamId, !status, showSnackbar));
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };
  /**
   * Handle function to close the toaster popup
   */
  const showSnackbar = () => {
    setOpenSnackBar(true);
  };

  useEffect(() => {
    // did mount
    // call the team api and set data in reducer
    dispatch(fetchTeams());
  }, []);

  /**
   * Function to handle click on name
   * @param {*} e
   * @param {*} data
   */
  const handleNameClick = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    handleEditPopupOpen(data.id);
  };

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'team_name',
        label: 'Team Name',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = teams.teamList[dataIndex];
            return (
              <Tooltip title={rData.team_name || ''} arrow>
                <div
                  className={`sort-by td-content-ellipsis td-anchor-content-ellipsis`}
                  onClick={(e) => handleNameClick(e, rData)}
                >
                  {rData.team_name}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Team Leader',
        name: 'team_lead_name',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value || ''} arrow>
                <div className={`td-content-ellipsis`}>{makeProperNamingStatement(value || '')}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Parent Team',
        name: 'parent_team_name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: 'Users',
        name: 'users',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return value ? (
              <ActiveIcon fontSize="small" />
            ) : (
              <InActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = teams.teamList.find((tData) => tData.id === value);
            return (
              <GridActions
                temp_id={row?.id}
                handleEditPopupOpen={handleEditPopupOpen}
                status={row?.status}
                row={row}
                updatestatus={changeStatus}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [teams.teamList],
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={teams.loading} {...props} />,
    [teams.loading],
  );

  return (
    <div className={classes.templateGridMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Teams'}
        subTitle={'Create, edit and remove teams from your account'}
        itemCount={teams?.teamList?.length || 0}
      />
      <div className={classes.OfficeGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns}
          data={teams.teamList}
          options={{
            pagination: Boolean(teams?.teamList?.length),
            selectableRows: 'multiple',
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search Teams',
            selectToolbarPlacement: 'none',
            searchOpen: true,
            search: true,
            customToolbar: () => {
              // Toolbar for search and add
              return (
                <AddButton
                  handleClick={handlePopupOpen}
                  title={`Create Team`}
                  dataTarget={`createTeam`}
                />
              );
            },
          }}
          components={{ TableBody: BodyComponent }}
        />
        {/* Loader on grid */}
        {/* <Loader open={teams?.loading} /> */}
        {/* Add/Edit team popup */}
        <AddTeamDrawer
          teamId={teamId}
          openDrawer={popupToggle}
          onClose={handlePopupClose}
        />
        {/* Toaster if any changes in grid */}
        <Snackbar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleSnackbarClose}
            severity="success"
          >
            Team status changed
          </Alert>
        </Snackbar>
        {/* <ToastContainer autoClose={2000} /> */}
      </div>
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const { auth, row } = props;
  const { user } = auth;
  const admin = props.admin ? props.admin : false;
  const [anchorElement, setAnchorElement] = useState(null);

  //const editable = user.id === row.created_by;
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      case 'changeStatus': {
        props.updatestatus(props.temp_id, props.status);
        break;
      }
      default:
        break;
    }
  };

  let menuItem = [
    {
      title: 'Change Status',
      itemType: 'changeStatus',
    },
  ];

  if (row.parent_team_name) {
    menuItem = [{ title: 'Edit', itemType: 'editPopUp' }, ...menuItem];
  }

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default TeamGrid;
