import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  addButton: {
    color: custom.colorCode.white,
    backgroundColor: custom.colorCode.blueLightShade10,
    border: `solid 1px ${custom.colorCode.blueLightShade10}`,
    height: 35,
    minWidth: 80,
    padding: '0 20px',
    fontSize: 13,
    root: {
      backgroundColor: 'green',
    },
    '&:hover': {
      border: `solid 1px ${custom.colorCode.blueLightShade11} !important`,
      backgroundColor: custom.colorCode.blueLightShade11,
    },
    '&:focus': {
      outline: `none`,
    },
  },
}));

const OfficeCustomToolbar = (props) => {
  const { addOfficeClick } = props;
  const classes = useStyles();

  const handleClick = (event) => {
    addOfficeClick(event);
  };

  return (
    <React.Fragment>
      <Tooltip title={'Create Office'}>
        <Button
          data-target="addOffice"
          onClick={handleClick}
          className={`ml-3 ${classes.addButton}`}
        >
          Create office
        </Button>
      </Tooltip>
    </React.Fragment>
  );
};

export default OfficeCustomToolbar;
