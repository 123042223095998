import React from 'react';
import MuiButton from '@material-ui/core/Button';
import MuiIconButton from '@material-ui/core/IconButton';

import useStyles from './style';

const Button = ({ children, className, dataTarget = '', ...restProps }) => {
  const classes = useStyles();

  return (
    <MuiButton
      className={`${classes.mainButton} ${className}`}
      variant="contained"
      classes={{
        label: classes.mainButtonLabel,
      }}
      {...restProps}
    >
      {children}
    </MuiButton>
  );
};

export default Button;

export const OutlinedButton = ({ children, className, dataTarget = '', ...restProps }) => {
  const classes = useStyles();

  return (
    <MuiButton
      className={`${classes.mainOutlinedButton} ${className}`}
      variant="contained"
      classes={{
        label: classes.mainOutlinedButtonLabel,
      }}
      {...restProps}
    >
      {children}
    </MuiButton>
  );
};

export const IconButton = ({ children, className = '', ...restProps }) => {
  const classes = useStyles();

  return (
    <MuiIconButton
      className={`${classes.iconButton} ${className}`}
      variant="contained"
      {...restProps}
    >
      {children}
    </MuiIconButton>
  );
};
