import React from 'react';
import { Box, Typography } from '@material-ui/core';

import Checkbox from '../../../components/CheckboxLabel';
import EditSortableColumn from './EditSortableColumn';

import useStyles from './ColumnContain.style';

const ColumnContainGrid = ({
  columns,
  columnOrder,
  onColumnVisibility,
  onColumnReorder,
  hideColumn,
}) => {
  const classes = useStyles();

  const handleCheckChange = (event) => {
    onColumnVisibility(event);
  };

  return (
    <Box className={classes.root}>
      <Box width={0.5} className={`${classes.wrappers} ${classes.leftWrapper}`}>
        <Typography variant="body2" gutterBottom paragraph>
          Choose columns to show.
        </Typography>

        <Box className={classes.checkBoxWrapper}>
          {columns
            .filter((col) => col.viewColumns)
            .map((col, index) => (
              <Checkbox
                key={`columns-col-${col.name}-${index}`}
                handleChange={handleCheckChange}
                name={col.name}
                label={col.label}
                checked={String(col.display) == 'true' ? true : false}
                disabled={col.draggable === false}
                checkboxProps={classes.checkBoxCheckedGrid}
                classes={{
                  root: classes.checkBoxLabel,
                }}
              />
            ))}
        </Box>
      </Box>

      <Box
        width={0.5}
        className={`${classes.wrappers} ${classes.rightWrapper}`}
      >
        <Typography variant="body2" gutterBottom paragraph>
          Drag and drop to change column order.
        </Typography>
        {Boolean(columns.length) && (
          <EditSortableColumn
            columns={columns}
            columnOrder={columnOrder}
            onColumnReorder={onColumnReorder}
            hideColumn={hideColumn}
          />
        )}
      </Box>
    </Box>
  );
};

export default ColumnContainGrid;
