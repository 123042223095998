import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

import api from '../../../utils/api';
import Spinner from '../../../layout/Spinner';

const useStyles = makeStyles(({ custom }) => ({
  typographyText: {
    color: custom.colorCode.lightGreyColorShade16,
  },
}));

const ContactDetail = ({ taskData }) => {
  const classes = useStyles();

  const [displayData, setDisplayData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isEmpty(displayData)) {
      setLoading(true);
      if (taskData.dmId) {
        api
          .get(`/dm/get-contact-detail/${taskData.dmId}`)
          .then(({ data }) => {
            setDisplayData({
              workEmail: data?.emailData?.work_email || '',
              personalEmail: data?.emailData?.personal_email || '',
              mobileNo: data?.phoneData?.personalNumber || '',
              homeNo: data?.phoneData?.homeNumber || '',
              officeNo: data?.phoneData?.officeNumber || '',
            });
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        api
          .get(`/candidate/get-contact-detail/${taskData.candidateId}`)
          .then(({ data }) => {
            setDisplayData({
              workEmail: data.emailData.work_email,
              personalEmail: data.emailData.email,
              mobileNo: data.phoneData.phone,
              officeNo: data.phoneData.office,
              homeNo: data.phoneData.home,
            });
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner style={{ width: 20, height: 20, position: 'unset' }} />
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4}>
          <Typography variant="body2" className={classes.typographyText}>
            Mobile
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          {displayData.mobileNo && (
            <a
              href={`tel:${displayData.mobileNo}`}
              style={{ cursor: 'pointer' }}
            >
              <Typography variant="body2">{displayData.mobileNo}</Typography>
            </a>
          )}
        </Grid>

        <Grid item xs={4} sm={4}>
          <Typography variant="body2" className={classes.typographyText}>
            Office
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          {displayData.officeNo && (
            <a
              href={`tel:${displayData.officeNo}`}
              style={{ cursor: 'pointer' }}
            >
              <Typography variant="body2">{displayData.officeNo}</Typography>
            </a>
          )}
        </Grid>

        <Grid item xs={4} sm={4}>
          <Typography variant="body2" className={classes.typographyText}>
            Home
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          {displayData.homeNo && (
            <a href={`tel:${displayData.homeNo}`} style={{ cursor: 'pointer' }}>
              <Typography variant="body2">{displayData.homeNo}</Typography>
            </a>
          )}
        </Grid>

        <Grid item xs={4} sm={4}>
          <Typography variant="body2" className={classes.typographyText}>
            Work Email
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          {displayData.workEmail && (
            <a
              href={`mailto:${displayData.workEmail}`}
              style={{ cursor: 'pointer' }}
            >
              <Typography variant="body2">{displayData.workEmail}</Typography>
            </a>
          )}
        </Grid>

        <Grid item xs={4} sm={4}>
          <Typography variant="body2" className={classes.typographyText}>
            Personal Email
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          {displayData.personalEmail && (
            <a
              href={`mailto:${displayData.personalEmail}`}
              style={{ cursor: 'pointer' }}
            >
              <Typography variant="body2">
                {displayData.personalEmail}
              </Typography>
            </a>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactDetail;
