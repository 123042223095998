import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  contentMainTabs: {
    margin: '15px 0 20px 0',
    '& .MuiTab-root': {},
    '& .MuiTabs-indicator': {
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '& .MuiTabs-flexContainer': {
      fontWeight: 700,
      '& .Mui-selected': {
        border: 'none !important',
        backgroundColor: 'transparent !important',
        fontWeight: 700,
        '& .MuiTab-wrapper': {
          color: custom.colorCode.blueLightShade8,
          fontWeight: 700,
        },
      },
      '& .MuiTab-wrapper': {
        fontWeight: 700,
      },
      '& .MuiButtonBase-root': {
        border: 'none !important',
        backgroundColor: 'transparent !important',
      },
      '& .Mui-selected:focus': {
        outline: 'unset',
      },
    },
    '& .MuiTabs-scroller': {
      borderBottom: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.12) !important',
    },
  },
}));

export default useStyles;
