import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import Login from './auth/Login';
import Routes from './routing/Routes';
import { LOGOUT } from './actions/types';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken, { setTenantHash } from './utils/setAuthToken';

// global material ui class
import globalUseStyles from './utils/globalStyleClasses';

import { colorCodeConstant, fontFamilyConstant } from './utils/styleConstant';

const theme = createMuiTheme({
  custom: {
    colorCode: {
      ...colorCodeConstant,
    },
    fontFamilyStyle: {
      ...fontFamilyConstant,
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  root: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  table: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
});

function App() {
  globalUseStyles(); // global css class imports in the application
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      setTenantHash(localStorage.tenantHash);
    }
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <MuiThemeProvider theme={theme}>
          <Fragment>
            {/* <SidebarNav></SidebarNav> */}
            {/* <header>
          <PrimaryHeader/>
        </header> */}

            <Switch>
              <Route exact path="/" component={Login} />
              <Route component={Routes} />
            </Switch>
          </Fragment>
        </MuiThemeProvider>
      </Router>
    </Provider>
  );
}

export default App;
