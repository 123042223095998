import React, { useEffect, useState } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import MainTabs, { TabPanel } from '../../components/MainTabs';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../layout/Spinner';
import {
  fetchSequenceCandidateTabs,
  setCurrentTab,
} from '../../actions/admin/sequencedCandidates';
import SequencedCandidateGrid from './SequencedCandidateGrid';

const useStyles = makeStyles(() => ({
  taskTabMainClass: {
    margin: 0,
  },
  mainHeader: {
    '& .heading-title': {
      marginBottom: 0,
    },
  },
}));

/**
 * Sequence caontacts/candidate tab component
 */
const SequencedCandidatesTabs = (props) => {
  const classes = useStyles();
  const [userTabIndex, setUserTabIndex] = useState(0);
  const [currentPath, setCurrentPath] = useState('');
  const [gridName, setGridName] = useState('');
  const [contactType, setContactType] = useState(2);
  const mySequencedCandidatesCount = useSelector(
    (state) => state.sequencedCandidates.count
  );
  const sequencedCandidatesTab = useSelector(
    (state) => state.sequencedCandidates.taskTab
  );
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let pathName = location.pathname;
    // match the location and set contact type (candidate or contacts)
    if (currentPath === '' || (!matchPath(pathName,'/'+currentPath) || !matchPath(pathName,'/'+currentPath))) {
      let currentLocation = 'candidates';
      let grid_name = 'Candidates';
      let contact_type = 2;
      if (matchPath(pathName,'/prospect/contacts/:id') || matchPath(pathName,'/prospect/contacts')) {
        currentLocation = 'contacts';
        grid_name = 'Contacts';
        contact_type = 1;
      }
      
      if(currentPath == '' || currentPath !== currentLocation) {
        setCurrentPath(currentLocation);
        setGridName(grid_name);
        setContactType(contact_type);
        setTabs(pathName);
      }
    }
  }, [location]);

  /**
   * Get and set tabs for current grid using tab object
   */
  const setTabs = async (url) => {
    let tabObject = 'sequence_candidates';
    if(matchPath(url,'/prospect/contacts/:id') || matchPath(url,'/prospect/contacts')) {
      tabObject = 'sequence_contacts'
    }
    const taskTabData = await dispatch(fetchSequenceCandidateTabs(tabObject));

    if (props.match.params.id) {
      const tabIndex = taskTabData.data.findIndex(
        (e) => e.url == props.match.params.id
      );
      if (tabIndex !== -1) {
        setUserTabIndex(tabIndex);
        dispatch(setCurrentTab(taskTabData.data[tabIndex]));
      }
    } else {
        const tabIndex = 0;
        setUserTabIndex(tabIndex);
        dispatch(setCurrentTab(taskTabData.data[tabIndex]));
    }
  };

  /**
   * Handle tab change event
   */
  const handleTabChange = (event, newValue) => {
    setUserTabIndex(newValue);
  };

  if (sequencedCandidatesTab.loading) {
    return <Spinner />;
  }

  return (
    <div>
      <PageHeader
        mainTitle={`Sequenced ${gridName}`}
        subTitle={null}
        showCount={false}
        itemCount={mySequencedCandidatesCount}
        className={classes.mainHeader}
      />

      <MainTabs
        tabsOptions={(sequencedCandidatesTab?.tabs || []).map(
          (tOption, index) => ({
            ...tOption,
            name: `${tOption.name}${
              userTabIndex === index ? ` (${mySequencedCandidatesCount})` : ''
            }`,
            component: Link,
            to: `/prospect/${currentPath}/${tOption.url}`,
          })
        )}
        tabIndex={userTabIndex}
        onTabChange={handleTabChange}
        className={classes.taskTabMainClass}
        tabLabel='name'
        tabKey='id'
      />

      {(sequencedCandidatesTab?.tabs || []).map((tab, index) => (
        <TabPanel
          value={userTabIndex}
          index={index}
          key={`tab-grids-${tab.id}-${tab.name}`}
        >
          <SequencedCandidateGrid {...props} tabProps={tab} gridName={gridName} contactType={contactType} />
        </TabPanel>
      ))}
    </div>
  );
};

export default SequencedCandidatesTabs;
