import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';

import Button from '../../components/Button';

const menuItem = [
  {
    title: 'Clone',
    itemType: 'copySequence',
  },
];

const ActionButtonMenu = (props) => {
  const { menusItem = [], onMenuClose } = props;

  const [anchorElement, setAnchorElement] = useState(null);
  const [menuItems, setMenuItems] = useState(menuItem);
  const menuId = 'action-menu-id';

  const {
    buttonClassName = '',
    handleEditPopupOpen = () => {},
    copySequence,
    showEditStep = true,
    publishAction = false,
    enrollSequence = () => {},
  } = props;

  useEffect(() => {
    if (publishAction) {
      const newItem = {
        title: 'Enroll',
        itemType: 'enrollSequence',
      };
      setMenuItems([...menuItems, newItem]);
    } else {
      setMenuItems([...menuItem]);
    }
  }, [publishAction]);

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        handleEditPopupOpen();
        break;
      }
      /* case 'changeStatus': {
        props.updatestatus(props.temp_id, props.status);
        break;
      } */
      case 'copySequence': {
        copySequence();
        break;
      }

      case 'enrollSequence': {
        enrollSequence();
        break;
      }
      default:
        onMenuClose && onMenuClose(type);
        break;
    }
  };

  return (
    <>
      <Button
        className={buttonClassName || ''}
        variant='contained'
        onClick={handleActionsMenuOpen}
      >
        Actions
      </Button>

      <Menu
        anchorEl={anchorElement}
        id={menuId}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {showEditStep && (
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={`/sequence/steps/${props.seq_id}`}
            // to={`/admin/sequence/steps/${props.seq_id}`}
          >
            Edit Steps
          </MenuItem>
        )}
        {!menusItem.length &&
          menuItems.map((mItem, index) => (
            <MenuItem
              key={mItem.itemType + index}
              onClick={handleMenuClose(mItem.itemType)}
            >
              {mItem.title}
            </MenuItem>
          ))}
        {menusItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ActionButtonMenu;
