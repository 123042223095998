import React from 'react';
//import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import placeholders from './placeholder.json';

const SmsEditor = (props) => {
  const handleEditorChange = (editor) => props.onChange(editor);

  return (
    <Editor
      //apiKey="p3bbxaw1zwxgllubxiq63uw548qeox43uvwfw66hsqfj7wtt"
      apiKey="vdbk3iqyzwhv4doses848y1k2n5x8dxldis46h9hht5qqe97"
      className="custom_input"
      value={props.initialvalue}
      onBlur={props?.handleBlur}
      init={{
        height: 180,
        menubar: false,
        statusbar: false,
        //inline:true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code',
        ],
        toolbar: 'undo redo | placeholder',
        setup: function (editor) {
          editor.ui.registry.addSplitButton('placeholder', {
            text: 'Personalize',
            //icon: 'info',
            tooltip: 'It is a personalize content variable',
            onAction: function () {
              //editor.insertContent('<p>You clicked the main button</p>');
            },
            onItemAction: function (api, value) {
              editor.insertContent(value);
            },
            fetch: function (callback) {
              callback(placeholders);
            },
          });
        },
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default SmsEditor;
