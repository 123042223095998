import React from 'react';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleIcon from '@material-ui/icons/People';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import BusinessIcon from '@material-ui/icons/Business';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import WorkIcon from '@material-ui/icons/Work';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import StorageIcon from '@material-ui/icons/Storage';
import CategoryIcon from '@material-ui/icons/Category';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EmailIcon from '@material-ui/icons/Email';
import SendIcon from '@material-ui/icons/Send';
import GroupSharpIcon from '@material-ui/icons/GroupSharp';
import DraftsSharpIcon from '@material-ui/icons/DraftsSharp';

import { english } from './language';

const getMenuItems = (path = '', sideBar = '') => {
  let menuItems = [];
  switch (sideBar) {
    case 'admin': {
      menuItems = [
        {
          id: 'Account Set Up',
          title: 'Account Set Up',
          menuIcon: <AccountBalanceIcon />,
          expand: true,
          link: '/admin/users',
          in: [
            '/admin/users',
            '/admin/entities',
            '/admin/teams',
            '/admin/offices',
          ].includes(path),
          child: [
            {
              id: 'Users',
              title: 'Users',
              activeClass: path.startsWith('/admin/users') ? 'active' : '',
              link: '/admin/users',
              // menuIcon: <PersonIcon />,
            },
            {
              id: 'Entities',
              title: 'Entities',
              activeClass: path.startsWith('/admin/entities') ? 'active' : '',
              link: '/admin/entities',
              // menuIcon: <BrandingWatermarkIcon />,
            },
            {
              id: 'Teams',
              title: 'Teams',
              activeClass: path.startsWith('/admin/teams') ? 'active' : '',
              link: '/admin/teams',
              // menuIcon: <PeopleIcon />,
            },
            {
              id: 'Offices',
              title: 'Offices',
              activeClass: path.startsWith('/admin/offices') ? 'active' : '',
              link: '/admin/offices',
              // menuIcon: <BusinessIcon />,
            },
          ],
        },
        /* {
          id: 'Tools',
          title: 'Tools',
          menuIcon: <BuildIcon />,
          child: [
            {
              id: 'sequence-automations',
              title: 'Sequence Automations',
              // handleClick: handleSequenceClick,
              in: [
                '/admin/sequence',
                '/admin/template',
                '/admin/linkedin-template',
                '/admin/specjob-template',
                '/admin/cvspec-template',
                '/admin/master-cvspec-template',
              ].includes(path),
              expand: true,
              menuIcon: <AutorenewIcon />,
              child: [
                {
                  id: 'Sequences',
                  title: 'Sequences',
                  activeClass: path.startsWith('/admin/sequence')
                    ? 'active'
                    : '',
                  link: '/admin/sequence',
                  menuIcon: <AutorenewIcon />,
                },
                {
                  id: 'email-template',
                  title: 'Email Template',
                  activeClass: path.startsWith('/admin/template')
                    ? 'active'
                    : '',
                  link: '/admin/template',
                  menuIcon: <AssignmentIcon />,
                },
                {
                  id: 'linkedin-template',
                  title: 'LinkedIn Template',
                  activeClass: path.startsWith('/admin/linkedin-template')
                    ? 'active'
                    : '',
                  link: '/admin/linkedin-template',
                  menuIcon: <LinkedInIcon />,
                },
                {
                  id: 'specjob-template',
                  title: 'Spec Job Template',
                  activeClass: path.startsWith('/admin/specjob-template')
                    ? 'active'
                    : '',
                  link: '/admin/specjob-template',
                  menuIcon: <WorkIcon />,
                },
                {
                  id: 'cvspec-template',
                  title: 'CV Spec Template',
                  activeClass: path.startsWith('/admin/cvspec-template')
                    ? 'active'
                    : '',
                  link: '/admin/cvspec-template',
                  menuIcon: <DescriptionIcon />,
                },
                {
                  id: 'master-cvspec-template',
                  title: 'Master CV Spec Template',
                  activeClass: path.startsWith('/admin/master-cvspec-template')
                    ? 'active'
                    : '',
                  link: '/admin/master-cvspec-template',
                  menuIcon: <InsertDriveFileIcon />,
                },
              ],
            },
          ],
        }, */
        {
          id: 'data-management',
          title: 'Data Management',
          menuIcon: <StorageIcon />,
          link: '/admin/categories',
          in: [
            '/admin/categories',
            '/admin/job-roles',
            '/admin/skills',
            '/admin/industries',
          ].includes(path),
          expand: true,
          child: [
            {
              id: 'categories',
              title: 'Job Categories',
              activeClass: path.startsWith('/admin/categories') ? 'active' : '',
              link: '/admin/categories',
              // menuIcon: <CategoryIcon />,
            },
            {
              id: 'job-roles',
              title: 'Job Roles',
              activeClass: path.startsWith('/admin/job-roles') ? 'active' : '',
              link: '/admin/job-roles',
              // menuIcon: <WorkIcon />,
            },
            {
              id: 'skills',
              title: 'Skills',
              activeClass: path.startsWith('/admin/skills') ? 'active' : '',
              link: '/admin/skills',
              // menuIcon: <BrightnessHighIcon />,
            },
            {
              id: 'industries',
              title: 'Industries',
              activeClass: path.startsWith('/admin/industries') ? 'active' : '',
              link: '/admin/industries',
              // menuIcon: <LocationCityIcon />,
            },
          ],
        },
        {
          id: 'market-place',
          title: 'Market Place',
          menuIcon: <AssessmentIcon />,
          expand: true,
          link: '/admin/integrations',
          in: ['/admin/integrations'].includes(path),
          child: [
            {
              id: 'integration',
              title: 'Integrations',
              activeClass: path.startsWith('/admin/integrations')
                ? 'active'
                : '',
              link: '/admin/integrations',
              // menuIcon: <SettingsInputHdmiIcon />,
            },
          ],
        },
      ];
      break;
    }
    case 'profile': {
      menuItems = [
        {
          id: 'profile',
          title: 'Profile',
          menuIcon: <PersonIcon />,
          expand: true,
          link: '/profile',
          in: ['/profile', '/profile/integrations'].includes(path),
          child: [
            {
              id: 'my-profile',
              title: 'My Profile',
              activeClass: path === '/profile' ? 'active' : '',
              link: '/profile',
              // menuIcon: <PersonIcon />,
            },
            {
              id: 'my-profile-integrations',
              title: 'Email',
              activeClass: path.startsWith('/profile/integrations')
                ? 'active'
                : '',
              link: '/profile/integrations',
            },
          ],
        },
        /* {
          id: 'Tools',
          title: 'Tools',
          menuIcon: <BuildIcon />,
          child: [
            {
              id: english.templates,
              title: english.templates,
              expand: true,
              menuIcon: <GradientIcon />,
              in: [
                '/profile/template',
                '/profile/linkedin-template',
                '/profile/specjob-template',
                '/profile/cvspec-template',
                '/profile/master-cvspec-template',
              ].includes(path),
              child: [
                {
                  id: english.emailTemplates,
                  title: english.emailTemplates,
                  activeClass: path.startsWith('/profile/template')
                    ? 'active'
                    : '',
                  link: '/profile/template',
                  menuIcon: <EmailIcon />,
                },
                {
                  id: english.linkedInTemplate,
                  title: english.linkedInTemplate,
                  activeClass: path.startsWith('/profile/linkedin-template')
                    ? 'active'
                    : '',
                  link: '/profile/linkedin-template',
                  menuIcon: <LinkedInIcon />,
                },
                {
                  id: english.specJobTemplates,
                  title: english.specJobTemplates,
                  activeClass: path.startsWith('/profile/specjob-template')
                    ? 'active'
                    : '',
                  link: '/profile/specjob-template',
                  menuIcon: <WorkIcon />,
                },
                {
                  id: english.cvSpecMenuItem,
                  title: english.cvSpecMenuItem,
                  activeClass: path.startsWith('/profile/cvspec-template')
                    ? 'active'
                    : '',
                  link: '/profile/cvspec-template',
                  menuIcon: <DescriptionIcon />,
                },
                {
                  id: english.masterCvSpecMenuItem,
                  title: english.masterCvSpecMenuItem,
                  activeClass: path.startsWith(
                    '/profile/master-cvspec-template',
                  )
                    ? 'active'
                    : '',
                  link: '/profile/master-cvspec-template',
                  menuIcon: <InsertDriveFileIcon />,
                },
              ],
            },
          ],
        }, */
      ];
      break;
    }
    default: {
      menuItems = [
        {
          id: 'app-task',
          title: 'Tasks',
          activeClass: path.startsWith('/task') ? 'active' : '',
          link: '/task',
          menuIcon: <AssignmentTurnedInIcon />,
        },
        {
          id: 'prospect',
          title: 'Prospects',
          expand: true,
          link: '/prospect/candidates',
          menuIcon: <GroupSharpIcon />,
          in:
            path.startsWith('/prospect/candidates') ||
            path.startsWith('/prospect/contacts'),
          child: [
            {
              id: 'my-candidates',
              title: 'Candidates',
              activeClass: path.startsWith('/prospect/candidates')
                ? 'active'
                : '',
              link: '/prospect/candidates',
            },
            {
              id: 'my-contacts',
              title: 'Contacts',
              activeClass: path.startsWith('/prospect/contacts')
                ? 'active'
                : '',
              link: '/prospect/contacts',
            },
          ],
        },
        {
          id: 'sequences',
          title: 'Sequences',
          activeClass: path.startsWith('/sequence') ? 'active' : '',
          link: '/sequence',
          menuIcon: <SendIcon />,
        },
        {
          id: 'sequence-automations',
          title: 'Templates',
          expand: true,
          menuIcon: <DraftsSharpIcon />,
          link: '/template',
          in: [
            '/template',
            '/linkedin-template',
            // '/specjob-template',
            // '/cvspec-template',
            // '/master-cvspec-template',
          ].includes(path),
          child: [
            {
              id: english.emailTemplatesId,
              title: english.emailTemplates,
              activeClass: path.startsWith('/template') ? 'active' : '',
              link: '/template',
              // menuIcon: <EmailIcon />,
            },
            {
              id: english.linkedInTemplateId,
              title: english.linkedInTemplate,
              activeClass: path.startsWith('/linkedin-template')
                ? 'active'
                : '',
              link: '/linkedin-template',
              // menuIcon: <LinkedInIcon />,
            },
            // {
            //   id: english.specJobTemplatesId,
            //   title: english.specJobTemplates,
            //   activeClass: path.startsWith('/specjob-template') ? 'active' : '',
            //   link: '/specjob-template',
            //   // menuIcon: <WorkIcon />,
            // },
            // {
            //   id: english.cvSpecMenuItemId,
            //   title: english.cvSpecMenuItem,
            //   activeClass: path.startsWith('/cvspec-template') ? 'active' : '',
            //   link: '/cvspec-template',
            //   // menuIcon: <DescriptionIcon />,
            // },
            // {
            //   id: english.masterCvSpecMenuItem,
            //   title: english.masterCvSpecMenuItem,
            //   activeClass: path.startsWith('/master-cvspec-template')
            //     ? 'active'
            //     : '',
            //   link: '/master-cvspec-template',
            //   // menuIcon: <InsertDriveFileIcon />,
            // },
          ],
        },
        {
          id: 'reports',
          title: 'Reports',
          expand: true,
          menuIcon: <AssessmentIcon />,
          link: '/activity-report',
          in: ['/activity-report'].includes(path),
          child: [
            {
              id: 'activity-report',
              title: 'Activity Report',
              activeClass: path.startsWith('/activity-report') ? 'active' : '',
              link: '/activity-report',
              // menuIcon: <InsertDriveFileIcon />,
            },
          ],
        },
        /* {
          id: 'succeeded-profile',
          title: 'Profile Details',
          activeClass: path.startsWith('/succeeded-profile') ? 'active' : '',
          link: '/succeeded-profile',
          menuIcon: <AssignmentTurnedInIcon />,
        }, */
      ];
    }
  }

  return menuItems;
};

export default getMenuItems;
