import { fade, makeStyles } from '@material-ui/core/styles';

// main app bar styling
const useStyles = makeStyles(
  ({ shape, palette, spacing, breakpoints, transitions, custom }) => ({
    root: {
      height: 55,
      background: 'unset',
      backgroundColor: custom.colorCode.white,
      borderBottom: `1px solid ${custom.colorCode.lightGreyColorShade6}`,
      boxShadow: 'unset',
      zIndex: 1000,
    },
    toolbarStyle: {
      justifyContent: 'space-between',
      paddingLeft: 10,
      paddingRight: 0,
      minHeight: 55,
      marginLeft: 230,
      transition: 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    leftBox: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 0,
    },
    leftBoxIcon: {
      height: 30,
      marginRight: 10,
    },
    rightBox: {
      display: 'flex',
      marginRight: 20,
    },
    rightBoxIcon: {
      fill: custom.colorCode.white,
    },
    rightBoxIconButton: {
      padding: '1px 8px',
      margin: '5px 0',
      width: 48,
      '&:hover': {
        backgroundColor: custom.colorCode.blackLightShade1,
      },
      '&:focus': {
        outline: 'none',
      },
    },
    userActionButton: {
      padding: '0 8px',
      borderRadius: 5,
      backgroundColor: custom.colorCode.lightGreyColorShade11,
      '&:focus': {
        outline: 'none',
      },
      '& .MuiIconButton-label': {
        minHeight: 30,
      },
      '& i': {
        marginLeft: 5,
        fontSize: 14,
        color: custom.colorCode.black,
      },
    },
    rightBoxIconButtonBadge: {
      '& .MuiBadge-badge': {
        width: 'max-content',
        backgroundColor: custom.colorCode.lightLightGreenColor,
      },
    },
    searchBox: {
      marginTop: 10,
      marginBottom: 10,
      position: 'relative',
      borderRadius: shape.borderRadius,
      backgroundColor: fade(palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(palette.common.white, 0.25),
      },
      marginRight: spacing(2),
      marginLeft: 0,
      width: '100%',
      [breakpoints.up('sm')]: {
        marginLeft: spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      height: '100%',
    },
    inputInput: {
      padding: spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${spacing(4)}px)`,
      transition: transitions.create('width'),
      width: '100%',
      [breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    typography: {
      fontSize: 18,
      alignItems: 'center',
      cursor: 'pointer',
      margin: '0 2px',
      color: custom.colorCode.blackLightShade2,
      fontWeight: 700,
      '&.active': {
        color: custom.colorCode.blackLightShade2,
        borderRadius: 5,
      },
      '&:hover': {
        color: custom.colorCode.blackLightShade2,
        borderRadius: 5,
      },
      '& i': {
        marginLeft: 5,
      },
    },
    headerIcons: {
      '& li': {
        padding: '0 5px',
      },
      '& .nav-link': {
        padding: '0.5rem 0.5rem',
        '& i': {
          color: custom.colorCode.offWhiteShade1,
        },
      },
      '& .dropdown-toggle': {
        '& i': {
          color: custom.colorCode.offWhiteShade1,
        },
        '&::after': {
          content: 'unset',
        },
      },
      '& .header-user-actions': {},
    },
    inputAdornmentClass: {
      position: 'absolute',
      right: 3,
      bottom: 17,
    },
    searchBoxIcon: {
      height: 15,
      fill: custom.colorCode.blueLightShade3,
    },
    userName: {
      fontSize: 14,
      color: custom.colorCode.black,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 120,
      fontWeight: 500,
    },
    userNameMenuItem: {},
    userNameMenuItemIcon: {
      marginRight: 8,
    },
    userNameMenuItemText: {
      marginTop: 7,
    },
    userNameMenuItemLink: {
      display: 'flex',
      alignItems: 'center',
      color: 'unset',
    },
  }),
);

export default useStyles;
