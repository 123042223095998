import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import PageHeader from '../../../components/PageHeader';
import CustomDataGrid from '../../../controlled-component/customGridComponent';
import AddButton from '../../../components/CreateToolbarButton';
import AddJobRoleDrawer from '../AddJobRoleDrawer';
import CustomSnackBar from '../../../components/CustomSnackBar';
import LoadingTableBody from '../../../components/LoadingTableBody';
import { ActiveIcon, InActiveIcon } from '../../../components/GridIcons';

import { fetchJobRoles } from '../../../actions/admin/jobRoles';
import { english } from '../../../utils/language';
import api from '../../../utils/api';

// import Job Roles Grid styling
import useStyles from './style';

// Grid listing of the job role will be done over here
const JobRolesGrid = (props) => {
  const classes = useStyles();

  // Local state variables
  const [popupToggle, setPopupToggle] = useState(false);
  // const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  const [editJobRolesData, setEditJobRolesData] = useState(null);
  const [tableParams, setTableParams] = useState({});

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const jobRoles = useSelector((state) => state.adminJobRoles);
  const isMounted = useRef(true);

  // handle function to open/close add job role pop up
  const handlePopupOpen = () => {
    setPopupToggle(!popupToggle);
  };

  // handle function to open/close add job role pop up
  const handlePopupClose = () => {
    setPopupToggle(false);
    setEditJobRolesData({});
  };

  // handle the edit job role pop up
  const handleEditPopupOpen = (id) => {
    setPopupToggle(!popupToggle);
    setEditJobRolesData(
      jobRoles.jobRolesList.data.find((jRole) => jRole.id === id) || {},
    );
  };

  // update status with reducer
  const updateStatus = (jobRoleId, rowData) => {
    const payload = {
      status: rowData.status ? 0 : 1,
    };
    api
      .put(`/automation/jobroles/update-status/${jobRoleId}`, {
        values: payload,
      })
      .then((resp) => {
        // if(isMounted) {
        //   return;
        // }
        if (tableParams) {
          dispatch(fetchJobRoles(tableParams));
        } else {
          dispatch(fetchJobRoles());
        }
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          status: 'success',
          message: rowData.status
            ? 'Job role deactivate successfully.'
            : 'Job role activate successfully.',
        });
      })
      .catch((error) => {
        // if(isMounted) {
        //   return;
        // }
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          status: 'error',
          message: error.response?.data?.error || 'Something went wrong',
        });
      });
  };

  useEffect(() => {
    isMounted.current = true;
    // did mount
    // call the job roles api and set data in reducer
    dispatch(fetchJobRoles());
    return () => {
      isMounted.current = false
    };
  }, []);

  // open edit pop up when click on the name
  const handleNameClick = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    handleEditPopupOpen(data.id);
  };

  // close snake bar and remove error
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Job Role',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({
            style: {
              minWidth: 300,
              width: 300,
              maxWidth: 300,
            },
          }),
          setCellProps: () => ({
            style: {
              minWidth: 300,
              width: 300,
              maxWidth: 300,
            },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = jobRoles.jobRolesList?.data
              ? jobRoles.jobRolesList.data[dataIndex]
              : {};
            return (
              <Tooltip title={rData.name} arrow>
                <div
                  className={`sort-by td-content-ellipsis td-anchor-content-ellipsis`}
                  onClick={(e) => handleNameClick(e, rData)}
                >
                  {rData.name}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: 'category_name',
        label: 'Category',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'upskill_name',
        label: 'Up Skill',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <div className={`td-content-ellipsis`}>{value}</div>
            );
          },
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = jobRoles.jobRolesList?.data
              ? jobRoles.jobRolesList.data[dataIndex]
              : {};
            // const text = rData.status ? 'Active' : 'In Active';
            // return <div title={text || ''}>{text}</div>;
            return rData.status ? (
              <ActiveIcon fontSize="small" />
            ) : (
              <InActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row =
              (jobRoles?.jobRolesList?.data || []).find(
                (tData) => tData.id === value,
              ) || {};
            return (
              <TemplateGridActions
                temp_id={row?.id}
                handleEditPopupOpen={handleEditPopupOpen}
                row={row}
                updatestatus={updateStatus}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [jobRoles.jobRolesList],
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={jobRoles.loading} {...props} />,
    [jobRoles.loading],
  );

  // store server params on state
  const handleTableChange = (params) => {
    setTableParams(params);
  };

  /**
   * Return function to render and return UI elements
   */
  return (
    <div className={classes.gridMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={english.jobRoleMainTitle}
        subTitle={english.jobRoleSubTitle}
        itemCount={jobRoles?.jobRolesList?.count || 0}
      />
      <div className={classes.gridWrapper}>
        <CustomDataGrid
          columns={columns}
          data={jobRoles?.jobRolesList?.data || []}
          fetchData={fetchJobRoles}
          onTableChange={handleTableChange}
          options={{
            pagination: Boolean(jobRoles?.jobRolesList?.count),
            selectableRows: 'multiple',
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search Job Roles',
            selectToolbarPlacement: 'none',
            searchOpen: true,
            search: true,
            serverSide: true,
            count: jobRoles?.jobRolesList?.count,
            customToolbar: () => (
              <AddButton
                handleClick={handlePopupOpen}
                title={`Create Job Role`}
                dataTarget={`createJobRole`}
              />
            ),
          }}
          components={{ TableBody: BodyComponent }}
        />
        <AddJobRoleDrawer
          isMounted={isMounted}
          jobRoleId={editJobRolesData?.id}
          openDrawer={popupToggle}
          onClose={handlePopupClose}
        />
      </div>

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

// custom grid action menu
const TemplateGridActions = (props) => {
  const { row } = props;
  const [anchorElement, setAnchorElement] = useState(null);

  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);
    switch (type) {
      case 'deactivateJobRole': {
        props.updatestatus(props.temp_id, row);
        break;
      }
      case 'editJobRole': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: 'Edit',
      itemType: 'editJobRole',
    },
    {
      title: 'Change Status',
      itemType: 'deactivateJobRole',
    },
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default JobRolesGrid;
