import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import ProfileForm from './ProfileForm';
import SecurityForm from './SecurityForm';
import SystemForm from './SystemForm';
import Signature from './Signature';
import Spinner from '../../../layout/Spinner';
import MainTabs, { TabPanel } from '../../../components/MainTabs';

import { getDropdonList } from '../../../actions/users';
import { fetchCountriesTimezones } from '../../../actions/admin/Offices';
import api from '../../../utils/api';

import useStyles from './style';

const tabsOptions = [
  {
    name: 'profile',
    title: 'Profile',
  },
  {
    name: 'system',
    title: 'System',
  },
  {
    name: 'security',
    title: 'Security',
  },
  {
    name: 'signature',
    title: 'Signature',
  },
];

const EditUserFormTab = (props) => {
  const { location, myProfile = false } = props;

  const [userTabIndex, setUserTabIndex] = useState(0);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const dispatch = useDispatch();
  const dropdownList = useSelector((state) => state.users.dropdownList || {});
  const timezones = useSelector((state) => state.adminOffices.timezones);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(getDropdonList());
    if (!timezones?.length) {
      dispatch(fetchCountriesTimezones());
    }
    getUserData();
  }, []);

  const getUserData = () => {
    let userId = '';
    if (myProfile) {
      const currentUserData = JSON.parse(localStorage.getItem('user'));
      userId = currentUserData.id;
    } else {
      userId = location.pathname.split('/').slice(-1)[0];
    }

    api
      .get(`/automation/users/${userId}`)
      .then((res) => {
        setLoading(false);
        setUserData(res.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setUserTabIndex(newValue);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      {!myProfile && (
        <Breadcrumbs
          className={classes.breadCrumb}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to="/admin/users">Users</Link>
          <Typography
            className={classes.breadCrumbLastChild}
            color="textPrimary"
          >
            {`${userData.fname} ${userData.lname}`}
          </Typography>
        </Breadcrumbs>
      )}

      <MainTabs
        tabsOptions={tabsOptions}
        tabIndex={userTabIndex}
        onTabChange={handleTabChange}
        className={myProfile ? classes.myProfileTabs : ''}
        tabLabel="title"
        tabKey="name"
        centered
      />

      <TabPanel
        value={userTabIndex}
        index={0}
        className="d-flex justify-content-center"
      >
        <ProfileForm
          dropdownList={dropdownList}
          userData={userData}
          myProfile={myProfile}
          getUserData={getUserData}
          userRoles={user.roles}
        />
      </TabPanel>

      <TabPanel
        value={userTabIndex}
        index={1}
        className="d-flex justify-content-center"
      >
        <SystemForm
          dropdownList={dropdownList}
          timezones={timezones}
          userData={userData}
          getUserData={getUserData}
          userRoles={user.roles}
        />
      </TabPanel>

      <TabPanel
        value={userTabIndex}
        index={2}
        className="d-flex justify-content-center"
      >
        <SecurityForm
          dropdownList={dropdownList}
          userData={userData}
          getUserData={getUserData}
          userRoles={user.roles}
        />
      </TabPanel>

      <TabPanel
        value={userTabIndex}
        index={3}
        className="d-flex justify-content-center"
      >
        <Signature userData={userData} getUserData={getUserData} />
      </TabPanel>
    </div>
  );
};

export default EditUserFormTab;
