import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
  state = {
    isChecked: this.props.checked,
  };

  toggleCheckboxChange = (event) => {
    const { handleCheckboxChange } = this.props;

    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
    }));

    handleCheckboxChange(event);
  };

  render() {
    //const { label } = this.props;
    const { value } = this.props;
    const { name } = this.props;
    const { isChecked } = this.state;

    return (
      <div className="filter-checkbox">
        <input
          type="checkbox"
          value={value}
          name={name}
          checked={isChecked}
          onChange={this.toggleCheckboxChange}
        />
      </div>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};

export default Checkbox;
