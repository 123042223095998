import React, { useState } from 'react';

import CustomDrawer from '../../components/CustomDrawer';
import DrawerFormHeader from '../../components/DrawerFormHeader';
import AddUserForm from '../AddUserForm';
import CustomSnackBar from '../../components/CustomSnackBar';

// Main drawer with the custom  drawer
const AddUserDrawer = (props) => {
  const { openDrawer, onClose } = props;
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleClose = (data) => {
    // to show the toast notification
    if (data && data?.userCreated) {
      setOpenSnackBar(true);
    }
    onClose();
  };

  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={'Create User'}
        >
          <AddUserForm closeDrawer={handleClose} />
        </DrawerFormHeader>
      </CustomDrawer>
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        handleSnackbarClose={handleSnackbarClose}
        barMessage={'New User added!'}
        severity={'success'}
      />
    </div>
  );
};

export default AddUserDrawer;
