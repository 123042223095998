import React from 'react';

export const CheckBox = (props) => {
  //console.log("props", props)
  return (
    <div>
      <input
        key={props.id}
        onChange={() =>
          props.handleCheckChildElement(props.id, props.name, props.details)
        }
        type="checkbox"
        value={props.name}
      />{' '}
      <label className="ml-2" style={{ fontSize: '12px' }}>
        {props.name}
      </label>
    </div>
  );
};

export default CheckBox;
