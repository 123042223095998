import api from '../../utils/api';

import {
  ADMIN_INTEGRATION_DETAIL_ERROR,
  ADMIN_INTEGRATION_ERROR,
  LOAD_ADMIN_INTEGRATION,
  LOAD_ADMIN_INTEGRATION_DETAIL,
  LOADING_ADMIN_INTEGRATION,
  LOADING_ADMIN_INTEGRATION_DETAIL,
  SAVE_ADMIN_INTEGRATION,
  LOAD_ADMIN_INTEGRATION_DETAIL_TABS,
  SET_TEMPLATE_FILTER_DATA,
} from '../types';

// to be remove in future
const detailDataList = [
  {
    id: 1,
    name: 'Candidates',
    status: true,
  },
  {
    id: 2,
    name: 'Decision makers',
    status: false,
  },
  {
    id: 3,
    name: 'Accounts',
    status: true,
  },
  {
    id: 4,
    name: 'Jobs',
    status: true,
  },
  {
    id: 5,
    name: 'Leads',
    status: false,
  },
  {
    id: 6,
    name: 'Notes',
    status: true,
  },
  {
    id: 7,
    name: 'Tasks',
    status: true,
  },
];

// Get INTEGRATION list and set in reducer
export const fetchIntegration = (listMounted) => async (dispatch) => {
  try {
    listMounted &&
      dispatch({
        type: LOADING_ADMIN_INTEGRATION,
        payload: {
          getListLoading: true,
          listMounted: false,
        },
      });

    const res = await api.get('/integrations/plugins');

    dispatch({
      type: LOAD_ADMIN_INTEGRATION,
      payload: {
        getListLoading: false,
        integrationList: res.data,
        listMounted: Boolean(listMounted),
      },
    });
  } catch (err) {
    dispatch({
      type: ADMIN_INTEGRATION_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
        getListLoading: false,
      },
    });
  }
};

export const fetchTabs = () => async (dispatch) => {
  try {
    // TODO: dummuy api call need to be removed
    const res = await api.get("automation/tabs/get-tabs?object=email_template");

    res.data = res.data.map((e) => ({
      ...e,
      url: e.tab_type ? e.name.toLocaleLowerCase().replace(/ /g, "-") : e.id,
    }));

    // TODO
    dispatch({
      type: LOAD_ADMIN_INTEGRATION_DETAIL_TABS,
      payload: {
        tabs: [{"id":12,"user_id":null,"tab_type":1,"name":"Email","object":"email_template"}], // static data need to be after popper api call
        loading: false,
      },
    });
    return res;
  } catch (err) {
    return err;
  }

};


// Get INTEGRATION detail and set in reducer
export const fetchIntegrationDetail = (
  filterData,
  gridData,
  tabData,
  gridColParams,
  showLoader = true,
) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_ADMIN_INTEGRATION_DETAIL,
      payload: {
        setDetailListLoading: true,
      },
    });

    // api call
    // const res = await api.get('/integrations/plugins');
    let filterListData = [];

    filterListData.push({
      name: 'status',
      optionLabel: 'name',
      optionValue: 'id',
      isSingle: true,
      title: 'Status',
      type: 'status',
      showName: true,
      data: [
        {
          id: true,
          name: 'Active',
        },
        {
          id: false,
          name: 'In Active',
        },
      ],
    });

    const filterList = {
      status: [
        {
          id: true,
          name: 'Active',
        },
        {
          id: false,
          name: 'In Active',
        },
      ],
    };

    const res = {
      "counts": 5,
      "filters": {},
      "grid_params": {
        "pageNo": 0,
        "sortOrder": {
          "name": "name",
          "direction": "desc"
        },
        "perPage": 25,
        "searchText": null
      },
      list: gridData?.status?.length
        ? [...detailDataList.filter((e) => e.status === gridData?.status[0])]
        : detailDataList,
      "columns": '{"columns":[{"name":"name","label":"Template Name","display":"true","empty":false,"filter":false,"sort":true,"print":true,"searchable":true,"download":true,"viewColumns":true,"sortCompare":null,"sortThirdClickReset":false,"sortDescFirst":false,"draggable":false},{"name":"is_published","label":"Status","display":"true","empty":false,"filter":true,"sort":true,"print":true,"searchable":true,"download":true,"viewColumns":true,"sortCompare":null,"sortThirdClickReset":false,"sortDescFirst":false}],"columnsOrder":[0,1]}',
    };

    setTimeout(() => {
      // filter data set
      dispatch({
        type: LOAD_ADMIN_INTEGRATION_DETAIL,
        payload: {
          id: filterData.id,
          [filterData.id]: {
            myFilterData: [...filterListData], // filter options
            filterList: filterList,
            filterState: { status: gridData?.status || [] }, // filter selected value
            defaultFilterState: { status: [] },
            ...res,
          },
        },
      });
    }, 2000);
  } catch (err) {
    dispatch({
      type: ADMIN_INTEGRATION_DETAIL_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Save Twilio Integration popup details
export const saveTwilioIntegrations = (values, onClose) => async (dispatch) => {
  try {
    await api.post(`/integrations/twilio`, values).then((res) => {
      dispatch({
        type: SAVE_ADMIN_INTEGRATION,
        payload: res.data,
      });
      onClose({ data: 'twilioIntegrated' });
    });
  } catch (err) {
    dispatch({
      type: ADMIN_INTEGRATION_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
        getListLoading: false,
      },
    });
  }
};

// Save bullhorn Integration
export const saveBullhornIntegrations = (values, onClose) => async (
  dispatch,
) => {
  try {
    return await api.post('/integrations/bh', { ...values }).then((res) => {
      onClose({ data: 'bullhornIntegration' });
      setTimeout(() => {
        dispatch(fetchIntegration());
      }, 10);
    });
  } catch (err) {
    dispatch({
      type: ADMIN_INTEGRATION_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
        getListLoading: false,
      },
    });
  }
};

// Save lusha Integration
export const saveLushaIntegrations = (values, onClose) => async (dispatch) => {
  try {
    return await api.post('/integrations/lusha', { ...values }).then((res) => {
      onClose({ data: 'integration' });
      setTimeout(() => {
        dispatch(fetchIntegration());
      }, 10);
    });
  } catch (err) {
    dispatch({
      type: ADMIN_INTEGRATION_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
        getListLoading: false,
      },
    });
  }
};

//Disconnect Twilio Integration
export const disconnectTwilio = (values) => async (dispatch) => {
  try {
    await api.post(`/integrations/twilio`, values).then((res) => {
      dispatch({
        type: SAVE_ADMIN_INTEGRATION,
        payload: res.data,
      });
      //onClose({ data: 'twilioIntegrated' });
    });
  } catch (err) {
    dispatch({
      type: ADMIN_INTEGRATION_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
        getListLoading: false,
      },
    });
  }
};
