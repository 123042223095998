import React, { useEffect, useState } from 'react';
import { Box, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import Spinner from '../../layout/Spinner';
import SequenceListItem from '../../components/ListItems/SequenceListItem';
import SequenceListingDialog from '../../admin/myTask/StartTaskDrawer/SequenceListingDialog';
import Collapse from '../../components/Collapse';
import api from '../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  collapseWrapper: {
    paddingLeft: 16,
    '& .MuiListItem-secondaryAction': {
      paddingRight: 80,
    },
  },
  listHeaderWrapper: {
    '& .MuiTypography-root': {
      marginRight: 16,
      color: custom.colorCode.lightGreyColorProfileShade24,
      cursor: 'pointer',
    },
    '& .active': {
      color: '#1861fd',
    },
  },
  collapseHeading: {
    display: 'flex',
    cursor: 'pointer',
  },
  sequenceHeading: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '50%',
    paddingRight: 8,
  },
  collapseSequenceSubHeading: {
    fontSize: 12,
    padding: '4px 8px',
    borderRadius: 4,
    background: custom.colorCode.lightGreyColorShade17,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '50%',
    maxWidth: 'fit-content',
  },
  flex: {
    flex: 1,
  },
}));

const listTabData = [
  {
    id: 1,
    title: 'Active',
  },
  {
    id: 2,
    title: 'Hold',
  },
  {
    id: 3,
    title: 'Completed',
  },
];

const ListView = ({ profileType, profileId }) => {
  const classes = useStyles();

  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedSequenceAction, setSelectedSequenceAction] = useState(null);
  const [sequenceSubHeading, setSequenceSubHeading] = useState('');
  const [reasonDropdown, setReasonDropdown] = useState();

  const getSequenceList = async () => {
    try {
      if (profileType === 'candidate') {
        await api
          .get(`/candidate/get-sequence-status/${profileId}`)
          .then(({ data }) => {
            setSequenceSubHeading(data || '');
          });
      }
      if (profileType === 'contact') {
        await api
          .get(`/dm/get-sequence-status/${profileId}`)
          .then(({ data }) => {
            setSequenceSubHeading(data || '');
          });
      }
      await api
        .get(
          `/sequence/get-sequence-list?contact_id=${profileId}&audience_type=${
            profileType === 'contact' ? 1 : 2
          }`,
        )
        .then(({ data }) => {
          setDisplayData([...data]);
        });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  // const [listTab, setListTab] = useState(listTabData);
  // const [activeListTab, setActiveListTab] = useState(1);

  const menuId = 'action-sequence-profile-menu-id';

  const handleMenuClose = (seqData) => () => {
    setAnchorElement(null);

    if (seqData) {
      setSelectedSequenceAction({
        ...seqData,
        audience_type: profileType === 'contact' ? 1 : 2,
        new_contact_id: profileId,
      });
    }
  };

  useEffect(() => {
    if (profileType && profileId) {
      getSequenceList();
      api.get(`/sequence/get-reason-dropdown`).then((respDropdown) => {
        setReasonDropdown(respDropdown.data);
      });
    }
  }, [profileType, profileId]);

  const handleAddSequenceClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner style={{ width: 20, height: 20, position: 'unset' }} />
      </Box>
    );
  }

  return (
    <>
      <Collapse
        leftIcon
        defaultOpen
        className="collapseCardContent"
        contentTitle={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width={1}
          >
            <Box flex={1} display="flex" alignItems="center" flexWrap="wrap">
              <Tooltip title={`Sequence (${displayData.length})`} arrow>
                <Typography variant="h6" className={classes.sequenceHeading}>
                  Sequence ({displayData.length})
                </Typography>
              </Tooltip>
              <Tooltip title={sequenceSubHeading || ''} arrow>
                <span className={classes.collapseSequenceSubHeading}>
                  {sequenceSubHeading}
                </span>
              </Tooltip>
            </Box>
            <Typography
              onClick={handleAddSequenceClick}
              variant="body2"
              className={classes.collapseHeading}
            >
              <AddIcon fontSize="small" />
              Add
            </Typography>
          </Box>
        }
      >
        <Box className={classes.collapseContentWrapper}>
          <Box className={classes.collapseWrapper}>
            {/*<Box display="flex" mb={1} className={classes.listHeaderWrapper}>*/}
            {/*  {listTab.map((lTab, index) => (*/}
            {/*    <Typography*/}
            {/*      key={`list-item-tab-${lTab.id}-${index}`}*/}
            {/*      variant="body1"*/}
            {/*      className={lTab.id === activeListTab ? 'active' : ''}*/}
            {/*      onClick={handleTabChange(lTab)}*/}
            {/*    >*/}
            {/*      {lTab.title}*/}
            {/*    </Typography>*/}
            {/*  ))}*/}
            {/*</Box>*/}

            <SequenceListItem
              anchorElement={anchorElement}
              displayData={displayData}
              handleMenuClose={handleMenuClose}
              setAnchorElement={setAnchorElement}
              menuId={menuId}
            />
          </Box>
        </Box>
      </Collapse>
      <SequenceListingDialog
        selectedSequenceAction={selectedSequenceAction}
        reasonDropdown={reasonDropdown}
        setSelectedSequenceAction={setSelectedSequenceAction}
        getSequenceList={getSequenceList}
      />
    </>
  );
};

export default ListView;
