import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import JobRolesGrid from './JobRolesGrid';

const JobRoles = (props) => {
  return <JobRolesGrid {...props} />;
};

JobRoles.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(JobRoles);
