import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import DrawerFormFooter from '../../../components/DrawerFormHeader/DrawerFormFooter';
import Spinner from '../../../layout/Spinner';

import { saveLushaIntegrations } from '../../../actions/admin/integration';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
}));

/**
 * Component for Bullhorn Integration form
 * @param {*} props
 * @returns
 */
const LushaIntegrationForm = (props) => {
  const classes = useStyles();

  //Local state variables
  const [formData, setFormData] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);

  const dispatch = useDispatch();

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['auth_token'];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Handle function of Integration Twilio form
   * It call server API to save data into tables
   */
  const handleSubmit = async () => {
    if (disabledNextButton() === false) {
      setDisabledButton(true);

      const values = {
        ...formData,
      };

      await dispatch(saveLushaIntegrations(values, props?.closeDrawer));
      setTimeout(() => {
        setDisabledButton(false);
      }, 10);
    }
  };

  /**
   * function to handle the form element change event
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  // this will show the loader when api is getting called
  if (disabledButton) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  return (
    <Box className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <Box className={classes.formWrapper}>
          <Box>
            <Grid container spacing={3}>
              {/*API Token*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="API Token *"
                  onChange={handleChange}
                  name="auth_token"
                  value={formData?.auth_token || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter API Token']}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <DrawerFormFooter disableButton={disabledNextButton()}>
          <Box />
        </DrawerFormFooter>
      </ValidatorForm>
    </Box>
  );
};

export default LushaIntegrationForm;
