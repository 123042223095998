import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    display: 'flex',
    paddingBottom: 8,
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  selectionMessageWrapper: {
    padding: 8,
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
}));

const CustomSelectToolbar = ({
  customContent = <></>,
  selectedRows,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.selectionMessageWrapper}>
        {selectedRows.data.length} Selected
      </Box>
      <Box display="flex">{customContent && customContent}</Box>
    </Box>
  );
};

export default CustomSelectToolbar;
