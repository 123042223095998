import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormGroup,
  Grid,
  MenuItem,
  Snackbar,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import FormAutoCompleteSelect from '../../components/FormAutoCompleteSelect';
import FormStepper from '../../components/FormStepper';
import CheckboxLabel from '../../components/CheckboxLabel';
import Spinner from '../../layout/Spinner';

import { getDropdonList, getUsersList } from '../../actions/users';
import api from '../../utils/api';

export const accessLevelOption = [
  {
    mainId: 1,
    id: 1,
    name: 'Access from office only',
  },
  {
    mainId: 0,
    id: 2,
    name: 'Access from anywhere',
  },
];

const useStyles = makeStyles(({ spacing, custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  selectBoxStyle: {},
  selectBoxMenuPaper: {
    maxHeight: 300,
    maxWidth: 500,
  },
  backButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.lightGreyColorShade13,
    color: custom.colorCode.lightGreyColorShade12,
    outline: 'none',
    border: `solid 1px ${custom.colorCode.lightGreyColorShade13}`,
    boxShadow: 'unset',
    '&:hover': {
      border: `solid 1px ${custom.colorCode.lightGreyColorShade12}`,
      outline: 'none',
    },
    '&:focus': {
      border: `solid 1px ${custom.colorCode.lightGreyColorShade12}`,
      outline: 'none',
    },
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',
    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
  instructions: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  wizardFormWrapper: {
    padding: '20px 40px',
    position: 'relative',
  },
  wizardFormHeading: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 0,
    paddingBottom: 16,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
  },
}));

// title of the wizard form
const getSteps = () => {
  return ['User Details', 'System Details', 'Permission'];
};

// steps for the wizard form
const getStepContent = (step) => {
  switch (step) {
    case 0:
      return 'Basic User Details';
    case 1:
      return 'System Info Required';
    case 2:
      return 'Access Permissions';
    default:
      return '';
  }
};

// Main add user form with wizard form
const AddUserForm = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [openErrorSnackBarMsg, setOpenErrorSnackBarMsg] = useState('');
  const [disableLoginBtn, setDisableLoginBtn] = useState(false);
  const [emailValidators, setEmailValidators] = useState({
    validators: ['isEmail', 'required', 'isEmailUnique'],
    errorMessages: ['Invalid email', 'Email is required', 'Duplicate email'],
  }); // email validation and message to be manged in the state
  const [formData, setFormData] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    email: '',
  });
  const [skipped, setSkipped] = useState(new Set());
  // const [disableButton, setDisableButton] = useState(false); // disable button to handle validation
  const steps = getSteps();

  const formRef = useRef();
  const emailRef = useRef();

  const dispatch = useDispatch();
  const dropdownList = useSelector((state) => state.users.dropdownList || {});

  useEffect(() => {
    dispatch(getDropdonList());
    ValidatorForm.addValidationRule('isEmailUnique', async (value) => {
      if (!value) {
        return true;
      }
      // call api for email duplication
      const params = {
        email: value,
      };
      return await api
        .get('/automation/user/check-duplicate', { params })
        .then((res) => {
          if (res.data === true) {
            return false;
          }
          // return false for the error
          return true;
        })
        .catch(() => {
          return false;
        });
    });

    return () => {
      if (ValidatorForm.hasValidationRule('isEmailUnique')) {
        ValidatorForm.removeValidationRule('isEmailUnique');
      }
    };
  }, []);

  useEffect(() => {
    if (activeStep === 0 && !ValidatorForm.hasValidationRule('isEmailUnique')) {
      ValidatorForm.addValidationRule('isEmailUnique', async (value) => {
        if (!value) {
          return false;
        }
        // call api for email duplication
        const params = {
          email: value,
        };
        return await api
          .get('/automation/user/check-duplicate', { params })
          .then((res) => {
            if (res.data === true) {
              return false;
            }
            // return false for the error
            return true;
          })
          .catch(() => {
            return false;
          });
      });
    }
  }, [activeStep]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackBar(false);
  };

  const handleCheckboxChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    setDisableLoginBtn(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let formSteps = <div />;

  const handleSelectChange = (name) => (event, tag) => {
    setFormData({
      ...formData,
      [name]: name === 'accessLevel' ? tag.mainId : tag.id,
    });
  };

  const handleChange = (event) => {
    if (event.target.name === 'email') {
      if (emailValidators.validators.find((e) => e === 'isEmail')) {
        setEmailValidators({
          validators: ['required', 'isEmailUnique'],
          errorMessages: ['Email is required', 'Duplicate email'],
        });
      }
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleEmailBlur = () => {
    emailValidators.validators = ['isEmail', 'required', 'isEmailUnique'];
    emailValidators.errorMessages = [
      'Invalid email',
      'Email is required',
      'Duplicate email',
    ];

    setEmailValidators({
      ...emailValidators,
    });
    setTimeout(() => {
      emailRef.current.validate(formData.email);
    }, 20);
  };

  switch (activeStep) {
    case 0: {
      formSteps = (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={2} sm={3}>
              <TextValidator
                label="Title *"
                onChange={handleChange}
                name="title"
                variant="outlined"
                select
                value={formData?.title || ''}
                fullWidth
                validators={['required']}
                errorMessages={['Select title']}
              >
                {(dropdownList.titleList || []).map((tList, index) => (
                  <MenuItem
                    key={tList.id + tList.title_name + index}
                    value={tList.id}
                  >
                    {tList.title_name}
                  </MenuItem>
                ))}
              </TextValidator>
            </Grid>

            <Grid item xs={8} sm={4}>
              <TextValidator
                label="First Name *"
                onChange={handleChange}
                name="firstName"
                value={formData?.firstName || ''}
                style={{ paddingRight: '10' }}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['First Name Required']}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextValidator
                label="Last Name *"
                onChange={handleChange}
                name="lastName"
                value={formData?.lastName || ''}
                style={{ paddingLeft: '10' }}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Last Name Required']}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextValidator
                label="Email *"
                onChange={handleChange}
                name="email"
                ref={emailRef}
                value={formData.email}
                style={{ paddingRight: '10' }}
                fullWidth
                variant="outlined"
                onBlur={handleEmailBlur}
                validators={emailValidators.validators}
                errorMessages={emailValidators.errorMessages}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextValidator
                label="Job Title *"
                onChange={handleChange}
                name="jobTitle"
                value={formData?.jobTitle || ''}
                style={{ paddingLeft: '10' }}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Job Title is required']}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextValidator
                label="Start Date *"
                onChange={handleChange}
                name="startDate"
                type="date"
                variant="outlined"
                value={formData.startDate}
                fullWidth
                validators={['required']}
                errorMessages={['Start Date is required']}
              />
            </Grid>
          </Grid>
        </div>
      );
      break;
    }
    case 1: {
      formSteps = (
        <div>
          <Grid container spacing={2}>
            {/*entity*/}
            <Grid item xs={12} sm={6} className="pr-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('entity')}
                name="entity"
                id="entity"
                key={'entity'}
                disableClearable
                options={[...(dropdownList.entityList || [])]}
                getOptionLabel={(option) => option.Name || ''}
                value={
                  formData?.entity
                    ? dropdownList.entityList.find(
                        (c) => c.id === formData?.entity,
                      ) || ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Entity *"
                    className={classes.selectBoxStyle}
                    name="entity"
                    value={formData?.entity || ''}
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={['Select Entity']}
                  />
                )}
              />
            </Grid>
            {/*Team*/}
            <Grid item xs={12} sm={6} className="pl-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('team')}
                name="team"
                id="team"
                key={'team'}
                disableClearable
                options={[...(dropdownList.teams || [])]}
                getOptionLabel={(option) => option.name || ''}
                value={
                  formData?.team
                    ? dropdownList.teams.find((c) => c.id === formData?.team) ||
                      ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Primary Team *"
                    className={classes.selectBoxStyle}
                    name="team"
                    value={formData?.team || ''}
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={['Select Team']}
                  />
                )}
              />
            </Grid>

            {/*office*/}
            <Grid item xs={12} sm={6} className="pr-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('office')}
                name="office"
                id="office"
                key={'office'}
                disableClearable
                options={[...(dropdownList.officeList || [])]}
                getOptionLabel={(option) => option.office_name || ''}
                value={
                  formData?.office
                    ? dropdownList.officeList.find(
                        (c) => c.id === formData?.office,
                      ) || ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Office *"
                    className={classes.selectBoxStyle}
                    name="office"
                    value={formData?.office || ''}
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={['Select Office']}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
      );
      break;
    }
    case 2: {
      formSteps = (
        <div>
          <Grid container spacing={2}>
            {/*access level*/}
            <Grid item xs={12} sm={6} className="pr-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('accessLevel')}
                name="accessLevel"
                id="accessLevel"
                disableClearable
                key={'accessLevel'}
                getOptionSelected={(option, value) => option.id === value.id}
                options={accessLevelOption}
                value={
                  !(formData?.accessLevel === undefined)
                    ? accessLevelOption.find(
                        (c) => c.mainId === formData?.accessLevel,
                      ) || ''
                    : ''
                }
                getOptionLabel={(option) => option.name || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Access Level *"
                    name="accessLevel"
                    value={
                      accessLevelOption.find(
                        (c) => c.mainId === formData?.accessLevel,
                      )?.name || ''
                    }
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={['Select Access Level']}
                  />
                )}
              />
            </Grid>

            {/*permission level*/}
            <Grid item xs={12} sm={6} className="pl-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('permissionLevel')}
                name="permissionLevel"
                id="permissionLevel"
                key={'permissionLevel'}
                disableClearable
                options={[...(dropdownList.roleList || [])]}
                getOptionLabel={(option) => option.name || ''}
                value={
                  formData.permissionLevel
                    ? dropdownList.roleList.find(
                        (c) => c.id === formData?.permissionLevel,
                      ) || ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Permission Level *"
                    name="permissionLevel"
                    value={formData?.permissionLevel || ''}
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={['Select Permission Level']}
                  />
                )}
              />
            </Grid>

            {/*Checkboxes*/}
            <Grid item xs={12} sm={6} className="pr-3">
              <FormGroup>
                <CheckboxLabel
                  label="Assigned Admin Role "
                  checked={formData?.adminRole || false}
                  name="adminRole"
                  value={''}
                  handleChange={handleCheckboxChange}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </div>
      );
      break;
    }
    default:
      break;
  }

  const handleSubmit = () => {
    if (activeStep === steps.length - 1) {
      if (disabledNextButton() === false) {
        setDisableLoginBtn(true);
        const values = {
          title: formData.title,
          fname: formData.firstName,
          lname: formData.lastName,
          job_title: formData.jobTitle,
          email: formData.email,
          start_date: formData.startDate,
          organisation: formData.entity,
          team: formData.team,
          branch: formData.office,
          security: formData.accessLevel,
          role: formData.permissionLevel,
          adminRole: formData.adminRole,
        };

        setDisableLoginBtn(true);
        api
          .post('/automation/users', {
            values,
          })
          .then((res) => {
            setDisableLoginBtn(false);
            if (res.status === 201) {
              props?.closeDrawer({ userCreated: true });
            }
            dispatch(getUsersList());
          })
          .catch((error) => {
            setOpenErrorSnackBar(true);
            setDisableLoginBtn(false);
            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
          });
      }
    } else {
      if (disabledNextButton() === false) {
        if (
          activeStep === 0 &&
          ValidatorForm.hasValidationRule('isEmailUnique')
        ) {
          ValidatorForm.removeValidationRule('isEmailUnique');
        }
        setDisableLoginBtn(true);
        handleNext();
      }
    }
  };

  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (e === 'accessLevel') {
        return formData[e] !== undefined;
      }
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  const disabledNextButton = () => {
    if (activeStep === 0) {
      const formKeys = ['title', 'firstName', 'lastName', 'email', 'jobTitle'];
      if (validateData(formKeys)) {
        return true;
      } else {
        return disableLoginBtn;
      }
    }
    if (activeStep === 1) {
      const formKeys = ['entity', 'team', 'office'];
      if (validateData(formKeys)) {
        return true;
      } else {
        return disableLoginBtn;
      }
    }
    if (activeStep === 2) {
      const formKeys = ['accessLevel', 'permissionLevel'];
      if (validateData(formKeys)) {
        return true;
      } else {
        return disableLoginBtn;
      }
    }
    return true;
  };

  return (
    <>
      <div className={classes.root}>
        <FormStepper
          alternativeLabel
          activeStep={activeStep}
          className={classes.stepper}
          steps={steps}
          isStepSkipped={isStepSkipped}
        />
        <ValidatorForm
          onSubmit={handleSubmit}
          ref={(r) => (formRef.current = r)}
          debounceTime={10}
        >
          <div className={classes.wizardFormWrapper}>
            {/*<h6 className={classes.wizardFormHeading}>*/}
            {/*  {' '}*/}
            {/*  {getStepContent(activeStep)}{' '}*/}
            {/*</h6>*/}
            {/*{formSteps}*/}
            {disableLoginBtn ? (
              <Box width={1} display={'flex'} justifyContent="center">
                <Spinner />
              </Box>
            ) : (
              formSteps
            )}
          </div>
          <div className={classes.formFooter}>
            <div>
              {Boolean(activeStep) && (
                <Button
                  variant="contained"
                  className={classes.backButton}
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}
            </div>

            <div>
              <Button
                variant="contained"
                className={classes.nextButton}
                type="submit"
                disabled={disabledNextButton()}
                // disabled={!disableButton}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        </ValidatorForm>
        <Snackbar
          open={openErrorSnackBar}
          autoHideDuration={2000}
          onClose={handleErrorSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleErrorSnackbarClose}
            severity="error"
          >
            {openErrorSnackBarMsg || ''}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default AddUserForm;
