import api from '../../utils/api';

import {
  PROFILE_INTEGRATION_ERROR,
  LOAD_PROFILE_INTEGRATION,
  LOADING_PROFILE_INTEGRATION,
} from '../types';

// Get INTEGRATION list and set in reducer
export const fetchIntegration = (listMounted) => async (dispatch) => {
  try {
    listMounted &&
      dispatch({
        type: LOADING_PROFILE_INTEGRATION,
        payload: {
          getListLoading: true,
          listMounted: false,
        },
      });

    const res = await api.get(
      '/integrations/user_plugins?plugin_scope=user&plugin_type=UserInbox',
    );

    let integrationList = res.data;

    if (res.data.find((e) => e.status === 1)) {
      integrationList = [
        {
          ...res.data.find((e) => e.status === 1),
        },
      ];
    }

    dispatch({
      type: LOAD_PROFILE_INTEGRATION,
      payload: {
        getListLoading: false,
        integrationList: integrationList,
        listMounted: Boolean(listMounted),
      },
    });
  } catch (err) {
    dispatch({
      type: PROFILE_INTEGRATION_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
        getListLoading: false,
      },
    });
  }
};
