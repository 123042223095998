import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, FormGroup, Grid } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import Spinner from '../../layout/Spinner';
import TextEditor from '../../utils/Editor';

import { english } from '../../utils/language';
import { getUserLinkedTemplate } from '../../actions/template';
import api from '../../utils/api';
import { toast } from "react-toastify";

import useStyles from './CreateTemplateFormStyle';

const CreateTemplateForm = (props) => {
  const { templateId, closeDrawer, admin, page } = props;

  const classes = useStyles();

  const [formData, setFormData] = useState({
    uuid: uuidv4(),
    template_type: '2',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const formRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    // did mount
    setAddEditTemplateData();
  }, []);

  /**
   * will hit post api to add or edit template in the db
   */
  const handleSubmit = () => {
    if (!formData.content) {
      return;
    }
    setDisabledButton(true);

    if (templateId) {
      // edit mode
      api
        .put(`/automation/template/${templateId}`, {
          values: formData,
        })
        .then((response) => {
          closeDrawer('editTemplate');
          props.getRecords &&
            props.getRecords(undefined, props.admin ? props.admin : null);
          setDisabledButton(false);
        })
        .catch(() => {
          setDisabledButton(false);
        });
    } else {
      api
        .post('/automation/template', {
          values: formData,
        })
        .then((response) => {
          if (response.status === 201) {
            closeDrawer('addTemplate');

            if (page === 'step') {
              dispatch(getUserLinkedTemplate());
              props.getUserLinkedTemplate && props.getUserLinkedTemplate(1);
              props.toSelect &&
                props.toSelect({
                  value: response.data.id,
                  label: response.data.name,
                });
            } else {
              dispatch(getUserLinkedTemplate(undefined, admin ? admin : null));
            }
            setDisabledButton(false);
          }
        })
        .catch((err) => {
          let msg = err?.response?.data?.error;
          toast.error(msg ? msg : "Something went wrong", {
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
          setDisabledButton(false);
        });
    }
  };

  /**
   * handle tinymce editor change event
   * @param name
   * @returns {function(...[*]=)}
   */
  const handleEditorChange = (name) => (value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /**
   * handle the input change
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to set the initial data in the state
   * @returns {Promise<void>}
   */
  const setAddEditTemplateData = async () => {
    if (templateId) {
      setIsLoading(true);
      api
        .get(`/automation/template/${templateId}`)
        .then((response) => {
          setFormData({
            ...formData,
            name: response.data.name,
            content: response.data.content,
          });

          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['name', 'content'];

    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  // show spinner
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  return (
    <div className={classes.root}>
      <ValidatorForm ref={(r) => (formRef.current = r)} onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}>*/}
          {/*  {' '}*/}
          {/*  {english.linkedinTemplateEditDetail}{' '}*/}
          {/*</h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*Sequence Name && Sequence audience*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  onChange={handleChange}
                  label={`${english.linkedinTemplateForm.name} *`}
                  name="name"
                  value={formData?.name || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={[english.linkedinTemplateForm.nameValidate]}
                />
              </Grid>

              {/*html content base */}
              <Grid item xs={12} sm={12}>
                <div className={classes.multipleSelectHeading}>
                  {english.content}
                </div>
                <FormGroup>
                  <TextEditor
                    onChange={handleEditorChange('content')}
                    initialvalue={formData.content}
                    toolBar="undo redo | placeholder "
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledNextButton()}
            >
              {templateId ? english.update : english.save}
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default CreateTemplateForm;
