import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Icon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';

import Button from '../../../components/Button';
import Spinner from '../../../layout/Spinner';
import CustomSnackBar from '../../../components/CustomSnackBar';

import api from '../../../utils/api';
import { dateFormat } from '../../../utils/applicationConstant';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import { convertHtmlToString } from '../../../utils/convertHtmlToString';
import { validURL } from '../../../utils';
import { updateTaskStatus } from '../../../actions/admin/myTask';

const useStyles = makeStyles(({ custom }) => ({
  collapseEditText: {
    color: custom.colorCode.lightLightGreenColor3,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '14px',
    '& svg': {
      marginLeft: 4,
    },
  },
  completedText: {
    color: custom.colorCode.lightLightGreenColor3,
    fontSize: '14px',
    cursor: 'pointer',
    '& svg': {
      fill: custom.colorCode.lightLightGreenColor3,
      marginRight: 4,
    },
  },
  typographyText: {
    color: custom.colorCode.lightGreyColorShade16,
  },
}));

const LinkedineStartForm = ({ taskData, setCurrentTaskData, currentTab }) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  const [disabledMarkAsComplete, setDisabledMarkAsComplete] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);

  const dispatch = useDispatch();
  const myTask = useSelector((state) => state.myTask[currentTab.id]);

  useEffect(() => {
    if (taskData?.free_text_notes) {
      setFormData({
        ...formData,
        message: convertHtmlToString(taskData?.free_text_notes || ''),
      });
    }
  }, [taskData?.free_text_notes]);

  // input handle change
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Handle function of copy or text
   */
  const handleSubmit = () => {
    setDisabledButton(true);
    setTimeout(() => {
      setDisabledButton(false);
    }, 1000);
  };

  // mark the task as complete
  const handleMarkComplete = () => {
    if (taskData.status === 1 && !disabledMarkAsComplete) {
      setDisabledMarkAsComplete(true);
      api
        .put(`/automation/change-task-status/${taskData.taskId}`)
        .then(() => {
          if (
            myTask.myTaskList.find((tData) => taskData.taskId === tData.taskId)
          ) {
            dispatch(
              updateTaskStatus({
                task: taskData,
                tabProps: currentTab,
              }),
            );
          }

          setOpenSnackBar(true);
          setOpenSnackBarMessage({
            message: 'Task marked as completed',
            status: 'success',
          });
          api
            .get(`/automation/get-task-detail/${taskData.taskId}`)
            .then((taskDetailResp) => {
              setDisabledMarkAsComplete(false);
              setCurrentTaskData(taskDetailResp.data[0]);
            });
        })
        .catch((error) => {
          setDisabledMarkAsComplete(false);
          setOpenSnackBar(true);
          setOpenSnackBarMessage({
            message: error?.response?.data?.message || 'Something went wrong',
            status: 'error',
          });
        });
    }
  };

  // this will close the snackbar
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  const linkedinClick = () => {
    if (formData?.message) {
      copyToClipboard(formData?.message);
    }
    if (taskData.linkedin_profile) {
      if (
        validURL(taskData.linkedin_profile) &&
        (taskData.linkedin_profile || '').includes('http')
      ) {
        window.open(taskData.linkedin_profile);
      } else {
        if (validURL(`https://${taskData.linkedin_profile}`)) {
          window.open(`https://${taskData.linkedin_profile}`);
        } else {
          setOpenSnackBar(true);
          setOpenSnackBarMessage({
            message: 'Given LinkedIn profile is not valid profile',
            status: 'error',
          });
        }
      }
    }
  };

  const getHeaderContent = () => {
    let content = <></>;
    switch ((taskData?.taskTypeName || '').toLocaleLowerCase()) {
      case 'linkedin': {
        content = (
          <Grid item xs={12} sm={12}>
            <TextValidator
              label=""
              onChange={handleChange}
              name="message"
              value={
                formData?.message ||
                convertHtmlToString(taskData?.free_text_notes || '') ||
                ''
              }
              variant="outlined"
              fullWidth
              validators={['required']}
              multiline
              rows={4}
              maxrows={6}
              InputLabelProps={{ shrink: false }}
              errorMessages={['Please enter message']}
            />
          </Grid>
        );
        break;
      }
      case 'callback': {
        content = (
          <>
            <Grid item xs={4} sm={4}>
              <Typography variant="body2" className={classes.typographyText}>
                Due Date
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8}>
              <Typography variant="body2">
                {moment(taskData.due_date).format(dateFormat)}
              </Typography>
            </Grid>
          </>
        );
        break;
      }
    }

    return content;
  };

  return (
    <Box>
      <ValidatorForm onSubmit={handleSubmit}>
        <Box>
          <Grid container spacing={2}>
            {getHeaderContent()}
          </Grid>
        </Box>

        <Box
          mt={2}
          justifyContent="space-between"
          display="flex"
          alignItems="center"
        >
          <Box>
            {(taskData?.taskTypeName || '').toLocaleLowerCase() ===
              'linkedin' &&
              taskData.status === 1 &&
              taskData.linkedin_profile && (
                <Button
                  dataTarget={`openLinkedin`}
                  disabled={disabledButton}
                  onClick={linkedinClick}
                >
                  Copy & Open LinkedIn
                </Button>
              )}
          </Box>
          {taskData.status === 1 ? (
            <Box
              // variant="body2"
              className={classes.collapseEditText}
              onClick={handleMarkComplete}
            >
              {disabledMarkAsComplete ? (
                <Spinner style={{ width: 20, height: 20, position: 'unset' }} />
              ) : (
                <>
                  Mark as complete
                  <ArrowForwardIcon fontSize="small" />
                </>
              )}
            </Box>
          ) : (
            taskData.status === 2 && (
              <Box
                // variant="body2"
                className={classes.completedText}
                onClick={handleMarkComplete}
              >
                <CheckIcon fontSize="small" />
                COMPLETED
              </Box>
            )
          )}
        </Box>
      </ValidatorForm>
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        handleSnackbarClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </Box>
  );
};

export default LinkedineStartForm;
