import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import ListViewTab from './ListViewTab';
import ListView from './ListView';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
  },
  listContent: {
    margin: '8px 16px 16px 16px',
    '& .collapseCardContent': {
      padding: '10px 0',
    },
  },
  headerListContent: {},
}));

const SucceededListView = ({ profileType, profileId }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.headerListContent}>
        <ListViewTab />
        {/*<Box>*/}
        {/*  <Typography variant="h6"> Actions </Typography>*/}
        {/*</Box>*/}
      </Box>
      <Box className={classes.listContent}>
        <ListView profileType={profileType} profileId={profileId} />
      </Box>
    </Box>
  );
};

export default SucceededListView;
