import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TemplateGrid from './TemplateGrid/index';

// class Template extends Component {
//   constructor(props) {
//     super(props);
//   }
//
//   render() {
//     return (
//       <div className="">
//         <div className="flex-row">
//           <aside className="sideBarMargin">
//             <SidebarNav linkedin="active" />
//           </aside>
//
//           <div className="flex-col content">
//             <TemplateGrid admin {...this.props} />
//             {/* <ToastContainer autoClose={2000} /> */}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

const Template = (props) => {
  const userRole = props.auth.user?.roles?.filter((val) => val.role_id === 1);
  const admin = userRole.length > 0 ? true : false;
  return <TemplateGrid admin={admin} {...props} />;
};

Template.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Template);
