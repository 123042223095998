import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import PageHeader from '../../components/PageHeader';
import CustomDataGrid from '../../controlled-component/customGridComponent';
import AddButton from '../../components/CreateToolbarButton';
import AddCategoryDrawer from './AddCategoryDrawer';
import Dialog from '../../components/Dialog';
import LoadingTableBody from '../../components/LoadingTableBody';
import { ActiveIcon, InActiveIcon } from '../../components/GridIcons';

import {
  fetchCategories,
  deleteCategory,
  updateStatus,
} from '../../actions/admin/categories';

// template styling
const useStyles = makeStyles(() => ({
  templateGridMainSection: {},
  OfficeGridWrapper: {
    padding: '10px 0 20px 0',
  },
}));

/**
 * Component to load the category grid
 * @param {*} props
 * @returns
 */
const CategoriesGrid = (props) => {
  const classes = useStyles();

  const [popupToggle, setPopupToggle] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  //const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [catId, setCatId] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackerMessage, setSnackerMessage] = useState(null);
  const [tableParams, setTableParams] = useState({});

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const category = useSelector((state) => state.category);

  //handle function to open add/edit category popup
  const handlePopupOpen = () => {
    setCatId(false);
    setPopupToggle(!popupToggle);
  };

  //handle function to close add/edit category popup
  const handlePopupClose = () => {
    setPopupToggle(false);
    setCatId(false);
  };

  // handle the edit category pop up
  const handleEditPopupOpen = (id) => {
    setPopupToggle(!popupToggle);
    setCatId(id);
  };

  // handle the Dialog pop up open
  const handleDialogOpen = (id) => {
    setDialogOpen(!dialogOpen);
    setCatId(id);
  };

  // handle the Dialog pop up close
  const handleDialogClose = () => {
    setDialogOpen(!dialogOpen);
    setCatId(false);
  };

  // handle function to remove category
  const removeCategory = (catId) => {
    dispatch(deleteCategory(catId, showSnackbar, tableParams));
    handleDialogClose();
  };

  // handle function to change status
  const changeStatus = (catId, status) => {
    dispatch(updateStatus(catId, !status, showSnackbar, tableParams));
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setSnackerMessage(null);
    setOpenSnackBar(false);
  };
  /**
   * Handle function to close the toaster popup
   */
  const showSnackbar = (message) => {
    setSnackerMessage(message);
    setOpenSnackBar(true);
  };

  useEffect(() => {
    // did mount
    // call the team api and set data in reducer
    dispatch(fetchCategories());
  }, []);

  /**
   * Function to handle click on name
   * @param {*} e
   * @param {*} data
   */
  const handleNameClick = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    handleEditPopupOpen(data.id);
  };

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Job Category',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: '85%', width: '85%', maxWidth: '85%' },
          }),
          setCellProps: () => ({
            style: { minWidth: '85%', width: '85%', maxWidth: '85%' },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = category.categories.data[dataIndex];
            return (
              <Tooltip title={rData.name || ''} arrow>
                <div
                  className={`sort-by td-content-ellipsis td-anchor-content-ellipsis`}
                  onClick={(e) => handleNameClick(e, rData)}
                >
                  {rData.name}
                </div>
              </Tooltip>
            );
          },
        },
      },

      {
        label: 'Status',
        name: 'status_label',
        options: {
          filter: false,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 150, maxWidth: 150 } }),
          customBodyRender: (value) => {
            return value === 'Active' ? (
              <ActiveIcon fontSize="small" />
            ) : (
              <InActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = category.categories.data.find(
              (tData) => tData.id === value,
            );
            return (
              <GridActions
                temp_id={row?.id}
                handleEditPopupOpen={handleEditPopupOpen}
                status={row?.status}
                row={row}
                removeCategory={handleDialogOpen}
                changeStatus={changeStatus}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [category.categories.data],
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={category.loading} {...props} />,
    [category.loading],
  );

  return (
    <div className={classes.templateGridMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Job Categories'}
        subTitle={'Create, edit and remove categories against your account.'}
        itemCount={category?.categories?.count || 0}
      />
      <div className={classes.OfficeGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns}
          data={category.categories.data}
          options={{
            pagination: Boolean(category?.categories?.count),
            selectableRows: 'multiple',
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search Categories',
            selectToolbarPlacement: 'none',
            searchOpen: true,
            search: true,
            serverSide: true,
            rowsPerPage: category?.rowsPerPage || 25,
            count: category?.categories?.count,
            onTableChange: (action, tableState) => {
              // a developer could react to change on an action basis or
              // examine the state as a whole and do whatever they want
              switch (action) {
                case 'changePage':
                case 'sort':
                case 'changeRowsPerPage':
                case 'search':
                  // eslint-disable-next-line no-case-declarations
                  let sortOrder =
                    Object.keys(tableState.sortOrder).length === 0
                      ? null
                      : tableState.sortOrder;
                  // eslint-disable-next-line no-case-declarations
                  const params = {
                    page: tableState.page || 0,
                    sortOrder: sortOrder,
                    rowsPerPage: tableState.rowsPerPage || 25,
                    searchText: tableState.searchText,
                  };
                  setTableParams(params);
                  dispatch(fetchCategories(params));
                  //this.changePage(tableState.page, tableState.sortOrder);
                  //props.onTableChange(params);
                  break;
                /* case 'sort':
                  //this.sort(tableState.page, tableState.sortOrder);
                  break; */
                case 'viewColumnsChange':
                case 'columnOrderChange':
                  //function call
                  break;
                default:
                //console.log('action not handled.');
              }
            },
            customToolbar: () => {
              // Toolbar for search and add
              return (
                <AddButton
                  handleClick={handlePopupOpen}
                  title={`Create Category`}
                  dataTarget={`createCategory`}
                />
              );
            },
          }}
          components={{ TableBody: BodyComponent }}
        />

        <AddCategoryDrawer
          catId={catId}
          openDrawer={popupToggle}
          onClose={handlePopupClose}
        />
        <Dialog
          objectId={catId}
          open={dialogOpen}
          title="Remove Job Category Confirmation"
          content="Are you sure, you want to remove this category? Once it is removed can't be undone!"
          handleClick={removeCategory}
          onClose={handleDialogClose}
          buttonText={`Confirm`}
        />
        <Snackbar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleSnackbarClose}
            severity="success"
          >
            {snackerMessage}
          </Alert>
        </Snackbar>
        {/* <ToastContainer autoClose={2000} /> */}
      </div>
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const { auth, row } = props;
  /* const { user } = auth;
  const admin = props.admin ? props.admin : false; */
  const [anchorElement, setAnchorElement] = useState(null);

  //const editable = user.id === row.created_by;
  const menuId = 'action-menu-id';

  /**
   * Handle function for menu item
   * @param {*} event
   */
  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  /**
   * Function to dispatch action on selection of menu item
   * @param {*} type
   * @returns
   */
  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      case 'changeStatus': {
        props.changeStatus(props.temp_id, row.status);
        break;
      }
      case 'removeCategory': {
        props.removeCategory(props.temp_id);
        break;
      }
      default:
        break;
    }
  };

  /**
   * Menu items
   */
  const menuItem = [
    {
      title: 'Edit',
      itemType: 'editPopUp',
    },
    {
      title: 'Change Status',
      itemType: 'changeStatus',
    },
    {
      title: 'Remove Category',
      itemType: 'removeCategory',
    },
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default CategoriesGrid;
