import api from '../utils/api';
/* import { setAlert } from './alert'; */

import { GET_NOTIFICATIONS, NOTIFICATION_ERROR } from '../actions/types';

// Get current users Notifications
export const getUserNotifications = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/get-notifications');
    //console.log(res);
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// update
export const updateNotificationFilter = (data, onHide) => async (dispatch) => {
  try {
    const values = {
      filters: data,
    };
    await api
      .post('/automation/update-notification-filter', {
        values,
      })
      .then((res) => {
        if (res.status === 200) {
          onHide();
          dispatch({
            type: GET_NOTIFICATIONS,
            payload: res.data,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
