import {
  PROFILE_INTEGRATION_ERROR,
  LOAD_PROFILE_INTEGRATION,
  LOADING_PROFILE_INTEGRATION,
} from '../../actions/types';

const initialState = {
  integrationList: [],
  getListLoading: true,
  error: {},
  setDetailListLoading: true,
  integrationDetail: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_PROFILE_INTEGRATION: {
      const data = {
        ...state,
        ...payload,
      };
      return data;
    }
    case LOAD_PROFILE_INTEGRATION:
      return {
        ...state,
        ...payload,
      };
    case PROFILE_INTEGRATION_ERROR:
      return {
        ...state,
        error: payload,
        getListLoading: false,
      };
    default:
      return state;
  }
}
