import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fade, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Link } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import GridAddNewViewsPopup from './GridAddNewViewsPopup';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '33%',
    /* maxWidth: 360, */
    backgroundColor: theme.palette.background.paper,
    float: 'left',
    borderLeft: '1px solid rgb(203, 214, 226)',
    height: '100%',
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#f76978',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontSize: '16px !important',
    fontWeight: '600',
  },
  link: {
    margin: `0 ${theme.spacing(2) + 4}px`,
    flex: 1,
    fontSize: '16px !important',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * List all Object views
 */
const GridAllTabsPopup = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { object, tabs, handleTabClick, auth, views } = props;
  const [allTabs, setAllTabs] = React.useState([]);
  let user_id = auth.user.id;

  useEffect(() => {
    setAllTabs(views.tabs);
  }, [views.tabs]);
  //Open dialog handle function
  const handleClickOpen = () => {
    setOpen(true);
  };

  //Close dialog handle function
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Handle search field event and search data in all tabs
   * @param {*} event
   */
  const handleSearch = (event) => {
    let searchText = event.target.value;
    if (searchText.length > 0) {
      const searchResult = allTabs.filter(
        (tab) =>
          tab.name.toLowerCase().includes(searchText.toLowerCase()) === true,
      );
      setAllTabs(searchResult);
    } else {
      setAllTabs(views.tabs);
    }
  };

  //Filter system views
  const systemViews = allTabs.filter((tab) => tab.filter_type === 1);
  //Filter user views
  const myViews = allTabs.filter(
    (tab) => tab.filter_type === 2 && tab.user_id === user_id,
  );
  //Filter other user views
  const otherViews = allTabs.filter(
    (tab) => tab.filter_type === 2 && tab.user_id != user_id,
  );

  /**
   * Function to return list item
   * @param {*} tabs
   * @returns
   */
  const listItems = (tabs) => {
    return tabs.map((tab) => {
      return (
        <React.Fragment key={`fr-${tab.id}`}>
          <ListItem button key={`li-${tab.id}`}>
            <ListItemText
              onClick={() => handleTabClick(tab.id, handleClose)}
              primary={tab.name}
              key={`lit-${tab.id}`}
            />
          </ListItem>
        </React.Fragment>
      );
    });
  };

  return (
    <div>
      <GridAddNewViewsPopup {...props} />

      <Link className={classes.link} onClick={handleClickOpen} to="#">
        All Views
      </Link>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} color={'secondary'}>
          <Toolbar color="secondary">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              All {object.charAt(0).toUpperCase() + object.slice(1)} saved views
              ({allTabs.length})
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleSearch}
              />
            </div>
          </Toolbar>
        </AppBar>
        <div style={{ maxWidth: '100%' }}>
          <div className={classes.root}>
            <Typography variant="h6" className={classes.title}>
              Standard
            </Typography>
            <List component="nav">{listItems(systemViews)}</List>
          </div>
          <div className={classes.root}>
            <Typography variant="h6" className={classes.title}>
              Created by me
            </Typography>
            <List component="nav">{listItems(myViews)}</List>
          </div>
          <div className={classes.root}>
            <Typography variant="h6" className={classes.title}>
              Created by others
            </Typography>
            <List component="nav">{listItems(otherViews)}</List>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

/**
 * Extract state props
 */
const mapStateToProps = (state) => ({
  auth: state.auth,
  views: state.tabs,
});

export default connect(mapStateToProps)(GridAllTabsPopup);
