import React from 'react';
import MUIBadge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';

// material ui design styles
const useStyles = makeStyles(({ custom }) => ({
  root: {},
  badgeName: {},
  badge: {
    width: 'max-content',
    backgroundColor: custom.colorCode.lightGreyColorShade9,
    height: 19,
    fontSize: 11,
    color: custom.colorCode.blueLightShade8,
    fontWeight: 500,
  },
}));

const Badge = ({
  countContent,
  className = '',
  onClick = () => {},
  children,
  ...props
}) => {
  const classes = useStyles();

  const handleClick = (event) => {
    onClick(event);
  };

  return (
    <MUIBadge
      badgeContent={countContent || 0}
      className={`${className} ${classes.badgeName}`}
      color={'secondary'}
      classes={{
        badge: classes.badge,
        root: classes.root,
      }}
      max={999}
      onClick={handleClick}
      {...props}
    >
      {children}
    </MUIBadge>
  );
};

export default Badge;
