import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  InputBase,
  Link,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';

import Card from '../../../components/Card';
import CustomDataGrid from '../../../controlled-component/customGridComponent';
import LoadingTableBody from '../../../components/LoadingTableBody';
import DateRangePicker from '../../../components/DateTimePickers/DateRangePicker';
import StepTemplates from '../StepTemplates/StepTemplates';
import Button from '../../../components/Button';
import FormAutoCompleteSelect, {
  AutoCompletePopper,
} from '../../../components/FormAutoCompleteSelect';

import {
  getSequenceReport,
  getSequenceStepsReport,
} from '../../../actions/sequence';
import { dateFormat } from '../../../utils/applicationConstant';

const useStyles = makeStyles(({ custom }) => ({
  mainPageWrapper: {},
  selectContainerWrapper: {
    display: 'flex',
    width: '100%',
    marginBottom: 20,
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
    '& .formControl-label': {
      fontSize: 14,
      color: custom.colorCode.blackShade1,
      marginRight: 20,
      marginBottom: 3,
    },
  },
  selectEmpty: {},
  selectEmptyRoot: {
    color: custom.colorCode.fontBlack,
    fontWeight: 700,
    fontSize: 14,
  },
  selectEmptyIcon: {
    color: custom.colorCode.fontBlack,
  },
  cardHeader: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',

    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title': {
      width: '70%',
      color: custom.colorCode.fontBlack,
    },
    '& .value': {
      width: '30%',
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 18,
      color: custom.colorCode.fontBlack,
    },
  },
  summaryContainerInfoIcon: {
    height: 20,
    color: '#93c3e6',
    transform: 'translate(0px, 2px)',
  },
  summaryDatePicker: {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: 120,
    '& .MuiIconButton-root': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  gridContent: {},
  gridContentData: {
    borderBottom: '1px solid #f3f4f7',
  },
  gridContentRowData: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0',
    borderBottom: '1px solid #f3f4f7',
    '&:last-child': {
      borderBottom: 'none',
      padding: '5px 0 0 0',
    },
    '& .title': {
      color: custom.colorCode.blackShade1,
      fontWeight: 500,
    },
  },
  mainCardSummary: {
    boxShadow: '#F5F7F9 0px 0px 0px 2px',
  },
  cardContentSummary: {
    paddingBottom: '16px !important',
  },
  stepMainGrid: {
    marginTop: 27,
  },
  tdContentEllipsis: {
    width: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 3,
  },
  actionButton: {
    height: 30,
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    '& span': {
      fontSize: 15,
    },
    ':hover': {
      backgroundColor: custom.colorCode.blueLightShade11,
    },
    ':focus': {
      backgroundColor: custom.colorCode.blueLightShade11,
    },
  },
  popoverStyle: {
    left: '-50px !important',
  },
  enrolledByAutoComplete: {
    '& .MuiSvgIcon-root': {
      color: custom.colorCode.fontBlack,
    },
    '& .MuiAutocomplete-input': {
      minWidth: '50px',
      maxWidth: 'auto',
      fontSize: 14,
      fontWeight: 700,
    },
  },
  buttonSelectContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectDatePicketText: {
    display: 'flex',
    marginBottom: 5,
    cursor: 'pointer',
  },
}));

/**
 * Component for summary tab on sequence detail page
 * @param {*} props
 * @returns
 */
const Summary = (props) => {
  const { users, sequence_id, dateFilterOptions } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const sequence = useSelector((state) => state.sequence);

  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [datePickerLabel, setDatePickerLabel] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [stepData, setStepData] = useState({});
  const [formData, setFormData] = useState({
    enrolledBy: '',
    enrolledDate: '',
  });
  const [showDatePopOver, setShowDatePopOver] = useState(null);

  /**
   * Handle function of onchange filters
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    if (event.target.value != 10) {
      handleFilterChange({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  // handle custom date change
  const handleDateChange = (name) => (date) => {
    setFormData({
      ...formData,
      [name]: date,
    });
    //handleFilterChange();
  };

  // handle onclick function of go button for custom date filter
  const handleDateFilterClick = () => {
    handleFilterChange(formData);
  };

  const getSequenceReportData = async () => {
    setIsLoading(true);
    await dispatch(getSequenceReport(sequence_id));
    await dispatch(getSequenceStepsReport(sequence_id));
    setIsLoading(false);
  };

  // did mount to call api one for one time
  useEffect(() => {
    getSequenceReportData();
  }, [sequence_id]);

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name) => (event, tag) => {
    const newFormData = {
      ...formData,
      [name]: tag.id === 'all' ? '' : tag.id,
    };
    setFormData(newFormData);

    handleFilterChange(newFormData);
  };

  /**
   * Handle function for filter change
   * It will call the api to change sequence and step stats
   */
  const handleFilterChange = async (params) => {
    //console.log('called:filter');
    setIsLoading(true);
    await dispatch(getSequenceReport(sequence_id, params));
    await dispatch(getSequenceStepsReport(sequence_id, params));
    setIsLoading(false);
  };

  const handleTemplatesPoup = (rowData) => (e) => {
    e.preventDefault();
    setOpen(!open);
    setStepData(rowData);
    setTemplateList(rowData.templateList);
  };

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={isLoading} {...props} />,
    [isLoading],
  );

  // Sequence stats data
  const sequenceSummaryCardDetail = [
    {
      name: 'enrolled',
      mainTitle: 'Enrolled',
      value: sequence?.stats?.total_enrolled || 0,
      data: [
        {
          name: 'Completed',
          value: sequence?.stats?.completed || 0,
        },
        {
          name: 'Pending',
          value: sequence?.stats?.pending || 0,
        },
      ],
    },
    {
      name: 'openRate',
      mainTitle: 'Open Rate',
      value:
        (Number(sequence?.stats?.total_delivered) &&
          (
            (
              Number(sequence.stats.total_opened) /
              Number(sequence.stats.total_delivered)
            ).toFixed(2) * 100
          ).toFixed(2) + '%') ||
        0 + '%',
      data: [
        {
          name: 'Emails Sent',
          value: sequence?.stats?.total_sent || 0,
        },
        {
          name: 'Unique Opens',
          value: sequence?.stats?.total_opened || 0,
        },
      ],
    },
    {
      name: 'clickRate',
      mainTitle: 'Click Rate',
      value:
        (Number(sequence?.stats?.total_delivered) &&
          (
            (
              Number(sequence.stats.total_clicked) /
              Number(sequence.stats.total_delivered)
            ).toFixed(2) * 100
          ).toFixed(2) + '%') ||
        0 + '%',
      data: [
        {
          name: 'Emails Opened',
          value: sequence?.stats?.total_opened || 0,
        },
        {
          name: 'Unique Clicks',
          value: sequence?.stats?.total_clicked || 0,
        },
      ],
    },
    {
      name: 'exitAnalysis',
      mainTitle: 'Exit Analysis',
      value:
        (Number(sequence?.stats?.completed) &&
          (
            (
              Number(sequence.stats.successfull_completed) /
              Number(sequence.stats.completed)
            ).toFixed(2) * 100
          ).toFixed(2) + '%') ||
        0 + '%',
      data: [
        {
          name: 'Successful',
          value: sequence?.stats?.successfull_completed || 0,
        },
        {
          name: 'Unsuccessful',
          value: sequence?.stats?.unsuccessfull_completed || 0,
        },
      ],
    },
  ];

  const handleDatePickerClose = () => {
    setShowDatePopOver(null);
  };

  const handleDatePickerChange = (dateData) => {
    setFormData({
      ...formData,
      enrolledDate: 10,
      startDate: dateData.startDate,
      endDate: dateData.endDate,
    });
    setDatePickerLabel(dateData?.label || '');
    handleFilterChange({
      ...formData,
      enrolledDate: 10,
      startDate: dateData.startDate,
      endDate: dateData.endDate,
    });
  };

  const openDatePicker = (event) => {
    setShowDatePopOver(event.currentTarget);
  };

  // columns for the steps table grid
  const columns = useMemo(
    () => [
      {
        name: 'event_order',
        label: '#',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 70, width: 70, maxWidth: 70 },
          }),
          setCellProps: () => ({
            style: { minWidth: 70, width: 70, maxWidth: 70 },
          }),
          customBodyRenderLite: (dataIndex) => {
            let rowData = sequence.steps[dataIndex];
            let stepCount = dataIndex + 1;
            return (
              <div className={'td-content-ellipsis'}>
                {stepCount}
                {/* (rowData.type === "1") ? <StepTempalte step={rowData} templates={rowData.templateList} />
                :null */}
              </div>
            );
          },
        },
      },
      {
        label: 'Step Name',
        name: 'step_name',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 150, maxWidth: 150 },
          }),
          setCellProps: () => ({
            style: { minWidth: 150, maxWidth: 150 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value || ''} arrow>
                <div className={classes.tdContentEllipsis}>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Step Type',
        name: 'event_type',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            let rowData = sequence.steps[dataIndex];
            let abtempates = rowData?.templateList?.filter(
              (row) => row.status === 1,
            );
            return (
              <div>
                {rowData.event_type === 'email' ? (
                  <Link
                    to="#"
                    href="#"
                    onClick={handleTemplatesPoup(rowData)}
                  >
                    {rowData.event_type[0].toUpperCase() +
                      rowData.event_type.slice(1)}
                    {abtempates.length > 1 ? ` (A/B)` : null}
                  </Link>
                ) : (
                  (rowData.event_type &&
                    rowData.event_type[0].toUpperCase() +
                      rowData.event_type.slice(1)) ||
                  ''
                )}
              </div>
            );
          },
        },
      },
      {
        label: 'Enrolled',
        name: 'total_enrolled',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      {
        label: 'Unsubscribe',
        name: 'total_unsubscribe',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      {
        label: 'Open Rate',
        name: 'open_rate',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}%</div>;
          },
        },
      },
      {
        label: 'Clicked Rate',
        name: 'click_rate',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}%</div>;
          },
        },
      },
      {
        label: 'Bounce Rate',
        name: 'bounce_rate',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}%</div>;
          },
        },
      },
      /* {
        label: 'Completion Rate',
        name: 'completion_rate',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      }, */
    ],
    [sequence.steps],
  );

  return (
    <div className={classes.mainPageWrapper}>
      <div className={classes.selectContainerWrapper} style={{}}>
        <div className={classes.selectContainer}>
          <span className="formControl-label"> Enrolled By: </span>
          <FormControl className={classes.formControl}>
            <FormAutoCompleteSelect
              className={classes.enrolledByAutoComplete}
              onChange={handleSelectChange('enrolledBy')}
              name="enrolledBy"
              id="enrolledBy"
              PopperComponent={(props) =>
                AutoCompletePopper({
                  ...props,
                  className: `${props.className} ${classes.popoverStyle}`,
                })
              }
              value={
                formData.enrolledBy
                  ? [{ name: 'All', id: '' }, ...users]?.find(
                      (c) => c.id === formData?.enrolledBy,
                    ) || ''
                  : { name: 'All', id: '' }
              }
              disableClearable
              options={[{ name: 'All', id: '' }, ...users] || []}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <InputBase
                    {...params.InputProps}
                    {...rest}
                    style={{ width: 'fit-content' }}
                  />
                );
              }}
            />
          </FormControl>
        </div>

        <div className={classes.selectContainer}>
          <span className="formControl-label"> Enrolled Date: </span>
          <Typography
            onClick={openDatePicker}
            variant="body2"
            component={'div'}
            className={classes.selectDatePicketText}
          >
            {formData?.startDate && formData?.endDate
              ? datePickerLabel
                ? datePickerLabel
                : `${moment(formData?.startDate).format(
                    dateFormat,
                  )} to ${moment(formData?.endDate).format(dateFormat)}`
              : 'Select Date'}
            <ArrowDropDownIcon fontSize="small" />
          </Typography>
          <DateRangePicker
            onClose={handleDatePickerClose}
            open={Boolean(showDatePopOver)}
            anchorEl={showDatePopOver}
            dateRange={{
              startDate: formData?.startDate,
              endDate: formData?.endDate,
            }}
            onDatePickerChange={handleDatePickerChange}
          />
        </div>
      </div>

      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {sequenceSummaryCardDetail.map((seqCardData, index) => (
          <Grid item xs={6} sm={3} key={seqCardData.name + index}>
            <Card
              className={classes.mainCardSummary}
              cardContent={{
                className: classes.cardContentSummary,
                children: (
                  <>
                    <div className={classes.cardHeader}>
                      <div className="title">{seqCardData.mainTitle}</div>
                      <div className="value">{seqCardData.value}</div>
                    </div>

                    <div className={classes.gridContent}>
                      {seqCardData.data.map((cardData, sIndex) => (
                        <div
                          className={classes.gridContentRowData}
                          key={cardData.name + index + sIndex}
                        >
                          <div className={'title'}>{cardData.name}</div>
                          <div className={'value'}>{cardData.value}</div>
                        </div>
                      ))}
                    </div>
                  </>
                ),
              }}
            />
          </Grid>
        ))}
      </Grid>

      <CustomDataGrid
        columns={columns}
        data={sequence.steps}
        hideToolBar={true}
        paperWidth={'287px'}
        className={classes.stepMainGrid}
        options={{
          pagination: true,
          selectableRows: 'none',
          filterType: 'dropdown',
          responsive: 'simple',
          selectToolbarPlacement: 'none',
        }}
        components={{ TableBody: BodyComponent }}
      />
      <StepTemplates
        step={stepData}
        templates={templateList}
        open={open}
        setOpen={setOpen}
        sequenceId={sequence_id}
        sequenceName={sequence?.details?.name}
      />
    </div>
  );
};

export default Summary;
