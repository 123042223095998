import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isArray } from 'lodash';

import FilterPickers from '../../components/FilterPickers';
import Spinner from '../../layout/Spinner';
import api from '../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  filterWrapper: {},
  filterTitle: {
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    color: custom.colorCode.lightGreyColorShade16,
  },
  filterItem: {
    fontSize: 16,
  },
}));

const FilterActivity = ({ getFilterState, filterItemData, filterData }) => {
  const classes = useStyles();

  const handleChange = (changeValue) => {
    if (changeValue.type === 'dateRangePicker') {
      getFilterState({
        ...filterData,
        [changeValue.type]: {
          ...filterData[changeValue.type],
          filter: changeValue.data,
        },
      });
    } else {
      getFilterState({
        ...filterData,
        [changeValue.name]: {
          ...filterData[changeValue.name],
          filter: changeValue.filter,
        },
      });
    }
  };

  return (
    <Box className={classes.filterWrapper} display="flex" alignItems="center">
      <Typography className={classes.filterTitle}>Filter by</Typography>

      {filterItemData.map((filter, index) => (
        <FilterPickers
          key={`option-filter-items-${filter.name}-${index}`}
          allOptions={isArray(filter.data) ? filter.data : []}
          optValue={filter.optionValue}
          optLabel={filter.optionLabel}
          title={filter.title}
          name={filter.name}
          type={filter.type}
          selectedPickers={filterData[filter.type]?.filter}
          isSingle={filter.isSingle}
          filter={filter}
          selectedFilter={filterData[filter.type]}
          handleFilterChange={handleChange}
          className={classes.filterItem}
          drawerFilter={undefined}
        />
      ))}
    </Box>
  );
};

export default FilterActivity;
