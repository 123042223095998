import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Drawers from './Drawers';
import NewViewsForm from '../../popupforms/NewViewsForm';
import Popover from './Popover';
import { Link, Tabs, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 400,
    margin: theme.spacing(2),
  },
  iconButton: {},
  filterPaper: {
    maxWidth: '30%',
    minWidth: '300px',
  },
  filterCloseIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#4d86bf !important',
  },
  savebutton: {
    margin: theme.spacing(1),
    backgroundColor: '#344e69 !important',
  },
}));

const SaveViews = (props) => {
  const [hideViewsPopover, setHideViewsPopover] = useState(false);

  const handlePopoverClick = () => {
    setHideViewsPopover(false);
  };

  const viewsPopoverExit = () => {
    setHideViewsPopover(false);
  };

  const closeViewsPopover = () => {
    setHideViewsPopover(true);
  };

  const styleclasses = useStyles();

  return (
    <React.Fragment>
      <Popover
        refExit={viewsPopoverExit}
        classes={{ closeIcon: styleclasses.filterCloseIcon }}
        hide={hideViewsPopover}
        trigger={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={styleclasses.button}
            startIcon={<SaveIcon />}
            onClick={handlePopoverClick}
          >
            Save View
          </Button>
        }
        content={<PoperContent handleClose={closeViewsPopover} {...props} />}
      />
    </React.Fragment>
  );
};

const PoperContent = (props) => {
  const { tabvalue, handleClose, auth, tabs } = props;
  //Check current selected view is created by logedin user or not
  let editable = false;
  let user_id = auth.user.id;
  const tabsrecord = tabs.tabs ? tabs.tabs : null;
  if (Object.keys(tabsrecord).length > 0) {
    const tabData = tabsrecord.filter((tab) => tab.id === Number(tabvalue));
    if (tabData[0].filter_type === 2 && tabData[0].user_id === user_id) {
      editable = true;
    }
  }

  /**
   * Handle function of save button
   */
  const handleSaveClick = () => {
    props.handleUpdateTabFilters(handleClose);
  };

  const handleResetClick = () => {
    props.handleResetFilters();
    handleClose();
  };
  const styleclasses = useStyles();
  return (
    <div className={styleclasses.root}>
      <Typography variant="h6" gutterBottom>
        {!editable ? `Read-only view` : `Editable view`}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {!editable
          ? `This is a system standard view or created by someone else. Save as new view to keep your changes.`
          : `This view was created by you. You can save filters, sort, and column
        edits to this view.`}
      </Typography>
      <Typography variant="button" display="block" gutterBottom>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={styleclasses.savebutton}
          onClick={handleSaveClick}
          disabled={!editable ? true : false}
        >
          Save
        </Button>
        <Button variant="contained" size="medium" onClick={handleResetClick}>
          Reset
        </Button>
      </Typography>
      <Typography variant="button" display="block" gutterBottom>
        <NewViews {...props} />
      </Typography>
    </div>
  );
};

const NewViews = (props) => {
  const styleclasses = useStyles();
  const { object } = props;

  const [hideViewsDrawer, setHideViewsDrawer] = useState(false);
  const handleClick = () => {
    setHideViewsDrawer(false);
  };
  const viewsDrawerExit = () => {
    setHideViewsDrawer(false);
  };

  const closeViewsDrawer = () => {
    setHideViewsDrawer(true);
  };

  return (
    <Drawers
      refExit={viewsDrawerExit}
      hide={hideViewsDrawer}
      classes={{
        paper: styleclasses.filterPaper,
        closeIcon: styleclasses.filterCloseIcon,
      }}
      trigger={
        <Tooltip title={'Save View'}>
          <Link component="button" variant="body2" onClick={handleClick}>
            Save New
          </Link>
        </Tooltip>
      }
      content={
        <NewViewsForm
          handleClose={closeViewsDrawer}
          object={object}
          {...props}
        />
      }
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  tabs: state.tabs,
});

export default connect(mapStateToProps)(SaveViews);
