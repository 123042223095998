import React from 'react';
import MUITooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const Tooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 16,
  },
}))(MUITooltip);

export default Tooltip;
