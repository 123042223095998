import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Snackbar,
  Box,
} from '@material-ui/core';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { isEmpty, isEqual } from 'lodash';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

import CustomDataGrid from '../../controlled-component/customGridComponent';
import Button, { OutlinedButton } from '../../components/Button';
import LoadingTableBody from '../../components/LoadingTableBody';
import CreateMyTask from './CreateMyTask';
import StartTaskDrawer from './StartTaskDrawer';
import TaskTableToolbar from '../../controlled-component/customGridComponent/CustomToolbar/TableToolbar';
import CustomSelectToolbar from '../../controlled-component/customGridComponent/CustomSelectToolbar';
import { ActiveIcon } from '../../components/GridIcons';

import {
  fetchMyTask,
  fetchMyTaskFilers,
  updateTaskStatus,
} from '../../actions/admin/myTask';
import api from '../../utils/api';
import { convertHtmlToString } from '../../utils/convertHtmlToString';
import { dateFormat } from '../../utils/applicationConstant';
import { makeProperNamingStatement } from '../../utils';

import 'react-toastify/dist/ReactToastify.css';

// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  myTaskGridWrapper: {
    padding: '10px 0 10px 0',
  },
  ellipsis: {
    width: 150,
    overflow: 'hidden',
    display: 'inlineBlock',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  noteEllipsis: {
    width: '100%',
    height: 20,
    overflow: 'hidden',
    display: 'inlineBlock',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 368,
  },
  openTaskIcon: {
    color: custom.colorCode.lightGreyColorShade14,
  },
  selectToolbarWrapper: {
    padding: 8,
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  selectToolbar: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    color: custom.colorCode.blueLightShade12,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    '& svg': {
      marginRight: 4,
    },
  },
}));

const MyTaskGrid = (props) => {
  const classes = useStyles();

  const [popupToggle, setPopupToggle] = useState(false);
  const [startTaskDrawer, setStartTaskDrawer] = useState(false);
  const [taskId, setTaskId] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState({
    severity: 'success',
    text: 'Task status changed',
  });
  const [myTaskFilter, setMyTaskFilter] = useState({});
  const [filters, setFilters] = useState([]);
  const [facetdata, setFacetdata] = useState({});
  const [userrecords, setUserrecords] = useState([]);
  const [facetfields, setFacetfields] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);

  const filterStateRef = useRef({});
  const gridParams = useRef({});
  const gridColParams = useRef({});
  const filterStateParams = useRef({});

  const dispatch = useDispatch();
  const myTask = useSelector((state) => state.myTask[props.tabProps.id]);
  const userData = useSelector((state) => state.auth.user);

  //handle function to open add task popup
  const handlePopupOpen = () => {
    setTaskId(false);
    setPopupToggle(!popupToggle);
  };

  //handle function to open add task popup
  const handleStartTaskDrawer = () => {
    setStartTaskDrawer(!startTaskDrawer);
  };

  useEffect(() => {
    gridParams.current = myTask.gridParams;
  }, [myTask.gridParams]);

  useEffect(() => {
    filterStateParams.current = myTask.filterState;
  }, [myTask.filterState]);

  useEffect(() => {
    if (!isEmpty(myTask.columns)) {
      gridColParams.current = myTask.columns;
    }
  }, [myTask.columns]);

  // handle the edit user pop up
  const handleEditPopupOpen = (id) => {
    /* setEditPopupToggle(!editPopupToggle); */
    setPopupToggle(!popupToggle);
    setTaskId(id);
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  /**
   * Handle function to close the drawer
   */
  const handlePopupClose = (closeData) => {
    if (closeData) {
      dispatch(
        fetchMyTask(
          {
            ...filterStateParams.current,
          },
          {
            ...gridParams.current,
            pageNo: 0,
            perPage: 25,
          },
          props.tabProps,
          gridColParams.current,
        ),
      );
    }
    setPopupToggle(false);
  };

  /**
   * Handle function to close the drawer
   */
  const handleStartTaskClose = () => {
    setStartTaskDrawer(false);
  };

  useEffect(() => {
    // my task data update this will update the options
    if (myTask.myTaskList.length && myTask.myFilterData.length) {
      setFacetdata({});
      setFacetfields(
        myTask.myFilterData.map((e) => ({
          label: e.name,
          value: e.title,
        })),
      );
    }
    filterStateRef.current = myTask.filterState;
  }, [myTask]);

  useEffect(() => {
    // did mount
    dispatch(fetchMyTaskFilers(props.tabProps));
  }, []);

  useEffect(() => {
    if (!isEmpty(myTask.filterList) && userData?.id) {
      // call the my task api and set data in reducer
      dispatch(
        fetchMyTask(
          undefined,
          myTask.gridParams
            ? myTask.gridParams
            : { pageNo: 0, perPage: 25, searchText: null },
          props.tabProps,
          undefined,
        ),
      );
    }
  }, [myTask.filterList, userData]);

  const handleStatusChange = (statusTask) => (event) => {
    api
      .put(`/automation/change-task-status/${statusTask.taskId}`)
      .then(() => {
        dispatch(
          updateTaskStatus({
            task: statusTask,
            tabProps: props.tabProps,
          }),
        );
        // setOpenSnackBar(true);
        // setOpenSnackBarMessage({
        //   severity: 'success',
        //   text: 'Task status changed',
        // });
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          severity: 'error',
          text: error?.response?.data?.error || 'Something went wrong',
        });
      });
    if (event.currentTarget.className.baseVal.includes(classes.openTaskIcon)) {
      const classArray = event.currentTarget.className.baseVal.split(' ');
      const classIndex = classArray.findIndex(
        (e) => e === classes.openTaskIcon,
      );
      classArray.splice(classIndex, 1);
      event.currentTarget.className.baseVal = classArray.join(' ');
    } else {
      event.currentTarget.className.baseVal =
        `${classes.openTaskIcon} ` + event.currentTarget.className.baseVal;
    }
  };

  // columns of the table
  const columns = useMemo(
    () => [
      {
        label: 'Status',
        name: 'status',
        options: {
          filter: true,
          sort: true,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = myTask.myTaskList[dataIndex];
            const currentTaskStatus = myTask.filterList?.taskStatusList?.find(
              (taskType) => taskType.id === rData.status,
            );
            return (
              <Tooltip title={currentTaskStatus?.name || ''} arrow>
                <Box>
                  <ActiveIcon
                    style={{ cursor: 'pointer', transition: 'all 1.8s ease 0s' }}
                    className={
                      currentTaskStatus?.name === 'Open'
                        ? classes.openTaskIcon
                        : ''
                    }
                    fontSize="small"
                    onClick={handleStatusChange(rData)}
                  />
                </Box>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Person',
        name: 'common_name',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = myTask.myTaskList[dataIndex];
            return <div>{makeProperNamingStatement(rData.common_name || '')}</div>;
          },
        },
      },
      {
        label: 'Task Type',
        name: 'taskTypeName',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRender: (value) => {
            /* const currentTaskType = myTask.filterList?.taskTypeListForFilter?.find(
              (taskType) => taskType.id === value,
            )?.name; */
            return (
              <Tooltip title={value || ''} arrow>
                <div>{value || null}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Notes',
        name: 'free_text_notes',
        options: {
          filter: false,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 400 },
          }),
          setCellProps: () => ({
            style: { minWidth: 400 },
          }),
          customBodyRender: (value) => {
            const stringValue = convertHtmlToString(value);
            return (
              <Tooltip title={stringValue} arrow>
                <div className={classes.noteEllipsis}>{stringValue}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Due Date',
        name: 'due_date',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          customBodyRender: (value) => {
            return <Moment format={dateFormat}>{value}</Moment>;
          },
        },
      },
      {
        label: 'Due Time',
        name: 'due_time',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: 'false',
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      // {
      //   label: 'Account',
      //   name: 'common_acc_name',
      //   options: {
      //     filter: true,
      //     sort: true,
      //     display: 'false',
      //     customBodyRenderLite: (dataIndex) => {
      //       const rData = myTask.myTaskList[dataIndex];
      //       return <div>{rData.common_acc_name}</div>;
      //     },
      //   },
      // },
      {
        label: 'Priority',
        name: 'priority',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          customBodyRender: (value) => {
            const currentTaskPriority = myTask.filterList?.priorityList?.find(
              (prio) => prio.id === value,
            )?.name;
            return <div>{currentTaskPriority}</div>;
          },
        },
      },
      {
        label: 'Created Date',
        name: 'created_date',
        options: {
          filter: false,
          sort: true,
          draggable: true,
          customBodyRender: (value) => {
            return <Moment format={dateFormat}>{value}</Moment>;
          },
        },
      },
      {
        label: 'Assigned to',
        name: 'assignedToName',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: 'false',
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      {
        label: 'Source',
        name: 'sourceName',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: 'false',
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      {
        label: 'Created By',
        name: 'rs_user_name',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: 'false',
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      {
        label: 'Updated Date',
        name: 'mod_date',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: 'false',
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRender: (value) => {
            return value && <Moment format={dateFormat}>{value}</Moment>;
          },
        },
      },
      {
        label: 'Completed Date',
        name: 'completed_date',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: 'false',
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRender: (value) => {
            return value && <Moment format={dateFormat}>{value}</Moment>;
          },
        },
      },
      {
        label: 'Completed By',
        name: 'completed_by',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: 'false',
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value || ''} arrow>
                <div>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: 'taskId',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = myTask.myTaskList.find((task) => task.taskId === value);
            return (
              <GridActions
                temp_id={row?.taskId}
                handleEditPopupOpen={handleEditPopupOpen}
                status={row?.status}
                row={row}
                // updatestatus={changeStatus}
                // auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [myTask.myTaskList, myTask.myFilterData],
  );

  const handleReFetch = (filterObject) => {
    dispatch(
      fetchMyTask(
        {
          ...(isEmpty(filterObject) ? {} : filterObject),
        },
        {
          ...gridParams.current,
          pageNo: 0,
          perPage: 25,
        },
        props.tabProps,
        gridColParams.current,
      ),
    );
  };

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={myTask.loading} {...props} />,
    [myTask.loading],
  );

  /**
   * Custom Tool Bar in grid
   */
  const TableToolbar = useMemo(
    () => (props) => {
      return (
        <TaskTableToolbar
          refetch={handleReFetch}
          myFilterData={myTask.myFilterData}
          defaultFilterState={myTask.defaultFilterState}
          filterState={myTask.filterState}
          visibleFilter={1}
          filterDrawerName={'Filters'}
          showEditCol
          onColumnChange={handleColumnChange()}
          loading={myTask.loading}
          {...props}
        />
      );
    },
    [myTask.myFilterData, myTask.defaultFilterState],
  );

  const handlePerPageChange = (params) => {
    // dispatch(
    //   fetchMyTask(
    //     {
    //       ...myTask.filterState,
    //       perPage: params.rowsPerPage,
    //     },
    //     undefined,
    //     props.tabProps,
    //   ),
    // );
  };

  const handleColumnChange = (showLoader) => (paramColumns) => {
    dispatch(
      fetchMyTask(
        {
          ...filterStateParams.current,
        },
        gridParams.current,
        props.tabProps,
        paramColumns,
        showLoader,
      ),
    );
  };

  const handleTableChange = (params) => {
    dispatch(
      fetchMyTask(
        {
          ...myTask.filterState,
        },
        params,
        props.tabProps,
        gridColParams.current,
      ),
    );
  };

  return (
    <div className={classes.templateGridMainSection}>
      <div className={classes.myTaskGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns.map((col, index) => ({
            ...col,
            options: myTask?.columns?.columns?.length
              ? { ...col.options, ...myTask?.columns?.columns[index] }
              : col.options,
          }))}
          data={myTask.myTaskList}
          onTableRowPerPageChange={handlePerPageChange}
          onTableChange={handleTableChange}
          onColumnChange={handleColumnChange(false)}
          paperHeight={'295px'}
          options={{
            columnOrder: myTask?.columns?.columnsOrder,
            pagination: Boolean(myTask?.count),
            page: myTask?.gridParams?.pageNo || 0,
            rowsPerPage: myTask?.gridParams?.perPage || 25,
            searchText: myTask?.gridParams?.searchText,
            selectableRows: 'multiple',
            serverSide: true,
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search Task',
            filterOptions: facetfields,
            userrecords: userrecords,
            facetdata: facetdata,
            customFilterList: filters,
            gridParams: gridParams,
            searchOpen: true,
            search: true,
            filter: true,
            download: true,
            viewColumns: true,
            count: myTask?.count,
            selectToolbarPlacement: 'replace',
            rowsSelected: rowsSelected,
              onRowsSelect: (rowsSelected, allRows) => {
              setRowsSelected(allRows.map((row) => row.dataIndex));
            },
            sortOrder: myTask?.gridParams?.sortOrder
              ? myTask?.gridParams?.sortOrder
              : undefined,
            customToolbarSelect: (
              selectedRows,
              displayData,
              setSelectedRows,
            ) => {
              return (
                <CustomSelectToolbar
                  selectedRows={selectedRows}
                  displayData={displayData}
                  setSelectedRows={setSelectedRows}
                  customContent={
                    <>
                      <Box className={classes.selectToolbarWrapper}>
                        <Typography
                          variant="body1"
                          component="div"
                          className={classes.selectToolbar}
                        >
                          <CheckIcon />
                          Mark as complete
                        </Typography>
                      </Box>
                      <Box className={classes.selectToolbarWrapper}>
                        <Typography
                          variant="body1"
                          component="div"
                          className={classes.selectToolbar}
                        >
                          <AssignmentTurnedInIcon />
                          Reassign
                        </Typography>
                      </Box>
                    </>
                  }
                />
              );
            },
            customToolbar: () => {
              // Toolbar for search and add
              return (
                <Box display="flex" alignItems="center">
                  <Button
                    onClick={handleStartTaskDrawer}
                    dataTarget={`startTask`}
                    disabled={!myTask?.count}
                    style={{
                      height: 35,
                    }}
                  >
                    Start {myTask?.count} Tasks
                  </Button>

                  <OutlinedButton
                    onClick={handlePopupOpen}
                    dataTarget={`createTask`}
                    variant="outlined"
                    style={{
                      height: 35,
                      marginLeft: 16,
                    }}
                  >
                    Create Task
                  </OutlinedButton>
                </Box>
              );
            },
          }}
          components={{
            TableBody: BodyComponent,
            TableToolbar: TableToolbar,
          }}
        />

        {/* Toaster if any changes in grid */}
        <Snackbar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleSnackbarClose}
            severity={openSnackBarMessage.severity}
          >
            {openSnackBarMessage.text}
          </Alert>
        </Snackbar>
      </div>

      <CreateMyTask
        openDrawer={popupToggle}
        dropDownValues={myTask.filterList}
        onClose={handlePopupClose}
        task={myTask.myTaskList.find((task) => task.taskId === taskId)}
      />

      <StartTaskDrawer
        openDrawer={startTaskDrawer}
        onClose={handleStartTaskClose}
        taskCount={myTask?.count}
        tabData={props.tabProps}
        filterParams={myTask.filterState}
        gridParams={myTask.gridParams}
        taskData={myTask.myTaskList}
        handleEditPopupOpen={handleEditPopupOpen}
      />
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const { auth, row } = props;
  const { user } = auth;
  const admin = props.admin ? props.admin : false;
  const [anchorElement, setAnchorElement] = useState(null);

  //const editable = user.company_user_id === row.created_by;
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      case 'changeStatus': {
        props.updatestatus(props.temp_id, props.status);
        break;
      }
      default:
        break;
    }
  };

  let menuItem = [
    {
      title: 'Edit Task',
      itemType: 'editPopUp',
    },
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default MyTaskGrid;
