import { makeStyles } from '@material-ui/core/styles';

// Job Roles Grid styling
const useStyles = makeStyles(({ custom }) => ({
  gridMainSection: {},
  gridWrapper: {
    padding: '10px 0 20px 0',
  },
}));

export default useStyles;
