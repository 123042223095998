import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { isEmpty } from 'lodash';
import {
  Menu,
  MenuItem,
  Typography,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomDataGrid from '../../controlled-component/customGridComponent';
import LoadingTableBody from '../../components/LoadingTableBody';
import {
  fetchSequenceCandidateData,
  fetchGridFilters,
  updateSequenceStatus,
} from '../../actions/admin/sequencedCandidates';
import api from '../../utils/api';
import { makeProperNamingStatement } from '../../utils';
import { dateFormat } from '../../utils/applicationConstant';
import CustomTableToolbar from './CustomTableToolbar';

// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  mainGridWrapper: {
    padding: '10px 0 10px 0',
  },
}));

/**
 * Sequence contacts/candidates Grid component
 */
const SequencedCandidateGrid = (props) => {

  const classes = useStyles();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState({
    severity: 'success',
    text: '',
  });
  const [filters, setFilters] = useState([]);
  const [facetdata, setFacetdata] = useState({});
  const [userrecords, setUserrecords] = useState([]);
  const [facetfields, setFacetfields] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);

  const filterStateRef = useRef({});
  const gridParams = useRef({});
  const gridColParams = useRef({});
  const filterStateParams = useRef({});

  const dispatch = useDispatch();
  const state = useSelector(
    (state) => state.sequencedCandidates[props.tabProps.id]
  );
  const userData = useSelector((state) => state.auth.user);
  const dataCount = useSelector(
    (state) => state.sequencedCandidates.count
  );

  useEffect(() => {
    gridParams.current = state.gridParams;
  }, [state.gridParams]);

  useEffect(() => {
    filterStateParams.current = state.filterState;
  }, [state.filterState]);

  useEffect(() => {
    if (!isEmpty(state.columns)) {
      gridColParams.current = state.columns;
    }
  }, [state.columns]);

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  useEffect(() => {
    // my task data update this will update the options
    if (state.contactsList.length && state.myFilterData.length) {
      setFacetdata({});
      setFacetfields(
        state.myFilterData.map((e) => ({
          label: e.name,
          value: e.title,
        }))
      );
    }
    filterStateRef.current = state.filterState;
  }, [state]);

  useEffect(() => {
    // did mount
    dispatch(fetchGridFilters(props.tabProps));
  }, []);

  useEffect(() => {
    if (!isEmpty(state.filterList) && userData?.id && dataCount == 0) {
      // call the my task api and set data in reducer
      dispatch(
        fetchSequenceCandidateData(
          props.contactType,
          undefined,
          state.gridParams
            ? state.gridParams
            : { pageNo: 0, perPage: 25, searchText: null },
          props.tabProps,
          undefined
        )
      );
    }
  }, [state.filterList, userData]);

  /**
   * Update sequence status call
   */
  const updateSequenceContactStatus = (type, id, name) => {
    const payload = {
      audience_type: props.contactType,
      action_type: type,
      sequence_name: name,
      sequence_contact_id: id,
    };
    api
      .post(`/sequence/update-sequence`, { ...payload })
      .then((res) => {
        if (res.status == 200 && res.data) {
          dispatch(
            updateSequenceStatus({
              task: payload,
              tabProps: props.tabProps,
            })
          );
          setOpenSnackBar(true);
          setOpenSnackBarMessage({
            severity: 'success',
            text: 'Sequence status updated successfully!',
          });
        }
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          severity: 'error',
          text: error?.response?.data?.error || 'Something went wrong',
        });
      });
  };

  // columns of the table
  const columns = useMemo(
    () => [
      {
        label: 'Candidate Name',
        name: 'candidate_name',
        options: {
          filter: true,
          sort: true,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = state.contactsList[dataIndex];
            return <div>{rData.candidate_name}</div>;
          },
        },
      },
      {
        label: 'Enrolled by',
        name: 'created_by_user',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = state.contactsList[dataIndex];
            return <div>{makeProperNamingStatement(rData.created_by_user || '')}</div>;
          },
        },
      },

      {
        label: 'Enrolled date',
        name: 'created_at',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRender: (value) => {
            return <Moment format={dateFormat}>{value}</Moment>;
          },
        },
      },
      {
        label: 'Sequence Name',
        name: 'name',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = state.contactsList[dataIndex];
            return <div>{rData.name}</div>;
          },
        },
      },
      {
        label: 'Steps completed',
        name: 'completed_steps',
        options: {
          filter: true,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = state.contactsList[dataIndex];
            return (
              <div>
                {rData.completed_steps} of {rData.total_steps}
              </div>
            );
          },
        },
      },
      {
        label: 'Opened',
        name: 'event_opened',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = state.contactsList[dataIndex];
            return <div>{rData.event_opened}</div>;
          },
        },
      },
      {
        label: 'Clicked',
        name: 'event_clicked',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = state.contactsList[dataIndex];
            return <div>{rData.event_clicked}</div>;
          },
        },
      },
      {
        label: 'Current Status',
        name: 'status',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRender: (value) => {
            const status = state.filterList?.sequnece_status?.find(
              (prio) => prio.id === value
            )?.name;
            return <div>{status}</div>;
          },
        },
      },
      {
        label: 'Reason Status',
        name: 'status_reason',
        options: {
          filter: false,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRender: (value) => {
            const status = state.filterList?.reason_status?.find(
              (prio) => prio.id === value
            )?.name;
            return <div>{status}</div>;
          },
        },
      },
      {
        label: 'Completed Date',
        name: 'completed_date',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: 'false',
          customBodyRender: (value) => {
            if (!value) {
              return <div></div>;
            }
            return <Moment format={dateFormat}>{value}</Moment>;
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = state.contactsList.find((task) => task.id === value);
            return (
              <GridActions
                temp_id={row?.taskId}
                status={row?.status}
                row={row}
                updatestatus={updateSequenceContactStatus}
                // auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [state.contactsList, state.myFilterData]
  );

  /**
   * Refetch grid data using filter params
   */
  const handleReFetch = (filterObject) => {
    dispatch(
      fetchSequenceCandidateData(
        props.contactType,
        {
          ...(isEmpty(filterObject) ? {} : filterObject),
        },
        {
          ...gridParams.current,
          pageNo: 0,
          perPage: 25,
        },
        props.tabProps,
        gridColParams.current
      )
    );
  };

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={state.loading} {...props} />,
    [state.loading]
  );

  /**
   * Custom Tool Bar in grid
   */
  const TableToolbar = useMemo(
    () => (props) => {
      return (
        <CustomTableToolbar
          refetch={handleReFetch}
          myFilterData={state.myFilterData}
          defaultFilterState={state.defaultFilterState}
          filterState={state.filterState}
          visibleFilter={0}
          filterDrawerName={props.options?.filterPlaceholder}
          showEditCol
          onColumnChange={handleColumnChange()}
          loading={state.loading}
          flist={state.filterList}
          showMoreFilterOption={true}
          selectPlaceHolder={'Enrolled By'}
          datePlaceHolder={'Enrolled Date'}
          selectNameToSave={'enrolled_by'}
          dateRangeNameToSave={'enrolledDate'}
          selectDataOptions={state?.filterList?.enrolled_by?.length ? state.filterList?.enrolled_by : []}
          datePickerDataOptions={state?.filterList?.enrolled_date?.length ? state.filterList?.enrolled_date : []}
          userFilterSelectValue={state?.filterState?.enrolled_by ? state?.filterState?.enrolled_by : []}
          userFilterDateValue={state?.filterState?.enrolledDate ? state?.filterState?.enrolledDate : ''}
          {...props}
        />
      );
    },
    [state.myFilterData, state.defaultFilterState, state.filterList]
  );

  /**
   * Handle grid per page change request
   */
  const handlePerPageChange = (params) => {
    // dispatch(
    //   fetchSequenceCandidateData(
      // props.contactType
    //     {
    //       ...state.filterState,
    //       perPage: params.rowsPerPage,
    //     },
    //     undefined,
    //     props.tabProps,
    //   ),
    // );
  };

  /**
   * Refetch grid data using filter params when column changes
   */
  const handleColumnChange = (showLoader) => (paramColumns) => {
    dispatch(
      fetchSequenceCandidateData(
        props.contactType,
        {
          ...filterStateParams.current,
        },
        gridParams.current,
        props.tabProps,
        paramColumns,
        showLoader
      )
    );
  };

  /**
   * Refetch grid data using filter params when any change in table props
   */
  const handleTableChange = (params) => {
    dispatch(
      fetchSequenceCandidateData(
        props.contactType,
        {
          ...state.filterState,
        },
        params,
        props.tabProps,
        gridColParams.current
      )
    );
  };

  return (
    <div className={classes.templateGridMainSection}>
      <div className={classes.mainGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns.map((col, index) => ({
            ...col,
            options: state?.columns?.columns?.length
              ? { ...col.options, ...state?.columns?.columns[index] }
              : col.options,
          }))}
          data={state.contactsList}
          onTableRowPerPageChange={handlePerPageChange}
          onTableChange={handleTableChange}
          onColumnChange={handleColumnChange(false)}
          paperHeight={'295px'}
          options={{
            columnOrder: state?.columns?.columnsOrder,
            pagination: Boolean(state?.count),
            page: state?.gridParams?.pageNo || 0,
            rowsPerPage: state?.gridParams?.perPage || 25,
            searchText: state?.gridParams?.searchText,
            selectableRows: 'multiple',
            serverSide: true,
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: `Search ${props.gridName}`,
            filterPlaceholder: `Filters`,
            filterOptions: facetfields,
            userrecords: userrecords,
            facetdata: facetdata,
            customFilterList: filters,
            gridParams: gridParams,
            searchOpen: true,
            search: true,
            filter: true,
            download: true,
            viewColumns: true,
            count: state?.count,
            selectToolbarPlacement: 'replace',
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
              setRowsSelected(allRows.map((row) => row.dataIndex));
            },
            sortOrder: state?.gridParams?.sortOrder
              ? state?.gridParams?.sortOrder
              : undefined,
            customToolbar: () => {
              // Toolbar for search and add
              return null;
            },
          }}
          components={{
            TableBody: BodyComponent,
            TableToolbar: TableToolbar,
          }}
        />

        {/* Toaster if any changes in grid */}
        <Snackbar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            elevation={6}
            variant='filled'
            onClose={handleSnackbarClose}
            severity={openSnackBarMessage.severity}
          >
            {openSnackBarMessage.text}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

/**
 * Action menus for grid
 */
const GridActions = (props) => {
  const { row } = props;
  const [anchorElement, setAnchorElement] = useState(null);
  const [menuItem, setMenuItem] = useState([]);

  useEffect(() => {
    if (row?.status == 1) {
      let menu = [
        {
          title: 'Hold Sequence',
          itemType: 'hold',
        },
        {
          title: 'Stop sequence',
          itemType: 'complete',
        },
      ];
      setMenuItem(menu);
    } else if (row?.status == 2) {
      let menu = [
        {
          title: 'Restart sequence',
          itemType: 'start',
        },
      ];
      setMenuItem(menu);
    } else {
      setMenuItem([]);
    }
  }, [row.status]);

  const menuId = 'action-menu-id';

  /**
   * Handle menu open event
   */
  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  /**
   * Handle menu close event and call for update status
   */
  const handleMenuClose = (type) => () => {
    setAnchorElement(null);
    if (type) {
      props.updatestatus(type, row.id, row.name);
    }
  };

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup='true'
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={menuItem.length ? Boolean(anchorElement) : false}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default SequencedCandidateGrid;
