import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formHeading: {
    fontSize: 20,
    fontWeight: 500,
    paddingBottom: 10,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    // justifyContent: 'space-between',
    justifyContent: 'flex-end',
  },
  formCheckbox: {
    '&.Mui-checked': {
      color: custom.colorCode.lightRedColor,
    },
    color: custom.colorCode.lightRedColor,
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
  createNewButton: {
    marginRight: '20px',
    "& MuiButton-label": {
      textTransform: "unset",
    },
    border: `solid 1px ${custom.colorCode.blueLightShade8}`,
    backgroundColor: custom.colorCode.white,
    color: custom.colorCode.blueLightShade8,
    "&:hover": {
      border: `solid 1px ${custom.colorCode.blueLightShade8}`,
      backgroundColor: custom.colorCode.blueLightShade11,
    },
    "&:focus": {
      border: `solid 1px ${custom.colorCode.blueLightShade8}`,
      outline: "none",
      backgroundColor: custom.colorCode.white,
    },
  },
  multipleSelectHeading: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 5,
  },
  OutlineContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'end',
  },
  outLineButton: {
    padding: '5px 15px',
    boxShadow: 'unset',
    // border: `2px solid  ${custom.colorCode.blueLightShade8}`,
    // color: custom.colorCode.blueLightShade8,
    // backgroundColor: custom.colorCode.transparent,
    textTransform: 'capitalize',
    width: '100%',
    height: '100%',
    marginLeft: '0px !important',
    color: '#506E91',
    border: '1px solid #8ba4be',
    borderRadius: '4px',
    backgroundColor: '#F5F7F9',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      border: 'none',
    },
  },
  menuPaper: {
    // maxHeight: 300,
    // marginTop: 8,
    // minWidth: 300,
    maxWidth: 200,
    overflowY: 'unset',
    paddingTop: 0,
    paddingBottom: 0,

    // top: 232px;
    // transform-origin: 115px 53px;
    // position: absolute;
    // left: 1669px;
    // max-height: 331px;
    // overflow: hidden auto;
    // opacity: 1;
  },
  menuItemWrapper: {
    overflow: 'auto',
    maxHeight: 330,
  },
}));

export default useStyles;
