// constant styles for material style
export const colorCodeConstant = {
  white: '#ffffff',
  black: '#000000',
  transparent: 'transparent',

  // black shades
  blackLightShade1: '#3a3e44',
  blackLightShade2: '#3D3D3D',
  fontBlack: '#33475b',

  navListHoverColor: '#0000001a',
  navListHoverColor1: '#eaf0f6',

  // blueShade
  blueLightShade1: '#5780ab',
  blueLightShade2: '#7e9ab7',
  blueLightShade3: '#8d97a7',
  blueLightShade4: '#5780ab',
  blueLightShade5: '#7FD1DE',
  blueLightShade6: '#526f9b',
  blueLightShade7: '#060F32',
  blueLightShade8: '#0655FF',
  blueLightShade8_1: '#3f51b50a',
  blueLightShade9: '#212949',
  blueLightShade10: '#0655FF',
  blueLightShade11: '#0655ff94',
  blueLightShade12: '#00acd9',
  blueLightShade13: '#4962ae',
  blueLightShade14: '#1861fd',
  blueDarkShade1: '#172b4d',
  blueDarkShade2: '#4b5d6f',
  blueDarkShade3: '#6a717f',
  blueDarkShade4: '#16243f',
  blueDarkShade5: '#60aec3',
  blueDarkShade6: '#060e32',
  primaryBlueDarkShade5: '#3f51b5',

  // brownShade
  darkBrownShade1: '#858585',
  darkBrownShade2: '#0091ae',

  // pinkShade
  pinkShade1: '#9d7596',

  // blackShade
  blackShade1: '#00000061',
  blackShade2: '#585858',

  // Red
  lightRedColor: '#f76978',
  lightRedColorShade1: '#f76978bf',
  lightRedColorShade2: '#ff7a59',
  errorRed: '#f44336',

  // grey
  lightGreyColor: '#b9bbc4',
  lightGreyColorShade1: '#e0e0e0',
  lightGreyColorShade2: '#e3e6ea',
  lightGreyColorShade3: '#a5a7af',
  lightGreyColorShade4: '#F5F7F9',
  lightGreyColorShade5: '#acadaf',
  lightGreyColorShade6: '#E3E3E3',
  lightGreyColorShade7: '#F8F8F8',
  lightGreyColorShade8: '#9d9fa6',
  lightGreyColorShade9: '#e7eeff',
  lightGreyColorShade10: '#a3a3a3',
  lightGreyColorShade11: '#f1f1f1',
  lightGreyColorShade12: '#acacac',
  lightGreyColorShade13: '#cfcfcf',
  lightGreyColorShade14: '#cbd6e2',
  lightGreyColorShade15: '#f5f8fa',
  lightGreyColorShade16: '#949494',
  lightGreyColorShade17: '#ebebeb',
  lightGreyColorShade18: '#8e8e8e',
  lightGreyColorShade19: '#ded9d9',
  lightGreyColorShade20: '#cbd6e2',
  lightGreyColorShade21: '#eaf0f6',
  // profile grey
  lightGreyColorProfileShade22: '#c3c3c3',
  lightGreyColorProfileShade23: '#aaaaaa',
  lightGreyColorProfileShade24: '#979797',
  lightGreyColorShade23: '#e8e8e8',

  defaultTextColor: '#3d5a79',
  defaultLinkColor: '#5780ab',

  collapseBorderBottom: '#00000020',

  green: 'green',
  // green-light
  lightLightGreenColor: '#21cfaa',
  lightLightGreenColor1: '#5BBB5B',
  lightLightGreenColor2: '#46C736',
  lightLightGreenColor3: '#09c672',

  // off-white
  offWhiteShade1: '#d4d5dc',

  // face book icon color
  fbIconColor: '#3a559f',

  // blue
  // header blue
  headerBarBlue: '#052dae',

  drawerHeaderBackGround: '#D6EAF6',

  // background-linear
  appBarBlue: 'linear-gradient(180deg, #224870 0, #183859 50%, #162540)',
};

export const fontFamilyConstant = {
  roboto: 'Roboto, sans-serif !important',
};
