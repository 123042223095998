import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, Snackbar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, isArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { v4 as uuidv4 } from 'uuid';
import FormAutoCompleteSelect from '../../components/FormAutoCompleteSelect';
import LookupAutoComplete from '../../components/FormAutoCompleteSelect/LookupAutoComplete';
import Spinner from '../../layout/Spinner';
import { english } from '../../utils/language';
import {
  getCandidatesLookup,
  getCandidatesDMLookup,
} from '../../actions/candidates';
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CallIcon from '@material-ui/icons/Call';
import WorkIcon from '@material-ui/icons/Work';
import {
  getSequenceSteps,
  getJobSpecTemplates,
  getCvSpecTemplates,
  submitEnrollSequence,
  getPersonalizedTemplate,
} from './api';
import Collapse from '../../components/Collapse';
import EmailContent from './EmailContentView';
import { toast } from 'react-toastify';
import api from '../../utils/api';

/**
 * Styles for Enroll form
 */
const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formHeading: {
    fontSize: 20,
    fontWeight: 500,
    paddingBottom: 10,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
  cancelButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    border: `solid 1px ${custom.colorCode.blueLightShade8}`,
    backgroundColor: custom.colorCode.white,
    color: custom.colorCode.blueLightShade8,
    '&:hover': {
      border: `solid 1px ${custom.colorCode.blueLightShade8}`,
      backgroundColor: custom.colorCode.blueLightShade11,
    },
    '&:focus': {
      border: `solid 1px ${custom.colorCode.blueLightShade8}`,
      outline: 'none',
      backgroundColor: custom.colorCode.white,
    },
  },
  callBackText: {
    fontSize: '20px',
    fontWeight: 400,
    color: '#000',
    padding: '10px',
    borderTop: '1px solid #e5e5e5',
    borderBottom: '1px solid #e5e5e5',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      fontWeight: 600,
    },
    '& svg': {
      fill: '#dadada',
      height: '20px',
      width: '20px',
      cursor: 'pointer',
    },
  },
  contentWrapper: {
    borderBottom: `1px solid #00000020`,
    margin: '0 7px',
    width: '100%',
  },
  titleWrapper: {
    color: '#000',
    boxShadow: 'none',
    fontSize: 16,
    textTransform: 'capitalize',
    '& svg': {
      marginRight: 8,
      fill: '#0655ff',
      cursor: 'pointer',
    },
  },
  paragraph: {
    color: 'black',
  },
  collapseContentWrapper: {
    paddingTop: 0,
    paddingBottom: 16,
  },
  collapseRowFirst: {
    fontSize: '14px',
    fontWeight: 'bolder',
  },
  sequenceHeading: {
    color: '#7b7b7b',
    '& span': {
      color: '#2000ef',
      cursor: 'pointer',
    },
  },
  titleSelect: {
    justifyContent: 'flex-end',
    flex: 1,
    display: 'flex',
  },
  errorText: {
    color: '#ed2020',
  },
}));

/**
 * Enroll form component
 */
const EnrollForm = (props) => {
  const classes = useStyles();

  const { closeDrawer, selectedSequenceToEnroll } = props;

  const userData = useSelector((state) => state.auth.user);
  // Local state variables
  const [formData, setFormData] = useState({
    uuid: uuidv4(),
  });

  const [formState, setFormState] = useState({});

  const [disabledButton, setDisabledButton] = useState(false);

  const [candidatesOption, setCandidatesOption] = useState([]);

  const [contactTypeList] = useState([
    {
      name: 'dm',
      title: 'Client Contact',
    },
    {
      name: 'contact',
      title: 'Candidate',
    },
  ]);
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [openErrorSnackBarMsg, setOpenErrorSnackBarMsg] = useState('');
  const [contactType, setContactType] = useState('');
  const [audienceType, setAudienceType] = useState(0);
  const [jobTemplate, setJobTemplate] = useState([]);
  const [cvTemplate, setCvTemplate] = useState([]);
  const [steps, setSteps] = useState([]);
  const [gotToNextPage, setGoToNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedJobTemplates, setSelectedJobTemple] = useState([]);
  const [selectedCvTemplates, setSelectedCvTemplates] = useState([]);
  const [linkedInUrl, setLinkedInUrl] = useState('');
  const [replacementOptions, setReplacementOptions] = useState([]);
  const [isfetching, setIsFetching] = useState(true);
  const [openDefault, setOpenDefalut] = useState(0);
  const [errors, setErrors] = useState([]);

  const contactTypeRef = useRef('');

  useEffect(() => {
   if(Object.keys(selectedSequenceToEnroll).length) {
    setContactType(
      selectedSequenceToEnroll?.audience_type == 'Client Contact'
        ? 'dm'
        : 'contact',
    );
    contactTypeRef.current =
      selectedSequenceToEnroll?.audience_type == 'Client Contact'
        ? 'dm'
        : 'contact';
    let type =
      selectedSequenceToEnroll?.audience_type == 'Client Contact' ? 1 : 2;
    setAudienceType(type);
   }
  }, [selectedSequenceToEnroll]);

  // to fetch replacement variables
  useEffect(() => {
    setIsFetching(true);
    api('automation/replacement-variables')
      .then((resp) => {
        if (resp.status == 200) {
          setReplacementOptions(resp.data);
          setIsFetching(false);
        }
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, []);

  /**
   * Handle jobSpec choosen change
   */
  const handleJobSpecChange = (name, key, id) => (event, tag) => {
    let options = {
      step_id: id,
      id: tag.id,
      name: tag.name,
    };
    let previousJobTemp = selectedJobTemplates.filter(
      (job) => job.step_id !== id,
    );
    let jobTemp = [...previousJobTemp, options];
    setSelectedJobTemple(jobTemp);
    let personalizedTempId = null;
    getTemplateData(personalizedTempId, options);
  };

  /**
   * To get personalized template from api
   */
  const getTemplateData = (personalizedTempId, options) => {
    const payload = {
      personalizedTempId,
      step_id: options?.step_id,
      template_id: options?.id,
      sequence_id: selectedSequenceToEnroll.id,
      contact_type: audienceType,
      contact_id: formData.candidates,
      uuid: formData.uuid,
    };
    getPersonalizedTemplate(payload).then((resp) => {
      if (resp.status == 200) {
        const { data } = resp;
        let resData = {
          step_id: options?.step_id,
          sequence_id: selectedSequenceToEnroll.id,
          template_id: data?.id,
          name: data?.name,
          subject: data?.subject,
          content: data?.content,
          is_personalized: data?.is_personalized,
          attachments: data?.attachments,
        };

        let newState = Object.keys(formState).find(
          (item) => item == options?.step_id,
        );
        if (newState) {
          setFormState({ ...formState, [options?.step_id]: resData });
        }
      }
    });
  };

  /**
   * Handle cvSpec choosen change
   */
  const handleCvSpecChange = (id) => (event, tag) => {
    let options = {
      step_id: id,
      id: tag.id,
      name: tag.name,
    };
    let previousJobTemp = selectedCvTemplates.filter(
      (job) => job.step_id !== id,
    );
    let jobTemp = [...previousJobTemp, options];
    setSelectedCvTemplates(jobTemp);
    let personalizedTempId = null;
    getTemplateData(personalizedTempId, options);
  };

  /**
   * Handle linkedin input change
   */
  const handleLinkedInChange = (event) => {
    setLinkedInUrl(event.target.value);
  };

  /**
   * Handle next button click
   */
  const nextButtonClick = () => {
    if (
      Object.keys(selectedSequenceToEnroll).length &&
      disabledNextButton() === false
    ) {
      setIsLoading(true);
      let seqId = selectedSequenceToEnroll.id;
      let seqName = selectedSequenceToEnroll.name;
      let profileId = formData.candidates;
      if (audienceType == 2) {
        getJobSpecTemplates()
          .then((res) => {
            if (res.status === 200) {
              let newData = res?.data?.reduce((previous, current) => {
                return [
                  ...previous,
                  ...current.options.map((i) => {
                    return {
                      id: i.id,
                      templateName: current.name,
                      name: i.name,
                    };
                  }),
                ];
              }, []);
              setJobTemplate(newData);
            }
          })
          .catch(() => {
            setOpenErrorSnackBar(true);
            setOpenErrorSnackBarMsg('Something went wrong');
          });
      } else {
        getCvSpecTemplates()
          .then((res) => {
            if (res.status === 200) {
              let newData = res.data.reduce((previous, current) => {
                return [
                  ...previous,
                  ...current.options.map((i) => {
                    return {
                      id: i.id,
                      templateName: current.name,
                      name: i.name,
                    };
                  }),
                ];
              }, []);
              setCvTemplate(newData);
            }
          })
          .catch((err) => {
            setOpenErrorSnackBar(true);
            setOpenErrorSnackBarMsg(err || 'Something went wrong');
          });
      }
      getSequenceSteps(seqId, seqName, profileId, audienceType, formData.uuid)
        .then((res) => {
          if (res.status === 200) {
            api(
              `automation/unsubscribe-check?contactId=${formData.candidates}&contactType=${audienceType}`,
            )
              .then((unsubscribeResponse) => {
                if (unsubscribeResponse.data == true) {
                  let data = res.data;
                  setSteps(data);
                  setGoToNextPage(true);
                  setIsLoading(false);

                  if (data.islinkedinExist) {
                    let linkUrl =
                      data?.linkedInSocial?.social_address == 'undefined'
                        ? ''
                        : data?.linkedInSocial?.social_address;
                    setLinkedInUrl(linkUrl);
                  }

                  if (data?.sequenceSteps) {
                    let forms = {};
                    data?.sequenceSteps?.map((data, i) => {
                      if ([1, 2, 3, 7].includes(parseInt(data.type))) {
                        forms[data.id] = {
                          step_id: data.id,
                          sequence_id: data.sequence_id,
                          template_id: data?.template?.id,
                          name: data?.template?.name,
                          subject: data?.template?.subject,
                          content: data?.template?.content,
                          is_personalized: data?.template?.is_personalized,
                          attachments: data?.template?.attachments,
                          email_type: data?.event_type,
                        };
                      }
                    });
                    setFormState(forms);
                  }
                  if (data?.warning) {
                    toast.warning(data?.warning, {
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: false,
                      pauseOnHover: true,
                      draggable: true,
                    });
                  }
                } else {
                  setDisabledButton(true);
                  setOpenErrorSnackBar(true);
                  setOpenErrorSnackBarMsg(
                    res.data?.message || 'You need to subscribe first',
                  );
                }
              })
              .catch((error) => {
                setIsLoading(false);
                setOpenErrorSnackBar(true);
                setOpenErrorSnackBarMsg(
                  error?.response?.data?.error || 'Something went wrong',
                );
              });
          }
        })
        .catch((err) => {
          let msg = 'Something went wrong';
          if (err?.response?.status === 405 || err?.response?.status === 400) {
            msg = err?.response?.data?.error;
          }
          setOpenErrorSnackBar(true);
          setOpenErrorSnackBarMsg(msg);
          setIsLoading(false);
        });
    }
  };

  /**
   * Hancle submit button click
   */
  const handleSubmit = () => {
    let newState = Object.keys(formState).filter((item) => {
      console.log(formState[item]);
      if(formState[item]?.email_type == "linkedIn") {
        return;
      }
      return (
        !formState[item].template_id ||
        !formState[item].subject?.trim() ||
        !formState[item].content?.trim()
      );
    });
    if (newState && newState?.length) {
      setErrors(newState);
      let validation = steps?.sequenceSteps?.findIndex(
        (item) => item.id == newState[0],
      );
      if (validation !== -1) {
        setOpenDefalut(validation);
        let stepNo = validation + 1;
        setOpenErrorSnackBar(true);
        setOpenErrorSnackBarMsg(`Some fields are required in Step ${stepNo}`);
      }
      return;
    }
    setErrors([]);
    setIsLoading(true);
    let contactId = formData.candidates;
    let uuid = formData.uuid;
    let formPayload = new FormData();
    if (audienceType == 2) {
      formPayload.append('contact_id', contactId);
      formPayload.append('audience_type', audienceType);
      formPayload.append('sequenceId', selectedSequenceToEnroll.id);
      formPayload.append('sequenceName', selectedSequenceToEnroll.name);
      formPayload.append('linkedinurl', linkedInUrl);
      steps?.sequenceSteps.map((item, i) => {
        let count = i + 1;
        let res = selectedJobTemplates.find((data) => data.step_id == item.id);
        if (res) {
          formPayload.append('event_temId_' + count, item.id + '_' + res.id);
        } else {
          let templateId = item.template_id;
          formPayload.append(
            'event_temId_' + count,
            item.id + '_' + templateId,
          );
        }
      });
      let totalSteps = steps?.sequenceSteps.length;
      formPayload.append('total_steps', totalSteps);
      formPayload.append('templates', JSON.stringify(formState));
      formPayload.append('refid', uuid);
    } else {
      formPayload.append('contact_id', contactId);
      formPayload.append('audience_type', audienceType);
      formPayload.append('sequenceId', selectedSequenceToEnroll.id);
      formPayload.append('sequenceName', selectedSequenceToEnroll.name);
      formPayload.append('linkedinurl', linkedInUrl);
      steps?.sequenceSteps.map((item, i) => {
        let count = i + 1;
        let res = selectedCvTemplates.find((data) => data.step_id == item.id);
        if (res) {
          // let master_id = item?.cv_spec_masters_id || 0;
          formPayload.append(
            'event_temId_' + count,
            item.id + '_' + res.id,
            // item.id + "_" + res.id + "_" + master_id
          );
        } else {
          let templateId = item.template_id;
          formPayload.append(
            'event_temId_' + count,
            item.id + '_' + templateId,
          );
        }
      });
      let totalSteps = steps?.sequenceSteps.length;
      formPayload.append('total_steps', totalSteps);
      formPayload.append('templates', JSON.stringify(formState));
      formPayload.append('refid', uuid);
    }
    submitEnrollSequence(formPayload)
      .then((res) => {
        setIsLoading(false);
        if (res.status == 200) {
          setIsLoading(false);

          toast.success('Candidate enrolled successfully!', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
          closeDrawer('enroll');
        }
      })
      .catch((err) => {
        toast.error(err?.error ? err?.error : 'Something went wrong', {
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
        setIsLoading(false);
      });
  };

  /**
   * Handle back button click
   */
  const backButtonClick = () => {
    setIsLoading(false);
    setGoToNextPage(false);
  };

  /**
   * get the icon by sequence type
   */
  const getSequenceIcon = (stringValue) => {
    let icon = <EmailIcon fontSize="small" />;

    switch (stringValue) {
      case 'email': {
        icon = <EmailIcon fontSize="small" />;
        break;
      }
      case 'linkedIn': {
        icon = <LinkedInIcon fontSize="small" />;
        break;
      }
      case 'callback': {
        icon = <CallIcon fontSize="small" />;
        break;
      }
      case 'task': {
        icon = <AssignmentTurnedInIcon fontSize="small" />;
        break;
      }
      case 'specjob': {
        icon = <WorkIcon fontSize="small" />;
        break;
      }
      default: {
        icon = <EmailIcon fontSize="small" />;
        break;
      }
    }
    return icon;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    if (isLoading) {
      return true;
    }
    const formKeys = ['candidates'];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = isArray(formData[e]) ? isEmpty(formData[e]) : !formData[e];
      }
    });
    return isValid;
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    if (name === 'contactType') {
      setFormData({
        ...formData,
        candidates: [],
      });
      setTimeout(() => {
        contactTypeRef.current = tag[key];
        setContactType(tag[key]);
      }, 5);
      return;
    }
    if (key) {
      setFormData({
        ...formData,
        [name]: tag[key],
        uuid: uuidv4(),
      });
    } else {
      setFormData({
        ...formData,
        [name]: tag,
      });
    }
    setDisabledButton(false);
  };

  /**
   * function will call after the search of candidates
   * to set the options in the dropdown
   */
  const searchCandidates = async (search, lookupType) => {
    let res = [];

    if (lookupType === 'dm') {
      res = await getCandidatesDMLookup(search, []);
    } else if (lookupType === 'contact') {
      res = await getCandidatesLookup(search, []);
    }

    return res.data || [];
  };

  /**
   * function to close toaster after server side error
   */
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackBar(false);
  };

  /**
   * function to handle subject change
   */
  const handleSubjectChange = (step, text) => {
    if (step) {
      setFormState({
        ...formState,
        [step]: { ...formState[step], subject: text },
      });
    }
  };

  /**
   * function to handle content change
   */
  const handleContentChange = (step, value) => {
    if (step) {
      setFormState({
        ...formState,
        [step]: { ...formState[step], content: value },
      });
    }
  };

  /**
   * Check errors for each steps
   */
  const checkError = (id) => {
    let error = errors.find((item) => item == id);

    if (error) {
      let data = formState[error];
      if (!data?.template_id) {
        return 'Select template';
      } else if (!data?.subject?.trim()) {
        return 'Subject is required';
      } else if (!data?.content?.trim()) {
        return 'Content is required';
      }
    }
    return null;
  };

  if (isfetching) {
    return (
      <Box className={classes.root}>
        <Spinner style={{ width: 40, height: 40, position: 'relative' }} />
      </Box>
    );
  }
  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> Task Details </h6>*/}
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Sequence Name *"
                  value={selectedSequenceToEnroll?.name}
                  name="sequence"
                  variant="outlined"
                  fullWidth
                  disabled
                  validators={['required']}
                  errorMessages={['Please select contact type']}
                />
              </Grid>
              {/*contact type*/}
              <Grid item xs={12} sm={12}>
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('contactType', 'name')}
                  name="contactType"
                  id="contactType"
                  disableClearable
                  value={
                    contactType
                      ? contactTypeList.find((c) => c.name === contactType) ||
                        ''
                      : ''
                  }
                  disabled={true}
                  options={contactTypeList || []}
                  getOptionLabel={(option) => option.title || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Person Type *"
                      value={contactType}
                      name="contactType"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select contact type']}
                    />
                  )}
                />
              </Grid>

              {/*contact*/}
              <Grid item xs={12} sm={12}>
                {contactType === 'dm' && (
                  <LookupAutoComplete
                    onChange={handleSelectChange('candidates', 'id')}
                    name="candidates"
                    id="candidates"
                    disableClearable
                    isEditMode={false}
                    remoteMethod={(val) =>
                      searchCandidates(val, contactTypeRef.current)
                    }
                    options={candidatesOption}
                    getOptionLabel={(option) => option.value || ''}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    disabled={Boolean(gotToNextPage)}
                    value={formData?.candidates}
                    inputProps={{
                      value: formData?.candidates || '',
                      label: 'Client Contact *',
                      name: 'dmcandidates',
                      variant: 'outlined',
                      fullWidth: true,
                      validators: ['required'],
                      errorMessages: [english.specTemplateForm.selectCandidate],
                    }}
                  />
                )}{' '}
                {contactType === 'contact' && (
                  <LookupAutoComplete
                    onChange={handleSelectChange('candidates', 'id')}
                    name="candidates"
                    id="candidates"
                    disableClearable
                    isEditMode={false}
                    remoteMethod={(val) =>
                      searchCandidates(val, contactTypeRef.current)
                    }
                    options={candidatesOption}
                    getOptionLabel={(option) => option.value || ''}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    disabled={Boolean(gotToNextPage)}
                    value={formData?.candidates}
                    inputProps={{
                      value: formData?.candidates || '',
                      label: 'Candidates *',
                      name: 'candidates',
                      variant: 'outlined',
                      fullWidth: true,
                      validators: ['required'],
                      errorMessages: [english.specTemplateForm.selectCandidate],
                    }}
                  />
                )}
              </Grid>
              {gotToNextPage &&
                Boolean(steps?.sequenceSteps?.length) &&
                (steps?.sequenceSteps || []).map((seqStep, index) => (
                  <Box
                    key={`${seqStep.id}-${seqStep.event_type}-sequence-step-${index}`}
                    className={classes.contentWrapper}
                  >
                    <Collapse
                      title={
                        <Box
                          display="flex"
                          alignItems="center"
                          className={classes.titleWrapper}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            className={classes.titleContentWrapper}
                          >
                            {getSequenceIcon(seqStep.event_type)}
                            Step {`${index + 1} (${seqStep.event_type})`}
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            className={classes.titleSelect}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              className={classes.errorText}
                            >
                              {/*{props.getFilterTitle()}*/}
                              {errors?.length ? checkError(seqStep.id) : null}
                            </Box>
                          </Box>
                        </Box>
                      }
                      defaultOpen={!index}
                      isOpen={openDefault == index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box className={classes.collapseContentWrapper}>
                        {seqStep.event_type === 'email' ||
                        seqStep.event_type === 'linkedIn' ? (
                          <EmailContent
                            formState={formState[seqStep.id]}
                            replacementOptions={replacementOptions}
                            handleSubjectChange={handleSubjectChange}
                            handleContentChange={handleContentChange}
                            uuid={formData.uuid}
                            emailType={seqStep.event_type}
                          />
                        ) : seqStep.event_type == 'specjob' ? (
                          <Box>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ paddingBottom: '20px' }}
                            >
                              <FormAutoCompleteSelect
                                multiple={false}
                                onChange={handleJobSpecChange(
                                  'job',
                                  'id',
                                  seqStep.id,
                                )}
                                name="sequence"
                                id="sequence"
                                disableClearable
                                options={jobTemplate}
                                groupBy={(option) => option.templateName}
                                getOptionLabel={(option) => option.name || ''}
                                value={selectedJobTemplates.find(
                                  (item) => item.step_id == seqStep.id,
                                )}
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    label="Jobspec *"
                                    value={selectedJobTemplates.find(
                                      (item) => item.step_id == seqStep.id,
                                    )}
                                    name="specjob"
                                    variant="outlined"
                                    fullWidth
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                  />
                                )}
                              />
                            </Grid>
                            {formState[seqStep.id] &&
                              formState[seqStep.id].template_id && (
                                <EmailContent
                                  formState={formState[seqStep.id]}
                                  replacementOptions={replacementOptions}
                                  handleSubjectChange={handleSubjectChange}
                                  handleContentChange={handleContentChange}
                                  uuid={formData.uuid}
                                />
                              )}
                            <Box />
                          </Box>
                        ) : seqStep.event_type == 'cvspec' ? (
                          <Box>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ paddingBottom: '20px' }}
                            >
                              <FormAutoCompleteSelect
                                multiple={false}
                                onChange={handleCvSpecChange(seqStep.id)}
                                name="sequence"
                                id="sequence"
                                disableClearable
                                options={cvTemplate}
                                groupBy={(option) => option.templateName}
                                getOptionLabel={(option) => option.name || ''}
                                value={selectedCvTemplates.find(
                                  (item) => item.step_id == seqStep.id,
                                )}
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    label="Cvspec *"
                                    value={selectedCvTemplates.find(
                                      (item) => item.step_id == seqStep.id,
                                    )}
                                    name="cvspec"
                                    variant="outlined"
                                    fullWidth
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                  />
                                )}
                              />
                            </Grid>
                            {formState[seqStep.id] &&
                              formState[seqStep.id].template_id && (
                                <EmailContent
                                  formState={formState[seqStep.id]}
                                  replacementOptions={replacementOptions}
                                  handleSubjectChange={handleSubjectChange}
                                  handleContentChange={handleContentChange}
                                  uuid={formData.uuid}
                                />
                              )}
                          </Box>
                        ) : (
                          seqStep.name
                        )}
                      </Box>
                    </Collapse>
                  </Box>
                ))}

              {/* {gotToNextPage && steps?.islinkedinExist && (
                  <Box className={classes.contentWrapper}>
                    <Collapse
                      title={
                        <Box
                          display='flex'
                          alignItems='center'
                          className={classes.titleWrapper}
                        >
                          {getSequenceIcon('linkedin')}
                          Step{' '}
                          {`${
                            (steps?.sequenceSteps?.length || 0) + 1
                          } (LinkedIn)`}
                        </Box>
                      }
                      // defaultOpen
                      timeout='auto'
                      unmountOnExit
                    >
                      <Box className={classes.collapseContentWrapper}>
                        <TextValidator
                          label='linkedIn'
                          value={linkedInUrl}
                          onChange={handleLinkedInChange}
                          name='linkedIn'
                          variant='outlined'
                          fullWidth
                        />
                      </Box>
                    </Collapse>
                  </Box>
                )} */}
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div>
            {gotToNextPage && (
              <Button
                variant="contained"
                className={classes.cancelButton}
                onClick={backButtonClick}
              >
                {'Cancel'}
              </Button>
            )}
          </div>
          <div>
            {!gotToNextPage ? (
              <Button
                variant="contained"
                className={classes.nextButton}
                onClick={nextButtonClick}
                disabled={disabledNextButton()}
              >
                {isLoading ? (
                  <Spinner
                    style={{
                      position: 'unset',
                      display: 'flex',
                      margin: '5px',
                      width: '20px',
                      height: '20px',
                    }}
                  />
                ) : (
                  'Next'
                )}
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.nextButton}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    style={{
                      position: 'unset',
                      display: 'flex',
                      margin: '5px',
                      width: '20px',
                      height: '20px',
                    }}
                  />
                ) : (
                  'Save & Confirm'
                )}
              </Button>
            )}
          </div>
        </div>
      </ValidatorForm>
      <Snackbar
        open={openErrorSnackBar}
        autoHideDuration={5000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleErrorSnackbarClose}
          severity="error"
        >
          {openErrorSnackBarMsg || ''}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EnrollForm;
