import React from 'react';
import { IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { makeStyles } from '@material-ui/core/styles';

// css styling for user form
const addUseStyles = makeStyles(({ custom }) => ({
  formWrapper: {
    width: '100%',
    height: '100%',
  },
  formHeader: {
    height: 70,
    padding: 10,
    display: 'flex',
    backgroundColor: custom.colorCode.drawerHeaderBackGround,
  },
  formHeaderWrapper: {
    padding: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  formHeaderTitle: {
    color: custom.colorCode.black,
    fontSize: 22,
    fontWeight: 700,
    marginLeft: 10,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
  },
  formHeaderCloseIconButton: {
    '&:focus': {
      outline: 'none',
    },
  },
  formHeaderCloseIcon: {
    fill: custom.colorCode.lightGreyColorShade10,
    '&:focus': {
      outline: 'none',
    },
    outline: 'none',
  },
  formContentWrapper: {
    marginBottom: 30,
  },
}));

// Form Drawer wrapper to mange drawer events
const DrawerFormHeader = ({
  handleCloseClick,
  children,
  formHeaderTitleClassName = '',
  titleText = 'Create User',
  showBackButton = false,
  backClick = () => {},
  drawerElement,
  elementLeftToCloseIcon,
}) => {
  const classes = addUseStyles();

  // to close the drawer
  const handleDrawerClose = (data) => {
    handleCloseClick(data);
  };

  return (
    <div className={`${classes.formWrapper}`}>
      <div className={`${classes.formHeader}`}>
        <div className={classes.formHeaderWrapper}>
          {showBackButton && (
            <Box>
              <IconButton
                onClick={() => backClick()}
                className={classes.formHeaderCloseIconButton}
                style={{ padding: 6 }}
              >
                <ChevronLeftIcon
                  fontSize="large"
                  className={classes.formHeaderCloseIcon}
                />
              </IconButton>
            </Box>
          )}
          <Box
            style={showBackButton ? { marginLeft: 0, paddingLeft: 0 } : {}}
            className={`${formHeaderTitleClassName} ${classes.formHeaderTitle}`}
          >
            {titleText}
          </Box>
          {drawerElement && (
            <Box display="flex" alignItems="center">
              {drawerElement}
            </Box>
          )}
          <Box display="flex" alignItems="center">
            {elementLeftToCloseIcon && elementLeftToCloseIcon}
            <IconButton
              onClick={() => handleDrawerClose()}
              className={classes.formHeaderCloseIconButton}
            >
              <CloseIcon className={classes.formHeaderCloseIcon} />
            </IconButton>
          </Box>
        </div>
      </div>

      <div className={classes.formContentWrapper}>{children}</div>
    </div>
  );
};

export default DrawerFormHeader;
