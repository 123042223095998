export const dateFormat = 'DD-MM-YY';
export const timeFormat = 'H:mm';

// Sequence audience type options
export const audienceTypeOption = [
  {
    value: 1,
    title: 'Client Contact',
  },
  {
    value: 2,
    title: 'Candidate',
  },
];

// sequence access options
export const sequenceAccessOption = [
  {
    value: 1,
    title: 'Private',
    helperText: 'Select the users that will have access to this sequence.',
  },
  {
    value: 2,
    title: 'Team',
    helperText: 'Select the teams that will have access to this sequence.',
  },
  {
    value: 3,
    title: 'Organisation',
    helperText: 'Everyone will have access to this sequence.',
  },
];
