import React from 'react';
import api from '../utils/api';
import { Col, Modal, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
//import { Editor } from '@tinymce/tinymce-react';
//import { defaultsDeep } from 'lodash';
import TextEditor from '../utils/Editor';
import Fileupload from '../utils/Fileupload';
import { v4 as uuidv4 } from 'uuid';
import { addTemplate, getJobSpecTemplate } from '../actions/template';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { fetchUsersTeams } from '../actions/users';
import MuiAutocomplete from '../controlled-component/MuiAutocomplete';
import TextField from '@material-ui/core/TextField';
/**
 * functional component for add spec job template
 */
const CreateTemplate = (props) => {
  /**
   * setting up some local state props
   */
  const [teamToggle, setTeamToggle] = React.useState(false);
  const [userToggle, setUserToggle] = React.useState(false);
  const [userSelected, setUserSelected] = React.useState([]);
  const [teamSelected, setTeamSelected] = React.useState([]);
  //here creating a yup schema for validation on form
  const schema = yup.object().shape({
    name: yup.string().required('Template name is a required field'),
    access_type: yup.string().required('Template access is a required field'),
    subject: yup.string().required('Template subject is a required field'),
    content: yup.string().required('Content is a required field'),
    shared_with_users: userToggle
      ? yup.array().required('Users are a required field')
      : '',
    shared_with_teams: teamToggle
      ? yup.array().required('Teams are a required field')
      : '',
  });
  //creating object of uuid for unique ref id
  const uuid = uuidv4();
  const {
    register,
    handleSubmit,
    control,
    errors,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(schema) });
  //const [templateData, setTemplateData] = React.useState({});
  /**
   * Function to handle save button click and call server side api to create new tempalte
   * @param {*} values
   */
  const onSubmitTemplate = async (values) => {
    await api
      .post('/automation/template', {
        values,
      })
      .then((response) => {
        props.onHide();
        if (props.page === 'step') {
          props.getJobSpecTemplate(1);
          props.toSelect({
            value: response.data.id,
            label: response.data.name,
          });
        } else {
          props.getJobSpecTemplate(null, props.admin ? props.admin : null);
        }
        toast.success('Spec Job Template added', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch(() => {
        //setTemplateData(values);
      });
    //props.addTemplate(values, props.page, props.onHide, setTemplateData);
  };

  /**
   * Handle function of template access premission change event
   */
  const handleAccessType = (event) => {
    let selected = event.target.value;
    if (selected == 1) {
      setUserToggle(true);
      setTeamToggle(false);
    } else if (selected == 2) {
      setUserToggle(false);
      setTeamToggle(true);
    } else {
      setUserToggle(false);
      setTeamToggle(false);
    }
  };

  // API call to get users and tema list to set in reducer
  React.useEffect(() => {
    props.fetchUsersTeams();
  }, [props.fetchUsersTeams]);

  //form users options
  const usersData = props.users.users ? props.users.users : null;
  var usersOptions = usersData.map(function (user) {
    return { value: user.company_user_id, label: user.name };
  });

  //form teams options
  const teamsData = props.users.teams ? props.users.teams : null;
  var teamsOptions = teamsData.map(function (team) {
    return { value: team.id, label: team.name };
  });

  /**
   * subscribe the reducer state props and set data into local state props
   */
  React.useEffect(() => {
    if (props.users.user_id) {
      setUserSelected([props.users.user_id]);
    }
    if (props.users.team_id) {
      setTeamSelected([props.users.team_id]);
    }
  }, [props.users.user_id, props.users.team_id]);

  return (
    <Modal
      show={props.modalState}
      onHide={() => props.onHide('addTemplate')}
      dialogClassName="custom-modal"
      enforceFocus={false}
    >
      <Form onSubmit={handleSubmit(onSubmitTemplate)}>
        <Modal.Header className="modalHeaderCls" closeButton={false}>
          <div>
            <Modal.Title className="modalTitle">
              Create Spec Job Template
            </Modal.Title>
            <span
              className="closeModalIconCls"
              onClick={() => props.onHide('addTemplate')}
            >
              <i className="fa fa-close"></i>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label className="floating-label">
                Spec Job Template Name
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                className="custom_input"
                ref={register}
              />
              {errors.name && (
                <label className="error">{errors.name?.message}</label>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="subject">
              <Form.Label className="floating-label">
                Template Subject
              </Form.Label>
              <Form.Control
                type="text"
                name="subject"
                className="custom_input"
                ref={register}
              />
              {errors.subject && (
                <label className="error">{errors.subject?.message}</label>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="content">
              <Form.Label className="floating-label">Content</Form.Label>
              <Controller
                control={control}
                name="content"
                defaultValue=""
                render={({ onChange, value }) => (
                  <TextEditor onChange={onChange} initialvalue={value} />
                )}
              />
              {errors.content && (
                <label className="error">{errors.content?.message}</label>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Control
            type="hidden"
            name="template_type"
            id="template_type"
            value="3"
            className="custom_input"
            ref={register}
          />
          <Form.Control
            type="hidden"
            name="uuid"
            id="uuid"
            value={uuid}
            allowtype="ATTACHMENT"
            className="custom_input"
            ref={register}
          />
          <Form.Row>
            <Form.Group as={Col} controlId="access_type">
              <Form.Label>Template Access</Form.Label>
              <Form.Control
                as="select"
                name="access_type"
                custom="true"
                ref={register}
                onChange={handleAccessType}
              >
                <option value="">Select</option>
                <option value="1">Private</option>
                <option value="2">Team</option>
                <option value="3">Organisation</option>
              </Form.Control>
              <label className="error">{errors.access_type?.message}</label>
            </Form.Group>
          </Form.Row>
          {userToggle ? (
            <Form.Row>
              <Form.Group as={Col} controlId="shared_with_users">
                <Form.Label>Select users</Form.Label>
                <MuiAutocomplete
                  control={control}
                  name="shared_with_users"
                  options={usersOptions}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  getOptionLabel={(option) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      margin="normal"
                      placeholder="Select users"
                    />
                  )}
                  defaultValue={userSelected}
                  multiple={true}
                />
                <label className="error">
                  {errors.shared_with_users?.message}
                </label>
              </Form.Group>
            </Form.Row>
          ) : null}
          {teamToggle ? (
            <Form.Row>
              <Form.Group as={Col} controlId="shared_with_teams">
                <Form.Label>Select teams</Form.Label>
                <MuiAutocomplete
                  control={control}
                  name="shared_with_teams"
                  options={teamsOptions}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  getOptionLabel={(option) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      margin="normal"
                      placeholder="Select teams"
                    />
                  )}
                  defaultValue={teamSelected}
                  multiple={true}
                />

                <label className="error">
                  {errors.shared_with_teams?.message}
                </label>
              </Form.Group>
            </Form.Row>
          ) : null}

          <Form.Row>
            <Form.Group as={Col} controlId="file">
              <Fileupload uuid={uuid} object_id={null} />
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button varient="primary" className="pl-4 pr-4" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CreateTemplate.propTypes = {
  auth: PropTypes.object.isRequired,
  addTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sequence: state.sequence,
  users: state.users,
});

const mapDispatchToProps = {
  addTemplate,
  getJobSpecTemplate,
  fetchUsersTeams,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
