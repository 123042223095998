import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Activity from './Activity';
import Applications from './Applications';
import CV from './CV';
import Meetings from './Meetings';
import Sequences from './Sequences';
import MainTabs, { TabPanel } from '../../components/MainTabs';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    padding: 16,
  },
  taskTabMainClass: {
    marginTop: 0,
  },
}));

const tabsOptions = [
  {
    name: 'activity',
    title: 'Activity',
  },
  // {
  //   name: 'sequences',
  //   title: 'Sequences',
  // },
  // {
  //   name: 'meetings',
  //   title: 'Meetings',
  // },
];

const SucceededDetailView = ({ profileType, profileId }) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const getTabComponent = (tabData) => {
    let comp = <></>;
    switch (tabData.name) {
      case 'activity': {
        comp = <Activity profileId={profileId} profileType={profileType} />;
        break;
      }
      case 'cv': {
        comp = <CV />;
        break;
      }
      case 'sequences': {
        comp = <Sequences />;
        break;
      }
      case 'meetings': {
        comp = <Meetings />;
        break;
      }
      case 'applications': {
        comp = <Applications />;
        break;
      }
      default: {
        break;
      }
    }
    return comp;
  };

  return (
    <Box className={classes.root}>
      <MainTabs
        tabsOptions={tabsOptions || []}
        tabIndex={tabIndex}
        onTabChange={handleTabChange}
        className={classes.taskTabMainClass}
        tabLabel="title"
        tabKey="name"
        variant="scrollable"
      />

      {(tabsOptions || []).map((tab, index) => (
        <TabPanel value={tabIndex} index={index} key={`${tab.name}-${index}`}>
          {getTabComponent(tab)}
        </TabPanel>
      ))}
    </Box>
  );
};

export default SucceededDetailView;
