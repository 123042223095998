import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import IndustriesGrid from './IndustriesGrid';

// const Industries = (props) => {
//   return (
//     <div className="">
//       <div className="flex-row">
//         <aside className="sideBarMargin">
//           <SidebarNav industry="active" />
//         </aside>
//
//         <div className="flex-col content">
//           <IndustriesGrid {...props} />
//         </div>
//       </div>
//     </div>
//   );
// };

const Industries = (props) => {
  return <IndustriesGrid {...props} />;
};

Industries.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Industries);
