import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import Spinner from '../../../layout/Spinner';
import CustomSnackBar from '../../../components/CustomSnackBar';

import { fetchIntegration } from '../../../actions/admin/integration';
import api from '../../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
  connectIntegrationDialog: {},
  connectIntegrationDialogImg: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    '& img': {},
  },
  connectIntegrationDialogText: {
    color: custom.colorCode.fontBlack,
    fontSize: 16,
    marginBottom: 10,
  },
}));

/**
 * Component for integration form
 * @param {*} props
 * @returns
 */
const IntegrationForm = (props) => {
  const classes = useStyles();

  const { integrationData, closeDrawer } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  const [adsAccounts, setAdsAccounts] = useState([]);
  const [formData, setFormData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (integrationData.plugin_name === 'Facebook') {
      setIsLoading(true);

      api
        .get('/integrations/accounts')
        .then((response) => {
          setIsLoading(false);
          setAdsAccounts(response.data?.original?.data || []);
        })
        .catch((error) => {
          setIsLoading(false);
          setOpenSnackBar(true);
          setOpenSnackBarMessage({
            message: error?.response?.data?.message || 'Something went wrong',
            status: 'error',
          });
        });
    }
  }, []);

  // this will close the snackbar
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  const handleSubmit = () => {
    const payload = {
      ads_account: formData.ads_account,
      plugin_id: integrationData.id,
    };

    setDisableButton(true);
    api
      .post('/integrations/adsaccount', payload)
      .then((resp) => {
        closeDrawer(true);
        setDisableButton(false);
        setTimeout(() => {
          dispatch(fetchIntegration());
        }, 10);
      })
      .catch((error) => {
        console.log(error);
        setDisableButton(false);
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          message: error?.response?.data?.message || 'Something went wrong',
          status: 'error',
        });
      });
  };

  const handleSelectionChange = (name, key) => (event, tag) => {
    setFormData({
      ...formData,
      [name]: tag[key],
    });
  };

  // Show loader
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          <div className={classes.connectIntegrationDialog}>
            <div className={classes.connectIntegrationDialogText}>
              Select Facebook Ads Account
            </div>

            <FormAutoCompleteSelect
              onChange={handleSelectionChange('ads_account', 'account_id')}
              name="ads_account"
              id="ads_account"
              disableClearable
              options={adsAccounts || []}
              getOptionLabel={(option) => option.account_id || ''}
              getOptionSelected={(option, value) => option.id === value}
              value={
                formData.ads_account
                  ? adsAccounts.find(
                      (c) => c.account_id === formData?.ads_account,
                    ) || ''
                  : ''
              }
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  label="Select *"
                  className={classes.selectBoxStyle}
                  name="ads_account"
                  value={formData.ads_account}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please select account']}
                />
              )}
            />
          </div>
        </div>

        <div className={classes.formFooter}>
          <div />

          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disableButton}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default IntegrationForm;
