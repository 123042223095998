import React, { useState, useCallback, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextValidator } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce, isEqual, uniqWith } from 'lodash';

/**
 * Main basic or default complaint for the application for the server side auto complete
 * @param props
 * inputProps: props for text fields or input box
 * remoteMethod: api call method
 * options: default options
 * @returns {*}
 * @constructor
 */
const LookupAutoComplete = (props) => {
  let {
    remoteMethod,
    inputProps,
    options = [],
    value,
    isEditMode = true,
    ...rest
  } = props;

  const [loading, setLoading] = useState(false);
  const [remoteOptions, setRemoteOptions] = useState([]);

  /**
   * call back and debounce function to call api in some interval
   */
  const onType = useCallback(
    debounce(async (event) => {
      if (
        typeof remoteMethod !== 'undefined' &&
        typeof remoteMethod === 'function'
      ) {
        setLoading(true);
        const data = await getRemoteData(event);
        setRemoteOptions(data);
        setLoading(false);
      }
    }, 300),
    [remoteOptions],
  );

  /**
   * input handle change to call api with min 2 char
   */
  const handleChange = (event) => {
    onType(event.target.value || '', event);
  };

  /**
   * handle the remote api call
   */
  const getRemoteData = async (value = '') => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await remoteMethod(value || '');
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
  const autoCompleteOptions = uniqWith([...options, ...remoteOptions], isEqual);

  return isEditMode ? (
    <Autocomplete
      {...rest}
      options={autoCompleteOptions}
      value={
        rest.multiple
          ? (value && value?.length)
            ? autoCompleteOptions.filter((ac) => value.includes(ac.id))
            : []
          : value
          ? autoCompleteOptions.find((ac) => value === ac.id) || ''
          : ''
      }
      onOpen={handleChange}
      loading={loading}
      renderInput={(params) => {
        return (
          <TextValidator
            {...params}
            {...inputProps}
            onChange={handleChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {/*loader*/}
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  ) : (
    <Autocomplete
      {...rest}
      options={autoCompleteOptions}
      onOpen={handleChange}
      loading={loading}
      renderInput={(params) => {
        return (
          <TextValidator
            {...params}
            {...inputProps}
            onChange={handleChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {/*loader*/}
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default LookupAutoComplete;
