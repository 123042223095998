import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
  Button,
  FormGroup,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { isArray, differenceBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import FormAutoCompleteSelect from '../../components/FormAutoCompleteSelect';
import LookupAutoComplete from '../../components/FormAutoCompleteSelect/LookupAutoComplete';
import Spinner from '../../layout/Spinner';
import TextEditor from '../../utils/Editor';
import FileUpload from '../../utils/Fileupload';
import { english } from '../../utils/language';
import api from '../../utils/api';
import { fetchUsersTeams } from '../../actions/users';
import {
  getCVSpecTemplate,
  getMasterCVSpecTemplatesList,
  getMasterCVSpecTemplate,
} from '../../actions/template';
import { getCandidatesLookup } from '../../actions/candidates';

import useStyles from './CreateTemplateFormStyle';
import CheckboxLabel from '../../components/CheckboxLabel';
// import CandidateDocument from './CandidateDocuments';

/**
 * CV Spec Form Component
 * Used for Create, Edit, and Clone CV Spec Template
 */

// sequence access options
const sequenceAccessOption = [
  /* {
    value: 1,
    title: 'Private',
  }, */
  {
    value: 2,
    title: 'Team',
  },
  /* {
    value: 3,
    title: 'Organisation',
  }, */
];

/**
 * CV Spec Template form
 * @param {*} props
 * @returns
 */
const CreateTemplateForm = (props) => {
  const {
    closeDrawer,
    history,
    templateId,
    cloneTemplate,
    ownerOfTemplate,
    setOwnerOfTemplate,
  } = props;

  const classes = useStyles();

  const [formData, setFormData] = useState({
    template_type: '2',
    uuid: uuidv4(),
  });
  const [isLoading, setIsLoading] = useState(true);
  const [teamToggle, setTeamToggle] = useState(false);
  const [userToggle, setUserToggle] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [formattedCv, setFormattedCv] = useState([]);
  const [checkMaster, setCheckMaster] = useState(true);
  const [validate, setValidate] = useState([]);
  const [candidateFormats, setCandidateFormats] = useState([]);

  const formRef = useRef();

  const users = useSelector((state) => state.users);
  const authUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // users options
  const usersData = users.users ? users.users : null;
  const usersOptions = (usersData || []).map((user) => ({
    value: user.company_user_id,
    label: user.name,
  }));

  // teams options
  const teamsData = users.teams ? users.teams : null;
  const teamsOptions = (teamsData || []).map((team) => ({
    value: team.id,
    label: team.name,
  }));

  // get master list of cv spec from reducer->template.masterList against the state
  const masterList = useSelector((state) => state.template.masterList || {});

  /**
   * Call the Master CV Spec list API
   */
  useEffect(() => {
    dispatch(getMasterCVSpecTemplatesList());
  }, []);

  // get candidates list reducer->candidate.lookuplist against the state
  const lookupList = useSelector((state) => state.candidate.lookupList || {});
  // console.log(lookupList);

  useEffect(() => {
    // did mount
    if (templateId) {
      setEditTemplateData();
    } else {
      setAddSequenceData();
    }
  }, []);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setValidate(['required']);
    } else {
      setValidate([]);
    }

    setCheckMaster(!event.target.checked);
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    let formDataClone = { ...formData };
    if (users.user_id) {
      formDataClone = {
        ...formDataClone,
        shared_with_users: isArray(users.user_id)
          ? users.user_id.map((sTeam) => sTeam.value)
          : [users.user_id.value],
      };
    }
    if (users.team_id) {
      formDataClone = {
        ...formDataClone,
        shared_with_teams: isArray(users.team_id)
          ? users.team_id.map((sTeam) => sTeam.value)
          : [users.team_id.value],
      };
    }
    setFormData({
      ...formDataClone,
    });
  }, [users.user_id, users.team_id]);

  /**
   * will hit post api to add sequence in the db
   */
  const handleSubmit = () => {
    if (disabledNextButton() === false) {
      setDisabledButton(true);

      let payload = {
        name: formData.name,
        content: formData.content,
        subject: formData.subject,
        access_type: formData.access_type,
        template_type: formData.template_type,
        uuid: formData.uuid,
        master: formData.master,
        master_id: formData.master_id,
        candidates: formData.candidates,
        candidateCvIds: candidateFormats
          .filter((cFor) => cFor.formattedDoc?.id)
          .map((e) => `${e.contact_id}_${e.formattedDoc.id}`)
          .join(','),
      };

      if (formData.access_type === 1) {
        payload = {
          ...payload,
          shared_with_users: usersOptions.filter((uO) =>
            formData.shared_with_users.includes(uO.value),
          ),
        };
      } else if (formData.access_type === 2) {
        payload = {
          ...payload,
          shared_with_teams: teamsOptions.filter((tO) =>
            formData.shared_with_teams.includes(tO.value),
          ),
        };
      }

      if (!cloneTemplate && templateId) {
        api
          .put(`/automation/cvspec-template/${templateId}`, {
            ...payload,
          })
          .then((response) => {
            closeDrawer('editTemplate');
            if (props.page === 'master') {
              dispatch(
                getMasterCVSpecTemplate(null, props.admin ? props.admin : null),
              );
            } else {
              dispatch(
                getCVSpecTemplate(null, props.admin ? props.admin : null),
              );
            }
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
          })
          .catch(() => {
            setDisabledButton(false);
            closeDrawer('error', false);
          });
      } else {
        // create and clone
        api
          .post('/automation/cvspec-template', {
            ...payload,
          })
          .then((response) => {
            cloneTemplate
              ? closeDrawer('cloneTemplate')
              : closeDrawer('addTemplate');
            if (props.page === 'master') {
              dispatch(
                getMasterCVSpecTemplate(null, props.admin ? props.admin : null),
              );
            } else if (props.page === 'step') {
              getCVSpecTemplate(null, props.admin ? props.admin : null);
              props.toSelect &&
                props.toSelect({
                  value: response.data.id,
                  label: response.data.name,
                });
            } else {
              dispatch(
                getCVSpecTemplate(null, props.admin ? props.admin : null),
              );
            }
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
          })
          .catch(() => {
            setDisabledButton(false);
            closeDrawer('error', false);
          });
      }
    }
  };

  /**
   * handle the input change
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function will call after the search of candidates
   * to set the options in the dropdown
   */
  const searchCandidates = async (search) => {
    const res = await getCandidatesLookup(
      search,
      (formData?.candidates && (formData?.candidates || []).map((c) => c.id)) ||
        [],
    );
    return res.data || [];
  };

  /**
   * function to set the initial data in the state
   * @returns {Promise<void>}
   */
  const setAddSequenceData = async () => {
    setIsLoading(true);
    await dispatch(fetchUsersTeams());
    setIsLoading(false);

    // To get master pre-selected if adding cv spec from master grid
    if (props.page === 'master') {
      setCheckMaster(false);
      // console.log('here add from master', props);
      setFormData({
        ...formData,
        master_id: props.masterId ? props.masterId : null,
        master: props.masterId ? true : false,
      });
    }
  };

  /**
   * Get the CV Spec template data
   * @returns
   */
  const setEditTemplateData = async () => {
    setIsLoading(true);
    await dispatch(fetchUsersTeams());
    if (templateId) {
      await api
        .get(`/automation/cvspec-template/${templateId}`)
        .then((response) => {
          setFormData({
            ...formData,
            name: cloneTemplate
              ? `${response.data.name} (clone)`
              : response.data.name,
            subject: response.data.subject,
            content: response.data.content,
            access_type: response.data.access_type,
            master_id: response.data.master_id,
            master: response.data.master_id ? true : false,
            candidates:
              response.data.candidates !== null
                ? response.data.candidates
                : null,

            /*  shared_with_users:
              response.data.shared_with_users !== null
                ? isArray(response.data.shared_with_users)
                  ? response.data.shared_with_users.map((sTeam) => sTeam.value)
                  : response.data.shared_with_users.value
                : [response.data.user.value], */
            shared_with_teams:
              response.data.shared_with_teams !== null
                ? isArray(response.data.shared_with_teams)
                  ? response.data.shared_with_teams.map((sTeam) => sTeam.value)
                  : response.data.shared_with_teams.value
                : [response.data.team.value],
          });
          if (response.data.master_id != null) {
            setCheckMaster(false);
          }

          if (response.data.access_type == 1) {
            setUserToggle(true);
          } else if (response.data.access_type == 2) {
            setTeamToggle(true);
          }
          if (response.data.created_by === authUser.id) {
            setOwnerOfTemplate(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });

      await api
        .get(`/automation/get-candidate-formated-doc?template_id=${templateId}`)
        .then((resp) => {
          setCandidateFormats(
            resp.data.map((d) => ({
              ...d,
              formattedDoc: d.formattedDocList.find(
                (fDoc) => fDoc.id === d.selectedFormattedCv,
              ),
            })),
          );
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
    // console.log('formdata = '.formData.shared_with_teams);
    setIsLoading(false);
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['name', 'content', 'subject', 'access_type'];

    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * handle tinymce editor change event
   * @param name
   * @returns {function(...[*]=)}
   */
  const handleEditorChange = (name) => (value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    // console.log(key, tag);
    if (name === 'master_id') {
      return setFormData({
        ...formData,
        [name]: tag.id,
      });
    }

    if (name === 'shared_with_users' || name === 'shared_with_teams') {
      return setFormData({
        ...formData,
        [name]: tag.map((e) => e[key]),
      });
    }
    if (name === 'candidates') {
      const diffArray = differenceBy(tag, formData[name], 'id');
      const newlyAdded = candidateFormats.length
        ? diffArray.find((t) =>
            candidateFormats.find((c) => c.contact_id !== t.id),
          )
        : tag[0]
        ? tag[0]
        : undefined;
      if (newlyAdded) {
        api
          .get(
            `automation/get-candidate-formated-doc?candidate_id=${newlyAdded.id}`,
          )
          .then((resp) => {
            resp?.data[0] &&
              setCandidateFormats([
                ...candidateFormats,
                { ...resp?.data[0], uuid: uuidv4() },
              ]);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const removeDiffArray = differenceBy(formData[name], tag, 'id');
        candidateFormats.splice(
          candidateFormats.findIndex((c) => c.id === removeDiffArray[0].id),
          1,
        );
        setCandidateFormats([...candidateFormats]);
      }

      return setFormData({
        ...formData,
        [name]: tag,
      });
    }
    let formDataClone = { ...formData };

    if (name === 'access_type') {
      if (tag[key] === 1) {
        setUserToggle(true);
        setTeamToggle(false);
        formDataClone = {
          ...formDataClone,
          shared_with_users: isArray(users.user_id)
            ? users.user_id.map((sTeam) => sTeam.value)
            : [users.user_id.value],
          [name]: tag[key],
        };
        return setFormData({
          ...formDataClone,
        });
      } else if (tag[key] === 2) {
        setUserToggle(false);
        setTeamToggle(true);
        formDataClone = {
          ...formDataClone,
          shared_with_teams: isArray(users.team_id)
            ? users.team_id.map((sTeam) => sTeam.value)
            : [users.team_id.value],
          [name]: tag[key],
        };
        return setFormData({
          ...formDataClone,
        });
      } else {
        setUserToggle(false);
        setTeamToggle(false);
      }
    }
    setFormData({
      ...formData,
      [name]: tag[key],
    });
  };

  /**
   * function to handle the select element for candidate event
   * @param {*} name
   * @returns
   */
  const handleCandidateSelectChange = (name, c) => (event, tag) => {
    const candidateIndex = candidateFormats.findIndex(
      (cand) => c.contact_id === cand.contact_id,
    );

    setCandidateFormats([
      ...candidateFormats.slice(0, candidateIndex),
      { ...candidateFormats[candidateIndex], [name]: tag },
      ...candidateFormats.slice(candidateIndex + 1),
    ]);
  };

  const onCandidateSuccess = (data) => () => {
    api
      .get(
        `automation/get-candidate-formated-doc?candidate_id=${data.contact_id}`,
      )
      .then((resp) => {
        const candidateIndex = candidateFormats.findIndex(
          (cand) => data.contact_id === cand.contact_id,
        );

        resp?.data[0] &&
          setCandidateFormats([
            ...candidateFormats.slice(0, candidateIndex),
            { ...candidateFormats[candidateIndex], ...resp?.data[0] },
            ...candidateFormats.slice(candidateIndex + 1),
          ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // show spinner
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  return (
    <div className={classes.root}>
      <ValidatorForm ref={(r) => (formRef.current = r)} onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}>*/}
          {/*  {english.cvSpecTemplateEditDetail}*/}
          {/*</h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*Sequence Name && Subject*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label={`${english.cvSpecTemplateForm.name} *`}
                  onChange={handleChange}
                  name="name"
                  value={formData?.name || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={[english.cvSpecTemplateForm.nameValidate]}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="pl-3">
                <TextValidator
                  label={`${english.cvSpecTemplateForm.subject} *`}
                  onChange={handleChange}
                  name="subject"
                  value={formData?.subject || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={[english.cvSpecTemplateForm.subjectValidate]}
                />
              </Grid>
              {/* master and master list */}
              <Grid item xs={12} sm={6} className="pr-3">
                <FormGroup>
                  <CheckboxLabel
                    checked={formData?.master || false}
                    name="master"
                    value={''}
                    label="Master"
                    handleChange={handleCheckboxChange}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} className="pl-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('master_id', 'id')}
                  name="master_id"
                  id="master_id"
                  key={'master_id'}
                  disableClearable
                  disabled={checkMaster}
                  options={[...(masterList || [])]}
                  getOptionLabel={(option) => option.name || ''}
                  value={
                    formData?.master_id
                      ? masterList.find((c) => c.id === formData?.master_id) ||
                        ''
                      : ''
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Master"
                      className={classes.selectBoxStyle}
                      name="master_id"
                      value={formData?.master_id || ''}
                      variant="outlined"
                      fullWidth
                      validators={validate}
                      errorMessages={['Select Master']}
                    />
                  )}
                />
              </Grid>
              {/* Candidates list */}
              <Grid item xs={12} sm={12}>
                <LookupAutoComplete
                  multiple
                  onChange={handleSelectChange('candidates', 'id')}
                  name="candidates"
                  id="candidates"
                  disableClearable
                  remoteMethod={(val) => searchCandidates(val)}
                  options={[]}
                  getOptionLabel={(option) => option.value || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={formData?.candidates}
                  inputProps={{
                    value: formData?.candidates || '',
                    label: 'Candidates *',
                    name: 'candidates',
                    variant: 'outlined',
                    fullWidth: true,
                    validators: ['required'],
                    errorMessages: [english.specTemplateForm.selectCandidate],
                  }}
                />
              </Grid>

              {/*html content base */}
              <Grid item xs={12} sm={12}>
                <div className={classes.multipleSelectHeading}>
                  {english.content}
                </div>
                <FormGroup>
                  <TextEditor
                    onChange={handleEditorChange('content')}
                    initialvalue={formData.content}
                  />
                </FormGroup>
              </Grid>

              {/*list will be here */}
              {Boolean(candidateFormats.length) && (
                <Grid item xs={12} sm={12}>
                  {candidateFormats.map((c, index) => (
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      alignItems="center"
                      key={`candidate-${c.id}-${index}`}
                    >
                      {/* <Grid item xs={3}> */}
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {c.contact_name}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={5}> */}
                      <Grid item xs={6}>
                        <FormAutoCompleteSelect
                          onChange={handleCandidateSelectChange(
                            'formattedDoc',
                            c,
                          )}
                          name="formattedDoc"
                          id="formattedDoc"
                          key={'master_id'}
                          disableClearable
                          options={c.formattedDocList || []}
                          getOptionLabel={(option) => option.name || ''}
                          value={c.formattedDoc || ''}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label="Formatted Doc"
                              className={classes.selectBoxStyle}
                              name="formattedDoc"
                              value={c?.formattedDoc?.name || ''}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      {/* <Grid item xs={4}>
                        <FileUpload
                          uuid={c.uuid || uuidv4()}
                          object_id={c.contact_id}
                          allowtype="ATTACHMENT"
                          clone={false}
                          type={'candidate'}
                          label={'Add new formatted profile'}
                          onUploadSuccesfully={onCandidateSuccess(c)}
                        />
                      </Grid> */}
                    </Grid>
                  ))}
                </Grid>
              )}

              {/*access type*/}
              <Grid item xs={12} sm={12}>
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('access_type', 'value')}
                  name="access_type"
                  id="access_type"
                  key={'access_type'}
                  disableClearable
                  options={sequenceAccessOption}
                  getOptionLabel={(option) => option.title || ''}
                  getOptionSelected={(option, value) => option.value === value}
                  value={
                    formData.access_type
                      ? sequenceAccessOption?.find(
                          (c) => c.value === formData?.access_type,
                        ) || ''
                      : ''
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label={`${english.cvSpecTemplateForm.templateAccess} *`}
                      className={classes.selectBoxStyle}
                      name="access_type"
                      value={formData?.access_type || ''}
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        english.cvSpecTemplateForm.templateAccessValidate,
                      ]}
                    />
                  )}
                />
              </Grid>

              {userToggle && (
                <Grid item xs={12} sm={12}>
                  <div className={classes.multipleSelectHeading}>
                    {english.seqUser}
                  </div>
                  <FormAutoCompleteSelect
                    multiple
                    onChange={handleSelectChange('shared_with_users', 'value')}
                    name="shared_with_users"
                    id="shared_with_users"
                    disableClearable
                    options={usersOptions}
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      formData?.shared_with_users?.length
                        ? (usersOptions || []).filter((c) =>
                            formData?.shared_with_users.includes(c.value),
                          )
                        : []
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label=""
                        name="shared_with_users"
                        fullWidth
                        value={formData?.shared_with_users || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[english.seqUserReq]}
                      />
                    )}
                  />
                </Grid>
              )}

              {teamToggle && (
                <Grid item xs={12} sm={12}>
                  <div className={classes.multipleSelectHeading}>
                    {english.seqTeam}
                  </div>
                  <FormAutoCompleteSelect
                    multiple
                    onChange={handleSelectChange('shared_with_teams', 'value')}
                    name="shared_with_teams"
                    id="shared_with_teams"
                    disableClearable
                    options={teamsOptions}
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      formData?.shared_with_teams?.length
                        ? (teamsOptions || []).filter((c) =>
                            formData?.shared_with_teams.includes(c.value),
                          )
                        : []
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label=""
                        name="shared_with_teams"
                        fullWidth
                        value={formData?.shared_with_teams || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[english.seqTeamReq]}
                      />
                    )}
                  />
                </Grid>
              )}
              {/* Candidate's formatted CVs */}
              <Grid item xs={12} sm={12}>
                {/* new component to get candidate record */}
                <div />
                {/* <CandidateDocument formattedCv={formattedCv} /> */}
              </Grid>

              {/*file upload*/}
              <Grid item xs={12} sm={12}>
                {templateId ? (
                  <FileUpload
                    uuid={formData.uuid}
                    object_id={templateId}
                    allowtype="ATTACHMENT"
                    clone={!!cloneTemplate}
                    template_type={formData.template_type}
                  />
                ) : (
                  <FileUpload
                    uuid={formData.uuid}
                    object_id={null}
                    template_type={formData.template_type}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />
          {/*Only owner of spec and admin can update spec*/}
          <div>
            {templateId && !cloneTemplate && !props.admin && ownerOfTemplate ? (
              <Typography>
                Please contact the owner to update this template.
              </Typography>
            ) : (
              <Button
                variant="contained"
                className={classes.nextButton}
                type="submit"
                disabled={disabledNextButton()}
              >
                {templateId
                  ? cloneTemplate
                    ? english.clone
                    : english.update
                  : english.save}
              </Button>
            )}
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default CreateTemplateForm;
