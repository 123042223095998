import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid } from '@material-ui/core';

import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import CustomSnackBar from '../../../components/CustomSnackBar';

import { commonFormStyles } from './style';
import api from '../../../utils/api';

const SystemForm = (props) => {
  const {
    dropdownList,
    timezones,
    userData,
    getUserData,
    userRoles = [],
  } = props;
  const classes = commonFormStyles();

  const [formData, setFormData] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  const formRef = useRef();

  const handleSelectChange = (name, key) => (event, tag) => {
    if (name === 'timeZone') {
      return setFormData({
        ...formData,
        [name]: tag[key],
      });
    }
    setFormData({
      ...formData,
      [name]: name === 'accessLevel' ? tag.mainId : tag.id,
    });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      entity: userData.entity_id,
      team: userData.team_id,
      office: userData.branch_id,
      timeZone: userData.timezone
        ? userData.timezone
        : userData.officce.time_zone,
    });
  }, [userData]);

  const handleSubmit = () => {
    const values = {
      tab: 'System',
      organisation: formData.entity,
      timezone: formData.timeZone,
      team: formData.team,
      branch: formData.office,
    };

    setDisabledButton(true);
    api
      .put(`/automation/users/${userData.id}`, { values })
      .then((res) => {
        getUserData();
        setDisabledButton(false);
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          message: 'Your system data updated successfully',
          status: 'success',
        });
      })
      .catch((error) => {
        setDisabledButton(false);
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          message: error?.response?.data?.error || 'Something went wrong ',
          status: 'error',
        });
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div className={classes.mainFormValidator}>
      <ValidatorForm ref={(r) => (formRef.current = r)} onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> System Info Required </h6>*/}

          <Grid container spacing={2}>
            {/*entity*/}
            <Grid item xs={12} sm={6} className="pr-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('entity')}
                name="entity"
                id="entity"
                key={'entity'}
                disableClearable
                options={[...(dropdownList?.entityList || [])]}
                getOptionLabel={(option) => option.Name || ''}
                value={
                  formData?.entity
                    ? (dropdownList?.entityList || []).find(
                        (c) => c.id === formData?.entity,
                      ) || ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Entity"
                    name="entity"
                    variant="outlined"
                    value={formData?.entity || ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            {/*Team*/}
            <Grid item xs={12} sm={6} className="pl-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('team')}
                name="team"
                id="team"
                key={'team'}
                disableClearable
                options={[...(dropdownList?.teams || [])]}
                getOptionLabel={(option) => option.name || ''}
                value={
                  formData?.team
                    ? (dropdownList?.teams || []).find(
                        (c) => c.id === formData?.team,
                      ) || ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Primary Team"
                    name="team"
                    variant="outlined"
                    value={formData?.team || ''}
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/*office*/}
            <Grid item xs={12} sm={6} className="pr-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('office')}
                name="office"
                id="office"
                key={'office'}
                disableClearable
                options={[...(dropdownList.officeList || [])]}
                getOptionLabel={(option) => option.office_name || ''}
                value={
                  formData?.office
                    ? (dropdownList?.officeList || []).find(
                        (c) => c.id === formData?.office,
                      ) || ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Office *"
                    name="office"
                    value={
                      (dropdownList?.officeList || []).find(
                        (c) => c.id === formData?.office,
                      ) || ''
                    }
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={['Please select Office']}
                  />
                )}
              />
            </Grid>

            {/*timeZone*/}
            <Grid item xs={12} sm={6} className="pl-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('timeZone', 'key')}
                name="timeZone"
                id="timeZone"
                disableClearable
                value={
                  formData.timeZone
                    ? timezones.find((c) => c.key === formData?.timeZone) || ''
                    : ''
                }
                options={[...(timezones || [])]}
                getOptionLabel={(option) => option.value || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="TimeZone"
                    value={formData.timeZone || ''}
                    name="timeZone"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.formFooter}>
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledButton}
            >
              Update
            </Button>
          </div>
        </div>
      </ValidatorForm>
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default SystemForm;
