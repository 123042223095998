import React from 'react';
import { TableFooter, TableRow, TableCell } from '@material-ui/core';
import MuiTablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';

// style for the footer
const defaultFooterStyles = makeStyles(() => ({
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 24px 0px 24px',
  },
  paginationClasses: {
    '& [class*="MuiToolbar-root"]': {
      paddingBottom: '0px !important',
    },
  },
}));

// CustomFooter to add custom css and to add custom and key feature
const CustomFooter = (props) => {
  const classes = defaultFooterStyles();

  // row change event
  const handleRowChange = (event) => {
    props.changeRowsPerPage(event.target.value);
  };

  // page change event
  const handlePageChange = (_, page) => {
    props.changePage(page);
  };

  const { count, textLabels, rowsPerPage, page, serverSide } = props;
  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.footerStyle}>
          <MuiTablePagination
            component="div"
            count={count}
            rowsPerPage={rowsPerPage || 25}
            page={page}
            className={classes.paginationClasses}
            labelRowsPerPage={textLabels.rowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${textLabels.displayRows} ${count}`
            }
            backIconButtonProps={{
              'aria-label': textLabels.previous,
            }}
            nextIconButtonProps={{
              'aria-label': textLabels.next,
            }}
            rowsPerPageOptions={[25, 50, 100]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowChange}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default CustomFooter;
