import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TeamsGrid from './TeamsGrid';

// const Teams = (props) => {
//   return (
//     <div className="">
//       <div className="flex-row">
//         <aside className="sideBarMargin">
//           <SidebarNav teams="active" />
//         </aside>
//
//         <div className="flex-col content">
//           <TeamsGrid {...props} />
//         </div>
//       </div>
//     </div>
//   );
// };

const Teams = (props) => {
  return <TeamsGrid {...props} />;
};

Teams.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Teams);
