import {
  USERS_TEAMS_LOADED,
  USERS_ERROR,
  GET_DROPDOWN_LIST,
  GET_USERS,
} from '../actions/types';

const initialState = {
  usersList: [],
  dropdownList: [],
  users: [],
  teams: [],
  user_id: null,
  team_id: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USERS_TEAMS_LOADED: {
      const data = {
        ...state,
        users: payload.users,
        teams: payload.teams,
        user_id: payload.user_id,
        team_id: payload.team_id,
        loading: false,
      };
      return data;
    }
    case GET_USERS: {
      const data = {
        ...state,
        usersList: payload,
        loading: false,
      };
      return data;
    }
    case GET_DROPDOWN_LIST: {
      const data = {
        ...state,
        dropdownList: payload,
        loading: false,
      };
      return data;
    }
    case USERS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        profile: null,
      };
    default:
      return state;
  }
}
