import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SequenceTabs from "./SequenceGrid/SequenceTabs";
// import SequenceGrid from './SequenceGrid/index';


const Sequence = (props) => {
  return <SequenceTabs admin {...props} />;
};

Sequence.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Sequence);
