import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Snackbar,
  Box,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';

import CustomDataGrid from '../controlled-component/customGridComponent';
import UserCustomToolbar from './UsersCustomToolbar';
import AddUserDrawer from './AddUserDrawer';
import Alert from '@material-ui/lab/Alert';
import { ActiveIcon, InActiveIcon } from '../components/GridIcons';
import LoadingTableBody from '../components/LoadingTableBody';

import { makeProperNamingStatement } from '../utils';
import { getUsersList, updateStatus } from '../actions/users';
import { fetchUserListData, fetchGridFilters } from '../actions/admin/user';
import CustomTableToolbar from '../admin/sequencedCandidates/CustomTableToolbar';

import 'react-toastify/dist/ReactToastify.css';

// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  OfficeGridWrapper: {
    padding: '10px 0 20px 0',
  },
}));

const UserGrid = (props) => {
  const classes = useStyles();

  const [popupToggle, setPopupToggle] = useState(false);
  const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [userId, setUserId] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackerMessage, setSnackerMessage] = useState(null);
  const [facetdata, setFacetdata] = useState({});
  const [facetfields, setFacetfields] = useState([]);
  const gridParams = useRef({});
  const gridColParams = useRef({});
  const filterStateParams = useRef({});
  const filterStateRef = useRef({});
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const userList = useSelector((state) => state.userGrid[props.tabProps.id]);
  // const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    gridParams.current = userList.gridParams;
  }, [userList.gridParams]);

  useEffect(() => {
    filterStateParams.current = userList.filterState;
  }, [userList.filterState]);

  useEffect(() => {
    if (!isEmpty(userList.columns)) {
      gridColParams.current = userList.columns;
    }
  }, [userList.columns]);

  useEffect(() => {
    // to update the report option
    if (userList.usersList.length && userList.myFilterData.length) {
      setFacetdata({});
      setFacetfields(
        userList.myFilterData.map((e) => ({
          label: e.name,
          value: e.title,
        }))
      );
    }
    filterStateRef.current = userList.filterState;
  }, [userList]);

  // useEffect(() => {
  //   // did mount
  //   dispatch(fetchGridFilters(props.tabProps));
  // }, []);

  useEffect(() => {
    // if (!isEmpty(userList.filterList) && userList.userList) {
    if (!isEmpty(userList.filterList)) {
      // call the fetch activity report api and set data in reducer
      dispatch(
        fetchUserListData(
          undefined,
          userList.gridParams
            ? userList.gridParams
            : { pageNo: 0, perPage: 25, searchText: null },
          props.tabProps,
          undefined
        ),
      );
    }
  }, [userList.filterList]);

  //handle function to open/close add user pop up
  const handlePopupOpen = () => {
    setPopupToggle(true);
  };

  //handle function to open/close add user pop up
  const handlePopupClose = () => {
    setPopupToggle(false);
  };

  // handle the edit user pop up
  const handleEditPopupOpen = (id) => {
    // setEditPopupToggle(!editPopupToggle);
    // setUserId(id);
    props.history.push(`/admin/users/${id}`);
  };

  // update status with reducer
  const changeStatus = (userId, status) => {
    const value = status === 3 ? 4 : 3;
    dispatch(updateStatus(userId, value, showSnackbar));
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setSnackerMessage(null);
    setOpenSnackBar(false);
  };
  /**
   * Handle function to close the toaster popup
   */
  const showSnackbar = (message) => {
    setSnackerMessage(message);
    setOpenSnackBar(true);
  };

  useEffect(() => {
    // did mount
    // call the user api and set data in reducer
    // dispatch(getUsersList());
    dispatch(
      fetchUserListData(
        undefined,
        userList.gridParams,
          // ? userList.gridParams
          // : { pageNo: 0, perPage: 25, searchText: null },
        props.tabProps,
        undefined,
      ),
    );
  }, []);

  const handleNameClick = (e, id) => {
    handleEditPopupOpen(id);
  };

  const handleReFetch = (filterObject) => {
    dispatch(
      fetchUserListData(
        {
          ...(isEmpty(filterObject) ? {} : filterObject),
        },
        {
          ...gridParams.current,
          pageNo: 0,
          perPage: 25,
        },
        props.tabProps,
        gridColParams.current
      )
    );
  };

  const handleTableChange = (params) => {
    dispatch(
      fetchUserListData(
        {
          ...userList.filterState,
        },
        params,
        props.tabProps,
        gridColParams.current,
      ),
    );
  };

  const handleColumnChange = (showLoader) => (paramColumns) => {
    dispatch(
      fetchUserListData(
        {
          ...filterStateParams.current,
        },
        gridParams.current,
        props.tabProps,
        paramColumns,
        showLoader,
      ),
    );
  };

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: false,
          sort: true,
          draggable: false,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = userList.userList[dataIndex];
            return (
              <Tooltip title={rData.name || ''} arrow>
                <Box className="td-content-ellipsis td-anchor-content-ellipsis">
                  <Link
                    className={`sort-by td-content-ellipsis td-anchor-content-ellipsis`}
                    to={`/admin/users/${rData.id}`}
                    onClick={(e) => handleNameClick(e, rData.id)}
                  >
                    {makeProperNamingStatement(rData.name || '')}
                  </Link>
                </Box>
                {/*<div*/}
                {/*  className={`sort-by ${classes.tdContentEllipsis} ${classes.tdAnchorContentEllipsis}`}*/}
                {/*  onClick={(e) => handleNameClick(e, rData.id)}*/}
                {/*>*/}
                {/*  {rData.name}*/}
                {/*</div>*/}
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Entity',
        name: 'org_name',
        options: {
          filter: false,
          sort: true,
          draggable: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value || ''} arrow>
                <div className={`td-content-ellipsis`}>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Office',
        name: 'country_name',
        options: {
          filter: false,
          sort: true,
          draggable: true,
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Team',
        name: 'team_name',
        options: {
          filter: false,
          sort: true,
          draggable: true,
          customBodyRender: (value) => {
            return <div>{value || ''}</div>;
          },
        },
      },
      {
        label: 'Start Date',
        name: 'created_date',
        options: {
          filter: false,
          sort: true,
          draggable: true,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              //console.log(order, obj1, obj2);
              /* let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === 'asc' ? 1 : -1); */
              return (
                (new Date(obj1.data) - new Date(obj2.data)) *
                (order === 'asc' ? 1 : -1)
              );
            };
          },
          customBodyRender: (value) => {
            return <Moment format="Do MMM YYYY">{value}</Moment>;
          },
        },
      },
      {
        label: 'Status',
        name: 'status_label',
        options: {
          filter: false,
          sort: true,
          draggable: true,
          customBodyRender: (value) => {
            return value === 'Active' ? (
              <ActiveIcon fontSize="small" />
            ) : (
              <InActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = userList.userList.find((tData) => tData.id === value);
            return (
              <>
                <GridActions
                  temp_id={row?.id}
                  handleEditPopupOpen={handleEditPopupOpen}
                  handleClonePopupOpen={() => {}}
                  status={row?.status}
                  row={row}
                  updatestatus={changeStatus}
                  auth={auth}
                  {...props}
                />
              </>
            );
          },
        },
      },
    ],
    [userList.userList, userList.myFilterData],
  );

  /**
   * Tool Bar for grid
   */
  const TableToolbar = useMemo(
    () => (props) => {
      return(
        <CustomTableToolbar
          refetch={handleReFetch}
          myFilterData={userList.myFilterData}
          defaultFilterState={userList.defaultFilterState}
          filterState={userList.filterState}
          visibleFilter={0}
          filterDrawerName={props.options?.filterPlaceholder}
          showEditCol
          showFilterOption={false}
          showDatePicker={false}
          onColumnChange={handleColumnChange()}
          loading={userList.loading}
          flist={userList.filterList}
          showMoreFilterOption={false}
          // selectPlaceHolder={'Assigned To'}
          // datePlaceHolder={'Assigned Date'}
          // selectNameToSave={'assigned_to'}
          // dateRangeNameToSave={'assignedDate'}
          selectDataOptions={userList?.filterList?.consultantList?.length ? userList.filterList?.consultantList : []}
          datePickerDataOptions={userList?.filterList?.assigned_date?.length ? userList.filterList?.assigned_date : []}
          userFilterSelectValue={userList?.filterState?.assigned_to ? userList?.filterState?.assigned_to : []}
          userFilterDateValue={userList?.filterState?.assignedDate ? userList?.filterState?.assignedDate : ''}
          {...props}
        />
      );
    },
    [
      userList.myFilterData,
      userList.defaultFilterState,
      userList.filterList,
      userList.loading,
    ],
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={userList.loading} {...props} />,
    [userList.loading],
  );

  return (
    <div className={classes.templateGridMainSection}>
      <div className={classes.OfficeGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns.map((col, index) => ({
            ...col,
            options: userList?.columns?.columns?.length
              ? { ...col.options, ...userList?.columns?.columns[index] }
              : col.options,
          }))}
          data={userList.userList}
          onTableChange={handleTableChange}
          onColumnChange={handleColumnChange(false)}
          options={{
            columnOrder: userList?.columns?.columnsOrder,
            pagination: Boolean(userList?.userList?.length),
            page: userList?.gridParams?.pageNo || 0,
            rowsPerPage: userList?.gridParams?.perPage || 25,
            searchText: userList?.gridParams?.searchText,
            selectableRows: 'multiple',
            serverSide: true,
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search Users',
            filterOptions: facetfields,
            facetdata: facetdata,
            gridParams: gridParams,
            searchOpen: true,
            search: true,
            filter: true,
            download: true,
            viewColumns: true,
            count: userList?.count,
            selectToolbarPlacement: 'replace',
            sortOrder: userList?.gridParams?.sortOrder
              ? userList?.gridParams?.sortOrder
              : undefined,
            customToolbar: () => {
              // Toolbar for search and add
              return <UserCustomToolbar addUserClick={handlePopupOpen} />;
            },
          }}
          components={{
            TableBody: BodyComponent,
            TableToolbar: TableToolbar,
          }}
        />

        <AddUserDrawer openDrawer={popupToggle} onClose={handlePopupClose} />
        <Snackbar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleSnackbarClose}
            severity="success"
          >
            {snackerMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      case 'changeStatus': {
        props.updatestatus(props.temp_id, props.status);
        break;
      }
      case 'resetPassword': {
        props.handleClonePopupOpen(props.temp_id);
        break;
      }
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: 'Edit',
      itemType: 'editPopUp',
    },
    {
      title: 'Change Status',
      itemType: 'changeStatus',
    },
    /* {
      title: 'Reset Password',
      itemType: 'resetPassword',
    }, */
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default UserGrid;
