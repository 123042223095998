import { LOAD_TEAMS, TEAM_ERROR, ADD_TEAM } from '../../actions/types';

const initialState = {
  teamList: [],
  team_id: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_TEAMS: {
      const data = {
        ...state,
        teamList: payload,
        loading: false,
      };
      return data;
    }

    case TEAM_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
