import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../utils/api';
import Alert from '@material-ui/lab/Alert';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import Spinner from '../../../layout/Spinner';
import {
  fetchEntities,
  fetchDropdownOptions,
} from '../../../actions/admin/entity';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formHeading: {
    fontSize: 20,
    fontWeight: 500,
    paddingBottom: 10,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
  },
  formCheckbox: {
    '&.Mui-checked': {
      color: custom.colorCode.lightRedColor,
    },
    color: custom.colorCode.lightRedColor,
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
}));

/**
 * Component for Entity form
 * @param {*} props
 * @returns
 */
const AddEntityForm = (props) => {
  const { entityId } = props;
  const classes = useStyles();
  //Local state variables
  const [formData, setFormData] = useState({});
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [openErrorSnackBarMsg, setOpenErrorSnackBarMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  /* const [timezoneSelected, setTimezoneSelected] = useState(null);
  const [countrySelected, setCountrySelected] = useState(null); */

  //Code to subscribe users and teams data from reducer
  const dispatch = useDispatch();
  const dropdownOptions = useSelector(
    (state) => state.entity.dropdownList || {},
  );

  /**
   * Did mount fuction to set dropdown options in reducer
   */
  useEffect(() => {
    dispatch(fetchDropdownOptions());
  }, [fetchDropdownOptions]);

  /**
   * Did mount function to get entity details for edit popup
   */
  useEffect(() => {
    if (entityId) {
      setIsLoading(true);
      api
        .get(`/automation/entity/${entityId}`)
        .then((res) => {
          setIsLoading(false);
          //console.log(res.data);
          setFormData({
            country: res.data.country_id,
            timezone: res.data.time_zone,
            office_number: res.data.main_office_phone,
            name: res.data.name,
            region: res.data.region,
            addressOne: res.data.street1,
            addressTwo: res.data.street2,
            town: res.data.town,
            post_code: res.data.zip,
            officeId: res.data.officeId,
          });
          /* const country = dropdownOptions.countryList?.find(
            (country) => country.key === res.data.country_id,
          );
          const timezone = dropdownOptions.timezoneList?.find(
            (timesone) => timesone.key === res.data.time_zone,
          ); */
          //console.log(dropdownOptions.countryList, dropdownOptions.timezoneList);
          //setCountrySelected(res.data.country_id);
          //setTimezoneSelected(res.data.time_zone);
        })
        .catch((error) => {
          setIsLoading(false);
          setOpenErrorSnackBar(true);
          setOpenErrorSnackBarMsg(
            error.response?.data?.error || 'Something went wrong',
          );
          //console.log(error, error.response.data.error);
        });
    }
  }, [entityId]);

  /**
   * Handle function of create/update entity form
   * It call server API to save data into tables
   */
  const handleSubmit = () => {
    if (disabledNextButton() === false) {
      setDisabledButton(true);

      let values = formData;

      //if entity id exist then update API will call otherwise add API will call
      if (entityId) {
        api
          .put(`/automation/entity/${entityId}`, { values })
          .then((res) => {
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
            if (res.status === 200) {
              props?.closeDrawer({ entityUpdated: true });
            }
            dispatch(fetchEntities());
          })
          .catch((error) => {
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
            setOpenErrorSnackBar(true);
            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
            //console.log(error, error.response.data.error);
          });
      } else {
        api
          .post('/automation/entity', { values })
          .then((res) => {
            if (res.status === 201) {
              props?.closeDrawer({ entityAdded: true });
            }
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
            dispatch(fetchEntities());
          })
          .catch((error) => {
            setOpenErrorSnackBar(true);
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
          });
      }
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    //console.log(formData);
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = [
      'name',
      'addressOne',
      'town',
      'region',
      'post_code',
      'country',
      'timezone',
      'office_number',
    ];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * function to handle the form element change event
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name) => (event, tag) => {
    setFormData({
      ...formData,
      [name]: tag.key,
    });
  };

  /**
   * function to close toaster after server side error
   */
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackBar(false);
  };

  // show loader
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }
  /**
   * Return function to render UI elements
   */
  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> Entity Details </h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*Office Name*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Entity Name *"
                  onChange={handleChange}
                  name="name"
                  value={formData?.name || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter entity name']}
                />
              </Grid>

              {/*Address 1*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label="Address 1 *"
                  onChange={handleChange}
                  name="addressOne"
                  value={formData?.addressOne || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  multiline
                  errorMessages={['Please enter address']}
                />
              </Grid>
              {/*Address 2*/}
              <Grid item xs={12} sm={6} className="pl-3">
                <TextValidator
                  label="Address 2"
                  onChange={handleChange}
                  name="addressTwo"
                  value={formData?.addressTwo || ''}
                  variant="outlined"
                  fullWidth
                  multiline
                />
              </Grid>

              {/*City*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label="City *"
                  onChange={handleChange}
                  name="town"
                  value={formData?.town || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter city']}
                />
              </Grid>
              {/*Region*/}
              <Grid item xs={12} sm={6} className="pl-3">
                <TextValidator
                  label="Region *"
                  onChange={handleChange}
                  name="region"
                  value={formData?.region || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter region']}
                />
              </Grid>

              {/*Post Code*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label="Post Code *"
                  onChange={handleChange}
                  name="post_code"
                  value={formData?.post_code || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']} /* , 'matchRegexp:^[0-9]*$' */
                  errorMessages={[
                    'Please enter post code',
                    /* 'Value should be in number', */
                  ]}
                />
              </Grid>
              {/*Country*/}
              <Grid item xs={12} sm={6} className="pl-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('country')}
                  name="country"
                  id="country"
                  key={'country'}
                  disableClearable
                  options={[...(dropdownOptions.countryList || [])]}
                  getOptionLabel={(option) => option.value || ''}
                  getOptionSelected={(option, value) => option.key == value}
                  value={
                    formData.country
                      ? dropdownOptions.countryList?.find(
                          (c) => c.key === formData?.country,
                        ) || ''
                      : ''
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Country *"
                      className={classes.selectBoxStyle}
                      name="country"
                      value={formData?.country || ''}
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Select Country']}
                    />
                  )}
                />
              </Grid>

              {/*TimeZone*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('timezone')}
                  name="timezone"
                  id="timezone"
                  key={'timezone'}
                  disableClearable
                  options={[...(dropdownOptions.timezoneList || [])]}
                  getOptionLabel={(option) => option.value || ''}
                  getOptionSelected={(option, value) => option.key == value}
                  value={
                    formData.timezone
                      ? dropdownOptions.timezoneList?.find(
                          (c) => c.key === formData?.timezone,
                        ) || ''
                      : ''
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="TimeZone *"
                      className={classes.selectBoxStyle}
                      name="timezone"
                      value={formData?.timezone || ''}
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Select Timezone']}
                    />
                  )}
                />
              </Grid>
              {/*Office number*/}
              <Grid item xs={12} sm={6} className="pl-3">
                <TextValidator
                  label="Office number *"
                  onChange={handleChange}
                  name="office_number"
                  value={formData?.office_number || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter office number']}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledNextButton()}
            >
              {entityId ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </ValidatorForm>
      <Snackbar
        open={openErrorSnackBar}
        autoHideDuration={2000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleErrorSnackbarClose}
          severity="error"
        >
          {openErrorSnackBarMsg || ''}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddEntityForm;
