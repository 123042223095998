import React, { useState } from 'react';
import ListItems, { ListItem } from './index';
import moment from 'moment';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { getSequenceColor } from '../../utils/sequenceUtils';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import MenuDropDown from '../MenuDropDown';
import TripOriginIcon from '@material-ui/icons/TripOrigin';
import PanToolIcon from '@material-ui/icons/PanTool';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const useStyles = makeStyles(({ custom }) => ({
  mainListItem: {
    padding: 0,
    '& .MuiListItem-root': {
      paddingLeft: 0,
    },
    '& .MuiListItemText-multiline': {
      marginTop: 4,
      marginBottom: 4,
    },
    '& .MuiListItemSecondaryAction-root': {
      right: 4,
    },
  },
  statusContentWrapper: {
    transform: 'translateY(-20px)',
  },
  statusContent: {
    padding: '4px 8px',
    borderRadius: 4,
  },
  sequenceActionIcon: {
    fill: custom.colorCode.lightGreyColorShade12,
    marginRight: 8,
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: 4,
    minWidth: 110,
  },
}));

const SequenceListItem = ({
  displayData,
  className = '',
  handleMenuClose,
  menuId,
  anchorElement,
  setAnchorElement,
  dropDownOptions,
  setDropDownOptions,
}) => {
  const classes = useStyles();

  const handleActionsMenuOpen = (seqData) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    switch (seqData.status) {
      case 1:
        setDropDownOptions([
          {
            name: 'stop',
            label: 'Stop',
            icon: (
              <TripOriginIcon
                fontSize="small"
                className={classes.sequenceActionIcon}
              />
            ),
            seqData,
          },
          {
            name: 'hold',
            label: 'Hold',
            icon: (
              <PanToolIcon
                fontSize="small"
                className={classes.sequenceActionIcon}
              />
            ),
            seqData,
          },
        ]);
        setAnchorElement(event.currentTarget);
        break;

      case 2:
        setDropDownOptions([
          {
            name: 'start',
            label: 'Start',
            icon: (
              <PlayCircleFilledIcon
                fontSize="small"
                className={classes.sequenceActionIcon}
              />
            ),
            seqData,
          },
        ]);
        setAnchorElement(event.currentTarget);
        break;

      case 4:
        setDropDownOptions([
          {
            name: 'start',
            label: 'Start',
            icon: (
              <PlayCircleFilledIcon
                fontSize="small"
                className={classes.sequenceActionIcon}
              />
            ),
            seqData,
          },
        ]);
        setAnchorElement(event.currentTarget);
        break;

      case 3:
        setDropDownOptions([]);
        break;

      default:
        setDropDownOptions([]);
        break;
    }
  };

  return (
    <>
      <ListItems className={`${className} ${classes.mainListItem}`}>
        {displayData.map((seq, index) => (
          <ListItem
            key={`seq-data-${seq.id}-start-task-${index}`}
            showSecondaryAction
            primaryText={seq.sequenceName}
            secondaryText={
              <>
                Enrolled by {seq.personAdded} on{' '}
                {moment(seq.createdDate).format('MMM DD, YYYY')} <br />
                Step {seq.stage_reached} of {seq.step_count} is completed
              </>
            }
            itemSecondaryContent={
              <Box className={classes.statusContentWrapper} display="flex">
                <Box
                  className={classes.statusContent}
                  style={{ background: getSequenceColor(seq) }}
                >
                  {seq.status === 1 ? 'Active' : seq.status_label}
                </Box>
                {seq.status !== 3 && (
                  <Typography
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleActionsMenuOpen(seq)}
                  >
                    <MoreVertIcon
                      style={{
                        fill: '#5780ab',
                        cursor: 'pointer',
                        marginRight: -10,
                      }}
                    />
                  </Typography>
                )}
              </Box>
            }
          />
        ))}
      </ListItems>

      {Boolean(!displayData.length) && (
        <Box display="flex" justifyContent="center">
          <Typography variant={'body1'}>Sorry, no sequence found</Typography>
        </Box>
      )}

      <MenuDropDown
        anchorEl={anchorElement}
        id={menuId}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
        classes={{
          paper: classes.menuPaper,
        }}
      >
        {dropDownOptions.map((dOption) => (
          <MenuItem
            key={`menu-${dOption.seqData.id}-seqData-action-items`}
            onClick={handleMenuClose(dOption)}
          >
            {dOption.icon} {dOption.label}
          </MenuItem>
        ))}
      </MenuDropDown>
    </>
  );
};

export default SequenceListItem;
