import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { isArray } from 'lodash';
import { Button, FormGroup, Grid, Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import FormAutoCompleteSelect from '../../components/FormAutoCompleteSelect';
import Spinner from '../../layout/Spinner';
import TextEditor from '../../utils/Editor';
import FileUpload from '../../utils/Fileupload';
import { fetchUsersTeams } from '../../actions/users';
import { getJobSpecTemplate } from '../../actions/template';
import { english } from '../../utils/language';
import api from '../../utils/api';

import useStyles from './CreateTemplateFormStyle';

// sequence access options
const sequenceAccessOption = [
  {
    value: 1,
    title: 'Private',
  },
  {
    value: 2,
    title: 'Team',
  },
  {
    value: 3,
    title: 'Organisation',
  },
];

const CreateTemplateForm = (props) => {
  const {
    closeDrawer,
    history,
    templateId,
    cloneTemplate,
    ownerOfTemplate,
    setOwnerOfTemplate,
  } = props;

  const classes = useStyles();

  const [formData, setFormData] = useState({
    template_type: '3',
    uuid: uuidv4(),
  });
  const [isLoading, setIsLoading] = useState(true);
  const [teamToggle, setTeamToggle] = useState(false);
  const [userToggle, setUserToggle] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const formRef = useRef();

  const users = useSelector((state) => state.users);
  const authUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // users options
  const usersData = users.users ? users.users : null;
  const usersOptions = (usersData || []).map((user) => ({
    value: user.company_user_id,
    label: user.name,
  }));

  // teams options
  const teamsData = users.teams ? users.teams : null;
  const teamsOptions = (teamsData || []).map((team) => ({
    value: team.id,
    label: team.name,
  }));

  useEffect(() => {
    // did mount
    if (templateId) {
      setEditSequenceData();
    } else {
      setAddSequenceData();
    }
  }, []);

  useEffect(() => {
    let formDataClone = { ...formData };
    if (users.user_id) {
      formDataClone = {
        ...formDataClone,
        shared_with_users: isArray(users.user_id)
          ? users.user_id.map((sTeam) => sTeam.value)
          : [users.user_id.value],
      };
    }
    if (users.team_id) {
      formDataClone = {
        ...formDataClone,
        shared_with_teams: isArray(users.team_id)
          ? users.team_id.map((sTeam) => sTeam.value)
          : [users.team_id.value],
      };
    }
    setFormData({
      ...formDataClone,
    });
  }, [users.user_id, users.team_id]);

  /**
   * will hit post api to add sequence in the db
   */
  const handleSubmit = () => {
    if (disabledNextButton() === false) {
      setDisabledButton(true);

      let payload = {
        name: formData.name,
        content: formData.content,
        subject: formData.subject,
        access_type: formData.access_type,
        template_type: formData.template_type,
        uuid: formData.uuid,
      };

      if (formData.access_type === 1) {
        payload = {
          ...payload,
          shared_with_users: usersOptions.filter((uO) =>
            formData.shared_with_users.includes(uO.value),
          ),
        };
      } else if (formData.access_type === 2) {
        payload = {
          ...payload,
          shared_with_teams: teamsOptions.filter((tO) =>
            formData.shared_with_teams.includes(tO.value),
          ),
        };
      }

      if (!cloneTemplate && templateId) {
        api
          .put(`/automation/template/${templateId}`, {
            values: payload,
          })
          .then((response) => {
            closeDrawer('editTemplate');
            dispatch(
              getJobSpecTemplate(null, props.admin ? props.admin : null),
            );
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
          })
          .catch(() => {
            setDisabledButton(false);
            closeDrawer('error', false);
          });
      } else {
        api
          .post('/automation/template', {
            values: payload,
          })
          .then((response) => {
            closeDrawer
              ? closeDrawer('cloneTemplate')
              : closeDrawer('addTemplate');
            if (props.page === 'step') {
              dispatch(getJobSpecTemplate(1));
              props.toSelect &&
                props.toSelect({
                  value: response.data.id,
                  label: response.data.name,
                });
            } else {
              dispatch(
                getJobSpecTemplate(null, props.admin ? props.admin : null),
              );
            }
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
          })
          .catch(() => {
            setDisabledButton(false);
            closeDrawer('error', false);
          });
      }
    }
  };

  /**
   * handle the input change
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to set the initial data in the state
   * @returns {Promise<void>}
   */
  const setAddSequenceData = async () => {
    setIsLoading(true);
    await dispatch(fetchUsersTeams());
    setIsLoading(false);
  };

  const setEditSequenceData = async () => {
    setIsLoading(true);
    await dispatch(fetchUsersTeams());
    if (templateId) {
      return api
        .get(`/automation/template/${templateId}`)
        .then((response) => {
          setFormData({
            ...formData,
            name: cloneTemplate
              ? `${response.data.name} (clone)`
              : response.data.name,
            subject: response.data.subject,
            content: response.data.content,
            access_type: response.data.access_type,
            shared_with_users:
              response.data.shared_with_users !== null
                ? isArray(response.data.shared_with_users)
                  ? response.data.shared_with_users.map((sTeam) => sTeam.value)
                  : response.data.shared_with_users.value
                : [response.data.user.value],
            shared_with_teams:
              response.data.shared_with_teams !== null
                ? isArray(response.data.shared_with_teams)
                  ? response.data.shared_with_teams.map((sTeam) => sTeam.value)
                  : response.data.shared_with_teams.value
                : [response.data.team.value],
          });

          if (response.data.access_type == 1) {
            setUserToggle(true);
          } else if (response.data.access_type == 2) {
            setTeamToggle(true);
          }
          if (response.data.created_by === authUser.id) {
            setOwnerOfTemplate(false);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    setIsLoading(false);
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['name', 'content', 'subject', 'access_type'];

    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * handle tinymce editor change event
   * @param name
   * @returns {function(...[*]=)}
   */
  const handleEditorChange = (name) => (value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    if (name === 'shared_with_users' || name === 'shared_with_teams') {
      return setFormData({
        ...formData,
        [name]: tag.map((e) => e[key]),
      });
    }
    let formDataClone = { ...formData };

    if (name === 'access_type') {
      if (tag[key] === 1) {
        setUserToggle(true);
        setTeamToggle(false);
        formDataClone = {
          ...formDataClone,
          shared_with_users: isArray(users.user_id)
            ? users.user_id.map((sTeam) => sTeam.value)
            : [users.user_id.value],
          [name]: tag[key],
        };
        return setFormData({
          ...formDataClone,
        });
      } else if (tag[key] === 2) {
        setUserToggle(false);
        setTeamToggle(true);
        formDataClone = {
          ...formDataClone,
          shared_with_teams: isArray(users.team_id)
            ? users.team_id.map((sTeam) => sTeam.value)
            : [users.team_id.value],
          [name]: tag[key],
        };
        return setFormData({
          ...formDataClone,
        });
      } else {
        setUserToggle(false);
        setTeamToggle(false);
      }
    }
    setFormData({
      ...formData,
      [name]: tag[key],
    });
  };

  // show spinner
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  return (
    <div className={classes.root}>
      <ValidatorForm ref={(r) => (formRef.current = r)} onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}>*/}
          {/*  {english.specTemplateEditDetail}*/}
          {/*</h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*Sequence Name && Subject*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label={`${english.specTemplateForm.name} *`}
                  onChange={handleChange}
                  name="name"
                  value={formData?.name || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={[english.specTemplateForm.nameValidate]}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="pl-3">
                <TextValidator
                  label={`${english.specTemplateForm.subject} *`}
                  onChange={handleChange}
                  name="subject"
                  value={formData?.subject || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={[english.specTemplateForm.subjectValidate]}
                />
              </Grid>

              {/*html content base */}
              <Grid item xs={12} sm={12}>
                <div className={classes.multipleSelectHeading}>
                  {english.content}
                </div>
                <FormGroup>
                  <TextEditor
                    onChange={handleEditorChange('content')}
                    initialvalue={formData.content}
                  />
                </FormGroup>
              </Grid>

              {/*access type*/}
              <Grid item xs={12} sm={12}>
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('access_type', 'value')}
                  name="access_type"
                  id="access_type"
                  key={'access_type'}
                  disableClearable
                  options={sequenceAccessOption}
                  getOptionLabel={(option) => option.title || ''}
                  getOptionSelected={(option, value) => option.value === value}
                  value={
                    formData.access_type
                      ? sequenceAccessOption?.find(
                          (c) => c.value === formData?.access_type,
                        ) || ''
                      : ''
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label={`${english.specTemplateForm.templateAccess} *`}
                      className={classes.selectBoxStyle}
                      name="access_type"
                      value={formData?.access_type || ''}
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        english.specTemplateForm.templateAccessValidate,
                      ]}
                    />
                  )}
                />
              </Grid>

              {userToggle && (
                <Grid item xs={12} sm={12}>
                  <div className={classes.multipleSelectHeading}>
                    {english.seqUser}
                  </div>
                  <FormAutoCompleteSelect
                    multiple
                    onChange={handleSelectChange('shared_with_users', 'value')}
                    name="shared_with_users"
                    id="shared_with_users"
                    disableClearable
                    options={usersOptions}
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      formData?.shared_with_users?.length
                        ? (usersOptions || []).filter((c) =>
                            formData?.shared_with_users.includes(c.value),
                          )
                        : []
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label=""
                        name="shared_with_users"
                        fullWidth
                        value={formData?.shared_with_users || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[english.seqUserReq]}
                      />
                    )}
                  />
                </Grid>
              )}

              {teamToggle && (
                <Grid item xs={12} sm={12}>
                  <div className={classes.multipleSelectHeading}>
                    {english.seqTeam}
                  </div>
                  <FormAutoCompleteSelect
                    multiple
                    onChange={handleSelectChange('shared_with_teams', 'value')}
                    name="shared_with_teams"
                    id="shared_with_teams"
                    disableClearable
                    options={teamsOptions}
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      formData?.shared_with_teams?.length
                        ? (teamsOptions || []).filter((c) =>
                            formData?.shared_with_teams.includes(c.value),
                          )
                        : []
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label=""
                        name="shared_with_teams"
                        fullWidth
                        value={formData?.shared_with_teams || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[english.seqTeamReq]}
                      />
                    )}
                  />
                </Grid>
              )}

              {/*file upload*/}
              <Grid item xs={12} sm={12}>
                {templateId ? (
                  <FileUpload
                    uuid={formData.uuid}
                    object_id={templateId}
                    allowtype="ATTACHMENT"
                    clone={cloneTemplate ? true : false}
                  />
                ) : (
                  <FileUpload uuid={formData.uuid} object_id={null} />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />
          {/*Only owner of spec and admin can update spec*/}
          <div>
            {templateId && !cloneTemplate && !props.admin && ownerOfTemplate ? (
              <Typography>
                Please contact the owner to update this template.
              </Typography>
            ) : (
              <Button
                variant="contained"
                className={classes.nextButton}
                type="submit"
                disabled={disabledNextButton()}
              >
                {templateId
                  ? cloneTemplate
                    ? english.clone
                    : english.update
                  : english.save}
              </Button>
            )}
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default CreateTemplateForm;
