import React, { useEffect, useState } from 'react';
import { commonFormStyles } from './style';
import { Button, FormGroup, Grid } from '@material-ui/core';

import CustomSnackBar from '../../../components/CustomSnackBar';
import TextEditor from '../../../utils/Editor';
import api from '../../../utils/api';

const Signature = (props) => {
  const { userData, getUserData } = props;
  const classes = commonFormStyles();

  const [editorValue, setEditorValue] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleEditorChange = (value) => {
    setEditorValue(value);
  };

  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  useEffect(() => {
    setEditorValue(userData?.signature?.signature || '');
  }, [userData]);

  const handleSubmitClick = () => {
    if (!editorValue) {
      return;
    }
    setDisabledButton(true);

    const values = {
      tab: 'Signature',
      signature_id: userData?.signature?.id || null,
      signature: editorValue,
    };

    api
      .put(`/automation/users/${userData.id}`, { values })
      .then((res) => {
        getUserData();
        setDisabledButton(false);
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          message: 'Your mail signature updated successfully',
          status: 'success',
        });
      })
      .catch((error) => {
        setDisabledButton(false);
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          message: error?.response?.data?.error || 'Something went wrong ',
          status: 'error',
        });
      });
  };

  const setDefaultTemplate = () => {
    setEditorValue(
      userData?.defaultSignatureTemplate
        ? userData?.defaultSignatureTemplate
        : editorValue,
    );
  };

  return (
    <div className={classes.mainFormValidator}>
      <div className={classes.formWrapper}>
        <h6 className={classes.formHeading}> My Email Signature </h6>

        <div
          className={`${classes.textWrapper} ${classes.textSignatureWrapper}`}
        >
          <div className={'value'}>{userData.username}</div>
          <div className={'link'} onClick={setDefaultTemplate}>
            {' '}
            Refresh Signature{' '}
          </div>
        </div>

        <Grid container className={classes.signatureEditorWrapper}>
          <Grid item xs={12} sm={12}>
            <FormGroup>
              <TextEditor
                onChange={handleEditorChange}
                initialvalue={editorValue}
              />
            </FormGroup>
            {!editorValue && (
              <span className={classes.errorMessage}>
                Please enter signature
              </span>
            )}
          </Grid>
        </Grid>
      </div>
      <div className={classes.formFooter}>
        <div>
          <Button
            variant="contained"
            className={classes.nextButton}
            onClick={handleSubmitClick}
            disabled={disabledButton}
          >
            Update
          </Button>
        </div>
      </div>
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default Signature;
