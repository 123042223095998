import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSkills } from '../../../actions/admin/skills';
import api from '../../../utils/api';
import Alert from '@material-ui/lab/Alert';
import Spinner from '../../../layout/Spinner';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formHeading: {
    fontSize: 20,
    fontWeight: 500,
    paddingBottom: 10,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
  },
  formCheckbox: {
    '&.Mui-checked': {
      color: custom.colorCode.lightRedColor,
    },
    color: custom.colorCode.lightRedColor,
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
}));

/**
 * Component for Skills form
 * @param {*} props
 * @returns
 */
const AddSkillForm = (props) => {
  const { skillId } = props;
  const classes = useStyles();
  //Local state variables
  const [formData, setFormData] = useState({});
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [openErrorSnackBarMsg, setOpenErrorSnackBarMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const dispatch = useDispatch();
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    // did mount
    return () => {
      isMounted.current = false
    };
  }, []);

  /**
   * Did mount fuction to get skill details for edit popup
   */
  useEffect(() => {
    if (skillId) {
      setIsLoading(true);
      api
        .get(`/automation/skills/${skillId}`)
        .then((res) => {
          setIsLoading(false);
          setFormData({
            name: res.data.name,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          setOpenErrorSnackBar(true);
          setOpenErrorSnackBarMsg(
            error.response?.data?.error || 'Something went wrong',
          );
        });
    }
  }, [skillId]);

  /**
   * Handle function of create/update skills form
   * It call server API to save data into tables
   */
  const handleSubmit = () => {
    if (!disabledNextButton()) {
      setDisabledButton(true);
      // if skill id exist then update API will call otherwise add API will call
      let values = formData;
      if (skillId) {
        api
          .put(`/automation/skills/${skillId}`, { values })
          .then((res) => {
            if (res.status === 200) {
              props?.closeDrawer({ skillUpdated: true });
            }
            dispatch(fetchSkills());
            if(isMounted.current) {
              return
            }
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
          })
          .catch((error) => {
            setOpenErrorSnackBar(true);
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
          });
      } else {
        api
          .post('/automation/skills', { values })
          .then((res) => {
            if (res.status === 201) {
              props?.closeDrawer({ skillAdded: true });
            }
            dispatch(fetchSkills());
            if(isMounted.current) {
              return
            }
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
          })
          .catch((error) => {
            setOpenErrorSnackBar(true);
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
          });
      }
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['name'];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * function to handle the form element change event
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to close toaster after server side error
   */
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackBar(false);
  };

  // show loader when data are not loaded
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  /**
   * Return function to reder UI elements
   */
  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> Skill Details </h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*skill Name*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Skill Name *"
                  onChange={handleChange}
                  name="name"
                  value={formData?.name || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter skill name']}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledNextButton()}
            >
              {skillId ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </ValidatorForm>
      <Snackbar
        open={openErrorSnackBar}
        autoHideDuration={2000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleErrorSnackbarClose}
          severity="error"
        >
          {openErrorSnackBarMsg || ''}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddSkillForm;
