import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Popper, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

/**
 * Main basic or default complaint for the application for the auto complete
 * @param props
 * @returns {*}
 * @constructor
 */
const AutoCompleteSelect = (props, ref) => {
  const { renderInput, ...rest } = props;
  return (
    <Autocomplete {...rest} ref={ref} renderInput={(params) => renderInput(params)} />
  );
};

/**
 * render option with info icon
 */
export const AutoCompleteRenderInfoOption = ({ title, helperText }) => {
  return (
    <Box width={1} display="flex" justifyContent="space-between">
      <Typography noWrap>{title}</Typography>
      <Tooltip title={helperText} placement="bottom">
        <InfoIcon style={{ fill: '#a3a3a3' }} />
      </Tooltip>
    </Box>
  );
};

/**
 * Popover component of the auto select cna be use to make autocomplete customize
 * @param props
 * @returns {*}
 * @constructor
 */
export const AutoCompletePopper = (props) => {
  return (
    <Popper
      {...props}
      style={{ width: 'fit-content' }}
      placement="bottom-start"
    />
  );
};

export default React.forwardRef(AutoCompleteSelect);
