import {
  SET_SIDE_BAR,
  SET_PROFILE_ALERT,
  SET_APP_CURRENT_HEADER_PAGE,
} from '../actions/types';

const initialState = {
  sideBarIsOpen: true,
  currentPageHeader: {},
  showProfileAlert: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SIDE_BAR:
      return {
        ...state,
        sideBarIsOpen: !state.sideBarIsOpen,
      };
    case SET_PROFILE_ALERT:
      return {
        ...state,
        showProfileAlert: !state.showProfileAlert,
      };
    case SET_APP_CURRENT_HEADER_PAGE:
      return {
        ...state,
        currentPageHeader: payload,
      };
    default:
      return state;
  }
}
