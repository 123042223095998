import React, { useState } from 'react';

import CustomDrawer from '../../components/CustomDrawer';
import DrawerFormHeader from '../../components/DrawerFormHeader';
import CustomSnackBar from '../../components/CustomSnackBar';
import CreateTemplateForm from './CreateTemplateForm';

import { english } from '../../utils/language';

/**
 * Component to create the CV Spec template
 * @param {*} props
 * @returns
 */
const CreateTemplate = (props) => {
  const { openDrawer, onClose, templateId } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  const [ownerOfTemplate, setOwnerOfTemplate] = useState(true);
  const admin = props.admin ? props.admin : false;

  /**
   * Handle function to close the drawer popup
   * @param {*} data
   */
  const handleClose = (data, closeDrawer = true) => {
    if (data && data === 'addTemplate') {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: english.createCvSpecTemplateSuccessMsg,
        status: 'success',
      });
    }
    if (data && data === 'editTemplate') {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: english.editCvSpecTemplateSuccessMsg,
        status: 'success',
      });
    }
    if (data && data === 'cloneTemplate') {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: english.cloneCvSpecTemplateSuccessMsg,
        status: 'success',
      });
    }

    if (data && data === 'error') {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: english.duplicateTemplateMsg,
        status: 'error',
      });
    }

    if (closeDrawer) {
      setOwnerOfTemplate(true);
      onClose(data);
    }
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  return (
    <div>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={
            templateId
              ? props.cloneTemplate
                ? english.cvSpecTemplateCloneFormHeading
                : !admin && ownerOfTemplate
                ? english.cvSpecTemplateViewFormHeading
                : english.cvSpecTemplateEditFormHeading
              : english.cvSpecTemplateAddFormHeading
          }
        >
          <CreateTemplateForm
            openDrawer={openSnackBar}
            closeDrawer={handleClose}
            cloneTemplate={props.cloneTemplate}
            templateId={templateId}
            admin={props.admin ? props.admin : false}
            page={props.page}
            getRecords={props.getRecords}
            toSelect={props.toSelect}
            setOwnerOfTemplate={setOwnerOfTemplate}
            ownerOfTemplate={ownerOfTemplate}
            masterId={props.masterId ? props.masterId : null}
          />
        </DrawerFormHeader>
      </CustomDrawer>

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default CreateTemplate;
