import React, { useState } from 'react';

import CustomDrawer from '../../../components/CustomDrawer';
import DrawerFormHeader from '../../../components/DrawerFormHeader';
import ContactEditForm from './ContactEditForm';
import ProfileEditForm from './ProfileEditForm';
import CustomSnackBar from '../../../components/CustomSnackBar';

const ProfileEditDrawer = (props) => {
  const {
    openDrawer,
    onClose,
    drawerType,
    profileType,
    profileId,
    profileDropDownData,
    profileDetail,
  } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);

  /**
   * Handle function to close the drawer popup
   * @param {*} data
   */
  const handleClose = (data) => {
    if (data && data?.data) {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: data.message,
        status: data.status,
      });
    }
    onClose(data);
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  /**
   * this function will return different form base on type
   */
  const getEditForm = () => {
    if (!drawerType?.name) {
      return <></>;
    }
    let component = '';
    switch (drawerType?.name) {
      case 'contact': {
        component = (
          <ContactEditForm
            closeDrawer={handleClose}
            profileType={profileType}
            profileId={profileId}
          />
        );
        break;
      }
      case 'profile': {
        component = (
          <ProfileEditForm
            profileDropDownData={profileDropDownData}
            profileType={profileType}
            profileId={profileId}
            closeDrawer={handleClose}
            profileDetail={profileDetail}
          />
        );
        break;
      }
      default: {
        break;
      }
    }
    return component;
  };

  /**
   * drawer title
   */
  const getDrawerTitle = () => {
    if (!drawerType?.name) {
      return '';
    }
    let title = '';
    switch (drawerType?.name) {
      case 'contact': {
        title = 'Edit Contact Detail';
        break;
      }
      case 'profile': {
        title = 'Edit Profile Detail';
        break;
      }
      default: {
        break;
      }
    }
    return title;
  };

  return (
    <div>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={getDrawerTitle()}
        >
          {getEditForm()}
        </DrawerFormHeader>
      </CustomDrawer>
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        handleSnackbarClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default ProfileEditDrawer;
