import React from 'react';
import { Grow, InputBase } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const defaultStyles = ({
  shape,
  palette,
  spacing,
  breakpoints,
  transitions,
  custom,
}) => ({
  main: {
    display: 'flex',
  },
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: palette.error.main,
    },
  },
  searchBox: {
    marginTop: 10,
    marginBottom: 10,
    position: 'relative',
    marginRight: spacing(2),
    marginLeft: 0,
    width: '100%',
    [breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    zIndex: 1,
    padding: '0 10px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fill: custom.colorCode.lightGreyColorShade3,
    },
  },
  inputRoot: {
    color: 'inherit',
    height: '100%',
    border: '1px solid #8ba4be',
    borderRadius: shape.borderRadius,
    backgroundColor: custom.colorCode.lightGreyColorShade4,
    '&:hover': {
      backgroundColor: custom.colorCode.lightGreyColorShade4,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  inputInput: {
    padding: spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${spacing(4)}px)`,
    transition: transitions.create('width'),
    width: '100%',
    [breakpoints.up('md')]: {
      width: '20ch',
    },
  },
});

class _DebounceTableSearch extends React.Component {
  state = {
    searchValue: null,
  };

  handleTextChangeWrapper = (event) => {
    this.setState({
      searchValue: event.target.value,
    });
    this.debouncedSearch(event.target.value);
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { searchText } = this.props;
    if (searchText && searchText != prevProps.searchText) {
      this.setState({
        searchValue: searchText,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  };

  debouncedSearch = debounce((value) => {
    this.props.onSearch(value);
  }, this.props.debounceWait);

  render() {
    const { classes, options, onHide, searchText, debounceWait } = this.props;
    const { searchValue } = this.state;

    // const inputClasses = newUseStyles();
    return (
      <Grow appear in={true} timeout={300}>
        <div className={classes.main}>
          <div className={`${classes.searchBox}`}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              autoFocus={false}
              variant={'outlined'}
              // defaultValue={options.searchText}
              value={searchValue || ''}
              onChange={(event) => this.handleTextChangeWrapper(event)}
              fullWidth={true}
              inputRef={(el) => (this.searchField = el)}
              placeholder={options.searchPlaceholder}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              {...(options.searchProps ? options.searchProps : {})}
            />
          </div>
        </div>
      </Grow>
    );
  }
}

const DebounceTableSearch = withStyles(defaultStyles, {
  name: 'MUIDataTableSearch',
})(_DebounceTableSearch);
export { DebounceTableSearch };

export function debounceSearchRender(debounceWait = 300) {
  return (searchText, handleSearch, hideSearch, options) => {
    return (
      <DebounceTableSearch
        searchText={searchText}
        onSearch={handleSearch}
        onHide={hideSearch}
        options={options}
        debounceWait={debounceWait}
      />
    );
  };
}
