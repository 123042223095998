import { cloneDeep } from 'lodash'
import {
  LOADING_ADMIN_INTEGRATION,
  LOAD_ADMIN_INTEGRATION,
  ADMIN_INTEGRATION_ERROR,
  LOADING_ADMIN_INTEGRATION_DETAIL,
  LOAD_ADMIN_INTEGRATION_DETAIL,
  ADMIN_INTEGRATION_DETAIL_ERROR,
  SAVE_ADMIN_INTEGRATION,
  LOAD_ADMIN_INTEGRATION_DETAIL_TABS,
} from '../../actions/types';

const initialState = {

  integrationList: [],
  getListLoading: true,
  error: {},
  setDetailListLoading: true,
  integrationDetail: {
    integrationTab: {
      tabs: [],
      loading: true,
    },
    count: 0,
  },
};

const updatePluginStatus = (plugins, params) => {
  // Encapsulate the idea of passing a new object as the first parameter
  //Find index of specific object using findIndex method.
  const objIndex = plugins.findIndex((obj) => obj.id === params.id);
  //Update object's status property.
  plugins[objIndex].status = params.status;
  return plugins;
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_ADMIN_INTEGRATION: {
      const data = {
        ...state,
        ...payload,
      };
      return data;
    }
    case LOAD_ADMIN_INTEGRATION:
      return {
        ...state,
        ...payload,
      };
    case ADMIN_INTEGRATION_ERROR:
      return {
        ...state,
        error: payload,
        getListLoading: false,
      };

    case SAVE_ADMIN_INTEGRATION:
      return {
        ...state,
        integrationList: updatePluginStatus(state.integrationList, payload),
      };

    case LOADING_ADMIN_INTEGRATION_DETAIL: {
      return {
        ...state,
        ...payload,
      };
    }
    case LOAD_ADMIN_INTEGRATION_DETAIL:
      return {
        ...state,
        integrationDetail: {
          ...state.integrationDetail,
          [payload.id]: {
            ...state[payload.id],
            ...payload[payload.id],
          },
        },
        setDetailListLoading: false,
      };
    case ADMIN_INTEGRATION_DETAIL_ERROR:
      return {
        ...state,
        error: payload,
        setDetailListLoading: false,
      };
    case LOAD_ADMIN_INTEGRATION_DETAIL_TABS:
      return {
        ...state,
        integrationDetail: {
          ...state.integrationDetail,
          integrationTab: { ...payload },
        },
      };
    default:
      return state;
  }
}
