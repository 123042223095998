import React from 'react';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import { Box } from '@material-ui/core';

import useStyles from './style';

// render the tabs components
export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

// main page tabs like page heading
const Tabs = (props) => {
  const {
    className = '',
    tabsOptions = [],
    tabIndex = 0,
    onTabChange,
    tabLabel = 'title',
    tabKey = 'name',
    byValue = false,
    centered = false,
    showCount = false,
    count = 0,
    ...rest
  } = props;

  const classes = useStyles();

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const handleTabChange = (event, newValue) => {
    onTabChange(event, newValue);
  };

  return (
    <MuiTabs
      value={tabIndex}
      onChange={handleTabChange}
      className={`${className} ${classes.contentMainTabs}`}
      indicatorColor="primary"
      textColor="primary"
      centered={Boolean(centered)}
      {...rest}
    >
      {tabsOptions.map((tabOp, index) =>
        byValue ? (
          <MuiTab
            key={tabOp[tabKey] + index}
            value={tabOp[tabKey]}
            label={tabOp[tabLabel]}
            {...tabOp}
            // label={showCount ? `${tabOp[tabLabel]} (${count})`: tabOp[tabLabel]}
            {...a11yProps(index)}
          />
        ) : (
          <MuiTab
            key={tabOp[tabKey] + index}
            label={tabOp[tabLabel]}
            {...tabOp}
            // label={showCount ? `${tabOp[tabLabel]} (${count})`: tabOp[tabLabel]}
            {...a11yProps(index)}
          />
        ),
      )}
    </MuiTabs>
  );
};

export default Tabs;
