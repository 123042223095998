import React, { useState } from 'react';
import { DateRangePicker as MUIDateRangePicker } from 'materialui-daterange-picker';
import { Popover } from '@material-ui/core';
import moment from 'moment';

export const defaultDefinedRanges = [
  {
    label: 'Yesterday',
    startDate: moment().subtract(1, 'days').toDate(),
    endDate: moment().subtract(1, 'days').toDate(),
  },
  {
    label: 'Last Week',
    startDate: moment().subtract(1, 'weeks').startOf('week').toDate(),
    endDate: moment().subtract(1, 'weeks').endOf('week').toDate(),
  },
  {
    label: 'Late 7 days',
    startDate: moment().subtract(7, 'd').toDate(),
    endDate: moment().toDate(),
  },
  {
    label: 'Last 30 days',
    startDate: moment().subtract(30, 'd').toDate(),
    endDate: moment().toDate(),
  },
  {
    label: 'Last 90 days',
    startDate: moment().subtract(90, 'd').toDate(),
    endDate: moment().toDate(),
  },
  {
    label: 'Last 6 months',
    startDate: moment().subtract(6, 'M').toDate(),
    endDate: moment().toDate(),
  },
  {
    label: 'Last 1 year',
    startDate: moment().subtract(1, 'Y').toDate(),
    endDate: moment().toDate(),
  },
];

const DateRangePicker = ({
  onClose,
  open,
  anchorEl,
  onDatePickerChange,
  dateRange,
  ...otherProps
}) => {
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
    >
      <MUIDateRangePicker
        open
        toggle={onClose}
        initialDateRange={dateRange}
        definedRanges={defaultDefinedRanges}
        // onChange={onDatePickerChange}
        onChange={(range) => onDatePickerChange(range)}
        {...otherProps}
      />
    </Popover>
  );
};

export default DateRangePicker;
