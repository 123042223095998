import {
  SET_SIDE_BAR,
  SET_APP_CURRENT_HEADER_PAGE,
  SET_PROFILE_ALERT,
} from './types';

export const openCloseSideBar = () => (dispatch) => {
  dispatch({
    type: SET_SIDE_BAR,
  });
};

export const openCloseProfileAlert = () => (dispatch) => {
  dispatch({
    type: SET_PROFILE_ALERT,
  });
};

export const setCurrentHeader = (data) => (dispatch) => {
  dispatch({
    type: SET_APP_CURRENT_HEADER_PAGE,
    payload: data,
  });
};
