import {
  LOAD_USER_LIST,
  SET_USER_LIST_FILTER_DATA,
  LOAD_USER_LIST_TABS,
  USERS_LIST_ERROR,
  SET_USER_LIST_CURRENT_TAB,
  SHOW_LOAD_USER_LIST_LOADER,
  LOAD_USER_LIST_LOADING,
} from '../../actions/types';

const initialState = {
  user_id: null,
  loading: true,
  userTab: {
    tabs: [],
    loading: true,
  },
  currentTab: {},
  count: 0,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LOAD_USER_LIST_LOADER: {
      return {
        ...state,
        [payload.tabData.id]: {
          ...state[payload.tabData.id],
          loading: true,
        },
      };
    }

    case LOAD_USER_LIST_LOADING: {
      return {
        ...state,
        userTab: {
          ...state.userTab,
          loading: payload,
        },
      };
    }

    case LOAD_USER_LIST: {
      const id = Object.keys(payload)[0];
      return {
        ...state,
        [id]: {
          ...state[id],
          ...payload[id],
        },
        count: payload[id]?.count || 0,
      };
    }

    case LOAD_USER_LIST_TABS: {
      const tabsData = {};
      payload.tabs.forEach((e) => {
        tabsData[e.id] = {
          usersList: [],
          myFilterData: [],
          filterList: {},
          filterState: {},
          defaultFilterState: {},
          count: 0,
          loading: true,
        };
      });

      return {
        ...state,
        userTab: {
          ...state.userTab,
          ...payload,
          loading: false,
        },
        ...tabsData,
      };
    }

    case SET_USER_LIST_FILTER_DATA: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload[payload.id],
        },
      };
    }

    case SET_USER_LIST_CURRENT_TAB: {
      return {
        ...state,
        ...payload,
      };
    }

    case USERS_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
