import api from '../utils/api';
import { setAlert } from './alert';
import { setTenantHash } from '../utils/setAuthToken';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_ERROR_CLEAR,
  REPLACEMENT_VARIABLES
} from './types';

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get('/user');
    /* localStorage.setItem('tenantHash', res.data.tenantHash);
    res.data?.tenantHash && setTenantHash(res.data?.tenantHash || ''); 
    */
    dispatch(getReplacementVariables());
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    localStorage.setItem('user', JSON.stringify(res.data));
  } catch (err) {
    // setTenantHash();
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (formData) => {
  return async (dispatch) => {
    try {
      const res = await api.post('/users', formData);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };
};

// Login User
export const login = (username, password, setDisableLoginBtn) => async (
  dispatch,
) => {
  const body = { username, password };

  try {
    // const res = await api.post('/login', body);
    const res = await api.post('/desktoplogin', body);
    localStorage.setItem('tenantHash', res.data.tenantHash);
    res.data?.tenantHash && setTenantHash(res.data?.tenantHash || '');
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    const error = err.response.data.error;
    setDisableLoginBtn(false);
    setTenantHash();
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    } else if (error) {
      return dispatch({
        type: LOGIN_FAIL,
        payload: error,
      });
    }

    dispatch({
      type: LOGIN_FAIL,
      payload: '',
    });
  }
};

// Login error clear
export const loginErrorClear = () => (dispatch) => {
  dispatch({
    type: LOGIN_ERROR_CLEAR,
    payload: '',
  });
};

// Logout
export const logout = () => ({ type: LOGOUT });

export const getReplacementVariables = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/replacement-variables');
    /* localStorage.setItem('tenantHash', res.data.tenantHash);
    res.data?.tenantHash && setTenantHash(res.data?.tenantHash || ''); 
    */
    dispatch({
      type: REPLACEMENT_VARIABLES,
      payload: res.data,
    });
  } catch (err) {
  }
};
