import React, { useEffect, useRef } from 'react';
//import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import placeholders from './placeholder.json';
import emailPlaceholder from './emailPlaceholder';

const TextEditor = (props) => {
  const { unsubscribe = false } = props;
  const handleEditorChange = (editor) => props.onChange(editor);

  const {
    // toolBar = 'undo redo | fontsizeselect forecolor | formatselect | bold italic | placeholder | forecolor backcolor |  \
    //             alignleft aligncenter alignright alignjustify | link | \
    //             bullist numlist outdent indent | code | removeformat',
    toolBar = 'bold italic underline | placeholder | fontselect fontsizeselect forecolor | \
                alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | \
                link image code  | backcolor removeformat',
  } = props;

  return (
    <Editor
      // apiKey="p3bbxaw1zwxgllubxiq63uw548qeox43uvwfw66hsqfj7wtt"
      apiKey="vdbk3iqyzwhv4doses848y1k2n5x8dxldis46h9hht5qqe97"
      className="custom_input"
      value={props.initialvalue}
      onBlur={props?.handleBlur}
      init={{
        height: 300,
        menubar: false,
        statusbar: false,
        //inline:true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code',
        ],
        selector: "textarea",
        toolbar: toolBar || '',
        setup: function (editor) {
          editor.ui.registry.addSplitButton('placeholder', {
            text: 'Personalize',
            //icon: 'info',
            tooltip: 'It is a personalize content variable',
            onAction: function () {
              //editor.insertContent('<p>You clicked the main button</p>');
            },
            onItemAction: function (api, value) {
              editor.insertContent(value);
            },
            fetch: function (callback) {
              callback(unsubscribe ? emailPlaceholder : placeholders);
            },
          });

          editor.ui.registry.addButton('unsubscribe', {
            text:
              // eslint-disable-next-line max-len
              '<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M20.99 14.04V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h10.05c.28 1.92 2.1 3.35 4.18 2.93 1.34-.27 2.43-1.37 2.7-2.71.25-1.24-.16-2.39-.94-3.18zm-2-9.04L12 8.5 5 5h13.99zm-3.64 10H5V7l7 3.5L19 7v6.05c-.16-.02-.33-.05-.5-.05-1.39 0-2.59.82-3.15 2zm5.15 2h-4v-1h4v1z"/></svg>',
            tooltip: 'Unsubscribe',
            onAction: function () {
              const selectedContent = editor.selection.getContent();
              editor.insertContent(
                `&nbsp;<a style="text-decoration: unset;" href='##unsubscribe##' target='_blank'>${
                  selectedContent || 'unsubscribe'
                }</a>&nbsp;`,
              );
            },
          });
        },
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default TextEditor;
