import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const useStyles = makeStyles(({ custom }) => ({
  activeIcon: {
    color: custom.colorCode.lightLightGreenColor3,
  },
  inActiveIcon: {
    color: custom.colorCode.lightGreyColor,
  },
}));

// check icon for active status
export const ActiveIcon = ({ className = '', ...restProps }) => {
  const classes = useStyles();
  return (
    <CheckCircleIcon
      className={`${className} ${classes.activeIcon}`}
      {...restProps}
    />
  );
};

// cross icon for inactive status
export const InActiveIcon = ({ className = '', ...restProps }) => {
  const classes = useStyles();
  return (
    <CancelOutlinedIcon
      className={`${className} ${classes.inActiveIcon}`}
      {...restProps}
    />
  );
};
