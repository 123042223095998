const roles = {
  superAdmin: 'superAdmin',
  admin: 1,
  finance: 2,
  rpo: 3,
  marketingConsultant: 4,
  director: 5,
  manager: 6,
};

const userRoles = {
  admins: [roles.superAdmin, roles.admin],
  users: [
    roles.finance,
    roles.director,
    roles.marketingConsultant,
    roles.rpo,
    roles.manager,
  ],
  all: [
    roles.superAdmin,
    roles.admin,
    roles.finance,
    roles.director,
    roles.marketingConsultant,
    roles.rpo,
    roles.manager,
  ],
};

export default userRoles;
