import {
  GET_TASKS,
  GET_TASK,
  ADD_TASK,
  ADD_COLUMN,
  TASK_ERROR,
  DELETE_COLUMN,
  DELETE_TASK,
  UPDATE_STATE,
} from '../actions/types';

const initialState = {
  lanes: [],
  task: [],
  column: [],
  loading: true,
  error: {},
};

const updateObject = (oldObject, newValues) => {
  // Encapsulate the idea of passing a new object as the first parameter
  // to Object.assign to ensure we correctly copy data instead of mutating
  return Object.assign({}, oldObject, newValues);
};

/* const addToTask = (state, payload) => {
  const lanes = state.lanes.map((data) => {
    if (data.column_id == payload.column_id) {
      let card = payload;
      card.laneId = payload.column_id;
      card.label = '0 second';
      data.cards.push(card);
    }
    return data;
  });

  return updateObject(state, { lanes: lanes, task: payload, loading: false });
}; */

const removeTask = (state, action) => {
  const lanes = state.lanes.map((lane) => ({
    ...lane,
    cards: lane.cards.filter((card) => card.id != action.cardId),
  }));

  return updateObject(state, { lanes: lanes, loading: false });
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TASKS: {
      const data = {
        ...state,
        lanes: payload,
        loading: false,
      };
      return data;
    }
    case GET_TASK: {
      const data = {
        ...state,
        task: payload,
        loading: false,
      };
      return data;
    }

    case DELETE_COLUMN:
      return {
        ...state,
        lanes: state.lanes.filter((lane) => {
          return lane.id != action.laneId;
        }),
        loading: false,
      };
    case DELETE_TASK:
      /* return {
            ...state,
            lanes: state.lanes.filter((lane) => {
                    return (lane.id != action.laneId)
                }),
            loading: false
            }; */
      return removeTask(state, action);
    case ADD_TASK:
      return {
        ...state,
        task: payload,
        loading: false,
      };
    //return addToTask(state, payload);
    case ADD_COLUMN:
      return {
        ...state,
        lanes: state.lanes.concat(payload),
        //column: payload,
        loading: false,
      };
    case UPDATE_STATE:
      return {
        ...state,
        task: [],
        loading: false,
      };
    case TASK_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        //lanes: null
      };
    default:
      return state;
  }
}
