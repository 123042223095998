import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import LoadingTableBody from '../../components/LoadingTableBody';
import CustomDataGrid from '../../controlled-component/customGridComponent';
import {
  fetchActivityReportData,
  fetchGridFilters,
} from '../../actions/admin/activityReport';
import CustomTableToolbar from '../sequencedCandidates/CustomTableToolbar';
import { makeProperNamingStatement } from '../../utils';

// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  reportsGridWrapper: {
    padding: '10px 0 10px 0',
  },
  gridCenter: {
    textAlign: 'center',
    paddingRight: '30px'
  }
}));

/**
 * Activity report grid component
 */
const ActivityReportGrid = (props) => {
  const classes = useStyles();
  const [facetdata, setFacetdata] = useState({});
  const [facetfields, setFacetfields] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);
  const filterStateRef = useRef({});
  const gridParams = useRef({});
  const gridColParams = useRef({});
  const filterStateParams = useRef({});
  const dispatch = useDispatch();
  const reports = useSelector(
    (state) => state.activityReports[props.tabProps.id]
  );
  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    gridParams.current = reports.gridParams;
  }, [reports.gridParams]);

  useEffect(() => {
    filterStateParams.current = reports.filterState;
  }, [reports.filterState]);

  useEffect(() => {
    if (!isEmpty(reports.columns)) {
      gridColParams.current = reports.columns;
    }
  }, [reports.columns]);

  useEffect(() => {
    // to update the report option
    if (reports.activityReportList.length && reports.myFilterData.length) {
      setFacetdata({});
      setFacetfields(
        reports.myFilterData.map((e) => ({
          label: e.name,
          value: e.title,
        }))
      );
    }
    filterStateRef.current = reports.filterState;
  }, [reports]);

  useEffect(() => {
    // did mount
    dispatch(fetchGridFilters(props.tabProps));
  }, []);

  useEffect(() => {
    if (!isEmpty(reports.filterList) && userData?.id) {
      // call the fetch activity report api and set data in reducer
      dispatch(
        fetchActivityReportData(
          undefined,
          reports.gridParams
            ? reports.gridParams
            : { pageNo: 0, perPage: 25, searchText: null },
          props.tabProps,
          undefined
        )
      );
    }
  }, [reports.filterList, userData]);

  /**
   * Default columns of table
   */
  const columns = useMemo(
    () => [
      {
        label: 'User Name',
        name: 'userName',
        options: {
          filter: true,
          sort: true,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = reports.activityReportList[dataIndex];
            return <div>{makeProperNamingStatement(rData.userName || '')}</div>;
          },
        },
      },
      {
        label: 'Task Completed',
        name: 'task_complete_count',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = reports.activityReportList[dataIndex];
            return <div className={classes.gridCenter}>{rData.task_complete_count}</div>;
          },
        },
      },

      {
        label: 'People added to sequence',
        name: 'people_sequence_count',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = reports.activityReportList[dataIndex];
            return <div className={classes.gridCenter}>{rData.people_sequence_count}</div>;
          },
        },
      },
      {
        label: 'Candidate added to sequence',
        name: 'candidate_sequence_count',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = reports.activityReportList[dataIndex];
            return <div className={classes.gridCenter}>{rData.candidate_sequence_count}</div>;
          },
        },
      },
      {
        label: 'Contacts added to sequence',
        name: 'contacts_sequence_count',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = reports.activityReportList[dataIndex];
            return <div className={classes.gridCenter}>{rData.contacts_sequence_count}</div>;
          },
        },
      },
      {
        label: 'Email Sent',
        name: 'email_sent_count',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = reports.activityReportList[dataIndex];
            return <div className={classes.gridCenter}>{rData.email_sent_count}</div>;
          },
        },
      },
      {
        label: 'Reply of Sequence',
        name: 'reply_count',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = reports.activityReportList[dataIndex];
            return <div className={classes.gridCenter}>{rData.reply_count}</div>;
          },
        },
      },

      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = reports.activityReportList.find(
              (task) => task.id === value
            );
            return null;
          },
        },
      },
    ],
    [reports.activityReportList, reports.myFilterData]
  );

  /**
   * Refetch the activity report data using filters options
   */
  const handleReFetch = (filterObject) => {
    dispatch(
      fetchActivityReportData(
        {
          ...(isEmpty(filterObject) ? {} : filterObject),
        },
        {
          ...gridParams.current,
          pageNo: 0,
          perPage: 25,
        },
        props.tabProps,
        gridColParams.current
      )
    );
  };

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={reports.loading} {...props} />,
    [reports.loading]
  );

  /**
   * Tool Bar for grid
   */
  const TableToolbar = useMemo(
    () => (props) => {
     return( <CustomTableToolbar
          refetch={handleReFetch}
          myFilterData={reports.myFilterData}
          defaultFilterState={reports.defaultFilterState}
          filterState={reports.filterState}
          visibleFilter={0}
          filterDrawerName={props.options?.filterPlaceholder}
          showEditCol
          onColumnChange={handleColumnChange()}
          loading={reports.loading}
          flist={reports.filterList}
          showMoreFilterOption={false}
          selectPlaceHolder={'Assigned To'}
          datePlaceHolder={'Assigned Date'}
          selectNameToSave={'assigned_to'}
          dateRangeNameToSave={'assignedDate'}
          selectDataOptions={reports?.filterList?.consultantList?.length ? reports.filterList?.consultantList : []}
          datePickerDataOptions={reports?.filterList?.assigned_date?.length ? reports.filterList?.assigned_date : []}
          userFilterSelectValue={reports?.filterState?.assigned_to ? reports?.filterState?.assigned_to : []}
          userFilterDateValue={reports?.filterState?.assignedDate ? reports?.filterState?.assignedDate : ''}
          {...props}
        />
      );
    },
    [reports.myFilterData, reports.defaultFilterState, reports.filterList]
  );


  const handlePerPageChange = (params) => {
    // dispatch(
    //   fetchSequenceCandidateData(
    //     {
    //       ...myTask.filterState,
    //       perPage: params.rowsPerPage,
    //     },
    //     undefined,
    //     props.tabProps,
    //   ),
    // );
  };


  const handleColumnChange = (showLoader) => (paramColumns) => {
    dispatch(
      fetchActivityReportData(
        {
          ...filterStateParams.current,
        },
        gridParams.current,
        props.tabProps,
        paramColumns,
        showLoader
      )
    );
  };

  const handleTableChange = (params) => {
    dispatch(
      fetchActivityReportData(
        {
          ...reports.filterState,
        },
        params,
        props.tabProps,
        gridColParams.current
      )
    );
  };

  return (
    <div className={classes.templateGridMainSection}>
      <div className={classes.reportsGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns.map((col, index) => ({
            ...col,
            options: reports?.columns?.columns?.length
              ? { ...col.options, ...reports?.columns?.columns[index] }
              : col.options,
          }))}
          data={reports.activityReportList}
          onTableRowPerPageChange={handlePerPageChange}
          onTableChange={handleTableChange}
          onColumnChange={handleColumnChange(false)}
          paperHeight={'295px'}
          options={{
            columnOrder: reports?.columns?.columnsOrder,
            pagination: Boolean(reports?.count),
            page: reports?.gridParams?.pageNo || 0,
            rowsPerPage: reports?.gridParams?.perPage || 25,
            searchText: reports?.gridParams?.searchText,
            selectableRows: 'multiple',
            serverSide: true,
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search User',
            filterOptions: facetfields,
            facetdata: facetdata,
            gridParams: gridParams,
            searchOpen: true,
            search: true,
            filter: true,
            download: true,
            viewColumns: true,
            count: reports?.count,
            selectToolbarPlacement: 'replace',
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
              setRowsSelected(allRows.map((row) => row.dataIndex));
            },
            sortOrder: reports?.gridParams?.sortOrder
              ? reports?.gridParams?.sortOrder
              : undefined,
          }}
          components={{
            TableBody: BodyComponent,
            TableToolbar: TableToolbar,
          }}
        />
      </div>
    </div>
  );
};

export default ActivityReportGrid;
