import { combineReducers } from 'redux';
import app from './app';
import alert from './alert';
import auth from './auth';
import sequence from './sequence';
import template from './template';
import notification from './notification';
import task from './task';
import users from './users';
import lead from './lead';
import tabs from './tabs';
import teams from './admin/teams';
import entity from './admin/entity';
import adminOffices from './admin/offices';
import skill from './admin/skill';
import category from './admin/category';
import industry from './admin/industry';
import adminJobRoles from './admin/jobRoles';
import integration from './admin/integration';
import profileIntegration from './admin/profileIntegration';
import userGrid from './admin/usersGrid';
import myTask from './admin/myTask';
import facebook from './facebook';
import candidate from './candidate';
import sequencedCandidates from './admin/sequencedCandidates';
import activityReports from './admin/activityReports';

export default combineReducers({
  app,
  alert,
  auth,
  sequence,
  template,
  notification,
  task,
  users,
  lead,
  tabs,
  teams,
  entity,
  adminOffices,
  skill,
  adminJobRoles,
  category,
  industry,
  integration,
  facebook,
  candidate,
  myTask,
  sequencedCandidates,
  activityReports,
  profileIntegration,
  userGrid,
});
