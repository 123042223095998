import React from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import useStyles from './style';

const BreadCrumbs = (props) => {
  const {
    crumbData = [],
    itemCLick = () => {},
    className = '',
    lastChildClassName = '',
    ...otherProps
  } = props;

  const classes = useStyles();

  const handleClick = (event, data) => {
    itemCLick(event, data);
  };

  return (
    <MuiBreadcrumbs
      separator={
        <NavigateNextIcon
          className={classes.breadCrumbsSeparator}
          fontSize="small"
        />
      }
      aria-label="breadcrumb"
      className={`${classes.root} ${className}`}
      {...otherProps}
    >
      {crumbData.map((e, index) =>
        e.to ? (
          <MuiLink
            color="inherit"
            to={e.to}
            onClick={(event) => handleClick(event, e)}
            component={Link}
            key={e.to + index}
          >
            {e.title}
          </MuiLink>
        ) : (
          <Typography
            color="textPrimary"
            className={`${classes.breadCrumbLastChild} ${lastChildClassName}`}
            key={e.title + index}
          >
            {e.title}
          </Typography>
        ),
      )}
    </MuiBreadcrumbs>
  );
};

export default BreadCrumbs;
