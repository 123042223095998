import React from 'react';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import CustomToolbar from './CustomToolbar';
import CustomFooter from './CustomFooter';
import { colorCodeConstant } from '../../utils/styleConstant';
import { debounceSearchRender } from '../component/NewDebounceSearchRender';

import useStyles from './mainGrid.style';

/*
 * Reusable grid with custom options
 * @param {*} columns columns of the grid
 * @param {*} data as per the column
 * @param {*} options to add parent custom thing
 * @param {*} className
 * @param {*} hideToolBar it will remove toolbar for grid
 * @param {*} paperWidth width controller of the grid
 * @param {*} stickyCol make first or second col position sticky
 * @param {*} otherProps props related other setting
 * @returns
 */
const CustomGrid = ({
  columns = [],
  data = [],
  options = {},
  className = '',
  fetchData,
  hideToolBar = false,
  paperWidth = '290px',
  stickyCol = true,
  paperHeight = '280px',
  ...otherProps
}) => {
  const stickyTD = stickyCol ? 1 : 0;
  // const stickyTD =
  //   options.selectableRows === 'multiple' ? (stickyCol ? 2 : 1) : 1;
  // const stickyTD = 0;
  const sideBarIsOpen = useSelector((state) => state.app.sideBarIsOpen);
  // overriding of the main material css class
  const getMuiTheme = () => {
    return createMuiTheme({
      overrides: {
        MUIDataTable: {
          paper: {
            maxWidth: `calc(100vw - ${
              paperWidth.substr(0, paperWidth.length - 2) -
              (sideBarIsOpen ? 0 : 175)
            }px)`,
            transition: 'max-width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          },
          responsiveBase: {
            border: `1px solid ${colorCodeConstant.lightGreyColorShade1}`,
            maxHeight: `calc(100vh - ${paperHeight}) !important`,
          },
        },
        MuiTableRow: {
          hover: {
            '&:hover': {
              [`& td:nth-child(${stickyTD})`]: stickyCol
                ? {
                    background: colorCodeConstant.lightGreyColorShade2,
                  }
                : {},
            },
          },
          root: {},
        },
        MuiSelected: {},
        MuiTablePagination: {
          caption: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
          },
          actions: {
            minWidth: 100,
          },
        },
        MuiTableHead: {
          root: {
            [`& th:nth-child(${stickyTD})`]: stickyCol
              ? {
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  left: stickyTD === 1 ? 0 : 42,
                  background: colorCodeConstant.white,
                  zIndex: 101,
                }
              : {},
          },
        },
        MuiTableBody: {
          root: {
            [`& td:nth-child(${stickyTD})`]: stickyCol
              ? {
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  left: stickyTD === 1 ? 0 : 42,
                  background: colorCodeConstant.white,
                  zIndex: 100,
                }
              : {},
            '& .mui-row-selected': {
              [`& td:nth-child(${stickyTD})`]: stickyCol
                ? {
                    background: colorCodeConstant.lightGreyColorShade2,
                  }
                : {},
            },
            '& tr': {
              height: 50,
            },
          },
        },
        MUIDataTableBodyCell: {
          root: {},
        },
        MuiTableCell: {
          root: {
            fontFamily: "'Roboto', sans-serif",
          },
          paddingCheckbox: {
            backgroundColor: colorCodeConstant.white,
          },
        },
        MUIDataTableBody: {
          emptyTitle: {
            lineHeight: 3,
            fontSize: '14px !important',
          },
        },
        /* MuiToolbar: {
          root: hideToolBar ? { display: 'none' } : {},
        }, */
        MUIDataTableToolbar: {
          root: hideToolBar ? { display: 'none' } : {},
        },
        MUIDataTableToolbarSelect: {
          root: {
            backgroundColor: colorCodeConstant.transparent,
            boxShadow: 'none',
            borderRadius: 0,
            border: `none`,
            borderBottom: 'none',
            justifyContent: 'start',
            '& :nth-child(1)': {
              '& h6': {
                display: 'none',
              },
            },
          },
        },
        MUIDataTableFooter: {
          root: {
            borderLeft: `1px solid ${colorCodeConstant.lightGreyColorShade1}`,
            borderRight: `1px solid ${colorCodeConstant.lightGreyColorShade1}`,

          },
        },
        MUIDataTableSelectCell: {
          root: {
            width: 42,
            minWidth: 42,
            maxWidth: 42,
            backgroundColor: 'inherit',
          },
          fixedLeft: {
            backgroundColor: colorCodeConstant.white,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontWeight: 700,
          },
          data: {
            whiteSpace: 'pre',
            marginRight: 15,
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 700,
          },
          toolButton: {
            width: '100%',
            '&:focus': {
              outline: 'none',
            },
            '&:hover': {
              outline: 'none',
              backgroundColor: 'unset',
            },
          },
          sortAction: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            '&:focus': {
              outline: 'none',
              backgroundColor: colorCodeConstant.appBarBlue,
            },
          },
        },
      },
    });
  };

  const dispatch = useDispatch();

  const classes = useStyles();

  // default option passed to make grid use custom component
  const defaultOptions = {
    selectableRows: 'none',
    filterType: 'dropdown',
    responsive: 'stacked',
    filter: false,
    download: false,
    print: false,
    search: false,
    pagination: false,
    viewColumns: false,
    rowsPerPage: 25,
    customSearchRender: debounceSearchRender(400),
    onTableChange: (action, tableState) => {
      //console.log(action, tableState);
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case 'changePage':
        case 'changeRowsPerPage':
        case 'sort':
        case 'search':
          if (!options.serverSide) {
            return;
          }
          // eslint-disable-next-line no-case-declarations
          let sortOrder =
            Object.keys(tableState.sortOrder).length === 0
              ? null
              : tableState.sortOrder;
          // eslint-disable-next-line no-case-declarations
          const params = {
            pageNo: tableState.page,
            sortOrder: sortOrder,
            perPage: tableState.rowsPerPage,
            searchText: tableState.searchText,
          };
          fetchData && dispatch(fetchData(params));
          //this.changePage(tableState.page, tableState.sortOrder);
          otherProps?.onTableChange(params);
          break;
        /* case 'sort':
          //this.sort(tableState.page, tableState.sortOrder);
          break; */
        case 'viewColumnsChange':
        case 'columnOrderChange':
          // eslint-disable-next-line no-case-declarations
          const paramColumns = {
            columns: tableState.columns,
            columnsOrder: tableState.columnOrder,
          };
          // call dispatch and re call the api
          // setGridparams({
          //   ...gridparams,
          //   columns: tableState.columns,
          //   columnsOrder: tableState.columnOrder,
          // });
          otherProps?.onColumnChange &&
            otherProps?.onColumnChange(paramColumns);
          break;
        default:
        //console.log('action not handled.');
      }
    },
    draggableColumns: {
      enabled: true,
    },
    // default custom footer
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      if (!options.pagination) {
        return <></>;
      }
      // default custom footer component
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      );
    },
    // default custom toolbar component
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        data={data}
        columns={columns}
        className={`${classes.customMainGrid} ${className}`}
        options={{
          ...defaultOptions,
          ...options,
          selectableRows: 'none',
        }}
        {...otherProps}
      />
    </MuiThemeProvider>
  );
};

export default CustomGrid;
