import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SidebarNav from '../includes/NewSidebarNav';
import CusFilter from './CusFilter';
/* import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */

/* import Leadgrid from './leadgrid'; */
class Lead extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="">
        <div className="flex-row">
          <aside className="sideBarMargin">
            <SidebarNav lead="active" />
          </aside>

          <div className="flex-col content">
            {/* <Leadgrid  {...this.props}/> */}
            {/* <ToastContainer autoClose={2000} /> */}
            <CusFilter />
          </div>
        </div>
      </div>
    );
  }
}

Lead.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Lead);
