import {
  LOAD_SEQUENCED_CANDIDATES,
  SEQUENCED_CANDIDATES_ERROR,
  SET_SEQUENCED_CANDIDATES_FILTER_DATA,
  SHOW_LOAD_SEQUENCED_CANDIDATES_LOADER,
  LOAD_SEQUENCED_CANDIDATES_TABS,
  LOAD_SEQUENCED_CANDIDATES_TABS_LOADING,
  UPDATE_SEQUENCED_CANDIDATES_STATUS,
  SET_SEQUENCED_CANDIDATES_CURRENT_TAB,
} from '../../actions/types';

const initialState = {
  task_id: null,
  loading: true,
  taskTab: {
    tabs: [],
    loading: true,
  },
  currentTab: {},
  count: 0,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LOAD_SEQUENCED_CANDIDATES_LOADER: {
      return {
        ...state,
        [payload.tabData.id]: {
          ...state[payload.tabData.id],
          loading: true,
        },
        count: 0,
      };
    }

    case LOAD_SEQUENCED_CANDIDATES_TABS_LOADING: {
      return {
        ...state,
        taskTab: {
          ...state.taskTab,
          loading: payload,
        },
      };
    }

    case LOAD_SEQUENCED_CANDIDATES_TABS: {
      const tabsData = {};
      payload.tabs.forEach((e) => {
        tabsData[e.id] = {
          contactsList: [],
          myFilterData: [],
          filterList: {},
          filterState: {},
          defaultFilterState: {},
          count: 0,
          loading: true,
        };
      });

      return {
        ...state,
        taskTab: {
          ...state.taskTab,
          ...payload,
          loading: false,
        },
        ...tabsData,
      };
    }

    case LOAD_SEQUENCED_CANDIDATES: {
      const id = Object.keys(payload)[0];
      return {
        ...state,
        [id]: {
          ...state[id],
          ...payload[id],
        },
        count: payload[id]?.count || 0,
      };
    }

    case SET_SEQUENCED_CANDIDATES_CURRENT_TAB: {
      return {
        ...state,
        ...payload,
      };
    }

    case SET_SEQUENCED_CANDIDATES_FILTER_DATA: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload[payload.id],
        },
        count: 0,
      };
    }

    case UPDATE_SEQUENCED_CANDIDATES_STATUS: {
      const taskUpdateIndex = state[payload.tabProps.id].contactsList.findIndex(
        (task) => task.id === payload.task.sequence_contact_id
      );
      const newStatus =
        payload.task.action_type == 'start'
          ? 1
          : payload.task.action_type == 'complete'
          ? 3
          : 2;

      return {
        ...state,
        [payload.tabProps.id]: {
          ...state[payload.tabProps.id],
          contactsList: [
            ...state[payload.tabProps.id].contactsList.slice(0, taskUpdateIndex),
            {
              ...state[payload.tabProps.id].contactsList[taskUpdateIndex],
              status: newStatus,
            },
            ...state[payload.tabProps.id].contactsList.slice(taskUpdateIndex + 1),
          ],
        },
      };
    }

    case SEQUENCED_CANDIDATES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
