import React, { useState } from 'react';

import CustomDrawer from '../../components/CustomDrawer';
import DrawerFormHeader from '../../components/DrawerFormHeader';
import CustomSnackBar from '../../components/CustomSnackBar';
import CreateTemplateForm from './CreateTemplateForm';

import { english } from '../../utils/language';

const CreateTemplate = (props) => {
  const { openDrawer, onClose, templateId } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);

  /**
   * Handle function to close the drawer popup
   * @param {*} data
   */
  const handleClose = (data) => {
    if (data && data === 'addTemplate') {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: english.createLinkedinTemplateSuccessMsg,
        status: 'success',
      });
    }
    if (data && data === 'editTemplate') {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: english.editLinkedinTemplateSuccessMsg,
        status: 'success',
      });
    }
    onClose(data);
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  return (
    <div>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={
            templateId
              ? english.linkedinTemplateEditFormHeading
              : english.linkedinTemplateCreateFormHeading
          }
        >
          <CreateTemplateForm
            openDrawer={openSnackBar}
            closeDrawer={handleClose}
            templateId={templateId}
            admin={props.admin ? props.admin : false}
            page={props.page}
            getRecords={props.getRecords}
            toSelect={props.toSelect}
          />
        </DrawerFormHeader>
      </CustomDrawer>

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default CreateTemplate;
