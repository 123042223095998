import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  mainSectionHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    justifyContent: 'space-between',
  },
  pageMainHeadingWrapper: {
    '& .heading-title': {
      fontSize: 22,
      fontWeight: 700,
      textTransform: 'none',
      '& span': {
        fontWeight: 300,
      },
      '& .text-muted': {
        color: `${custom.colorCode.blueDarkShade1} !important`,
      },
    },
    '& .heading-sub-title': {
      fontWeight: '400',
      fontSize: 14,
      color: custom.colorCode.blueDarkShade1,
      marginBottom: 0,
    },
  },
}));

/*
 * Reusable page header
 * @param {*} mainTitle heading of page
 * @param {*} subTitle title of page
 * @param {*} itemCount record present on page
 * @param {*} className
 * @returns
 */
const PageHeader = (props) => {
  const {
    mainTitle = 'Heading',
    subTitle = 'Check your data',
    className = '',
    itemCount = 0,
    showCount = true,
    showRightHeading = false,
    rightHeadingContent = <></>,
  } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.mainSectionHeader} ${className}`}>
      <div className={classes.pageMainHeadingWrapper}>
        <div className="heading-title">
          {mainTitle}{' '}
          {showCount && <span>({itemCount})</span>}
        </div>
        {subTitle && <div className="heading-sub-title">{subTitle}</div>}
      </div>
      {showRightHeading && rightHeadingContent}
    </div>
  );
};

export default PageHeader;
