import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import TouchAppIcon from '@material-ui/icons/TouchApp';

import CollapseCard from '../../components/CollapseCard';
import { dateFormat } from '../../utils/applicationConstant';

const useStyles = makeStyles(({ custom }) => ({
  cardTextContent: {
    padding: '8px 0',
  },
  cardContent: {
    border: 'none',
    margin: 0,
  },
  userName: {
    fontWeight: 700,
  },
}));

const CardContentNoteDetail = (props) => {
  const classes = useStyles();

  return (
    <CollapseCard
      showIcon
      className={classes.cardContent}
      startIcon={<TouchAppIcon fontSize="small" />}
      heading1={props.sequence_name ? `(${props.sequence_name || ''})` : ''}
      heading2={props.note_type_name}
      subHeading={props.user_name}
      rightHeading={moment(props.created_date).format(dateFormat)}
    >
      <Box className={classes.cardTextContent}>
        <Typography
          variant="body1"
          gutterBottom
          style={{ color: '#979797' }}
          dangerouslySetInnerHTML={{ __html: props.note }}
        />
      </Box>
    </CollapseCard>
  );
};

export default CardContentNoteDetail;
