import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import IntegrationList from './IntegrationList';

const Integrations = (props) => {
  return <IntegrationList {...props} />;
};

Integrations.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Integrations);
