import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

const defaultToolbarStyles = makeStyles(() => ({
  iconButton: {},
}));

const CustomToolbar = () => {
  const classes = defaultToolbarStyles();
  return (
    <Tooltip title={'custom icon'}>
      <IconButton className={classes.iconButton}>
        <AddIcon className={classes.deleteIcon} />
      </IconButton>
    </Tooltip>
  );
};

export default CustomToolbar;
