import { GET_NOTIFICATIONS, NOTIFICATION_ERROR } from '../actions/types';

const initialState = {
  notifications: [],
  filters: [],
  domain: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
  case GET_NOTIFICATIONS: {
    const data = {
      ...state,
      notifications: payload.notificatonRecord,
      filters: payload.filters,
      domain: payload.domain,
      loading: false,
    };
    return data;
  }

  case NOTIFICATION_ERROR:
    return {
      ...state,
      error: payload,
      loading: false,
      notifications: null,
    };
  default:
    return state;
  }
}
