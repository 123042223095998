import React, { useEffect } from 'react';
import api from '../utils/api';
import { Col, Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateSequence } from '../actions/sequence';
import { fetchUsersTeams } from '../actions/users';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import MatButton from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import MuiAutocomplete from '../controlled-component/MuiAutocomplete';
import * as yup from 'yup';

//class CreateSequence extends React.Component {
const EditSequence = (props) => {
  const [teamToggle, setTeamToggle] = React.useState(false);
  const [userToggle, setUserToggle] = React.useState(false);
  const [userSelected, setUserSelected] = React.useState([]);
  const [teamSelected, setTeamSelected] = React.useState([]);
  const schema = yup.object().shape({
    name: yup.string().required('Sequence name is a required field'),
    //audience_type: yup.number().required('Sequence audience is a required field'),
    access_type: yup
      .number()
      .nullable()
      .required('Sequence access is a required field'),
    description: yup.string().required('Description is a required field'),
    shared_with_users: userToggle
      ? yup.array().required('Users are a required field')
      : '',
    shared_with_teams: teamToggle
      ? yup.array().required('Teams are a required field')
      : '',
  });
  /**Get users and teams data for options*/
  useEffect(() => {
    props.fetchUsersTeams();
  }, [props.fetchUsersTeams]);

  useEffect(() => {
    if (props.seq_id) {
      api.get(`/automation/sequence/get/${props.seq_id}`).then((response) => {
        //console.log(response.data);
        setValue('name', response.data.name);
        setValue('audience_type', response.data.audience_type);
        setValue('access_type', response.data.access_type);
        setValue('description', response.data.description);
        if (response.data.shared_with_users != null) {
          setUserSelected(response.data.shared_with_users);
        } else {
          setUserSelected([response.data.user]);
        }
        if (response.data.shared_with_teams != null) {
          setTeamSelected(response.data.shared_with_teams);
        } else {
          setTeamSelected([response.data.team]);
        }
        if (response.data.access_type == 1) {
          setUserToggle(true);
        } else if (response.data.access_type == 2) {
          setTeamToggle(true);
        }
      });
    }
  }, [props.seq_id]);

  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm({ mode: 'onSubmit', resolver: yupResolver(schema) });
  const [submittedData, setSubmittedData] = React.useState({});

  const onSubmit = (values) => {
    props.updateSequence(
      props.seq_id,
      values,
      props.history,
      closePopup,
      props.page,
    );
    //console.log(values);
  };

  const handleAccessType = (event) => {
    let selected = event.target.value;
    if (selected == 1) {
      setUserToggle(true);
      setTeamToggle(false);
    } else if (selected == 2) {
      setUserToggle(false);
      setTeamToggle(true);
    } else {
      setUserToggle(false);
      setTeamToggle(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      //console.log(submittedData);
      reset({ ...submittedData });
    }
  }, [isSubmitSuccessful, submittedData, reset]);

  //users options
  const usersData = props.users.users ? props.users.users : null;
  var usersOptions = usersData.map(function (user) {
    return { value: user.company_user_id, label: user.name };
  });

  //teams options
  const teamsData = props.users.teams ? props.users.teams : null;
  var teamsOptions = teamsData.map(function (team) {
    return { value: team.id, label: team.name };
  });

  const closePopup = () => {
    setUserSelected([]);
    setTeamSelected([]);
    setTeamToggle(false);
    setUserToggle(false);
    props.onHide();
  };

  //render() {
  return (
    <Modal
      show={props.modalState}
      onHide={() => closePopup()}
      dialogClassName="custom-modal"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="modalHeaderCls" closeButton={false}>
          <div>
            <Modal.Title className="modalTitle">Edit Sequence</Modal.Title>
            <span className="closeModalIconCls" onClick={() => closePopup()}>
              <i className="fa fa-close"></i>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label className="floating-label">Sequence Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                className="custom_input"
                ref={register}
              ></Form.Control>
              <label className="error">{errors.name?.message}</label>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="audience_type">
              <Form.Label>Sequence audience</Form.Label>
              <Form.Control
                as="select"
                name="audience_type"
                custom="true"
                disabled={true}
                ref={register}
              >
                <option value="1">DM</option>
                <option value="2">Candidate</option>
              </Form.Control>
              <label className="error">{errors.audience_type?.message}</label>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="description">
              <Form.Label className="floating-label">
                Sequence Description
              </Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                className="custom_input"
                rows="2"
                ref={register}
              ></Form.Control>
              <label className="error">{errors.description?.message}</label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="access_type">
              <Form.Label>Sequence access</Form.Label>
              <Form.Control
                as="select"
                name="access_type"
                custom="true"
                ref={register}
                onChange={handleAccessType}
              >
                <option value="">Select</option>
                <option value="1">Private</option>
                <option value="2">Team</option>
                <option value="3">Organisation</option>
              </Form.Control>
              <label className="error">{errors.access_type?.message}</label>
            </Form.Group>
          </Form.Row>
          {userToggle ? (
            <Form.Row>
              <Form.Group as={Col} controlId="access_type">
                <Form.Label>Select users</Form.Label>
                <MuiAutocomplete
                  control={control}
                  name="shared_with_users"
                  options={usersOptions}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  getOptionLabel={(option) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      margin="normal"
                      placeholder="Select users"
                    />
                  )}
                  defaultValue={userSelected}
                  multiple={true}
                />

                <label className="error">
                  {errors.shared_with_users?.message}
                </label>
              </Form.Group>
            </Form.Row>
          ) : null}
          {teamToggle ? (
            <Form.Row>
              <Form.Group as={Col} controlId="shared_with_teams">
                <Form.Label>Select teams</Form.Label>
                <MuiAutocomplete
                  control={control}
                  name="shared_with_teams"
                  options={teamsOptions}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  getOptionLabel={(option) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      margin="normal"
                      placeholder="Select teams"
                    />
                  )}
                  defaultValue={teamSelected}
                  multiple={true}
                />
                <label className="error">
                  {errors.shared_with_teams?.message}
                </label>
              </Form.Group>
            </Form.Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button varient="primary" className="pl-4 pr-4" type="submit">Next</Button> */}
          <MatButton
            variant="contained"
            color="primary"
            type="submit"
            /* className={classes.button} */ startIcon={<SaveIcon />}
          >
            Save
          </MatButton>
          {/* <Button varient="primary" className="pl-4 pr-4"
          onClick={() => this.props.onHide('addSequence')}>Next</Button> */}
        </Modal.Footer>
      </Form>
    </Modal>
  );
  //}
};

EditSequence.propTypes = {
  auth: PropTypes.object.isRequired,
  updateSequence: PropTypes.func.isRequired,
  fetchUsersTeams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sequence: state.sequence,
  users: state.users,
});
const mapDispatchToProps = {
  updateSequence,
  fetchUsersTeams,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSequence);
