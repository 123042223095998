import React, { Component, useState, useEffect } from 'react';
import { Col, Row, Modal, Form, Button, Nav } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
//import SidebarNav from '../includes/SidebarNav';
//import SequenceSteps from './SequenceSteps';
import SliderPopup from '../components/SliderPopup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  getSequenceData,
  deleteSequenceStep,
  updateSequenceStatus,
  createCVSpecStep,
  createEndSequence,
  createCallbackStep,
  addSequenceStep,
  updateEmailName,
} from '../actions/sequence';
import CreateEmail from './CreateEmail';
import CreateTask from './CreateTask';
import CreateSms from './CreateSms';
import CreateWaitTime from './CreateWaitTime';
import StepActions from './StepActions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import EditTemplate from '../templates/EditTemplate';
import { Tooltip } from '@material-ui/core';
import EmailStep from './EmailStep';
import StepTemplates from './SequenceDetail/StepTemplates/StepTemplates';
import SimpleSwitch from '../components/SimpleSwitch';
import ActionButtonMenu from './SequenceDetail/ActionButtonMenu';
import EditSequence from './EditSequence';
import CloneSequence from './CloneSequence';
import DrawerFormHeader from '../components/DrawerFormHeader';
import CustomDrawer from '../components/CustomDrawer';
import SButton from '../components/Button';
import Badge from '../components/Badge';
import AddFacebook from './AddFacebook';
import FacebookIcon from '@material-ui/icons/Facebook';
import SmsIcon from '@material-ui/icons/Sms';

// material ui design styles
const useStyles = makeStyles({
  selectSliderRoot: {
    zIndex: 1000,
  },
  selectSliderPaper: {
    width: 420,
    zIndex: 1000,
  },
  backButton: {
    height: 30,
    padding: '0 10px',
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: '#cfcfcf',
    color: '#acacac',
    outline: 'none',
    border: `solid 1px #cfcfcf`,
    boxShadow: 'unset',
    '&:hover': {
      border: `solid 1px #acacac`,
      outline: 'none',
      backgroundColor: '#cfcfcf',
    },
    '&:focus': {
      border: `solid 1px #acacac`,
      outline: 'none',
      backgroundColor: '#cfcfcf',
    },
  },
  backButtonIcon: {
    marginBottom: 2,
  },
  badge: {
    width: 'max-content',
    backgroundColor: '#e7eeff',
    height: 19,
    fontSize: 11,
    color: '#0655FF',
    fontWeight: 500,
  },
});

//Component for sequence steps page
const SequenceStep = ({
  getSequenceData,
  deleteSequenceStep,
  updateSequenceStatus,
  createCVSpecStep,
  createEndSequence,
  createCallbackStep,
  addSequenceStep,
  updateEmailName,
  sequence: { campaign },
  match,
}) => {
  const classes = useStyles();
  //Get the sequence and steps data and set in reducer
  useEffect(() => {
    getSequenceData(match.params.id);
  }, [getSequenceData, match.params.id]);

  const [stepcontent, setStepcontent] = useState({});
  const [totaldays, setTotaldays] = useState(0);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [toggleEmail, setToggleEmail] = useState(false);
  const [toggleJobSpec, setToggleJobSpec] = useState(false);
  const [toggleTime, setToggleTime] = useState(false);
  const [toggleTask, setToggleTask] = useState(false);
  const [toggleSms, setToggleSms] = useState(false);
  const [eventOrder, setEventOrder] = useState(false);
  const [emailEdit, setEmailEdit] = useState(false);
  const [toggleAddFacebook, setToggleAddFacebook] = useState(false);
  const [toggleRemoveFacebook, setToggleRemoveFacebook] = useState(false);
  const [open, setOpen] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [stepData, setStepData] = useState({});
  const [editPopupToggle, setEditPopupToggle] = useState(false); // edit pop up
  const [clonePopupToggle, setClonePopupToggle] = useState(false); // copy pop up

  /**
   * Function to toggle step options slider popup
   */
  const toggleSideDrawer = (event_order) => {
    if (event_order !== undefined) {
      setEventOrder(event_order);
    }
    setToggleOpen(!toggleOpen);
    setToggleEmail(false);
    setToggleJobSpec(false);
    setToggleTime(false);
    setToggleTask(false);
    setToggleSms(false);
    setStepcontent({});
    setToggleAddFacebook(false);
    setToggleRemoveFacebook(false);
  };

  /**
   * Function to toggle email step popup
   */
  const createEmailToggle = () => {
    setToggleEmail(!toggleEmail);
  };

  /**
   * Function to create Job Spec step
   */
  const createJobSpec = () => {
    //setToggleJobSpec(!toggleJobSpec)
    const values = {
      template_id: null,
      template_name: 'Job Spec Email',
      sequence_id: match.params.id,
      type: 7,
      event: 'specjob',
      event_order: eventOrder,
    };
    addSequenceStep(values, match.params.id, setToggleOpen);
  };

  /**
   * Function to toggle wait step popup
   */
  const createTimeToggle = () => {
    setToggleTime(!toggleTime);
  };

  /**
   * Function to toggle task step popup
   */
  const createTaskToggle = () => {
    setToggleTask(!toggleTask);
  };

  /**
   * Function to toggle SMS step popup
   */
  const createSmsToggle = () => {
    setToggleSms(!toggleSms);
  };

  /**
   * Function to create CV Spec step
   */
  const createCVSpec = () => {
    createCVSpecStep(match.params.id, setToggleOpen, eventOrder);
  };

  /**
   * Function to create Callback step
   */
  const createCallback = () => {
    createCallbackStep(match.params.id, setToggleOpen, eventOrder);
  };

  /**
   * Function to create End step
   */
  const createEndStep = () => {
    //setToggleTask(!toggleTask)
    createEndSequence(match.params.id, setToggleOpen, eventOrder);
    //setToggleOpen(false);
  };

  /**
   * Function to close the slider popup
   */
  const modalToggleAction = () => {
    setToggleEmail(false);
    setToggleJobSpec(false);
    setToggleTime(false);
    setToggleTask(false);
    setToggleSms(false);
    setToggleOpen(false);
    setToggleAddFacebook(false);
    setToggleRemoveFacebook(false);
  };

  /**
   * Delete steps
   * @param {*} eventId
   * @param {*} eventOrder
   */
  const deleteSteps = (eventId, eventOrder) => {
    deleteSequenceStep(eventId, match.params.id, eventOrder);
  };

  const createAddFacebookToggle = () => {
    setToggleAddFacebook(!toggleAddFacebook);
  };

  const createRemoveFacebookToggle = () => {
    setToggleRemoveFacebook(!toggleRemoveFacebook);
  };

  /**
   * Function to calculate wait time
   * @param {*} unit
   * @param {*} interval
   * @returns
   */
  const updateCounts = (unit, interval) => {
    switch (unit) {
      case 'day':
        return interval;
      case 'week':
        return interval * 7;
      case 'month':
        return interval * 30;
      case 'hour': {
        return interval ? interval / 8 : 0;
      }
      case 'minute': {
        return interval ? interval / 60 / 8 : 0;
      }
      default:
        return interval;
    }
  };

  //const eventsCount = campaign.events ? campaign.events.length : null;
  //Calculate steps and bussiness working days of sequence
  var countStep = 0;
  var countDays = 0;
  campaign.events &&
    campaign.events.map((ev, x) => {
      if (ev.event_type === 'wait') {
        let current_count = updateCounts(
          ev.trigger_interval_unit,
          ev.trigger_interval,
        );
        countDays = countDays + parseFloat(current_count);
      } else {
        countStep = countStep + 1;
      }
    });

  /**
   * Edit step popup
   * @param {*} type
   * @param {*} step
   */
  const editStep = (type, step) => {
    setToggleOpen(!toggleOpen);
    setStepcontent(step);
    if (type === 'wait') setToggleTime(!toggleTime);
    else if (type === 'email') setToggleEmail(!toggleEmail);
    else if (type === 'jobspec') setToggleJobSpec(!toggleJobSpec);
    else if (type === 'task') setToggleTask(!toggleTask);
    else if (type === 'SMS') setToggleSms(!toggleSms);
    else if (type === 'AddFacebook') createAddFacebookToggle();
    else if (type === 'RemoveFacebook') createRemoveFacebookToggle();
    else setToggleEmail(!toggleEmail);
  };

  /**
   * Function to handle email template popup
   * @param {*} step
   */
  const handleEditEmail = (step) => {
    setEmailEdit(!emailEdit);
    setStepcontent(step);
  };

  /**
   * function to handle the close popup
   */
  const hadleClosePopup = () => {
    setEmailEdit(!emailEdit);
    setStepcontent({});
  };

  /**
   * Update email template name in sequence
   * @param {*} name
   */
  const updateEmailsName = (name) => {
    //code to update
    updateEmailName(name, stepcontent);
  };

  /**
   * Update sequence status
   * @param {*} sequenceId
   * @param {*} statusText
   */
  const updateStatus = (sequenceId, statusText) => {
    const status = statusText === 'Un-Published' ? 1 : 2;
    //console.log(statusText);
    updateSequenceStatus(sequenceId, status, 'detail', null);
  };

  const handleTemplatesPoup = (rowData) => {
    setOpen(!open);
    setStepData(rowData);
    setTemplateList(rowData.templateList);
  };

  // handle the edit sequence pop up
  const handleEditPopupOpen = () => {
    setEditPopupToggle(!editPopupToggle);
  };

  // this function will manage publish and unpublish of step
  const handleSwitchChange = () => {
    updateStatus(campaign.id, `${campaign.is_published}`);
  };

  // this will open the clone pop up
  const handleCopySequence = () => {
    setClonePopupToggle(!clonePopupToggle);
  };

  let history = useHistory();

  /**
   * this will return object will contain header title and back click
   * */
  const sequenceSliderHeader = () => {
    let sliderDataEvent = {
      headerText: '',
      handleBackClick: () => {},
    };

    switch (true) {
      case toggleEmail: {
        sliderDataEvent = {
          ...sliderDataEvent,
          headerText: 'Choose Email Template',
          handleBackClick: createEmailToggle,
        };
        break;
      }
      case toggleTask: {
        sliderDataEvent = {
          ...sliderDataEvent,
          headerText: 'Create LinkedIn Message',
          handleBackClick: createTaskToggle,
        };
        break;
      }
      case toggleSms: {
        sliderDataEvent = {
          ...sliderDataEvent,
          headerText: 'Create SMS Message',
          handleBackClick: createSmsToggle,
        };
        break;
      }
      case toggleTime: {
        sliderDataEvent = {
          ...sliderDataEvent,
          headerText: 'Choose Wait Duration',
          handleBackClick: createTimeToggle,
        };
        break;
      }
      case toggleAddFacebook: {
        sliderDataEvent = {
          ...sliderDataEvent,
          headerText: 'Add to Facebook',
          handleBackClick: createAddFacebookToggle,
        };
        break;
      }
      case toggleRemoveFacebook: {
        sliderDataEvent = {
          ...sliderDataEvent,
          headerText: 'Remove from Facebook',
          handleBackClick: createRemoveFacebookToggle,
        };
        break;
      }
      default: {
        sliderDataEvent = {
          ...sliderDataEvent,
          headerText: 'Choose Step',
        };
        break;
      }
    }
    return sliderDataEvent;
  };

  const sliderHeaderDataEvent = sequenceSliderHeader();

  return (
    <div className="sequenceOverallCls">
      <div className="sequence_header_overall">
        <div className="headerContent">
          <Row>
            <div className="back-button">
              <SButton
                className={classes.backButton}
                variant="outlined"
                onClick={() => {
                  history.length > 1
                    ? history.goBack()
                    : history.push('/sequence');
                  // : history.push('/admin/sequence');
                }}
              >
                Back
              </SButton>
            </div>
            <div className="page-heading">
              <h2>{campaign.name}</h2>
            </div>
            <div className="right-content-wrapper">
              <div className="actionButton-switchWrapper">
                <SimpleSwitch
                  checked={campaign.is_published === 'Published'}
                  handleChange={handleSwitchChange}
                  className="sequence_header_switch"
                  leftLabelText={'Unpublish'}
                  rightLabelText={'Publish'}
                  name={'publish-un-publish'}
                />
                <ActionButtonMenu
                  seq_id={campaign.id}
                  buttonClassName="sequence_header_action_button"
                  handleEditPopupOpen={handleEditPopupOpen}
                  copySequence={handleCopySequence}
                  showEditStep={false}
                />
              </div>
              {/*<Button*/}
              {/*  varient="secondary"*/}
              {/*  className="pl-3 pr-3"*/}
              {/*  type="button"*/}
              {/*  onClick={() =>*/}
              {/*    updateStatus(campaign.id, `${campaign.is_published}`)*/}
              {/*  }*/}
              {/*>*/}
              {/*  {campaign.is_published}*/}
              {/*  /!* <i className="fa fa-chevron-right" style={{marginLeft: '4px'}}></i> *!/*/}
              {/*</Button>*/}
            </div>
          </Row>
        </div>
        <div className="contentSequenceCls pt-3">
          <div className="cardSequenceCls">
            <p className="pb-2" style={{ color: '#adb4ca' }}>
              {countStep} steps | {Number(countDays).toFixed(1)} business days
              to complete
            </p>
            <p>
              A contact will be unenrolled from this sequence in any of these
              cases
            </p>
            <div className="lineJointCls"></div>
          </div>
          <br />
          <div className="cardSequenceCls">
            <p>
              Get started by adding an email template or task to your sequence.
            </p>
            <div className="lineJointCls"></div>
          </div>
          <br />
          {campaign.events == false ? (
            <PlusButton toggleSideDrawer={toggleSideDrawer} />
          ) : (
            <PlusButtonInBetween toggleSideDrawer={toggleSideDrawer} />
          )}

          {campaign.events &&
            campaign.events.map((step, i) => {
              let keyval = i + 1;
              switch (step.event_type) {
                case 'email':
                  return (
                    <EmailCard
                      indexkey={keyval}
                      eventlength={campaign.events.length}
                      key={`step-${step.id}-${step.id + i}`}
                      step={step}
                      editstep={editStep}
                      delete={deleteSteps}
                      event_id={step.id}
                      title={step.name}
                      toggleSideDrawer={toggleSideDrawer}
                      handleTemplatesPoup={handleTemplatesPoup}
                    />
                  );
                case 'specjob':
                  return (
                    <JobspecCard
                      indexkey={keyval}
                      eventlength={campaign.events.length}
                      key={`step-${step.id}-${step.id + i}`}
                      step={step}
                      editstep={editStep}
                      delete={deleteSteps}
                      event_id={step.id}
                      title={step.name}
                      toggleSideDrawer={toggleSideDrawer}
                    />
                  );
                case 'cvspec':
                  return (
                    <CvspecCard
                      indexkey={keyval}
                      eventlength={campaign.events.length}
                      key={`step-${step.id}-${step.id + i}`}
                      step={step}
                      delete={deleteSteps}
                      event_id={step.id}
                      title={step.name}
                      toggleSideDrawer={toggleSideDrawer}
                    />
                  );
                case 'callback':
                  return (
                    <CallbackCard
                      indexkey={keyval}
                      eventlength={campaign.events.length}
                      key={`step-${step.id}-${step.id + i}`}
                      step={step}
                      delete={deleteSteps}
                      event_id={step.id}
                      title={step.name}
                      toggleSideDrawer={toggleSideDrawer}
                    />
                  );
                case 'linkedIn':
                  return (
                    <TaskCard
                      indexkey={keyval}
                      eventlength={campaign.events.length}
                      key={`step-${step.id}-${step.id + i}`}
                      step={step}
                      editstep={editStep}
                      delete={deleteSteps}
                      event_id={step.id}
                      title={step.name}
                      toggleSideDrawer={toggleSideDrawer}
                    />
                  );
                case 'SMS':
                  return (
                    <SmsCard
                      indexkey={keyval}
                      eventlength={campaign.events.length}
                      key={`step-${step.id}-${step.id + i}`}
                      step={step}
                      editstep={editStep}
                      delete={deleteSteps}
                      event_id={step.id}
                      title={step.name}
                      toggleSideDrawer={toggleSideDrawer}
                    />
                  );
                case 'AddFacebook':
                case 'RemoveFacebook':
                  return (
                    <AddFBCard
                      indexkey={keyval}
                      eventlength={campaign.events.length}
                      key={`step-${step.id}-${step.id + i}`}
                      step={step}
                      editstep={editStep}
                      delete={deleteSteps}
                      event_id={step.id}
                      title={step.name}
                      toggleSideDrawer={toggleSideDrawer}
                      provider={step.event_type}
                    />
                  );
                case 'wait':
                  return (
                    <WaitTime
                      indexkey={keyval}
                      eventlength={campaign.events.length}
                      key={`step-${step.id}-${step.id + i}`}
                      step={step}
                      editstep={editStep}
                      delete={deleteSteps}
                      event_id={step.id}
                      duration={`${step.trigger_interval} ${step.trigger_interval_unit}(s)`}
                      toggleSideDrawer={toggleSideDrawer}
                    />
                  );
                case 'endsquence':
                  return (
                    <EndSquenceCard
                      key={`step-${step.id}-${step.id + i}`}
                      step={step}
                      editstep={editStep}
                      delete={deleteSteps}
                      event_id={step.id}
                      toggleSideDrawer={toggleSideDrawer}
                    />
                  );
                default:
                  return null;
              }
            })}

          <ToastContainer autoClose={2000} />
        </div>
        <CustomDrawer
          style={{ zIndex: 1000 }}
          anchor={'right'}
          open={toggleOpen}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          onClose={toggleSideDrawer}
          classes={{
            root: classes.selectSliderRoot,
            paper: classes.selectSliderPaper,
          }}
        >
          <DrawerFormHeader
            handleCloseClick={toggleSideDrawer}
            titleText={sliderHeaderDataEvent.headerText}
            showBackButton={
              toggleEmail ||
              toggleTask ||
              toggleSms ||
              toggleTime ||
              toggleAddFacebook ||
              toggleRemoveFacebook
            }
            backClick={() => sliderHeaderDataEvent.handleBackClick()}
          >
            {toggleTime ? (
              <CreateWaitTime
                closeModal={modalToggleAction}
                sid={match.params.id}
                stepcontent={stepcontent}
                eventorder={eventOrder}
              />
            ) : null}
            {toggleEmail ? (
              <CreateEmail
                closeModal={modalToggleAction}
                sid={match.params.id}
                stepcontent={stepcontent}
                eventorder={eventOrder}
                template_type={1}
              />
            ) : null}
            {/* {(toggleJobSpec) ? <CreateEmail closeModal={modalToggleAction}
              sid={match.params.id} stepcontent={stepcontent} eventorder={eventOrder}
              title={'job spec'} template_type={3} step_type={7} step_event={'jobspec'} />  : null} */}
            {toggleTask ? (
              <CreateTask
                closeModal={modalToggleAction}
                sid={match.params.id}
                stepcontent={stepcontent}
                eventorder={eventOrder}
              />
            ) : null}
            {toggleSms ? (
              <CreateSms
                closeModal={modalToggleAction}
                sid={match.params.id}
                stepcontent={stepcontent}
                eventorder={eventOrder}
              />
            ) : null}
            {toggleAddFacebook ? (
              <AddFacebook
                closeModal={modalToggleAction}
                sid={match.params.id}
                stepcontent={stepcontent}
                eventorder={eventOrder}
                provider="add"
              />
            ) : null}
            {toggleRemoveFacebook ? (
              <AddFacebook
                closeModal={modalToggleAction}
                sid={match.params.id}
                stepcontent={stepcontent}
                eventorder={eventOrder}
                provider="remove"
              />
            ) : null}

            {!toggleEmail &&
            !toggleJobSpec &&
            !toggleTime &&
            !toggleTask &&
            !toggleSms &&
            !toggleAddFacebook &&
            !toggleRemoveFacebook ? (
              <StepActions
                createTaskToggle={createTaskToggle}
                createSmsToggle={createSmsToggle}
                createEmailToggle={createEmailToggle}
                createTimeToggle={createTimeToggle}
                createCVSpec={createCVSpec}
                createEndStep={createEndStep}
                createCallback={createCallback}
                createJobSpec={createJobSpec}
                type={campaign.audience_type}
                createAddFacebookToggle={createAddFacebookToggle}
                createRemoveFacebookToggle={createRemoveFacebookToggle}
              />
            ) : null}
          </DrawerFormHeader>
        </CustomDrawer>

        {/*<SliderPopup show={toggleOpen}>*/}
        {/*  <Form>*/}

        {/*    <Modal.Header className="sequence_modal_header" closeButton={false}>*/}
        {/*      {toggleEmail ? (*/}
        {/*        <div>*/}
        {/*          <Modal.Title>*/}
        {/*            <h2 className="modalTitle">*/}
        {/*              <i*/}
        {/*                className="fa fa-chevron-left"*/}
        {/*                style={{ marginRight: '30px' }}*/}
        {/*                onClick={() => createEmailToggle()}*/}
        {/*              ></i>*/}
        {/*              Choose Email Template*/}
        {/*            </h2>*/}
        {/*          </Modal.Title>*/}
        {/*          <span*/}
        {/*            className="closeModalIconCls"*/}
        {/*            style={{ top: '22px' }}*/}
        {/*            onClick={toggleSideDrawer}*/}
        {/*          >*/}
        {/*            <i className="fa fa-close"></i>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      ) : null}*/}
        {/*      /!* {(toggleJobSpec) ?*/}
        {/*                    <div>*/}
        {/*                        <Modal.Title>*/}
        {/*                        <h2 className="modalTitle">*/}
        {/*                        <i className="fa fa-chevron-left" style={{marginRight: '30px'}}*/}
        {/*                        onClick={() => createJobSpec()}></i>*/}
        {/*                        Choose Job Spec Template</h2></Modal.Title>*/}
        {/*                        <span className="closeModalIconCls"*/}
        {/*                        style={{top: "22px"}} onClick={toggleSideDrawer}>*/}
        {/*                        <i className="fa fa-close"></i></span>*/}
        {/*                    </div> :*/}
        {/*                    null} *!/*/}
        {/*      {toggleTask ? (*/}
        {/*        <div>*/}
        {/*          <Modal.Title>*/}
        {/*            <h2 className="modalTitle">*/}
        {/*              <i*/}
        {/*                className="fa fa-chevron-left"*/}
        {/*                style={{ marginRight: '30px' }}*/}
        {/*                onClick={() => createTaskToggle()}*/}
        {/*              ></i>*/}
        {/*              Create LinkedIn Message*/}
        {/*            </h2>*/}
        {/*          </Modal.Title>*/}
        {/*          <span*/}
        {/*            className="closeModalIconCls"*/}
        {/*            style={{ top: '22px' }}*/}
        {/*            onClick={toggleSideDrawer}*/}
        {/*          >*/}
        {/*            <i className="fa fa-close"></i>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      ) : null}*/}
        {/*      {toggleTime ? (*/}
        {/*        <div>*/}
        {/*          <Modal.Title>*/}
        {/*            <h2 className="modalTitle">*/}
        {/*              <i*/}
        {/*                className="fa fa-chevron-left"*/}
        {/*                style={{ marginRight: '30px' }}*/}
        {/*                onClick={() => createTimeToggle()}*/}
        {/*              ></i>*/}
        {/*              Choose Wait Duration*/}
        {/*            </h2>*/}
        {/*          </Modal.Title>*/}
        {/*          <span*/}
        {/*            className="closeModalIconCls"*/}
        {/*            style={{ top: '22px' }}*/}
        {/*            onClick={toggleSideDrawer}*/}
        {/*          >*/}
        {/*            <i className="fa fa-close"></i>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      ) : null}*/}
        {/*      {!toggleEmail && !toggleJobSpec && !toggleTime && !toggleTask ? (*/}
        {/*        <div>*/}
        {/*          <Modal.Title>*/}
        {/*            <h2 className="modalTitle">Choose Step</h2>*/}
        {/*          </Modal.Title>*/}
        {/*          <span*/}
        {/*            className="closeModalIconCls"*/}
        {/*            onClick={toggleSideDrawer}*/}
        {/*          >*/}
        {/*            <i className="fa fa-close"></i>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      ) : null}*/}
        {/*    </Modal.Header>*/}

        {/*    <Modal.Body style={{ padding: 0 }}>*/}
        {/*      {toggleTime ? (*/}
        {/*        <CreateWaitTime*/}
        {/*          closeModal={modalToggleAction}*/}
        {/*          sid={match.params.id}*/}
        {/*          stepcontent={stepcontent}*/}
        {/*          eventorder={eventOrder}*/}
        {/*        />*/}
        {/*      ) : null}*/}
        {/*      {toggleEmail ? (*/}
        {/*        <CreateEmail*/}
        {/*          closeModal={modalToggleAction}*/}
        {/*          sid={match.params.id}*/}
        {/*          stepcontent={stepcontent}*/}
        {/*          eventorder={eventOrder}*/}
        {/*          template_type={1}*/}
        {/*        />*/}
        {/*      ) : null}*/}
        {/*      /!* {(toggleJobSpec) ? <CreateEmail closeModal={modalToggleAction}*/}
        {/*      sid={match.params.id} stepcontent={stepcontent} eventorder={eventOrder}*/}
        {/*      title={'job spec'} template_type={3} step_type={7} step_event={'jobspec'} />  : null} *!/*/}
        {/*      {toggleTask ? (*/}
        {/*        <CreateTask*/}
        {/*          closeModal={modalToggleAction}*/}
        {/*          sid={match.params.id}*/}
        {/*          stepcontent={stepcontent}*/}
        {/*          eventorder={eventOrder}*/}
        {/*        />*/}
        {/*      ) : null}*/}

        {/*      {!toggleEmail && !toggleJobSpec && !toggleTime && !toggleTask ? (*/}
        {/*        <StepActions*/}
        {/*          createTaskToggle={createTaskToggle}*/}
        {/*          createEmailToggle={createEmailToggle}*/}
        {/*          createTimeToggle={createTimeToggle}*/}
        {/*          createCVSpec={createCVSpec}*/}
        {/*          createEndStep={createEndStep}*/}
        {/*          createCallback={createCallback}*/}
        {/*          createJobSpec={createJobSpec}*/}
        {/*          type={campaign.audience_type}*/}
        {/*        />*/}
        {/*      ) : null}*/}
        {/*    </Modal.Body>*/}

        {/*    <Modal.Footer></Modal.Footer>*/}

        {/*  </Form>*/}
        {/*</SliderPopup>*/}
        <EditTemplate
          step
          temp_id={stepcontent?.template_id}
          modalState={emailEdit}
          onHide={hadleClosePopup}
          /* getRecords={updateEmailName} */
          updateName={updateEmailsName}
        />
        <StepTemplates
          step={stepData}
          templates={templateList}
          open={open}
          setOpen={setOpen}
          sequenceId={match.params.id}
          sequenceName={campaign?.name}
        />
      </div>
      {/* edit sequence pop up */}
      {editPopupToggle && (
        <EditSequence
          seq_id={campaign.id}
          sname={campaign?.name}
          modalState={editPopupToggle}
          onHide={handleEditPopupOpen}
          history={history}
        />
      )}
      {/* clone or copy sequence pop up*/}
      {clonePopupToggle && (
        <CloneSequence
          seq_id={campaign.id}
          sname={campaign?.name}
          modalState={clonePopupToggle}
          onHide={handleCopySequence}
          history={history}
        />
      )}
    </div>
  );
};

/**
 * component to render plus button
 */
const PlusButton = (props) => {
  return (
    <>
      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(0)}
        >
          +
        </Button>
        {/* <div className="lineBtnJointCls"></div> */}
      </div>
      <br />
    </>
  );
};

/**
 * component to render plus button between two steps
 */
const PlusButtonInBetween = (props) => {
  return (
    <>
      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(0)}
        >
          +
        </Button>
        <div className="lineBtnJointCls"></div>
      </div>
      <br />
    </>
  );
};

/**
 * component to render wait time step
 */
const WaitTime = (props) => {
  let isLast = props.eventlength - props.indexkey;
  return (
    <>
      <div className="cardSequenceCls tooltipCard">
        <div className="tooltipActionCls">
          <Tooltip title={'Update Step'} placement={`top-start`} arrow>
            <span onClick={() => props.editstep('wait', props.step)}>
              <i className="fa fa-file editTooltipIcon"></i>
            </span>
          </Tooltip>
          <Tooltip title={'Delete Step'} placement={`top-start`} arrow>
            <span
              onClick={() =>
                props.delete(props.event_id, props.step.event_order)
              }
            >
              <i className="fa fa-trash deleteTooltipIcon"></i>
            </span>
          </Tooltip>
        </div>
        <p>
          <i className="fa fa-clock-o mr-2 iconSize" aria-hidden="true"></i>Wait
          for <span>{props.duration}</span> &nbsp;
          {props.step.queued ? <Queued queued={props.step.queued} /> : null}
        </p>
        <div className="lineJointCls"></div>
      </div>
      <br />

      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(props.step.event_order)}
        >
          +
        </Button>
        {isLast ? <div className="lineBtnJointCls"></div> : null}
      </div>
      <br />
    </>
  );
};

/**
 * component to render Queued counts
 */
const Queued = (props) => {
  return <span className="queued-number">Queue ({props.queued})</span>;
};

/**
 * component to render email step
 */
const EmailCard = (props) => {
  const { step } = props;
  let isLast = props.eventlength - props.indexkey;
  const classes = useStyles();

  return (
    <>
      <div className="cardSequenceCls emailTemplateCls tooltipCard">
        <div className="tooltipActionCls">
          {/* <Tooltip title={'Edit Email'} placement={`top-start`} arrow>
            <span onClick={() => props.editEmail(props.step)}>
              <SystemUpdateAltIcon
                style={{
                  position: 'relative',
                  color: 'black',
                  fontSize: '15px',
                  fontWeight: 400,
                }}
              />
            </span>
          </Tooltip> */}
          <Tooltip title={'Update Step'} placement={`top-start`} arrow>
            <span onClick={() => props.editstep('email', props.step)}>
              <i className="fa fa-file editTooltipIcon"></i>
            </span>
          </Tooltip>
          <Tooltip title={'Delete Step'} placement={`top-start`} arrow>
            <span
              onClick={() =>
                props.delete(props.event_id, props.step.event_order)
              }
            >
              <i className="fa fa-trash deleteTooltipIcon"></i>
            </span>
          </Tooltip>
        </div>
        <p>
          <Badge
            countContent={step?.templateList?.length || 0}
            max={999}
            onClick={() => props.handleTemplatesPoup(props.step)}
          >
            <i className="fa fa-envelope envelopeIconCls" aria-hidden="true" />
          </Badge>{' '}
          <span className="ml-3">Send an email "{props.title}"</span>
        </p>
        <div className="lineJointCls"></div>
      </div>
      <br />

      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(props.step.event_order)}
        >
          +
        </Button>
        {isLast ? <div className="lineBtnJointCls"></div> : null}
      </div>
      <br />
    </>
  );
};

/**
 * component to render CV SPEC step
 */
const CvspecCard = (props) => {
  let isLast = props.eventlength - props.indexkey;
  return (
    <>
      <div
        className="cardSequenceCls tooltipCard"
        style={{ background: '#5780ab', color: '#fff' }}
      >
        <div className="tooltipActionCls">
          <Tooltip title={'Delete Step'} placement={`top-start`} arrow>
            <span
              onClick={() =>
                props.delete(props.event_id, props.step.event_order)
              }
            >
              <i className="fa fa-trash deleteTooltipIcon"></i>
            </span>
          </Tooltip>
        </div>
        <p>
          <i
            className="fa fa-file-text mr-2"
            style={{ color: '#fff', fontSize: '20px' }}
            aria-hidden="true"
          ></i>{' '}
          Send CV Spec
        </p>
        <div className="lineJointCls"></div>
      </div>
      <br />

      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(props.step.event_order)}
        >
          +
        </Button>
        {isLast ? <div className="lineBtnJointCls"></div> : null}
      </div>
      <br />
    </>
  );
};

/**
 * component to render Job Spec step
 */
const JobspecCard = (props) => {
  let isLast = props.eventlength - props.indexkey;
  return (
    <>
      <div
        className="cardSequenceCls tooltipCard"
        style={{ background: '#5780ab', color: '#fff' }}
      >
        <div className="tooltipActionCls">
          {/* <span onClick={() => props.editstep('jobspec', props.step)}>
          <i className="fa fa-file editTooltipIcon"></i>
          </span> */}
          <Tooltip title={'Delete Step'} placement={`top-start`} arrow>
            <span
              onClick={() =>
                props.delete(props.event_id, props.step.event_order)
              }
            >
              <i className="fa fa-trash deleteTooltipIcon"></i>
            </span>
          </Tooltip>
        </div>
        <p>
          <i
            className="fa fa-file-text mr-2"
            style={{ color: '#fff', fontSize: '20px' }}
            aria-hidden="true"
          ></i>{' '}
          Send a spec job email
        </p>
        <div className="lineJointCls"></div>
      </div>
      <br />

      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(props.step.event_order)}
        >
          +
        </Button>
        {isLast ? <div className="lineBtnJointCls"></div> : null}
      </div>
      <br />
    </>
  );
};

/**
 * component to render Callback step
 */
const CallbackCard = (props) => {
  let isLast = props.eventlength - props.indexkey;
  return (
    <>
      <div
        className="cardSequenceCls tooltipCard"
        style={{ background: '#5780ab', color: '#fff' }}
      >
        <div className="tooltipActionCls">
          <Tooltip title={'Update Step'} placement={`top-start`} arrow>
            <span
              onClick={() =>
                props.delete(props.event_id, props.step.event_order)
              }
            >
              <i className="fa fa-trash deleteTooltipIcon"></i>
            </span>
          </Tooltip>
        </div>
        <p>
          {/* <i className="fa fa-headphones mr-2" style={{color: "#fff", fontSize: '20px'}} aria-hidden="true"></i> */}
          <PhoneCallbackIcon
            className="mr-2"
            style={{ color: '#fff' }}
            aria-hidden="true"
          />
          Create Callback task
        </p>
        <div className="lineJointCls"></div>
      </div>
      <br />

      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(props.step.event_order)}
        >
          +
        </Button>
        {isLast ? <div className="lineBtnJointCls"></div> : null}
      </div>
      <br />
    </>
  );
};

/**
 * component to render End step
 */
const EndSquenceCard = (props) => {
  return (
    <>
      <div
        className="cardSequenceCls"
        style={{ background: 'RGB(215, 67, 56)', color: '#fff' }}
      >
        {/* <div className="tooltipActionCls">
                    <span onClick={() => props.delete(props.event_id, props.step.event_order)}>
                    <i className="fa fa-trash deleteTooltipIcon"></i>
                    </span>
                </div> */}
        <p>
          <i
            className="fa fa-lock mr-2"
            style={{ color: '#fff', fontSize: '20px' }}
            aria-hidden="true"
          ></i>{' '}
          End this sequence
        </p>
        {/* <div className="lineJointCls"></div> */}
      </div>
      <br />
    </>
  );
};

/**
 * component to render LinkedIn task step
 */
const TaskCard = (props) => {
  let isLast = props.eventlength - props.indexkey;
  return (
    <>
      <div
        className="cardSequenceCls tooltipCard"
        style={{ background: '#6c757d', color: '#fff' }}
      >
        <div className="tooltipActionCls">
          <Tooltip title={'Update Step'} placement={`top-start`} arrow>
            <span onClick={() => props.editstep('task', props.step)}>
              <i className="fa fa-file editTooltipIcon"></i>
            </span>
          </Tooltip>
          <Tooltip title={'Delete Step'} placement={`top-start`} arrow>
            <span
              onClick={() =>
                props.delete(props.event_id, props.step.event_order)
              }
            >
              <i className="fa fa-trash deleteTooltipIcon"></i>
            </span>
          </Tooltip>
        </div>
        <p>
          {/* <i className="fa fa-linkedin mr-2" style={{color: "#fff", fontSize: '20px'}} aria-hidden="true"></i>  */}
          <LinkedInIcon
            className="mr-2"
            style={{ color: '#fff' }}
            aria-hidden="true"
          />
          Create task "{props.title}"
        </p>
        <div className="lineJointCls"></div>
      </div>
      <br />

      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(props.step.event_order)}
        >
          +
        </Button>
        {isLast ? <div className="lineBtnJointCls"></div> : null}
      </div>
      <br />
    </>
  );
};

/**
 * component to render SMS step
 */
const SmsCard = (props) => {
  let isLast = props.eventlength - props.indexkey;
  return (
    <>
      <div
        className="cardSequenceCls tooltipCard"
        style={{ background: 'rgb(102 144 169)', color: '#fff' }}
      >
        <div className="tooltipActionCls">
          <Tooltip title={'Update Step'} placement={`top-start`} arrow>
            <span onClick={() => props.editstep('SMS', props.step)}>
              <i className="fa fa-file editTooltipIcon"></i>
            </span>
          </Tooltip>
          <Tooltip title={'Delete Step'} placement={`top-start`} arrow>
            <span
              onClick={() =>
                props.delete(props.event_id, props.step.event_order)
              }
            >
              <i className="fa fa-trash deleteTooltipIcon"></i>
            </span>
          </Tooltip>
        </div>
        <p>
          <SmsIcon
            className="mr-2"
            style={{ color: '#fff' }}
            aria-hidden="true"
          />
          Send a {props.title}
        </p>
        <div className="lineJointCls"></div>
      </div>
      <br />

      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(props.step.event_order)}
        >
          +
        </Button>
        {isLast ? <div className="lineBtnJointCls"></div> : null}
      </div>
      <br />
    </>
  );
};

/**
 * component to render Add Facebook step
 */
const AddFBCard = (props) => {
  let isLast = props.eventlength - props.indexkey;
  return (
    <>
      <div
        className="cardSequenceCls tooltipCard"
        style={{ background: '#6c757d', color: '#fff' }}
      >
        <div className="tooltipActionCls">
          <Tooltip title={'Update Step'} placement={`top-start`} arrow>
            <span onClick={() => props.editstep(props.provider, props.step)}>
              <i className="fa fa-file editTooltipIcon"></i>
            </span>
          </Tooltip>
          <Tooltip title={'Delete Step'} placement={`top-start`} arrow>
            <span
              onClick={() =>
                props.delete(props.event_id, props.step.event_order)
              }
            >
              <i className="fa fa-trash deleteTooltipIcon"></i>
            </span>
          </Tooltip>
        </div>
        <p>
          {/* <i className="fa fa-linkedin mr-2" style={{color: "#fff", fontSize: '20px'}} aria-hidden="true"></i>  */}
          <FacebookIcon
            className="mr-2"
            style={{ color: '#fff' }}
            aria-hidden="true"
          />
          {props.provider == 'AddFacebook'
            ? 'Add to Facebook'
            : 'Remove from Facebook'}{' '}
          "{props.title}"
        </p>
        <div className="lineJointCls"></div>
      </div>
      <br />

      <div className="btnCreateTemplate">
        <Button
          className="addResourcesCls"
          onClick={() => props.toggleSideDrawer(props.step.event_order)}
        >
          +
        </Button>
        {isLast ? <div className="lineBtnJointCls"></div> : null}
      </div>
      <br />
    </>
  );
};

SequenceStep.propTypes = {
  auth: PropTypes.object.isRequired,
  getSequenceData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sequence: state.sequence,
});

const mapDispatchToProps = {
  getSequenceData,
  deleteSequenceStep,
  updateSequenceStatus,
  createCVSpecStep,
  createEndSequence,
  createCallbackStep,
  addSequenceStep,
  updateEmailName,
};

export default connect(mapStateToProps, mapDispatchToProps)(SequenceStep);
