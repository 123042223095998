import api from '../../utils/api';
import { toast } from 'react-toastify';

import { LOAD_TEAMS, TEAM_ERROR, ADD_TEAM, CHANGE_TEAM_STATUS } from '../types';

// Get Users and team list for dropdown/autocomplete
export const fetchTeams = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/teams');

    dispatch({
      type: LOAD_TEAMS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEAM_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update template status
export const updateStatus = (teamId, status, showSnacker) => async (
  dispatch,
) => {
  try {
    const values = {
      status: status,
    };
    await api
      .put(`/automation/teams/update-status/${teamId}`, values)
      .then((res) => {
        dispatch({
          type: CHANGE_TEAM_STATUS,
          payload: res.data,
        });
        //onHide('addSequence');
        dispatch(fetchTeams());
        showSnacker();
      });
  } catch (err) {
    dispatch({
      type: TEAM_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
