import React from 'react';
import { Link } from 'react-router-dom';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import SmsIcon from '@material-ui/icons/Sms';

const StepActions = (props) => {
  const handleLinkClick = (event) => {
    event.preventDefault();
  };

  return (
    <div className="listSection">
      <div
        className="automation-action"
        onClick={() => props.createTimeToggle()}
      >
        <div className="col action-icon">
          <i className="fa fa-clock-o" aria-hidden="true"></i>
        </div>
        <div className="col action-item">
          <h3>
            <Link to="#" onClick={handleLinkClick}>
              Wait Time
            </Link>
          </h3>
          <p className="text-muted">Wait for a certain period of time.</p>
        </div>
      </div>

      <div
        className="automation-action"
        onClick={() => props.createEmailToggle()}
      >
        <div className="col action-icon">
          <i className="fa fa-paper-plane"></i>
        </div>
        <div className="col action-item">
          <h3>
            <Link to="#" onClick={handleLinkClick}>
              Email
            </Link>
          </h3>
          <p className="text-muted">Send an email automatically.</p>
        </div>
      </div>

      {props.type === 'DM' && (
        <div className="automation-action" onClick={() => props.createCVSpec()}>
          <div className="col action-icon">
            <i className="fa fa-file-text"></i>
          </div>
          <div className="col action-item">
            <h3>
              <Link to="#" onClick={handleLinkClick}>
                CV Spec
              </Link>
            </h3>
            <p className="text-muted">Send CV Spec automatically.</p>
          </div>
        </div>
      )}

      {props.type === 'Candidate' && (
        <div
          className="automation-action"
          onClick={() => props.createJobSpec()}
        >
          <div className="col action-icon">
            <i className="fa fa-file-text"></i>
          </div>
          <div className="col action-item">
            <h3>
              <Link to="#" onClick={handleLinkClick}>
                Spec Job
              </Link>
            </h3>
            <p className="text-muted">Send Spec Job automatically.</p>
          </div>
        </div>
      )}

      <div
        className="automation-action"
        onClick={() => props.createTaskToggle()}
      >
        <div className="col action-icon">
          {/* <i className="fa fa-linkedin"></i> */}
          <LinkedInIcon />
        </div>
        <div className="col action-item">
          <h3>
            <Link to="#" onClick={handleLinkClick}>
              LinkedIn Message
            </Link>
          </h3>
          <p className="text-muted">Create a task to send a LinkedIn message</p>
        </div>
      </div>
      <div className="automation-action" onClick={() => props.createCallback()}>
        <div className="col action-icon">
          {/* <i className="fa fa-headphones"></i> */}
          <PhoneCallbackIcon />
        </div>
        <div className="col action-item">
          <h3>
            <Link to="#" onClick={handleLinkClick}>
              Callback
            </Link>
          </h3>
          <p className="text-muted">Create a task to callback.</p>
        </div>
      </div>
      {/* Twilio SMS commented for now */}
      {/* <div
        className="automation-action"
        onClick={() => props.createSmsToggle()}
      >
        <div className="col action-icon">
          <SmsIcon />
        </div>
        <div className="col action-item">
          <h3>
            <Link to="#" onClick={handleLinkClick}>
              SMS
            </Link>
          </h3>
          <p className="text-muted">Send a SMS automatically.</p>
        </div>
      </div> */}
      {/* <div
        className="automation-action"
        onClick={() => props.createAddFacebookToggle()}
      >
        <div className="col action-icon">
          <FacebookIcon />
        </div>
        <div className="col action-item">
          <h3>
            <Link to="#" onClick={handleLinkClick}>Add to Facebook</Link>
          </h3>
          <p className="text-muted">Add contacts to Facebook custom Audience</p>
        </div>
      </div>
      <div
        className="automation-action"
        onClick={() => props.createRemoveFacebookToggle()}
      >
        <div className="col action-icon">
          <FacebookIcon />
        </div>
        <div className="col action-item">
          <h3>
            <Link to="#" onClick={handleLinkClick}>Remove from Facebook</Link>
          </h3>
          <p className="text-muted">
            Remove contacts from Facebook custom Audience
          </p>
        </div>
      </div> */}

      <div
        className="automation-action-danger"
        onClick={() => props.createEndStep()}
      >
        <div className="col action-icon">
          <i className="fa fa-lock"></i>
        </div>
        <div className="col action-item">
          <h3>
            <Link to="#" onClick={handleLinkClick}>
              End this sequence
            </Link>
          </h3>
          <p className="text-muted">End step of sequence</p>
        </div>
      </div>

      {/* <div className="socialMedias">
            <h3>Social</h3>
            </div>

            <div className="automation-action">
                <div className="col action-icon"><i className="fa fa-facebook"></i></div>
                <div className="col action-item">
                    <h3><Link to="#">Facebook Campaign</Link></h3>
                    <p className="text-muted">Create a task for a connection request.</p>
                </div>
            </div>

            <div className="automation-action">
                <div className="col action-icon"><i className="fa fa-linkedin"></i></div>
                <div className="col action-item">
                    <h3><Link to="#">LinkedIn Campaign</Link></h3>
                    <p className="text-muted">Create a task to send InMail.</p>
                </div>
            </div> */}
    </div>
  );
};

StepActions.propTypes = {};

export default StepActions;
