import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import EditColumnPopup from './EditColumnPopup';

const EditColumns = ({
  handleToggleDialog,
  editClosePopup,
  columns,
  columnOrder,
  onColumnChange,
}) => {
  const handleClose = () => {
    handleToggleDialog(!editClosePopup);
  };

  return (
    <Box>
      <EditColumnPopup
        showEditColPopup={editClosePopup}
        handleCloseDialog={handleClose}
        columns={columns}
        columnOrder={columnOrder}
        onColumnChange={onColumnChange}
      />
    </Box>
  );
};

export default EditColumns;
