import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  Menu,
  MenuItem,
  Link,
  Tooltip,
  Toolbar,
  Slide,
  Snackbar,
} from '@material-ui/core';
import CustomDataGrid from '../../../controlled-component/customGridComponent';
import SimpleSwitch from '../../../components/SimpleSwitch';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarsIcon from '@material-ui/icons/Stars';

// import EditTemplate from '../../../templates/EditTemplate';
import EditTemplate from '../../../templates/CreateTemplate/CreateTemplate';
import NewTemplate from './NewTemplate';

import {
  getStepTemplates,
  updateStepTemplateStatus,
} from '../../../actions/sequence';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0, //theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    /* float: 'left',
    borderLeft: '1px solid rgb(203, 214, 226)',
    height: '100%', */
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.custom.colorCode.white,
    boxShadow: 'none',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontSize: '16px !important',
    fontWeight: '600',
    color: theme.custom.colorCode.black,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.custom.colorCode.lightGreyColorShade10,
  },
});

const useStyles = makeStyles({
  root: {
    width: '100%',
    /* maxWidth: '850px', */
  },
  stepMainGrid: {
    marginTop: 27,
    marginLeft: 20,
  },
  tdContentEllipsis: {
    width: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 3,
  },
  publishSwitch: {
    marginRight: 10,
  },
});

/**
 * Transition effect of dialog popup
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Component to show the heading of Dialog popup
 */
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, onAdd, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <AppBar className={classes.appBar} color={'secondary'}>
        <Toolbar color="secondary">
          <IconButton
            edge="start"

            onClick={onAdd}
            aria-label="Add"
            variant="contained" color="primary"
          >
            <AddIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {children}
          </Typography>
          {onClose ? (
            <IconButton
              edge="start"
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
              color="inherit"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Toolbar>
      </AppBar>
    </MuiDialogTitle>
  );
});

/**
 * Dialog content themeing
 */
const DialogContent = withStyles((theme) => ({
  root: {
    padding: '0px', //theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '0px', //theme.spacing(1),
  },
}))(MuiDialogActions);

/**
 * Component to List the step templates
 * @param {*} props
 * @returns
 */
const StepTemplates = (props) => {
  const dispatch = useDispatch();
  const { step, open, setOpen, sequenceId, sequenceName } = props;
  /* const [open, setOpen] = React.useState(false); */
  const [templateId, setTemplateId] = useState(null);
  //const [templateData, setTemplateData] = useState(templates);
  const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [clonePopupToggle, setClonePopupToggle] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openNewTempalte, setOpenNewTemplate] = useState(false);
  const [snackerMessage, setSnackerMessage] = useState(null);
  const [severity, setSeverity] = useState('success');
  const classes = useStyles();

  const templates = useSelector((state) => state.sequence.templates);

  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Handle function to close the popup
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Handle function to open add tempate popup
   */
  const handleAdd = () => {
    setOpenNewTemplate(true);
  };

  useEffect(() => {
    // call the api to get step templates
    if (step.id > 0) {
      dispatch(getStepTemplates(step.id, sequenceId));
    }
  }, [step, sequenceId]);

  // handle the publish and un publish of sequence
  const handleSwitchChange = (event, data) => {
    const status = data?.status === 1 ? 0 : 1;
    const params = {
      status: status,
      step_id: data.step_id,
    };
    dispatch(updateStepTemplateStatus(data.id, params, showSnaker, dispatch));
  };

  /**
   * Show snaker toaster
   * @param {*} error
   * @param {*} errorMessage
   */
  const showSnaker = (error = false, errorMessage = null) => {
    if (error === true) {
      setSnackerMessage(errorMessage);
      setSeverity('error');
    } else {
      setSnackerMessage('Tempalte status changed!');
      setSeverity('success');
    }
    setOpenSnackBar(true);
  };

  // handle the edit template pop up
  const handleEditPopupOpen = (id) => {
    // handleClose();
    setTemplateId(id);
    setEditPopupToggle(!editPopupToggle);
  };

  const handleClonePopupOpen = (id) => {
    // handleClose();
    setTemplateId(id);
    setClonePopupToggle(!clonePopupToggle);
  };

  // handle function to close edit template popup
  const handleEditPopupClose = (data) => {
    setEditPopupToggle(false);
    setClonePopupToggle(false);
    setTemplateId(false);
    if (
      step.id > 0 &&
      data &&
      (data === 'editTemplate' || data === 'addTemplate')
    ) {
      dispatch(getStepTemplates(step.id, sequenceId));
    }
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setSnackerMessage(null);
    setOpenSnackBar(false);
  };

  // columns for the step templates table grid
  const columns = useMemo(
    () => [
      {
        name: 'TemplateName',
        label: 'Template Name',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 300, maxWidth: 300 },
          }),
          setCellProps: () => ({
            style: { minWidth: 300, maxWidth: 300 },
          }),
          customBodyRenderLite: (dataIndex) => {
            let rowData = templates[dataIndex];
            return (
              <div>
                <Tooltip title={rowData.TemplateName || ''} arrow>
                  <span className={classes.tdContentEllipsis}>
                    <Link
                      /* to={`/email/detail/${rowData.id}`}
                      href={`/email/detail/${rowData.id}`}
                      target="_blank"
                      rel="noopener noreferrer" */
                      href="#"
                      onClick={() => handleEditPopupOpen(`${rowData.id}`)}
                    >
                      {rowData.TemplateName}
                    </Link>
                  </span>
                </Tooltip>

                {rowData.winner === 1 ? (
                  <Tooltip title={'Winning Template'} arrow>
                    <StarsIcon
                      style={{
                        color: '#5BBB5B',
                        fontSize: '22px',
                        marginLeft: '5px',
                      }}
                    />
                  </Tooltip>
                ) : null}
              </div>
            );
          },
        },
      },
      {
        label: 'Subject',
        name: 'subject',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 150, maxWidth: 150 },
          }),
          setCellProps: () => ({
            style: { minWidth: 150, maxWidth: 150 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value || ''} arrow>
                <div>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Sent',
        name: 'total_sent',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 150, maxWidth: 150 },
          }),
          setCellProps: () => ({
            style: { minWidth: 150, maxWidth: 150 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value || ''} arrow>
                <div>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      /* {
        label: 'Delivered',
        name: 'total_delivered',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 150, maxWidth: 150 },
          }),
          setCellProps: () => ({
            style: { minWidth: 150, maxWidth: 150 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value || ''} arrow>
                <div>{value}</div>
              </Tooltip>
            );
          },
        },
      }, */
      {
        label: 'Open Rate',
        name: 'total_opened',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            let rowData = templates[dataIndex];
            return (
              <div>
                {(Number(rowData.total_delivered) &&
                  (
                    (
                      Number(rowData.total_opened) /
                      Number(rowData.total_delivered)
                    ).toFixed(2) * 100
                  ).toFixed(2)) ||
                  0}
                %
              </div>
            );
          },
        },
      },
      {
        label: 'Clicked Rate',
        name: 'total_clicked',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            let rowData = templates[dataIndex];
            return (
              <div>
                {(Number(rowData.total_delivered) &&
                  (
                    (
                      Number(rowData.total_clicked) /
                      Number(rowData.total_delivered)
                    ).toFixed(2) * 100
                  ).toFixed(2)) ||
                  0}
                %
              </div>
            );
          },
        },
      },
      {
        label: 'Bounced Rate',
        name: 'total_bounced',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            let rowData = templates[dataIndex];
            return (
              <div>
                {(Number(rowData.total_sent) &&
                  (
                    (
                      Number(rowData.total_bounced) / Number(rowData.total_sent)
                    ).toFixed(2) * 100
                  ).toFixed(2)) ||
                  0}
                %
              </div>
            );
          },
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            let rowData = templates[dataIndex];
            return (
              <SimpleSwitch
                checked={rowData.status ? true : false}
                handleChange={handleSwitchChange}
                className={classes.publishSwitch}
                leftLabelText={''}
                rightLabelText={''}
                name={'status-switch'}
                data={rowData}
              />
            );
          },
        },
      },
      {
        label: ' ',
        name: 'id',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <div>
                <GridActions
                  temp_id={value}
                  handleEditPopupOpen={handleEditPopupOpen}
                  handleClonePopupOpen={handleClonePopupOpen}
                  {...props}
                />
                {/* <Tooltip title={'Edit Email'} placement={`top-start`} arrow>
                  <Link
                    onClick={() => handleEditPopupOpen(value)}
                    style={{ cursor: 'pointer' }}
                  >
                    <EditIcon
                      style={{
                        position: 'relative',
                        color: 'black',
                        fontSize: '20px',
                        fontWeight: 400,
                      }}
                    />
                  </Link>
                </Tooltip>
                <Tooltip title={'View Report'} placement={`top-start`} arrow>
                  <Link
                    to={`/email/detail/${value}`}
                    href={`/email/detail/${value}`}
                    component="a"
                  >
                    <ReceiptIcon
                      style={{
                        position: 'relative',
                        color: 'black',
                        fontSize: '20px',
                        fontWeight: 400,
                      }}
                    />
                  </Link>
                </Tooltip> */}
              </div>
            );
          },
        },
      },
    ],
    [templates],
  );

  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {templates.length}
      </Button> */}
      <Dialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.root}
        TransitionComponent={Transition}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          onAdd={handleAdd}
        >
          {`${sequenceName} (${step.name})`}
        </DialogTitle>
        <DialogContent dividers>
          <CustomDataGrid
            columns={columns}
            data={templates}
            hideToolBar={true}
            paperWidth={'40px'}
            className={classes.stepMainGrid}
            options={{
              pagination: true,
              selectableRows: 'none',
              filterType: 'dropdown',
              responsive: 'simple',
              selectToolbarPlacement: 'none',
              /* customToolbar: () => {
                return <></>;
              }, */
            }}
            /* components={{ TableBody: BodyComponent }} */
          />
          <Snackbar
            open={openSnackBar}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={handleSnackbarClose}
              severity={severity}
            >
              {snackerMessage}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>

      <EditTemplate
        templateId={templateId}
        openDrawer={editPopupToggle || clonePopupToggle}
        onClose={handleEditPopupClose}
        cloneTemplate={clonePopupToggle}
        sequenceId={sequenceId}
        step={step}
        /* history={props.history}
        getRecords={props.getUserTemplate} */
      />
      <NewTemplate
        open={openNewTempalte}
        setOpen={setOpenNewTemplate}
        sequenceId={sequenceId}
        step={step}
        showSnaker={showSnaker}
      />
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const [anchorElement, setAnchorElement] = useState(null);

  //const editable = user.id === row.created_by;
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      case 'changeStatus': {
        props.updatestatus(props.temp_id, props.row);
        break;
      }
      case 'cloneTemplate': {
        props.handleClonePopupOpen(props.temp_id, props.row);
        break;
      }
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: 'Edit',
      itemType: 'editPopUp',
    },
    /* {
      title: 'Edit Steps',
      itemType: 'editStep',
    },
    {
      title: 'Change Status',
      itemType: 'changeStatus',
    },*/
    {
      title: 'Clone',
      itemType: 'cloneTemplate',
    },
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          href={`/email/detail/${props.temp_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Detail
        </MenuItem>
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default StepTemplates;
