import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// import TemplateGrid from "./TemplateGrid";

import TemplateTabs from "./TemplateTabs";

// const Template = (props) => {
//   return (
//     <div className="">
//       <div className="flex-row">
//         <aside className="sideBarMargin">
//           <SidebarNav template="active" />
//         </aside>
//
//         <div className="flex-col content">
//           <TemplateGrid admin {...props} />
//           {/* <ToastContainer autoClose={2000} /> */}
//         </div>
//       </div>
//     </div>
//   );
// };

const Template = (props) => {
  const userRole = props.auth.user?.roles?.filter((val) => val.role_id === 1);
  const admin = userRole.length > 0 ? true : false;
  return <TemplateTabs admin={admin} {...props} />;
};

Template.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Template);
