import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { isArray } from 'lodash';
import { Button, Grid } from '@material-ui/core';

import FormAutoCompleteSelect, {
  AutoCompleteRenderInfoOption,
} from '../../components/FormAutoCompleteSelect';
import Spinner from '../../layout/Spinner';
import {
  audienceTypeOption,
  sequenceAccessOption,
} from '../../utils/applicationConstant';
import { createSequence } from '../../actions/sequence';
import { fetchUsersTeams } from '../../actions/users';

import useStyles from './CreateSequenceFormStyle';

const CreateSequenceForm = (props) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [teamToggle, setTeamToggle] = useState(false);
  const [userToggle, setUserToggle] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const formRef = useRef();

  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  // users options
  const usersData = users.users ? users.users : null;
  const usersOptions = [
    ...(usersData || []).map((user) => ({
      value: user.company_user_id,
      label: user.name,
    })),
  ];

  // teams options
  const teamsData = users.teams ? users.teams : null;
  const teamsOptions = (teamsData || []).map((team) => ({
    value: team.id,
    label: team.name,
  }));

  useEffect(() => {
    // did mount
    setAddSequenceData();
  }, []);

  useEffect(() => {
    let formDataClone = { ...formData };
    if (users.user_id) {
      formDataClone = {
        ...formDataClone,
        shared_with_users: isArray(users.user_id)
          ? users.user_id.map((sTeam) => sTeam.value)
          : [users.user_id.value],
      };
    }
    if (users.team_id) {
      formDataClone = {
        ...formDataClone,
        shared_with_teams: isArray(users.team_id)
          ? users.team_id.map((sTeam) => sTeam.value)
          : [users.team_id.value],
      };
    }
    setFormData({
      ...formDataClone,
    });
  }, [users.user_id, users.team_id]);

  /**
   * will hit post api to add sequence in the db
   */
  const handleSubmit = async () => {
    if (disabledNextButton() === false) {
      setDisabledButton(true);

      let payload = {
        access_type: formData.access_type,
        audience_type: formData.audience_type,
        description: formData.description,
        name: formData.name,
      };

      if (formData.access_type === 1) {
        payload = {
          ...payload,
          shared_with_users: usersOptions.filter((uO) =>
            formData.shared_with_users.includes(uO.value),
          ),
        };
        setUserToggle(true);
        setTeamToggle(false);
      } else if (formData.access_type === 2) {
        payload = {
          ...payload,
          shared_with_teams: teamsOptions.filter((tO) =>
            formData.shared_with_teams.includes(tO.value),
          ),
        };
      }

      dispatch(
        createSequence(
          payload,
          props.history,
          props.closeDrawer,
          setDisabledButton,
        ),
      );
    }
  };

  /**
   * handle the input change
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to set the initial data in the state
   * @returns {Promise<void>}
   */
  const setAddSequenceData = async () => {
    setIsLoading(true);
    await dispatch(fetchUsersTeams());
    setIsLoading(false);
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['name', 'audience_type', 'description', 'access_type'];

    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  // Helper text for the sequence
  const getSequenceHelperText = () => {
    const sequence = sequenceAccessOption?.find(
      (c) => c.value === formData?.access_type,
    );

    return sequence ? `${sequence.title}:  ${sequence.helperText}` : '';
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    if (name === 'shared_with_users' || name === 'shared_with_teams') {
      return setFormData({
        ...formData,
        [name]: tag.map((e) => e[key]),
      });
    }
    if (name === 'access_type') {
      if (tag[key] === 1) {
        setUserToggle(true);
        setTeamToggle(false);
      } else if (tag[key] === 2) {
        setUserToggle(false);
        setTeamToggle(true);
      } else {
        setUserToggle(false);
        setTeamToggle(false);
      }
    }

    setFormData({
      ...formData,
      [name]: tag[key],
    });
  };

  // show spinner
  if (isLoading) {
    return (
      <div className="text-center mt-2">
        <Spinner style={{ width: 40, height: 40, position: 'unset' }} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ValidatorForm ref={(r) => (formRef.current = r)} onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> Sequence Details </h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*Sequence Name && Sequence audience*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label="Sequence Name *"
                  onChange={handleChange}
                  name="name"
                  variant="outlined"
                  value={formData?.name || ''}
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter sequence name']}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="pl-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('audience_type', 'value')}
                  name="audience_type"
                  id="audience_type"
                  key={'audience_type'}
                  disableClearable
                  options={audienceTypeOption}
                  getOptionLabel={(option) => option.title || ''}
                  getOptionSelected={(option, value) => option.value === value}
                  value={
                    formData.audience_type
                      ? audienceTypeOption?.find(
                          (c) => c.value === formData?.audience_type,
                        ) || ''
                      : ''
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Sequence Audience *"
                      className={classes.selectBoxStyle}
                      name="audience_type"
                      variant="outlined"
                      value={formData?.audience_type || ''}
                      fullWidth
                      validators={['required']}
                      errorMessages={['Select Sequence audience']}
                    />
                  )}
                />
              </Grid>

              {/*seq desc and access type*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Sequence Description *"
                  onChange={handleChange}
                  name="description"
                  type="description"
                  variant="outlined"
                  value={formData?.description || ''}
                  fullWidth
                  multiline
                  rowsMax={4}
                  validators={['required']}
                  errorMessages={['Please enter description']}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('access_type', 'value')}
                  name="access_type"
                  id="access_type"
                  key={'access_type'}
                  disableClearable
                  debug
                  options={sequenceAccessOption}
                  getOptionLabel={(option) => option.title || ''}
                  getOptionSelected={(option, value) => option.value === value}
                  value={
                    formData.access_type
                      ? sequenceAccessOption?.find(
                          (c) => c.value === formData?.access_type,
                        ) || ''
                      : ''
                  }
                  renderOption={({ title, helperText }) => {
                    return (
                      <AutoCompleteRenderInfoOption
                        title={title}
                        helperText={helperText}
                      />
                    );
                  }}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Sequence access *"
                      className={classes.selectBoxStyle}
                      name="access_type"
                      variant="outlined"
                      value={formData?.access_type || ''}
                      fullWidth
                      validators={['required']}
                      errorMessages={['Select access type']}
                      helperText={getSequenceHelperText()}
                    />
                  )}
                />
              </Grid>

              {userToggle && (
                <Grid item xs={12} sm={12}>
                  <div className={classes.multipleSelectHeading}>
                    Select users
                  </div>
                  <FormAutoCompleteSelect
                    multiple
                    onChange={handleSelectChange('shared_with_users', 'value')}
                    name="shared_with_users"
                    id="shared_with_users"
                    disableClearable
                    options={usersOptions}
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      formData?.shared_with_users?.length
                        ? (usersOptions || []).filter((c) =>
                            formData?.shared_with_users.includes(c.value),
                          )
                        : []
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label=""
                        name="shared_with_users"
                        variant="outlined"
                        fullWidth
                        value={formData?.shared_with_users || ''}
                        validators={['required']}
                        errorMessages={['Select users']}
                      />
                    )}
                  />
                </Grid>
              )}

              {teamToggle && (
                <Grid item xs={12} sm={12}>
                  <div className={classes.multipleSelectHeading}>
                    Select teams
                  </div>
                  <FormAutoCompleteSelect
                    multiple
                    onChange={handleSelectChange('shared_with_teams', 'value')}
                    name="shared_with_teams"
                    id="shared_with_teams"
                    disableClearable
                    options={teamsOptions}
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      formData?.shared_with_teams?.length
                        ? (teamsOptions || []).filter((c) =>
                            formData?.shared_with_teams.includes(c.value),
                          )
                        : []
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label=""
                        name="shared_with_teams"
                        variant="outlined"
                        fullWidth
                        value={formData?.shared_with_teams || ''}
                        validators={['required']}
                        errorMessages={['Select team']}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledNextButton()}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default CreateSequenceForm;
