import api from '../utils/api';
import { toast } from 'react-toastify';
import {
  GET_TASKS,
  ADD_TASK,
  ADD_COLUMN,
  UPDATE_COLUMN_ORDER,
  DELETE_TASK,
  DELETE_COLUMN,
  UPDATE_STATE,
  TASK_ERROR,
} from '../actions/types';

// Get current users Tasks
export const getUserTasks = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/task-column');
    //console.log(res);
    dispatch({
      type: GET_TASKS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/* const Msg = ({ closeToast, toastProps }) => (
    <div>
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>This contact already exist in this board
    </div>
  ) */

//Add user task
export const createTasks = (data, laneId, addCardToLane) => async (
  dispatch,
) => {
  try {
    const values = {
      column_id: data.column_id,
      task_title: data.title,
      task_content: data.full_description
        ? data.full_description
        : data.description,
      notification_id: data.notification_id,
      contact_id: data.contact_id ? data.contact_id : null,
      contact_type: data.contact_type ? data.contact_type : null,
    };
    await api
      .post('/automation/task', {
        values,
      })
      .then((res) => {
        if (res.status === 201) {
          //addCardToLane(res.data, laneId);
          dispatch({
            type: ADD_TASK,
            payload: res.data,
          });
          toast.success('Task added', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
          //dispatch(getUserTasks());
        }
      });
    //console.log(res);
  } catch (err) {
    if (err.response.status == 409) {
      toast.warn('This contact already exist in this board', {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      /* toast.warn(<Msg />,{
                autoClose: 2000,
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            }); */
    } else {
      dispatch({
        type: TASK_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  }
};

//Delete card
export const deleteTask = (cardId, laneId) => async (dispatch) => {
  try {
    await api.delete(`/automation/task/${cardId}`).then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_TASK,
          payload: [],
          cardId: cardId,
          laneId: laneId,
        });
        toast.success('Task removed', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
      }
    });
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update column order
export const updateColumnOrder = (removedIndex, addedIndex, payload) => async (
  dispatch,
) => {
  try {
    const values = {
      old_column_order: removedIndex,
      new_column_order: addedIndex,
      column_id: payload.column_id,
    };
    await api
      .put(`/automation/arrange-column`, {
        values,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_COLUMN_ORDER,
            payload: res.data,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update column order
export const arrangeTask = (
  cardId,
  sourceLaneId,
  targetLaneId,
  position,
  cardDetails,
) => async (dispatch) => {
  try {
    const values = {
      task_id: cardId,
      from_column_id: sourceLaneId,
      to_column_id: targetLaneId,
      position: position,
      task_detail: cardDetails,
    };

    await api
      .put(`/automation/task-arrange`, {
        values,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_COLUMN_ORDER,
            payload: res.data,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Add user column
export const addColumn = (data) => async (dispatch) => {
  try {
    const values = {
      column_name: data.title,
    };
    await api
      .post('/automation/task-column', {
        values,
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: ADD_COLUMN,
            payload: res.data,
          });
          toast.success('Column added', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
    //console.log(res);
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Delete card
export const deleteColumn = (laneId) => async (dispatch) => {
  try {
    await api.delete(`/automation/task-column/${laneId}`).then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_COLUMN,
          payload: res.data,
          laneId: laneId,
        });
        toast.success('Column removed', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
      }
    });
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update task state
export const updateTaskState = () => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_STATE,
      payload: null,
    });
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
