import React from 'react';
import api from '../utils/api';
import { Col, Modal, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import { v4 as uuidv4 } from 'uuid';
// import { addTemplate, getUserLinkedTemplate } from '../actions/template';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';

const schema = yup.object().shape({
  // name: yup.string().required('Template name is a required field'),
  //template_type: yup.number().required('Template type is a required field'),
  //subject: yup.string().required('Template subject is a required field'),
  // content: yup.string().required('Content is a required field'),
});

const CreateTemplate = (props) => {
  const {
    register,
    handleSubmit,
    // control,
    errors,
    // reset,
    // formState: { isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(schema) });

  // const [templateData, setTemplateData] = React.useState({});

  const onSubmitTemplate = async (values) => {
    try {
      await api
      .post('/integrations/fbaddaud', {
        values,
      })
        .then((res) => {
          // console.log(res);
          toast.success('Custom audience added', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        });
    } catch (err) {
      // console.log(err.response);
      toast.error(err.response.data.Error, {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  /*   React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...templateData });
    }
  }, [isSubmitSuccessful, templateData, reset]); */

  return (
    <Modal
      show={props.modalState}
      // onHide={() => props.onHide('addTemplate')}
      dialogClassName="custom-modal"
      enforceFocus={false}
    >
      <Form onSubmit={handleSubmit(onSubmitTemplate)}>
        <Modal.Header className="modalHeaderCls" closeButton={false}>
          <div>
            <Modal.Title className="modalTitle">
              Create Custom Audience (Ads Sccount: {props.adsAccounts})
            </Modal.Title>
            <span
              className="closeModalIconCls"
              onClick={() => props.onHide('addTemplate')}
            >
              <i className="fa fa-close"></i>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label className="floating-label">Audienec Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                className="custom_input"
                ref={register}
              ></Form.Control>
              {errors.name && (
                <label className="error">{errors.name?.message}</label>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="subtype">
              <Form.Label className="floating-label">Subtype</Form.Label>
              <Form.Control
                type="text"
                name="subtype"
                defaultValue="CUSTOM"
                className="custom_input"
                ref={register}
                readOnly
              ></Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="description">
              <Form.Label className="floating-label">Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                className="custom_input"
                ref={register}
              ></Form.Control>
              {errors.description && (
                <label className="error">{errors.description?.message}</label>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Control
            type="hidden"
            name="adsAccount"
            id="adsAccount"
            value={props.adsAccounts}
            className="custom_input"
            ref={register}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button varient="primary" className="pl-4 pr-4" type="submit">
            Save
          </Button>
          {/* <Button varient="primary" className="pl-4 pr-4"
          onClick={() => this.props.onHide('addSequence')}>Next</Button> */}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

/* CreateTemplate.propTypes = {
  auth: PropTypes.object.isRequired,
  addTemplate: PropTypes.func.isRequired,
};
 */
const mapStateToProps = (state) => ({
  auth: state.auth,
  sequence: state.sequence,
});
const mapDispatchToProps = {
  // addTemplate,
  // getUserLinkedTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
