import api from '../utils/api';
import { GET_TABS, ADD_TAB, UPDATE_TAB, TAB_ERROR } from '../actions/types';
import { toast } from 'react-toastify';

// Get current users object tabs
export const fetchTabs = (object) => async (dispatch) => {
  try {
    const params = {
      object: object,
    };
    const res = await api.get('/automation/tabs', { params });
    dispatch({
      type: GET_TABS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TAB_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get create new tab/saved filter of user
export const createTab = (values, history, handleClose, setTabvalue) => async (
  dispatch,
) => {
  try {
    await api.post('/automation/tabs', { values }).then((res) => {
      dispatch({
        type: ADD_TAB,
        payload: res.data,
      });
      setTabvalue(res.data.id);
      history.push('/leads/views/' + res.data.id);
      handleClose();
      toast.success('Views saved', {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
    });
  } catch (err) {
    dispatch({
      type: TAB_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
    toast.error('Error in saving view', {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
  }
};

// update tab filters
export const updateTabFilters = (tabId, values, handleClose) => async (
  dispatch,
) => {
  try {
    await api
      .put(`/automation/tabs/update-filters/${tabId}`, { values })
      .then((res) => {
        dispatch({
          type: UPDATE_TAB,
          payload: res.data,
        });
        handleClose();
        toast.success('Views saved', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
      });
  } catch (err) {
    dispatch({
      type: TAB_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
    toast.error('Error in saving view', {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
  }
};
