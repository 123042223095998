import React, { useEffect, useState } from 'react';
import MUICollapse from '@material-ui/core/Collapse';
import { ListItem, Typography, Box } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom, transitions }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
  },
  mainRoot: {},
  collapseWrapper: {},
  collapseHeaderText: {
    fontWeight: 'bold',
    flex:1,
  },
  collapseIconExpand: {
    transform: 'rotate(0deg) !important',
  },
  collapseIcon: {
    fill: custom.colorCode.lightGreyColorShade8,
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    cursor: 'pointer',
    transition: transitions?.create('transform', {
      duration: transitions.duration.shortest,
    }),
  },
}));

const Collapse = ({
  children,
  title = '',
  isOpen,
  icon,
  contentTitle,
  handleCollapseCLick = () => {},
  className = '',
  collapseProps: { collapseClassName = '', ...collapseProps } = {},
  defaultOpen,
  iconSize = 'default',
  leftIcon = false,
}) => {
  const classes = useStyles();

  const [isCollapseOpen, setIsCollapseOpen] = useState(defaultOpen || false);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsCollapseOpen(isOpen);
    }
  }, [isOpen]);

  const handleClick = () => {
    setIsCollapseOpen(!isCollapseOpen);
    handleCollapseCLick();
  };

  return (
    <>
      <Box className={`${className} ${classes.root}`} onClick={handleClick}>
        {leftIcon &&
          (icon ? (
            icon
          ) : (
            <ExpandMore
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleClick(event);
              }}
              fontSize={iconSize}
              className={
                isCollapseOpen
                  ? `${classes.collapseIconExpand} ${classes.collapseIcon}`
                  : classes.collapseIcon
              }
              style={{ marginLeft: 'unset' }}
            />
          ))}

        {contentTitle ? (
          contentTitle
        ) : (
          <Typography variant="h6" className={classes.collapseHeaderText}>
            {title}
          </Typography>
        )}

        {!leftIcon &&
          (icon ? (
            icon
          ) : (
            <ExpandMore
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleClick(event);
              }}
              fontSize={iconSize}
              className={
                isCollapseOpen
                  ? `${classes.collapseIconExpand} ${classes.collapseIcon}`
                  : classes.collapseIcon
              }
            />
          ))}
      </Box>

      <MUICollapse
        in={isCollapseOpen}
        timeout="auto"
        unmountOnExit
        className={`${collapseClassName} ${classes.collapseWrapper}`}
        {...collapseProps}
      >
        {children}
      </MUICollapse>
    </>
  );
};

export default Collapse;
