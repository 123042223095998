import React, { useState } from 'react';

import CustomDrawer from '../../../../components/CustomDrawer';
import DrawerFormHeader from '../../../../components/DrawerFormHeader';
import ResetPasswordForm from './ResetPasswordForm';
import CustomSnackBar from "../../../../components/CustomSnackBar";

const ResetPassword = (props) => {
  const { openDrawer, onClose, userData, getUserData } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  // const [updated, setUpdated] = useState(false);

  /**
   * Handle function to close the drawer popup
   * @param {*} data
   */
  const handleClose = (data) => {
    if (data && data?.passwordUpdated) {
      setOpenSnackBar(true);
      if (data?.status === 'success') {
        getUserData();
        onClose();
      }
      return setOpenSnackBarMessage({
        message: data?.message || '',
        status: data?.status || '',
      });
    }
    onClose();
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={'Reset Password'}
        >
          <ResetPasswordForm closeDrawer={handleClose} userData={userData} />
        </DrawerFormHeader>
      </CustomDrawer>
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default ResetPassword;
