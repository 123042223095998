import {
  GET_TEMPLATE,
  GET_TEMPLATES,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  UPDATE_STATE,
  TEMPLATE_ERROR,
  GET_TEMPLATE_STATS,
  GET_TEMPLATE_RECIPIENTS,
  GET_CVSPEC_TEMPLATES,
  GET_MASTER_CVSPEC_TEMPLATES_LIST,
  GET_CVSPEC_TEMPLATELIST,
  LOAD_TEMPLATE_DATA,
  LOAD_TEMPLATE_ERROR,
  SHOW_LOAD_TEMPLATE_LOADER,
  SET_TEMPLATE_FILTER_DATA,
  LOAD_TEMPLATE_TABS,
  LOAD_FILTER_TEMPLATE_DATA,
} from "../actions/types";

const initialState = {
  templates: [],
  recipients: [],
  loading: true,
  detail: {},
  performance: {},
  content: null,
  error: {},
  masterList: [],
  cvspecList: [],
  templateTab: {
    tabs: [],
    loading: true,
  },
  count: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TEMPLATES: {
      let data = {
        ...state,
        templates: payload,
        loading: false,
      };
      return data;
    }
    case CREATE_TEMPLATE:
    case UPDATE_TEMPLATE:
    case UPDATE_STATE:
      return {
        ...state,
        content: null,
        loading: false,
      };
    case GET_TEMPLATE: {
      let data = {
        ...state,
        detail: payload,
        content: payload.content,
        loading: false,
      };
      return data;
    }
    case GET_TEMPLATE_STATS: {
      let data = {
        ...state,
        performance: payload,
        loading: false,
      };
      return data;
    }
    case GET_TEMPLATE_RECIPIENTS: {
      let data = {
        ...state,
        recipients: payload,
        loading: false,
      };
      return data;
    }
    case TEMPLATE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        profile: null,
      };
    case GET_CVSPEC_TEMPLATES: {
      let data = {
        ...state,
        templates: payload,
        loading: false,
      };
      return data;
    }
    case GET_MASTER_CVSPEC_TEMPLATES_LIST: {
      let data = {
        ...state,
        masterList: payload,
        loading: false,
      };
      return data;
    }
    case GET_CVSPEC_TEMPLATELIST: {
      let data = {
        ...state,
        cvspecList: payload,
        loading: false,
      };
      return data;
    }

    case SHOW_LOAD_TEMPLATE_LOADER: {
      return {
        ...state,
        [payload.tabData.id]: {
          ...state[payload.tabData.id],
          loading: true,
        },
        count: 0,
      };
    }

    case LOAD_TEMPLATE_TABS: {
      const tabsData = {};
      payload.tabs.forEach((e) => {
        tabsData[e.id] = {
          templateDataList: [],
          myFilterData: [],
          filterList: {},
          filterState: {},
          defaultFilterState: {},
          count: 0,
          loading: true,
        };
      });

      return {
        ...state,
        templateTab: {
          ...state.templateTab,
          ...payload,
          loading: false,
        },
        ...tabsData,
      };
    }

    case LOAD_FILTER_TEMPLATE_DATA: {
      const id = Object.keys(payload)[0];
      return {
        ...state,
        [id]: {
          ...state[id],
          ...payload[id],
        },
        count: payload[id]?.count || 0,
      };
    }

    case SET_TEMPLATE_FILTER_DATA: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload[payload.id],
        },
      };
    }

    case LOAD_TEMPLATE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
