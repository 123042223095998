import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiChip from '@material-ui/core/Chip';
import MuiPaper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const Accordion = ({keys, headings, expand, content, ...providedProps}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {selected} = providedProps;
  return (
    <>
      <MuiAccordion expanded={expanded === expand} onChange={handleChange(expand)} key={`${keys}-accordian`} >
        <MuiAccordionSummary
            key={`${keys}-accordian-summary`}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${expand}-content`}
          id={`${expand}-header`}
        >
          <Typography className={classes.root} key={`${keys}-typeogra`}>{headings}</Typography>
          {(!expanded) ? <FilterSelected selected={selected} /> : null}
        </MuiAccordionSummary>
        <MuiAccordionDetails key={`${keys}-accordian-detail`}>
          {content}
        </MuiAccordionDetails>
      </MuiAccordion>

    </>
  );
}

/**
 * Selected Chips In Accordian
 * @param {*} props
 * @returns
 */
const FilterSelected = (props) => {
    const classes = useStyles();
    const handleDelete = (data) =>{
        //console.log(data);
    }
    let key = 0;
    let icon;
    const filterChips = props.selected.map(selected =>{
        key++;
        let opt = selected.split('_');
        let optionText = (opt.length > 1) ? opt[1] : opt[0];
        return <MuiChip
            key={key}
            icon={icon}
            label={optionText}
            onDelete={handleDelete(selected)}
            className={classes.chip}
        />
    });
    return (
        <>
        <div className={classes.root}>{filterChips}</div>
        </>
    )
}

Accordion.propTypes = {
    /* refExit: PropTypes.func, */
    /* trigger: PropTypes.node.isRequired, */
    content: PropTypes.node.isRequired,
    /* headings: PropTypes.text.isRequired,
    expand: PropTypes.text.isRequired, */
    /* hide: PropTypes.bool, */
};
export default Accordion;
