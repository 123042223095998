import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import BreadCrumbs from '../../components/BreadCrumbs';
import SimpleSwitch from '../../components/SimpleSwitch';
import Summary from './Summary';
import Recipients from './Recipients';
import {
  getSequenceDetail,
  updateSequenceStatus,
} from '../../actions/sequence';

import MainTabs, { TabPanel } from '../../components/MainTabs';
import ActionButtonMenu from './ActionButtonMenu';
import CloneSequence from '../CloneSequence';
import EditSequence from './EditSequence';
import Spinner from '../../layout/Spinner';
import api from '../../utils/api';
import EnrollContact from '../EnrollContact';
import Dialog from '../../components/Dialog';

import useStyles from './style';
import { Link } from 'react-router-dom';

// tabs menus items
const tabsOptions = [
  {
    name: 'summary',
    title: 'Summary',
  },
  {
    name: 'step',
    title: 'Steps',
  },
  {
    name: 'recipients',
    title: 'People',
  },
  {
    name: 'editDetail',
    title: 'Setting',
  },
  // {
  //   name: 'details',
  //   title: 'Details',
  // },
];

/**
 * Component for sequence detail page
 * @param {*} props
 * @returns
 */
const SequenceDetailPage = (props) => {
  const { match } = props;
  const dispatch = useDispatch();

  const [sequenceTabIndex, setSequenceTabIndex] = useState(0); // active tab index
  const [publishAction, setPublishAction] = useState(false); // toggle of the switch in header
  const [clonePopupToggle, setClonePopupToggle] = useState(false);
  const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [sequenceId, setSequenceId] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [enrollPopupToggle, setEnrollPopupToggle] = useState(false);
  const [showEmailConnectPopup, setShowEmailConnectPopup] = useState(false);

  const sequence = useSelector((state) => state.sequence);

  const classes = useStyles();

  // did mount to call api one for one time
  useEffect(() => {
    setIsLoading(true);
    dispatch(getSequenceDetail(match.params.id, setIsLoading));
  }, [match.params.id]);

  /**
   * Subscribe the sequence detail state
   */
  useEffect(() => {
    setPublishAction(sequence.details.is_published === 'Published');
  }, [sequence.details]);

  // when tab index changed
  const handleTabChange = (event, newValue) => {
    tabsOptions[newValue].name !== 'step' && setSequenceTabIndex(newValue);
  };

  // handle the publish and un publish of sequence
  const handleSwitchChange = () => {
    setPublishAction(!publishAction);
    const status = sequence?.details?.is_published === 'Un-Published' ? 1 : 2;
    dispatch(
      updateSequenceStatus(
        sequence?.details?.id,
        status,
        'sequence-detail',
        dispatch
      )
    );
  };

  // handle the edit sequence pop up
  const handleEditPopupOpen = () => {
    setSequenceId(match.params.id);
    setEditPopupToggle(!editPopupToggle);
  };

  // make copy of the selected sequence
  const handleCopySequence = () => {
    setSequenceId(match.params.id);
    setClonePopupToggle(!clonePopupToggle);
  };

  // enroll drawer open
  const handleEnrollSequence = () => {
    api
      .get('integrations/myemail_plugin')
      .then((resp) => {
        if (resp.status == 200) {
          if (!resp.data) {
            setShowEmailConnectPopup(!resp.data);
          } else {
    setEnrollPopupToggle(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnConnectButton = () => {
    props.history.push('/profile/integrations');
  };

  const handleDialogClose = () => {
    setShowEmailConnectPopup(!showEmailConnectPopup);
  };

  // enroll drawer close
  const handleEnrollPopup = () => {
    setEnrollPopupToggle(false);
  };

  // Show Loader
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  return (
    <div>
      <BreadCrumbs
        crumbData={[
          // { to: '/admin/sequence', title: 'Sequence' },
          { to: '/sequence', title: 'Sequence' },
          { title: sequence?.details?.name },
        ]}
      />

      <div className={classes.pageHeader}>
        <span>{sequence?.details?.name}</span>

        <div className={classes.pageHeaderRight}>
          <SimpleSwitch
            checked={publishAction}
            handleChange={handleSwitchChange}
            className={classes.publishSwitch}
            leftLabelText={'Deactive'}
            rightLabelText={'Active'}
            name={'publish-un-publish'}
          />

          {/* <Button className={classes.actionButton} variant='contained'>
            Actions
          </Button> */}
          <ActionButtonMenu
            seq_id={match.params.id}
            buttonClassName={classes.actionButton}
            handleEditPopupOpen={handleEditPopupOpen}
            copySequence={handleCopySequence}
            publishAction={publishAction}
            enrollSequence={handleEnrollSequence}
          />
        </div>
      </div>

      {/*email connect popup*/}
      <Dialog
        // objectId={sequenceId}
        open={showEmailConnectPopup}
        title="Connect Mailbox"
        content="To send emails via SuperReach your first need to connect your mailbox."
        handleClick={handleOnConnectButton}
        onClose={handleDialogClose}
        buttonText={`Connect`}
      />

      <MainTabs
        tabsOptions={tabsOptions.map((tOption) =>
          tOption.name === 'step'
            ? {
                ...tOption,
                component: 'a',
                href: `/sequence/steps/${match.params.id}`,
                target: '_blank',
                rel: 'noreferrer',
              }
            : tOption
        )}
        tabIndex={sequenceTabIndex}
        onTabChange={handleTabChange}
        className={classes.contentMainTabs}
        tabLabel='title'
        tabKey='name'
      />

      <TabPanel value={sequenceTabIndex} index={0}>
        <Summary
          users={sequence?.details?.userList || []}
          sequence_id={match.params.id}
          dateFilterOptions={sequence?.details?.dateFilterOptions || []}
        />
      </TabPanel>
      <TabPanel value={sequenceTabIndex} index={2}>
        <Recipients
          users={sequence?.details?.userList || []}
          sequence_id={match.params.id}
          dateFilterOptions={sequence?.details?.dateFilterOptions || []}
        />
      </TabPanel>
      <TabPanel value={sequenceTabIndex} index={3}>
        {/* Edit sequence tab */}
        <EditSequence
          seq_id={match.params.id}
          sname={sequence?.details?.name}
          onHide={handleEditPopupOpen}
          history={props.history}
          page='detail'
        />
      </TabPanel>
      {/* Copy/clone sequence popup */}
      {clonePopupToggle && (
        <CloneSequence
          seq_id={match.params.id}
          sname={sequence?.details?.name}
          modalState={clonePopupToggle}
          onHide={handleCopySequence}
          history={props.history}
        />
      )}
      {/* enroll sequence popup */}
      {publishAction && (
        <EnrollContact
          openDrawer={enrollPopupToggle}
          onClose={handleEnrollPopup}
          history={props.history}
          selectedSequenceToEnroll={sequence?.details}
        />
      )}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default SequenceDetailPage;
