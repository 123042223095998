import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  customMainGrid: {
    '& table': {
      background: custom.colorCode.white,
      backgroundColor: custom.colorCode.white,
    },
    // '& [class*="MUIDataTableToolbarSelect-root"]': {
    //   backgroundColor: custom.colorCode.white,
    //   boxShadow: 'none',
    //   borderRadius: 0,
    //   border: `1px solid ${custom.colorCode.lightGreyColorShade1}`,
    //   borderBottom: 'none',
    // },
    '.MuiCheckbox-root': {},
    '& [class*="Mui-checked"]': {
      color: custom.colorCode.blueLightShade8,
    },
    '& .MuiPaper-root': {},
    '& [class*="MuiToolbar-root"]': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 8,
      '& div:nth-child(2)': {
        '& [class*="MuiIconButton-root"]': {
          display: 'none',
        },
      },
    },
    '& [class*="MuiTableRow-hover"]': {
      '&:hover': {
        backgroundColor: custom.colorCode.lightGreyColorShade2,
        '& [class*="MUIDataTableSelectCell-root"]': {
          '&:hover': {
            backgroundColor: custom.colorCode.lightGreyColorShade2,
          },
          backgroundColor: custom.colorCode.lightGreyColorShade2,
        },
        // '& a': {
        //   color: custom.colorCode.white,
        // },
        // '& div': {
        //   color: custom.colorCode.white,
        // },
      },
    },
    fontSize: 16,
    boxShadow: 'none',
    backgroundColor: custom.colorCode.lightGreyColorShade7,
    '& [class*="MuiTableHead-root"]': {
      height: 55,
    },
    '& [class*="MuiTableCell-paddingCheckbox"]': {
      paddingLeft: 0,
    },
    '& [class*="MuiTableRow-root"]': {
      '& td': {
        paddingTop: 0,
        paddingBottom: 0,
        '& div': {
          // fontSize: 16,
        },
      },
      '& thead': {
        height: 45,
      },
      '& th': {
        height: 45,
        paddingTop: 0,
        paddingBottom: 0,
        '& div': {
          // fontSize: 16,
          textTransform: 'capitalize',
        },
      },
    },
    '& [class*="MuiTableBody-root"]': {
      '& [class*="mui-row-selected"]': {
        backgroundColor: custom.colorCode.lightGreyColorShade2,
      },
    },
  },
}));

export default useStyles;
