import api from '../../utils/api';
import {
  LOAD_ADMIN_JOB_ROLES,
  JOB_ROLES_ERROR,
  LOAD_ADMIN_OFFICES_CATEGORY,
} from '../types';

// Get Job Roles list for grid listing
export const fetchJobRoles = (params = {}) => async (dispatch) => {
  try {
    const res = await api.get('/automation/jobroles', { params });

    dispatch({
      type: LOAD_ADMIN_JOB_ROLES,
      payload: { ...res.data },
    });
  } catch (err) {
    // error handle and set in the reducer
    dispatch({
      type: JOB_ROLES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get category and up skill list for add/edit job role drop down
export const fetchCategory = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/get-category-skill');

    dispatch({
      type: LOAD_ADMIN_OFFICES_CATEGORY,
      payload: {
        categoryList: res.data.categoryList,
        skillsList: res.data.skillsList,
      },
    });
  } catch (err) {
    // error handle and set in the reducer
    dispatch({
      type: JOB_ROLES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
