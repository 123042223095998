import api from '../utils/api';

// Get candidates list for cv spec templates create/edit
export const getCandidatesLookup = async (term = null, excludeIds = null) => {
  try {
    const params = {
      term: term,
      excludeid: excludeIds,
    };

    const res = await api.get('/candidate/candidate-lookup', {
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
};

// Get candidates list for cv spec templates create/edit
export const getCandidatesDMLookup = async (term = '') => {
  try {
    const params = {
      term,
    };

    const res = await api.get('/dm/dm-lookup', {
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
};

// Get candidates list for cv spec templates create/edit
export const getAssignToLookup = async (term = '', only = '') => {
  try {
    const params = {
      term, only,
    };

    const res = await api.get('/dm/get-their-manager', {
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
};
