import api from '../../utils/api';

import {
  LOAD_INDUSTRIES,
  INDUSTRIES_ERROR,
  CHANGE_INDUSTRY_STATUS,
  REMOVE_INDUSTRY,
} from '../types';

// Get industries list and set in reducer
export const fetchIndustries = (params = {}) => async (dispatch) => {
  try {
    const res = await api.get('/automation/industries', { params });

    dispatch({
      type: LOAD_INDUSTRIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: INDUSTRIES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Delete Industry
export const deleteIndustry = (catId, showSnacker, params) => async (dispatch) => {
  try {
    await api.delete(`/automation/industries/${catId}`).then((res) => {
      dispatch({
        type: REMOVE_INDUSTRY,
        payload: res.data,
      });
      dispatch(fetchIndustries(params));
      showSnacker('Industry removed!');
    });
  } catch (err) {
    dispatch({
      type: INDUSTRIES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update industries status
export const updateStatus = (catId, status, showSnacker, params) => async (
  dispatch,
) => {
  try {
    const values = { status: status };
    await api
      .put(`/automation/industries/update-status/${catId}`, { values })
      .then((res) => {
        dispatch({
          type: CHANGE_INDUSTRY_STATUS,
          payload: res.data,
        });
        dispatch(fetchIndustries(params));
        showSnacker('Industry status updated!');
      });
  } catch (err) {
    dispatch({
      type: INDUSTRIES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
