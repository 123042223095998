import React, { useState } from 'react';

import CustomDrawer from '../../../components/CustomDrawer';
import DrawerFormHeader from '../../../components/DrawerFormHeader';
import AddSkillsForm from '../AddSkillsForm';
import CustomSnackBar from '../../../components/CustomSnackBar';
/**
 * Component to open Entity drawer/slider popup
 * @param {*} props
 * @returns
 */
const AddSkillsDrawer = (props) => {
  const { openDrawer, onClose, skillId } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [updated, setUpdated] = useState(false);

  /**
   * Handle function to close the drawer popup
   * @param {*} data
   */
  const handleClose = (data) => {
    if (data && data?.skillAdded) {
      setOpenSnackBar(true);
    }
    if (data && data?.skillUpdated) {
      setOpenSnackBar(true);
      setUpdated(true);
    }
    onClose();
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={skillId ? 'Edit Skill' : 'Create Skill'}
        >
          <AddSkillsForm
            openDrawer={openSnackBar}
            closeDrawer={handleClose}
            skillId={skillId}
          />
        </DrawerFormHeader>
      </CustomDrawer>
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        handleSnackbarClose={handleSnackbarClose}
        barMessage={updated ? `Skill updated!` : `New skill added!`}
        severity={'success'}
      />
    </div>
  );
};

export default AddSkillsDrawer;
