import api from '../../utils/api';

import {
  LOAD_CATEGORIES,
  CATEGORIES_ERROR,
  CHANGE_CATEGORY_STATUS,
  REMOVE_CATEGORY,
} from '../types';

// Get categories list and set in reducer
export const fetchCategories = (params = {}) => async (dispatch) => {
  try {
    const res = await api.get('/automation/categories', { params });

    dispatch({
      type: LOAD_CATEGORIES,
      payload: { ...res.data, rowsPerPage: params.rowsPerPage },
    });
  } catch (err) {
    dispatch({
      type: CATEGORIES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Delete Category
export const deleteCategory = (catId, showSnacker, params) => async (dispatch) => {
  try {
    await api.delete(`/automation/categories/${catId}`).then((res) => {
      dispatch({
        type: REMOVE_CATEGORY,
        payload: res.data,
      });
      dispatch(fetchCategories(params));
      showSnacker('Category removed!');
    });
  } catch (err) {
    dispatch({
      type: CATEGORIES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update cateogries status
export const updateStatus = (catId, status, showSnacker, params) => async (
  dispatch,
) => {
  try {
    const values = { status: status };
    await api
      .put(`/automation/categories/update-status/${catId}`, { values })
      .then((res) => {
        dispatch({
          type: CHANGE_CATEGORY_STATUS,
          payload: res.data,
        });
        dispatch(fetchCategories(params));
        showSnacker('Category status updated!');
      });
  } catch (err) {
    dispatch({
      type: CATEGORIES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
