import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import PageHeader from '../../components/PageHeader';
import CustomDataGrid from '../../controlled-component/customGridComponent';
import AddButton from '../../components/CreateToolbarButton';
import AddEntityDrawer from './AddEntityDrawer';

import { fetchEntities, deleteEntity } from '../../actions/admin/entity';
import LoadingTableBody from '../../components/LoadingTableBody';

// template styling
const useStyles = makeStyles(() => ({
  templateGridMainSection: {},
  OfficeGridWrapper: {
    padding: '10px 0 20px 0',
  },
}));

const EntityGrid = (props) => {
  const classes = useStyles();

  const [popupToggle, setPopupToggle] = useState(false);
  //const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [entityId, setEntityId] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const entity = useSelector((state) => state.entity);

  //handle function to open add entity popup
  const handlePopupOpen = () => {
    setEntityId(false);
    setPopupToggle(!popupToggle);
  };

  //handle function to close add entity popup
  const handlePopupClose = () => {
    setPopupToggle(false);
    setEntityId(false);
  };

  // handle the edit entity pop up
  const handleEditPopupOpen = (id) => {
    /* setEditPopupToggle(!editPopupToggle); */
    setPopupToggle(!popupToggle);
    setEntityId(id);
  };

  // handle function to remove entity
  const removeEntity = (entityId) => {
    dispatch(deleteEntity(entityId, showSnackbar));
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };
  /**
   * Handle function to close the toaster popup
   */
  const showSnackbar = () => {
    setOpenSnackBar(true);
  };

  useEffect(() => {
    // did mount
    // call the entity api and set data in reducer
    dispatch(fetchEntities());
  }, []);

  const handleNameClick = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    handleEditPopupOpen(data.id);
  };

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Entity Name',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: '85%', width: '85%', maxWidth: '85%' },
          }),
          setCellProps: () => ({
            style: { minWidth: '85%', width: '85%', maxWidth: '85%' },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = entity.entities[dataIndex];
            return (
              <Tooltip title={rData.name || ''} arrow>
                <div
                  className={`sort-by td-content-ellipsis td-anchor-content-ellipsis`}
                  onClick={(e) => handleNameClick(e, rData)}
                >
                  {rData.name}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Users',
        name: 'totalUsers',
        options: {
          filter: false,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 150, maxWidth: 150 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = entity.entities.find((tData) => tData.id === value);
            return (
              <GridActions
                temp_id={row?.id}
                handleEditPopupOpen={handleEditPopupOpen}
                status={row?.status}
                row={row}
                removeEntity={removeEntity}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [entity.entities],
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={entity.loading} {...props} />,
    [entity.loading],
  );

  return (
    <div className={classes.templateGridMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Entities'}
        subTitle={'Create and edit entities against your account.'}
        itemCount={entity?.entities?.length || 0}
      />
      <div className={classes.OfficeGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns}
          data={entity.entities}
          options={{
            pagination: Boolean(entity?.entities?.length),
            selectableRows: 'multiple',
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search Entity',
            selectToolbarPlacement: 'none',
            searchOpen: true,
            search: true,
            customToolbar: () => {
              // Toolbar for search and add
              return (
                <AddButton
                  handleClick={handlePopupOpen}
                  title={`Create Entity`}
                  dataTarget={`createEntity`}
                />
              );
            },
          }}
          components={{ TableBody: BodyComponent }}
        />

        <AddEntityDrawer
          entityId={entityId}
          openDrawer={popupToggle}
          onClose={handlePopupClose}
        />
        <Snackbar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleSnackbarClose}
            severity="success"
          >
            Entity removed!
          </Alert>
        </Snackbar>
        {/* <ToastContainer autoClose={2000} /> */}
      </div>
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const { auth, row } = props;
  const { user } = auth;
  const admin = props.admin ? props.admin : false;
  const [anchorElement, setAnchorElement] = useState(null);

  //const editable = user.id === row.created_by;
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      case 'removeEntity': {
        props.removeEntity(props.temp_id);
        break;
      }
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: 'Edit',
      itemType: 'editPopUp',
    },
    /* {
      title: 'Remove entity',
      itemType: 'removeEntity',
    }, */
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default EntityGrid;
