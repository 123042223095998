import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NewTemplateGrid from './NewTemplateGrid';

// class Mytemplate extends Component {
//   constructor(props) {
//     super(props);
//   }
//
//   render() {
//     return (
//       <div className="">
//         <div className="flex-row">
//           <aside className="sideBarMargin">
//             <SidebarNav jobspec="active" />
//           </aside>
//
//           <div className="flex-col content">
//             <NewTemplateGrid {...this.props} />
//             {/* <ToastContainer autoClose={2000} /> */}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

/**
 * It is main component for Spec job template in user profile section
 */
const Mytemplate = (props) => {
  /**
   * Ii will load the left and right section section component
   * @returns
   */
  return <NewTemplateGrid {...props} />;
};

/**
 * Required props
 */
Mytemplate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Mytemplate);
