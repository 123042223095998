import { makeStyles } from "@material-ui/core/styles";

export { default as overviewIcon } from "../../../../img/icons/OverviewIcon.svg";
export { default as rocketIcon } from "../../../../img/icons/RocketLaunchFilled.svg";
export { default as setupIcon } from "../../../../img/icons/SetupIcon.svg";

export const useStyles = makeStyles((theme) => ({
  onboardingRoot: {
    width: "100%",
    paddingTop: 240,
  },
}));
