import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, FormHelperText } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Spinner from '../../../layout/Spinner';
import CustomSnackBar from '../../../components/CustomSnackBar';
import { saveTwilioIntegrations } from '../../../actions/admin/integration';

import api from '../../../utils/api';
import { english } from '../../../utils/language';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formHeading: {
    fontSize: 20,
    fontWeight: 500,
    paddingBottom: 10,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    justifyContent: 'space-between',
    zIndex: 1,
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
}));

/**
 * Component for Twilio Integration form
 * @param {*} props
 * @returns
 */
const TwilioIntegrationForm = (props) => {
  const classes = useStyles();

  //Local state variables
  const [formData, setFormData] = useState({});
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [openErrorSnackBarMsg, setOpenErrorSnackBarMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const dispatch = useDispatch();

  /**
   * Did mount to get integration twilio details for integration
   */
  useEffect(() => {}, []);

  /**
   * Handle function of Integration Twilio form
   * It call server API to save data into tables
   */
  const handleSubmit = () => {
    if (disabledNextButton() === false) {
      setDisabledButton(true);
      const values = formData;
      //console.log(values)
      dispatch(saveTwilioIntegrations(values, props?.closeDrawer));
      //props?.closeDrawer({ data: 'twilioIntegrated' });
      setTimeout(() => {
        setDisabledButton(false);
      }, 10);
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['account_sid', 'auth_token'];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @param key
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    console.log('---event, tag---', event, tag);
  };

  /**
   * function to handle the form element change event
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to close toaster after server side error
   */
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackBar(false);
  };

  // show loader
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  /**
   * Return function to render UI elements
   */
  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> {english.twilioSubTitle} </h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*Account SID*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label={`${english.twilioIntegrationForm.accountSid} *`}
                  onChange={handleChange}
                  name="account_sid"
                  value={formData?.account_sid || ''}
                  fullWidth
                  variant="outlined"
                  validators={['required']}
                  errorMessages={[
                    english.twilioIntegrationForm.accountSidRequire,
                  ]}
                />
                <FormHelperText>{english.accountSIDInfo}</FormHelperText>
              </Grid>

              {/*Auth Token*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Auth Token *"
                  onChange={handleChange}
                  name="auth_token"
                  value={formData?.auth_token || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter auth token']}
                />
                <FormHelperText>{english.authTokenInfo}</FormHelperText>
              </Grid>

              {/*Mobile Number*/}
              {/*<Grid item xs={12} sm={12}>*/}
              {/*  <FormAutoCompleteSelect*/}
              {/*    onChange={handleSelectChange('mobile_number', 'key')}*/}
              {/*    error={'true'}*/}
              {/*    multiple*/}
              {/*    freeSolo*/}
              {/*    name="mobile_number"*/}
              {/*    id="mobile_number"*/}
              {/*    disableClearable*/}
              {/*    options={[]}*/}
              {/*    getOptionLabel={(option) => option}*/}
              {/*    renderInput={(params) => (*/}
              {/*      <TextValidator*/}
              {/*        {...params}*/}
              {/*        label="Mobile Number *"*/}
              {/*        onChange={handleChange}*/}
              {/*        name="mobileNumber"*/}
              {/*        value={formData?.mobileNumber || ''}*/}
              {/*        fullWidth*/}
              {/*        validators={['required', `matchRegexp:^[+][0-9]*$`, `matchRegexp:^.{12,}$`]}*/}
              {/*        errorMessages={[*/}
              {/*          'Please enter mobile number',*/}
              {/*          'Please enter number with country code',*/}
              {/*          'Min length of the mobile number should be 12',*/}
              {/*        ]}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  />*/}
              {/*  <FormHelperText>Please add mobile number with country code example +1XXXXXXXXXX</FormHelperText>*/}
              {/*  */}
              {/*</Grid>*/}
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledNextButton()}
            >
              {english.save}
            </Button>
          </div>
        </div>
      </ValidatorForm>

      <CustomSnackBar
        open={openErrorSnackBar}
        autoHideDuration={2000}
        onClose={handleErrorSnackbarClose}
        severity={'error'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </div>
  );
};

export default TwilioIntegrationForm;
