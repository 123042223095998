import api from '../../utils/api';

import {
  LOAD_ENTITIES,
  ENTITIES_ERROR,
  CHANGE_ENTITY_STATUS,
  LOAD_ENTITIES_DROPDOWNS,
} from '../types';

// Get entities list and set in reducer
export const fetchEntities = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/entity');

    dispatch({
      type: LOAD_ENTITIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ENTITIES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/**
 * Fetch entity dropdown options and set in reducer
 * @returns
 */
export const fetchDropdownOptions = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/get-country-timezone');

    dispatch({
      type: LOAD_ENTITIES_DROPDOWNS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ENTITIES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Delete entity
export const deleteEntity = (entiyId, showSnacker) => async (dispatch) => {
  try {
    await api.delete(`/automation/entity/${entiyId}`).then((res) => {
      dispatch({
        type: CHANGE_ENTITY_STATUS,
        payload: res.data,
      });
      //onHide('addSequence');
      dispatch(fetchEntities());
      showSnacker();
    });
  } catch (err) {
    dispatch({
      type: ENTITIES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
