import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  mainButton: {
    paddingTop: 4,
    paddingBottom: 4,
    boxShadow: 'unset',
    border: `2px solid  ${custom.colorCode.blueLightShade8}`,
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: custom.colorCode.blueLightShade11,
    },
    '&:focus': {
      outline: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      border: 'none',
    },
  },
  mainButtonLabel: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  mainOutlinedButton: {
    padding: '5px 15px',
    boxShadow: 'unset',
    border: `2px solid  ${custom.colorCode.blueLightShade8}`,
    color: custom.colorCode.blueLightShade8,
    backgroundColor: custom.colorCode.transparent,
    '&:hover': {
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      border: 'none',
    },
  },
  iconButton: {
    '&:hover': {
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      border: 'none',
    },
  },
  mainOutlinedButtonLabel: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
}));

export default useStyles;
