import { GET_CANDIDATES_LOOKUP } from '../actions/types';

const initialState = {
  lookupList: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CANDIDATES_LOOKUP: {
      const data = {
        ...state,
        lookupList: payload,
        loading: false,
      };
      return data;
    }
    default:
      return state;
  }
}
