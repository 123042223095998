import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Alert from '@material-ui/lab/Alert';

import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import Spinner from '../../../layout/Spinner';

import { fetchUsersTeams } from '../../../actions/users';
import { fetchTeams } from '../../../actions/admin/teams';
import api from '../../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formHeading: {
    fontSize: 20,
    fontWeight: 500,
    paddingBottom: 10,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
  },
  formCheckbox: {
    '&.Mui-checked': {
      color: custom.colorCode.lightRedColor,
    },
    color: custom.colorCode.lightRedColor,
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
}));

/**
 * Component for team form
 * @param {*} props
 * @returns
 */
const AddTeamForm = (props) => {
  const { teamId } = props;
  const classes = useStyles();
  //Local state variables
  const [formData, setFormData] = useState({});
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [openErrorSnackBarMsg, setOpenErrorSnackBarMsg] = useState('');
  const [parentTeamSelected, setParentTeamSelected] = useState([]);
  const [teamLeadSelected, setTeamLeadSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //Code to subscribe users and teams data from reducer
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users || {});
  const teams = useSelector((state) => state.users.teams || {});

  /**
   * Did mount fuction to set users and teams data in reducer
   */
  useEffect(() => {
    dispatch(fetchUsersTeams());
  }, [fetchUsersTeams]);

  /**
   * Did mount fuction to get team details for edit popup
   */
  useEffect(() => {
    if (teamId) {
      setIsLoading(true);
      api
        .get(`/automation/teams/${teamId}`)
        .then((res) => {
          //console.log(res.data);
          setIsLoading(false);
          setFormData({
            description: res.data.description,
            parent_id: res.data.parent_id,
            team_name: res.data.team_name,
            team_lead: res.data.team_lead_id,
            status: res.data.status,
          });
          setParentTeamSelected({
            id: res.data.parent_id,
            name: res.data.parent_team_name,
          });
          setTeamLeadSelected({
            company_user_id: res.data.team_lead_id,
            name: res.data.team_lead_name,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          setOpenErrorSnackBar(true);
          setOpenErrorSnackBarMsg(
            error.response?.data?.error || 'Something went wrong',
          );
        });
    }
  }, [teamId]);

  /**
   * Handle function of create/update team form
   * It call server API to save data into tables
   */
  const handleSubmit = () => {
    //if team id exist then update API will call other add API will call
    if (teamId) {
      api
        .put(`/automation/teams/${teamId}`, formData)
        .then((res) => {
          if (res.status === 200) {
            props?.closeDrawer({ teamUpdated: true });
          }
          dispatch(fetchTeams());
        })
        .catch((error) => {
          setOpenErrorSnackBar(true);
          setOpenErrorSnackBarMsg(
            error.response?.data?.error || 'Something went wrong',
          );
          //console.log(error, error.response.data.error);
        });
    } else {
      api
        .post('/automation/teams', formData)
        .then((res) => {
          if (res.status === 201) {
            props?.closeDrawer({ teamAdded: true });
          }
          dispatch(fetchTeams());
        })
        .catch((error) => {
          setOpenErrorSnackBar(true);
          setOpenErrorSnackBarMsg(
            error.response?.data?.error || 'Something went wrong',
          );
          //console.log(error, error.response.data.error);
        });
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['team_name', 'team_lead', 'parent_id', 'status'];
    return validateData(formKeys);
  };

  /**
   * function to handle the form element change event
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name) => (event, tag) => {
    setFormData({
      ...formData,
      [name]: name === 'team_lead' ? tag.company_user_id : tag.id,
    });
  };

  /**
   * function to close toaster after server side error
   */
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackBar(false);
  };

  // show loader while getting data
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  /**
   * Return function to reder UI elements
   */
  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> Team Details </h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*Team Name*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label="Team Name *"
                  onChange={handleChange}
                  name="team_name"
                  value={formData?.team_name || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter team name']}
                />
              </Grid>
              {/*Team Lead*/}
              <Grid item xs={12} sm={6} className="pl-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('team_lead')}
                  name="team_lead"
                  id="team_lead"
                  key={'team_lead'}
                  disableClearable
                  options={[...(users || [])]}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionSelected={(option, value) =>
                    option.company_user_id == value.company_user_id
                  }
                  value={
                    formData.team_lead
                      ? users.find(
                          (c) => c.company_user_id === formData?.team_lead,
                        ) || ''
                      : ''
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Team Lead *"
                      className={classes.selectBoxStyle}
                      name="team_lead"
                      value={formData?.team_lead || ''}
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Select Team Lead']}
                    />
                  )}
                />
              </Grid>

              {/*Parent Team*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('parent_id')}
                  name="parent_id"
                  id="parent_id"
                  key={'parent_id'}
                  disableClearable
                  options={[...(teams || [])]}
                  getOptionLabel={(option) => option.name || ''}
                  getOptionSelected={(option, value) => option.id == value.id}
                  value={
                    formData.parent_id
                      ? teams.find((c) => c.id === formData?.parent_id) || ''
                      : ''
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Parent Team *"
                      className={classes.selectBoxStyle}
                      name="parent_id"
                      value={formData?.parent_id || ''}
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Select parent team']}
                    />
                  )}
                />
              </Grid>
              {/*Status*/}
              <Grid item xs={12} sm={6} className="pl-3">
                <TextValidator
                  label="Status *"
                  onChange={handleChange}
                  name="status"
                  select
                  value={`${formData?.status}` || '0'}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Select status']}
                >
                  <MenuItem value={'1'}>Active</MenuItem>
                  <MenuItem value={'0'}>In Active</MenuItem>
                </TextValidator>
              </Grid>

              {/*desc*/}
              <Grid item xs={12} sm={12} className="pr-3">
                <TextValidator
                  label="Description"
                  onChange={handleChange}
                  name="description"
                  value={formData?.description || ''}
                  variant="outlined"
                  fullWidth
                  multiline
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />

          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledNextButton()}
            >
              {teamId ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </ValidatorForm>
      <Snackbar
        open={openErrorSnackBar}
        autoHideDuration={2000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleErrorSnackbarClose}
          severity="error"
        >
          {openErrorSnackBarMsg || ''}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddTeamForm;
