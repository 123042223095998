import React, { useRef } from 'react';
import { differenceWith, isArray, isEqual } from 'lodash';
import { TextField } from '@material-ui/core';

import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';

const FilterAutoCompleteSelect = (props, ref) => {
  const { mFilter, handleDropDownChange, selectedRecord } = props;
  const autoCompleteRef = useRef();
  const handleChange = (filter) => (event, tag) => {
    handleDropDownChange(filter)(event, tag);
  };

  return (
    <FormAutoCompleteSelect
      onChange={handleChange(mFilter)}
      name={mFilter.title}
      id={mFilter.type}
      key={mFilter.type}
      disableClearable
      options={differenceWith(
        mFilter.data,
        isArray(selectedRecord[mFilter.type])
          ? selectedRecord[mFilter.type]
          : [selectedRecord[mFilter.type]],
        isEqual,
      )}
      ref={autoCompleteRef}
      size={'small'}
      getOptionLabel={(option) => option.name || ''}
      value={mFilter.isSingle ? selectedRecord[mFilter.type] || '' : []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Select ${mFilter.title}`}
          name={mFilter.type}
          fullWidth
        />
      )}
    />
  );
};

export default React.forwardRef(FilterAutoCompleteSelect);
