import React from 'react';
import {
  MUIDataTableBody,
  TableBody,
  TableBodyCell,
  TableBodyRow,
} from 'mui-datatables';
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Typography,
  TableBody as MuiTableBody,
} from '@material-ui/core';

/* interface Props extends MUIDataTableBody {
  loading: boolean;
} */

const useStyles = makeStyles(({ custom }) =>
  createStyles({
    loading: {
      textAlign: 'center',
      /* height: '100px !improtant', */
      padding: '1.5rem',
    },
    loader: {
      color: custom.colorCode.blueLightShade8,
      backgroundColor: custom.colorCode.lightRedwhiteColor,
    },
  }),
);

const LoadingTableBody = (props) => {
  const { loading, options, columns, ...others } = props;
  // @ts-ignore
  const visibleColCnt = columns.filter((c) => c.display === 'true').length;
  const classes = useStyles();
  return loading ? (
    <MuiTableBody>
      <TableBodyRow options={options}>
        <TableBodyCell
          // @ts-ignore
          colSpan={
            options.selectableRows !== 'none' || options.expandableRows
              ? visibleColCnt + 1
              : visibleColCnt
          }
          options={options}
          colIndex={0}
          rowIndex={0}
        >
          <Typography
            variant="body1"
            className={classes.loading}
            component={'div'}
          >
            <CircularProgress className={classes.loader} />
          </Typography>
        </TableBodyCell>
      </TableBodyRow>
    </MuiTableBody>
  ) : (
    <TableBody options={options} columns={columns} {...others} />
  );
};

export default LoadingTableBody;
