import api from './api';

const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['x-auth-token'] = token;
    api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);
  } else {
    delete api.defaults.headers.common['x-auth-token'];
    delete api.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
    localStorage.removeItem('tenantHash');
    //localStorage.removeItem('user');
  }
};

export const setTenantHash = (tenantHash) => {
  if (tenantHash) {
    api.defaults.headers.common['x-tenant-hash'] = tenantHash;
  } else {
    // delete api.defaults.headers.common['tenantHash'];
    delete api.defaults.headers.common['x-tenant-hash'];
  }
};

export default setAuthToken;
