import React from 'react';

const allowedTypes = (type) => {
  let types = [];
  switch (type) {
  case 'ATTACHMENT':
    types = [
      'image/png',
      'image/jpeg',
      'image/gif',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
      'text/plain',
      'text/rtf',
      'application/octet-stream',
      'application/vnd.oasis.opendocument.text',
    ];
    break;
  case 'IMAGE':
    types = [
      'image/png',
      'image/jpeg',
      'image/gif',
    ];
    break;
  case 'DOC':
    types = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
      'text/plain',
      'text/rtf',
      'application/octet-stream',
      'application/vnd.oasis.opendocument.text',
    ];
    break;

  default:
    types = [
      'image/png',
      'image/jpeg',
      'image/gif',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
      'text/plain',
      'text/rtf',
      'application/octet-stream',
      'application/vnd.oasis.opendocument.text',
    ];
  }
  return types;
};

export default { allowedTypes };
