import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';

import api from '../../utils/api';

import CustomSnackBar from '../../components/CustomSnackBar';

const useStyles = makeStyles(() => ({
  unsubscribeWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: '#FFFFFF',
  },
  unsubscribeContainer: {
    padding: '150px 0',
    display: 'block',
    justifyContent: 'center',
  },
  warningText: {
    color: '#3c3c3c',
    fontWeight: '400',
    display: 'flex',
    justifyContent: 'center',
  },
  mailAddressContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  mailAddressWarning: {
    fontWeight: '400',
    color: '#3c3c3c',
    justifyContent: 'center',
    paddingRight: '5px',
  },
  mailAddress: {
    fontWeight: '400',
    justifyContent: 'center',
  },
  confirmButton: {
    '&:hover': {
      border: `none`,
    },
    '&:focus': {
      outline: `none`,
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
}));

const Unsubscribe = (props) => {
  const { match } = props;
  const [mailId, setMailId] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [buttonHide, setButtonHide] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isUnsubscribe, setIsUnsubscribe] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState({});

  useEffect(() => {
    try {
      setMailId(atob(match.params.id).split('##'));
    } catch (err) {
      setMailId([]);
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        status: 'error',
        message: 'Something went wrong',
      });
    }
  }, []);

  useEffect(() => {
    if ((mailId.length || []) !== 2) {
      setButtonHide(true);
    } else {
      setButtonHide(false);
    }
  },[mailId]);

  const submitConfirmButton = () => {
    setDisableBtn(true);
    const payload = {
      id: match.params.id,
    };
    api
      .post(`/automation/unsubscribe`, payload)
      .then((res) => {
        if(!res.data.message) {
          setIsUnsubscribe(true);
          setOpenSnackBar(true);
          setOpenSnackBarMessage({
            message: 'Unsubscribe successfully',
          });
          /* setTimeout(() => {
            props.history.push('/login');
          },2000); */
        } else {
          setOpenSnackBar(true);
          setOpenSnackBarMessage({
            status: 'error',
            message: res.data.message || 'Something went wrong',
          });
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          status: 'error',
          message: err?.error || 'Something went wrong',
        });
        setDisableBtn(false);
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage({});
  };

  const classes = useStyles();
  return (
    <Box className={classes.unsubscribeWrapper}>
      {isUnsubscribe ? (
        <Box className={classes.unsubscribeContainer}>
          <Typography variant="h5" className={classes.warningText} style={{color: 'green', paddingTop: '20px'}}>
            You are unsubscribed successfully!
          </Typography>
        </Box>
      ) : (
      <Box className={classes.unsubscribeContainer}>
        <Typography variant="h5" className={classes.warningText}>
          Are you sure would like to unsubscribe?
        </Typography>
        <Box className={classes.mailAddressContainer}>
          <Typography variant="h6" className={classes.mailAddressWarning}>
            with the following email address:
          </Typography>
          <Typography variant="h6" className={classes.mailAddress}>
            {mailId[1] || ''}
          </Typography>
        </Box>
        {!buttonHide && (
          <Box className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={submitConfirmButton}
              disabled={disableBtn}
              className={classes.confirmButton}
              type="submit"
            >
              Unsubscribe
            </Button>
          </Box>
        )}
      </Box>
      )}

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={2000}
        handleSnackbarClose={handleSnackbarClose}
        barMessage={openSnackBarMessage.message || ''}
        severity={openSnackBarMessage.status || 'success'}
      />
    </Box>
  );
};

export default Unsubscribe;
