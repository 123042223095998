import api from '../../utils/api';
import { isEmpty } from 'lodash';

import {
  LOAD_ACTIVITY_REPORT,
  ACTIVITY_REPORT_ERROR,
  SET_ACTIVITY_REPORT_FILTER_DATA,
  SHOW_LOAD_ACTIVITY_REPORT_LOADER,
  LOAD_ACTIVITY_REPORT_TABS,
  SET_ACTIVITY_REPORT_CURRENT_TAB,
} from '../types';

/**
 * Get and create url for fetching data
 */
const getAndMakeUrl = (tabData, filterData, gridParams, gridColParams) => {
  let url;
  if (!filterData) {
    url = `/automation/activity-report?object=activity_report&tab=${tabData.id}`;
  } else {
    if (isEmpty(gridColParams)) {
      url = `/automation/activity-report?object=activity_report&tab=${
          tabData.id
        }&filters=${JSON.stringify(filterData)}&gridParams=${JSON.stringify(
          gridParams
        )}`;
      } else {
        console.log('here in else part');
        url = `/automation/activity-report?object=activity_report&tab=${
            tabData.id
          }&filters=${JSON.stringify(filterData)}&gridParams=${JSON.stringify(
            gridParams
          )}&columns=${JSON.stringify(gridColParams)}`;
      }
}
 return url;
}

// Get activity reports
export const fetchActivityReportData = (
  filterData,
  gridParams,
  tabData,
  gridColParams,
  showLoader = true
) => async (dispatch) => {
  let url = getAndMakeUrl(tabData, filterData, gridParams, gridColParams);
  try {
    if (showLoader) {
      dispatch({
        type: SHOW_LOAD_ACTIVITY_REPORT_LOADER,
        payload: { tabData },
      });
    }

    let res = await api.get(url);
    if (!filterData) {
    dispatch({
        type: LOAD_ACTIVITY_REPORT,
      payload: {
        [tabData.id]: {
          activityReportList: res.data.report,
          count: res.data.counts,
          filterState: isEmpty(res.data.filters)
            ? {}
            : { ...res.data.filters },
          defaultFilterState: isEmpty(res.data.filters)
            ? {}
            : { ...res.data.filters },
          gridParams: isEmpty(res.data.grid_params)
            ? gridParams
            : { ...res.data.grid_params },
          columns: isEmpty(res.data.columns)
            ? {}
            : JSON.parse(res.data.columns),
          loading: false,
        },
      },
    });
    } else {
      dispatch({
        type: LOAD_ACTIVITY_REPORT,
        payload: {
          [tabData.id]: {
            activityReportList: res.data.report,
            count: res.data.counts,
            filterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            gridParams: isEmpty(res.data.grid_params)
              ? gridParams
              : { ...res.data.grid_params },
            columns: isEmpty(res.data.columns)
              ? {}
              : JSON.parse(res.data.columns),
            loading: false,
          },
        },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_REPORT_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get default filters for activity reports
export const fetchGridFilters = (tabData) => async (dispatch) => {
  try {
    const res = await api.get('/automation/get-activity-report-filter');

    let filterListData = Object.keys(res.data);
    dispatch({
      type: SET_ACTIVITY_REPORT_FILTER_DATA,
      payload: {
        id: tabData.id,
        [tabData.id]: {
          myFilterData: [...filterListData],
          filterList: res.data,
        },
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

//Get tab for activity_report object if any
export const fetchTabs = () => async (dispatch) => {
  try {
    const res = await api.get(
      'automation/tabs/get-tabs?object=activity_report'
    );

    res.data = res.data.map((e) => ({
      ...e,
      url: e.tab_type ? e.name.toLocaleLowerCase().replace(/ /g, '-') : e.id,
    }));

    dispatch({
      type: LOAD_ACTIVITY_REPORT_TABS,
      payload: {
        tabs: res.data,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

// Set current tab for activity report if any
export const setCurrentTab = (currentTab) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ACTIVITY_REPORT_CURRENT_TAB,
      payload: {
        currentTab,
      },
    });
  } catch (err) {
    return err;
  }
};
