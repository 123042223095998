import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SidebarNav from '../../includes/NewSidebarNav';
import SequenceDetailPage from './DetailPage';

/* const SequenceDetail = (props) => {
  return (
    <div className="">
      <div className="flex-row">
        <aside className="sideBarMargin">
          <SidebarNav sequences="active" />
        </aside>

        <div className="flex-col content">
          <SequenceDetailPage {...props} />
        </div>
      </div>
    </div>
  );
}; */

const SequenceDetail = (props) => {
  return <SequenceDetailPage {...props} />;
};

SequenceDetail.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SequenceDetail);
