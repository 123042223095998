import {
  LOAD_SKILLS,
  SKILLS_ERROR,
  ADD_SKILL,
  REMOVE_SKILL,
} from '../../actions/types';

const initialState = {
  skills: [],
  skill_id: null,
  dropdownList: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_SKILLS: {
      const data = {
        ...state,
        skills: payload,
        loading: false,
      };
      return data;
    }

    case SKILLS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
