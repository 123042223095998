const getParentNode = (array, link, parent) => {
  return array.some(
    (o) =>
      o.link === link ||
      (o.child && (parent = getParentNode(o.child, link, o)) !== null),
  )
    ? parent
    : null;
};

export default getParentNode;
