import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Checkbox from '../../components/Checkbox';
import {
  getUserNotifications,
  updateNotificationFilter,
} from '../../actions/notification';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const NotificationFilter = (props) => {
  // const [state, setState] = useState({});
  // const [formdata, setFormdata] = useState({});
  const [selected, setSelected] = useState(props.notification.filters);
  //console.log(props.notification.filters);
  const options = [
    { value: '1', label: 'Email Sent' },
    { value: '2', label: 'Task Added' },
    { value: '3', label: 'CV Spec Opened' },
    { value: '4', label: 'Email Delivered' },
    { value: '5', label: 'Email Opened' },
    { value: '6', label: 'Email Clicked' },
    { value: '7', label: 'Email Bounced' },
  ];

  const handleChange = (event) => {
    /* setState({ ...state, [event.target.name]: event.target.checked});
        //console.log(event);
        if(event.target.checked){
            setFormdata({...formdata ,[event.target.name]: event.target.value});
            //setSelected(event.target.value);
        }else{
            delete formdata[event.target.name];
            //delete selected.event.target.value;
        } */
    const { value } = event.target;
    setSelected(
      selected.includes(value)
        ? selected.filter((c) => c !== value)
        : [...selected, value],
    );
  };

  const createCheckbox = (item, selected) => {
    let checked = selected.includes(item.value) ? true : null;
    return (
      <div key={item.value}>
        {/* <Checkbox key={item.value} checked={checked} value={item.value} 
        onChange={handleChange} name={item.label.split(' ').join('_')} color="primary" /> */}
        <FormControlLabel
          key={item.value}
          control={
            <Checkbox
              key={item.value}
              checked={checked}
              value={item.value}
              handleCheckboxChange={handleChange}
              name={item.label.split(' ').join('_')}
              label={item.label}
              color="primary"
            />
          }
          label={item.label}
        />
      </div>
    );
  };

  const createCheckboxes = (selected) =>
    options.map((item) => createCheckbox(item, selected));

  const handleFormSubmit = () => {
    //formSubmitEvent.preventDefault();
    //console.log(selected);

    props.updateNotificationFilter(selected, props.onHide);
  };

  useEffect(() => {
    if (props.notification.filters.length > 0) {
      setSelected(props.notification.filters);
    }
  }, [props.notification.filters]);

  return (
    <Modal
      show={props.modalShow}
      contentClassName="modalContentCls"
      dialogClassName="notification-filter-modal"
      animation={false}
    >
      <Modal.Header className="modalHeaderCls" closeButton={false}>
        <div>
          <Modal.Title className="modalTitle">Notification Filter</Modal.Title>
          <span className="closeModalIconCls" onClick={() => props.onHide()}>
            <i className="fa fa-close"></i>
          </span>
        </div>
      </Modal.Header>
      <Modal.Body className="notificationModalBody">
        <Row>
          <Col sm={12} md={12} lg={12}>
            <label>
              Checkbox to select which notification you want to see:
            </label>
            {createCheckboxes(props.notification.filters)}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="modalFooterCls">
        {/* <Button className="selectedBtn">Reset</Button> */}
        <Button className="unSelectedBtn" onClick={() => handleFormSubmit()}>
          Search
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

NotificationFilter.propTypes = {
  auth: PropTypes.object.isRequired,
  getUserNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  notification: state.notification,
});
const mapDispatchToProps = {
  getUserNotifications,
  updateNotificationFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationFilter);
