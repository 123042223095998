import {
  LOAD_INDUSTRIES,
  INDUSTRIES_ERROR,
  CHANGE_INDUSTRY_STATUS,
  REMOVE_INDUSTRY,
} from '../../actions/types';

const initialState = {
  industries: [],
  industry_id: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_INDUSTRIES: {
      const data = {
        ...state,
        industries: payload,
        loading: false,
      };
      return data;
    }

    case INDUSTRIES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
