import {
  LOAD_ADMIN_JOB_ROLES,
  JOB_ROLES_ERROR,
  LOAD_ADMIN_OFFICES_CATEGORY,
} from '../../actions/types';

// initialize the admin job roles
const initialState = {
  jobRolesList: {},
  categoryList: [],
  skillsList: [],
  jobRoles_id: null,
  loading: true,
  error: {},
};

// job roles reducer
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ADMIN_JOB_ROLES: {
      return {
        ...state,
        jobRolesList: payload,
        loading: false,
      };
    }

    case JOB_ROLES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case LOAD_ADMIN_OFFICES_CATEGORY:
      return { ...state, ...payload };

    default:
      return state;
  }
}
