import React, { useState } from 'react';

import Drawer from '../../../components/CustomDrawer';
import DrawerFormHeader from '../../../components/DrawerFormHeader';
import CustomSnackBar from '../../../components/CustomSnackBar';
import IntegrationForm from './IntegrationForm';

const IntegrationDrawer = (props) => {
  const { openDrawer, onClose, integrationData } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);

  /**
   * Handle function to close the drawer popup
   * @param integrationSaved to check weather integration is saved
   */
  const handleClose = (integrationSaved) => {
    if (integrationSaved === true) {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: 'Integration Added Successfully',
        status: 'success',
      });
    }
    onClose();
  };

  // this will close the snackbar
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  return (
    <div>
      <Drawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={'Add Integration Account'}
        >
          <IntegrationForm
            closeDrawer={handleClose}
            integrationData={integrationData}
          />
        </DrawerFormHeader>
      </Drawer>

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default IntegrationDrawer;
