import {
  LOAD_MY_TASK,
  MY_TASK_ERROR,
  SET_MY_TASK_FILTER_DATA,
  SHOW_MY_TASK_LOADER,
  LOAD_TASK_TABS,
  LOAD_TASK_TABS_LOADING,
  UPDATE_TASK_STATUS,
  SET_TASK_CURRENT_TAB,
} from '../../actions/types';

const initialState = {
  task_id: null,
  loading: true,
  taskTab: {
    tabs: [],
    loading: true,
  },
  currentTab: {},
  count: 0,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MY_TASK_LOADER: {
      return {
        ...state,
        [payload.tabData.id]: {
          ...state[payload.tabData.id],
          loading: true,
        },
      };
    }

    case LOAD_TASK_TABS_LOADING: {
      return {
        ...state,
        taskTab: {
          ...state.taskTab,
          loading: payload,
        },
      };
    }

    case LOAD_TASK_TABS: {
      const tabsData = {};
      payload.tabs.forEach((e) => {
        tabsData[e.id] = {
          myTaskList: [],
          myFilterData: [],
          filterList: {},
          filterState: {},
          defaultFilterState: {},
          count: 0,
          loading: true,
        };
      });

      return {
        ...state,
        taskTab: {
          ...state.taskTab,
          ...payload,
          loading: false,
        },
        ...tabsData,
      };
    }

    case LOAD_MY_TASK: {
      const id = Object.keys(payload)[0];
      return {
        ...state,
        [id]: {
          ...state[id],
          ...payload[id],
        },
        count: payload[id]?.count || 0,
      };
    }

    case SET_TASK_CURRENT_TAB: {
      return {
        ...state,
        ...payload,
      };
    }

    case SET_MY_TASK_FILTER_DATA: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload[payload.id],
        },
      };
    }

    case UPDATE_TASK_STATUS: {
      const taskUpdateIndex = state[payload.tabProps.id].myTaskList.findIndex(
        (task) => task.taskId === payload.task.taskId,
      );

      return {
        ...state,
        [payload.tabProps.id]: {
          ...state[payload.tabProps.id],
          myTaskList: [
            ...state[payload.tabProps.id].myTaskList.slice(0, taskUpdateIndex),
            {
              ...state[payload.tabProps.id].myTaskList[taskUpdateIndex],
              status:
                state[payload.tabProps.id].myTaskList[taskUpdateIndex]
                  .status === 1
                  ? 2
                  : 1,
            },
            ...state[payload.tabProps.id].myTaskList.slice(taskUpdateIndex + 1),
          ],
        },
      };
    }

    case MY_TASK_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
