import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import SelectDropDown from '../components/SelectDropDown';
// import CreateTemplate from '../templates/CreateTemplate';
import CreateTemplate from '../templates/CreateTemplate/CreateTemplate';
import JobspecTemplate from '../jobspectemplate/CreateTemplate';
import FormAutoCompleteSelect from '../components/FormAutoCompleteSelect';
import { getUserTemplate } from '../actions/template';
import { createEmailStep, updateEmailStep } from '../actions/sequence';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const useStyles = makeStyles(({ custom }) => ({
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
  autoSkillTitle: {
    fontSize: '1rem',
    paddingBottom: 10,
  },
}));

const CreateEmail = (props) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({});
  const [toggleOpen, setToggleOpen] = useState(false);
  const [toggleOpenJobSpec, setToggleOpenJobSpec] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [templateId, setTemplateId] = useState(false);
  const [eventId, setEventId] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const toggleTemplateModal = () => {
    setToggleOpen(!toggleOpen);
  };

  const toggleJobspecModal = () => {
    setToggleOpenJobSpec(!toggleOpenJobSpec);
  };

  const toggleBtnAction = (value) => {
    setToggleBtn(!toggleBtn);
    setTemplateId(value);
  };

  const templates = props.template.templates ? props.template.templates : null;
  var templateOptions = templates.map(function (temlate) {
    return { value: temlate.id, label: temlate.name };
  });

  /**
   * Did mount functionality
   */
  useEffect(() => {
    props.getUserTemplate(1, props.template_type);
    if (props.stepcontent.template_id) {
      setTemplateId({
        value: props.stepcontent.template_id,
        label: props.stepcontent.name,
      });
      const selectedEmails = props.stepcontent?.templateList?.filter(
        (e) => e.status === 1,
      );
      setFormData({
        ...formData,
        /* emails: [props.stepcontent.template_id], */
        emails: selectedEmails?.map((e) => e.id),
        /* [name]: tag[key], */
      });
      setEventId(props.stepcontent.id);
    }
  }, [
    props.getUserTemplate,
    props.template_type,
    props.stepcontent.template_id,
  ]);

  /* const addEmail = (value) => {
    //console.log(value);
    let steps = {
      step_type: props.step_type ? props.step_type : 1,
      step_event: props.step_event ? props.step_event : 'email',
    };
    if (eventId) {
      props.updateEmailStep(value, props.sid, eventId, props.closeModal, steps);
    } else {
      props.createEmailStep(
        value,
        props.sid,
        props.closeModal,
        props.eventorder,
        steps,
      );
    }
    //props.closeModal();
  }; */

  /**
   * function to handle the select element change event
   * @param {*} name
   * @param key
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    if (name === 'emails') {
      return setFormData({
        ...formData,
        [name]: tag.map((e) => e[key]),
        /* [name]: tag[key], */
      });
    }
    setFormData({
      ...formData,
      [name]: tag[key],
    });
  };

  /**
   * Function to set value in autocomplete
   * @param {*} val
   */
  const handleCreateTemplate = (val) => {
    if (!('emails' in formData)) {
      setFormData({
        ...formData,
        emails: [val],
      });
    } else {
      formData.emails.push(val);
    }
  };

  /**
   * Function to handle add/update button click event and call api to save data into database
   */
  const handleSubmit = () => {
    let value = templateOptions.find((e) => e.value === formData.emails[0]);
    let selectedTemplates = templateOptions.filter((c) =>
      formData?.emails.includes(c.value),
    );
    let steps = {
      step_type: props.step_type ? props.step_type : 1,
      step_event: props.step_event ? props.step_event : 'email',
      emails: formData.emails,
      selectedTemplates: selectedTemplates,
    };
    if (eventId) {
      //Update step templates into database
      props.updateEmailStep(value, props.sid, eventId, props.closeModal, steps);
    } else {
      //Save step templates into database
      props.createEmailStep(
        value,
        props.sid,
        props.closeModal,
        props.eventorder,
        steps,
      );
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = e === 'emails' ? !formData[e]?.length : !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['emails'];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disableButton;
    }
  };
  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <div className="createEmailHeadCls">
        <div className="createEmailOptionCls">
          {props.template_type == 3 ? (
            <p onClick={() => toggleJobspecModal()}>
              <i className="fa fa-plus"></i> Create new job spec template
            </p>
          ) : (
            <p onClick={() => toggleTemplateModal()}>
              <i className="fa fa-plus"></i> Create new email template
            </p>
          )}
        </div>
        <div className="emailContent">
          <h3>
            {props.title
              ? `Existing ${props.title} templates`
              : `Existing email templates`}
            {/* <span className="muted-text">(max 5 templates)</span> */}
          </h3>
          <FormAutoCompleteSelect
            multiple
            onChange={handleSelectChange('emails', 'value')}
            name="emails"
            id="emails"
            disableClearable
            options={templateOptions}
            getOptionLabel={(option) => option.label || ''}
            /* getOptionDisabled={(options) =>
              formData?.emails?.length >= 5 ? true : false
            } */
            value={
              formData?.emails?.length
                ? (templateOptions || []).filter((c) =>
                    formData?.emails.includes(c.value),
                  )
                : []
            }
            renderInput={(params) => (
              <TextValidator
                {...params}
                label=""
                name="emails"
                variant="outlined"
                fullWidth
                /* variant="outlined" */
                value={formData?.emails || ''}
              />
            )}
          />
          {/* <SelectDropDown
            setValue={toggleBtnAction}
            templateOptions={templateOptions}
            selected={templateId}
          /> */}
          {/* <Button
            className={
              toggleBtn
                ? 'pl-4 pr-4 mt-5 unSelectedBtn'
                : 'pl-4 pr-4 mt-5  selectedBtn'
            }
            onClick={() => addEmail(templateId)}
          > */}
          <Button
            variant="contained"
            className={`${classes.nextButton} mt-5`}
            type="submit"
            disabled={disabledNextButton()}
          >
            {eventId ? `Update` : `Add`}
          </Button>
        </div>
        <CreateTemplate
          openDrawer={toggleOpen}
          toSelect={setTemplateId}
          onClose={toggleTemplateModal}
          admin
          page={'step'}
          setValue={handleCreateTemplate}
        />
        {/*<CreateTemplate*/}
        {/*  modalState={toggleOpen}*/}
        {/*  toSelect={setTemplateId}*/}
        {/*  onHide={toggleTemplateModal}*/}
        {/*  page={'step'}*/}
        {/*  setValue={handleCreateTemplate}*/}
        {/*/>*/}
        <JobspecTemplate
          modalState={toggleOpenJobSpec}
          toSelect={setTemplateId}
          onHide={() => toggleJobspecModal()}
          page={'step'}
        />
      </div>
    </ValidatorForm>
  );
};

CreateEmail.propTypes = {
  auth: PropTypes.object.isRequired,
  getUserTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  template: state.template,
});
const mapDispatchToProps = {
  getUserTemplate,
  createEmailStep,
  updateEmailStep,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateEmail);
