import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '../../../components/Avatar';
import bhLogo from '../../../../../img/bullinorange.png';
import bhGreyLogo from '../../../../../img/bullhorn-grey.png';

const useStyles = makeStyles(({ custom }) => ({
  userInfoWrapper: {},
  userAvatar: {
    width: 80,
    height: 80,
    fontSize: '3rem',
  },
  imageWrapper: {
    width: 40,
  },
  userTextWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '2em',
  },
  userName: {
    fontWeight: 'bold',
    '& span': {
      fontWeight: 300,
    },
  },
  subText: {
    color: custom.colorCode.lightGreyColorShade16,
  },
}));

const UserInfo = ({
  userName,
  type,
  position,
  region,
  imgUrl,
  bhUrl,
  bhId,
  bhConnected,
}) => {
  const classes = useStyles();

  return (
    <Box display={'flex'} className={classes.userInfoWrapper}>
      <Avatar
        className={classes.userAvatar}
        alt={userName}
        src={imgUrl || ''}
      />
      <Box className={classes.userTextWrapper}>
        <Box>
          <Typography variant="h6" component="h6" className={classes.userName}>
            {userName} <span>({type})</span>
          </Typography>
          <Typography variant="body1" className={classes.subText}>
            {position}
          </Typography>
          <Typography variant="body1" className={classes.subText}>
            {region}
          </Typography>
        </Box>
        {bhConnected && (
          <Box className={classes.imageWrapper}>
            {(bhId && bhUrl) ? (
              <a href={bhUrl} target="_blank" rel="noreferrer">
                <img
                  src={bhLogo}
                  alt=""
                  border="0"
                  style={{ height: 40 }}
                  //className={`img-fluid rounded-circle ${classes.leftBoxIcon}`}
                />
              </a>
            ) : (
              <img
                src={bhGreyLogo}
                alt=""
                border="0"
                style={{ height: 40 }}
                //className={`img-fluid rounded-circle ${classes.leftBoxIcon}`}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserInfo;
