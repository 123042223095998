import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Typography } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PanToolIcon from '@material-ui/icons/PanTool';
import TripOriginIcon from '@material-ui/icons/TripOrigin';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import SequenceListItem from '../../../components/ListItems/SequenceListItem';
import MenuDropDown from '../../../components/MenuDropDown';
import Spinner from '../../../layout/Spinner';
import SequenceListingDialog from './SequenceListingDialog';

import api from '../../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {},
  mainListItem: {
    padding: 0,
    '& .MuiListItem-root': {
      paddingLeft: 0,
    },
    '& .MuiListItemText-multiline': {
      marginTop: 4,
      marginBottom: 4,
    },
    '& .MuiListItemSecondaryAction-root': {
      right: 4,
    },
  },
  statusContentWrapper: {
    transform: 'translateY(-20px)',
  },
  statusContent: {
    padding: '4px 8px',
    borderRadius: 4,
  },
  sequenceActionIcon: {
    fill: custom.colorCode.lightGreyColorShade12,
    marginRight: 8,
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: 4,
    minWidth: 110,
  },
}));

const SequenceListing = ({ taskData }) => {
  const classes = useStyles();

  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedSequenceAction, setSelectedSequenceAction] = useState(null);
  const [reasonDropdown, setReasonDropdown] = useState();
  const [dropDownOptions, setDropDownOptions] = useState([]);

  const menuId = 'action-sequence-task-menu-id';

  useEffect(() => {
    if (isEmpty(displayData)) {
      setLoading(true);
      if (taskData?.dmId) {
        getSequenceList();
      } else {
        getSequenceList();
      }
    }
    api.get(`/sequence/get-reason-dropdown`).then((respDropdown) => {
      setReasonDropdown(respDropdown.data);
    });
  }, []);

  const getSequenceList = () => {
    api
      .get(
        `/sequence/get-sequence-list?contact_id=${
          taskData.candidateId || taskData.dmId
        }&audience_type=${taskData.dmId ? 1 : 2}`,
      )
      .then(({ data }) => {
        setDisplayData([...data]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleMenuClose = (seqData) => () => {
    setAnchorElement(null);
    setDropDownOptions([]);
    if (seqData) {
      setSelectedSequenceAction({
        ...seqData,
        audience_type: taskData.dmId ? 1 : 2,
        new_contact_id: taskData.dmId || taskData.candidateId,
      });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner style={{ width: 20, height: 20, position: 'unset' }} />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <SequenceListItem
        anchorElement={anchorElement}
        displayData={displayData}
        handleMenuClose={handleMenuClose}
        setAnchorElement={setAnchorElement}
        menuId={menuId}
        dropDownOptions={dropDownOptions}
        setDropDownOptions={setDropDownOptions}
      />

      <SequenceListingDialog
        selectedSequenceAction={selectedSequenceAction}
        reasonDropdown={reasonDropdown}
        setSelectedSequenceAction={setSelectedSequenceAction}
        getSequenceList={getSequenceList}
      />
    </Box>
  );
};

export default SequenceListing;
