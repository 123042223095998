import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MainTabs, { TabPanel } from '../../components/MainTabs';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../layout/Spinner';
import { fetchSequenceTabs } from '../../actions/sequence';
import SequenceGrid from './SequenceGrid';

// template styling
const useStyles = makeStyles(() => ({
  tabContainer: {
    margin: 0,
  },
  mainHeader: {
    '& .heading-title': {
      marginBottom: 0,
    },
  },
}));

/**
 * Sequence tab component
 */
const SequenceTabs = (props) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const sequenceCount = useSelector((state) => state.sequence.count);
  const sequnceGridTabs = useSelector((state) => state.sequence.sequenceGridTabs);

  const dispatch = useDispatch();

  useEffect(() => {
    // did mount
    if (!sequnceGridTabs?.tabs?.length) {
      setTabs();
    }
  }, []);

  /**
   * Get and set tab
   */
  const setTabs = async () => {
    const sequenceTabData = await dispatch(fetchSequenceTabs());
    if (props.match.params.id) {
      const tabIndex = sequenceTabData.data.findIndex(
        (e) => e.url == props.match.params.id,
      );
      if (tabIndex !== -1) {
        setTabIndex(tabIndex);
      }
    }
  };

  /**
   * Handle tab change event
   */
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  /**
   * Show loading
   */
  if (sequnceGridTabs.loading) {
    return <Spinner />;
  }

  return (
    <div>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Sequences'}
        subTitle={'Use sequences to automate your follows-ups and process.'}
        showCount={false}
        itemCount={0}
        className={classes.mainHeader}
      />

      <MainTabs
        tabsOptions={(sequnceGridTabs?.tabs || []).map((tOption, index) => ({
          ...tOption,
          name: `${tOption.name}${
            tabIndex === index ? ` (${sequenceCount})` : ''
          }`,
          component: Link,
          to: `/sequence/${tOption.url}`,
        }))}
        tabIndex={tabIndex}
        onTabChange={handleTabChange}
        className={classes.tabContainer}
        tabLabel="name"
        tabKey="id"
      />

      {(sequnceGridTabs?.tabs || []).map((tab, index) => (
        <TabPanel
          value={tabIndex}
          index={index}
          key={`tab-grids-${tab.id}-${tab.name}`}
        >
          <SequenceGrid {...props} tabProps={tab} />
        </TabPanel>
      ))}
    </div>
  );
};

export default SequenceTabs;
