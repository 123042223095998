import React, { useState } from 'react';

import CustomDrawer from '../../../components/CustomDrawer';
import DrawerFormHeader from '../../../components/DrawerFormHeader';
import TwilioIntegrationForm from './TwilioIntegrationForm';
import CustomSnackBar from '../../../components/CustomSnackBar';

import { english } from '../../../utils/language';

const TwilioDrawer = (props) => {
  const { openDrawer, onClose } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);

  /**
   * Handle function to close the drawer popup
   * @param {*} data
   */
  const handleClose = (data) => {
    if (data && data?.data === 'twilioIntegrated') {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: 'Twilio integrated successfully',
        status: 'success',
      });
    }
    onClose();
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={english.twilioTitle}
        >
          <TwilioIntegrationForm closeDrawer={handleClose} />
        </DrawerFormHeader>
      </CustomDrawer>

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default TwilioDrawer;
