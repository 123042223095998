import React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { Intro, Accordion } from "./components";
import { useStyles, overviewIcon, rocketIcon, setupIcon } from "./styles";
import { onboardingData } from "./data";

function OnboardingView(props) {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.onboardingRoot}>
        <Intro user={props.auth.user} />
        {onboardingData.map((accordion, index) => {
          return (
            <Box key={`Accordion-${index}`}>
              <Typography variant="h5">{accordion.title}</Typography>
              <Box mt={2} mb={4} position="relative" zIndex={99}>
                {(accordion.child || true) && (
                  <>
                    {accordion.child.map((accordionOuter, index1) => {
                      let progress = accordionOuter.child.filter(
                        (item) => item.isCompleted == true
                      ).length;
                      progress = (100 * progress) / accordionOuter.child.length;
                      return (
                        <Accordion.Outer
                          key={`AccordionOuter-${index1}`}
                          title={accordionOuter.title}
                          isCompleted={progress == 100}
                          icon={overviewIcon}
                          progress={progress}
                          steps={accordionOuter.child.length}
                          time={accordion.time}
                        >
                          {accordionOuter.child && accordionOuter.child.length && (
                            <>
                              {accordionOuter.child.map(
                                (accordionInner, index2) => {
                                  return (
                                    <Accordion.Inner
                                      key={`AccordionInner-${index2}`}
                                      isCompleted={accordionInner.isCompleted}
                                      title={accordionInner.title}
                                    >
                                      <Typography> Hello World 1</Typography>
                                    </Accordion.Inner>
                                  );
                                }
                              )}
                            </>
                          )}
                        </Accordion.Outer>
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </Container>
    </>
  );
}

export default OnboardingView;
