import React, { useEffect, useState } from 'react';
import { arrayMoveImmutable } from 'array-move';

import Dialog from '../../../components/Dialog';
import ColumnContainGrid from './ColumnContainGrid';

const EditColumnPopup = ({
  showEditColPopup,
  handleCloseDialog,
  onColumnChange,
  ...props
}) => {
  const [columns, setColumns] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);

  const handleDialogClose = () => {
    handleCloseDialog(!showEditColPopup);
  };

  useEffect(() => {
    if (showEditColPopup) {
      setColumns(props.columns);
      setColumnOrder(props.columnOrder);
    } else {
      setColumns([]);
      setColumnOrder([]);
    }
  }, [showEditColPopup]);

  const handleSaveClick = () => {
    onColumnChange({ columns: columns, columnsOrder: columnOrder });
    handleCloseDialog(false);
  };

  // console.log('----columnOrder---', columnOrder);
  const handleColumnVisibility = (event) => {
    const colIndex = columns.findIndex((c) => c.name === event.target.name);
    if (event.target.checked) {
      const colOrderIndex = columnOrder.findIndex((e) => e === colIndex);

      if (columns.find((e) => !e.label.trim())) {
        setColumnOrder([
          ...arrayMoveImmutable(
            columnOrder,
            colOrderIndex,
            columnOrder.length - 2,
          ),
        ]);
      } else {
        setColumnOrder([
          ...arrayMoveImmutable(
            columnOrder,
            colOrderIndex,
            columnOrder.length - 1,
          ),
        ]);
      }
    }

    setColumns([
      ...columns.slice(0, colIndex),
      {
        ...columns[colIndex],
        display: String(event.target.checked),
      },
      ...columns.slice(colIndex + 1),
    ]);
  };

  const handleHideColumn = (data) => () => {
    const colIndex = columns.findIndex((c) => c.name === data.name);
    setColumns([
      ...columns.slice(0, colIndex),
      {
        ...columns[colIndex],
        display: String(false),
      },
      ...columns.slice(colIndex + 1),
    ]);
  };

  const handleColumnReorder = (data) => {
    setColumnOrder([...data]);
  };

  return (
    <>
      <Dialog
        open={Boolean(showEditColPopup)}
        draggable={false}
        title="Manage Columns"
        onClose={handleDialogClose}
        showCloseIcon
        maxWidth={'lg'}
        buttonText={'Save'}
        ContentComponent={ColumnContainGrid}
        contentProps={{
          columns,
          columnOrder,
          onColumnVisibility: handleColumnVisibility,
          onColumnReorder: handleColumnReorder,
          hideColumn: handleHideColumn,
        }}
        handleClick={handleSaveClick}
      />
    </>
  );
};

export default EditColumnPopup;
