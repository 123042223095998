import api from '../utils/api';
import { GET_LEADS, GET_TABS, LOAD_LEADS, LEAD_ERROR } from '../actions/types';
import { object } from 'yup';
import { toast } from 'react-toastify';

// Get current users sequence
export const fetchLeads = (tab = null, args = object, filters = []) => async (
  dispatch,
) => {
  const tabs = {
    tab: tab,
  };
  const params = {
    ...tabs,
    ...args,
    filters: filters,
  };
  //console.log(params);
  try {
    const res = await api.get('/automation/lead', { params });
    dispatch({
      type: LOAD_LEADS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOAD_LEADS,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
    toast.error('Error in fetching grid data', {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
  }
};

// Get current users sequence
export const fetchTabs = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/lead/tabs');
    dispatch({
      type: GET_TABS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LEAD_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Save grid columns
export const updateColumns = (tabId, params) => async (dispatch) => {
  try {
    const values = {
      id: tabId,
      columns: params.columns,
      columnsOrder: params.columnsOrder,
      object: 'lead',
    };
    //console.log(values);
    await api.put('/automation/lead/columns', { values }).then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_TABS,
          payload: res.data,
        });
      }
    });
  } catch (err) {
    dispatch({
      type: LEAD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
