import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import MyTaskGrid from './MyTaskGrid';
import MainTabs, { TabPanel } from '../../components/MainTabs';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../layout/Spinner';
import { fetchTaskTabs, setTaskCurrentTab } from '../../actions/admin/myTask';

const tabsOptions = [
  {
    name: 'my-task',
    title: 'All',
  },
];

const useStyles = makeStyles(() => ({
  taskTabMainClass: {
    margin: 0,
  },
  mainHeader: {
    '& .heading-title': {
      marginBottom: 0,
    },
  },
}));

const TaskTab = (props) => {
  const classes = useStyles();
  const [userTabIndex, setUserTabIndex] = useState(0);
  const myTaskCount = useSelector((state) => state.myTask.count);
  const taskTab = useSelector((state) => state.myTask.taskTab);

  const dispatch = useDispatch();

  useEffect(() => {
    // did mount
    setTabs();
  }, []);

  const setTabs = async () => {
    const taskTabData = await dispatch(fetchTaskTabs());
    if (props.match.params.id) {
      const tabIndex = taskTabData.data.findIndex(
        (e) => e.url == props.match.params.id,
      );
      if (tabIndex !== -1) {
        setUserTabIndex(tabIndex);
        dispatch(setTaskCurrentTab(taskTabData.data[tabIndex]));
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setUserTabIndex(newValue);
  };

  if (taskTab.loading) {
    return <Spinner />;
  }

  return (
    <div>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Tasks'}
        subTitle={null}
        showCount={false}
        itemCount={myTaskCount}
        className={classes.mainHeader}
      />

      <MainTabs
        tabsOptions={(taskTab?.tabs || []).map((tOption, index) => ({
          ...tOption,
          name: `${tOption.name}${
            userTabIndex === index ? ` (${myTaskCount})` : ''
          }`,
          component: Link,
          to: `/task/${tOption.url}`,
        }))}
        tabIndex={userTabIndex}
        onTabChange={handleTabChange}
        className={classes.taskTabMainClass}
        tabLabel="name"
        tabKey="id"
      />

      {(taskTab?.tabs || []).map((tab, index) => (
        <TabPanel
          value={userTabIndex}
          index={index}
          key={`tab-grids-${tab.id}-${tab.name}`}
        >
          <MyTaskGrid {...props} tabProps={tab} />
        </TabPanel>
      ))}
    </div>
  );
};

export default TaskTab;
