import api from '../utils/api';
import { toast } from 'react-toastify';

import {
  USERS_TEAMS_LOADED,
  GET_USERS,
  GET_DROPDOWN_LIST,
  USERS_ERROR,
  CREATE_ADD_USER,
  UPDATE_USER,
} from '../actions/types';

// Get Users and team list for dropdown/autocomplete
export const fetchUsersTeams = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/users');

    dispatch({
      type: USERS_TEAMS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USERS_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get user list for user grid in admin section
export const getUsersList = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/get-users');

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

/**
 * Get all dropdown options for add/edit form and set data in reducer
 * like roles, functions, offices, countries and job roles
 */
export const getDropdonList = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/get-dropdown-list');

    dispatch({
      type: GET_DROPDOWN_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

/**
 * Call api to save add user data into database
 */
export const createUser = (values, history, onHide) => async (dispatch) => {
  try {
    await api
      .post('/automation/users', {
        values,
      })
      .then((res) => {
        dispatch({
          type: CREATE_ADD_USER,
          payload: res.data,
        });
        onHide('addSequence');
        /* history.push('/sequence/steps/'+res.data.id); */
        toast.success('User created', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
      });
  } catch (err) {
    dispatch({
      type: USERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//update user status
export const updateStatus = (userId, status, showSnacker) => async (
  dispatch,
) => {
  try {
    const values = { status: status };
    await api
      .put(`/automation/users/update-status/${userId}`, values)
      .then((res) => {
        dispatch({
          type: UPDATE_USER,
          payload: res.data,
        });
        //onHide('addSequence');
        dispatch(getUsersList());
        showSnacker('User status changed successfully!');
      });
  } catch (err) {
    dispatch({
      type: USERS_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
