import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MUIPopper from '@material-ui/core/Popper';
import MUICheckbox from '@material-ui/core/Checkbox';
import MUIInputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import AutoCompleteSelect from '../FormAutoCompleteSelect';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
];

const useStyles = makeStyles(({ custom, ...theme }) => ({
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    width: 300,
    zIndex: 140,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
  },
  popperDisablePortal: {
    position: 'relative',
    width: '100% !important',
  },
  autoCompleteRoot: {
    padding: 10,
  },
  inputBase: {
    border: '1px solid #dfe2e5',
    borderRadius: 4,
    '& input': {
      backgroundColor: theme.palette.common.white,
      padding: 8,
      fontSize: 14,
    },
  },
}));

const PoperAutoComplete = (props) => {
  const { openPopper, anchorEl = null, onPopperClose, options = [] } = props;

  // const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  useEffect(() => {}, []);

  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }
    onPopperClose();
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @param key
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {};

  return (
    <MUIPopper
      id={'id'}
      open={Boolean(openPopper)}
      anchorEl={anchorEl}
      placement="bottom-start"
      className={classes.popper}
    >
      <Grid container spacing={3}>
        {/*Job Roles*/}
        <Grid item xs={12} sm={12}>
          <AutoCompleteSelect
            onChange={handleSelectChange('levelOwner', 'key')}
            name="category"
            id="category"
            classes={{
              root: classes.autoCompleteRoot,
              paper: classes.paper,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            open
            onClose={handleClose}
            multiple
            disableClearable
            options={top100Films}
            disableCloseOnSelect
            disablePortal
            getOptionLabel={(option) => option.title || ''}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <MUICheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.title}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <MUIInputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                fullWidth
                autoFocus
                className={classes.inputBase}
              />
            )}
          />
        </Grid>
      </Grid>
    </MUIPopper>
  );
};

export default PoperAutoComplete;
