import React, { useState } from 'react';

import CustomDrawer from '../../components/CustomDrawer';
import DrawerFormHeader from '../../components/DrawerFormHeader';
import AddOfficeForm from '../AddOfficeForm';
import CustomSnackBar from '../../components/CustomSnackBar';

/**
 * Component to open team drawer/slider popup
 * @param {*} props
 * @returns
 */
const AddOfficeDrawer = (props) => {
  const { openDrawer, onClose, editData } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState('');

  /**
   * Handle function to close the drawer popup
   * @param {*} data
   */
  const handleClose = (data) => {
    if (data && data?.officeAdded) {
      setOpenSnackBar(true);
      setOpenSnackBarMessage('New Office added!');
    }
    if (data && data?.officeEdited) {
      setOpenSnackBar(true);
      setOpenSnackBarMessage('Office updated added!');
    }
    onClose();
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage('');
  };

  return (
    <div>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={editData?.id ? 'Edit Office' : 'Create Office'}
        >
          <AddOfficeForm
            openDrawer={openSnackBar}
            closeDrawer={handleClose}
            editOffice={editData}
          />
        </DrawerFormHeader>
      </CustomDrawer>

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        handleSnackbarClose={handleSnackbarClose}
        barMessage={openSnackBarMessage || ''}
        severity={'success'}
      />
    </div>
  );
};

export default AddOfficeDrawer;
