import { isArray, isEmpty } from 'lodash';
import api from '../../utils/api';
import {
  LOAD_SEQUENCED_CANDIDATES,
  SEQUENCED_CANDIDATES_ERROR,
  SET_SEQUENCED_CANDIDATES_FILTER_DATA,
  SHOW_LOAD_SEQUENCED_CANDIDATES_LOADER,
  LOAD_SEQUENCED_CANDIDATES_TABS,
  UPDATE_SEQUENCED_CANDIDATES_STATUS,
  SET_SEQUENCED_CANDIDATES_CURRENT_TAB,
  LOAD_SEQUENCED_CANDIDATES_TABS_LOADING
} from '../types';

/**
 * fetch sequence data using contact type and other filter options
 */
export const fetchSequenceCandidateData = (
  contactType,
  filterData,
  gridParams,
  tabData,
  gridColParams,
  showLoader = true
) => async (dispatch) => {
  try {
    if (showLoader) {
      dispatch({
        type: SHOW_LOAD_SEQUENCED_CANDIDATES_LOADER,
        payload: { tabData },
      });
    }

    let res;
    if (!filterData) {
      res = await api.get(
        `/candidate/get-sequenced-candidate-tab-grid?tab=${
          tabData.id
        }&contactType=${
          contactType
        }`
      );
    } else {
      if (isEmpty(gridColParams)) {
        res = await api.get(
          `/candidate/get-sequenced-candidate-tab-grid?tab=${
            tabData.id
          }&contactType=${
            contactType
          }&filters=${
            JSON.stringify(filterData)
          }&gridParams=${JSON.stringify(
            gridParams
          )}`
        );
      } else {
        res = await api.get(
          `/candidate/get-sequenced-candidate-tab-grid?tab=${
            tabData.id
          }&contactType=${
            contactType
          }&filters=${
            JSON.stringify(filterData)
          }&gridParams=${JSON.stringify(
            gridParams
          )}&columns=${JSON.stringify(gridColParams)}`
        );
      }
    }
    if (!filterData) {
      dispatch({
        type: LOAD_SEQUENCED_CANDIDATES,
        payload: {
          [tabData.id]: {
            contactsList: res.data.candidates,
            count: res.data.counts,
            filterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            defaultFilterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            gridParams: isEmpty(res.data.grid_params)
              ? gridParams
              : { ...res.data.grid_params },
            columns: isEmpty(res.data.columns)
              ? {}
              : JSON.parse(res.data.columns),
            loading: false,
          },
        },
      });
    } else {
      dispatch({
        type: LOAD_SEQUENCED_CANDIDATES,
        payload: {
          [tabData.id]: {
            contactsList: res.data.candidates,
            count: res.data.counts,
            filterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            gridParams: isEmpty(res.data.grid_params)
              ? gridParams
              : { ...res.data.grid_params },
            columns: isEmpty(res.data.columns)
              ? {}
              : JSON.parse(res.data.columns),
            loading: false,
          },
        },
      });
    }
  } catch (err) {
    dispatch({
      type: SEQUENCED_CANDIDATES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/**
 * Update sequence status in local state
 */
export const updateSequenceStatus = (taskData = {}) => async (dispatch) => {
  dispatch({
    type: UPDATE_SEQUENCED_CANDIDATES_STATUS,
    payload: taskData,
  });
};

/**
 * Get filter data and its lable
 */
const getFilterLabel = (text = '', data) => {
  let newLabel = {};
  switch (text) {
    case 'enrolled_by': {
      newLabel = {
        type: 'enrolled_by',
        title: 'Enrolled by',
        isSingle: true,
        showName: true,
      };
      break;
    }
    case 'enrolled_date': {
      newLabel = {
        type: 'enrolled_date',
        title: 'Enrolled Date',
        isSingle: true,
        showName: true,
      };
      break;
    }
    case 'sequnece_name': {
      newLabel = {
        type: 'sequnece_name',
        title: 'Sequence Name',
        // isSingle: true,
        // showName: true,
        
      };
      break;
    }
    case 'sequnece_status': {
      newLabel = {
        type: 'sequnece_status',
        title: 'Current Status',
      };
      break;
    }

    case 'reason_status': {
      newLabel = {
        type: 'reason_status',
        title: 'Reason Status',
      };
      break;
    }
    case 'opened_status': {
      newLabel = {
        type: 'opened_status',
        title: 'Opened Status',
      };
      break;
    }
    case 'clicked_status': {
      newLabel = {
        type: 'clicked_status',
        title: 'Clicked Status',
      };
      break;
    }
    default: {
      newLabel = {};
      break;
    }
  }

  return newLabel;
};

/**
 * Get filters for the grid
 */
export const fetchGridFilters = (tabData) => async (dispatch) => {
  try {
    const res = await api.get('/candidate/get-sequenced-candidate-filter');

    let filterListData = Object.keys(res.data);
    filterListData = filterListData
      .filter(
        (e) =>
          e !== 'complete_date' && e !== 'enrolled_by' && e !== 'enrolled_date'
      )
      .map((k) => ({
        name: k,
        data: res.data[k],
        optionLabel: 'name',
        optionValue: 'id',
        ...getFilterLabel(k, res.data),
      }));
      
      if( tabData.name != 'In Progress' || (tabData.id == 9 || tabData.id == 6)) {
        filterListData.push({
          name: 'completeDate',
          control: 'dateRangePicker',
          isSingle: true,
          title: 'Completed Date',
          type: 'completeDate',
          from: 'completeFrom',
          to: 'completeTo',
        });
      }

    dispatch({
      type: SET_SEQUENCED_CANDIDATES_FILTER_DATA,
      payload: {
        id: tabData.id,
        [tabData.id]: {
          myFilterData: [...filterListData],
          filterList: res.data,
        },
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

/**
 * Get grid tabs and store them to local state
 */
export const fetchSequenceCandidateTabs = (objectName) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SEQUENCED_CANDIDATES_TABS_LOADING,
      payload: true,
    });
    const res = await api.get(`automation/tabs/get-tabs?object=${objectName}`);

    res.data = res.data.map((e) => ({
      ...e,
      url: e.tab_type ? e.name.toLocaleLowerCase().replace(/ /g, '-') : e.id,
    }));

    dispatch({
      type: LOAD_SEQUENCED_CANDIDATES_TABS,
      payload: {
        tabs: res.data,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

/**
 * Set active tab in local state
 */
export const setCurrentTab = (currentTab) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SEQUENCED_CANDIDATES_CURRENT_TAB,
      payload: {
        currentTab,
      },
    });
  } catch (err) {
    return err;
  }
};
