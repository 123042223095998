import React from 'react';
import { Button } from '@material-ui/core';
import { english } from '../../utils/language';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    justifyContent: 'space-between',
    zIndex: 1,
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
}));

const DrawerFormFooter = ({ children, disableButton, className = '' }) => {
  const classes = useStyles();

  return (
    <div className={`${className} ${classes.formFooter}`}>
      {children}
      <div>
        <Button
          variant="contained"
          className={classes.nextButton}
          type="submit"
          disabled={disableButton}
        >
          {english.save}
        </Button>
      </div>
    </div>
  );
};

export default DrawerFormFooter;
