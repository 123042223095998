import { Settings } from '@material-ui/icons';

const eng = {
  // login screen start
  signIn: 'Sign in',
  logIn: 'Log In',
  forPassword: 'Forgot password?',
  // login screen end

  // forgotPassword start
  forgotPasswordHeading: 'Reset Password',
  forgotPasswordSubTitle:
    'Enter your registered email address and we will send an email with the password reset instructions.',
  resetPasswordLink: 'Reset password link sent to your email.',
  // forgotPassword end

  // reset password start
  resetPasswordTitle: 'Update Password',
  resetSuccess: 'Your password update successfully.',
  // reset password end

  // job roles start
  jobRoleMainTitle: 'Job Roles',
  jobRoleSubTitle: 'Create, edit and remove job roles against your account.',
  // job roles end

  // Validation message start
  emailRequiredValidation: 'Please enter email',
  emailNotValidValidation: 'Email is not valid',
  enterPassword: 'Please enter password',
  passwordRequired: 'Please enter new password',
  passwordCustomValidation:
    'Min 6 char, min 1 capital char, min 1 number and min 1 special char',
  confirmPasswordRequired: 'Please enter confirm password',
  passwordMismatch: 'Password mismatch',
  // Validation message end

  // side bar start
  tool: 'Tools',
  linkedInTemplate: 'LinkedIn',
  linkedInTemplateId: 'linkedIn-template',
  emailTemplates: 'Email',
  emailTemplatesId: 'email-templates',
  // profile
  profileSetting: 'Profile Settings',
  profile: 'Profile',
  myProfile: 'My Profile',
  templates: 'Templates',
  sequenceEmails: 'Sequences Emails',
  specJobTemplates: 'Spec Job',
  specJobTemplatesId: 'spec-job-templates',
  // profile side bar end
  // side bar end

  // edit sequence form start
  sequenceName: 'Sequence Name',
  sequenceNameReq: 'Sequence name required',
  seqAudience: 'Sequence Audience',
  seqAudienceReq: 'Select sequence audience',
  seqDesc: 'Sequence Description',
  seqDescReq: 'Select sequence audience',
  seqAccess: 'Sequence Access',
  seqAccessReq: 'Select access type',
  seqUser: 'Select Users',
  seqUserReq: 'Select access type',
  seqTeam: 'Select Teams',
  seqTeamReq: 'Select team type',
  seqNumberEmail: 'Number of emails',
  seqNumberEmailReq: 'Please enter email number',
  seqWinPer: 'Winner percentage',
  seqWinPerReq: 'Please enter email number',
  seqVarPer: 'Variance percentage to keep',
  seqVarPerReq: 'Please enter variance percentage',
  seqEnrollmentSettings: 'Sequence Enrollment Runs',
  seqEnrollmentSettingsReq: 'Select sequence enrollment runs',

  // edit sequence form end

  // email template start
  createTemplateSuccessMsg: 'Template Added Successfully',
  editTemplateSuccessMsg: 'Template Updated Successfully',
  cloneTemplateSuccessMsg: 'Template cloned Successfully',
  emailTemplateAddFormHeading: 'Create Email Template',
  emailTemplateEditFormHeading: 'Edit Email Template',
  emailTemplateForm: {
    name: 'Template Name',
    nameValidate: 'Please enter template name',
    subject: 'Template Subject',
    subjectValidate: 'Please enter template subject',
    content: 'Content',
  },
  emailTemplateCloneFormHeading: 'Clone Email Template',
  duplicateTemplateMsg: 'Template already exists',
  // email template end

  // linkedin template start
  linkedinTemplateEditFormHeading: 'Edit LinkedIn Template',
  linkedinTemplateCreateFormHeading: 'Create LinkedIn Template',
  linkedinTemplateEditDetail: 'LinkedIn Template Details',
  createLinkedinTemplateSuccessMsg: 'Linkedin Template Added Successfully',
  editLinkedinTemplateSuccessMsg: 'Linkedin Template Updated Successfully',
  linkedinTemplateForm: {
    name: 'LinkedIn Template Name',
    nameValidate: 'Please enter linkedIn template name',
  },
  // linkedin template end

  // Spec Job template start
  specTemplateEditFormHeading: 'Edit Spec Job Template',
  specTemplateViewFormHeading: 'View Spec Job Template',
  specTemplateAddFormHeading: 'Create Spec Job Template',
  specTemplateCloneFormHeading: 'Clone Spec Job Template',
  specTemplateEditDetail: 'Spec Job Template Details',
  createSpecTemplateSuccessMsg: 'Spec Job Template Added Successfully',
  editSpecTemplateSuccessMsg: 'Spec Job Template Updated Successfully',
  cloneSpecTemplateSuccessMsg: 'Spec Job Template cloned Successfully',
  specTemplateForm: {
    name: 'Spec Job Template Name',
    nameValidate: 'Please enter spec job template name',
    subject: 'Template Subject',
    subjectValidate: 'Please enter template subject',
    templateAccess: 'Template access',
    templateAccessValidate: 'Select template access',
    selectCandidate: 'Select candidate',
  },
  // Spec Job template end

  // Twilio Integration start
  accountSIDInfo:
    'Login to your account to find API credentials here: twilio.com/user/account/settings',
  authTokenInfo: 'Found immediately below your Account SID',
  twilioTitle: 'Twilio Setting',
  twilioSubTitle: 'Twilio Details',
  twilioIntegrationForm: {
    accountSid: 'Account SID',
    accountSidRequire: 'Please enter account SID',
  },
  // Twilio Integration end

  // Bullhorn Integration start
  bullhornTitle: 'Bullhorn Setting',
  bullhornIntegrationForm: {},
  // Bullhorn Integration end

  // Integration detail start
  integrationDisconnectDialogSubTitle:
    'Are you sure, you want to disconnect this integration?',
  integrationDisconnectSyncDialogTitle: 'Stop Sync for',
  integrationDisconnectSyncDialogSubTitle:
    'Are you sure, you want to stop this sync?',
  // Integration detail end

  // common text
  submitButton: 'Submit',
  loginButton: 'Login',
  commonErrorMsg: 'Something went wrong',
  backToDashboard: 'Back to Dashboard',
  save: 'Save',
  update: 'Update',
  content: 'Content',
  clone: 'Clone',
  confirm: 'Confirm',
  name: 'Name',
  status: 'Status',

  // CV Spec template start
  cvSpecMenuItem: 'CV Spec',
  cvSpecMenuItemId: 'cv-spec-templates',
  cvSpecTemplateEditFormHeading: 'Edit CV Spec Template',
  cvSpecTemplateViewFormHeading: 'View CV Spec Template',
  cvSpecTemplateAddFormHeading: 'Create CV Spec Template',
  cvSpecTemplateCloneFormHeading: 'Clone CV Spec Template',
  cvSpecTemplateEditDetail: 'CV Spec Template Details',
  createCvSpecTemplateSuccessMsg: 'CV Spec Template Added Successfully',
  editCvSpecTemplateSuccessMsg: 'CV Spec Template Updated Successfully',
  cloneCvSpecTemplateSuccessMsg: 'CV Spec Template cloned Successfully',

  cvSpecTemplateForm: {
    name: 'CV Spec Template Name',
    nameValidate: 'Please enter CV spec template name',
    subject: 'Template Subject',
    subjectValidate: 'Please enter template subject',
    templateAccess: 'Template access',
    templateAccessValidate: 'Select template access',
    candidates: 'Candidates',
    candidatesValidate: 'Please select the candidates',
  },

  // Master CV Spec template start
  masterCvSpecMenuItem: 'Master CV Spec Templates',
  masterCvSpecTemplateEditFormHeading: 'Edit Master CV Spec Template',
  masterCvSpecTemplateAddFormHeading: 'Create Master CV Spec Template',
  masterCvSpecTemplateCloneFormHeading: 'Clone Master CV Spec Template',
  masterCvSpecTemplateViewFormHeading: 'View Master CV Spec Template',
  masterCvSpecTemplateEditDetail: 'Master CV Spec Template Details',
  chanageMasterCvSpecTemplate: 'Change Master CV Spec Template',
  createMasterCvSpecTemplateSuccessMsg: 'Master CV Spec Added Successfully',
  editMasterCvSpecTemplateSuccessMsg: 'Master CV Spec Updated Successfully',
  cloneMasterCvSpecTemplateSuccessMsg: 'Master CV Spec cloned Successfully',
  changeMasterCvSpecTemplateSuccessMsg: 'CV Spec Template changed Successfully',

  masterCvSpecTemplateForm: {
    name: 'Master CV Spec Template Name',
    nameValidate: 'Please enter master CV spec template name',
    subject: 'Template Subject',
    subjectValidate: 'Please enter template subject',
    templateAccess: 'Template access',
    templateAccessValidate: 'Select template access',
    candidates: 'Candidates',
    candidatesValidate: 'Please select the candidates',
  },
  // CV Spec template end
};

export default eng;
