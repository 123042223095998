import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Spinner from '../../layout/Spinner';

const useStyles = makeStyles(({ custom }) => ({
  typographyText: {
    color: custom.colorCode.lightGreyColorShade16,
  },
  showMoreText: {
    color: custom.colorCode.blueLightShade8,
    fontWeight: 700,
    cursor: 'pointer',
  },
}));

const ProfileDetail = ({ assignData = [], loading }) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner style={{ width: 20, height: 20, position: 'unset' }} />
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {assignData.map((assign, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            key={`profile-succeeded-assign-user${index}`}
          >
            <Box pb={0.5}>
              <Typography variant="body2">{assign.assigned_name}</Typography>
            </Box>
            {/*<Box>*/}
            {/*  <Typography variant="body2" className={classes.typographyText}>*/}
            {/*    Project | Last Activity: 04-10-21*/}
            {/*  </Typography>*/}
            {/*</Box>*/}
          </Grid>
        ))}

        {!assignData.length && (
          <Grid item xs={8} sm={8}>
            <Box>
              <Typography variant="body2">Sorry, no records found</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ProfileDetail;
