import React from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, custom }) => ({
  stepper: {
    padding: `${spacing(1)}px ${spacing(3)}px`,
  },
  stepperStep: {
    '& .MuiStepConnector-alternativeLabel': {
      top: 18,
      padding: `0 ${spacing(1)}px`,
    },
  },
  stepperTextCircle: {
    '& .MuiStepIcon-active': {
      fill: custom.colorCode.blueLightShade8,
      '& .MuiStepIcon-text': {
        fill: custom.colorCode.white,
      },
    },
    '& .MuiStepIcon-root': {
      width: spacing(5),
      height: spacing(5),
    },
    '& .MuiStepLabel-label': {
      fontSize: 16,
      '&.MuiStepLabel-active': {
        color: custom.colorCode.blueLightShade8,
      },
    },
    '& .MuiStepIcon-completed': {
      fill: custom.colorCode.blueLightShade8,
      '& .MuiStepIcon-text': {
        fill: custom.colorCode.white,
      },
    },
    '& svg': {
      fill: custom.colorCode.lightGreyColorShade9,
      '& .MuiStepIcon-text': {
        fill: custom.colorCode.blueLightShade8,
      },
    },
  },
}));

const FormStepper = ({
  steps = [],
  activeStep = 0,
  isStepSkipped = () => {},
  className = '',
  stepProps = {},
  labelProps = {},
}) => {
  const classes = useStyles();
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      className={`${className || ''} ${classes.stepper}`}
    >
      {steps.map((label, index) => {
        if (isStepSkipped(index)) {
          stepProps.completed = false;
        }
        return (
          <Step key={label} {...stepProps} className={classes.stepperStep}>
            <StepLabel className={classes.stepperTextCircle} {...labelProps}>
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default FormStepper;
