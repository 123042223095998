import React from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/Close';

import { IconButton } from '../../../components/Button';
import useStyles from './ColumnContain.style';

const DragHandle = sortableHandle(({ classes, value }) => (
  <Box className={classes.draggableItemText} display="flex" alignItems="center">
    {value.draggable === undefined || value.draggable ? (
      <DragIndicatorIcon fontSize="small" />
    ) : (
      <Box style={{ width: 36 }}> </Box>
    )}
    <Typography variant="body1">{value.label}</Typography>
  </Box>
));

const SortableItem = sortableElement(({ value, hideColumn }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.draggableItemTextWrapper}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <DragHandle classes={classes} value={value} />
      {value.draggable === undefined || value.draggable ? (
        <IconButton onClick={hideColumn(value)} component="span">
          <CloseIcon fontSize="small" className={classes.closeIcon} />
        </IconButton>
      ) : (
        <span className={classes.hiddenCloseIcon} />
      )}
    </Box>
  );
});

const SortableContainer = sortableContainer(({ children, className }) => {
  const classes = useStyles();
  return (
    <Box className={`${className} ${classes.draggableItem}`}>{children}</Box>
  );
});

const EditSortableColumn = ({
  className = '',
  onColumnReorder,
  columns,
  columnOrder,
  hideColumn,
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    onColumnReorder([...arrayMoveImmutable(columnOrder, oldIndex, newIndex)]);
  };

  return (
    <SortableContainer
      onSortEnd={onSortEnd}
      className={className}
      useDragHandle
    >
      {columnOrder.map(
        (value, index) =>
          columns[value].viewColumns &&
          columns[value].display == 'true' && (
            <SortableItem
              key={`item-edit-col-${columns[value].name}-${index}`}
              index={index}
              value={columns[value]}
              hideColumn={hideColumn}
              disabled={
                !(
                  columns[value].draggable === undefined ||
                  columns[value].draggable
                )
              }
              distance={1}
            />
          ),
      )}
    </SortableContainer>
  );
};

export default EditSortableColumn;
