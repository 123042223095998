import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 12,
  },
  breadCrumbLastChild: {
    fontSize: 12,
  },
  breadCrumbsSeparator: {
    fontSize: '1.2rem',
  },
}));

export default useStyles;
