import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import SelectDropDown from '../components/SelectDropDown';
// import CreateTemplate from '../linkedintemplate/CreateTemplate';
import CreateTemplate from '../linkedintemplate/CreateTemplate/CreateTemplate';

import {
  getTaskCategories,
  createTaskStep,
  updateTaskStep,
} from '../actions/sequence';
import {
  getUserLinkedTemplate,
  getTemplateData,
  updateContentState,
} from '../actions/template';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextEditor from '../utils/Editor';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  //category_id: yup.number().required('Template type is a required field'),
  content: yup.string().required('Content is a required field'),
});
const CreateTask = (props) => {
  const [toggleOpen, setToggleOpen] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [templateId, setTemplateId] = useState(false);
  const [eventId, setEventId] = useState(false);
  //const [contents, setContents] = useState(props.template.content);

  const {
    register,
    handleSubmit,
    control,
    errors,

    setValue,
    // formState: { isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(schema) });
  // const [formdata, setFormdata] = useState({});

  useEffect(() => {
    props.getTaskCategories();
    props.getUserLinkedTemplate();
    if (props.stepcontent.id) {
      setTemplateId({
        value: props.stepcontent.template_id,
        label: props.stepcontent.name,
      });
      setEventId(props.stepcontent.id);
      setValue('content', props.stepcontent.description);
    }
  }, [
    props.getTaskCategories,
    props.getUserLinkedTemplate,
    props.stepcontent.id,
  ]);

  /* const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });*/

  const toggleTemplateModal = () => {
    setToggleOpen(!toggleOpen);
  };

  const toggleBtnAction = (value) => {
    setToggleBtn(!toggleBtn);
    setTemplateId(value);
    props.getTemplateData(value.value);
  };

  const categories = props.sequence.categories
    ? props.sequence.categories
    : null;
  const cat_id = categories.map(function (cat) {
    return cat.id;
  });

  const templates = props.template.templates ? props.template.templates : null;
  var templateOptions = templates.map(function (temlate) {
    return { value: temlate.id, label: temlate.name };
  });

  const addTask = async (value) => {
    //console.log(value);
    if (eventId) {
      props.updateTaskStep(
        value,
        props.sid,
        eventId,
        props.closeModal,
        templateId,
      );
    } else {
      props.createTaskStep(
        value,
        props.sid,
        props.closeModal,
        props.eventorder,
        templateId,
      );
    }
    //props.closeModal();
  };

  //Initialize content field data after template selection
  useEffect(() => {
    if (props.template.content) {
      setValue('content', props.template.content);
      props.updateContentState();
    }
  }, [props.template.content]);

  return (
    <div className="createEmailHeadCls">
      <Form onSubmit={handleSubmit(addTask)}>
        <div className="createEmailOptionCls">
          <p onClick={() => toggleTemplateModal()}>
            <i className="fa fa-plus"></i> Create New LinkedIn Template
          </p>
        </div>
        <div className="emailContent">
          <h3>Existing LinkedIn Templates</h3>
          <SelectDropDown
            setValue={toggleBtnAction}
            templateOptions={templateOptions}
            selected={templateId}
          />
        </div>
        <div className="emailContent">
          {/* <Row>
                        <Col sm={12} md={12} lg={12}>
                        <label><strong>Category</strong></label>
                        <Form.Control as="select" name="category_id" defaultValue="" custom="true" ref={register}>
                            <option value="">Select</option>
                            {
                                props.sequence.categories.map(function(cat){
                                    return <option value={cat.id}>{cat.title}</option>
                                })
                            }
                        </Form.Control>
                        {errors.category_id && <label className="error">{errors.category_id?.message}</label>}

                        </Col>
                    </Row> */}
          <Row>
            <Col sm={12} md={12} lg={12}>
              <label>
                <strong>Description</strong>
              </label>
              <Controller
                control={control}
                name="content"
                defaultValue=""
                render={({ onChange, value }) => (
                  <TextEditor onChange={onChange} initialvalue={value} />
                )}
              />
              {errors.content && (
                <label className="error">{errors.content?.message}</label>
              )}
            </Col>
          </Row>
        </div>
        <div className="emailContent">
          <Row>
            <Col sm={12} md={12} lg={12}>
              <input
                type="checkbox"
                name="copy_template"
                id="copy_template"
                value="1"
                ref={register}
              />{' '}
              Save as new template
            </Col>
          </Row>
          <Form.Control
            type="hidden"
            name="category_id"
            id="category_id"
            value={cat_id}
            className="custom_input"
            ref={register}
          />
        </div>

        <div className="emailContent d-flex">
          {/* <h3>Existing email templates</h3>
                <SelectDropDown setValue={toggleBtnAction} templateOptions={templateOptions}
                selected={templateId} /> */}
          <Button
            className={
              toggleBtn ? 'pl-4 pr-4 unSelectedBtn' : 'pl-4 pr-4 selectedBtn'
            }
            type="submit"
          >
            {eventId ? `Update` : `Add`}
          </Button>
        </div>
      </Form>

      <CreateTemplate
        openDrawer={toggleOpen}
        templateId={templateId}
        onClose={() => toggleTemplateModal()}
        toSelect={toggleBtnAction}
        page={'step'}
        admin
      />
      {/*<CreateTemplate*/}
      {/*  modalState={toggleOpen}*/}
      {/*  toSelect={toggleBtnAction}*/}
      {/*  onHide={() => toggleTemplateModal()}*/}
      {/*  page={'step'}*/}
      {/*/>*/}
    </div>
  );
};

CreateTask.propTypes = {
  auth: PropTypes.object.isRequired,
  getTaskCategories: PropTypes.func.isRequired,
  getUserLinkedTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sequence: state.sequence,
  template: state.template,
});
const mapDispatchToProps = {
  getTaskCategories,
  createTaskStep,
  updateTaskStep,
  getUserLinkedTemplate,
  getTemplateData,
  updateContentState,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTask);
