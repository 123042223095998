import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SucceededView from './SucceededView';

const SucceededProfileDetail = (props) => {
  return (
    <SucceededView {...props} />
  );
};

SucceededProfileDetail.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SucceededProfileDetail);
