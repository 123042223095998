import {
  GET_CATEGORIES,
  GET_SEQUENCES,
  GET_SEQUENCE,
  CREATE_SEQUENCE,
  CLONE_SEQUENCE,
  CREATE_STEPS,
  UPDATE_STEPS,
  DELETE_STEPS,
  UPDATE_SEQUENCE,
  SEQUENCE_ERROR,
  SEQUENCE_DETAIL,
  GET_SEQUENCE_REPORTS,
  STEPS_REPORTS,
  SEQUENCE_RECIPIENTS,
  STEP_TEMPLATES,
  UPDATE_STEP_TEMPLATE,
  GET_TWILIO_NUMBERS,
  LOAD_SEQUENCE_TABS,
  SET_SEQUENCED_DEFAULT_FILTER_DATA,
  SHOW_TAB_GRID_DATA_LOADER,
  LOAD_SEQUENCE_GRID_DATA,
} from '../actions/types';

const initialState = {
  campaign: [],
  categories: [],
  sequences: [],
  recipients: [],
  templates: [],
  details: {},
  stats: {},
  steps: [],
  twilioNumbers: [],
  loading: true,
  error: {},
  sequenceGridTabs: {
    tabs: [],
    loading: true,
  },
  count: 0,
  reload: false,
};

const updateTemplateStatus = (templates, params) => {
  // Encapsulate the idea of passing a new object as the first parameter
  // to Object.assign to ensure we correctly copy data instead of mutating
  //Find index of specific object using findIndex method.
  const objIndex = templates.findIndex((obj) => obj.id === params.id);
  //Update object's status property.
  templates[objIndex].status = params.status;
  return templates;
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORIES: {
      const data = {
        ...state,
        categories: payload,
        loading: false,
      };
      return data;
    }
    case GET_SEQUENCES: {
      const data = {
        ...state,
        sequences: payload,
        loading: false,
      };
      return data;
    }
    case CREATE_SEQUENCE:
    case CLONE_SEQUENCE: {
      return {
        ...state,
        reload: true,
      };
    }
    case CREATE_STEPS:
    case UPDATE_STEPS:
    case DELETE_STEPS:
      return {
        ...state,
        loading: false,
      };
    case GET_SEQUENCE:
    case UPDATE_SEQUENCE:
      return {
        ...state,
        campaign: payload,
        loading: false,
        reload: true,
      };
    case SEQUENCE_DETAIL:
      return {
        ...state,
        details: payload,
        loading: false,
      };
    case GET_SEQUENCE_REPORTS:
      return {
        ...state,
        stats: payload,
        loading: false,
      };
    case STEPS_REPORTS:
      return {
        ...state,
        steps: payload,
        loading: false,
      };
    case SEQUENCE_RECIPIENTS:
      return {
        ...state,
        recipients: payload,
        loading: false,
      };
    case STEP_TEMPLATES: {
      const templateListIndex = (state.campaign.events || []).findIndex(
        (tList) => tList.id === action.campaign.stepEventId,
      );
      if (templateListIndex > -1) {
        return {
          ...state,
          templates: payload,
          campaign: {
            ...state.campaign,
            events: [
              ...state.campaign.events.slice(0, templateListIndex),
              {
                ...state.campaign.events[templateListIndex],
                templateList: payload,
              },
              ...state.campaign.events.slice(templateListIndex + 1),
            ],
          },
          loading: false,
        };
      } else {
        return {
          ...state,
          templates: payload,
          loading: false,
        };
      }
    }

    case UPDATE_STEP_TEMPLATE:
      return {
        ...state,
        templates: updateTemplateStatus(state.templates, payload),
        loading: false,
      };
    case GET_TWILIO_NUMBERS:
      return {
        ...state,
        twilioNumbers: payload,
        loading: false,
      };
    case SEQUENCE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        profile: null,
      };
    case LOAD_SEQUENCE_TABS: {
      const tabsData = {};
      payload.tabs.forEach((e) => {
        tabsData[e.id] = {
          sequenceList: [],
          myFilterData: [],
          filterList: {},
          filterState: {},
          defaultFilterState: {},
          count: 0,
          loading: true,
        };
      });

      return {
        ...state,
        sequenceGridTabs: {
          ...state.sequenceGridTabs,
          ...payload,
          loading: false,
        },
        ...tabsData,
      };
    }
    case SET_SEQUENCED_DEFAULT_FILTER_DATA: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload[payload.id],
        },
        count: 0,
      };
    }
    case SHOW_TAB_GRID_DATA_LOADER: {
      return {
        ...state,
        [payload.tabData.id]: {
          ...state[payload.tabData.id],
          loading: true,
        },
        count: 0,
      };
    }
    case LOAD_SEQUENCE_GRID_DATA: {
      const id = Object.keys(payload)[0];
      return {
        ...state,
        [id]: {
          ...state[id],
          ...payload[id],
        },
        count: payload[id]?.count || 0,
        reload: false,
      };
    }
    default:
      return state;
  }
}
