import React from 'react';
import { useSelector } from 'react-redux';

import SidebarNav from '../includes/NewSidebarNav';
import HeaderAlert from '../components/HeaderAlert';

/*
 * This component is like hoc to render the constant component and business logic for the private
 * This will have sidebar menu items that needed to render base on the category
 */
const AuthLayout = (props) => {
  // pass array in the menu item
  const showProfileAlert = useSelector((state) => state.app.showProfileAlert);
  return (
    <div>
      <div className="flex-row">
        <aside className="sideBarMargin">
          <SidebarNav />
        </aside>

        <div style={{ width: '100%' }}>
          <HeaderAlert />

          <div
            style={showProfileAlert ? { height: 'calc(100vh - 191px)' } : {}}
            className="flex-col content"
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
