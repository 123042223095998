/**
 * this function will convert the query string into json object
 * @param queryString value be of location.search only
 * @returns json object
 */
export const convertQueryString = (queryString) => {
  const pairs = queryString.slice(1).split('&');

  const result = {};
  pairs.forEach((pair) => {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });

  return result;
};

/**
 * this function will convert the json object to query string
 * @param data value be of location.search only
 * @returns string
 */
export const convertObjectToQuerystring = (data) => {
  return Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join('&');
};
