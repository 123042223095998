import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import PageHeader from '../../../components/PageHeader';
import TaskTableToolbar from '../../../controlled-component/customGridComponent/CustomToolbar/TableToolbar';
import EditSyncSlider from './EditSyncSlider';
import CustomDataGrid from '../../../controlled-component/customGridComponent';
import LoadingTableBody from '../../../components/LoadingTableBody';
import Dialog from '../../../components/Dialog';
import ActionButtonMenu from '../../../Sequence/SequenceDetail/ActionButtonMenu';
import { ActiveIcon, InActiveIcon } from '../../../components/GridIcons';
import { fetchIntegrationDetail } from '../../../actions/admin/integration';
import { english } from '../../../utils/language';

// IntegrationDetail styling
const useStyles = makeStyles(({ custom }) => ({
  integrationDetailGridSection: {},
  integrationGridWrapper: {
    padding: '10px 0 20px 0',
  },
  integrationGrid: {
    marginTop: 12,
  },
  tdDivLineHeight: {
    lineHeight: 3,
  },
  actionButton: {
    color: custom.colorCode.white,
    height: 30,
    backgroundColor: custom.colorCode.lightRedColor,
  },
}));

// main integration menu item
const menuItem = [
  {
    title: 'Disconnect',
    itemType: 'disconnect',
  },
];

const IntegrationDetailGrid = (props) => {
  const { history } = props;
  const classes = useStyles();

  const [facetdata, setFacetdata] = useState({});
  const [facetfields, setFacetfields] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [openEditSyncSlider, setOpenEditSyncSlider] = useState(false);
  const [integrationSyncData, setIntegrationSyncData] = useState({});
  const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const [integrationListId, setIntegrationListId] = useState(null);

  const filterStateRef = useRef({});
  const gridParams = useRef({});
  const gridColParams = useRef({});
  const filterStateParams = useRef({});

  const integrationDetail = useSelector((state) => state.integration.integrationDetail[props.tabProps.id] || {});
  const setDetailListLoading = useSelector((state) => state.integration.setDetailListLoading);
  const dispatch = useDispatch();

  // const integration = useSelector((state) => state.integration);

  useEffect(() => {
    gridParams.current = integrationDetail?.gridParams;
  }, [integrationDetail?.gridParams]);

  useEffect(() => {
    filterStateParams.current = integrationDetail.filterState;
  }, [integrationDetail.filterState]);

  useEffect(() => {
    if (!isEmpty(integrationDetail?.columns)) {
      gridColParams.current = integrationDetail?.columns;
    }
  }, [integrationDetail?.columns]);

  useEffect(() => {
    // to update the integrationDetail option
    if (integrationDetail?.list?.length && integrationDetail?.myFilterData?.length) {
      setFacetdata({});
      setFacetfields(
        integrationDetail.myFilterData.map((e) => ({
          label: e.name,
          value: e.title,
        })),
      );
    }
    filterStateRef.current = integrationDetail.filterState;
  }, [integrationDetail]);

  useEffect(() => {
    // did mount
    // call the integration detail api and set data in reducersetReloadGrid(false);
    dispatch(fetchIntegrationDetail(props.tabProps));
  }, []);


  useEffect(() => {
    if (!isEmpty(integrationDetail.filterList)) {
      // call the fetch template api and set data in reducer
      // dispatch(
      //   fetchTemplateGridData(
      //     undefined,
      //     templates.gridParams
      //       ? templates.gridParams
      //       : { pageNo: 0, perPage: 25, searchText: null },
      //     props.tabProps,
      //     undefined
      //   )
      // );
    }
  }, [integrationDetail.filterList]);

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: english.name,
        options: {
          filter: false,
          sort: true,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            if (!integrationDetail?.list) {
              return null;
            }
            const rData = integrationDetail?.list[dataIndex];
            return (
              <Tooltip title={rData.name || ''} arrow>
                <div
                  className={`td-content-ellipsis ${classes.tdDivLineHeight}`}
                >
                  {rData.name}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: english.status,
        name: 'status',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 300 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 300 },
          }),
          customBodyRender: (value) => {
            return !value ? (
              <InActiveIcon fontSize="small" />
            ) : (
              <ActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            if (!integrationDetail?.list) {
              return null;
            }
            const rData = integrationDetail?.list.find(
              (tData) => tData.id === value,
            );
            return (
              <GridActions
                stopSyncOpenSlider={stopSyncOpenModal}
                editSyncOpenSlider={editSyncSlider}
                temp_id={value}
                row={rData}
              />
            );
          },
        },
      },
    ],
    [integrationDetail],
  );

  const handleReFetch = (filterObject) => {

    dispatch(
      fetchIntegrationDetail(props.tabProps,
        {
          ...(isEmpty(filterObject) ? {} : filterObject),
        },
        {
          ...gridParams.current,
          pageNo: 0,
          perPage: 25,
        },
        props.tabProps,
        gridColParams.current,
      )
    );
    // dispatch(
    //   fetchIntegrationDetail(
    //     {
    //       ...(isEmpty(filterObject)
    //         ? {}
    //         : filterObject),
    //     },
    //     {
    //       ...gridParams.current,
    //       pageNo: 0,
    //       perPage: 25,
    //     },
    //     props.tabProps,
    //     gridColParams.current
    //   )
    // );
  };

  const stopSyncOpenModal = (integrationListId, integrationListRow) => {
    if (integrationListRow.status) {
      setIntegrationListId(integrationListId);
    } else {
      // setIntegrationListId(integrationListId);
    }
  };

  const stopSyncCloseModal = () => {
    setIntegrationListId(null);
  };

  const disconnectIntegration = () => {
    setDisconnectDialogOpen(!disconnectDialogOpen);
  };

  const handleDisconnectDialogClose = () => {
    setDisconnectDialogOpen(!disconnectDialogOpen);
  };

  const editSyncSlider = (id, rData) => {
    setOpenEditSyncSlider(true);
    setIntegrationSyncData(rData);
  };

  const editCloseSlider = () => {
    setOpenEditSyncSlider(false);
  };

  const handleColumnChange = (showLoader) => (paramColumns) => {
    dispatch(
      fetchIntegrationDetail(
        {
          ...filterStateParams.current,
        },
        gridParams.current,
        props.tabProps,
        paramColumns,
        showLoader
      )
    );
  };

  /**
   * Tool Bar for grid
   */
  const TableToolbar = useMemo(
    () => (props) => {
      return (
        <TaskTableToolbar
          refetch={handleReFetch}
          myFilterData={integrationDetail?.myFilterData || []}
          defaultFilterState={integrationDetail?.defaultFilterState}
          filterState={integrationDetail?.filterState}
          visibleFilter={1}
          filterDrawerName={'Filters'}
          showEditCol
          onColumnChange={handleColumnChange()}
          loading={setDetailListLoading}
          {...props}
        />
      );
    },
    [integrationDetail?.myFilterData, integrationDetail?.defaultFilterState]
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => (
      <LoadingTableBody loading={setDetailListLoading} {...props} />
    ),
    [setDetailListLoading],
  );

  const handleMenuClose = (type) => {
    switch (type) {
      case 'disconnect': {
        // disconnect function will all here
        disconnectIntegration();
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={classes.integrationDetailGridSection}>
      {/*common page header*/}
      {/*<PageHeader*/}
      {/*  mainTitle={'Bullhorn'}*/}
      {/*  subTitle={'Data Sync - Sync Bullhorn data.'}*/}
      {/*  itemCount={integrationDetail?.filterList?.list?.length || 0}*/}
      {/*  showRightHeading*/}
      {/*  rightHeadingContent={*/}
      {/*    <>*/}
      {/*      <ActionButtonMenu*/}
      {/*        buttonClassName={classes.actionButton}*/}
      {/*        showEditStep={false}*/}
      {/*        menusItem={menuItem}*/}
      {/*        onMenuClose={handleMenuClose}*/}
      {/*      />*/}
      {/*    </>*/}
      {/*  }*/}
      {/*/>*/}
      <div className={classes.integrationGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns.map((col, index) => ({
            ...col,
            options: integrationDetail?.columns?.columns?.length
              ? {
                  ...col.options,
                  ...integrationDetail?.columns?.columns[index],
                }
              : col.options,
          }))}
          paperWidth={'290px'}
          data={integrationDetail?.list || []}
          className={classes.integrationGrid}
          onColumnChange={handleColumnChange(false)}
          options={{
            pagination: Boolean(integrationDetail?.list?.length || 0),
            filterType: 'dropdown',
            facetdata: facetdata,
            facetfields: facetfields,
            selectableRows: 'none',
            responsive: 'simple',
            searchPlaceholder: 'Search',
            selectToolbarPlacement: 'none',
            searchOpen: true,
            search: true,
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
              setRowsSelected(allRows.map((row) => row.dataIndex));
            },
          }}
          components={{
            TableBody: BodyComponent,
            TableToolbar: TableToolbar,
          }}
        />
      </div>
      <EditSyncSlider
        toggleOpen={openEditSyncSlider}
        closeSlider={editCloseSlider}
        selectedSyncData={integrationSyncData}
      />
      <Dialog
        open={disconnectDialogOpen}
        title="Disconnect Bullhorn integration"
        content={english.integrationDisconnectDialogSubTitle}
        handleClick={disconnectIntegration}
        onClose={handleDisconnectDialogClose}
        buttonText={english.confirm}
      />

      <Dialog
        objectId={integrationListId}
        open={Boolean(integrationListId)}
        title={`${english.integrationDisconnectSyncDialogTitle} ${
          (
            integrationDetail?.list?.find(
              (e) => e.id === integrationListId,
            ) || {}
          ).name
        }`}
        content={english.integrationDisconnectSyncDialogSubTitle}
        handleClick={stopSyncCloseModal}
        onClose={stopSyncCloseModal}
        buttonText={english.confirm}
      />
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const [anchorElement, setAnchorElement] = useState(null);

  //const editable = user.id === row.created_by;
  const menuId = 'integration-detail-action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'stopSync': {
        props.stopSyncOpenSlider(props.temp_id, props.row);
        break;
      }
      case 'editSync': {
        props.editSyncOpenSlider(props.temp_id, props.row);
        break;
      }
      case 'copySequence': {
        // props.copySequence(props.temp_id, props.row);
        break;
      }
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: 'Edit Sync',
      itemType: 'editSync',
    },
    {
      title: props.row.status ? 'Stop Sync' : 'Start Sync',
      itemType: 'stopSync',
    },
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default IntegrationDetailGrid;
