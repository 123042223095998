import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Toolbar,
  Box,
  Typography,
  Menu,
  MenuItem,
  TextField,
  Fade,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ToolBarPickers from '../../controlled-component/customGridComponent/ToolBarPickers';
import DrawerFilter from '../../controlled-component/customGridComponent/DrawerFilter';
import EditColumnPopup from '../../controlled-component/customGridComponent/EditColumns';
import CustomDrawer from '../../components/CustomDrawer';
import DrawerFormHeader from '../../components/DrawerFormHeader';
import Button from '../../components/Button';
import CustomDateRangePicker from './CustomDateRangePicker';
import { dateFormat } from '../../utils/applicationConstant';

/**
 * Custom styling for date picker filters
 */
const useStyles = makeStyles(({ custom }) => ({
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
    '& .formControl-label': {
      fontSize: 16,
      color: custom.colorCode.black,
      marginRight: 20,
      // marginBottom: 3,
    },
  },
  selectEmpty: {},
  selectEmptyRoot: {
    color: custom.colorCode.fontBlack,
    fontWeight: 700,
    fontSize: 14,
  },
  selectEmptyIcon: {
    color: custom.colorCode.fontBlack,
  },
  summaryDatePicker: {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: 120,
    '& .MuiIconButton-root': {
      paddingRight: 0,
      paddingLeft: 0,
      display: 'block',
    },
    '& button': {
      display: 'flex !important',
    },
  },
  actionButton: {
    height: 30,
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    '& span': {
      fontSize: 15,
    },
    ':hover': {
      backgroundColor: custom.colorCode.blueLightShade11,
    },
    ':focus': {
      backgroundColor: custom.colorCode.blueLightShade11,
    },
  },
  popoverStyle: {
    left: '-50px !important',
    '& button': {
      display: 'flex !important',
    },
  },
  userAutoComplete: {
    '& button': {
      display: 'flex !important',
    },
    '& .MuiSvgIcon-root': {
      color: custom.colorCode.fontBlack,
    },
    '& .MuiAutocomplete-input': {
      minWidth: '50px',
      maxWidth: 'auto',
      fontSize: 14,
      fontWeight: 700,
    },
  },
  buttonSelectContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filterMenuSearch: {
    width: '100%',
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: 8,
    minWidth: 300,
    overflowY: 'unset',
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuPaperList: {},
  menuItemWrapper: {
    overflow: 'auto',
    maxHeight: 230,
  },
  checkBoxLabel: {
    marginBottom: 0,
  },
  titleSelect: {
    padding: 6,
    marginRight: 12,
    cursor: 'pointer',
    borderRadius: 4,
    '& svg': {
      cursor: 'pointer',
    },
  },
  active: {
    backgroundColor: custom.colorCode.lightGreyColorShade17,
  },
  selectDatePicketText: {
    display: 'flex',
    marginTop: 3,
    cursor: 'pointer',
  },
}));

/**
 * Default toolbar styles
 */
export const defaultToolbarStyles = (theme) => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  fullWidthRoot: {},
  left: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
  },
  fullWidthLeft: {
    flex: '1 1 auto',
  },
  actions: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  fullWidthActions: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  titleRoot: {},
  titleText: {},
  fullWidthTitleText: {
    textAlign: 'left',
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  iconActive: {
    color: theme.palette.primary.main,
  },
  filterPaper: {
    maxWidth: '30%',
    /*  maxWidth: '300px', */
    /* top:'60px !important',
    right:'0', */
    minWidth: '300px',
    /* minHeight:'500px',
    maxHeight:'600px', */
  },
  filterCloseIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
  },
  searchIcon: {
    display: 'inline-flex',
    marginTop: '10px',
    marginRight: '8px',
  },
  [theme.breakpoints.down('sm')]: {
    titleRoot: {},
    titleText: {
      fontSize: '16px',
    },
    spacer: {
      display: 'none',
    },
    left: {
      // flex: '1 1 40%',
      padding: '8px 0px',
    },
    actions: {
      // flex: '1 1 60%',
      textAlign: 'right',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      display: 'block',
      '@media print': {
        display: 'none !important',
      },
    },
    left: {
      padding: '8px 0px 0px 0px',
    },
    titleText: {
      textAlign: 'center',
    },
    actions: {
      textAlign: 'center',
    },
  },
  typoGraphSelect: {
    fontSize: 14,
    letterSpacing: '0.3px',
    marginRight: 20,
    '& .fa': {
      marginLeft: 5,
    },
  },
  filterMenuSearch: {},
  drawerPaper: {
    width: 400,
  },
  drawerFilters: {
    marginBottom: 8,
  },
  drawerFilterHeaderTitle: {
    paddingLeft: 0,
  },
  drawerFilterWrapper: {
    padding: '0 20px',
  },
  resetLink: {
    color: theme.custom.colorCode.blueLightShade12,
    outline: 'unset !important',
    backgroundColor: 'transparent !important',
    border: 'transparent',
    textTransform: 'capitalize',
  },
  '@media screen and (max-width: 480px)': {},
  selectToolbar: {
    boxShadow: 'none',
    borderBottom: 'none',
    borderRadius: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
  selectToolbarEditCol: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    padding: 8,
    marginRight: 8,
    cursor: 'pointer',
  },
});

const RESPONSIVE_FULL_WIDTH_NAME = 'scrollFullHeightFullWidth';

/**
 * Toolbar component for contacts/candidates Grid
 */
const CustomTableToolbar = ({
  options,
  classes,
  title,
  myFilterData: filterData,
  displayData,
  defaultFilterState,
  visibleFilter = 0,
  filterState,
  showEditCol = false,
  loading,
  flist,
  showMoreFilterOption = true,
  showFilterOption = true,
  showDatePicker = true,
  selectPlaceHolder,
  datePlaceHolder,
  selectNameToSave,
  dateRangeNameToSave,
  selectDataOptions,
  datePickerDataOptions,
  userFilterSelectValue,
  userFilterDateValue,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');
  const [activeSearch] = useState(true);
  const [myFilterData, setMyFilterData] = useState(filterData);
  const [gridFilter, setGridFilter] = useState({});
  const [customFilter, setCustomFilter] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editTaskPopup, setEditTaskPopup] = useState(false);
  const customClasses = useStyles();
  const [formData, setFormData] = useState({
    user: '',
    date_range: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [showDatePopOver, setShowDatePopOver] = useState(null);
  const [datePickerLabel, setDatePickerLabel] = useState(false);

  useEffect(() => {
    if (options?.searchText && options?.searchText !== searchText) {
      setSearchText(options?.searchText);
    }
  }, [options.searchText]);

  useEffect(() => {
    setFilterOptions([...selectDataOptions]);
  }, [selectDataOptions]);

  /**
   * Handle select change event and refetch data call
   */
  const handleSelectChange = (name, tag) => {
    const newFormData = {
      ...formData,
      [name]: tag === 'all' ? '' : tag.id,
    };
    setFormData(newFormData);
    handleCustomToolbarFilterChange(newFormData);
  };

  useEffect(() => {
    let newFormData = {
      user: '',
      date_range: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    };
    // get the custom date picker data and set in local state
    if (userFilterSelectValue?.length) {
      newFormData.user = userFilterSelectValue[0];
    }
    if (userFilterDateValue !== '') {
      if (userFilterDateValue?.data) {
        newFormData.date_range = 10;
        newFormData.startDate = userFilterDateValue?.data.startDate;
        newFormData.endDate = userFilterDateValue?.data.endDate;
      } else {
        newFormData.date_range = userFilterDateValue;
      }
      setDatePickerLabel(
        datePickerDataOptions.find((i) => i.key == newFormData.date_range)
          ?.value,
      );
    } else {
      setDatePickerLabel('All');
    }
    setFormData(newFormData);
  }, [filterState]);

  useEffect(() => {
    const defaultFilters = { ...defaultFilterState, ...filterState };
    if (!isEmpty(defaultFilters)) {
      Object.keys(defaultFilters).filter(
        (e) => e !== 'pageNo' && e !== 'perPage',
      );
      let payload = {};
      let customFilters = {};
      const defaultOne = Object.keys(defaultFilters).filter(
        (e) => e !== 'pageNo' && e !== 'perPage',
      );

      defaultOne.forEach((e) => {
        const ha = filterData.find((fData) => e === fData.type)?.data;
        if (e == 'completeDate') {
          let completedDatedata = filterData.find((fData) => e === fData.type);
          if (completedDatedata) {
            payload = {
              ...payload,
              [e]: {
                name: e,
                filter: [defaultFilters[e]],
              },
            };
            return;
          }
        }

        if (!ha) {
          customFilters = {
            ...customFilters,
            [e]: defaultFilters[e],
          };
          return;
        }
        payload = {
          ...payload,
          [e]: {
            name: e,
            filter: ha.filter((h1) =>
              h1.isSingle
                ? defaultFilters[e] === h1.id
                : defaultFilters[e].includes(h1.id),
            ),
          },
        };
      });
      setCustomFilter({ ...customFilters });
      setGridFilter({
        ...payload,
      });
    }
  }, [defaultFilterState]);

  /**
   * Handle search event
   */
  const handleSearch = (value) => {
    setSearchText(value);
    props.searchTextUpdate(value);
  };

  /**
   * Hide search bar call
   */
  const hideSearch = () => {
    const { onSearchClose } = options;
    props.setTableAction('onSearchClose');
    if (onSearchClose) onSearchClose();
    props.searchClose();
    setSearchText(null);
  };

  /**
   * Handle edit column button click
   */
  const handleEditColumnsClick = () => {
    setEditTaskPopup(!editTaskPopup);
  };

  /**
   * Handle when custom toolbar filter changes
   */
  const handleCustomToolbarFilterChange = (data) => {
    let isSingle = null;
    let assingnedUser = [];
    let customFilters = {
      ...customFilter,
    };
    let filters = {
      ...gridFilter,
    };

    if (data.date_range == 10) {
      customFilters[dateRangeNameToSave] = {
        data: { startDate: data?.startDate, endDate: data?.endDate },
      };
    } else {
      customFilters[dateRangeNameToSave] = data.date_range;
    }
    if (data.user !== '') {
      assingnedUser.push(data.user);
      customFilters[selectNameToSave] = assingnedUser;
    } else {
      customFilters = Object.keys(customFilters)
        .filter((key) => key !== selectNameToSave)
        .reduce((obj, key) => {
          obj[key] = customFilters[key];
          return obj;
        }, {});
    }
    setCustomFilter({ ...customFilters });

    let filterObject = {};
    Object.keys(filters).forEach((e) => {
      filterObject =
        myFilterData.find((fData) => fData.type === e)?.isSingle && isSingle
          ? {
              ...filterObject,
              [e]:
                filters[e]?.filter && typeof filters[e]?.filter != 'function'
                  ? filters[e]?.filter?.map((f) => f.id)
                  : filters[e],
            }
          : {
              ...filterObject,
              [e]:
                filters[e]?.filter && typeof filters[e]?.filter != 'function'
                  ? filters[e]?.filter?.map((f) => f.id)
                  : filters[e],
            };
    });
    filterObject = { ...filterObject, ...customFilters };
    props.refetch(filterObject);
  };

  /**
   * Handle grid default filters change
   */
  const handleFilterChange = ({ isSingle, type, control, ...data }) => {
    if (data.name === 'moreFilter') {
      setOpenDrawer(true);
      return;
    }
    let filters = {
      ...gridFilter,
    };
    if (data.filter?.length) {
      filters = {
        ...filters,
        [data.name]: {
          ...data,
        },
      };
    } else {
      if (control === 'dateRangePicker') {
        filters[type] = {
          data: { ...data.data },
        };
      } else {
        delete filters[data.name];
      }
    }
    setGridFilter({ ...filters });
    let filterObject = {};
    Object.keys(filters).forEach((e) => {
      if (
        myFilterData.find((fData) => fData.type === e).control ===
        'dateRangePicker'
      ) {
        filterObject = {
          ...filterObject,
          [e]: filters[e],
        };
      } else {
        filterObject =
          myFilterData.find((fData) => fData.type === e)?.isSingle && isSingle
            ? {
                ...filterObject,
                [e]: filters[e]?.filter
                  ? filters[e]?.filter.map((f) => f.id)
                  : filters[e],
              }
            : {
                ...filterObject,
                [e]: filters[e]?.filter
                  ? filters[e]?.filter.map((f) => f.id)
                  : filters[e],
              };
      }
    });
    filterObject = { ...filterObject, ...customFilter };
    props.refetch(filterObject);
  };

  /**
   * Clear all drawers filters
   */
  const clearDrawersAllFilters = () => {
    setGridFilter({});
    let filterObject = { ...customFilter };
    props.refetch(filterObject);
  };

  /**
   * Handle Close drawer event
   */
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  /**
   * Handle Choosen close event
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handle Choosen open event on click
   */
  const handleBoxClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handle Choosen search change
   */
  const onType = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value) {
      const newFilteredOptions = selectDataOptions.filter((option) =>
        option['name']
          .toLocaleLowerCase()
          .includes(event.target.value.toLocaleLowerCase()),
      );
      setFilterOptions(newFilteredOptions);
    } else {
      setFilterOptions([...selectDataOptions]);
    }
  };

  /**
   * Get Selected title for select
   */
  const getFilterTitle = () => {
    let title = selectPlaceHolder;
    if (formData.user) {
      let selectedOption = selectDataOptions.find(
        (c) => c.id === formData?.user,
      );
      if (selectedOption) {
        title = selectedOption.name;
      }
    }
    return title;
  };

  /**
   * Open date picker
   */
  const openDatePicker = (event) => {
    setShowDatePopOver(event.currentTarget);
  };

  /**
   * Handle date picker close
   */
  const handleDatePickerClose = () => {
    setShowDatePopOver(null);
  };

  /**
   * Handle date picker change event
   */
  const handleDatePickerChange = (dateData) => {
    let newFormData = { ...formData };
    if (!dateData?.id) {
      newFormData.date_range = 10;
      newFormData.startDate = moment(dateData.startDate).format('YYYY-MM-DD');
      newFormData.endDate = moment(dateData.endDate).format('YYYY-MM-DD');
    } else if (dateData?.id == 50) {
      newFormData.date_range = '';
      newFormData.startDate = '';
      newFormData.endDate = '';
    } else {
      newFormData.date_range = dateData.id;
      newFormData.startDate = '';
      newFormData.endDate = '';
    }
    setDatePickerLabel(dateData?.label || '');
    setFormData(newFormData);
    handleCustomToolbarFilterChange(newFormData);
    handleDatePickerClose();
  };

  const numberOfFilterVisible = visibleFilter ? visibleFilter : 0;

  return (
    <Toolbar
      className={
        options.responsive !== RESPONSIVE_FULL_WIDTH_NAME
          ? classes.root
          : classes.fullWidthRoot
      }
      role={'toolbar'}
      aria-label={'Table Toolbar'}
    >
      <div
        className={
          options.responsive !== RESPONSIVE_FULL_WIDTH_NAME
            ? classes.left
            : classes.fullWidthLeft
        }
      >
        {activeSearch === true ? (
          options.customSearchRender ? (
            options.customSearchRender(
              searchText,
              handleSearch,
              hideSearch,
              options,
            )
          ) : (
            <div></div>
          )
        ) : (
          typeof title !== 'string' && title
        )}
        {showFilterOption && (
          <Box display="flex">
            <Box
              display="flex"
              alignItems="center"
              className={`${customClasses.titleSelect} ${
                formData.user ? customClasses.active : ''
              }`}
            >
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={handleBoxClick}
                >
                  {getFilterTitle()}
                  <svg
                    className="MuiSvgIcon-root MuiSelect-icon"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M7 10l5 5 5-5z" />
                  </svg>
                </Box>
                {Boolean(formData.user) && (
                  <CloseIcon
                    onClick={() => handleSelectChange('user', 'all')}
                    fontSize="small"
                  />
                )}
              </>
            </Box>
            <Menu
              id={'sequence-menu-id'}
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              TransitionComponent={Fade}
              classes={{
                paper: customClasses.menuPaper,
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <TextField
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  onChange={(event) => {
                    onType(event);
                  }}
                  value={searchValue || ''}
                  className={customClasses.filterMenuSearch}
                />
              </MenuItem>
              {filterOptions.length ? (
                <Box className={customClasses.menuItemWrapper}>
                  {filterOptions.map((option, index) => (
                    <MenuItem
                      key={`option-menu-check-${index}`}
                      value={option['id']}
                      selected={Boolean(option['id'] == formData?.user)}
                    >
                      <Box
                        onClick={() => handleSelectChange('user', option)}
                        style={{ width: '100%' }}
                      >
                        {option['name']}
                      </Box>
                    </MenuItem>
                  ))}
                </Box>
              ) : (
                <MenuItem disabled>
                  <Typography>No options.</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        )}

        {showDatePicker && (
          <div className={customClasses.selectContainer}>
            <span className="formControl-label"> {datePlaceHolder} </span>
            <Typography
              onClick={openDatePicker}
              variant="body2"
              component={'div'}
              className={customClasses.selectDatePicketText}
            >
              {formData?.startDate && formData?.endDate
                ? datePickerLabel
                  ? datePickerLabel
                  : `${moment(formData?.startDate).format(
                      dateFormat,
                    )} to ${moment(formData?.endDate).format(dateFormat)}`
                : 'All'}
              <ArrowDropDownIcon fontSize="small" />
            </Typography>
            <CustomDateRangePicker
              onClose={handleDatePickerClose}
              open={Boolean(showDatePopOver)}
              anchorEl={showDatePopOver}
              dateRange={formData}
              datePickerDataOptions={datePickerDataOptions}
              onDatePickerChange={handleDatePickerChange}
            />
          </div>
        )}

        <Box display="flex">
          {showMoreFilterOption && (
            <ToolBarPickers
              myFilterData={
                myFilterData.slice(numberOfFilterVisible).length
                  ? [
                      ...myFilterData.slice(0, numberOfFilterVisible),
                      {
                        type: 'moreFilter',
                        title: 'More Filter',
                        data: myFilterData.slice(numberOfFilterVisible + 1),
                      },
                    ]
                  : myFilterData.slice(0, numberOfFilterVisible)
              }
              myFilters={gridFilter}
              handleChange={handleFilterChange}
            />
          )}
        </Box>
      </div>

      <Box display="flex" alignItems="center">
        {showEditCol && !loading && (
          <Typography
            variant="body1"
            className={classes.selectToolbarEditCol}
            onClick={handleEditColumnsClick}
          >
            Edit Columns
          </Typography>
        )}
        {options.customToolbar &&
          options.customToolbar({ displayData: displayData })}
      </Box>
      <Box>
        <CustomDrawer
          anchor={'right'}
          open={openDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerClose}
        >
          <DrawerFormHeader
            handleCloseClick={handleDrawerClose}
            titleText={props.filterDrawerName || 'Filters'}
            formHeaderTitleClassName={classes.drawerFilterHeaderTitle}
            drawerElement={
              <Button
                color="primary"
                className={classes.resetLink}
                tabIndex={0}
                onClick={clearDrawersAllFilters}
              >
                Clear All
              </Button>
            }
          >
            <Box className={classes.drawerFilterWrapper}>
              <DrawerFilter
                myFilterData={myFilterData.slice(numberOfFilterVisible)}
                handleChange={handleFilterChange}
                myFilter={gridFilter}
              />
            </Box>
          </DrawerFormHeader>
        </CustomDrawer>

        <EditColumnPopup
          handleToggleDialog={setEditTaskPopup}
          editClosePopup={editTaskPopup}
          columns={props.columns}
          columnOrder={props.columnOrder}
          onColumnChange={props.onColumnChange}
        />
      </Box>
    </Toolbar>
  );
};

export default withStyles(defaultToolbarStyles, {
  name: 'MUIDataTableToolbar',
})(CustomTableToolbar);
