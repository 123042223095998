import React, { useState } from 'react';
import { Fade, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  menuPaper: {
    maxHeight: 300,
    marginTop: 4,
  },
}));

const MenuDropDown = ({ children, id = 'menu-bottom-select', ...props }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      TransitionComponent={Fade}
      classes={{
        paper: classes.menuPaper,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      {...props}
    >
      {children}
    </Menu>
  );
};

export default MenuDropDown;
