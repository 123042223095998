import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Menu, MenuItem, Typography, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';

import PageHeader from '../../components/PageHeader';
import CustomDataGrid from '../../controlled-component/customGridComponent';
import AddButton from '../../components/CreateToolbarButton';
import LoadingTableBody from '../../components/LoadingTableBody';
// import CreateTemplate from '../CreateTemplate';
import CreateTemplate from '../CreateTemplate/CreateTemplate';
import EditTemplate from '../EditTemplate';
import { ActiveIcon, InActiveIcon } from '../../components/GridIcons';

import {
  getUserLinkedTemplate,
  updateTemplateStatus,
} from '../../actions/template';
import { makeProperNamingStatement } from '../../utils';

import 'react-toastify/dist/ReactToastify.css';

// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  gridWrapper: {
    padding: '10px 0 20px 0',
  },
}));

const TemplateGrid = (props) => {
  const classes = useStyles();

  const [popupToggle, setPopupToggle] = useState(false);
  const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [templateId, setTemplateId] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const template = useSelector((state) => state.template);

  //handle function to open add template popup
  const handlePopupOpen = () => {
    setTemplateId(false);
    setPopupToggle(!popupToggle);
  };

  //handle function to close add template popup
  const handlePopupClose = () => {
    setPopupToggle(false);
    setTemplateId(false);
    setEditPopupToggle(false);
  };

  // handle the edit user pop up
  const handleEditPopupOpen = (id) => {
    setEditPopupToggle(!editPopupToggle);
    setTemplateId(id);
  };

  // update status with reducer
  const changeStatus = (templateId, data) => {
    const status = data.is_published === 'Un-Published' ? 1 : 2;
    dispatch(updateTemplateStatus(templateId, status, 'linkedin_grid', null));
  };

  const getLinkedTemplate = async () => {
    await props.getUserLinkedTemplate(
      undefined,
      props.admin ? props.admin : null,
    );
    setIsLoading(false);
  };

  useEffect(() => {
    // did mount
    // call the linked template api and set data in reducer
    getLinkedTemplate();
  }, []);

  /**
   * Function to handle click on name
   * @param {*} e
   * @param {*} data
   */
  const handleNameClick = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    handleEditPopupOpen(data.id);
  };

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Template Name',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = template.templates[dataIndex];
            return (
              <Tooltip title={rData?.name || ''} arrow>
                <div
                  className={`td-content-ellipsis`}
                  onClick={(e) => handleNameClick(e, rData)}
                >
                  <span className={`td-anchor-content-ellipsis`}>
                    {rData.name}
                  </span>{' '}
                  ({rData.id})
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Owner',
        name: 'created_by_user',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{makeProperNamingStatement(value || '')}</div>;
          },
        },
      },
      {
        label: 'Created Date',
        name: 'created_at',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Status',
        name: 'is_published',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return value === 'Un-Published' ? (
              <InActiveIcon fontSize="small" />
            ) : (
              <ActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = template.templates.find((tData) => tData.id === value);
            return (
              <GridActions
                temp_id={row?.id}
                status={row?.status}
                row={row}
                handleEditPopupOpen={handleEditPopupOpen}
                updateStatus={changeStatus}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [template.templates],
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={isLoading} {...props} />,
    [isLoading],
  );

  return (
    <div className={classes.templateGridMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={'LinkedIn Templates'}
        subTitle={
          'Set up and manage LinkedIn templates for all users, to help increase their efficiency.'
        }
        itemCount={template.templates?.length || 0}
      />
      <div className={classes.gridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns}
          data={template.templates}
          options={{
            pagination: Boolean(template.templates?.length),
            selectableRows: 'multiple',
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search Template',
            selectToolbarPlacement: 'none',
            searchOpen: true,
            search: true,
            customToolbar: () => {
              // Toolbar for search and add
              return (
                <AddButton
                  handleClick={handlePopupOpen}
                  title={`Create Template`}
                  dataTarget={`createTemplate`}
                />
              );
            },
          }}
          components={{ TableBody: BodyComponent }}
        />

        {/* Add/Edit template popup */}
        {/*<CreateTemplate*/}
        {/*  modalState={popupToggle}*/}
        {/*  onHide={handlePopupClose}*/}
        {/*  history={props.history}*/}
        {/*  page={'grid'}*/}
        {/*  admin={props.admin ? props.admin : null}*/}
        {/*  getRecords={props.getUserLinkedTemplate}*/}
        {/*/>*/}

        <CreateTemplate
          openDrawer={popupToggle || editPopupToggle}
          templateId={templateId}
          onClose={handlePopupClose}
          history={props.history}
          page={'grid'}
          admin={props.admin ? props.admin : null}
          getRecords={props.getUserLinkedTemplate}

        />

        {/*<EditTemplate*/}
        {/*  temp_id={templateId}*/}
        {/*  modalState={editPopupToggle}*/}
        {/*  onHide={handlePopupClose}*/}
        {/*  history={props.history}*/}
        {/*  admin={props.admin ? props.admin : null}*/}
        {/*  getRecords={props.getUserLinkedTemplate}*/}
        {/*/>*/}

        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const { auth, row } = props;
  const { user } = auth;
  const admin = props.admin ? props.admin : false;
  const [anchorElement, setAnchorElement] = useState(null);

  const editable = user.id === row.created_by;

  //const editable = user.id === row.created_by;
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      case 'changeStatus': {
        props.updateStatus(props.temp_id, props.row);
        break;
      }
      default:
        break;
    }
  };

  const menuItem =
    editable || admin
      ? [
          {
            title: 'Edit',
            itemType: 'editPopUp',
          },
          {
            title: 'Change Status',
            itemType: 'changeStatus',
          },
        ]
      : [];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getUserLinkedTemplate,
};

export default connect(null, mapDispatchToProps)(TemplateGrid);
