import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, makeStyles } from '@material-ui/core';

import api from '../../utils/api';
import CustomSnackBar from '../../components/CustomSnackBar';
import { convertQueryString } from '../../utils/convertQueryString';
import { english } from '../../utils/language';

import Trlogo from '../../../../img/endorsed-logo.png';
import Iglogo from '../../../../img/IgniteSAP-ogo.png';
import '../../../../css/login.css';

// styling for this component
const useStyles = makeStyles(({ custom }) => ({
  formWrapper: {},
  formInputFiled: {
    marginTop: 5,
    marginBottom: 8,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  formSubmitButtonWrapper: {
    marginTop: 10,
    marginBottom: 10,
  },
  formSubmitButton: {
    '&:hover': {
      border: `none`,
    },
    '&:focus': {
      outline: `none`,
    },
  },
  subTitle: {
    fontSize: '0.90rem',
    marginTop: 5,
  },
  errorMessage: {
    color: custom.colorCode.errorRed,
    fontSize: '0.75rem',
    marginTop: 5,
    marginBottom: 7,
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
}));

/**
 * reset current password with proper access token from email
 * @returns {*}
 * @constructor
 */
const ResetPassword = ({ isAuthenticated, location, history }) => {
  // check whether the user is login into our system
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  const classes = useStyles();
  const [formData, setFormData] = useState({
    username: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [queryStringData, setQueryStringData] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const formRef = useRef();
  const formDataRef = useRef();

  const { username, newPassword, confirmPassword } = formData;

  // will work as a did mount function
  useEffect(() => {
    const searchData = convertQueryString(location.search);
    setQueryStringData({ ...searchData });

    setFormData({
      ...formData,
      username: searchData.email,
    });

    // mange the validation as per the mount and unmount
    // this will add validation
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      return value === formDataRef.current.newPassword;
    });
    ValidatorForm.addValidationRule('customPassword', (value) => {
      const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
      return regularExpression.test(value);
      // return value === formDataRef.current.newPassword;
    });
    // unmount of the component
    return () => {
      // this will remove validation
      if (ValidatorForm.hasValidationRule('isPasswordMatch')) {
        ValidatorForm.removeValidationRule('isPasswordMatch');
      }
      if (ValidatorForm.hasValidationRule('customPassword')) {
        ValidatorForm.removeValidationRule('customPassword');
      }
    };
  }, []);

  useEffect(() => {
    formDataRef.current = { ...formData };
  }, [formData]);

  // handle input value change
  const handleChange = (e) => {
    setErrorMessage('');
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * handle submit and will reset password or show error or handle the error
   * @param e
   */
  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      username,
      password: newPassword,
      password_confirmation: confirmPassword,
      token: queryStringData.token,
    };

    setDisableBtn(true);
    api
      .post('/password/reset', payload)
      .then((resp) => {
        setOpenSnackBar(true);
        history.push('/login');
      })
      .catch((error) => {
        setDisableBtn(false);
        let errorMessage = 'Something went wrong';
        if (error.response?.data?.Error) {
          errorMessage = error.response?.data?.Error;
        }
        if (error.response?.data?.error) {
          errorMessage = error.response?.data?.error;
        }
        setErrorMessage(errorMessage);
      });
  };

  /**
   * this will close the snackbar
   * @param e
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  let logo = Trlogo;
  // let clientName = 'ThirdRepublic';
  if (
    window.location.hostname === 'ig-testing.succede.com' ||
    window.location.hostname === 'ignitesap.succede.com'
  ) {
    logo = Iglogo;
    // clientName = 'IgniteSAP';
  }

  /**
   * Return function to render UI elements
   */
  return (
    <Fragment>
      <div className="login-wrapper">
        <div className="login-container">
          <div className="loging-right-section">
            <ValidatorForm
              onSubmit={onSubmit}
              ref={(r) => (formRef.current = r)}
            >
              <div className="heading">
                <img src={logo} width="240" />

                <h3>{english.resetPasswordTitle}</h3>
              </div>

              <div className={classes.formWrapper}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      label="Email"
                      className={classes.formInputFiled}
                      onChange={handleChange}
                      name="username"
                      value={username}
                      fullWidth
                      disabled
                      variant="outlined"
                      validators={['required', 'isEmail']}
                      errorMessages={[
                        english.emailRequiredValidation,
                        english.emailNotValidValidation,
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      label="New Password"
                      className={classes.formInputFiled}
                      onChange={handleChange}
                      name="newPassword"
                      value={newPassword}
                      type="password"
                      fullWidth
                      variant="outlined"
                      validators={['required', 'customPassword']}
                      errorMessages={[
                        english.passwordRequired,
                        english.passwordCustomValidation,
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      label="Confirm Password"
                      className={classes.formInputFiled}
                      onChange={handleChange}
                      name="confirmPassword"
                      value={confirmPassword}
                      type="password"
                      fullWidth
                      variant="outlined"
                      validators={['required', 'isPasswordMatch']}
                      errorMessages={[
                        english.confirmPasswordRequired,
                        english.passwordMismatch,
                      ]}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.formSubmitButtonWrapper}>
                {errorMessage && (
                  <p className={classes.errorMessage}>{errorMessage}</p>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  disabled={disableBtn}
                  className={classes.formSubmitButton}
                  type="submit"
                  fullWidth
                >
                  {english.submitButton}
                </Button>
              </div>
            </ValidatorForm>
          </div>
        </div>

        <CustomSnackBar
          openSnackBar={openSnackBar}
          hideDuration={2000}
          handleSnackbarClose={handleSnackbarClose}
          barMessage={english.resetSuccess}
        />
      </div>
    </Fragment>
  );
};

ResetPassword.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(ResetPassword);
