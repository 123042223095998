import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../auth/Login';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
import Unsubscribe from '../auth/Unsubscribe';
import Alert from '../layout/Alert';
import Dashboard from '../dashboard/Dashboard';
import Sequence from '../Sequence/index';
import ProfileSequence from '../Sequence/ProfileSequence';
// import AppSequence from '../Sequence/AppSequence';
import SequenceStep from '../Sequence/SequenceStep';
//import NotFound from '../layout/NotFound';
import PrivateRoute from '../routing/PrivateRoute';
import Template from '../templates/template';
import ProfileTemplate from '../templates/ProfileTemplate';
import LinkedinTemplate from '../linkedintemplate/template';
import ProfileLinkedinTemplate from '../linkedintemplate/ProfileTemplate';
import SucceededProfileDetail from '../SucceededProfileDetail';
// import JobSpecTemplate from '../jobspectemplate/template';
import MySpecjobTemplate from '../jobspectemplate/mytemplate';
import SequenceDetail from '../Sequence/SequenceDetail';
import Users from '../Users/Users';
import EditUserProfile from '../Users/EditUserProfile';
import CurrentUserProfile from '../Users/EditUserProfile/MyProfile';
import ProfileIntegrations from '../profile/profileIntegrations';
import Teams from '../admin/teams/teams';
import Entities from '../admin/entities/entities';
import Skills from '../admin/skills/skills';
import Task from '../admin/myTask/Task';
import Offices from '../Offices';
import JobRoles from '../admin/jobRoles';
import Categories from '../admin/categories/categories';
import Industries from '../admin/industries/industries';
import Integrations from '../admin/integrations';
import Integration from '../admin/integrations/IntegrationDetail';
/* import Lead from '../lead/lead'; */
import Lead from '../lead/lead';
import Leads from '../lead/leads';
import EmailDetails from '../templates/EmailDetails';
// import EmailDetails from '../templates/EmailDetails/index-bak';
// import CvSpecTemplate from '../cvspectemplates/template.js';
// import MasterCvSpecTemplate from '../mastercvspec/template.js';
import MyCvSpecTemplate from '../cvspectemplates/myTemplate.js';
// import MyMasterCvSpecTemplate from '../mastercvspec/myTemplate.js';
import userRoles from '../utils/userRoles';
// sequenced candidate view
import SequencedCandidates from '../admin/sequencedCandidates';
// activity report view
import ActivityReport from '../admin/activityReports';

import OnboardingContainer from '../profile/onboarding';

const Routes = () => {
  return (
    <Fragment>
      <Alert />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/password-forgot" component={ForgotPassword} />
        <Route exact path="/password/reset" component={ResetPassword} />
        <Route exact path="/unsubscribe/:id" component={Unsubscribe} />
        <PrivateRoute
          exact
          path="/dashboard"
          showSidebar={false}
          accessRole={userRoles.all}
          component={Dashboard}
        />
        <PrivateRoute
          exact
          path="/admin/sequence"
          accessRole={userRoles.admins}
          component={Sequence}
        />
        <PrivateRoute
          exact
          path="/admin/sequence/:id"
          accessRole={userRoles.admins}
          component={Sequence}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          showSidebar={false}
          path="/admin/sequence/steps/:id"
          component={SequenceStep}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/template"
          component={Template}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/linkedin-template"
          component={LinkedinTemplate}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/sequence/detail/:id"
          component={SequenceDetail}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/email/detail/:id"
          component={EmailDetails}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          showSidebar={false}
          path="/lead"
          component={Lead}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          showSidebar={false}
          path="/leads"
          component={Leads}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/users"
          component={Users}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/users/:id"
          component={EditUserProfile}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/entities"
          component={Entities}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/teams"
          component={Teams}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/offices"
          component={Offices}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/task"
          component={Task}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/task/:id"
          component={Task}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/skills"
          component={Skills}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/categories"
          component={Categories}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/industries"
          component={Industries}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/job-roles"
          component={JobRoles}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/integrations"
          component={Integrations}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/integrations/detail/:id"
          component={Integration}
        />
        <Redirect
          accessRole={userRoles.admins}
          exact
          showSidebar={false}
          from="/leads"
          to="/leads/views/1"
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          showSidebar={false}
          path="/leads/views/:tab"
          component={Leads}
        />
        {/* <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/specjob-template"
          component={JobSpecTemplate}
        />
        <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/cvspec-template"
          component={CvSpecTemplate}
        /> */}
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/profile/sequence"
          // component={ProfileSequence}
          component={ProfileSequence}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/profile/template"
          component={ProfileTemplate}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/profile/linkedin-template"
          component={ProfileLinkedinTemplate}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/profile/specjob-template"
          component={MySpecjobTemplate}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/profile/integrations"
          component={ProfileIntegrations}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/profile"
          component={CurrentUserProfile}
        />
        {/* <PrivateRoute
          accessRole={userRoles.admins}
          exact
          path="/admin/master-cvspec-template"
          component={MasterCvSpecTemplate}
        /> */}
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/profile/cvspec-template"
          component={MyCvSpecTemplate}
        />
        {/* <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/profile/master-cvspec-template"
          component={MyMasterCvSpecTemplate}
        /> */}
        {/* <PrivateRoute
          accessRole={userRoles.all}
          exact
          path='/app'
          component={Sequence}
        /> */}
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          // path='/app/sequence'
          path="/sequence"
          // component={AppSequence}
          component={Sequence}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/sequence/:id"
          component={Sequence}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/template"
          component={Template}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/linkedin-template"
          component={LinkedinTemplate}
        />
        {/* <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/specjob-template"
          component={JobSpecTemplate}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/cvspec-template"
          component={CvSpecTemplate}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/master-cvspec-template"
          component={MasterCvSpecTemplate}
        /> */}
        {/* sequence details page */}
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/sequence/detail/:id"
          component={SequenceDetail}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/sequence/steps/:id"
          showSidebar={false}
          component={SequenceStep}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/succeeded-profile"
          component={SucceededProfileDetail}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/succeeded-profile/:type/:id"
          component={SucceededProfileDetail}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/prospect/candidates"
          component={SequencedCandidates}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/prospect/candidates/:id"
          component={SequencedCandidates}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/prospect/contacts"
          component={SequencedCandidates}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/prospect/contacts/:id"
          component={SequencedCandidates}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/activity-report"
          component={ActivityReport}
        />
        <PrivateRoute
          accessRole={userRoles.all}
          exact
          path="/onboarding"
          component={OnboardingContainer}
        />
        {/*<PrivateRoute*/}
        {/*  accessRole={userRoles.all}*/}
        {/*  exact*/}
        {/*  path='/recipient-profile/:id'*/}
        {/*  component={SucceededProfileDetail}*/}
        {/*/>*/}
      </Switch>
    </Fragment>
  );
};

export default Routes;
