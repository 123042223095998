import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import CheckboxLabel from '../../../components/CheckboxLabel';
import { accessLevelOption } from '../../AddUserForm';
import { commonFormStyles } from './style';
import ResetPassword from './ResetPassword';
import CustomSnackBar from '../../../components/CustomSnackBar';

import api from '../../../utils/api';

const SecurityForm = (props) => {
  const { dropdownList, userData, getUserData, userRoles = [] } = props;
  const classes = commonFormStyles();

  /**
   * disable all controls in this tab based on role
   **/
  const disableControl = useMemo(() => {
    // admin role check
    return !userRoles.find((uR) => uR.role_id === 1);
  }, []);

  const formRef = useRef();
  const [formData, setFormData] = useState({});
  const [popupToggle, setPopupToggle] = useState(false);
  const [disabledButton, setDisabledButton] = useState(disableControl);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);

  useEffect(() => {
    setFormData({
      ...formData,
      accessLevel: userData.ip_verify,
      permissionLevel: userData.role,
      adminRole: Boolean(userData.adminRole),
    });
  }, [userData]);

  const handleSelectChange = (name) => (event, tag) => {
    setFormData({
      ...formData,
      [name]: name === 'accessLevel' ? tag.mainId : tag.id,
    });
  };

  const handleCheckboxChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };

  //handle function to close reset password popup
  const handlePopupClose = () => {
    setPopupToggle(false);
  };

  // handle function to open reset password popup
  const handleResetCLick = () => {
    setPopupToggle(true);
  };

  const handleSubmit = () => {
    if (disableControl) {
      return;
    }

    const values = {
      tab: 'Security',
      security: formData.accessLevel,
      adminRole: Boolean(formData.adminRole),
      role: formData.permissionLevel,
    };

    setDisabledButton(true);
    api
      .put(`/automation/users/${userData.id}`, { values })
      .then((res) => {
        getUserData();
        setDisabledButton(false);
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          message: 'Your security updated successfully',
          status: 'success',
        });
      })
      .catch((error) => {
        setDisabledButton(false);
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          message: error?.response?.data?.error || 'Something went wrong ',
          status: 'error',
        });
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div className={classes.mainFormValidator}>
      <div className={classes.formWrapper}>
        <h6 className={classes.formHeading}> Password </h6>

        <div className={classes.textWrapper}>
          <div className={'text'} onClick={handleResetCLick}>
            Reset Password
          </div>
          {/*<div className={'value'}>Last reset on 05/05/2021</div>*/}
        </div>
      </div>

      <ValidatorForm ref={(r) => (formRef.current = r)} onSubmit={handleSubmit}>
        <div className={classes.formWrapper} style={{ marginTop: '10px' }}>
          {/*<h6 className={classes.formHeading}> System Info Required </h6>*/}

          <Grid container spacing={2}>
            {/*access level*/}
            <Grid item xs={12} sm={6} className="pr-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('accessLevel')}
                name="accessLevel"
                id="accessLevel"
                disableClearable
                disabled={disableControl}
                key={'accessLevel'}
                getOptionSelected={(option, value) => option.id === value.id}
                options={accessLevelOption}
                value={
                  !(formData?.accessLevel === undefined)
                    ? accessLevelOption.find(
                        (c) => c.mainId === formData?.accessLevel,
                      ) || ''
                    : ''
                }
                getOptionLabel={(option) => option.name || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Access Level *"
                    name="accessLevel"
                    value={
                      accessLevelOption.find(
                        (c) => c.mainId === formData?.accessLevel,
                      )?.id || ''
                    }
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={['Select Access Level']}
                  />
                )}
              />
            </Grid>

            {/*permission level*/}
            <Grid item xs={12} sm={6} className="pl-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('permissionLevel')}
                name="permissionLevel"
                id="permissionLevel"
                key={'permissionLevel'}
                disableClearable
                disabled={disableControl}
                options={[...(dropdownList.roleList || [])]}
                getOptionLabel={(option) => option.name || ''}
                value={
                  formData.permissionLevel
                    ? (dropdownList?.roleList || []).find(
                        (c) => c.id === formData?.permissionLevel,
                      ) || ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Permission Level *"
                    name="permissionLevel"
                    value={formData?.permissionLevel || ''}
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={['Select Permission Level']}
                  />
                )}
              />
            </Grid>

            {/*Checkboxes*/}
            <Grid item xs={12} sm={6} className="pr-3">
              <FormGroup>
                <CheckboxLabel
                  label="Assigned Admin Role "
                  checked={formData?.adminRole || false}
                  name="adminRole"
                  checkboxProps={{
                    disabled: disableControl,
                  }}
                  value={''}
                  handleChange={handleCheckboxChange}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </div>

        <div className={classes.formFooter}>
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledButton}
            >
              Update
            </Button>
          </div>
        </div>
      </ValidatorForm>

      <ResetPassword
        openDrawer={popupToggle}
        onClose={handlePopupClose}
        getUserData={getUserData}
        userData={userData}
      />
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default SecurityForm;
