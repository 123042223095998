import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';

import { login, loginErrorClear } from '../actions/auth';
import { english } from '../utils/language';

import Trlogo from '../../../img/endorsed-logo.png';
import Iglogo from '../../../img/IgniteSAP-ogo.png';
import '../../../css/login.css';

// styling for this component
const useStyles = makeStyles(({ custom }) => ({
  formWrapper: {},
  formInputFiled: {
    marginTop: 5,
    marginBottom: 8,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  formSubmitButtonWrapper: {
    marginTop: 10,
    marginBottom: 10,
  },
  formSubmitButton: {
    '&:hover': {
      border: `none`,
    },
    '&:focus': {
      outline: `none`,
    },
  },
  forgotPassword: {
    fontSize: 14,
  },
  errorMessage: {
    color: custom.colorCode.errorRed,
    fontSize: '0.75rem',
    marginTop: 5,
    marginBottom: 7,
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
}));

/**
 * login in to our application or go to reset password page as well
 * @returns {*}
 * @constructor
 */
const Login = ({
  login,
  loginErrorClear,
  errorMessage,
  isAuthenticated,
  loading,
}) => {
  // check whether the user is login into our system
  if (isAuthenticated) {
    return <Redirect to="/sequence" />;
    // return <Redirect to="/dashboard" />;
  }
  const classes = useStyles();
  const [disableLoginBtn, setDisableLoginBtn] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const formRef = useRef();

  const { email, password } = formData;

  // handle input value change
  const handleChange = (e) => {
    loginErrorClear();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // handle the change of the loading state
  useEffect(() => {
    // disable enable button as per loading
    if (!loading && disableLoginBtn) {
      setDisableLoginBtn(false);
    }
  }, [loading]);

  /**
   * handle submit and do login or show error
   */
  const onSubmit = (e) => {
    e.preventDefault();
    setDisableLoginBtn(true);
    login(email, password, setDisableLoginBtn);
  };

  //console.log(window.location.hostname);
  var logo = Trlogo;
  var clientName = 'ThirdRepublic';
  if (
    window.location.hostname === 'ig-testing.succede.com' ||
    window.location.hostname === 'ignitesap.succede.com'
  ) {
    logo = Iglogo;
    clientName = 'IgniteSAP';
  }

  /**
   * Return function to render UI elements
   */
  return (
    <Fragment>
      {/* <h1 className="large text-primary">Sign In</h1>
      <p className="lead">
        <i className="fas fa-user" /> Sign Into Your Account
      </p> */}
      <div className="login-wrapper">
        <div className="login-container">
          <div className="loging-right-section">
            <ValidatorForm
              onSubmit={onSubmit}
              ref={(r) => (formRef.current = r)}
            >
              <div className="heading">
                <img src={logo} width="240" />
                <Typography variant="h3">{english.signIn}</Typography>
              </div>

              <div className={classes.formWrapper}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      label="Email"
                      className={classes.formInputFiled}
                      onChange={handleChange}
                      name="email"
                      value={email}
                      fullWidth
                      variant="outlined"
                      validators={['required', 'isEmail']}
                      errorMessages={[
                        english.emailRequiredValidation,
                        english.emailNotValidValidation,
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      label="Password"
                      className={classes.formInputFiled}
                      onChange={handleChange}
                      name="password"
                      type="password"
                      value={password}
                      fullWidth
                      variant="outlined"
                      validators={['required']}
                      errorMessages={[english.enterPassword]}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.formSubmitButtonWrapper}>
                {errorMessage && (
                  <p className={classes.errorMessage}>{errorMessage}</p>
                )}
                <Button
                  variant="contained"
                  disabled={disableLoginBtn}
                  color="primary"
                  className={classes.formSubmitButton}
                  type="submit"
                  fullWidth
                >
                  {english.logIn}
                </Button>
              </div>
              <div>
                <Link
                  to={`/password-forgot`}
                  className={classes.forgotPassword}
                >
                  {english.forPassword}
                </Link>
              </div>
            </ValidatorForm>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  loginErrorClear: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  errorMessage: state.auth.errorMessage,
});

export default connect(mapStateToProps, { login, loginErrorClear })(Login);
