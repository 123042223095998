import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fade, makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Drawers from './Drawers';
import NewViewsForm from '../../popupforms/NewViewsForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 380,
    backgroundColor: theme.palette.background.paper,
    /* height: 380, */
  },
  itemplate: {
    width: '100%',
    maxWidth: 380,
    backgroundColor: theme.palette.background.paper,
    maxHeight: 280,
    Overflow: 'auto',
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#f76978',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontSize: '16px !important',
    fontWeight: '600',
  },
  item: {
    paddingLeft: '30px !important',
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
  },
  link: {
    margin: `0 ${theme.spacing(2) + 4}px`,
    flex: 1,
    fontSize: '16px !important',
  },
  createnewview: {
    /* marginLeft: theme.spacing(2), */
    flex: 1,
    fontSize: '14px !important',
    fontWeight: '600',
    color: '#2b78c7 !important',
    height: 45,
    padding: '8px 16px',
    borderTop: '1px solid rgb(203, 214, 226)',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    /* backgroundColor: '#e3e6ea', */
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  filterPaper: {
    maxWidth: '30%',
    minWidth: '300px',
  },
  filterCloseIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
  },
}));

/**
 * List all Object views
 */
const GridAddNewViewsPopup = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { object, tabs, handleTabClick, auth, alltabs } = props;
  const [allTabs, setAllTabs] = React.useState([]);
  let user_id = auth.user.id;

  useEffect(() => {
    setAllTabs(alltabs.tabs);
  }, [alltabs.tabs]);
  //Open dialog handle function
  const handleClick = (event) => {
    setAllTabs(alltabs.tabs);
    setAnchorEl(event.currentTarget);
  };

  //Close dialog handle function
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handle search field event and search data in all tabs
   * @param {*} event
   */
  const handleSearch = (event) => {
    let searchText = event.target.value;
    if (searchText.length > 0) {
      const searchResult = allTabs.filter(
        (tab) =>
          tab.name.toLowerCase().includes(searchText.toLowerCase()) === true,
      );
      setAllTabs(searchResult);
    } else {
      setAllTabs(alltabs.tabs);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  //Filter system views
  const systemViews = allTabs.filter((tab) => tab.filter_type === 1);
  //Filter user views
  const myViews = allTabs.filter(
    (tab) => tab.filter_type === 2 && tab.user_id === user_id,
  );
  //Filter other user views
  const otherViews = allTabs.filter(
    (tab) => tab.filter_type === 2 && tab.user_id != user_id,
  );

  /**
   * Function to return list item
   * @param {*} tabs
   * @returns
   */
  const listItems = (tabs) => {
    return tabs.map((tab) => {
      return (
        <React.Fragment key={`fr-${tab.id}`}>
          <ListItem
            button
            key={`li-${tab.id}`}
            className={classes.item}
            style={{ lineHeight: '1.25' }}
          >
            <ListItemText
              onClick={() => handleTabClick(tab.id, handleClose)}
              primary={tab.name}
              key={`lit-${tab.id}`}
            />
          </ListItem>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <Link className={classes.link} onClick={handleClick} to="#">
        <Add /> Add New
      </Link>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.root}>
          <AppBar className={classes.appBar} color={'secondary'}>
            <Toolbar color="secondary">
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={handleSearch}
                />
              </div>
            </Toolbar>
          </AppBar>
          <div className={classes.itemplate} style={{ overflow: 'auto' }}>
            {systemViews.length > 0 ? (
              <>
                <Typography variant="h6" className={classes.title}>
                  Standard
                </Typography>
                <List component="nav">{listItems(systemViews)}</List>
              </>
            ) : null}
            {myViews.length > 0 ? (
              <>
                <Typography variant="h6" className={classes.title}>
                  Created by me
                </Typography>
                <List component="nav">{listItems(myViews)}</List>
              </>
            ) : null}
            {otherViews.length > 0 ? (
              <>
                <Typography variant="h6" className={classes.title}>
                  Created by others
                </Typography>
                <List component="nav">{listItems(otherViews)}</List>
              </>
            ) : null}
            {systemViews.length === 0 &&
            myViews.length === 0 &&
            otherViews.length === 0 ? (
              <>
                <List component="nav">
                  <ListItem button className={classes.item} disabled={true}>
                    <ListItemText primary={'No results found'} />
                  </ListItem>
                </List>
              </>
            ) : null}
          </div>
          <div className={classes.createnewview}>
            <CreateNewViews handleNewClose={handleClose} {...props} />
          </div>
        </div>
      </Popover>
    </>
  );
};

/**
 * Create new views actions
 */
const CreateNewViews = (props) => {
  const styleclasses = useStyles();
  const { object } = props;

  const [hideViewsDrawer, setHideViewsDrawer] = React.useState(false);
  const handleClick = () => {
    //props.handleNewClose();
    setHideViewsDrawer(false);
  };
  const viewsDrawerExit = () => {
    setHideViewsDrawer(false);
  };

  const closeViewsDrawer = () => {
    setHideViewsDrawer(true);
  };

  return (
    <Drawers
      refExit={viewsDrawerExit}
      hide={hideViewsDrawer}
      classes={{
        paper: styleclasses.filterPaper,
        closeIcon: styleclasses.filterCloseIcon,
      }}
      trigger={
        <Link component="a" onClick={handleClick}>
          Create new view
        </Link>
      }
      content={
        <NewViewsForm
          handleClose={closeViewsDrawer}
          object={object}
          {...props}
        />
      }
    />
  );
};

/**
 * Extract state props
 */
const mapStateToProps = (state) => ({
  auth: state.auth,
  alltabs: state.tabs,
});

export default connect(mapStateToProps)(GridAddNewViewsPopup);
