import { GET_LEADS, GET_TABS, LOAD_LEADS, LEAD_ERROR } from '../actions/types';

const initialState = {
  leads: [],
  /* tabs: [], */
  facet_fields: [],
  facet_data: [],
  selected_filters: [],
  grid_params: [],
  counts: 0,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_LEADS: {
      const data = {
        ...state,
        leads: payload.leads,
        counts: payload.counts,
        facet_fields: payload.facet_fields,
        facet_data: payload.facet_data,
        selected_filters: payload.filters,
        grid_params: payload.grid_params,
        loading: false,
      };
      return data;
    }
    case GET_LEADS: {
      const data = {
        ...state,
        leads: payload.leads,
        counts: payload.counts,
        fields: payload.facet_fields,
        loading: false,
      };
      return data;
    }
    /* case GET_TABS: {
      const data = {
        ...state,
        tabs: payload,
        loading: false,
      };
      return data;
    } */

    case LEAD_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        profile: null,
      };
    default:
      return state;
  }
}
