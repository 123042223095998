import { makeStyles } from '@material-ui/core/styles';

export const commonFormStyles = makeStyles(({ custom }) => ({
  mainFormValidator: {
    width: 550,
  },
  formHeaderWrapper: {},
  formWrapper: {
    padding: '15px 0',
  },
  signatureEditorWrapper: {
    marginTop: 15,
  },
  formHeading: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 10,
  },
  profilePic: {
    marginBottom: 20,
    '& .MuiAvatar-root': {
      height: 50,
      width: 50,
    },
  },
  textWrapper: {
    color: '#172b4d',
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 0,
    '& .text': {
      fontWeight: 600,
      color: '#60aec3',
      cursor: 'pointer',
    },
    '& .value': {
      color: custom.colorCode.blueDarkShade1,
      fontSize: 14,
      fontFamily: 'Roboto, sans-serif !important',
      fontWeight: 400,
    },
    '& .link': {
      color: custom.colorCode.defaultLinkColor,
      fontWeight: 400,
      cursor: 'pointer',
      '&:hover': {
        color: custom.colorCode.defaultLinkColor,
        fontWeight: 400,
        cursor: 'pointer',
      },
    },
  },
  textSignatureWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formFooter: {
    fontWeight: 500,
    padding: 0,
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'flex-end',
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',
    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
  checkBoxChecked: {
    color: `${custom.colorCode.blueLightShade8} !important`,
  },
  errorMessage: {
    color: custom.colorCode.errorRed,
    margin: 0,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
}));

const useStyles = makeStyles(({ palette, custom }) => ({
  root: {
    backgroundColor: palette.background.paper,
    width: 500,
  },
  myProfileTabs: {
    color: custom.colorCode.blueDarkShade1,
    margin: '0 0 20px 0 !important',
  },
  breadCrumb: {
    fontSize: 14,
  },
  breadCrumbLastChild: {
    fontSize: 14,
  },
}));

export default useStyles;
