import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { Toolbar, Box, Typography } from '@material-ui/core';

import ToolBarPickers from '../ToolBarPickers';
import DrawerFilter from '../DrawerFilter';
import EditColumnPopup from '../EditColumns';
import CustomDrawer from '../../../components/CustomDrawer';
import DrawerFormHeader from '../../../components/DrawerFormHeader';
import Button from '../../../components/Button';

export const defaultToolbarStyles = (theme) => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  fullWidthRoot: {},
  left: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
  },
  fullWidthLeft: {
    flex: '1 1 auto',
  },
  actions: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  fullWidthActions: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  titleRoot: {},
  titleText: {},
  fullWidthTitleText: {
    textAlign: 'left',
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  iconActive: {
    color: theme.palette.primary.main,
  },
  filterPaper: {
    maxWidth: '30%',
    /*  maxWidth: '300px', */
    /* top:'60px !important',
    right:'0', */
    minWidth: '300px',
    /* minHeight:'500px',
    maxHeight:'600px', */
  },
  filterCloseIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
  },
  searchIcon: {
    display: 'inline-flex',
    marginTop: '10px',
    marginRight: '8px',
  },
  [theme.breakpoints.down('sm')]: {
    titleRoot: {},
    titleText: {
      fontSize: '16px',
    },
    spacer: {
      display: 'none',
    },
    left: {
      // flex: '1 1 40%',
      padding: '8px 0px',
    },
    actions: {
      // flex: '1 1 60%',
      textAlign: 'right',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      display: 'block',
      '@media print': {
        display: 'none !important',
      },
    },
    left: {
      padding: '8px 0px 0px 0px',
    },
    titleText: {
      textAlign: 'center',
    },
    actions: {
      textAlign: 'center',
    },
  },
  typoGraphSelect: {
    fontSize: 14,
    letterSpacing: '0.3px',
    marginRight: 20,
    '& .fa': {
      marginLeft: 5,
    },
  },
  filterMenuSearch: {},
  drawerPaper: {
    width: 400,
  },
  drawerFilters: {
    marginBottom: 8,
  },
  drawerFilterHeaderTitle: {
    paddingLeft: 0,
  },
  drawerFilterWrapper: {
    padding: '0 20px',
  },
  resetLink: {
    color: theme.custom.colorCode.blueLightShade12,
    outline: 'unset !important',
    backgroundColor: 'transparent !important',
    border: 'transparent',
    textTransform: 'capitalize',
  },
  '@media screen and (max-width: 480px)': {},
  selectToolbar: {
    boxShadow: 'none',
    borderBottom: 'none',
    borderRadius: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
  selectToolbarEditCol: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    padding: 8,
    marginRight: 8,
    cursor: 'pointer',
  },
});

const RESPONSIVE_FULL_WIDTH_NAME = 'scrollFullHeightFullWidth';

const TableToolbar = ({
  options,
  classes,
  title,
  myFilterData: filterData,
  displayData,
  defaultFilterState,
  visibleFilter = 0,
  filterState,
  showEditCol = false,
  loading,
  defaultUnSetFilters=false,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');
  const [activeSearch] = useState(true);
  const [myFilterData, setMyFilterData] = useState(filterData);
  const [myTaskFilter, setMyTaskFilter] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editTaskPopup, setEditTaskPopup] = useState(false);

  useEffect(() => {
    if (options?.searchText && options?.searchText !== searchText) {
      setSearchText(options?.searchText);
    }
  }, [options.searchText]);

  useEffect(() => {
    const defaultFilters = { ...defaultFilterState, ...filterState };
    if (!isEmpty(defaultFilters)) {
      Object.keys(defaultFilters).filter(
        (e) => e !== 'pageNo' && e !== 'perPage'
      );
      let payload = {};
      const defaultOne = Object.keys(defaultFilters).filter(
        (e) => e !== 'pageNo' && e !== 'perPage'
      );
      defaultOne.forEach((e) => {
        const ha = filterData.find((fData) => e === fData.type)?.data;
        if (e == 'createDate' || e == 'createdDate' || e == 'lastSendDate') {
          let completedDatedata = filterData.find((fData) => e === fData.type);
          if (completedDatedata) {
            payload = {
              ...payload,
              [e]: {
                name: e,
                filter: [defaultFilters[e]],
              },
            };
            return;
          }
        }
        if (!ha) return;
        payload = {
          ...payload,
          [e]: {
            name: e,
            filter: ha.filter((h1) =>
              h1.isSingle
                ? defaultFilters[e] === h1.id
                : defaultFilters[e].includes(h1.id)
            ),
          },
        };
      });
     
      setMyTaskFilter({
        ...payload,
      });
    }
  }, [defaultFilterState]);

  const handleSearch = (value) => {
    setSearchText(value);
    props.searchTextUpdate(value);
  };

  const hideSearch = () => {
    const { onSearchClose } = options;

    props.setTableAction('onSearchClose');
    if (onSearchClose) onSearchClose();
    props.searchClose();

    setSearchText(null);
  };

  const handleEditTaskClick = () => {
    setEditTaskPopup(!editTaskPopup);
  };

  const handleFilterChange = ({ isSingle, type, control, ...data }) => {
    if (data.name === 'moreFilter') {
      setOpenDrawer(true);
      return;
    }
    let filters = {
      ...myTaskFilter,
    };
   
    if (data.filter?.length) {
      filters = {
        ...filters,
        [data.name]: {
          ...data,
        },
      };
    } else {
      if (control === 'dateRangePicker') {
        filters[type] = {
          data: { ...data.data },
        };
      } else {
        delete filters[data.name];
      }
    }

    setMyTaskFilter({ ...filters });

    let filterObject = {};
    Object.keys(filters).forEach((e) => {
      if (
        myFilterData.find((fData) => fData.type === e).control ===
        'dateRangePicker'
      ) {
        let filterData = filters[e]?.filter;
        filterObject = {
          ...filterObject,
          // ...filters[e]?.data,
          [e]: filters[e]?.data
            ? filters[e]
            : filterData
            ? filterData[0]
            : filters[e],
        };
      } else {
        filterObject =
          myFilterData.find((fData) => fData.type === e)?.isSingle && isSingle
            ? {
                ...filterObject,
                [e]: filters[e]?.filter
                  ? filters[e]?.filter.map((f) => f.id)
                  : filters[e],
              }
            : {
                ...filterObject,
                [e]: filters[e]?.filter
                  ? filters[e]?.filter.map((f) => f.id)
                  : filters[e],
              };
      }
    });
    props.refetch(filterObject);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const clearAllFilters = () => {
    if(defaultUnSetFilters) {
       let checkDefaultFilter = myTaskFilter[defaultUnSetFilters];
       let filterObject = {};
       if(checkDefaultFilter) {
        let filters = {
          [checkDefaultFilter.name]: {
            ...checkDefaultFilter,
          },
        };
        setMyTaskFilter({ ...filters });
       
        Object.keys(filters).forEach((e) => {
            filterObject =
         {
            ...filterObject,
            [e]: filters[e]?.filter
              ? filters[e]?.filter.map((f) => f.id)
              : filters[e],
          };
        });
       }

       props.refetch(filterObject);
    } else {
      setMyTaskFilter({});
      props.refetch({});
    }
  };

  const numberOfFilterVisible = visibleFilter ? visibleFilter : 3;

  return (
    <Toolbar
      className={
        options.responsive !== RESPONSIVE_FULL_WIDTH_NAME
          ? classes.root
          : classes.fullWidthRoot
      }
      role={'toolbar'}
      aria-label={'Table Toolbar'}
    >
      <div
        className={
          options.responsive !== RESPONSIVE_FULL_WIDTH_NAME
            ? classes.left
            : classes.fullWidthLeft
        }
      >
        {activeSearch === true ? (
          options.customSearchRender ? (
            options.customSearchRender(
              searchText,
              handleSearch,
              hideSearch,
              options
            )
          ) : (
            <div></div>
          )
        ) : (
          typeof title !== 'string' && title
        )}
        <Box display='flex'>

          <ToolBarPickers
            myFilterData={
              myFilterData.slice(numberOfFilterVisible).length
                ? [
                    ...myFilterData.slice(0, numberOfFilterVisible),
                    {
                      type: 'moreFilter',
                      title: 'More Filter',
                      data: myFilterData.slice(numberOfFilterVisible + 1),
                    },
                  ]
                : myFilterData.slice(0, numberOfFilterVisible)
            }
            myFilters={myTaskFilter}
            handleChange={handleFilterChange}
          />

        </Box>
      </div>

      <Box display='flex' alignItems='center'>
        {showEditCol && !loading && (
          <Typography
            variant='body1'
            className={classes.selectToolbarEditCol}
            onClick={handleEditTaskClick}
          >
            {/*<ViewWeekOutlinedIcon fontSize='small' />*/}
            Edit Columns
          </Typography>
        )}
        {options.customToolbar &&
          options.customToolbar({ displayData: displayData })}
      </Box>
      <Box>
        <CustomDrawer
          anchor={'right'}
          open={openDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerClose}
        >
          <DrawerFormHeader
            handleCloseClick={handleDrawerClose}
            titleText={props.filterDrawerName || 'Filters'}
            formHeaderTitleClassName={classes.drawerFilterHeaderTitle}
            drawerElement={
              <Button
                color='primary'
                className={classes.resetLink}
                tabIndex={0}
                onClick={() => {
                  // setMyTaskFilter({});
                  // props.refetch({});
                  clearAllFilters()
                }}
              >
                Clear All
              </Button>
            }
          >
            <Box className={classes.drawerFilterWrapper}>
              <DrawerFilter
                myFilterData={myFilterData.slice(numberOfFilterVisible)}
                handleChange={handleFilterChange}
                myFilter={myTaskFilter}
                defaultUnSetFilters={true}
              />

              {/*<ToolBarPickers*/}
              {/*  myFilterData={myFilterData.slice(numberOfFilterVisible)}*/}
              {/*  handleChange={handleFilterChange}*/}
              {/*  className={classes.drawerFilters}*/}
              {/*  myFilters={myTaskFilter}*/}
              {/*  drawerFilter*/}
              {/*/>*/}
            </Box>
          </DrawerFormHeader>
        </CustomDrawer>

        <EditColumnPopup
          handleToggleDialog={setEditTaskPopup}
          editClosePopup={editTaskPopup}
          columns={props.columns}
          columnOrder={props.columnOrder}
          onColumnChange={props.onColumnChange}
        />
      </Box>
    </Toolbar>
  );
};

export default withStyles(defaultToolbarStyles, {
  name: 'MUIDataTableToolbar',
})(TableToolbar);
