import overviewIcon from "../../../../img/icons/OverviewIcon.svg";
import rocketIcon from "../../../../img/icons/RocketLaunchFilled.svg";
import setupIcon from "../../../../img/icons/SetupIcon.svg";

export const onboardingData = [
  {
    title: "Quick Start Guide",
    child: [
      {
        title: "Overview",
        icon: overviewIcon,
        time: "3 min",
        child: [
          {
            title: "What is SuperReach",
            isCompleted: true,
          },
          {
            title: "Benefits of SuperReach",
            isCompleted: true,
          },
        ],
      },
      {
        title: "Set up",
        icon: setupIcon,
        time: "5 min",
        child: [
          {
            title: "Connect Mailbox",
            isCompleted: true,
          },
          {
            title: "Add Signature",
            isCompleted: true,
          },
          {
            title: "Download Chrome Extension",
            isCompleted: false,
          },
        ],
      },
      {
        title: "Getting Started",
        icon: rocketIcon,
        time: "5 min",
        child: [
          {
            title: "Create Sequence",
            isCompleted: true,
          },
          {
            title: "Add Contacts",
            isCompleted: false,
          },
          {
            title: "Manage tasks",
            isCompleted: false,
          },
        ],
      },
    ],
  },
];
