import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';

import Spinner from '../layout/Spinner';
import PageHeader from '../components/PageHeader';
import { fetchTabs } from '../actions/admin/user';
import UsersGrid from './UsersGrid';

const UserTabs = (props) => {
  const users = useSelector((state) => state.userGrid.count);
  const userTabs = useSelector((state) => state.userGrid.userTab);
  const dispatch = useDispatch();

  useEffect(() => {
    setTabs();
  }, []);

  const setTabs = async () => {
    await dispatch(fetchTabs());
  };

  if (userTabs.loading) {
    return <Spinner />;
  }

  return(
    <Box>
      <PageHeader
        mainTitle={'Users'}
        subTitle={'Create, edit and remove users from your account.'}
        itemCount={users || 0}
      />
      {userTabs?.tabs && (
        <UsersGrid {...props} tabProps={userTabs.tabs[0]}/>
      )}
    </Box>
  )
};

export default UserTabs;
