import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TemplateGrid from './TemplateGrid/index';

// class ProfileTemplate extends Component {
//   constructor(props) {
//     super(props);
//   }
//
//   render() {
//     return (
//       <div className="">
//         <div className="flex-row">
//           <aside className="sideBarMargin">
//             <SidebarNav linkedin="active" />
//           </aside>
//
//           <div className="flex-col content">
//             <TemplateGrid {...this.props} />
//             {/* <ToastContainer autoClose={2000} /> */}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

const ProfileTemplate = (props) => {
  return <TemplateGrid {...props} />;
};

ProfileTemplate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProfileTemplate);
