import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import Automation from './components/Automation/App';
import * as serviceWorker from './serviceWorker';

import './css/app.css';

if (document.getElementById('automation')) {
  ReactDOM.render(<Automation />, document.getElementById('automation'));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
