import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Alert } from '@material-ui/lab';
import { Button, Grid, Snackbar } from '@material-ui/core';

import FormAutoCompleteSelect from '../../components/FormAutoCompleteSelect';
import Spinner from '../../layout/Spinner';

import { fetchOffices } from '../../actions/admin/Offices';
import { fetchCountriesTimezones } from '../../actions/admin/Offices';
import api from '../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formHeading: {
    fontSize: 20,
    fontWeight: 500,
    paddingBottom: 10,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
}));

/**
 * Component for office form
 * @param {*} props
 * @returns
 */
const AddOfficeForm = (props) => {
  const classes = useStyles();
  const { editOffice, closeDrawer } = props;

  // Local state variables
  const [formData, setFormData] = useState({});
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [openErrorSnackBarMsg, setOpenErrorSnackBarMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  // Code to subscribe timezone, country and entity data from reducer for drop down
  const dispatch = useDispatch();
  const timezones = useSelector((state) => state.adminOffices.timezones);
  const countries = useSelector((state) => state.adminOffices.countries);
  const entityList = useSelector((state) => state.adminOffices.entityList);

  /**
   * Did mount function to set timezone, country and entity data in reducer
   */
  useEffect(() => {
    dispatch(fetchCountriesTimezones());
  }, []);

  /**
   * Did mount function to get team details for edit popup
   */
  useEffect(() => {
    if (editOffice?.id) {
      setIsLoading(true);
      api
        .get(`/automation/offices/${editOffice.id}`)
        .then((res) => {
          setIsLoading(false);
          if (!res.data.length) {
            return;
          }

          setFormData({
            officeName: res.data[0].office_name,
            addressOne: res.data[0].street1,
            addressTwo: res.data[0].street2,
            region: res.data[0].region,
            city: res.data[0].town,
            postCode: res.data[0].zip,
            officeNumber: res.data[0].main_office_phone,
            country: res.data[0].country_id,
            timeZone: res.data[0].time_zone,
            entity: res.data[0].entities_id,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          setOpenErrorSnackBar(true);
          setOpenErrorSnackBarMsg(
            error.response?.data?.error || 'Something went wrong',
          );
        });
    }
  }, [editOffice]);

  /**
   * Handle function of create/update team form
   * It call server API to save data into tables
   */
  const handleSubmit = () => {
    if (disabledNextButton() === false) {
      setDisabledButton(true);

      // create payload for api
      const payload = {
        name: formData.officeName,
        timezone: formData.timeZone,
        addressOne: formData.addressOne,
        addressTwo: formData.addressTwo,
        town: formData.city,
        region: formData.region,
        post_code: formData.postCode,
        country: formData.country,
        office_number: formData.officeNumber,
        entity: formData.entity,
      };

      // hit edit api if id is present
      if (editOffice?.id) {
        api
          .put(`/automation/offices/${editOffice?.id}`, { values: payload })
          .then(() => {
            closeDrawer({ officeEdited: true });
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);

            dispatch(fetchOffices());
          })
          .catch((error) => {
            setOpenErrorSnackBar(true);
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);

            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
          });
      } else {
        api
          .post('/automation/offices', { values: payload })
          .then((res) => {
            closeDrawer({ officeAdded: true });
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);

            dispatch(fetchOffices());
          })
          .catch((error) => {
            setOpenErrorSnackBar(true);
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
          });
      }
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = [
      'officeName',
      'addressOne',
      'city',
      'region',
      'postCode',
      'country',
      'timeZone',
      'officeNumber',
      'entity',
    ];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * function to handle the form element change event
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    setFormData({
      ...formData,
      [name]: tag[key],
    });
  };

  /**
   * function to close toaster after server side error
   */
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackBar(false);
  };

  // show loader before the api fetched the data
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  /**
   * Return function to render and return UI elements
   */
  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> Office Details </h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*Office Name*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Office Name *"
                  onChange={handleChange}
                  name="officeName"
                  value={formData?.officeName || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter office name']}
                />
              </Grid>

              {/*Address 1*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label="Address 1 *"
                  onChange={handleChange}
                  name="addressOne"
                  value={formData?.addressOne || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  multiline
                  errorMessages={['Please enter address']}
                />
              </Grid>
              {/*Address 2*/}
              <Grid item xs={12} sm={6} className="pl-3">
                <TextValidator
                  label="Address 2"
                  onChange={handleChange}
                  name="addressTwo"
                  value={formData?.addressTwo || ''}
                  variant="outlined"
                  fullWidth
                  multiline
                />
              </Grid>

              {/*City*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label="City *"
                  onChange={handleChange}
                  name="city"
                  value={formData?.city || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter city']}
                />
              </Grid>
              {/*Region*/}
              <Grid item xs={12} sm={6} className="pl-3">
                <TextValidator
                  label="Region *"
                  onChange={handleChange}
                  name="region"
                  value={formData?.region || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter region']}
                />
              </Grid>

              {/*Post Code*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label="Post Code *"
                  onChange={handleChange}
                  name="postCode"
                  value={formData.postCode || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter post code']}
                />
              </Grid>
              {/*Country*/}
              <Grid item xs={12} sm={6} className="pl-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('country', 'key')}
                  name="country"
                  id="country"
                  value={
                    formData.country
                      ? countries.find((c) => c.key === formData?.country) || ''
                      : ''
                  }
                  disableClearable
                  options={[...(countries || [])]}
                  getOptionLabel={(option) => option.value || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Country *"
                      name="country"
                      variant="outlined"
                      fullWidth
                      value={formData.country}
                      validators={['required']}
                      errorMessages={['Please select country']}
                    />
                  )}
                />
              </Grid>

              {/*TimeZone*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('timeZone', 'key')}
                  name="timeZone"
                  id="timeZone"
                  disableClearable
                  value={
                    formData.timeZone
                      ? timezones.find((c) => c.key === formData?.timeZone) ||
                        ''
                      : ''
                  }
                  options={[...(timezones || [])]}
                  getOptionLabel={(option) => option.value || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="TimeZone *"
                      value={formData.timeZone}
                      name="timeZone"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select time zone']}
                    />
                  )}
                />
              </Grid>

              {/*entity*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('entity', 'key')}
                  name="entity"
                  id="entity"
                  disableClearable
                  value={
                    formData.entity
                      ? entityList.find((c) => c.key === formData?.entity) || ''
                      : ''
                  }
                  options={[...(entityList || [])]}
                  getOptionLabel={(option) => option.value || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Entity *"
                      value={formData.entity}
                      name="entity"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select entity']}
                    />
                  )}
                />
              </Grid>

              {/*Office number*/}
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Office number *"
                  onChange={handleChange}
                  name="officeNumber"
                  value={formData?.officeNumber || ''}
                  variant="outlined"
                  fullWidth
                  validators={[`matchRegexp:^[- +0-9]*$`]}
                  errorMessages={['Please enter number only']}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledNextButton()}
            >
              {editOffice?.id ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </ValidatorForm>
      <Snackbar
        open={openErrorSnackBar}
        autoHideDuration={2000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleErrorSnackbarClose}
          severity="error"
        >
          {openErrorSnackBarMsg || ''}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddOfficeForm;
