import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import PageHeader from '../../../components/PageHeader';

import TemplateGrid from '../../../templates/TemplateGrid/TemplateGrid';
import IntegrationDetailGrid from './IntegrationDetailGrid';

import { fetchTabs } from "../../../actions/admin/integration";

const useStyles = makeStyles(() => ({
  mainHeader: {
    "& .heading-title": {
      marginBottom: 0,
    },
  },
}));

const IntegrationDetailTabs = (props) => {
  const classes = useStyles();
  const integrationDetail = useSelector((state) => state.integration.integrationDetail);
  const integrationDetailCount = useSelector((state) => state.integration.integrationDetail?.count);
  const integrationTabs = useSelector((state) => state.integration.integrationDetail?.integrationTab);
  const dispatch = useDispatch();
  useEffect(() => {
    setTabs();
  }, []);

  const setTabs = async () => {
    await dispatch(fetchTabs());
  };

  return (
    <div>
      <PageHeader
        mainTitle={'Bullhorn'}
        subTitle={'Data Sync - Sync Bullhorn data.'}
        showCount={true}
        itemCount={
          integrationTabs?.tabs.length
            ? integrationDetail[integrationTabs.tabs[0].id]?.counts || 0
            : 0
        }
        className={classes.mainHeader}
      />
      {Boolean(integrationTabs?.tabs.length) && (
        <IntegrationDetailGrid {...props} tabProps={integrationTabs.tabs[0]} />
      )}
    </div>
  );
};

export default IntegrationDetailTabs;
