import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Collapse from '../../../components/Collapse';
import UserInfo from './UserInfo';
import SequenceListing from './SequenceListing';
import LinkedineStartForm from './LinkedineStartForm';
import ContactDetail from './ContactDetail';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 86px)',
    overflow: 'auto',
  },
  startTaskWrapper: {
    padding: '16px 40px 20px 40px',
  },
  collapseWrapper: {
    marginTop: '2.25em',
  },
  collapseHeaderText: {
    fontWeight: 'bold',
  },
  collapseEditText: {
    marginLeft: 16,
    fontSize: 16,
    color: custom.colorCode.blueLightShade8,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  collapseContentWrapper: {
    paddingTop: 8,
    paddingBottom: 20,
  },
}));

const StartTaskView = ({
  taskData,
  setCurrentTaskData,
  handleEditPopupOpen,
}) => {
  const classes = useStyles();

  const tabData = useSelector((state) => state.myTask.currentTab);

  const handleTaskEditClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleEditPopupOpen(taskData.taskId);
  };

  const getCollapseTitle = () => {
    let contentMessage = '';
    switch ((taskData?.taskTypeName || '').toLocaleLowerCase()) {
      case 'linkedin': {
        contentMessage = 'Linkedin Message';
        break;
      }
      case 'callback': {
        contentMessage = 'Call Back';
        break;
      }
    }

    return (
      <Typography variant="h6" className={classes.collapseHeaderText}>
        {contentMessage}
      </Typography>
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.startTaskWrapper}>
        <UserInfo
          userName={taskData.common_name || ''}
          type={taskData.dmId ? 'Contact' : 'Candidate'}
          position={taskData.job_title || ''}
          region={taskData.common_acc_name || ''}
          imgUrl={
            taskData.cdnUrl && taskData.picture
              ? `${taskData.cdnUrl}${taskData.picture}`
              : ''
          }
          bhConnected={taskData.bh_connected}
          bhUrl={taskData.bh_url || ''}
          bhId={taskData.bh_id || ''}
        />

        {/* <div>{taskData.bh_url}<img src={"https://files.slack.com/files-pri/T28H33W3D-F02JSJF707N/bullinorange.png"}/></div> */}

        <Box className={classes.collapseWrapper}>
          <Collapse
            defaultOpen
            contentTitle={
              <>
                {getCollapseTitle()}
                <Typography
                  variant="body2"
                  className={classes.collapseEditText}
                  onClick={handleTaskEditClick}
                >
                  Edit
                </Typography>
              </>
            }
          >
            <Box className={classes.collapseContentWrapper}>
              <LinkedineStartForm
                taskData={taskData}
                setCurrentTaskData={setCurrentTaskData}
                currentTab={tabData}
              />
            </Box>
          </Collapse>

          <Collapse title={'Contact Details'}>
            <Box className={classes.collapseContentWrapper}>
              <ContactDetail taskData={taskData} />
            </Box>
          </Collapse>

          <Collapse title={'Sequences'}>
            <Box className={classes.collapseContentWrapper}>
              <SequenceListing taskData={taskData} />
            </Box>
          </Collapse>

          {/*<Collapse title={'Profile'}>*/}
          {/*  <Box className={classes.collapseContentWrapper}>*/}
          {/*    <LinkedineStartForm />*/}
          {/*  </Box>*/}
          {/*</Collapse>*/}

          {/*<Collapse title={'Application'}>*/}
          {/*  <Box className={classes.collapseContentWrapper}>*/}
          {/*    <LinkedineStartForm />*/}
          {/*  </Box>*/}
          {/*</Collapse>*/}
        </Box>
      </Box>
    </Box>
  );
};

export default StartTaskView;
