import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Menu, MenuItem, Typography, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';

// import CreateSequence from '../CreateSequence';
import CreateSequence from '../CreateSequenceSlider';
import CloneSequence from '../CloneSequence';
import EditSequence from '../EditSequence';
import PageHeader from '../../components/PageHeader';
import CustomDataGrid from '../../controlled-component/customGridComponent';
import AddButton from '../../components/CreateToolbarButton';
import LoadingTableBody from '../../components/LoadingTableBody';
import CustomSnackBar from '../../components/CustomSnackBar';
import { ActiveIcon, InActiveIcon } from '../../components/GridIcons';

import { getUserSequence, updateSequenceStatus } from '../../actions/sequence';

import 'react-toastify/dist/ReactToastify.css';

// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  sequenceGridWrapper: {
    padding: '10px 0 20px 0',
  },
}));

const SequenceGrid = (props) => {
  const classes = useStyles();

  const [popupToggle, setPopupToggle] = useState(false);
  const [sequenceId, setSequenceId] = useState();
  const [selectedSequence, setSelectedSequence] = useState({});
  const [clonePopupToggle, setClonePopupToggle] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState({ isOpen: false });
  const [editPopupToggle, setEditPopupToggle] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const sequence = useSelector((state) => state.sequence);

  // handle function to open add sequence popup
  const handlePopupOpen = () => {
    setSequenceId(false);
    setPopupToggle(!popupToggle);
  };

  // handle function to close add sequence popup
  const handlePopupClose = () => {
    setPopupToggle(false);
    setSequenceId(false);
  };

  // handle the edit sequence pop up
  const handleEditPopupOpen = (id, sequenceRow) => {
    /* setEditPopupToggle(!editPopupToggle); */
    // setPopupToggle(!popupToggle);
    setSequenceId(id);
    setEditPopupToggle(!editPopupToggle);
    setSelectedSequence(sequenceRow ? sequenceRow : {});
  };

  // update status with reducer
  const changeStatus = (newSequenceId, rowData) => {
    const newStatus = rowData.is_published === 'Un-Published' ? 1 : 2;
    props.updateSequenceStatus(
      newSequenceId,
      newStatus,
      'grid',
      null,
      setOpenSnackBar,
    );
  };

  // make copy of the selected sequence
  const handleCopySequence = (newSequenceId, sequenceRow) => {
    //const status = statusText === 'Un-Published' ? 1 : 2;
    setSequenceId(newSequenceId);
    setSelectedSequence(sequenceRow ? sequenceRow : {});
    setClonePopupToggle(!clonePopupToggle);
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar({
      isOpen: false,
      message: '',
    });
  };

  useEffect(() => {
    // did mount
    // call the sequence api and set data in reducer
    dispatch(getUserSequence());
  }, []);

  /* const handleNameClick = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    handleEditPopupOpen(id);
  }; */

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Sequence Name',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 300, width: 300, maxWidth: 300 },
          }),
          setCellProps: () => ({
            style: { minWidth: 300, width: 300, maxWidth: 300 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = sequence?.sequences[dataIndex];
            return (
              <Tooltip title={rData.name || ''} arrow>
                <div className={'td-content-ellipsis'}>
                  {/*<Link*/}
                  {/*  to={`/sequence/steps/${rData.id}`}*/}
                  {/*  className="text-truncate"*/}
                  {/*>*/}
                  {/*  {rData.name}*/}
                  {/*</Link>*/}
                  <Link
                    to={`/sequence/detail/${rData.id}`}
                    // to={`/admin/sequence/detail/${rData.id}`}
                    className="text-truncate td-anchor-content-ellipsis"
                  >
                    {rData.name}
                  </Link>{' '}
                  ({rData.id})
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Type',
        name: 'audience_type',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        label: 'Enrolled',
        name: 'total_enrolled',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Steps',
        name: 'total_steps',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Delivered',
        name: 'total_delivered',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Opened',
        name: 'total_opened',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: 'Clicked',
        name: 'total_clicked',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: 'Bounces',
        name: 'total_bounces',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: 'Status',
        name: 'is_published',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return value === 'Un-Published' ? (
              <InActiveIcon fontSize="small" />
            ) : (
              <ActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = sequence.sequences.find((tData) => tData.id === value);
            return (
              <GridActions
                temp_id={row?.id}
                status={row?.status}
                row={row}
                handleEditPopupOpen={handleEditPopupOpen}
                updatestatus={changeStatus}
                copySequence={handleCopySequence}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [sequence.sequences],
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={sequence.loading} {...props} />,
    [sequence.loading],
  );

  return (
    <div className={classes.templateGridMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Sequences'}
        subTitle={'Use sequences to automate your follows-ups and process.'}
        itemCount={sequence?.sequences?.length || 0}
      />
      <div className={classes.sequenceGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns}
          data={sequence?.sequences}
          options={{
            pagination: Boolean(sequence?.sequences?.length),
            selectableRows: 'multiple',
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search Sequences',
            selectToolbarPlacement: 'none',
            searchOpen: true,
            search: true,
            customToolbar: () => {
              // Toolbar for search and add
              return (
                <AddButton
                  handleClick={handlePopupOpen}
                  title={`Create Sequence`}
                  dataTarget={`createSequence`}
                />
              );
            },
          }}
          components={{ TableBody: BodyComponent }}
        />
        {/* Loader on grid */}
        {/* <Loader open={teams?.loading} /> */}
        {/* Add sequence popup */}
        {/*<CreateSequence*/}
        {/*  modalState={popupToggle}*/}
        {/*  onHide={handlePopupOpen}*/}
        {/*  history={props.history}*/}
        {/*/>*/}
        <CreateSequence
          openDrawer={popupToggle}
          onClose={handlePopupOpen}
          history={props.history}
        />

        {/* Copy/clone sequence popup */}
        <CloneSequence
          seq_id={sequenceId}
          sname={selectedSequence.name}
          modalState={clonePopupToggle}
          onHide={handleCopySequence}
          history={props.history}
        />

        {/* Edit sequence popup */}
        <EditSequence
          seq_id={sequenceId}
          sname={selectedSequence.name}
          modalState={editPopupToggle}
          onHide={handleEditPopupOpen}
          history={props.history}
          page="grid"
        />

        {/* Toaster if any changes in grid */}
        {/*<CustomSnackBar*/}
        {/*  openSnackBar={openSnackBar.isOpen}*/}
        {/*  hideDuration={5000}*/}
        {/*  severity={openSnackBar?.type || 'success'}*/}
        {/*  handleSnackbarClose={handleSnackbarClose}*/}
        {/*  barMessage={openSnackBar?.message || ''}*/}
        {/*/>*/}
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const [anchorElement, setAnchorElement] = useState(null);

  //const editable = user.id === row.created_by;
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id, props.row);
        break;
      }
      case 'changeStatus': {
        props.updatestatus(props.temp_id, props.row);
        break;
      }
      case 'copySequence': {
        props.copySequence(props.temp_id, props.row);
        break;
      }
      default:
        break;
    }
  };

  const menuItem = [
    /*{
      title: 'Edit Detail',
      itemType: 'editPopUp',
    },*/
    /* {
      title: 'Edit Steps',
      itemType: 'editStep',
    }, */
    {
      title: 'Change Status',
      itemType: 'changeStatus',
    },
    {
      title: 'Clone',
      itemType: 'copySequence',
    },
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          to={`/sequence/steps/${props.temp_id}`}
          // to={`/admin/sequence/steps/${props.temp_id}`}
        >
          Edit Steps
        </MenuItem>
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  updateSequenceStatus,
};

export default connect(null, mapDispatchToProps)(SequenceGrid);
