import api from '../utils/api';
import { toast } from 'react-toastify';

import {
  GET_ADS_ACCOUNT,
  ADS_ACCOUNT_ERROR,
  GET_AUDIENCES,
  AUDIENCES_ERROR,
  CREATE_STEPS_ADD_FACEBOOK,
  UPDATE_STEPS,
  SEQUENCE_ERROR,
} from '../actions/types';
import { getSequenceData } from './sequence';

// Call API to get the Ads account
export const getAdsAccount = () => async (dispatch) => {
  try {
    const res = await api.get('/integrations/getaddaccount');
    //console.log(res);
    dispatch({
      type: GET_ADS_ACCOUNT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADS_ACCOUNT_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Call API to get the custom Audieneces agains the Ads Account
export const getCustomAudiences = (adsAccountId) => async (dispatch) => {
  try {
    const res = await api.get(`/integrations/audiences/${adsAccountId}`);
    //console.log(res);
    dispatch({
      type: GET_AUDIENCES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUDIENCES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Create facebook step
export const createAddFacebookStep = (
  sequenceId,
  onHide,
  eventorder,
  customAudienceId,
  event,
) => async (dispatch) => {
  
  try {
    const values = {
      audience_id: customAudienceId.value,
      template_name: customAudienceId.label
        ? customAudienceId.label
        : event.descripton,
      description: customAudienceId.label
        ? customAudienceId.label
        : event.descripton,
      sequence_id: sequenceId,
      type: event.type,
      event: event.name,
      event_order: eventorder,
    };
    await api
      .post('/automation/seqsteps', {
        values,
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: CREATE_STEPS_ADD_FACEBOOK,
            payload: res.data,
          });
          onHide();
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step added', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Create facebook step
export const updateFacebookStep = (
  sequenceId,
  eventId, // step id
  onHide,
  customAudienceId,
  event,
) => async (dispatch) => {
  // console.log(customAudienceId);

  try {
    const values = {
      audience_id: customAudienceId.value,
      template_name: customAudienceId.label
        ? customAudienceId.label
        : event.descripton,
      description: customAudienceId.label
        ? customAudienceId.label
        : event.descripton,
      sequence_id: sequenceId,
      type: event.type,
      event: event.name,
    };
    await api
      .put(`/automation/seqsteps/${eventId}`, {
        values,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_STEPS,
            payload: res.data,
          });
          onHide();
          dispatch(getSequenceData(sequenceId));
          toast.success('Sequence step updated', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: SEQUENCE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
