import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Box, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';

import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import DrawerFormFooter from '../../../components/DrawerFormHeader/DrawerFormFooter';
import api from '../../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
}));

const ProfileEditForm = (props) => {
  const classes = useStyles();
  const {
    categoryList,
    industryList,
    jobroleList,
    languageList,
    skillsList,
  } = props.profileDropDownData;

  //Local state variables
  const [formData, setFormData] = useState({});
  const [responseData, setResponseData] = useState({});
  let [disabledButton, setDisabledButton] = useState(false);
  const [jobroleListData, setJobroleListData] = useState([]);

  useEffect(() => {
    if (!isEmpty(props.profileDetail)) {
      setFormData({
        ...formData,
        jobrole: props.profileDetail?.jobroleData?.map((p) => p.id) || [],
        skill: props.profileDetail?.selectedSkills?.map((p) => p.id) || [],
        industry: props.profileDetail?.industryData?.map((p) => p.id) || [],
        language: props.profileDetail?.languageData?.map((p) => p.id) || [],
      });
    }
    if (jobroleList) {
      setJobroleListData(
        jobroleList?.map((e) => ({
          ...e,
          id: `${e.category_id}--${e.skill_id}`,
        })) || [],
      );
    }
  }, [props.profileDetail]);

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e]?.length;
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['jobrole', 'skill', 'industry', 'language'];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * Handle function of save detail of contact
   * It call server API to save data for contact and candidate
   */
  const handleSubmit = async () => {
    if (disabledNextButton() === false) {
      disabledButton = true;
      setDisabledButton(true);
      if (props.profileType === 'candidate') {
        const values = {
          jobrole: formData.jobrole?.length
            ? jobroleListData
                .filter((e) => formData.jobrole.includes(e.id))
                .map((e) => `${e.id}--${e.skill_name}`) || []
            : [],
          skill: formData.skill || [],
          industry: formData.industry || [],
          language: formData.language || [],
        };

        await api
          .post(`/candidate/save-profile/${props.profileId}`, { ...values })
          .then((res) => {
            props?.closeDrawer({
              data: true,
              message: 'Profile updated successfully',
              status: 'success',
            });
          });
      }
      setTimeout(() => {
        setDisabledButton(false);
      }, 10);
    }
  };

  /**
   * function to handle the select element change event
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    return setFormData({
      ...formData,
      [name]: tag.map((e) => e[key]),
    });
  };

  return (
    <Box className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <Box className={classes.formWrapper}>
          <Box>
            <Grid container spacing={3}>
              {/*Job Role*/}
              <Grid item xs={12} sm={12}>
                <FormAutoCompleteSelect
                  multiple
                  onChange={handleSelectChange('jobrole', 'id')}
                  name="jobrole"
                  id="jobrole"
                  disableClearable
                  options={[...(jobroleListData || [])]}
                  getOptionLabel={(option) => option.skill_name || ''}
                  groupBy={(option) =>
                    categoryList?.find((cL) => cL.id === option.category_id)
                      ?.name || option.category_id
                  }
                  value={
                    formData?.jobrole?.length
                      ? (jobroleListData || []).filter((c) =>
                          formData?.jobrole.includes(c.id),
                        )
                      : []
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Job Role *"
                      value={formData?.jobrole || ''}
                      name="jobrole"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select job role']}
                    />
                  )}
                />
              </Grid>

              {/*Key Skills*/}
              <Grid item xs={12} sm={12}>
                <FormAutoCompleteSelect
                  multiple
                  onChange={handleSelectChange('skill', 'id')}
                  name="skill"
                  id="skill"
                  disableClearable
                  options={[...(skillsList || [])]}
                  getOptionLabel={(option) => option.name || ''}
                  value={
                    formData?.skill?.length
                      ? (skillsList || []).filter((c) =>
                          formData?.skill.includes(c.id),
                        )
                      : []
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Key Skills *"
                      value={formData?.skill || ''}
                      name="skill"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select key skill']}
                    />
                  )}
                />
              </Grid>

              {/*Industries*/}
              <Grid item xs={12} sm={12}>
                <FormAutoCompleteSelect
                  multiple
                  onChange={handleSelectChange('industry', 'id')}
                  name="industry"
                  id="industry"
                  disableClearable
                  options={[...(industryList || [])]}
                  getOptionLabel={(option) => option.name || ''}
                  value={
                    formData?.industry?.length
                      ? (industryList || []).filter((c) =>
                          formData?.industry.includes(c.id),
                        )
                      : []
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Industries *"
                      value={formData?.industry || ''}
                      name="industry"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select industry']}
                    />
                  )}
                />
              </Grid>

              {/*Languages*/}
              <Grid item xs={12} sm={12}>
                <FormAutoCompleteSelect
                  multiple
                  onChange={handleSelectChange('language', 'id')}
                  name="language"
                  id="language"
                  disableClearable
                  options={[...(languageList || [])]}
                  getOptionLabel={(option) => option.name || ''}
                  value={
                    formData?.language?.length
                      ? (languageList || []).filter((c) =>
                          formData?.language.includes(c.id),
                        )
                      : []
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Languages *"
                      value={formData?.language || ''}
                      name="language"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select language']}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <DrawerFormFooter disableButton={disabledNextButton()}>
          <Box />
        </DrawerFormFooter>
      </ValidatorForm>
    </Box>
  );
};

export default ProfileEditForm;
