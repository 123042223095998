import React from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(({ custom }) => ({
  formDatePicker: {
    width: '100%',
    '& .MuiIconButton-root': {
      outline: 'none',
    },
    '& button': {
      outline: 'none',
    },
  },
}));

const FormDatePicker = ({
  value = new Date(),
  onChange = () => {},
  className = '',
  ...otherProps
}) => {
  const classes = useStyles();

  const handleDateChange = (date) => {
    onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="outlined"
        inputVariant="outlined"
        format="dd-MM-yy"
        className={`${className} ${classes.formDatePicker}`}
        InputLabelProps={{ shrink: true }}
        value={value}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        autoOk
        {...otherProps}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FormDatePicker;
