import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import CustomSnackBar from '../../../components/CustomSnackBar';

import { commonFormStyles } from './style';
import api from '../../../utils/api';

const ProfileForm = (props) => {
  const {
    dropdownList,
    userData,
    myProfile,
    getUserData,
    userRoles = [],
  } = props;
  const classes = commonFormStyles();

  const [formData, setFormData] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    email: '',
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [emailValidators, setEmailValidators] = useState({}); // email validation and message to be manged in the state

  const formRef = useRef();

  /**
   * disable controls in this tab based on role
   **/
  const disableControl = useMemo(() => {
    // admin role check
    return !userRoles.find((uR) => uR.role_id === 1);
  }, []);

  useEffect(() => {
    // check that user or admin is changing profile base on the rules will be added
    if (!myProfile) {
      ValidatorForm.addValidationRule('isEmailUnique', async (value) => {
        if (!value) {
          return false;
        }
        // call api for email duplication
        const params = {
          email: value,
        };
        return await api
          .get('/automation/user/check-duplicate', { params })
          .then((res) => {
            if (res.data === true) {
              return false;
            }
            // return false for the error
            return true;
          })
          .catch(() => {
            return false;
          });
      });
      setEmailValidators({
        validators: ['required'],
        errorMessages: ['Email is required'],
      });
    }

    return () => {
      if (!myProfile) {
        ValidatorForm.removeValidationRule('isEmailUnique');
      }
    };
  }, []);

  useEffect(() => {
    const officeNumber = (userData?.numbers || []).find((n) => n.type === 2);
    const mobileNumber = (userData?.numbers || []).find((n) => n.type === 3);

    const linkedin = (userData?.socials || []).find((n) => n.social_type === 1);
    const xing = (userData?.socials || []).find((n) => n.social_type === 4);
    const calendar = (userData?.socials || []).find(
      (n) => n.social_type === 14,
    );

    setFormData({
      ...formData,
      title: userData.title,
      firstName: userData.fname,
      lastName: userData.lname,
      jobTitle: userData.job_title,
      email: userData.username,
      startDate: moment(userData.created_at).format('YYYY-MM-DD'),
      officeNumber: officeNumber?.phone || '',
      mobileNumber: mobileNumber?.phone || '',
      xing: xing?.social_address || '',
      linkedin: linkedin?.social_address || '',
      calendar: calendar?.social_address || '',
    });
  }, [userData]);

  const handleChange = (event) => {
    if (event.target.name === 'email') {
      if (event.target.value === userData.username) {
        if (!emailValidators.validators.includes('required')) {
          setEmailValidators({
            validators: ['required'],
            errorMessages: ['Email is required'],
          });
        }
      } else if (!myProfile) {
        if (!event.target.value) {
          setEmailValidators({
            validators: ['required'],
            errorMessages: ['Email is required'],
          });
        } else if (
          !emailValidators.validators.includes('required') &&
          !emailValidators.validators.includes('isEmailUnique')
        ) {
          setEmailValidators({
            validators: ['required', 'isEmailUnique'],
            errorMessages: ['Email is required', 'Duplicate email'],
          });
        }
      }
    }

    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  const handleSubmit = () => {
    setDisabledButton(true);
    const values = {
      tab: 'Profile',
      calendarLink: formData.calendar,
      username: formData.email,
      fname: formData.firstName,
      job_title: formData.jobTitle,
      linkedin: formData.linkedin,
      lname: formData.lastName,
      mob_number: formData.mobileNumber,
      ofc_number: formData.officeNumber,
      title: formData.title,
      xing: formData.xing,
      start_date: formData.startDate,
    };

    api
      .put(`/automation/users/${userData.id}`, { values })
      .then((res) => {
        setDisabledButton(false);
        setOpenSnackBar(true);
        getUserData();
        setOpenSnackBarMessage({
          message: 'Your profile updated successfully',
          status: 'success',
        });
      })
      .catch((error) => {
        setDisabledButton(false);
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          message: error?.response?.data?.error || 'Something went wrong ',
          status: 'error',
        });
      });
  };

  const handleEmailBlur = (event) => {
    if (event.target.value === userData.username) {
      return;
    }
    if (!event.target.value) {
      emailValidators.validators = ['required', 'isEmail'];
      emailValidators.errorMessages = ['Email is required', 'Invalid email'];
    } else {
      emailValidators.validators = ['required', 'isEmail', 'isEmailUnique'];
      emailValidators.errorMessages = [
        'Email is required',
        'Invalid email',
        'Duplicate email',
      ];
    }

    setEmailValidators({
      ...emailValidators,
    });
    setTimeout(() => {
      formRef.current.childs[
        formRef.current.childs.findIndex((e) => e.props.name === 'email')
      ].validate(formData.email);
    }, 20);
  };

  return (
    <div className={classes.mainFormValidator}>
      <ValidatorForm ref={(r) => (formRef.current = r)} onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          <h6 className={classes.formHeading}> Basic User Details </h6>

          {/*<div className={classes.profilePic}>*/}
          {/*  <Avatar*/}
          {/*    alt={`${userData.fname} ${userData.lname}`}*/}
          {/*    src={userData.picture}*/}
          {/*  />*/}
          {/*</div>*/}

          <Grid container spacing={2}>
            <Grid item xs={2} sm={2}>
              <TextValidator
                label="Title *"
                onChange={handleChange}
                name="title"
                variant="outlined"
                select
                value={formData?.title || ''}
                fullWidth
                validators={['required']}
                errorMessages={['Select title']}
              >
                {(dropdownList.titleList || []).map((tList, index) => (
                  <MenuItem
                    key={tList.id + tList.title_name + index}
                    value={tList.id}
                  >
                    {tList.title_name}
                  </MenuItem>
                ))}
              </TextValidator>
            </Grid>

            <Grid item xs={8} sm={4}>
              <TextValidator
                label="First Name *"
                onChange={handleChange}
                name="firstName"
                value={formData?.firstName || ''}
                style={{ paddingRight: '10' }}
                variant="outlined"
                fullWidth
                validators={['required']}
                errorMessages={['First Name Required']}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                label="Last Name *"
                onChange={handleChange}
                name="lastName"
                value={formData?.lastName || ''}
                style={{ paddingLeft: '10' }}
                variant="outlined"
                fullWidth
                validators={['required']}
                errorMessages={['Last Name Required']}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextValidator
                label="Email *"
                onChange={handleChange}
                name="email"
                disabled={myProfile}
                value={formData?.email || ''}
                style={{ paddingRight: '10' }}
                variant="outlined"
                fullWidth
                onBlur={handleEmailBlur}
                validators={emailValidators.validators}
                errorMessages={emailValidators.errorMessages}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextValidator
                label="Job Title *"
                onChange={handleChange}
                name="jobTitle"
                value={formData?.jobTitle || ''}
                style={{ paddingLeft: '10' }}
                variant="outlined"
                fullWidth
                validators={['required']}
                errorMessages={['Job Title is required']}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextValidator
                label="Start Date *"
                onChange={handleChange}
                name="startDate"
                type="date"
                disabled={disableControl}
                value={formData.startDate}
                variant="outlined"
                fullWidth
                validators={['required']}
                errorMessages={['Start Date is required']}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.formWrapper}>
          <h6 className={classes.formHeading}> Other Info </h6>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextValidator
                label="Mobile number"
                onChange={handleChange}
                name="mobileNumber"
                value={formData?.mobileNumber || ''}
                variant="outlined"
                fullWidth
                // validators={[`matchRegexp:^[+0-9]*$`]}
                validators={[`matchRegexp:^[- +0-9]*$`]}
                errorMessages={['Please enter number only']}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextValidator
                label="Office number"
                onChange={handleChange}
                name="officeNumber"
                value={formData?.officeNumber || ''}
                variant="outlined"
                fullWidth
                validators={[`matchRegexp:^[- +0-9]*$`]}
                errorMessages={['Please enter number only']}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextValidator
                label="Linkedin"
                onChange={handleChange}
                name="linkedin"
                value={formData?.linkedin || ''}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextValidator
                label="Xing"
                onChange={handleChange}
                name="xing"
                value={formData?.xing || ''}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextValidator
                label="Calendar"
                onChange={handleChange}
                name="calendar"
                value={formData?.calendar || ''}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.formFooter}>
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledButton}
            >
              Update
            </Button>
          </div>
        </div>
      </ValidatorForm>
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default ProfileForm;
