import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link, Tooltip, Toolbar, Slide, Snackbar } from '@material-ui/core';
import CustomDataGrid from '../../../controlled-component/customGridComponent';
import SimpleSwitch from '../../../components/SimpleSwitch';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';

import {
  getStepTemplates,
  addTemplateToEmailStep,
} from '../../../actions/sequence';
import { getUserTemplate } from '../../../actions/template';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0, //theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    /* float: 'left',
    borderLeft: '1px solid rgb(203, 214, 226)',
    height: '100%', */
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.custom.colorCode.white,
  },
  title: {
    /* marginLeft: theme.spacing(2), */
    flex: 1,
    fontSize: '16px !important',
    fontWeight: '600',
    color: theme.custom.colorCode.black,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.custom.colorCode.lightGreyColorShade10,
  },
});

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    /* maxWidth: '850px', */
  },
  stepMainGrid: {
    marginTop: 27,
    marginLeft: 20,
  },
  tdContentEllipsis: {
    width: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 3,
  },
  publishSwitch: {
    marginRight: 10,
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
}));

/**
 * Transition effect of dialog popup
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Component to show the heading of Dialog popup
 */
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, onAdd, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <AppBar className={classes.appBar} color={'secondary'}>
        <Toolbar color="secondary">
          <Typography variant="h6" className={classes.title}>
            {children}
          </Typography>
          {onClose ? (
            <IconButton
              edge="start"
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
              color="inherit"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Toolbar>
      </AppBar>
    </MuiDialogTitle>
  );
});

/**
 * Dialog content themeing
 */
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: 500,
    height: 300,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '0px', //theme.spacing(1),
  },
}))(MuiDialogActions);

/**
 * Component to open add new tempalte popup in step
 * @param {*} props
 */
const NewTemplate = (props) => {
  const dispatch = useDispatch();
  const { open, setOpen, sequenceId, step, showSnaker } = props;
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  /**
   * Handle function for close button
   */
  const handleClose = (showToaster = false) => {
    //if (showToaster) showSnaker();
    setOpen(false);
  };

  //subscribe data from state
  const addedTemplates = useSelector((state) => state.sequence.templates);
  const templates = useSelector((state) => state.template.templates);

  var templateOptions = templates.map(function (temlate) {
    return { value: temlate.id, label: temlate.name };
  });
  const templatesOptions = templateOptions.filter(
    (e) => !addedTemplates.find((obj) => obj.id === e.value),
  );

  /**
   * Call api function to get templates data
   */
  useEffect(() => {
    dispatch(getUserTemplate(1, 1));
  }, []);

  /**
   * Handle function for select element
   * @param {*} name
   * @param {*} key
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    return setFormData({
      ...formData,
      [name]: tag.map((e) => e[key]),
      /* [name]: tag[key], */
    });
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = e === 'emails' ? !formData[e]?.length : !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['emails'];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disableButton;
    }
  };

  /**
   * Handle function for submit button
   */
  const handleSubmit = () => {
    //let value = templateOptions.find((e) => e.value === formData.emails[0]);
    let selectedTemplates = templateOptions.filter((c) =>
      formData?.emails.includes(c.value),
    );
    let steps = {
      step_type: props.step_type ? props.step_type : 1,
      step_event: props.step_event ? props.step_event : 'email',
      emails: formData.emails,
      selectedTemplates: selectedTemplates,
    };

    //Save step templates into database
    dispatch(addTemplateToEmailStep(sequenceId, step.id, handleClose, steps));
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.root}
        TransitionComponent={Transition}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {`Add new template`}
        </DialogTitle>
        <DialogContent dividers>
          <ValidatorForm onSubmit={handleSubmit}>
            <div className="emailContent">
              <h3>Existing email templates</h3>
              <FormAutoCompleteSelect
                multiple
                onChange={handleSelectChange('emails', 'value')}
                name="emails"
                id="emails"
                disableClearable
                options={templatesOptions}
                getOptionLabel={(option) => option.label || ''}
                /* getOptionDisabled={(options) =>
                  formData?.emails?.length >= 5 ? true : false
                } */
                value={
                  formData?.emails?.length
                    ? (templatesOptions || []).filter((c) =>
                        formData?.emails.includes(c.value),
                      )
                    : []
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label=""
                    name="emails"
                    variant="outlined"
                    fullWidth
                    /* variant="outlined" */
                    value={formData?.emails || ''}
                  />
                )}
              />
              <Box display="flex" className="mt-5" justifyContent="right">
                <Button
                  variant="contained"
                  className={`${classes.nextButton}`}
                  type="submit"
                  disabled={disabledNextButton()}
                >
                  {`Add`}
                </Button>
              </Box>
            </div>
          </ValidatorForm>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NewTemplate;
