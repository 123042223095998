import { makeStyles } from "@material-ui/core/styles";

export { default as accordionInfoIcon } from "../../../../../img/icons/AccordionInfoIcon.svg";
export { default as accordionCompleteIcon } from "../../../../../img/icons/AccordionCompleteIcon.svg";
export { default as checkedCircleIcon } from "../../../../../img/icons/CheckedCircle.svg";

export const useStyles = makeStyles((theme) => ({
  // ACCORDION COMPONENT STYLES : START
  outerAccordionInfoText: {
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "150%",
  },
  outerAccordionInfoTextComplete: {
    color: "#66BB6A",
  },
  outerAccordionIconWrap: {
    position: "relative",
  },
  outerAccordionCompletedCheck: {
    position: "absolute",
    top: -6,
    right: 4,
  },
  outerAccordionIcon: {
    width: 35,
    height: 35,
    marginRight: 10,
  },
  accordionContainer: {},
  outerAccordionContainer: {
    marginBottom: 30,
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #E0E0E0",
    borderRadius: 4,
    "&.Mui-expanded": {
      marginBottom: 30,
    },
  },
  outerAccordionDetails: {
    flexDirection: "column",
    padding: 0,
  },
  innerAccordionContainer: {
    border: "1px solid #ffffff",
    "&:before": {
      backgroundColor: "transparent",
    },
    "&.Mui-expanded": {
      border: "1px solid #1E88E5",
      boxSizing: "border-box",
      borderRadius: 5,
    },
  },
  innerAccordionDetails: {
    paddingLeft: 46,
  },
  outerAccordionSummary: {
    minHeight: 64,
    maxHeight: 64,
    "& .MuiAccordionSummary-content": {
      position: "relative",
      display: "block",
    },
  },
  innerAccordionSummary: {
    minHeight: 64,
    maxHeight: 64,
  },
  accordionProgress: {
    position: "absolute",
    bottom: -14,
    width: "calc(100% + 32px)",
    left: -16,
    backgroundColor: "#dddddd !important",
    borderRadius: 4,
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#66BB6A",
    },
  },
  accordionInfoIcon: {
    width: 20,
    marginRight: 10,
  },
  // ACCORDION COMPONENT STYLES : END

  // INTRO COMPONENT STYLES : START
  introContainer: {
    width: "calc(100vw - 50% - 160px)",
    maxWidth: 500,
    minWidth: 440,
    position: "absolute",
    left: "calc(100vw - 50% - 200px)",
    top: 140,
  },
  introContent: {
    position: "relative",
  },
  introManImage: {
    maxWidth: 230,
    position: "absolute",
    right: -150,
    bottom: -75,
    zIndex: 1,
  },
  introBubble: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "flex-start",
    padding: 20,
    boxShadow: "0px 0px 0px 1px #FFA726",
    borderRadius: 5,
    zIndex: 2,
    backgroundColor: "#ffffff",
  },
  introFromText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  introSRLogo: {
    marginLeft: 10,
  },
  // INTRO COMPONENT STYLES : END
}));
