import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

export const CustomSnackBar = ({
  openSnackBar,
  barMessage = 'Success',
  handleSnackbarClose,
  hideDuration = 2000,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  severity = 'success',
  children,
  ...restProps
}) => {
  return (
    <Snackbar
      open={openSnackBar}
      autoHideDuration={hideDuration}
      onClose={handleSnackbarClose}
      anchorOrigin={anchorOrigin}
      {...restProps}
    >
      {children ? (
        children
      ) : (
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={severity}
        >
          {barMessage}
        </Alert>
      )}
    </Snackbar>
  );
};

export default CustomSnackBar;
