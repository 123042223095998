import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TemplateGrid from './templateGrid';

// class MyTemplate extends Component {
//   constructor(props) {
//     super(props);
//   }
//
//   render() {
//     return (
//       <div className="">
//         <div className="flex-row">
//           <aside className="sideBarMargin">
//             {/* Call the sidebar components */}
//             <SidebarNav cvspec="active" />
//           </aside>
//
//           <div className="flex-col content">
//             {/* Call the template grid component to have the CV Spec grid */}
//             <TemplateGrid {...this.props} />
//             {/* <ToastContainer autoClose={2000} /> */}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

/**
 * Class to manage the CV Spec grid
 * Main class to invlok all the required components
 */
const MyTemplate = (props) => {
  // Call the template grid component to have the CV Spec grid
  return <TemplateGrid {...props} />;
};

MyTemplate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MyTemplate);
