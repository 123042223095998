import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../components/PageHeader";
import TemplateGrid from "./TemplateGrid/TemplateGrid";
import Spinner from "../layout/Spinner";
import { fetchTabs } from "../actions/template";

const useStyles = makeStyles(() => ({
  mainHeader: {
    "& .heading-title": {
      marginBottom: 0,
    },
  },
}));

const TemplateTabs = (props) => {
  const classes = useStyles();
  const templateCount = useSelector((state) => state.template.count);
  const templateTabs = useSelector((state) => state.template.templateTab);
  const dispatch = useDispatch();

  useEffect(() => {
    setTabs();
  }, []);

  const setTabs = async () => {
    await dispatch(fetchTabs());
  };

  if (templateTabs.loading) {
    return <Spinner />;
  }

  return (
    <div>
      <PageHeader
        mainTitle={"Email Templates"}
        subTitle={
          "Set up and manage templates for all users, to help increase their efficiency."
        }
        showCount={true}
        itemCount={templateCount}
        className={classes.mainHeader}
      />
      {templateTabs?.tabs && (
        <TemplateGrid {...props} tabProps={templateTabs.tabs[0]} />
      )}
    </div>
  );
};

export default TemplateTabs;
