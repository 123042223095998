import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  Collapse,
  Drawer,
  IconButton,
  Box,
  ListItemIcon,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { openCloseSideBar, setCurrentHeader } from '../actions/app';
import { Link, useLocation } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import { ExpandMore } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';

import getParentNode from '../utils/getParentNode';
import Tooltip from '../components/Tooltip';

// common material ui style for sidebars
import useStyles from '../utils/sidebar.style';
import logo from '../../../img/logo.png';
import getMenuItems from '../utils/getMenuItems';

/**
 Sidebar for all users
 */
const Sidebar = () => {
  const classes = useStyles();

  let locations = useLocation();
  const [path, setPath] = useState(locations.pathname);
  let [menuItems, setMenuItems] = useState([]);

  const dispatch = useDispatch();
  const { sideBarIsOpen } = useSelector((state) => state.app);

  useEffect(() => {
    setMenuItems(getMenuItems(path, path.split('/')[1]));
  }, []);

  useEffect(() => {
    if (path.split('/')[1] !== locations.pathname.split('/')[1]) {
      // todo will be used for future
      if (
        locations.pathname.split('/')[1] !== 'profile' &&
        locations.pathname.split('/')[1] !== 'admin'
      ) {
        menuItems = getMenuItems(
          locations.pathname,
          locations.pathname.split('/')[1],
        );
        setMenuItems([...menuItems]);
        updateActiveState(menuItems);
        setMenuItems(cloneDeep(menuItems));
      }
    } else if (menuItems.length) {
      updateActiveState(menuItems);
      setMenuItems(cloneDeep(menuItems));
    }
    setPath(locations.pathname);
    if (menuItems.length) {
      dispatch(setCurrentHeader(getParentNode(menuItems, locations.pathname)));
    }
  }, [locations.pathname]);

  useEffect(() => {
    if (menuItems.length) {
      dispatch(setCurrentHeader(getParentNode(menuItems, locations.pathname)));
    }
  }, [menuItems]);

  const updateActiveState = (arr) => {
    (arr || []).forEach((i) => {
      let currentPAth = locations.pathname.split('/')[1];
      if (currentPAth === 'prospect') {
        currentPAth = `${locations.pathname.split('/')[1]}/${
          locations.pathname.split('/')[2]
        }`;
      }
      if (locations.pathname === '/profile/integrations') {
        currentPAth = `${locations.pathname.split('/')[1]}/${
          locations.pathname.split('/')[2]
        }`;
      }
      if (
        isEqual(i.link, locations.pathname) ||
        isEqual(i.link, `/${currentPAth}`)
      ) {
        if (!i.child?.length) {
          i.activeClass = 'active';
        } else {
          updateActiveState(i.child);
        }
      } else {
        if (i.activeClass !== undefined) i.activeClass = '';
        updateActiveState(i.child);
      }
    });
  };

  const handleClick = (parent, child) => (event) => {
    const parentNodeChild = menuItems.find((e) => e.id === parent.id);

    if (parentNodeChild?.child?.length) {
      // event is null then child active will not be added
      // null is expand icon
      if (event) {
        menuItems.forEach((e) => {
          e?.child?.forEach((c) =>
            c.id === (child ? child : parentNodeChild?.child[0]).id
              ? (c.activeClass = 'active')
              : (c.activeClass = ''),
          );
          e.activeClass = '';
        });
      }
      if (!child) {
        const childNode = menuItems.find((e) => e.id === parent.id);
        if (!sideBarIsOpen) {
          if (!childNode.in) {
            childNode.in = !childNode.in;
          }
        } else {
          childNode.in = !childNode.in;
        }
      }
    } else {
      menuItems.forEach((e) => {
        e?.child?.forEach((c) => (c.activeClass = ''));
        parentNodeChild.id === e.id
          ? (e.activeClass = 'active')
          : (e.activeClass = '');
      });
    }
    menuItems = cloneDeep(menuItems);
    setMenuItems(cloneDeep(menuItems));
    !sideBarIsOpen && dispatch(openCloseSideBar());
  };

  return (
    <div
      className="sidebar-wrapper"
      style={sideBarIsOpen ? { width: 230 } : { width: 55 }}
    >
      <Drawer
        variant="permanent"
        docked={undefined}
        className={classes.sliderDrawer}
        classes={{
          paper: sideBarIsOpen ? classes.sliderPaper : classes.drawerPaperClose,
        }}
        open={sideBarIsOpen}
      >
        <Box display="flex" className={classes.headerLogo}>
          {/*<IconButton onClick={toggleSidebar} style={{ outline: 'none' }}>*/}
          {/*  <MenuIcon style={{ color: '#0655FF' }} />*/}
          {/*</IconButton>*/}
          <Link to={'/'}>
            <img
              src={logo}
              alt=""
              border="0"
              className={`img-fluid rounded-circle ${classes.leftBoxIcon}`}
            />
          </Link>
        </Box>
        {/*<Box*/}
        {/*  display="flex"*/}
        {/*  justifyContent={sideBarIsOpen ? 'space-between' : 'flex-start'}*/}
        {/*>*/}
        {/*  {sideBarIsOpen && (*/}
        {/*    <div className={classes.goBackWrapper}>*/}
        {/*      <Link to="/dashboard">*/}
        {/*        <span>*/}
        {/*          <i className="fa fa-chevron-left" />*/}
        {/*        </span>{' '}*/}
        {/*        Back to Dashboard*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</Box>*/}

        {/*{sideBarIsOpen && (*/}
        {/*  <div className="admin_settings">*/}
        {/*    <h2>Admin Settings</h2>*/}
        {/*  </div>*/}
        {/*)}*/}

        <List component="nav" className={classes.root}>
          {menuItems.map((item, index) => (
            <Box key={`menu-list-item-wrapper-${item.id}-${index}`}>
              <Tooltip
                title={!sideBarIsOpen ? item.title : ''}
                arrow
                placement={sideBarIsOpen ? 'bottom' : 'right'}
                key={`menu-tooltip-item-${item.id}-${index}`}
              >
                <ListItem
                  button
                  className={`${classes.navListTitle} font-weight-bold ${
                    item.activeClass || ''
                  }`}
                  key={`menu-list-item-${item.id}-${index}`}
                  onClick={handleClick(item)}
                  component={Link}
                  to={item.link}
                >
                  {item.menuIcon && (
                    <ListItemIcon
                      // onClick={
                      //   item.expand && !sideBarIsOpen
                      //     ? handleClick(item)
                      //     : () => {}
                      // }
                      className={`${classes.listItemIcon} ${
                        item?.child?.find((e) => e.activeClass === 'active') &&
                        !sideBarIsOpen
                          ? 'active'
                          : ''
                      }`}
                    >
                      {item.menuIcon}
                    </ListItemIcon>
                  )}

                  <div className="career_portal sideText">{item.title}</div>
                  {item.expand && (
                    <ExpandMore
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleClick(item)(null);
                      }}
                      className={
                        sideBarIsOpen && item.in
                          ? `${classes.collapseIconExpand} ${classes.collapseIcon}`
                          : classes.collapseIcon
                      }
                    />
                  )}
                </ListItem>
              </Tooltip>
              <Collapse
                in={sideBarIsOpen && item.in}
                timeout="auto"
                unmountOnExit
                className={classes.collapseWrapper}
              >
                {Boolean(item?.child?.length) && (
                  <List component="div" disablePadding>
                    {item.child.map((child, cIndex) =>
                      child.expand ? (
                        <Box
                          key={`menu-list-child-item-${child.id}-${cIndex}-menu-collapse-list-child-item`}
                        >
                          {sideBarIsOpen && (
                            <List component="div" disablePadding key={`menu-list-super-child-item-${child.id}-${cIndex}`}>
                              {Boolean(child.child.length) &&
                                child.child.map((c) => (
                                  <Tooltip
                                    title={!sideBarIsOpen ? c.title : ''}
                                    arrow
                                    placement={
                                      sideBarIsOpen ? 'bottom' : 'right'
                                    }
                                    key={`menu-list-child-item-nested-${child.id}-${c.id}-${cIndex}`}
                                  >
                                    <ListItem
                                      button
                                      className={`${classes.nested} ${classes.navListChildItem} ${c.activeClass}`}
                                      component={Link}
                                      to={c.link}
                                      key={`menu-listItem-child-item-nested-${child.id}-${c.id}-${cIndex}`}
                                      style={
                                        c.menuIcon ? {} : { paddingLeft: 48 }
                                      }
                                    >
                                      {c.menuIcon && (
                                        <ListItemIcon
                                          className={classes.listItemIcon}
                                        >
                                          {c.menuIcon}
                                        </ListItemIcon>
                                      )}

                                      <div className="career_portal sideText">
                                        {c.title}
                                      </div>
                                    </ListItem>
                                  </Tooltip>
                                ))}
                            </List>
                          )}
                        </Box>
                      ) : (
                        <Tooltip
                          title={!sideBarIsOpen ? child.title : ''}
                          arrow
                          placement={sideBarIsOpen ? 'bottom' : 'right'}
                          key={`menu-tooltip-list-item-wrapper-${child.id}-${cIndex}`}
                        >
                          <ListItem
                            button
                            className={`${classes.nested} ${classes.navListChildItem} ${child.activeClass}`}
                            component={Link}
                            to={child.link}
                            key={`menu-list-child-item-${child.id}-${cIndex}`}
                            style={child.menuIcon ? {} : { paddingLeft: 48 }}
                            onClick={handleClick(item, child)}
                          >
                            {child.menuIcon && (
                              <ListItemIcon className={classes.listItemIcon}>
                                {child.menuIcon}
                              </ListItemIcon>
                            )}

                            <div className="career_portal sideText">
                              {child.title}
                            </div>
                          </ListItem>
                        </Tooltip>
                      ),
                    )}
                  </List>
                )}
              </Collapse>
            </Box>
          ))}

          {/*Item to be used in future*/}
          {/*  <ListItem*/}
          {/*    button*/}
          {/*    className={`${classes.nested} ${classes.navListChildItem} ${props.offices}`}*/}
          {/*    component={Link}*/}
          {/*    to={'/admin/new-offices'}*/}
          {/*  >*/}
          {/*    Offices*/}
          {/*  </ListItem>*/}
          {/*</List>*/}
          {/*Item to be used in future*/}
          {/*      <ListItem*/}
          {/*        button*/}
          {/*        className={`${classes.nested} ${classes.navListChildItem}`}*/}
          {/*      >*/}
          {/*        <div className="career_portal sideText">Job Titles</div>*/}
          {/*      </ListItem>*/}
          {/*      <ListItem*/}
          {/*        button*/}
          {/*        className={`${classes.nested} ${classes.navListChildItem}`}*/}
          {/*      >*/}
          {/*        <div className="career_portal sideText">Note types</div>*/}
          {/*      </ListItem>*/}
          {/*      <ListItem*/}
          {/*        button*/}
          {/*        className={`${classes.nested} ${classes.navListChildItem}`}*/}
          {/*      >*/}
          {/*        <div className="career_portal sideText">Job Grades</div>*/}
          {/*      </ListItem>*/}
          {/*      <ListItem*/}
          {/*        button*/}
          {/*        className={`${classes.nested} ${classes.navListChildItem}`}*/}
          {/*      >*/}
          {/*        <div className="career_portal sideText">Languages</div>*/}
          {/*      </ListItem>*/}
          {/*      <ListItem*/}
          {/*        button*/}
          {/*        className={`${classes.nested} ${classes.navListChildItem}`}*/}
          {/*      >*/}
          {/*        <div className="career_portal sideText">Currencies</div>*/}
          {/*      </ListItem>*/}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
