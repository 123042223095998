import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Collapse, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Alert from '../Alert';
import { OutlinedButton } from '../Button';

import { openCloseProfileAlert } from '../../actions/app';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
  },
  alertMainBox: {
    backgroundColor: custom.colorCode.drawerHeaderBackGround,
    borderRadius: 0,
  },
  title: {
    width: '50%',
    '& .MuiTypography-root': {
      fontWeight: 300,
    },
    '& span': {
      fontWeight: 700,
    },
    '& a': {
      textDecoration: 'underline',
    },
  },
  right: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontWeight: 300,
    },
  },
  headingAlertButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
}));

const HeaderAlert = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const appReducer = useSelector((state) => state.app);

  const handleClose = () => {
    dispatch(openCloseProfileAlert());
  };

  // TODO: create separate component and will be used like plugin in the near future as well
  return (
    <Box className={classes.root}>
      <Collapse in={appReducer.showProfileAlert}>
        <Alert className={classes.alertMainBox} handleClose={handleClose}>
          <Box display="flex" flex={'wrap'}>
            <Box className={classes.title}>
              <Typography variant="body2" component="div">
                <span>Task LinkedIn Mesasage</span>
              </Typography>

              <Typography variant="body2" component="div">
                Mohit Tailor
              </Typography>
            </Box>

            <Box className={classes.right}>
              <Box pr={1} pl={1}>
                <OutlinedButton
                  variant="outlined"
                  className={classes.headingAlertButton}
                >
                  Reschedule
                </OutlinedButton>
              </Box>

              <Box pr={1} pl={1}>
                <OutlinedButton
                  variant="outlined"
                  className={classes.headingAlertButton}
                >
                  Skip
                </OutlinedButton>
              </Box>

              <Box pr={1} pl={1}>
                <OutlinedButton
                  variant="outlined"
                  className={classes.headingAlertButton}
                >
                  Complete
                </OutlinedButton>
              </Box>
              <Box pr={1} pl={1}>
                <Typography variant="body2" component="div">
                  1 of 2
                </Typography>
              </Box>
            </Box>
          </Box>
        </Alert>
      </Collapse>
    </Box>
  );
};

export default HeaderAlert;
