import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';

import FilterActivity from './FilterActivity';
import CardContentNoteDetail from './CardContentNoteDetail';
import Spinner from '../../layout/Spinner';
import api from '../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {},
  filterWrapper: {
    paddingBottom: 8,
  },
  cardTextContentWrapper: {
    background: custom.colorCode.white,
    // border: `1px solid ${custom.colorCode.lightGreyColorProfileShade22}`,
  },
  showMoreText: {
    color: custom.colorCode.blueLightShade8,
    fontWeight: 700,
    cursor: 'pointer',
  },
}));

const ActivityNoteSection = ({ profileType, profileId }) => {
  const classes = useStyles();

  const [filterData, setFilterData] = useState({
    user: {
      name: 'user',
      filter: [],
    },
    noteType: {
      name: 'type',
      filter: [],
    },
    // dateRangePicker: {
    //   name: 'date',
    //   filter: {},
    // },
  });
  const [filterItemData, setFilterItemData] = useState([
    {
      data: [],
      isSingle: true,
      name: 'user',
      optionLabel: 'name',
      optionValue: 'id',
      showName: true,
      title: 'Users',
      type: 'user',
    },
    {
      data: [],
      isSingle: true,
      name: 'noteType',
      optionLabel: 'name',
      optionValue: 'id',
      showName: true,
      title: 'Note Type',
      type: 'noteType',
    },
    // {
    //   data: {},
    //   isSingle: true,
    //   name: 'date',
    //   showName: true,
    //   title: 'Date',
    //   type: 'dateRangePicker',
    // },
  ]);
  const [loading, setLoading] = useState(true);
  let [currentPageNo, setCurrentPageNo] = useState(0);
  const [noteFilterState, setNoteFilterState] = useState();
  const [notesData, setNotesData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getFilterDataFromApi();
  }, []);

  useEffect(() => {
    if (profileType && profileId) {
      getFilterDataApiState();
    }
  }, [profileType, profileId]);

  const getFilterDataFromApi = async () => {
    await api
      .get(`/community/get-note-type-list`)
      .then((resp) => {
        const filterItemIndex = filterItemData.findIndex(
          (fItem) => fItem.type === 'noteType',
        );
        filterItemData[filterItemIndex].data = resp.data;
        setFilterItemData([...filterItemData]);
      })
      .catch((error) => {
        console.log('error', error);
      });
    api
      .get(`/automation/get-users-dropdown-list`)
      .then((resp) => {
        const filterItemIndex = filterItemData.findIndex(
          (fItem) => fItem.type === 'user',
        );
        filterItemData[filterItemIndex].data = resp.data;
        setFilterItemData([...filterItemData]);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getFilterDataApiState = (noteFilterOptions, isShowAll) => {
    const keys = Object.keys(noteFilterOptions || {});

    setNoteFilterState(noteFilterOptions || noteFilterState || {});

    let payload = {};
    keys.forEach((e) => {
      if (!isEmpty(noteFilterOptions[e].filter)) {
        if (e === 'dateRangePicker') {
          payload.createdFrom = noteFilterOptions[e].filter.from;
          payload.createdTo = noteFilterOptions[e].filter.to;
          return;
        }
        payload[e] = noteFilterOptions[e].filter.length
          ? noteFilterOptions[e].filter.map((no) => no.id)
          : '';
      }
    });

    setLoading(true);
    api
      .get(
        `community/get-note-list?contact_id=${profileId}&audience_type=${
          profileType === 'candidate' ? 2 : 1
        }&filters=${
          isEmpty(payload) ? JSON.stringify([]) : JSON.stringify(payload)
        }&perPage=25&pageNo=${currentPageNo}`,
      )
      .then((resp) => {
        setTotalCount(resp.data.count);
        isShowAll
          ? setNotesData([...notesData, ...resp.data.data])
          : setNotesData(resp.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleChange = (changeValue) => {
    setFilterData({ ...changeValue });
    currentPageNo = 0;
    setCurrentPageNo(currentPageNo);
    getFilterDataApiState({ ...changeValue });
  };

  const handleShowMoreClick = () => {
    currentPageNo = ++currentPageNo;
    setCurrentPageNo(currentPageNo);
    getFilterDataApiState(undefined, true);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.filterWrapper} mt={1}>
        <FilterActivity
          getFilterState={handleChange}
          filterItemData={filterItemData}
          filterData={filterData}
        />
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center">
          <Spinner style={{ width: 20, height: 20, position: 'unset' }} />
        </Box>
      ) : (
        <>
          <Paper
            className={classes.cardTextContentWrapper}
            pb={0.5}
            elevation={3}
          >
            {notesData.length ? (
              notesData.map((nD, index) => (
                <CardContentNoteDetail
                  key={`notes-card-content-${nD.id}-${index}`}
                  {...nD}
                />
              ))
            ) : (
              <Box p={1.5} textAlign={'center'}>
                <Typography variant="body2">Sorry, no records found</Typography>
              </Box>
            )}
          </Paper>

          {totalCount > notesData.length && (
            <Grid item xs={12} sm={12}>
              <Box
                display="flex"
                justifyContent="center"
                onClick={handleShowMoreClick}
                p={1}
              >
                <Typography variant="h6" className={classes.showMoreText}>
                  Show all
                </Typography>
              </Box>
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default ActivityNoteSection;
