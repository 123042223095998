import React from 'react';
import MUIList from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import {
  Avatar,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  avatar: {},
}));

export const ListItem = ({
  showAvatar = false,
  avatarText = '',
  avatarSrc,
  showSecondaryAction,
  primaryText = '',
  secondaryText = '',
  itemSecondaryContent = <></>,
}) => {
  const classes = useStyles();

  return (
    <MUIListItem>
      {showAvatar && (
        <ListItemAvatar>
          <Avatar
            className={classes.avatar}
            alt={avatarText}
            src={avatarSrc || ''}
          />
        </ListItemAvatar>
      )}
      <ListItemText primary={primaryText} secondary={secondaryText} />
      {showSecondaryAction && (
        <ListItemSecondaryAction>
          {itemSecondaryContent}
        </ListItemSecondaryAction>
      )}
    </MUIListItem>
  );
};

const ListItems = ({ children, ...props }) => {
  return <MUIList {...props}>{children}</MUIList>;
};

export default ListItems;
