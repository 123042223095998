import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  LinearProgress,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  useStyles,
  accordionInfoIcon,
  accordionCompleteIcon,
  checkedCircleIcon,
} from "./styles";

const OnboardingAccordion = ({ children }) => {
  const classes = useStyles();

  const outer = React.Children.map(children, (child) =>
    child.type.displayName === "Outer" ? child : null
  );
  const inner = React.Children.map(children, (child) =>
    child.type.displayName === "Inner" ? child : null
  );
  return <div>{outer}</div>;
};

const Outer = ({ title, isCompleted, icon, progress, ...props }) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.outerAccordionContainer}>
      <AccordionSummary
        // expandIcon={<ExpandMoreIcon />}
        className={classes.outerAccordionSummary}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex">
            {icon && (
              <Box className={classes.outerAccordionIconWrap}>
                {isCompleted && (
                  <img
                    src={checkedCircleIcon}
                    className={classes.outerAccordionCompletedCheck}
                  />
                )}
                <img src={icon} className={classes.outerAccordionIcon} />
              </Box>
            )}
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Typography
            variant="body2"
            className={
              isCompleted
                ? `${classes.outerAccordionInfoTextComplete} ${classes.outerAccordionInfoText}`
                : classes.outerAccordionInfoText
            }
          >
            {isCompleted ? (
              "All steps complete!"
            ) : (
              <>
                {props.steps} steps | About {props.time}
              </>
            )}
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={progress}
          className={classes.accordionProgress}
        />
      </AccordionSummary>
      <AccordionDetails className={classes.outerAccordionDetails}>
        <Box padding={2}>{props.children}</Box>
      </AccordionDetails>
    </Accordion>
  );
  return <div className="card">{props.children}</div>;
};
Outer.displayName = "Outer";
OnboardingAccordion.Outer = Outer;

const Inner = ({ title, isCompleted, ...props }) => {
  const classes = useStyles();
  return (
    <Accordion
      square={true}
      className={classes.innerAccordionContainer}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.innerAccordionSummary}
      >
        <img
          className={classes.accordionInfoIcon}
          src={!isCompleted ? accordionInfoIcon : accordionCompleteIcon}
        />
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.innerAccordionDetails}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};
Inner.displayName = "Inner";
OnboardingAccordion.Inner = Inner;

export default OnboardingAccordion;
