import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import AssignDetail from '../SucceededProfile/AssignDetail';
import ActivityNoteSection from './ActivityNoteSection';
import TaskActivityCollapseItem from './TaskActivityCollapseItem';
import Spinner from '../../layout/Spinner';
import CustomSnackBar from '../../components/CustomSnackBar';

import api from '../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
  },
  upcomingTaskWrapper: {},
  upcomingTitle: {
    marginBottom: '0.7em',
  },
  fontRed: {},
  collapseCardWrapper: {
    '& .collapseCard': {
      border: 'none',
      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
  },
  collapseEditText: {
    color: custom.colorCode.lightLightGreenColor3,
    textDecoration: 'underline',
    cursor: 'pointer',
    '& svg': {
      marginLeft: 4,
    },
  },
  completedText: {
    color: custom.colorCode.lightLightGreenColor3,
    cursor: 'pointer',
    '& svg': {
      fill: custom.colorCode.lightLightGreenColor3,
      marginRight: 4,
    },
  },
}));

const Activity = ({ profileType, profileId }) => {
  const classes = useStyles();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tasksData, setTasksData] = useState([]);

  useEffect(() => {
    getUpcomingTask();
  }, [profileType]);

  useEffect(() => {}, []);

  const getUpcomingTask = () => {
    if (profileType === 'candidate') {
      return api
        .get(
          `/automation/get-all-open-task?contact_type=2&contact_id=${profileId}`,
        )
        .then((resp) => {
          setTasksData(resp.data.task);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
      // setTabIndex
    } else if (profileType === 'contact') {
      return api
        .get(
          `/automation/get-all-open-task?contact_type=1&contact_id=${profileId}`,
        )
        .then((resp) => {
          setTasksData(resp.data.task);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  // if (loading) {
  //   return (
  //     <Box display="flex" justifyContent="center">
  //       <Spinner style={{ width: 20, height: 20, position: 'unset' }} />
  //     </Box>
  //   );
  // }

  const handleShowToast = (showToast, toastData) => {
    setOpenSnackBar(showToast);
    setOpenSnackBarMessage(toastData);
  };

  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.upcomingTaskWrapper}>
        <>
          {loading ? (
            <>
              <Box display="flex" justifyContent="center">
                <Spinner style={{ width: 20, height: 20, position: 'unset' }} />
              </Box>
            </>
          ) : (
            Boolean(tasksData.length) && (
              <>
                <Typography
                  color="secondary"
                  variant="body1"
                  component="p"
                  gutterBottom
                  className={classes.upcomingTitle}
                >
                  Tasks
                </Typography>

                <Box className={classes.collapseCardWrapper}>
                  {tasksData.map((task, index) => (
                    <TaskActivityCollapseItem
                      task={task}
                      profileType={profileType}
                      key={`profile-task-${task.taskId}-${index}`}
                      showToast={handleShowToast}
                      reFetchData={getUpcomingTask}
                    />
                  ))}
                </Box>
              </>
            )
          )}
        </>
        <ActivityNoteSection profileType={profileType} profileId={profileId} />
      </Box>
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        handleSnackbarClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </Box>
  );
};

export default Activity;
