import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import DrawerFormFooter from '../../../components/DrawerFormHeader/DrawerFormFooter';
import Spinner from '../../../layout/Spinner';

import api from '../../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
}));

/**
 * Component for Bullhorn Integration form
 * @param {*} props
 * @returns
 */
const ContactEditForm = (props) => {
  const classes = useStyles();

  //Local state variables
  const [formData, setFormData] = useState({});
  const [responseData, setResponseData] = useState({});
  let [disabledButton, setDisabledButton] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setDateInForm();
    ValidatorForm.addValidationRule('isEmailUnique', async (value) => {
      if (!value) {
        return true;
      }
      // call api for email duplication
      const params = {
        email: value,
        id: props.profileId,
      };
      return await api
        .get('/candidate/check-duplicate-email', { params })
        .then((res) => {
          if (res.data === true) {
            return false;
          }
          // return false for the error
          return true;
        })
        .catch(() => {
          return false;
        });
    });
    return () => {
      if (ValidatorForm.hasValidationRule('isEmailUnique')) {
        ValidatorForm.removeValidationRule('isEmailUnique');
      }
    };
  }, []);

  // this will initialize the text field
  const setDateInForm = () => {
    if (props.profileType === 'candidate') {
      api
        .get(`/candidate/get-contact-detail/${props.profileId}`)
        .then((resp) => {
          setResponseData(resp.data);
          setFormData({
            mobile: resp.data?.phoneData?.phone || '',
            home: resp.data?.phoneData?.home || '',
            office: resp.data?.phoneData?.office || '',
            workEmail: resp.data?.emailData?.work_email || '',
            personalEmail: resp.data?.emailData?.email || '',
          });
          setDisabledButton(false);
        })
        .catch(() => {
          setDisabledButton(false);
        });
    } else if (props.profileType === 'contact') {
      api
        .get(`/dm/get-contact-detail/${props.profileId}`)
        .then((resp) => {
          setResponseData(resp.data);
          setFormData({
            mobile: resp.data?.phoneData?.personalNumber || '',
            home: resp.data?.phoneData?.homeNumber || '',
            office: resp.data?.phoneData?.officeNumber || '',
            workEmail: resp.data?.emailData?.work_email || '',
            personalEmail: resp.data?.emailData?.email || '',
          });
          setDisabledButton(false);
        })
        .catch(() => {
          setDisabledButton(false);
        });
    }
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = [];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Handle function of save detail of profile
   * It call server API to save data for contact and candidate
   */
  const handleSubmit = async () => {
    if (disabledNextButton() === false) {
      disabledButton = true;
      setDisabledButton(true);
      if (props.profileType === 'candidate') {
        const values = {
          candidate_id: props.profileId,
          account_id: '',
          personanl_code: '',
          personanl_number: formData?.mobile || '',
          home_number: formData?.home || '',
          office_code: '',
          office_number: formData?.office || '',
          previous_email: responseData?.emailData?.email || '',
          email: formData?.personalEmail || '',
          previous_personal_email: responseData?.emailData?.email || '',
          previous_work_email: responseData?.emailData?.work_email || '',
          personal_email: formData?.personalEmail || '',
          work_email: formData?.workEmail || '',
          phone: [formData?.mobile, formData?.home, formData?.office],
        };

        await api
          .post('/candidate/save-people-contact', { ...values })
          .then((res) => {
            props?.closeDrawer({
              data: true,
              message: 'Contact updated successfully',
              status: 'success',
            });
          });
      } else if (props.profileType === 'contact') {
        const values = {
          dm_id: props.profileId,
          account_id: '',
          personanl_code: '',
          personanl_number: formData?.mobile || '',
          home_number: formData?.home || '',
          office_code: '',
          office_number: formData?.office || '',
          previous_email: responseData?.emailData?.work_email || '',
          email: formData?.workEmail || '',
          previous_personal_email: responseData?.emailData?.email || '',
          previous_work_email: responseData?.emailData?.work_email || '',
          personal_email: formData?.personalEmail || '',
          work_email: formData?.workEmail || '',
          phone: [formData?.mobile, formData?.home, formData?.office],
        };

        await api.post('/dm/save-people-contact', { ...values }).then((res) => {
          props?.closeDrawer({
            data: true,
            message: 'Contact updated successfully',
            status: 'success',
          });
        });
      }
      setTimeout(() => {
        setDisabledButton(false);
      }, 10);
    }
  };

  /**
   * function to handle the form element change event
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  // this will show the loader when api is getting called
  if (disabledButton) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  return (
    <Box className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <Box className={classes.formWrapper}>
          <Box>
            <Grid container spacing={3}>
              {/*Mobile Number*/}
              <Grid item xs={6} sm={6}>
                <TextValidator
                  label="Mobile Number"
                  onChange={handleChange}
                  name="mobile"
                  value={formData?.mobile || ''}
                  variant="outlined"
                  fullWidth
                  validators={[`matchRegexp:^[- +0-9]*$`]}
                  errorMessages={['Please enter number only']}
                />
              </Grid>

              {/*Home Number*/}
              <Grid item xs={6} sm={6}>
                <TextValidator
                  label="Home Number"
                  onChange={handleChange}
                  name="home"
                  value={formData?.home || ''}
                  variant="outlined"
                  fullWidth
                  validators={[`matchRegexp:^[- +0-9]*$`]}
                  errorMessages={['Please enter number only']}
                />
              </Grid>

              {/*Office Number*/}
              <Grid item xs={6} sm={6}>
                <TextValidator
                  label="Office Number"
                  onChange={handleChange}
                  name="office"
                  value={formData?.office || ''}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {/*Work Email*/}
              <Grid item xs={6} sm={6}>
                <TextValidator
                  label="Work Email"
                  onChange={handleChange}
                  name="workEmail"
                  value={formData?.workEmail || ''}
                  variant="outlined"
                  fullWidth
                  validators={
                    props.profileType === 'contact' ? ['isEmailUnique'] : []
                  }
                  errorMessages={
                    props.profileType === 'contact' ? ['Duplicate email'] : []
                  }
                />
              </Grid>

              {/*Personal Email*/}
              <Grid item xs={6} sm={6}>
                <TextValidator
                  label="Personal Email"
                  onChange={handleChange}
                  name="personalEmail"
                  value={formData?.personalEmail || ''}
                  variant="outlined"
                  fullWidth
                  validators={
                    props.profileType === 'candidate' ? ['isEmailUnique'] : []
                  }
                  errorMessages={
                    props.profileType === 'candidate' ? ['Duplicate email'] : []
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <DrawerFormFooter disableButton={disabledNextButton()}>
          <Box />
        </DrawerFormFooter>
      </ValidatorForm>
    </Box>
  );
};

export default ContactEditForm;
