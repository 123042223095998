/**
 * This component is to clone the Spec Job Templates
 * It opens the popup prepopulted with all the required data based on the template id
 * It allows to modify the template
 * It allow to modify the attachments for this template
 */
import React, { useState, useEffect, useCallback } from 'react';
import { Col, Modal, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { v4 as uuidv4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getJobSpecTemplate } from '../actions/template';
import api from '../utils/api';
import Fileupload from '../utils/Fileupload';
import MuiAutocomplete from '../controlled-component/MuiAutocomplete';
import TextEditor from '../utils/Editor';

/**
 * It is the main component for clone the template
 * @param {tempId, modalState, onHide, history, getRecords} props
 * @returns component
 */
const CloneTemplate = (props) => {
  /**
   * Initialize all the states
   * States for team and selected team, user and selected user
   * State for file uuid
   * States for template and form data
   */
  const [teamToggle, setTeamToggle] = React.useState(false);
  const [userToggle, setUserToggle] = React.useState(false);
  const [userSelected, setUserSelected] = React.useState([]);
  const [teamSelected, setTeamSelected] = React.useState([]);
  const [uid, setUid] = React.useState('');
  const [templateData, setTemplateData] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    template_type: '',
    subject: '',
    content: '',
    id: '',
  });

  /**
   * constant for file uuid
   */
  const uuid = uuidv4();

  /**
   * Validations
   * @todo validation message need to be from constants or lang file
   */
  const schema = yup.object().shape({
    name: yup.string().required('Template name is a required field'),
    subject: yup.string().required('Template subject is a required field'),
    content: yup.string().required('Content is a required field'),
    shared_with_users: userToggle
      ? yup.array().required('Users are a required field')
      : '',
    shared_with_teams: teamToggle
      ? yup.array().required('Teams are a required field')
      : '',
  });

  /**
   * Constants for form data
   * Apply validations
   */
  // const { name, template_type, subject, content, id } = formData;
  const {
    register,
    handleSubmit,
    control,
    errors,
    reset,
    setValue,
    formState: { isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(schema), defaultValues: formData });

  /**
   * Call API to post the cloned template
   * @param {*} values as the form data and others need to store
   */
  const onCloneTemplate = async (values) => {
    await api
      .post(`/automation/template`, {
        values,
      })
      .then((response) => {
        //console.log('API response-', response);
        //setFormData({ content: '' });
        closePopup();
        props.getJobSpecTemplate(null, props.admin ? props.admin : null);
        toast.success('Spec Job Template cloned', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch(() => {
        setTemplateData(values);
      });
  };

  /**
   * function to handle the accesstype drop down
   * @param {*} event
   */
  const handleAccessType = (event) => {
    let selected = event.target.value;
    if (selected == 1) {
      setUserToggle(true);
      setTeamToggle(false);
    } else if (selected == 2) {
      setUserToggle(false);
      setTeamToggle(true);
    } else {
      setUserToggle(false);
      setTeamToggle(false);
    }
  };

  /**
   * Users Data variabale to have the users options
   */
  const usersData = props.users.users ? props.users.users : null;
  var usersOptions = usersData.map(function (user) {
    return { value: user.company_user_id, label: user.name };
  });

  /**
   * Teams Data variable to have the teams options
   */
  const teamsData = props.users.teams ? props.users.teams : null;
  var teamsOptions = teamsData.map(function (team) {
    return { value: team.id, label: team.name };
  });

  /**
   * Reset the template data on form submit
   */
  useEffect(() => {
    if (isSubmitSuccessful) {
      //console.log(templateData);
      reset({ ...templateData });
    }
  }, [isSubmitSuccessful, templateData, reset]);

  /**
   * Function to the template data from API based on the template id
   */
  const getTemplateRecord = useCallback(() => {
    if (props.temp_id) {
      api.get(`/automation/template/${props.temp_id}`).then((response) => {
        // console.log(response.data);
        setFormData(response.data);
        setValue('name', response.data.name);
        setValue('subject', response.data.subject);
        setValue('content', response.data.content);
        setValue('access_type', response.data.access_type);
        if (response.data.shared_with_users != null) {
          // setUserSelected(response.data.shared_with_users);
          let sharedWith = response.data.shared_with_users.find(
            (element) => element.value === response.data.user.value,
          );
          if (sharedWith) {
            setUserSelected(response.data.shared_with_users);
          } else {
            setUserSelected([
              ...response.data.shared_with_users,
              ...[response.data.user],
            ]);
          }
        } else {
          setUserSelected([response.data.user]);
        }
        if (response.data.shared_with_teams != null) {
          setTeamSelected(response.data.shared_with_teams);
        } else {
          setTeamSelected([response.data.team]);
        }
        if (response.data.access_type == 1) {
          setUserToggle(true);
        } else if (response.data.access_type == 2) {
          setTeamToggle(true);
        }
      });
    }
  }, [props.temp_id]);

  /**
   * Call the API to get template record
   */
  useEffect(() => {
    getTemplateRecord();
    if (uid === '') {
      setUid(uuid);
    }
  }, [props.temp_id]);

  /**
   * Function to manage the close popup to reset states
   */
  const closePopup = () => {
    setUserSelected([]);
    setTeamSelected([]);
    setTeamToggle(false);
    setUserToggle(false);
    props.onHide();
    setUid('');
  };

  /**
   * Return the component JSX
   * Its is Model popup with form
   * Using the form controll, form group, MuiAutocomplete
   */
  return (
    <Modal
      show={props.modalState}
      onHide={() => closePopup()}
      dialogClassName="custom-modal"
      enforceFocus={false}
    >
      <Form onSubmit={handleSubmit(onCloneTemplate)}>
        <Modal.Header className="modalHeaderCls" closeButton={false}>
          <div>
            <Modal.Title className="modalTitle">
              Clone Spec Job Template
            </Modal.Title>
            <span className="closeModalIconCls" onClick={() => closePopup()}>
              <i className="fa fa-close"></i>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label className="floating-label">Template Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                className="custom_input"
                ref={register}
              ></Form.Control>
              {errors.name && (
                <label className="error">{errors.name?.message}</label>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="subject">
              <Form.Label className="floating-label">
                Template Subject
              </Form.Label>
              <Form.Control
                type="text"
                name="subject"
                className="custom_input"
                ref={register}
              ></Form.Control>
              {errors.subject && (
                <label className="error">{errors.subject?.message}</label>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="content">
              <Form.Label className="floating-label">Content</Form.Label>
              <Controller
                control={control}
                name="content"
                defaultValue=""
                render={({ onChange, value }) => (
                  <TextEditor onChange={onChange} initialvalue={value} />
                )}
              />
              {errors.content && (
                <label className="error">{errors.content?.message}</label>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Control
            type="hidden"
            name="template_type"
            id="template_type"
            value="3"
            className="custom_input"
            ref={register}
          />
          <Form.Control
            type="hidden"
            name="uuid"
            id="uuid"
            value={uid}
            className="custom_input"
            ref={register}
          />
          <Form.Row>
            <Form.Group as={Col} controlId="access_type">
              <Form.Label>Template Access</Form.Label>
              <Form.Control
                as="select"
                name="access_type"
                custom="true"
                ref={register}
                onChange={handleAccessType}
              >
                <option value="">Select</option>
                <option value="1">Private</option>
                <option value="2">Team</option>
                <option value="3">Organisation</option>
              </Form.Control>
              <label className="error">{errors.access_type?.message}</label>
            </Form.Group>
          </Form.Row>
          {userToggle ? (
            <Form.Row>
              <Form.Group as={Col} controlId="shared_with_users">
                <Form.Label>Select users</Form.Label>
                <MuiAutocomplete
                  control={control}
                  name="shared_with_users"
                  options={usersOptions}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  getOptionLabel={(option) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      margin="normal"
                      placeholder="Select users"
                    />
                  )}
                  defaultValue={userSelected}
                  multiple={true}
                />
                <label className="error">
                  {errors.shared_with_users?.message}
                </label>
              </Form.Group>
            </Form.Row>
          ) : null}
          {teamToggle ? (
            <Form.Row>
              <Form.Group as={Col} controlId="shared_with_teams">
                <Form.Label>Select teams</Form.Label>
                <MuiAutocomplete
                  control={control}
                  name="shared_with_teams"
                  options={teamsOptions}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  getOptionLabel={(option) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      margin="normal"
                      placeholder="Select teams"
                    />
                  )}
                  defaultValue={teamSelected}
                  multiple={true}
                />

                <label className="error">
                  {errors.shared_with_teams?.message}
                </label>
              </Form.Group>
            </Form.Row>
          ) : null}

          <Form.Row>
            <Form.Group as={Col} controlId="file">
              <Fileupload
                uuid={uid}
                object_id={props.temp_id}
                allowtype="ATTACHMENT"
                clone={true}
              />
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button varient="primary" className="pl-4 pr-4" type="submit">
            Clone
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CloneTemplate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
});

const mapDispatchToProps = {
  getJobSpecTemplate,
};

/**
 * Exporting the clone template component
 */
export default connect(mapStateToProps, mapDispatchToProps)(CloneTemplate);
