import React, { useState } from 'react';

import CustomDrawer from '../../../components/CustomDrawer';
import DrawerFormHeader from '../../../components/DrawerFormHeader';
import AddJobRoleForm from '../AddJobRoleForm';
import CustomSnackBar from '../../../components/CustomSnackBar';

/**
 * Component to open Entity drawer/slider popup
 * @param {*} props
 * @returns
 */
const AddJobRoleDrawer = (props) => {
  const { openDrawer, onClose, jobRoleId, isMounted } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState('');

  /**
   * Handle function to close the drawer popup
   * @param {*} data
   */
  const handleClose = (data) => {
    if (data && data?.jobRoleEdited) {
      setOpenSnackBar(true);
      setOpenSnackBarMessage('Job role updated added!');
    }
    if (data && data?.jobRoleAdded) {
      setOpenSnackBar(true);
      setOpenSnackBarMessage('New job role added!');
    }
    onClose();
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={jobRoleId ? 'Edit Job Role' : 'Create Job Role'}
        >
          <AddJobRoleForm
            openDrawer={openSnackBar}
            closeDrawer={handleClose}
            jobRoleEditId={jobRoleId}
          />
        </DrawerFormHeader>
      </CustomDrawer>

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        severity="success"
        barMessage={openSnackBarMessage || ``}
      />
    </div>
  );
};

export default AddJobRoleDrawer;
