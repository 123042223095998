import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Menu, MenuItem, Typography, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';

import PageHeader from '../../components/PageHeader';
import CustomDataGrid from '../../controlled-component/customGridComponent';
import AddButton from '../../components/CreateToolbarButton';
import LoadingTableBody from '../../components/LoadingTableBody';
// import CreateTemplate from '../CreateTemplate';
// import EditTemplate from '../EditTemplate';
import CreateTemplate from '../CreateTemplate/CreateTemplate';
import { ActiveIcon, InActiveIcon } from '../../components/GridIcons';

import { getUserTemplate, updateTemplateStatus } from '../../actions/template';

import 'react-toastify/dist/ReactToastify.css';

// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  gridWrapper: {
    padding: '10px 0 20px 0',
  },
}));

const TemplateGrid = (props) => {
  const classes = useStyles();

  const [popupToggle, setPopupToggle] = useState(false);
  const [templateId, setTemplateId] = useState();

  const [clonePopupToggle, setClonePopupToggle] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [editPopupToggle, setEditPopupToggle] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const template = useSelector((state) => state.template);

  // handle function to open add template popup
  const handlePopupOpen = () => {
    setTemplateId(false);
    setPopupToggle(!popupToggle);
  };

  // handle function to close add template popup
  const handlePopupClose = () => {
    setPopupToggle(false);
    setTemplateId(false);
    setEditPopupToggle(false);
    setClonePopupToggle(false);
  };

  // handle function to close edit template popup
  const handleEditPopupClose = () => {
    setEditPopupToggle(false);
    setTemplateId(false);
  };

  // handle the edit template pop up
  const handleEditPopupOpen = (id, templateRow) => {
    /* setEditPopupToggle(!editPopupToggle); */
    // setPopupToggle(!popupToggle);
    setTemplateId(id);
    setEditPopupToggle(!editPopupToggle);
  };

  // handle the clone template pop up
  const handleClonePopupOpen = (id, templateRow) => {
    setTemplateId(id);
    setClonePopupToggle(!clonePopupToggle);
  };

  // update status with reducer
  const changeStatus = (newTemplateId, statusText) => {
    const status = statusText === 'Un-Published' ? 1 : 2;

    props.updateTemplateStatus(
      newTemplateId,
      status,
      'grid',
      props.admin ? props.admin : null,
    );
  };

  // make copy and share of the selected template
  const handleShareTemplate = (newTemplateId, templateRow) => {
    // TODO: check it
    // setTemplateId(newTemplateId);
    // setClonePopupToggle(!clonePopupToggle);
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };
  /**
   * Handle function to close the toaster popup
   */
  const showSnackbar = () => {
    setOpenSnackBar(true);
  };

  useEffect(() => {
    // did mount
    // call the template api and set data in reducer
    dispatch(
      getUserTemplate(undefined, undefined, props.admin ? props.admin : null),
    );
  }, []);

  /* const handleNameClick = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    handleEditPopupOpen(id);
  }; */

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Template Name',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 300, width: 300, maxWidth: 300 },
          }),
          setCellProps: () => ({
            style: { minWidth: 300, width: 300, maxWidth: 300 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = template?.templates[dataIndex];
            return (
              <Tooltip title={rData.name || ''} arrow>
                <div className={'td-content-ellipsis'}>
                  <Link
                    to={`/email/detail/${rData.id}`}
                    className="text-truncate td-anchor-content-ellipsis"
                  >
                    {rData.name}
                  </Link>{' '}
                  ({rData.id})
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Subject',
        name: 'subject',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRender: (value) => {
            return (
              <Tooltip title={value} arrow>
                <div className={`td-content-ellipsis`}>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Delivered',
        name: 'total_delivered',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Opened',
        name: 'total_opened',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Clicked',
        name: 'total_clicked',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Bounces',
        name: 'total_bounces',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: 'Owner',
        name: 'created_by_user',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      {
        label: 'Status',
        name: 'is_published',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return value === 'Un-Published' ? (
              <InActiveIcon fontSize="small" />
            ) : (
              <ActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = template?.templates.find((tData) => tData.id === value);
            return (
              <GridActions
                temp_id={row?.id}
                status={row?.status}
                row={row}
                handleEditPopupOpen={handleEditPopupOpen}
                handleClonePopupOpen={handleClonePopupOpen}
                updateStatus={changeStatus}
                shareTemplate={handleShareTemplate}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [template.templates],
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={template.loading} {...props} />,
    [template.loading],
  );

  return (
    <div className={classes.templateGridMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Email Templates'}
        subTitle={
          'Set up and manage templates for all users, to help increase their efficiency.'
        }
        itemCount={template?.templates?.length || 0}
      />
      <div className={classes.gridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns}
          data={template?.templates}
          options={{
            pagination: Boolean(template?.templates?.length),
            selectableRows: 'multiple',
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search Templates',
            selectToolbarPlacement: 'none',
            searchOpen: true,
            search: true,
            customToolbar: () => {
              // Toolbar for search and add
              return (
                <AddButton
                  handleClick={handlePopupOpen}
                  title={`Create Template`}
                  dataTarget={`createTemplate`}
                />
              );
            },
          }}
          components={{ TableBody: BodyComponent }}
        />
        <ToastContainer autoClose={2000} />
      </div>

      {/*<CreateTemplate*/}
      {/*  modalState={popupToggle}*/}
      {/*  onHide={handlePopupClose}*/}
      {/*  history={props.history}*/}
      {/*  admin={props.admin ? props.admin : false}*/}
      {/*  page={'grid'}*/}
      {/*  getRecords={props.getUserTemplate}*/}
      {/*/>*/}
      <CreateTemplate
        openDrawer={popupToggle || editPopupToggle || clonePopupToggle}
        onClose={handlePopupClose}
        admin={props.admin ? props.admin : false}
        page={'grid'}
        getRecords={props.getUserTemplate}
        templateId={templateId}
        cloneTemplate={clonePopupToggle}
      />

      {/*<EditTemplate*/}
      {/*  temp_id={templateId}*/}
      {/*  modalState={editPopupToggle}*/}
      {/*  onHide={handleEditPopupClose}*/}
      {/*  history={props.history}*/}
      {/*  getRecords={props.getUserTemplate}*/}
      {/*  admin={props.admin ? props.admin : false}*/}
      {/*/>*/}
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const { auth, row } = props;
  const { user } = auth;
  const admin = props.admin ? props.admin : false;
  const [anchorElement, setAnchorElement] = useState(null);

  const editable = user.id === row.created_by;

  const menuItem =
    editable || admin
      ? [
          {
            title: 'Edit',
            itemType: 'editPopUp',
          },
          {
            title: 'Change Status',
            itemType: 'changeStatus',
          },
          {
            title: 'Clone',
            itemType: 'cloneTemplate',
          },
          // {
          //   title: 'Share',
          //   itemType: 'shareTemplate',
          // },
        ]
      : [];

  //const editable = user.id === row.created_by;
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id, props.row);
        break;
      }
      case 'changeStatus': {
        props.updateStatus(props.temp_id, props.row.is_published);
        break;
      }
      case 'shareTemplate': {
        props.shareTemplate(props.temp_id, props.row);
        break;
      }
      case 'cloneTemplate': {
        props.handleClonePopupOpen(props.temp_id, props.row);
        break;
      }
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getUserTemplate,
  updateTemplateStatus,
};

export default connect(null, mapDispatchToProps)(TemplateGrid);
