import React, { useState } from 'react';
import Accordion from './Accordion';
import FilterAutocomplete from './FilterAutocomplete';

const Filters = ({
  index,
  filters,
  filterdata,
  handleSelection,
  selected,
  ...providedProps
}) => {
  let key = 0;
  //console.log(filters);
  const filtersContent = filters.map((field) => {
    key++;
    let { value, label } = field;
    let fitersData = filterdata[value];
    let fieldSelected =
      Object.keys(selected).length != 0 && [value] in selected
        ? selected[value]
        : [];
    let selectedOptions =
      Object.keys(fieldSelected).length != 0
        ? fieldSelected.map((val) => {
            let opt = val.split('_');
            let optionText = opt.length > 1 ? opt[1] : opt[0];
            return { value: val, label: optionText };
          })
        : [];

    return (
      <Accordion
        key={key}
        expand={value}
        headings={<>{label}</>}
        selected={fieldSelected}
        content={
          <>
            <FilterAutocomplete
              index={key}
              field={value}
              label={label}
              optionsData={fitersData}
              selected={selectedOptions}
              handleSelection={handleSelection}
            />
          </>
        }
      />
    );
  });

  return <>{filtersContent}</>;
};

export default Filters;
