import React from 'react';
/* import TextField from '@material-ui/core/TextField'; */
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';

const MuiAutocomplete = ({
  options = [],
  renderInput,
  getOptionLabel,
  onChanged: ignored,
  control,
  defaultValue,
  name,
  renderOption,
  multiple,
  getOptionSelected,
  handleChange = null,
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          options={options}
          getOptionSelected={getOptionSelected}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          multiple={multiple}
          onChange={(e, data) => {
            onChange(data);
            if (handleChange != null) {
              handleChange(name, data);
            }
          }}
          fullWidth={true}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};

export default MuiAutocomplete;

/* export default function CountrySelect({ onChange: ignored, control }) {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          options={countries}
          getOptionLabel={option => option.label}
          renderOption={option => (
            <span>
              {countryToFlag(option.code)}
              {option.label}
            </span>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label="Choose a country"
              variant="outlined"
            />
          )}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={{ code: "AF", label: "Afghanistan", phone: "93" }}
      name="country"
      control={control}
    />
  );
  // Version < 6.0.0
  //   <Controller
  //     as={
  //       <Autocomplete
  //         options={countries}
  //         getOptionLabel={option => option.label}
  //         renderOption={option => (
  //           <span>
  //             {countryToFlag(option.code)}
  //             {option.label}
  //           </span>
  //         )}
  //         renderInput={params => (
  //           <TextField
  //             {...params}
  //             label="Choose a country"
  //             variant="outlined"
  //           />
  //         )}
  //       />
  //     }
  //     onChange={([, data]) => data}
  //     name="country"
  //     control={control}
  //     defaultValue={{ code: "AF", label: "Afghanistan", phone: "93" }}
  //   />
  // );
}

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
} */
