export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const USERS_TEAMS_LOADED = "USERS_TEAMS_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const USERS_ERROR = "USERS_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_ERROR_CLEAR = "LOGIN_ERROR_CLEAR";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_SEQUENCES = "GET_SEQUENCES";
export const GET_SEQUENCE_REPORTS = "GET_SEQUENCE_REPORTS";
export const STEPS_REPORTS = "STEPS_REPORTS";
export const SEQUENCE_RECIPIENTS = "SEQUENCE_RECIPIENTS";
export const GET_SEQUENCE = "GET_SEQUENCE";
export const SEQUENCE_DETAIL = "SEQUENCE_DETAIL";
export const CREATE_SEQUENCE = "CREATE_SEQUENCE";
export const CLONE_SEQUENCE = "CLONE_SEQUENCE";
export const CREATE_STEPS = "CREATE_STEPS";
export const UPDATE_STEPS = "UPDATE_STEPS";
export const STEP_TEMPLATES = "STEP_TEMPLATES";
export const UPDATE_STEP_TEMPLATE = "UPDATE_STEP_TEMPLATE";
export const DELETE_STEPS = "DELETE_STEPS";
export const UPDATE_SEQUENCE = "UPDATE_SEQUENCE";
export const SEQUENCE_ERROR = "SEQUENCE_ERROR";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const GET_TEMPLATE_STATS = "GET_TEMPLATE_STATS";
export const GET_TEMPLATE_RECIPIENTS = "GET_TEMPLATE_RECIPIENTS";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_CVSPEC_TEMPLATELIST = "GET_CVSPEC_TEMPLATELIST";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const TEMPLATE_ERROR = "TEMPLATE_ERROR";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_TASKS = "GET_TASKS";
export const GET_TASK = "GET_TASK";
export const ADD_TASK = "ADD_TASK";
export const ADD_COLUMN = "ADD_COLUMN";
export const UPDATE_TASK_ORDER = "UPDATE_TASK_ORDER";
export const UPDATE_COLUMN_ORDER = "UPDATE_COLUMN_ORDER";
export const DELETE_TASK = "DELETE_TASK";
export const DELETE_COLUMN = "DELETE_COLUMN";
export const TASK_ERROR = "TASK_ERROR";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const UPDATE_STATE = "UPDATE_STATE";
export const LOAD_LEADS = "LOAD_LEADS";
export const GET_LEADS = "GET_LEADS";
export const GET_TABS = "GET_TABS";
export const ADD_TAB = "ADD_TAB";
export const TAB_ERROR = "TAB_ERROR";
export const LEAD_ERROR = "LEAD_ERROR";
export const GET_USERS = "GET_USERS";
export const GET_DROPDOWN_LIST = "GET_DROPDOWN_LIST";
export const CREATE_ADD_USER = "CREATE_ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_TAB = "UPDATE_TAB";
export const LOAD_TEAMS = "LOAD_TEAMS";
export const TEAM_ERROR = "TEAM_ERROR";
export const ADD_TEAM = "ADD_TEAM";
export const CHANGE_TEAM_STATUS = "CHANGE_TEAM_STATUS";
export const LOAD_ENTITIES = "LOAD_ENTITIES";
export const ENTITIES_ERROR = "ENTITIES_ERROR";
export const ADD_ENTITY = "ADD_ENTITY";
export const CHANGE_ENTITY_STATUS = "CHANGE_ENTITY_STATUS";
export const LOAD_ENTITIES_DROPDOWNS = "LOAD_ENTITIES_DROPDOWNS";

// application sidebar
export const SET_SIDE_BAR = "SET_SIDE_BAR";
export const SET_PROFILE_ALERT = "SET_PROFILE_ALERT";
export const SET_APP_CURRENT_HEADER_PAGE = "SET_APP_CURRENT_HEADER_PAGE";

// Admin offices
export const LOAD_ADMIN_OFFICES = "LOAD_ADMIN_OFFICES";
export const OFFICE_ADMIN_ERROR = "OFFICE_ADMIN_ERROR";
export const ADD_ADMIN_OFFICE = "ADD_ADMIN_OFFICE";
export const LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES =
  "LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES";
export const LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES_ERROR =
  "LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES_ERROR";
//SKILLS
export const LOAD_SKILLS = "LOAD_SKILLS";
export const SKILLS_ERROR = "SKILLS_ERROR";
export const ADD_SKILL = "ADD_SKILL";
export const REMOVE_SKILL = "REMOVE_SKILL";
export const CHANGE_SKILL_STATUS = "CHANGE_SKILL_STATUS";
//Categories
export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const CHANGE_CATEGORY_STATUS = "CHANGE_CATEGORY_STATUS";
//Industries
export const LOAD_INDUSTRIES = "LOAD_INDUSTRIES";
export const INDUSTRIES_ERROR = "INDUSTRIES_ERROR";
export const ADD_INDUSTRY = "ADD_INDUSTRY";
export const REMOVE_INDUSTRY = "REMOVE_INDUSTRY";
export const CHANGE_INDUSTRY_STATUS = "CHANGE_INDUSTRY_STATUS";

// Job Roles offices
export const LOAD_ADMIN_JOB_ROLES = "LOAD_ADMIN_JOB_ROLES";
export const JOB_ROLES_ERROR = "JOB_ROLES_ERROR";
export const ADD_JOB_ROLES = "ADD_JOB_ROLES";
export const LOAD_ADMIN_OFFICES_CATEGORY = "LOAD_ADMIN_OFFICES_CATEGORY";

// Integration
export const LOADING_ADMIN_INTEGRATION = "LOADING_ADMIN_INTEGRATION";
export const LOAD_ADMIN_INTEGRATION = "LOAD_ADMIN_INTEGRATION";
export const SAVE_ADMIN_INTEGRATION = "SAVE_ADMIN_INTEGRATION";
export const ADMIN_INTEGRATION_ERROR = "ADMIN_INTEGRATION_ERROR";
export const LOADING_ADMIN_INTEGRATION_DETAIL =
  "LOADING_ADMIN_INTEGRATION_DETAIL";
export const LOAD_ADMIN_INTEGRATION_DETAIL = "LOAD_ADMIN_INTEGRATION_DETAIL";
export const ADMIN_INTEGRATION_DETAIL_ERROR = "ADMIN_INTEGRATION_DETAIL_ERROR";
export const LOAD_ADMIN_INTEGRATION_DETAIL_TABS = 'LOAD_ADMIN_INTEGRATION_DETAIL_TABS';

// Profile Integration
export const LOAD_PROFILE_INTEGRATION = 'LOAD_PROFILE_INTEGRATION';
export const PROFILE_INTEGRATION_ERROR = 'PROFILE_INTEGRATION_ERROR';
export const LOADING_PROFILE_INTEGRATION = 'LOADING_PROFILE_INTEGRATION';

// Facebook
export const GET_ADS_ACCOUNT = "GET_ADS_ACCOUNT";
export const ADS_ACCOUNT_ERROR = "ADS_ACCOUNT_ERROR";
export const GET_AUDIENCES = "GET_AUDIENCES";
export const AUDIENCES_ERROR = "AUDIENCES_ERROR";
export const CREATE_STEPS_ADD_FACEBOOK = "CREATE_STEPS_ADD_FACEBOOK";
export const CREATE_STEPS_UPDATE_FACEBOOK = "CREATE_STEPS_UPDATE_FACEBOOK";

//Twilio
export const GET_TWILIO_NUMBERS = "GET_TWILIO_NUMBERS";

// CV Spec Templates
export const GET_CVSPEC_TEMPLATES = "GET_CVSPEC_TEMPLATES";
export const GET_MASTER_CVSPEC_TEMPLATES_LIST =
  "GET_MASTER_CVSPEC_TEMPLATES_LIST";
export const GET_CANDIDATES_LOOKUP = "GET_CANDIDATES_LOOKUP";
export const CANDIDATE_ERROR = "CANDIDATE_ERROR";
export const REMOVE_CVSPEC_TEMPLATES = "REMOVE_CVSPEC_TEMPLATES";

// My task
export const LOAD_MY_TASK = "LOAD_MY_TASK";
export const MY_TASK_ERROR = "MY_TASK_ERROR";
export const SHOW_MY_TASK_LOADER = "SHOW_MY_TASK_LOADER";
export const SET_MY_TASK_FILTER_DATA = "SET_MY_TASK_FILTER_DATA";
export const LOAD_TASK_TABS = "LOAD_TASK_TABS";
export const LOAD_TASK_TABS_LOADING = "LOAD_TASK_TABS_LOADING";
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const SET_TASK_CURRENT_TAB = "SET_TASK_CURRENT_TAB";

// Sequenced Candidates
export const LOAD_SEQUENCED_CANDIDATES = "LOAD_SEQUENCED_CANDIDATES";
export const SEQUENCED_CANDIDATES_ERROR = "SEQUENCED_CANDIDATES_ERROR";
export const SHOW_LOAD_SEQUENCED_CANDIDATES_LOADER =
  "SHOW_LOAD_SEQUENCED_CANDIDATES_LOADER";
export const SET_SEQUENCED_CANDIDATES_FILTER_DATA =
  "SET_SEQUENCED_CANDIDATES_FILTER_DATA";
export const LOAD_SEQUENCED_CANDIDATES_TABS = "LOAD_SEQUENCED_CANDIDATES_TABS";
export const LOAD_SEQUENCED_CANDIDATES_TABS_LOADING =
  "LOAD_SEQUENCED_CANDIDATES_TABS_LOADING";
export const UPDATE_SEQUENCED_CANDIDATES_STATUS =
  "UPDATE_SEQUENCED_CANDIDATES_STATUS";
export const SET_SEQUENCED_CANDIDATES_CURRENT_TAB =
  "SET_SEQUENCED_CANDIDATES_CURRENT_TAB";

// Activity Reports
export const LOAD_ACTIVITY_REPORT = "LOAD_ACTIVITY_REPORT";
export const ACTIVITY_REPORT_ERROR = "ACTIVITY_REPORT_ERROR";
export const SHOW_LOAD_ACTIVITY_REPORT_LOADER =
  "SHOW_LOAD_ACTIVITY_REPORT_LOADER";
export const SET_ACTIVITY_REPORT_FILTER_DATA =
  "SET_ACTIVITY_REPORT_FILTER_DATA";
export const LOAD_ACTIVITY_REPORT_TABS = "LOAD_ACTIVITY_REPORT_TABS";
export const LOAD_ACTIVITY_REPORT_LOADING = "LOAD_ACTIVITY_REPORT_LOADING";
export const SET_ACTIVITY_REPORT_CURRENT_TAB =
  "SET_ACTIVITY_REPORT_CURRENT_TAB";

//user grid
export const LOAD_USER_LIST_TABS = "LOAD_USER_LIST_TABS";
export const USERS_LIST_ERROR = "USERS_LIST_ERROR";
export const LOAD_USER_LIST = "LOAD_USER_LIST";
export const SET_USER_LIST_FILTER_DATA = "SET_USER_LIST_FILTER_DATA";
export const SHOW_LOAD_USER_LIST_LOADER = "SHOW_LOAD_USER_LIST_LOADER";
export const SET_USER_LIST_CURRENT_TAB = "SET_USER_LIST_CURRENT_TAB";
export const LOAD_USER_LIST_LOADING = "LOAD_USER_LIST_LOADING";

// Sequence new options
export const LOAD_SEQUENCE_TABS = "LOAD_SEQUENCE_TABS";
export const LOAD_SEQUENCE_GRID_DATA = "LOAD_SEQUENCE_GRID_DATA";
export const SHOW_TAB_GRID_DATA_LOADER = "SHOW_TAB_GRID_DATA_LOADER";
export const SET_SEQUENCED_DEFAULT_FILTER_DATA =
  "SET_SEQUENCED_DEFAULT_FILTER_DATA";
export const RELOAD_SEQUENCE_DATA = "RELOAD_SEQUENCE_DATA";

//template options
export const LOAD_TEMPLATE_DATA = "LOAD_TEMPLATE_DATA";
export const LOAD_TEMPLATE_ERROR = "LOAD_TEMPLATE_ERROR";
export const SHOW_LOAD_TEMPLATE_LOADER = "SHOW_LOAD_TEMPLATE_LOADER";
export const SET_TEMPLATE_FILTER_DATA = "SET_TEMPLATE_FILTER_DATA";
export const LOAD_TEMPLATE_TABS = "LOAD_TEMPLATE_TABS";
export const LOAD_FILTER_TEMPLATE_DATA = "LOAD_FILTER_TEMPLATE_DATA";

//replacement variables from api
export const REPLACEMENT_VARIABLES = "REPLACEMENT_VARIABLES";
