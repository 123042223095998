import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';

import Button from '../../../components/Button';

const menuItem = [
  {
    title: 'Edit',
    itemType: 'editPopUp',
  },
  // {
  //   title: 'Share',
  //   itemType: 'shareTemplate',
  // },
];

const ActionButtonMenu = (props) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const menuId = 'action-menu-id';

  const {
    buttonClassName = '',
    handleEditPopupOpen = () => {},
    updateStatus,
  } = props;

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        handleEditPopupOpen();
        break;
      }
      case 'changeStatus': {
        updateStatus();
        break;
      }
      case 'shareTemplate': {
        // props.shareTemplate(props.temp_id, props.row);
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Button
        className={buttonClassName || ''}
        variant="contained"
        onClick={handleActionsMenuOpen}
      >
        Actions
      </Button>

      <Menu
        anchorEl={anchorElement}
        id={menuId}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ActionButtonMenu;
