import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Select,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  InputBase,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import CustomDataGrid from '../../../controlled-component/customGridComponent';
import LoadingTableBody from '../../../components/LoadingTableBody';
import DateRangePicker from '../../../components/DateTimePickers/DateRangePicker';
import FormAutoCompleteSelect, {
  AutoCompletePopper,
} from '../../../components/FormAutoCompleteSelect';

import { getRecipientReport } from '../../../actions/sequence';
import { dateFormat } from '../../../utils/applicationConstant';

const useStyles = makeStyles(({ custom }) => ({
  mainPageWrapper: {},
  selectContainerWrapper: {
    display: 'flex',
    width: '100%',
    marginBottom: 20,
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
    '& .formControl-label': {
      fontSize: 14,
      color: custom.colorCode.blackShade1,
      marginRight: 20,
      marginBottom: 3,
    },
  },
  selectEmpty: {},
  selectEmptyRoot: {
    color: custom.colorCode.fontBlack,
    fontWeight: 700,
    fontSize: 14,
  },
  selectEmptyIcon: {
    color: custom.colorCode.fontBlack,
  },
  summaryDatePicker: {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: 120,
    '& .MuiIconButton-root': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  gridWrapper: {
    padding: '0 0 20px 0',
  },
  actionButton: {
    height: 30,
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    '& span': {
      fontSize: 15,
    },
    ':hover': {
      backgroundColor: custom.colorCode.blueLightShade11,
    },
    ':focus': {
      backgroundColor: custom.colorCode.blueLightShade11,
    },
  },
  popoverStyle: {
    left: '-50px !important',
  },
  enrolledByAutoComplete: {
    '& .MuiSvgIcon-root': {
      color: custom.colorCode.fontBlack,
    },
    '& .MuiAutocomplete-input': {
      minWidth: '50px',
      maxWidth: 'auto',
      fontSize: 14,
      fontWeight: 700,
    },
  },
  buttonSelectContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Recipients = (props) => {
  const { users, sequence_id, dateFilterOptions } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const sequence = useSelector((state) => state.sequence);

  const [isLoading, setIsLoading] = useState(true);
  const [datePickerLabel, setDatePickerLabel] = useState(false);
  const [formData, setFormData] = useState({
    enrolledBy: '',
    enrolledDate: '',
  });
  const [showDatePopOver, setShowDatePopOver] = useState(null);

  /**
   * Handle function of onchange filters
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    if (event.target.value != 10) {
      handleFilterChange({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  // handle custom date change
  const handleDateChange = (name) => (date) => {
    setFormData({
      ...formData,
      [name]: date,
    });
    //handleFilterChange();
  };

  // handle onclick function of go button for custom date filter
  const handleDateFilterClick = () => {
    handleFilterChange(formData);
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name) => (event, tag) => {
    const newFormData = {
      ...formData,
      [name]: tag.id === 'all' ? '' : tag.id,
    };
    setFormData(newFormData);

    handleFilterChange(newFormData);
  };

  /**
   * Handle function for filter change
   * It will call the api to change sequence recipients
   */
  const handleFilterChange = async (params) => {
    setIsLoading(true);
    await dispatch(getRecipientReport(sequence_id, params));
    setIsLoading(false);
  };

  const getSequenceRecipientData = async () => {
    setIsLoading(true);
    await dispatch(getRecipientReport(sequence_id));
    setIsLoading(false);
  };

  // did mount to call api one for one time
  useEffect(() => {
    getSequenceRecipientData();
  }, [sequence_id]);


  const openDatePicker = (event) => {
    setShowDatePopOver(event.currentTarget);
  };

  const handleDatePickerClose = () => {
    setShowDatePopOver(null);
  };

  const handleDatePickerChange = (dateData) => {
    setFormData({
      ...formData,
      enrolledDate: 10,
      startDate: dateData.startDate,
      endDate: dateData.endDate,
    });
    setDatePickerLabel(dateData?.label || '');
    handleFilterChange({
      ...formData,
      enrolledDate: 10,
      startDate: dateData.startDate,
      endDate: dateData.endDate,
    });
  };

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'recipient_name',
        label: 'Name',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = sequence.recipients[dataIndex];
            return (
              <Tooltip title={rData.recipient_name || ''} arrow>
                <div className={`sort-by`}>{rData.recipient_name}</div>
                {/*<div className={'td-content-ellipsis'}>*/}
                {/*  <Link*/}
                {/*    to={`/recipient-profile/${rData.contact_id}`}*/}
                {/*    className="text-truncate td-anchor-content-ellipsis"*/}
                {/*  >*/}
                {/*    {rData.recipient_name}*/}
                {/*  </Link>*/}
                {/*</div>*/}
              </Tooltip>
            );
          },
        },
      },
      {
        label: 'Consultant Added',
        name: 'consultant_name',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      {
        label: 'Date',
        name: 'created_at',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 120, width: 120, maxWidth: 120 },
          }),
          setCellProps: () => ({
            style: { minWidth: 120, width: 120, maxWidth: 120 },
          }),
          customBodyRender: (value) => {
            return (
              <div>
                <Moment format="Do MMM YYYY">{value}</Moment>
              </div>
            );
          },
        },
      },
      {
        label: 'Steps Completed',
        name: 'steps_completed',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Opened',
        name: 'total_opened',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Clicked',
        name: 'total_clicked',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: 'Current Status',
        name: 'status_text',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      {
        label: 'Status Reason',
        name: 'status_reason_text',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div>{value}</div>;
          },
        },
      },
      /* {
        name: 'id',
        label: ' ',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = sequence.recipients.find((tData) => tData.id === value);
            // const row = {};
            return (
              <GridActions
                temp_id={row?.id}
                handleEditPopupOpen={handleEditPopupOpen}
                status={row?.status}
                row={row}
                updatestatus={changeStatus}
                {...props}
              />
            );
          },
        },
      }, */
    ],
    [sequence.recipients],
  );

  // update status with reducer
  const changeStatus = (teamId, status) => {
    //const status = statusText === 'Un-Published' ? 1 : 2;
  };

  // handle the edit recipient pop up
  const handleEditPopupOpen = (id) => {
    /* setEditPopupToggle(!editPopupToggle); */
  };

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={isLoading} {...props} />,
    [isLoading],
  );

  return (
    <div className={classes.gridWrapper}>
      <div className={classes.selectContainerWrapper}>
        <div className={classes.selectContainer}>
          <span className="formControl-label"> Enrolled By: </span>
          <FormControl className={classes.formControl}>
            <FormAutoCompleteSelect
              className={classes.enrolledByAutoComplete}
              onChange={handleSelectChange('enrolledBy')}
              name="enrolledBy"
              id="enrolledBy"
              PopperComponent={(props) =>
                AutoCompletePopper({
                  ...props,
                  className: `${props.className} ${classes.popoverStyle}`,
                })
              }
              value={
                formData.enrolledBy
                  ? [{ name: 'All', id: '' }, ...users]?.find(
                      (c) => c.id === formData?.enrolledBy,
                    ) || ''
                  : { name: 'All', id: '' }
              }
              disableClearable
              options={[{ name: 'All', id: '' }, ...users] || []}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <InputBase
                    {...params.InputProps}
                    {...rest}
                    style={{ width: 'fit-content' }}
                  />
                );
              }}
            />
          </FormControl>
        </div>

        <div className={classes.selectContainer}>
          <span className="formControl-label"> Enrolled Date: </span>
          <Typography
            onClick={openDatePicker}
            variant="body2"
            component={'div'}
            className={classes.selectDatePicketText}
          >
            {formData?.startDate && formData?.endDate
              ? datePickerLabel
                ? datePickerLabel
                : `${moment(formData?.startDate).format(
                    dateFormat,
                  )} to ${moment(formData?.endDate).format(dateFormat)}`
              : 'Select Date'}
            <ArrowDropDownIcon fontSize="small" />
          </Typography>
          <DateRangePicker
            onClose={handleDatePickerClose}
            open={Boolean(showDatePopOver)}
            anchorEl={showDatePopOver}
            dateRange={{
              startDate: formData?.startDate,
              endDate: formData?.endDate,
            }}
            onDatePickerChange={handleDatePickerChange}
          />
        </div>
      </div>

      <CustomDataGrid
        columns={columns}
        data={sequence?.recipients}
        paperWidth={'287px'}
        hideToolBar={true}
        options={{
          pagination: Boolean(sequence?.recipients?.length),
          selectableRows: 'multiple',
          filterType: 'dropdown',
          responsive: 'simple',
          searchPlaceholder: 'Search Recipients',
          selectToolbarPlacement: 'none',
          searchOpen: false,
          search: false,
        }}
        components={{ TableBody: BodyComponent }}
      />
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const [anchorElement, setAnchorElement] = useState(null);

  //const editable = user.id === row.created_by;
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case 'editPopUp': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      case 'changeStatus': {
        props.updatestatus(props.temp_id, props.status);
        break;
      }
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: 'Edit',
      itemType: 'editPopUp',
    },
    {
      title: 'Change Status',
      itemType: 'changeStatus',
    },
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default Recipients;
