import React, { useEffect, useState } from 'react';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import Checkbox from './Checkbox';
//import boardDetails from './../data/data.json';
import { getUserNotifications } from '../../actions/notification';
import { createTasks } from '../../actions/task';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const NotificationSideBar = (props) => {
  const [toggleAssignCard, setToggleAssignCard] = useState(false);
  const [getBoardData, setBoardData] = useState([]);
  //const [togglePopup, setTogglePopup] = useState(undefined);
  const [obj, setObj] = useState(null);
  const [index, setIndex] = useState(null);
  const [domain, setDomain] = useState(null);
  const [notificationData, setNotificationData] = useState([]);

  const toggleAssign = () => {
    setToggleAssignCard(true);
  };

  const toggleAssignClose = () => {
    // setBoardData([]);
    // setObj(null);
    // setIndex(null);
    document.body.click();
  };

  const getCheckedData = (id, name, details) => {
    //console.log("satyam", id, name, details)
    setObj(details);
    setIndex(id);
  };

  const assignAction = (data) => {
    //console.log("laneassign", data)
    //let cardLength = obj.cards.length;
    //let idCreate = obj.id + cardLength + Number(1);

    //data.id = idCreate;
    data.title = titleData(data);
    data.column_id = obj.column_id;
    data.notification_id = data.id;
    // let objData = data;
    // objData.id = objData['id'] + 1;
    // console.log("newobj", objData);

    //console.log("modified", data);
    props.createTasks(data);
    //setObj(obj.cards.push(data));
    //props.updatedAssignCard(obj, index);
    //setBoardData([]);
    setObj(null);
    setIndex(null);
    //setTogglePopup(false);

    document.body.click();

    setNotificationData(
      notificationData.filter((notification, userId) => {
        return notification.id !== data.id;
      }),
    );
  };

  /* const backToDefault = () => {
    setTogglePopup(undefined);
  }; */

  useEffect(() => {
    setBoardData(props.newBoardData);
  }, [props]);

  useEffect(() => {
    props.getUserNotifications();
  }, [props.getUserNotifications]);

  useEffect(() => {
    //setBoardData(boardDetails.lanes);
    //console.log("Notifications:", props.notification.notifications.length);
    //if(props.notification.notifications.length){
    //console.log("Notifications:", props.notification);
    setDomain(props.notification.domain);
    setNotificationData(props.notification.notifications);
    //}
  }, [props.notification.notifications]);

  //console.log("domain:", domain);
  //const notinfications = props.notification ? props.notification.notifications : [];
  //setNotificationData(notinfications);
  const truncate = (str) => {
    return str.length > 150 ? str.substring(0, 150) + '...' : str;
  };

  /* const escapeRegExp = (stringToGoIntoTheRegex) => {
    return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  const titleText = (data) => {
    let linkurls =
      data.contact_type == 1
        ? domain + `/edmgr/dm/detail/dmId/${btoa(data.contact_id)}`
        : domain +
          `/edmgr/candidate/candidate-profile/candidate_id/${btoa(
            data.contact_id,
          )}`;
    let linkName = `<a target='_blank' href='${linkurls}'>${data.contact_name}</a>`;
    let recordLink = data.title.replace(data.contact_name, linkName);

    return { __html: recordLink };
  }; */

  const titleData = (data) => {
    let linkurls =
      data.contact_type == 1
        ? domain + `/edmgr/dm/detail/dmId/${btoa(data.contact_id)}`
        : domain +
          `/edmgr/candidate/candidate-profile/candidate_id/${btoa(
            data.contact_id,
          )}`;
    let linkName = `<a target='_blank' href='${linkurls}'>${data.contact_name}</a>`;
    let recordLink = data.title.replace(data.contact_name, linkName);

    return recordLink;
  };

  /* const moo = () => {
    var parts = 'I am a cow; cows say moo. MOOOOO.'.split(/(\bmoo+\b)/gi);
    for (var i = 1; i < parts.length; i += 2) {
      parts[i] = (
        <a href="https://world.con" key={i}>
          {parts[i]}
        </a>
      );
    }
    console.log(parts);
    return <span>{parts}</span>;
  };

  const createMarkup = (val) => {
    return { __html: val };
  }; */

  return (
    <div className="notificationMainCls">
      {notificationData.map((data, id) => {
        let linkurls =
          data.contact_type == 1
            ? domain + `/edmgr/dm/detail/dmId/${btoa(data.contact_id)}`
            : domain +
              `/edmgr/candidate/candidate-profile/candidate_id/${btoa(
                data.contact_id,
              )}`;
        return (
          <div key={id} className="mb-4">
            <label key={`label_noti_${id}`} className="contentCls">
              {data.activity}
            </label>
            {/* <label className="timeCls">{data.label}</label> */}
            <div key={`noti_${id}`} className="notificationCardCls">
              <h5 key={`h5_noti_${id}`}>
                {/* <i className="fa fa-comment mr-3" aria-hidden="true" style={{color: "#5cd0ab"}}></i> */}
                {/* <span dangerouslySetInnerHTML={titleText(data)} /> */}
                {/*data.title notificationTitle(data)*/}
                <span>
                  <a href={linkurls} target="_blank" rel="noreferrer">
                    {data.contact_name}
                  </a>
                </span>
                <OverlayTrigger
                  key={`noti_overlay_${id}`}
                  trigger="click"
                  rootClose={true}
                  placement="right"
                  overlay={
                    <Popover
                      key={`noti_popover_${id}`}
                      id="popover-basic"
                      style={{ width: '200px' }}
                    >
                      <Popover.Title key={`noti_popovert_${id}`} as="h3">
                        Allocate Notification{' '}
                        <span
                          className="closeNotiActionItem"
                          onClick={() => toggleAssignClose()}
                        >
                          <i className="fa fa-close"></i>
                        </span>
                      </Popover.Title>
                      <Popover.Content key={`noti_popoverc_${id}`}>
                        {getBoardData && (
                          <>
                            {getBoardData.map((data, id) => {
                              return (
                                <Checkbox
                                  key={`noti_check_${id}`}
                                  name={data.title}
                                  id={data.id}
                                  details={data}
                                  handleCheckChildElement={getCheckedData}
                                />
                              );
                            })}
                            <div key={`noti_btn_${id}`} className="text-right">
                              <Button
                                className="unSelectedBtn"
                                onClick={() => assignAction(data)}
                                style={{
                                  fontSize: '12px',
                                }}
                              >
                                Assign
                              </Button>
                            </div>
                          </>
                        )}
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <i
                    className="fa fa-ellipsis-h ellipsesCls"
                    aria-hidden="true"
                    onClick={() => toggleAssign()}
                  ></i>
                </OverlayTrigger>
              </h5>
              <p>{truncate(data.description)}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

NotificationSideBar.propTypes = {
  auth: PropTypes.object.isRequired,
  getUserNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  notification: state.notification,
});
const mapDispatchToProps = {
  getUserNotifications,
  createTasks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationSideBar);
