import api from '../../utils/api';
import { isEmpty } from 'lodash';

import {
  LOAD_USER_LIST_TABS,
  USERS_LIST_ERROR,
  SET_USER_LIST_FILTER_DATA,
  SHOW_LOAD_USER_LIST_LOADER,
  LOAD_USER_LIST,
  SET_USER_LIST_CURRENT_TAB,
} from '../types';

/**
 * Get and create url for fetching data
 */
const getAndMakeUrl = (tabData, filterData, gridParams, gridColParams) => {
  let url;
  if (!filterData) {
    url = `/automation/get-users?object=user_grid&tab=${tabData.id}`;
  } else {
    // if (isEmpty(filterData)) {
    //   url = `/automation/get-users?object=user_grid&tab=${
    //     tabData.id
    //   }&filters=${JSON.stringify(filterData)}&gridParams=${JSON.stringify(
    //     gridParams,
    //   )}`;
    // } else {
      url = `/automation/get-users?object=user_grid&tab=${
        tabData.id
      }&filters=${JSON.stringify(filterData)}&gridParams=${JSON.stringify(
        gridParams,
      )}&columns=${JSON.stringify(gridColParams)}`;
    // }
  }
  return url;
};

// Get user list
export const fetchUserListData = (
  filterData,
  gridParams,
  tabData,
  gridColParams,
  showLoader = true,
) => async (dispatch) => {
  let url = getAndMakeUrl(tabData, filterData, gridParams, gridColParams);
  try {
    if (showLoader) {
      dispatch({
        type: SHOW_LOAD_USER_LIST_LOADER,
        payload: { tabData },
      });
    }
    let res = await api.get(url);
    dispatch({
      type: LOAD_USER_LIST,
      payload: {
        [tabData.id]: {
          userList: res.data.users_list,
          count: res.data.counts,
          filterState: isEmpty(res.data.filters)
            ? {}
            : { ...res.data.filters },
          defaultFilterState: isEmpty(res.data.filters)
            ? {}
            : { ...res.data.filters },
          gridParams: isEmpty(res.data.grid_params)
            ? gridParams
            : { ...res.data.grid_params },
          columns: isEmpty(res.data.columns)
            ? {}
            : JSON.parse(res.data.columns),
          loading: false,
        },
      },
    });
  } catch (err) {
    dispatch({
      type: USERS_LIST_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get default filters for user list
export const fetchGridFilters = (tabData) => async (dispatch) => {
  try {
    const res = await api.get('/automation/get-users-filter');
    // const res = await api.get('/automation/get-users');

    let filterListData = Object.keys(res.data);
    dispatch({
      type: SET_USER_LIST_FILTER_DATA,
      payload: {
        id: tabData.id,
        [tabData.id]: {
          myFilterData: [...filterListData],
          filterList: res.data,
        },
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const fetchTabs = () => async (dispatch) => {
  try {
    const res = await api.get(
      'automation/tabs/get-tabs?object=user_grid',
    );
    res.data = res.data.map((e) => ({
      ...e,
      url: e.tab_type ? e.name.toLocaleLowerCase().replace(/ /g, '-') : e.id,
    }));

    // TODO
    dispatch({
      type: LOAD_USER_LIST_TABS,
      payload: {
        tabs: res.data,
        loading: false,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

// Set current tab for User list if any
export const setCurrentTab = (currentTab) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_LIST_CURRENT_TAB,
      payload: {
        currentTab,
      },
    });
  } catch (err) {
    return err;
  }
};
