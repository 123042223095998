import React from 'react';

import EditUserFormTab from './EditUserFormTab';

// const EditUserProfile = (props) => {
//   return (
//     <div className="">
//       <div className="flex-row">
//         <aside className="sideBarMargin">
//           <SidebarNav users="active" />
//         </aside>
//
//         <div className="flex-col content">
//           <EditUserFormTab {...props} />
//         </div>
//       </div>
//     </div>
//   );
// };

const EditUserProfile = (props) => {
  return <EditUserFormTab {...props} />;
};

export default EditUserProfile;
