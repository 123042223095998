import api from "../utils/api";

import { toast } from "react-toastify";
import { isEmpty } from "lodash";

import {
  GET_TEMPLATE,
  GET_TEMPLATES,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  UPDATE_STATE,
  TEMPLATE_ERROR,
  GET_TEMPLATE_STATS,
  GET_TEMPLATE_RECIPIENTS,
  GET_CVSPEC_TEMPLATES,
  GET_MASTER_CVSPEC_TEMPLATES_LIST,
  GET_CVSPEC_TEMPLATELIST,
  REMOVE_CVSPEC_TEMPLATES,
  LOAD_TEMPLATE_DATA,
  LOAD_TEMPLATE_ERROR,
  SHOW_LOAD_TEMPLATE_LOADER,
  SET_TEMPLATE_FILTER_DATA,
  LOAD_TEMPLATE_TABS,
  LOAD_FILTER_TEMPLATE_DATA
} from "../actions/types";

// Get current users temlate
export const getUserTemplate = (
  status = null,
  type = 1,
  admin = true
) => async (dispatch) => {
  try {
    //const params = (status !== null) ? {status:status} : null
    const params = {
      type: type,
      status: status,
      admin,
    };
    const res = await api.get("/automation/template", { params });
    //console.log(res);
    dispatch({
      type: GET_TEMPLATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get template data and set in reducer
export const getTemplate = (template_id) => async (dispatch) => {
  try {
    //const params = (status !== null) ? {status:status} : null
    const res = await api.get(`/automation/template/${template_id}`);
    //console.log(res);
    dispatch({
      type: GET_TEMPLATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get template performance report data and set in reducer
export const getTemplatePerformance = (template_id, params = {}) => async (
  dispatch
) => {
  try {
    const res = await api.get(`/automation/template/details/${template_id}`, {
      params,
    });
    //console.log(res);
    dispatch({
      type: GET_TEMPLATE_STATS,
      payload: {
        ...res.data,
        userList: [{ name: "All", id: "all" }, ...res.data.userList],
      },
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get current users Linkedin temlate
export const getUserLinkedTemplate = (status = null, admin = true) => async (
  dispatch
) => {
  try {
    //const params = (status !== null) ? {status:status} : null;
    const params = {
      type: 2,
      status: status,
      admin,
    };

    //params['type'] = 1;
    //console.log(params);
    const res = await api.get("/automation/template", { params });
    //console.log(res);
    dispatch({
      type: GET_TEMPLATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/**
 * function to get Spec job template list data based on access permission
 */
export const getJobSpecTemplate = (status = null, admin = null) => async (
  dispatch
) => {
  try {
    const params = {
      type: 3,
      status: status,
      admin: admin,
    };
    // static data for template
    /* dispatch({
      type: GET_TEMPLATES,
      payload: templateData,
    }); */
    const res = await api.get("/automation/template", { params });
    //console.log(res);
    dispatch({
      type: GET_TEMPLATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
// add temlate
export const addTemplate = (
  values,
  page,
  onHide,
  setSubmittedTemplate
) => async (dispatch) => {
  try {
    await api.post("/automation/template", { values }).then((res) => {
      /* if (res.status === 201) { */
      dispatch({
        type: CREATE_TEMPLATE,
        payload: res.data,
      });
      onHide("addTemplate");
      if (page === "step") {
        dispatch(getUserTemplate(1));
      } else {
        dispatch(getUserTemplate());
      }
      toast.success("Template created", {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      /* } else {
        setSubmittedTemplate(values);
      } */
    });
    //console.log(res);
  } catch (err) {
    setSubmittedTemplate(values);
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// update temlate
export const updateTemplate = (values, id) => async (dispatch) => {
  try {
    const res = await api.put(`/automation/template/${id}`, values);
    //console.log(res);
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: res.data,
    });
    toast.success("Template updated", {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Update template status
export const updateTemplateStatus = (
  templateId,
  status,
  page,
  admin = true,
  tab = null
) => async (dispatch) => {
  try {
    const values = {
      is_published: status,
    };
    await api
      .put(`/automation/template/update-status/${templateId}`, {
        values,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_TEMPLATE,
            payload: res.data,
          });
          //onHide('addSequence');
          if (page == "job_spec_grid") {
            dispatch(getJobSpecTemplate(null, admin));
          } else if (page == "linkedin_grid") {
            dispatch(getUserLinkedTemplate());
          } else if (page === "email-detail-template") {
            dispatch(getTemplate(templateId));
          } else if (page === "grid") {
            // dispatch(getUserTemplate(undefined, undefined, admin));
            dispatch(fetchTemplateGridData(undefined, undefined, tab));
          } else {
            dispatch(getUserTemplate());
          }

          toast.success("Template status updated", {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Get Template details
export const getTemplateData = (templateId) => async (dispatch) => {
  try {
    const res = await api.get(`/automation/template/${templateId}`);
    //console.log(res);
    dispatch({
      type: GET_TEMPLATE,
      payload: res.data,
    });
    //return res.data;
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
//Get Template content state
export const updateContentState = () => async (dispatch) => {
  try {
    //const res = await api.get(`/automation/template/${templateId}`);
    //console.log(res);
    dispatch({
      type: UPDATE_STATE,
      payload: null,
    });
    //return res.data;
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get template recipient report data and set in reducer
export const getTemplateRecipients = (
  template_id,
  eventFilter = null,
  setIsLoading,
  params = {}
) => async (dispatch) => {
  try {
    //let eventFilters = eventFilter || 'sent';
    const res = await api.get(
      `/automation/template/report-recipients/${template_id}/${
        eventFilter || "sent"
      }`,
      { params }
    );
    //console.log(res);
    setIsLoading(false);
    dispatch({
      type: GET_TEMPLATE_RECIPIENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/**
 * function to get CV Spec templates list data based on access permission
 */
export const getCVSpecTemplate = (status = null, admin = null) => async (
  dispatch
) => {
  try {
    const params = {
      status: status,
      admin: admin,
    };

    const res = await api.get("/automation/cvspec-template", { params });
    // console.log(res);
    dispatch({
      type: GET_CVSPEC_TEMPLATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/**
 * function to get CV Spec templates list data based on access permission
 */
export const getMasterCVSpecTemplatesList = (
  status = null,
  admin = null
) => async (dispatch) => {
  try {
    const params = {
      status: status,
      admin: admin,
    };

    const res = await api.get("/automation/master-cvspec-templates-list", {
      params,
    });
    // console.log(res);
    dispatch({
      type: GET_MASTER_CVSPEC_TEMPLATES_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/**
 * function to get CV Spec templates list data based on access permission
 */
export const getMasterCVSpecTemplate = (status = null, admin = null) => async (
  dispatch
) => {
  try {
    const params = {
      status: status,
      admin: admin,
    };

    const res = await api.get("/automation/master-cvspec-template", { params });
    // console.log(res);
    dispatch({
      type: GET_TEMPLATES,
      // type: GET_TEMPLATE_CVSPEC,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/**
 * Get CV spec template list for drop down
 * @param {*} status
 * @param {*} admin
 * @returns
 */
export const getCVSpecTemplates = (status = null, admin = null) => async (
  dispatch
) => {
  try {
    const params = {
      status: status,
      admin: admin,
    };

    const res = await api.get("/automation/get-cvspec-templates", { params });
    // console.log(res);
    dispatch({
      type: GET_CVSPEC_TEMPLATELIST,
      // type: GET_TEMPLATE_CVSPEC,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/**
 * Call API to delete CV Spec Template
 * @param {*} templateId
 * @param {*} admin
 * @returns
 */
export const deleteCvSpecTemplate = (
  templateId,
  admin = null,
  showSnackbar
) => async (dispatch) => {
  try {
    await api
      .delete(`/automation/cvspec-template/${templateId}`)
      .then((res) => {
        dispatch({
          type: REMOVE_CVSPEC_TEMPLATES,
          payload: res.data,
        });
        dispatch(getCVSpecTemplate(null, admin));
        showSnackbar("Template removed!", "success");
      });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
    showSnackbar(err.response.data.error, "error");
  }
};

/**
 * Get and create url for fetching data for template
 */
const getAndMakeUrl = (tabData, filterData, gridParams, gridColParams) => {
  let url;
  if (!filterData) {
    url = `/automation/get-template-grid?type=1&admin=true&tab=${tabData.id}`;
  } else {
    if (isEmpty(gridColParams)) {
      url = `/automation/get-template-grid?type=1&admin=true&tab=${
        tabData.id
      }&filters=${JSON.stringify(filterData)}&gridParams=${JSON.stringify(
        gridParams
      )}`;
    } else {
      url = `/automation/get-template-grid?type=1&admin=true&tab=${
        tabData.id
      }&filters=${JSON.stringify(filterData)}&gridParams=${JSON.stringify(
        gridParams
      )}&columns=${JSON.stringify(gridColParams)}`;
    }
  }
  return url;
};

// Get template grid data from api
export const fetchTemplateGridData = (
  filterData,
  gridParams,
  tabData,
  gridColParams,
  showLoader = true
) => async (dispatch) => {
  let url = getAndMakeUrl(tabData, filterData, gridParams, gridColParams);
  try {
    if (showLoader) {
      dispatch({
        type: SHOW_LOAD_TEMPLATE_LOADER,
        payload: { tabData },
      });
    }

    let res = await api.get(url);
    if (!filterData) {
      dispatch({
        type: LOAD_FILTER_TEMPLATE_DATA,
        payload: {
          [tabData.id]: {
            templateDataList: res.data.sequences,
            count: res.data.counts,
            filterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            defaultFilterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            gridParams: isEmpty(res.data.grid_params)
              ? gridParams
              : { ...res.data.grid_params },
            columns: isEmpty(res.data.columns)
              ? {}
              : JSON.parse(res.data.columns),
            loading: false,
          },
        },
      });
    } else {
      dispatch({
        type: LOAD_FILTER_TEMPLATE_DATA,
        payload: {
          [tabData.id]: {
            templateDataList: res.data.sequences,
            count: res.data.counts,
            filterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            gridParams: isEmpty(res.data.grid_params)
              ? gridParams
              : { ...res.data.grid_params },
            columns: isEmpty(res.data.columns)
              ? {}
              : JSON.parse(res.data.columns),
            loading: false,
          },
        },
      });
    }
  } catch (err) {
    dispatch({
      type: LOAD_TEMPLATE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

/**
 * Get filter data and its lable
 */
const getFilterLabel = (text = "", data) => {
  let newLabel = {};
  switch (text) {
    case "assigneesList": {
      newLabel = {
        type: "assigned_to",
        title: "All Assignees",
        isSingle: true,
        showName: true,
      };
      break;
    }
    case "categoryList": {
      newLabel = {
        type: "category_id",
        title: "Category",
        isSingle: true,
        showName: true,
      };
      break;
    }
    case "ownerList": {
      newLabel = {
        type: "owner_id",
        title: "Owner",
      };
      break;
    }
    case "accessLevelList": {
      newLabel = {
        type: "access_type",
        title: "Access Level",
      };
      break;
    }
    case "status": {
      newLabel = {
        type: "status",
        title: "Status",
      };
      break;
    }
    default: {
      newLabel = {};
      break;
    }
  }

  return newLabel;
};

// Get default filters for template
export const fetchGridFilters = (tabData) => async (dispatch) => {
  try {
    const res = await api.get("/automation/template-filters");

    let filterListData = Object.keys(res.data).sort((a, b) => {
      let item = [
        "assigneesList",
        "categoryList",
        "ownerList",
        "accessLevelList",
        "status",
      ];
      return item.indexOf(a) - item.indexOf(b);
    });
    filterListData = filterListData.map((k) => ({
      name: k,
      data: res.data[k],
      optionLabel: "name",
      optionValue: "id",
      ...getFilterLabel(k, res.data),
    }));

    filterListData.push({
      name: "createdDate",
      control: "dateRangePicker",
      isSingle: true,
      title: "Created Date",
      type: "createdDate",
      from: "createdFrom",
      to: "createdTo",
    });

    filterListData.push({
      name: "lastSendDate",
      control: "dateRangePicker",
      isSingle: true,
      title: "Last Send Date",
      type: "lastSendDate",
      from: "sentDateFrom",
      to: "sentDateTo",
    });

    dispatch({
      type: SET_TEMPLATE_FILTER_DATA,
      payload: {
        id: tabData.id,
        [tabData.id]: {
          myFilterData: [...filterListData],
          filterList: res.data,
        },
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

//Get tab for email_template object if any
export const fetchTabs = () => async (dispatch) => {
  try {
    const res = await api.get("automation/tabs/get-tabs?object=email_template");

    res.data = res.data.map((e) => ({
      ...e,
      url: e.tab_type ? e.name.toLocaleLowerCase().replace(/ /g, "-") : e.id,
    }));

    dispatch({
      type: LOAD_TEMPLATE_TABS,
      payload: {
        tabs: res.data,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};
