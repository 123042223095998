import api from '../../utils/api';
import { isArray, isEmpty } from 'lodash';

import {
  LOAD_MY_TASK,
  MY_TASK_ERROR,
  SET_MY_TASK_FILTER_DATA,
  SHOW_MY_TASK_LOADER,
  LOAD_TASK_TABS,
  UPDATE_TASK_STATUS,
  SET_TASK_CURRENT_TAB,
} from '../types';

// Get Users and team list for dropdown/autocomplete
export const fetchMyTask = (
  filterData,
  gridParams,
  tabData,
  gridColParams,
  showLoader = true,
) => async (dispatch) => {
  try {
    if (showLoader) {
      dispatch({
        type: SHOW_MY_TASK_LOADER,
        payload: { tabData },
      });
    }

    let res;
    if (!filterData) {
      // filterData = { ...defaultFilter, status: [1] };
      res = await api.get(`/automation/get-task-tab-grid?tab=${tabData.id}`);
    } else {
      if (Number(tabData.id) < 4) {
        filterData = { ...filterData, status: [1] };
      }
      if (isEmpty(gridColParams)) {
        res = await api.get(
          `/automation/get-task-tab-grid?tab=${
            tabData.id
          }&filters=${JSON.stringify(filterData)}&gridParams=${JSON.stringify(
            gridParams,
          )}`,
        );
      } else {
        res = await api.get(
          `/automation/get-task-tab-grid?tab=${
            tabData.id
          }&filters=${JSON.stringify(filterData)}&gridParams=${JSON.stringify(
            gridParams,
          )}&columns=${JSON.stringify(gridColParams)}`,
        );
      }
    }

    // const res = await api.post(
    //   `/automation/my-task-grid`,
    //   defaultFilterState
    //     ? { ...filterData, ...defaultFilterState }
    //     : filterData,
    // );

    if (res.data.filters.assigned_to) {
      res.data.filters.assigned_to = isArray(res.data.filters.assigned_to)
        ? res.data.filters.assigned_to
        : [Number(res.data.filters.assigned_to)];
    }
    // res.data.filters.status && delete res.data.filters.status;

    // if (defaultFilterState) {
    //   dispatch({
    //     type: LOAD_MY_TASK,
    //     payload: {
    //       [tabData.id]: {
    //         myTaskList: res.data.task,
    //         count: res.data.counts,
    //         // myTaskList: res.data.data,
    //         // count: res.data.count,
    //         filterState: { ...res.data.filters },
    //         defaultFilterState: { ...res.data.filters },
    //         loading: false,
    //       },
    //     },
    //   });
    // } else {
    if (!filterData) {
      dispatch({
        type: LOAD_MY_TASK,
        payload: {
          [tabData.id]: {
            myTaskList: res.data.task,
            count: res.data.counts,
            filterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            defaultFilterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            gridParams: isEmpty(res.data.grid_params)
              ? gridParams
              : { ...res.data.grid_params },
            columns: isEmpty(res.data.columns)
              ? {}
              : JSON.parse(res.data.columns),
            loading: false,
          },
        },
      });
    } else {
      dispatch({
        type: LOAD_MY_TASK,
        payload: {
          [tabData.id]: {
            myTaskList: res.data.task,
            count: res.data.counts,
            filterState: isEmpty(res.data.filters)
              ? {}
              : { ...res.data.filters },
            gridParams: isEmpty(res.data.grid_params)
              ? gridParams
              : { ...res.data.grid_params },
            columns: isEmpty(res.data.columns)
              ? {}
              : JSON.parse(res.data.columns),
            loading: false,
          },
        },
      });
    }
  } catch (err) {
    dispatch({
      type: MY_TASK_ERROR,
      payload: {
        msg: err?.response?.statusText || '',
        status: err?.response?.status || '',
      },
    });
  }
};

// Get Users and team list for dropdown/autocomplete
export const fetchTaskForStart = async (filterData, gridParams, tabData) => {
  try {
    const res = await api.get(
      `/automation/get-task-tab-grid?tab=${tabData.id}&filters=${JSON.stringify(
        filterData,
      )}&gridParams=${JSON.stringify(gridParams)}&plateform=slider`,
    );

    return res;
  } catch (err) {
    return err;
  }
};

// Get Users and team list for dropdown/autocomplete
export const updateTaskStatus = (taskData = {}) => async (dispatch) => {
  dispatch({
    type: UPDATE_TASK_STATUS,
    payload: taskData,
  });
};

const getFilterLabel = (text = '', data) => {
  let newLabel = {};
  switch (text) {
    case 'consultantList': {
      newLabel = {
        type: 'assigned_to',
        title: 'All assignees',
        isSingle: true,
        showName: true,
      };
      break;
    }
    case 'priorityList': {
      newLabel = {
        type: 'priority',
        title: 'Priority',
      };
      break;
    }
    case 'taskStatusList': {
      newLabel = {
        type: 'status',
        title: 'Task Status',
      };
      break;
    }
    case 'taskTimePeriodList': {
      newLabel = {
        type: 'due',
        title: 'Time Period',
        isSingle: true,
        control: 'dateRangePicker',
      };
      break;
    }
    case 'taskTypeListForFilter': {
      newLabel = {
        type: 'type',
        title: 'Task Type',
      };
      break;
    }
    default: {
      newLabel = {};
      break;
    }
  }

  return newLabel;
};

// my task filter api
export const fetchMyTaskFilers = (tabData) => async (dispatch) => {
  try {
    const res = await api.get('/automation/get-task-filter');

    let filterListData = Object.keys(res.data)
      .sort()
      .filter((e) => e !== 'taskTypeList');

    switch (tabData.id) {
      case 1: {
        filterListData = filterListData
          .filter((e) => e !== 'taskStatusList' && e !== 'taskTimePeriodList')
          .map((k) => ({
            name: k,
            data: res.data[k],
            optionLabel: 'name',
            optionValue: 'id',
            ...getFilterLabel(k, res.data),
          }));
        break;
      }
      case 2: {
        filterListData = filterListData
          .filter((e) => e !== 'taskStatusList' && e !== 'taskTimePeriodList')
          .map((k) => ({
            name: k,
            data: res.data[k],
            optionLabel: 'name',
            optionValue: 'id',
            ...getFilterLabel(k, res.data),
          }));
        break;
      }
      case 3: {
        filterListData = filterListData
          .filter((e) => e !== 'taskStatusList' && e !== 'taskTimePeriodList')
          .map((k) => ({
            name: k,
            data: res.data[k],
            optionLabel: 'name',
            optionValue: 'id',
            ...getFilterLabel(k, res.data),
          }));
        break;
      }
      case 4: {
        filterListData = filterListData
          .filter((e) => e !== 'taskTimePeriodList')
          .map((k) => ({
            name: k,
            data: res.data[k],
            optionLabel: 'name',
            optionValue: 'id',
            ...getFilterLabel(k, res.data),
          }));
        break;
      }
      default: {
        filterListData = filterListData.map((k) => ({
          name: k,
          data: res.data[k],
          optionLabel: 'name',
          optionValue: 'id',
          ...getFilterLabel(k, res.data),
        }));
        break;
      }
    }

    // const filterListData = Object.keys(res.data)
    //   .sort()
    //   .filter((e) => e !== 'taskStatusList' && e !== 'taskTimePeriodList')
    //   .map((k) => ({
    //     name: k,
    //     data: res.data[k],
    //     optionLabel: 'name',
    //     optionValue: 'id',
    //     ...getFilterLabel(k, res.data),
    //   }));

    if (tabData.id !== 1 && tabData.id !== 2 && tabData.id !== 3) {
      filterListData.push({
        name: 'dueDate',
        control: 'dateRangePicker',
        isSingle: true,
        title: 'Due Date',
        type: 'dueDate',
        from: 'customfilterfrom',
        to: 'customfilterto',
      });
    }
    filterListData.push({
      name: 'createDate',
      control: 'dateRangePicker',
      isSingle: true,
      title: 'Created Date',
      type: 'createDate',
      from: 'createdFrom',
      to: 'createdTo',
    });

    dispatch({
      type: SET_MY_TASK_FILTER_DATA,
      payload: {
        id: tabData.id,
        [tabData.id]: {
          myFilterData: [...filterListData],
          filterList: res.data,
        },
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

// get task tabs
export const fetchTaskTabs = () => async (dispatch) => {
  try {
    const res = await api.get('automation/tabs/get-tabs?object=task');

    res.data = res.data.map((e) => ({
      ...e,
      url: e.tab_type ? e.name.toLocaleLowerCase().replace(/ /g, '-') : e.id,
    }));

    dispatch({
      type: LOAD_TASK_TABS,
      payload: {
        tabs: res.data,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

// set current tabs
export const setTaskCurrentTab = (currentTab) => async (dispatch) => {
  try {
    dispatch({
      type: SET_TASK_CURRENT_TAB,
      payload: {
        currentTab,
      },
    });
  } catch (err) {
    return err;
  }
};
