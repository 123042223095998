import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid } from '@material-ui/core';

import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import CustomSnackBar from '../../../components/CustomSnackBar';
import Spinner from '../../../layout/Spinner';

import { fetchCategory, fetchJobRoles } from '../../../actions/admin/jobRoles';
import api from '../../../utils/api';

// styling for add new job role
const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formHeading: {
    fontSize: 20,
    fontWeight: 500,
    paddingBottom: 10,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
  },
  formCheckbox: {
    '&.Mui-checked': {
      color: custom.colorCode.lightRedColor,
    },
    color: custom.colorCode.lightRedColor,
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
  autoSkillTitle: {
    fontSize: '1rem',
    paddingBottom: 10,
  },
}));

/**
 * This component will be used to create or edit job role
 * @param props
 * @returns {*}
 * @constructor
 */
const AddJobRoleForm = (props) => {
  const { jobRoleEditId, closeDrawer } = props;

  const classes = useStyles();

  // local state to manage the value in the form
  const [formData, setFormData] = useState({});
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [openErrorSnackBarMsg, setOpenErrorSnackBarMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  // Code to subscribe job category and up skill from reducer for drop down
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.adminJobRoles.categoryList);
  const skillsList = useSelector((state) => state.adminJobRoles.skillsList);
  const isMounted = useRef(true);

  // edit and add from date mange when did mount is done
  useEffect(() => {
    // when this form will open in edit mode api will be call with id to populate data
    if (jobRoleEditId) {
      setIsLoading(true);
      api
        .get(`/automation/jobroles/${jobRoleEditId}`)
        .then((res) => {
          setIsLoading(false);

          setFormData({
            name: res.data.name,
            category: res.data.category_id,
            autoSkill: res.data.upskill?.split(',').map((e) => Number(e)),
          });
        })
        .catch((error) => {
          setIsLoading(false);
          setOpenErrorSnackBar(true);
          setOpenErrorSnackBarMsg(
            error.response?.data?.error || 'Something went wrong',
          );
        });
    }
  }, [jobRoleEditId]);

  /**
   * save the edit and add data with api as per condition
   */
  const handleSubmit = () => {
    if (disabledNextButton() === false) {
      setDisableButton(true);

      // create payload for api
      const payload = {
        name: formData.name,
        category: formData.category,
        upskills: (formData.autoSkill || []).join(','),
      };

      // hit edit api if id is present
      if (jobRoleEditId) {
        api
          .put(`/automation/jobroles/${jobRoleEditId}`, { values: payload })
          .then(() => {
            closeDrawer({ jobRoleEdited: true });
            dispatch(fetchJobRoles());
            if(isMounted.current) {
              return
            }
            setTimeout(() => {
              setDisableButton(false);
            });
          })
          .catch((error) => {
            setOpenErrorSnackBar(true);
            setTimeout(() => {
              setDisableButton(false);
            });
            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
          });
      } else {
        // create the job role
        api
          .post('/automation/jobroles', { values: payload })
          .then((res) => {
            closeDrawer({ jobRoleAdded: true });
            dispatch(fetchJobRoles());
            if(isMounted.current) {
              return
            }
            setTimeout(() => {
              setDisableButton(false);
            });
          })
          .catch((error) => {
            setOpenErrorSnackBar(true);
            setTimeout(() => {
              setDisableButton(false);
            });
            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
          });
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;
    // did mount
    // call the job roles api and set data in reducer
    dispatch(fetchCategory());
    return () => {
      isMounted.current = false
    };
  }, []);

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = e === 'autoSkill' ? !formData[e]?.length : !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ['name', 'category'];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disableButton;
    }
  };

  /**
   * function to handle the form element change event
   * @param {*} event
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @param key
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    if (name === 'autoSkill') {
      return setFormData({
        ...formData,
        [name]: tag.map((e) => e[key]),
      });
    }
    setFormData({
      ...formData,
      [name]: tag[key],
    });
  };

  /**
   * function to close toaster after server side error
   */
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackBar(false);
  };

  // show loader unless api data got any response
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> Job Role Details </h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*Job Roles*/}
              <Grid item xs={12} sm={6} className="pr-3">
                <TextValidator
                  label="Job Role *"
                  onChange={handleChange}
                  name="name"
                  value={formData?.name || ''}
                  variant="outlined"
                  fullWidth
                  validators={['required']}
                  errorMessages={['Enter job role']}
                />
              </Grid>

              {/*Job Category*/}
              <Grid item xs={12} sm={6} className="pl-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('category', 'key')}
                  name="category"
                  id="category"
                  disableClearable
                  options={[...(categoryList || [])]}
                  getOptionLabel={(option) => option.value || ''}
                  value={
                    formData?.category
                      ? (categoryList || []).find(
                          (c) => c.key === formData?.category,
                        ) || ''
                      : ''
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Job Category *"
                      value={formData?.category || ''}
                      name="category"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select category']}
                    />
                  )}
                />
              </Grid>

              {/*Auto Skill multi select*/}
              <Grid item xs={12} sm={12}>
                <div className={classes.autoSkillTitle}>
                  Auto Upskill (upskill records automatically based on the job
                  role)
                </div>
                <FormAutoCompleteSelect
                  multiple
                  onChange={handleSelectChange('autoSkill', 'key')}
                  name="autoSkill"
                  id="autoSkill"
                  disableClearable
                  options={skillsList}
                  getOptionLabel={(option) => option.value || ''}
                  value={
                    formData?.autoSkill?.length
                      ? (skillsList || []).filter((c) =>
                          formData?.autoSkill.includes(c.key),
                        )
                      : []
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label=""
                      name="autoSkill"
                      variant="outlined"
                      fullWidth
                      value={formData?.autoSkill || ''}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledNextButton()}
            >
              {jobRoleEditId ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </ValidatorForm>

      <CustomSnackBar
        open={openErrorSnackBar}
        autoHideDuration={5000}
        onClose={handleErrorSnackbarClose}
        severity="error"
        barMessage={openErrorSnackBarMsg || ''}
      />
    </div>
  );
};

export default AddJobRoleForm;
