import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SequencedCandidatesTabs from './SequencedCandidatesTabs';

const SequencedCandidates = (props) => {
  return <SequencedCandidatesTabs {...props} />;
};

SequencedCandidates.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SequencedCandidates);
