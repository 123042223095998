import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import UploadService from "../../utils/UploadService";
import AllowedTypes from "../../utils/AllowedTypes";
//import PropTypes from 'prop-types';

const Fileupload = (props) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("alert alert-light");
  const [deletedFiles, setDeletedFile] = useState([]);

  const [fileInfos, setFileInfos] = useState([]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
    let currentFile = event.target.files[0];
    if (checkMimeType(event) && checkFileSize(event)) {
      upload(currentFile);
    }
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = [];
    // list allow mime type
    const types = AllowedTypes.allowedTypes(props.allowtype);
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err[x] = files[x].type + " is not a supported format";
        setMessageClass("alert alert-danger");
        setMessage("This is not a supported format!");
        return false;
      }
    }
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      //toast.error(err[z])
      event.target.value = null;
    }
    return true;
  };

  const checkFileSize = (event) => {
    let files = event.target.files;
    let size = 8388608;
    let err = [];
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].type + "is too large, please pick a smaller file";
        setMessageClass("alert alert-danger");
        setMessage("File is too large. Max 8 MB file allowed!");
        return false;
      }
    }
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      //toast.error(err[z]);
      event.target.value = null;
    }
    return true;
  };

  const upload = (currentFile) => {
    //let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);

    let object_id = props.is_personalized ? props.object_id : null;
    let template_type = props.template_type ? props.template_type : null;
    if (props.type === "candidate") {
      UploadService.uploadCandidate(
        currentFile,
        props.uuid,
        object_id,
        2,
        (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        }
      )
        .then((response) => {
          setMessageClass("alert alert-light");
          setMessage(response.data.message);
          setProgress(0);
          setCurrentFile(undefined);
          props.onUploadSuccesfully();
        })
        .catch(() => {
          setProgress(0);
          setMessageClass("alert alert-danger");
          setMessage("Could not upload the file!");
          setCurrentFile(undefined);
        });
    } else {
      let personalized = true;
      UploadService.upload(
        currentFile,
        props.uuid,
        object_id,
        template_type,
        personalized,
        (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        }
      )
        .then((response) => {
          setMessageClass("alert alert-light");
          setMessage(response.data.message);
          setProgress(0);
          setCurrentFile(undefined);
          return UploadService.getPersonalizedAssestFiles(
            props.uuid,
            object_id,
            null,
            true
          );
        })
        .then((files) => {
          setFileInfos(files.data);
        })
        .catch(() => {
          setProgress(0);
          setMessageClass("alert alert-danger");
          setMessage("Could not upload the file!");
          setCurrentFile(undefined);
        });
    }

    setSelectedFiles(undefined);
  };

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " (" + sizes[i] + ")";
  };

  const deleteAssets = (id) => {
    setDeletedFile((o) => [...o, id]);
    let object_id = props.is_personalized ? props.object_id : null;
    let template_type = props.template_type ? props.template_type : null;
    let personalized = true;
    UploadService.deleteFile(id, template_type, personalized)
      .then((response) => {
        setMessageClass("alert alert-light");
        setMessage(response.data.message);
        return UploadService.getPersonalizedAssestFiles(
          props.uuid,
          object_id,
          null,
          true
        );
      })
      .then((files) => {
        setFileInfos(files.data);
      })
      .catch(() => {
        setMessageClass("alert alert-danger");
        setMessage("Could not delete the file!");
      });
  };

  useEffect(() => {
    if (props?.attachments && props?.attachments?.length) {
      setFileInfos(props.attachments);
    }
  }, [props.attachments]);
  return (
    <div>
      {currentFile && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}

      {/* <label className="btn btn-primery">
                <input type="file" onChange={selectFile} />
            </label> */}
      <div className="upload-btn-wrapper">
        <button className="uploadBtnCls MuiTypography-body2">
          <i className="fa fa-paperclip" aria-hidden="true"></i>
          {props.type === "candidate" ? props.label : "Upload a file"}
        </button>
        <input type="file" onChange={selectFile} />
      </div>

      {/* <button
                className="btn btn-success"
                disabled={!selectedFiles}
                onClick={upload}
            >
                Upload
            </button> */}

      {message && (
        <div className={messageClass} role="alert">
          {message}
        </div>
      )}

      {fileInfos && Boolean(fileInfos.length) && (
        <div className="card">
          <ul className="list-group list-group-flush">
            {fileInfos.filter(i => deletedFiles.indexOf(i.id) < 0).map((file, index) => (
              /* '/storage/uploads/'+file.name */
              <li className="list-group-item" key={index}>
                <a href={file.path} target="_blank" rel="noreferrer">
                  <i
                    className="fa fa-file-text-o fileIcon"
                    aria-hidden="true"
                  ></i>{" "}
                  {file.original_file_name}
                  <p className="fileSizeCls">{bytesToSize(file.size)}</p>
                </a>
                <span
                  className="fileDeleteCls"
                  onClick={() => {
                    deleteAssets(file.id);
                  }}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </span>
                {/* <Link to='#' onClick={()=>{deleteAssets(file.id)}}>&times;</Link>
                        <button type="button" className="chip-remove"
                        onClick={()=>{deleteAssets(file.id)}}></button> */}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

/* Fileupload.propTypes = {

} */

export default Fileupload;
