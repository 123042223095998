import React, { useState } from 'react';

import CustomDrawer from '../../../components/CustomDrawer';
import DrawerFormHeader from '../../../components/DrawerFormHeader';
import MyTaskForm from './MyTaskForm';
import CustomSnackBar from '../../../components/CustomSnackBar';

// Main creat and edit drawer with the custom drawer
const AddMyTaskDrawer = (props) => {
  const { openDrawer, onClose, dropDownValues, task } = props;
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleClose = (data) => {
    // to show the toast notification
    if (data && data?.taskCreated) {
      setOpenSnackBar(true);
    }
    onClose(data?.taskCreated);
  };

  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div>
      <CustomDrawer anchor={'right'} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={task?.taskId ? 'Edit Task' : 'Create Task'}
        >
          <MyTaskForm
            dropDownValues={dropDownValues}
            closeDrawer={handleClose}
            editMyTask={task}
          />
        </DrawerFormHeader>
      </CustomDrawer>
      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        handleSnackbarClose={handleSnackbarClose}
        barMessage={
          task?.taskId ? 'Task update successfully!' : 'New Task added!'
        }
        severity={'success'}
      />
    </div>
  );
};

export default AddMyTaskDrawer;
