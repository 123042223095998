import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { Col, Modal, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
//import { Editor } from '@tinymce/tinymce-react';
//import { defaultsDeep } from 'lodash';
import TextEditor from '../utils/Editor';
import Fileupload from '../utils/Fileupload';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  name: yup.string().required('Template name is a required field'),
  //template_type: yup.number().required('Template type is a required field'),
  subject: yup.string().required('Template subject is a required field'),
  content: yup.string().required('Content is a required field'),
});

const EditTemplate = (props) => {
  const uuid = uuidv4();

  const [templateData, setTemplateData] = useState({});

  const [formData, setFormData] = useState({
    name: '',
    template_type: '',
    subject: '',
    content: '',
    id: '',
  });

  //const { name, template_type, subject, content, id } = formData;
  const {
    register,
    handleSubmit,
    control,
    errors,
    reset,
    setValue,
    //getValues,
    formState: { isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(schema), defaultValues: formData });
  //var content = '';
  const onUpdateTemplate = async (values) => {
    await api
      .put(`/automation/template/${props.temp_id}`, {
        values,
      })
      .then((response) => {
        //console.log('API response-', response);
        if (response.status === 200) {
          setFormData({ content: '' });
          'getRecords' in props
            ? props.getRecords(
                undefined,
                undefined,
                props.admin ? props.admin : null,
              )
            : null;
          'updateName' in props ? props.updateName(values.name) : null;
          props.onHide();
          toast.success('Template updated', {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
          //return <Redirect to='/sequence/steps/' />;
        } else {
          setTemplateData(values);
          //isSubmitSuccessful = false;
        }
      })
      .catch(() => {
        setTemplateData(values);
        //isSubmitSuccessful = false;
      });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      //console.log(templateData);
      reset({ ...templateData });
    }
  }, [isSubmitSuccessful, templateData, reset]);

  useEffect(() => {
    if (props.temp_id) {
      api.get(`/automation/template/${props.temp_id}`).then((response) => {
        setFormData(response.data);
        setValue('name', response.data.name);
        setValue('subject', response.data.subject);
        setValue('content', response.data.content);
        //setValue('template_type',response.data.template_type);
      });
    }
  }, [props.temp_id]);

  return (
    <Modal
      show={props.modalState}
      onHide={() => props.onHide()}
      dialogClassName="custom-modal"
      enforceFocus={false}
    >
      <Form onSubmit={handleSubmit(onUpdateTemplate)}>
        <Modal.Header className="modalHeaderCls" closeButton={false}>
          <div>
            <Modal.Title className="modalTitle">
              Edit Email Template
            </Modal.Title>
            <span className="closeModalIconCls" onClick={() => props.onHide()}>
              <i className="fa fa-close"></i>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label className="floating-label">Template Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                className="custom_input"
                ref={register}
              ></Form.Control>
              {errors.name && (
                <label className="error">{errors.name?.message}</label>
              )}
            </Form.Group>
            {/* <Form.Group as={Col} controlId="group">
                                <Form.Label className="floating-label">Group Name</Form.Label>
                                <Form.Control type="text" name="group" className="custom_input"
                                ref={register}></Form.Control>
                            </Form.Group> */}
          </Form.Row>

          {/* <Form.Row>
                            <Form.Group as={Col} controlId="template_type">
                                <Form.Label>Tempalte Type</Form.Label>
                                <Form.Control as="select" name="template_type" defaultValue="1"
                                custom="true" ref={register}>
                                    <option value="1">Email</option>
                                    <option value="2">Cv Spec</option>
                                </Form.Control>
                                {errors.template_type && <label className="error">
                                {errors.template_type?.message}</label>}
                            </Form.Group>
                        </Form.Row> */}

          <Form.Row>
            <Form.Group as={Col} controlId="subject">
              <Form.Label className="floating-label">
                Template Subject
              </Form.Label>
              <Form.Control
                type="text"
                name="subject"
                className="custom_input"
                ref={register}
              ></Form.Control>
              {errors.subject && (
                <label className="error">{errors.subject?.message}</label>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="content">
              <Form.Label className="floating-label">Content</Form.Label>
              <Controller
                control={control}
                name="content"
                defaultValue=""
                render={({ onChange, value }) => (
                  <TextEditor onChange={onChange} initialvalue={value} />
                )}
              />
              {errors.content && (
                <label className="error">{errors.content?.message}</label>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Control
            type="hidden"
            name="uuid"
            id="uuid"
            value={uuid}
            className="custom_input"
            ref={register}
          />

          <Form.Row>
            <Form.Group as={Col} controlId="file">
              <Fileupload
                uuid={uuid}
                object_id={props.temp_id}
                allowtype="ATTACHMENT"
              />
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button varient="primary" className="pl-4 pr-4" type="submit">
            Update
          </Button>
          {/* <Button varient="primary" className="pl-4 pr-4"
          onClick={() => this.props.onHide('addSequence')}>Next</Button> */}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditTemplate;
