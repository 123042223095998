import React, { useEffect } from 'react';

import { Col, Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { cloneSequence } from '../actions/sequence';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const schema = yup.object().shape({
  name: yup.string().required('Sequence name is a required field'),
});

const CloneSequence = (props) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    //formState: { isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(schema) });
  // const [templateData, setTemplateData] = React.useState({});

  const onSubmitClone = async (values) => {
    props.cloneSequence(values, props.history, props.onHide);
  };

  useEffect(() => {
    if (props.seq_id) {
      setValue('name', props.sname + ' (copy)');
      setValue('sequence_id', props.seq_id);
    }
  }, [props.seq_id]);

  return (
    <Modal
      show={props.modalState}
      onHide={() => props.onHide()}
      dialogClassName="custom-modal"
    >
      <Form onSubmit={handleSubmit(onSubmitClone)}>
        <Modal.Header className="modalHeaderCls" closeButton={false}>
          <div>
            <Modal.Title className="modalTitle" style={{ color: '#fff' }}>
              <strong>Clone sequence</strong>
            </Modal.Title>
            <span className="closeModalIconCls" onClick={() => props.onHide()}>
              <i className="fa fa-close"></i>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label className="floating-label">Sequence Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                className="custom_input"
                ref={register}
              ></Form.Control>
              {errors.name && (
                <label className="error">{errors.name?.message}</label>
              )}
            </Form.Group>
            <Form.Control
              type="hidden"
              name="sequence_id"
              id="sequence_id"
              className="custom_input"
              ref={register}
            />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button varient="primary" className="pl-4 pr-4" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CloneSequence.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = {
  cloneSequence,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloneSequence);
