import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SequenceGrid from './SequenceGrid/index';

// const Sequence = (props) => {
//   return (
//     <div className="">
//       <div className="flex-row">
//         <aside className="sideBarMargin">
//           <SidebarNav sequences="active" />
//         </aside>
//
//         <div className="flex-col content">
//           <SequenceGrid {...props} />
//         </div>
//       </div>
//     </div>
//   );
// };

const Sequence = (props) => {
  return <SequenceGrid {...props} />;
};

Sequence.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Sequence);
