import React from 'react';
import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ shape, spacing, custom }) => ({
  searchBox: {
    marginTop: 10,
    marginBottom: 10,
    position: 'relative',
    borderRadius: shape.borderRadius,
    marginRight: spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: '0 10px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    '& svg': {
      fill: '#a5a7af',
    },
  },
  inputRoot: {
    color: 'inherit',
    height: '100%',
    border: '1px solid #8ba4be',
    borderRadius: 4,
    backgroundColor: '#F5F7F9',
  },
  inputInput: {
    padding: spacing(1, 1, 1, 0),
    paddingLeft: 48,
  },
  searchBoxIcon: {
    height: 15,
    fill: custom.colorCode.blueLightShade3,
  },
}));

// this is the custom page header without grid
const PageSearchBox = (props) => {
  const { mainSearchClassName = '', handleChange, ...restProps } = props;

  const classes = useStyles();

  // handle change
  const handleSearchChange = (event) => {
    handleChange(event);
  };

  return (
    <div className={`${classes.searchBox} ${mainSearchClassName}`}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search here…"
        onChange={handleSearchChange}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        {...restProps}
      />
    </div>
  );
};

export default PageSearchBox;
