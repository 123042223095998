import React from "react";
import api from "../utils/api";

const upload = (
  file,
  uuid,
  object_id,
  template_type,
  onUploadProgress,
  personalized = null
) => {
  let formData = new FormData();

  formData.append("file", file);
  formData.append("uuid", uuid);
  if (object_id != null && object_id > 0) {
    formData.append("object_id", object_id);
  }
  if (template_type != null && template_type > 0) {
    formData.append("template_type", template_type);
  }
  if (personalized) {
    formData.append("personalized", true);
  }
  return api.post("/automation/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const uploadCandidate = (file, uuid, object_id, cv_type, onUploadProgress) => {
  let formData = new FormData();

  formData.append("file", file);
  formData.append("uuid", uuid);
  formData.append("cv_type", cv_type);
  formData.append("candidate_id", object_id);
  return api.post("/automation/cv-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const getFiles = (uuid, object_id, clone = null, template_type = null) => {
  return api.get(`/automation/files`, {
    params: {
      uuid: uuid,
      object_id: object_id,
      clone: clone,
      template_type: template_type,
    },
  });
};

const deleteFile = (id, template_type = null, personalized = null) => {
  // return api.delete(`/automation/files/${id}`);
  return api.delete(`/automation/files/${id}`, {
    params: {
      template_type: template_type,
      personalized: personalized,
    },
  });
};

const getPersonalizedAssestFiles = (
  uuid,
  object_id,
  clone = null,
  personalized = null,
) => {
  return api.get(`/automation/files`, {
    params: {
      uuid: uuid,
      object_id: object_id,
      clone: clone,
      personalized: personalized,
    },
  });
};

export default {
  upload,
  getFiles,
  deleteFile,
  uploadCandidate,
  getPersonalizedAssestFiles,
};
