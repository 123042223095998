import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MUIAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    '& .MuiAlert-message': {
      width: '100%',
    },
  },
  iconButton: {
    '& svg': {},
  },
}));

const Alert = ({
  handleClose = () => {},
  className = '',
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MUIAlert
      icon={false}
      className={`${className} ${classes.root}`}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          className={classes.iconButton}
          onClick={() => {
            console.log('MUIAlert');
            handleClose();
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      {...props}
    >
      {children ? children : <div>Close me!</div>}
    </MUIAlert>
  );
};

export default Alert;
