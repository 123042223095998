import React, { useState } from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//import SidebarNav from '../includes/SidebarNav';
import NotificationSideBar from './components/NotificationSideBar';
import NotificationFilter from './components/NotificationFilter';
import TaskBoard from './components/TaskBoard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
  const [toggleFilter, setToggleFilter] = useState(false);
  const [newBoardData, setNewBoardData] = useState([]);
  const [updatedBoardData, setUpdatedBoardData] = useState(null);
  const [cardId, setCardId] = useState(null);
  const [toggleData, setToggleData] = useState(false);

  const toggleNotificationFilter = () => {
    setToggleFilter(!toggleFilter);
  };

  const getBoardData = (data) => {
    let newData = null;
    newData = data;
    setNewBoardData(newData);
  };

  const taskCardUpdated = (data, id) => {
    console.log('data', data, id);
    setUpdatedBoardData(null);
    let newData = null;
    newData = data;
    setUpdatedBoardData(newData);
    setCardId(id);
    setToggleData(!toggleData);
  };

  return (
    <div className="flex-row">
      <div className="flex-col sidebar" style={{ flexBasis: '350px' }}>
        <h2>
          Notification
          {
            <a
              data-toggle="modal"
              data-target="#searchSlider"
              data-backdrop="static"
              data-keyboard="false"
              className="pull-right text-sixteen"
              onClick={() => toggleNotificationFilter()}
            >
              <i className="fa fa-external-link"></i>
            </a>
          }
        </h2>
        <NotificationSideBar
          newBoardData={newBoardData}
          updatedAssignCard={taskCardUpdated}
        />
        <NotificationFilter
          modalShow={toggleFilter}
          onHide={() => toggleNotificationFilter()}
        />
      </div>

      <div className="flex-col content trelloContent" style={{ padding: 0 }}>
        <TaskBoard
          sendUpdateboardDetails={getBoardData}
          updatedAssignCard={taskCardUpdated}
          updatedBoardData={updatedBoardData}
          cardId={cardId}
        />
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Dashboard);
