import React from "react";
import { Typography, Box } from "@material-ui/core";

import { useStyles } from "./styles";
import onboardingManPresenting from "../../../../../img/onboardingManPresenting.png";
import superReachLogo from "../../../../../img/superReach-logo.svg";

function Intro({ user, ...props }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.introContainer}>
        <div className={classes.introContent}>
          <img
            src={onboardingManPresenting}
            className={classes.introManImage}
          />
          <div className={classes.introBubble}>
            <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
              <Typography className={classes.introFromText}>
                Anil from
              </Typography>
              <img src={superReachLogo} className={classes.introSRLogo} />
            </Box>
            <Typography variant="h6">
              Hi {user.fname}, ! I'm Anil from SuperReach. I'm here to help you
              get set up to build relationships with your customers. Let's go!
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intro;
