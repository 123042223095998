import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, custom }) => ({
  root: {
    fontSize: 12,
  },

}));

export default useStyles;
