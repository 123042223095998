import api from '../../utils/api';

import {
  LOAD_ADMIN_OFFICES,
  OFFICE_ADMIN_ERROR,
  LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES,
  LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES_ERROR,
} from '../types';

// Get office list for grid listing
export const fetchOffices = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/offices');

    dispatch({
      type: LOAD_ADMIN_OFFICES,
      payload: res.data,
    });
  } catch (err) {
    // error handle and set in the reducer
    dispatch({
      type: OFFICE_ADMIN_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get entity, countries and timezones list for add/edit office drop down
export const fetchCountriesTimezones = () => async (dispatch) => {
  try {
    const res = await api.get('/automation/get-country-timezone');

    dispatch({
      type: LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES,
      payload: {
        countries: res.data.countryList,
        timezones: res.data.timezoneList,
        entityList: res.data.entityList,
      },
    });
  } catch (err) {
    // error handle and set in the reducer
    dispatch({
      type: LOAD_ADMIN_OFFICES_TIMEZONES_COUNTRIES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
