import React from 'react';
import { connect } from 'react-redux';

import IntegrationDetailTabs from './IntegrationDetailTabs';
import TemplateTabs from "../../../templates/TemplateTabs";

// const IntegrationDetail = (props) => {
//   return (
//     <div className="">
//       <div className="flex-row">
//         <aside className="sideBarMargin">
//           <SidebarNav integration="active" />
//         </aside>
//
//         <div className="flex-col content">
//           <IntegrationDetailGrid admin {...props} />
//         </div>
//       </div>
//     </div>
//   );
// };

const IntegrationDetail = (props) => {


  return <IntegrationDetailTabs admin {...props} />


  // return <IntegrationDetailGrid admin {...props} />;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(IntegrationDetail);
