import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ custom }) => ({
  breadCrumb: {
    fontSize: 14,
  },
  breadCrumbLastChild: {
    fontSize: 14,
  },
  pageHeader: {
    margin: '10px 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      fontSize: 20,
    },
  },
  pageHeaderRight: {
    display: 'flex',
    alignItems: 'center',
  },
  pageHeaderRightOwner: {
    fontSize: '16px !important',
    marginRight: 10,
    '& b': {
      marginRight: 5,
    },
  },
  publishSwitch: {
    marginRight: 10,
  },
  actionButton: {
    height: 30,
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    '& span': {
      fontSize: 15,
    },
    ': hover': {
      backgroundColor: custom.colorCode.blueLightShade11,
    },
  },
}));

export default useStyles;
