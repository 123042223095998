import React, { useEffect, useMemo, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isEmpty, isEqual } from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Menu, MenuItem, Typography, Tooltip } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";

// import CreateSequence from '../CreateSequence';
import CreateSequence from "../CreateSequenceSlider";
import CloneSequence from "../CloneSequence";
import EditSequence from "../EditSequence";
import CustomDataGrid from "../../controlled-component/customGridComponent";
import AddButton from "../../components/CreateToolbarButton";
import LoadingTableBody from "../../components/LoadingTableBody";
import { ActiveIcon, InActiveIcon } from "../../components/GridIcons";
import {
  fetchGridFilters,
  fetchSequenceGridData,
  updateSequenceStatus,
} from "../../actions/sequence";
import api from "../../utils/api";
import TaskTableToolbar from "../../controlled-component/customGridComponent/CustomToolbar/TableToolbar";
import EnrollContact from "../EnrollContact";
import Dialog from "../../components/Dialog";

// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  sequenceGridWrapper: {
    padding: "10px 0 20px 0",
  },
}));

/**
 * Sequence Grid component for current tab
 */
const SequenceGrid = (props) => {
  const classes = useStyles();

  const [popupToggle, setPopupToggle] = useState(false);
  const [sequenceId, setSequenceId] = useState();
  const [selectedSequence, setSelectedSequence] = useState({});
  const [clonePopupToggle, setClonePopupToggle] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState({ isOpen: false });
  const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [facetdata, setFacetdata] = useState({});
  const [facetfields, setFacetfields] = useState([]);
  const [filters, setFilters] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [enrollPopupToggle, setEnrollPopupToggle] = useState(false);
  const [selectedSequenceToEnroll, setSelectedSequenceToEnroll] = useState({});
  const [showEmailConnectPopup, setShowEmailConnectPopup] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const reloadData = useSelector((state) => state.sequence.reload);
  const sequenceData = useSelector(
    (state) => state.sequence[props.tabProps.id]
  );
  const userData = useSelector((state) => state.auth.user);
  const sequenceCount = useSelector((state) => state.sequence.count);

  const filterStateRef = useRef({});
  const gridParams = useRef({});
  const gridColParams = useRef({});
  const filterStateParams = useRef({});

  useEffect(() => {
    if (!isEmpty(sequenceData.gridParams)) {
    gridParams.current = sequenceData.gridParams;
    }
  }, [sequenceData.gridParams]);

  useEffect(() => {
    filterStateParams.current = sequenceData.filterState;
  }, [sequenceData.filterState]);

  useEffect(() => {
    if (!isEmpty(sequenceData.columns)) {
      gridColParams.current = sequenceData.columns;
    }
  }, [sequenceData.columns]);

  // handle function to open add sequence popup
  const handlePopupOpen = () => {
    setSequenceId(false);
    setPopupToggle(!popupToggle);
  };

  // handle function to close add sequence popup
  const handlePopupClose = () => {
    setPopupToggle(false);
    setSequenceId(false);
  };

  // handle the edit sequence pop up
  const handleEditPopupOpen = (id, sequenceRow) => {
    setSequenceId(id);
    setEditPopupToggle(!editPopupToggle);
    setSelectedSequence(sequenceRow ? sequenceRow : {});
  };

  // update status with reducer
  const changeStatus = (newSequenceId, rowData) => {
    const newStatus = rowData.is_published === "Un-Published" ? 1 : 2;
    props.updateSequenceStatus(
      newSequenceId,
      newStatus,
      "sequence_grid",
      null,
      setOpenSnackBar
    );
  };

  const handleEnrollContact = (sequenceId, rowData) => {
    api
      .get("integrations/myemail_plugin")
      .then((resp) => {
        if (resp.status == 200) {
          if (!resp.data) {
            setShowEmailConnectPopup(!resp.data);
          } else {
            setEnrollPopupToggle(true);
            setSelectedSequenceToEnroll(rowData);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnConnectButton = () => {
    props.history.push("/profile/integrations");
  };

  const handleDialogClose = () => {
    setShowEmailConnectPopup(!showEmailConnectPopup);
  };

  const handleEnrollPopup = (e) => {
    if (e && e == "enroll") {
      dispatch(
        fetchSequenceGridData(
          {
            ...filterStateParams.current,
          },
          gridParams.current,
          props.tabProps,
          isEmpty(gridColParams.current) ? null : gridColParams.current
        )
      );
    }
    setEnrollPopupToggle(false);
  };

  // make copy of the selected sequence
  const handleCopySequence = (newSequenceId, sequenceRow) => {
    setSequenceId(newSequenceId);
    setSelectedSequence(sequenceRow ? sequenceRow : {});
    setClonePopupToggle(!clonePopupToggle);
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar({
      isOpen: false,
      message: "",
    });
  };

  useEffect(() => {
    // sequence data update this will update the options
    if (sequenceData.sequenceList.length && sequenceData.myFilterData.length) {
      setFacetdata({});
      setFacetfields(
        sequenceData.myFilterData.map((e) => ({
          label: e.name,
          value: e.title,
        }))
      );
    }
    filterStateRef.current = sequenceData.filterState;
  }, [sequenceData]);

  useEffect(() => {
    // did mount
    console.log("here in monut state");
    dispatch(fetchGridFilters(props.tabProps));
  }, []);

  useEffect(() => {
    if (
      !isEmpty(sequenceData.filterList) &&
      userData?.id &&
      sequenceCount == 0
    ) {
      // call the fetch sequence data api and set data in reducer
      console.log("here in grid state", sequenceData);
      dispatch(
        fetchSequenceGridData(
          null,
          sequenceData.gridParams
            ? sequenceData.gridParams
            : { pageNo: 0, perPage: 25, searchText: null },
          props.tabProps,
          null
        )
      );
    }
  }, [sequenceData.filterList, userData]);

  useEffect(() => {
    if (reloadData) {
      // call the fetch sequence api when action reloads
      if(isEmpty(filterStateParams.current) && isEmpty(gridParams.current)) {
        dispatch(fetchGridFilters(props.tabProps));
      } else {
      dispatch(
        fetchSequenceGridData(
          {
            ...filterStateParams.current,
          },
          gridParams.current,
          props.tabProps,
          isEmpty(gridColParams.current) ? null : gridColParams.current
        )
      );
    }
    }
  }, [reloadData]);

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: "name",
        label: "Sequence Name",
        options: {
          filter: true,
          sort: true,
          draggable: false,
          setCellHeaderProps: () => ({
            style: { minWidth: 300, width: 300, maxWidth: 300 },
          }),
          setCellProps: () => ({
            style: { minWidth: 300, width: 300, maxWidth: 300 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = sequenceData?.sequenceList[dataIndex];
            return (
              <Tooltip title={rData.name || ""} arrow>
                <div className={"td-content-ellipsis"}>
                  {/*<Link*/}
                  {/*  to={`/sequence/steps/${rData.id}`}*/}
                  {/*  className='text-truncate'*/}
                  {/*>*/}
                  {/*  {rData.name}*/}
                  {/*</Link>*/}
                  <Link
                    to={`/sequence/detail/${rData.id}`}
                    // to={`/admin/sequence/detail/${rData.id}`}
                    className="text-truncate td-anchor-content-ellipsis"
                  >
                    {rData.name}
                  </Link>{" "}
                  ({rData.id})
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        label: "Type",
        name: "audience_type",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        label: "Steps",
        name: "total_steps",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: "Enrolled",
        name: "total_enrolled",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: "In Progress",
        name: "in_progress",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: "Sent",
        name: "total_sent",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: "Unsubscribe",
        name: "total_unsubscribe",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: "Opened",
        name: "total_opened",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: "Clicked",
        name: "total_clicked",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: "Bounces",
        name: "total_bounces",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: "Replied",
        name: "total_replied",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: "Permission Type",
        name: "access_type",
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: false,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: "Completed",
        name: "completed",
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: false,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: "Hold",
        name: "hold",
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: false,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: "Stoped",
        name: "stopped",
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: false,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },

      // {
      //   label: 'Status',
      //   name: 'is_published',
      //   options: {
      //     filter: true,
      //     sort: true,
      //     setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
      //     customBodyRender: (value) => {
      //       return value === 'Un-Published' ? (
      //         <InActiveIcon fontSize='small' />
      //       ) : (
      //         <ActiveIcon fontSize='small' />
      //       );
      //     },
      //   },
      // },
      {
        name: "id",
        label: " ",
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = sequenceData?.sequenceList.find(
              (tData) => tData.id === value
            );
            return (
              <GridActions
                temp_id={row?.id}
                status={row?.status}
                row={row}
                handleEditPopupOpen={handleEditPopupOpen}
                updatestatus={changeStatus}
                copySequence={handleCopySequence}
                enrollContact={handleEnrollContact}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [sequenceData.sequenceList]
  );

  /**
   * Handle refetch of sequence data when filter changed
   */
  const handleReFetch = (filterObject) => {
    if (filterStateParams.current?.is_published) {
      filterObject = {
        ...filterObject,
        is_published: filterStateParams.current?.is_published,
      };
    }
    dispatch(
      fetchSequenceGridData(
        {
          ...(isEmpty(filterObject)
            ? !isEmpty(defaultFilter)
              ? defaultFilter
              : {}
            : filterObject),
        },
        {
          ...gridParams.current,
          pageNo: 0,
          perPage: 25,
        },
        props.tabProps,
        isEmpty(gridColParams.current) ? null : gridColParams.current
      )
    );
  };

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) =>
      <LoadingTableBody loading={sequenceData.loading} {...props} />,
    [sequenceData.loading]
  );

  /**
   * Sequence Toolbar component
   */
  const TableToolbar = useMemo(
    () => (props) => {
      return (
        <TaskTableToolbar
          refetch={handleReFetch}
          myFilterData={sequenceData.myFilterData}
          defaultFilterState={sequenceData.defaultFilterState}
          filterState={sequenceData.filterState}
          visibleFilter={1}
          filterDrawerName={"Sequence Filters"}
          showEditCol
          onColumnChange={handleColumnChange()}
          loading={sequenceData.loading}
          defaultUnSetFilters={"assignees"}
          {...props}
        />
      );
    },
    [sequenceData.myFilterData, sequenceData.defaultFilterState]
  );

  /**
   * Handle page change in grid
   */
  const handlePerPageChange = (params) => {
    // dispatch(
    //   fetchSequenceGridData(
    //     {
    //       ...sequenceData.filterState,
    //       perPage: params.rowsPerPage,
    //     },
    //     undefined,
    //     props.tabProps,
    //   ),
    // );
  };

  /**
   * Handle column change of grid
   */
  const handleColumnChange = (showLoader) => (paramColumns) => {
    dispatch(
      fetchSequenceGridData(
        {
          ...filterStateParams.current,
        },
        gridParams.current,
        props.tabProps,
        paramColumns,
        showLoader
      )
    );
  };

  /**
   * Handle table change event of grid
   */
  const handleTableChange = (params) => {
    dispatch(
      fetchSequenceGridData(
        {
          ...sequenceData.filterState,
        },
        params,
        props.tabProps,
        isEmpty(gridColParams.current) ? null : gridColParams.current
      )
    );
  };

  return (
    <div className={classes.templateGridMainSection}>
      <div className={classes.sequenceGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns.map((col, index) => ({
            ...col,
            options: sequenceData?.columns?.columns?.length
              ? { ...col.options, ...sequenceData?.columns?.columns[index] }
              : col.options,
          }))}
          data={sequenceData.sequenceList}
          onTableRowPerPageChange={handlePerPageChange}
          onTableChange={handleTableChange}
          onColumnChange={handleColumnChange(false)}
          paperHeight={"295px"}
          options={{
            columnOrder: sequenceData?.columns?.columnsOrder,
            pagination: Boolean(sequenceData?.count),
            page: sequenceData?.gridParams?.pageNo || 0,
            rowsPerPage: sequenceData?.gridParams?.perPage || 25,
            searchText: sequenceData?.gridParams?.searchText,
            selectableRows: "multiple",
            serverSide: true,
            filterType: "dropdown",
            responsive: "simple",
            searchPlaceholder: "Search Sequences",
            filterOptions: facetfields,
            facetdata: facetdata,
            customFilterList: filters,
            gridParams: gridParams,
            searchOpen: true,
            search: true,
            filter: true,
            download: true,
            viewColumns: true,
            count: sequenceData?.count,
            selectToolbarPlacement: "replace",
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
              setRowsSelected(allRows.map((row) => row.dataIndex));
            },
            sortOrder: sequenceData?.gridParams?.sortOrder
              ? sequenceData?.gridParams?.sortOrder
              : undefined,
            customToolbar: () => {
              return (
                <AddButton
                  handleClick={handlePopupOpen}
                  title={`Create Sequence`}
                  dataTarget={`createSequence`}
                />
              );
            },
          }}
          components={{ TableBody: BodyComponent, TableToolbar: TableToolbar }}
        />

        {/* create sequence popup */}
        <CreateSequence
          openDrawer={popupToggle}
          onClose={handlePopupOpen}
          history={props.history}
        />

        {/* Copy/clone sequence popup */}
        <CloneSequence
          seq_id={sequenceId}
          sname={selectedSequence.name}
          modalState={clonePopupToggle}
          onHide={handleCopySequence}
          history={props.history}
        />

        {/* Edit sequence popup */}
        <EditSequence
          seq_id={sequenceId}
          sname={selectedSequence.name}
          modalState={editPopupToggle}
          onHide={handleEditPopupOpen}
          history={props.history}
          page="grid"
        />

        {/* enroll sequence popup */}
        <EnrollContact
          openDrawer={enrollPopupToggle}
          onClose={handleEnrollPopup}
          history={props.history}
          selectedSequenceToEnroll={selectedSequenceToEnroll}
        />

        {/*email connect popup*/}
        <Dialog
          // objectId={sequenceId}
          open={showEmailConnectPopup}
          title="Connect Mailbox"
          content="To send emails via SuperReach your first need to connect your mailbox."
          handleClick={handleOnConnectButton}
          onClose={handleDialogClose}
          buttonText={`Connect`}
        />

        {/* Toaster if any changes in grid */}
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
};

/**
 * Grid Actions
 */
const GridActions = (props) => {
  const { row } = props;
  const [anchorElement, setAnchorElement] = useState(null);

  //const editable = user.id === row.created_by;
  const menuId = "action-menu-id";

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case "editPopUp": {
        props.handleEditPopupOpen(props.temp_id, props.row);
        break;
      }
      case "changeStatus": {
        props.updatestatus(props.temp_id, props.row);
        break;
      }
      case "copySequence": {
        props.copySequence(props.temp_id, props.row);
        break;
      }
      case "enrollContactToSequence": {
        props.enrollContact(props.temp_id, props.row);
      }
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: "Change Status",
      itemType: "changeStatus",
    },
    {
      title: "Clone",
      itemType: "copySequence",
    },
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: "#5780ab" }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          to={`/sequence/steps/${props.temp_id}`}
          // to={`/admin/sequence/steps/${props.temp_id}`}
        >
          Edit Steps
        </MenuItem>
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
        {row.is_published == "Published" && (
          <MenuItem
            key={"enroll_1"}
            onClick={handleMenuClose("enrollContactToSequence")}
          >
            Enroll
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  updateSequenceStatus,
};

export default connect(null, mapDispatchToProps)(SequenceGrid);
