import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

import CustomDrawer from '../../../components/CustomDrawer';
import DrawerFormHeader from '../../../components/DrawerFormHeader';
import { OutlinedButton } from '../../../components/Button';
import StartTaskView from './StartTaskView';
import Spinner from '../../../layout/Spinner';
import CreateMyTask from '../CreateMyTask';

import { fetchTaskForStart } from '../../../actions/admin/myTask';
import api from '../../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  collapseEditText: {
    color: custom.colorCode.blueLightShade8,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: 4,
    '& svg': {
      marginLeft: 4,
    },
  },
  startTaskDrawerMain: {},
}));

// Main open the start task drawer
const StartTaskDrawer = (props) => {
  const {
    openDrawer,
    onClose,
    taskCount,
    tabData,
    filterParams,
    gridParams,
    taskData,
  } = props;

  const classes = useStyles();

  let [currentRecord, setCurrentRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentTaskData, setCurrentTaskData] = useState({});
  let [taskDataChunk, setTaskDataChunk] = useState([]);
  const [popupToggle, setPopupToggle] = useState(false);
  const currentRecordRef = useRef(0);

  const myTask = useSelector((state) => state.myTask[props.tabData.id]);

  useEffect(() => {
    if (!isEmpty(gridParams) && gridParams?.pageNo === 0) {
      setTaskDataChunk([...taskData]);
    }
  }, [gridParams, taskData]);

  const handleClose = (data) => {
    onClose();
  };

  useEffect(() => {
    if (!openDrawer) {
      setCurrentRecord(0);
      setCurrentTaskData({});
    }
  }, [openDrawer]);

  const getTaskDetail = (counter) => {
    setLoading(true);
    if (!taskDataChunk[counter || 0]) {
      if (loading) return;
      setCurrentPage(currentPage + 1);

      fetchTaskForStart(
        filterParams,
        {
          ...gridParams,
          pageNo: currentPage + 1,
          perPage: gridParams.perPage,
        },
        tabData,
        'slider',
      )
        .then((resp) => {
          taskDataChunk = [...taskDataChunk, ...resp.data.task];
          setTaskDataChunk([...taskDataChunk]);
          if (resp.data?.task.length) {
            if (taskDataChunk[currentRecordRef.current]?.taskId) {
              return api
                .get(
                  `/automation/get-task-detail/${
                    taskDataChunk[currentRecordRef.current || 0].taskId
                  }`,
                )
                .then((taskDetailResp) => {
                  setCurrentTaskData(taskDetailResp.data[0]);
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                });
            }
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      if (taskDataChunk[counter || 0]) {
        api
          .get(
            `/automation/get-task-detail/${taskDataChunk[counter || 0].taskId}`,
          )
          .then((taskDetailResp) => {
            setCurrentTaskData(taskDetailResp.data[0]);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  // first api call with the first task for pagination filter
  useEffect(() => {
    if (openDrawer) {
      getTaskDetail();
    }
  }, [openDrawer]);

  // this will show next task page oin the pagination and page number
  const handleNextClick = () => {
    // next api call will be hear
    currentRecord = currentRecord + 1;
    currentRecordRef.current = currentRecord;
    getTaskDetail(currentRecord);
    setCurrentRecord(currentRecord);
  };

  // edit task to open slider
  const handleEditPopup = () => {
    setPopupToggle(true);
  };

  // edit task check the data is update for start next task
  const handlePopupClose = (data) => {
    setPopupToggle(false);
    if (data) {
      handleNextClick();
    }
  };

  return (
    <div>
      <CustomDrawer
        anchor={'right'}
        open={openDrawer}
        onClose={onClose}
        className={classes.startTaskDrawerMain}
      >
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={`Completing Task ${currentRecord + 1} of ${
            taskCount || 0
          }`}
          elementLeftToCloseIcon={
            <>
              {currentRecord + 1 < taskCount && (
                <OutlinedButton onClick={handleNextClick}>Next</OutlinedButton>
              )}
            </>
          }
        >
          {loading ? (
            <Spinner style={{ width: 40, height: 40 }} />
          ) : (
            <StartTaskView
              handleEditPopupOpen={handleEditPopup}
              taskData={currentTaskData}
              setCurrentTaskData={setCurrentTaskData}
            />
          )}
        </DrawerFormHeader>
      </CustomDrawer>

      {currentTaskData?.taskId && (
        <CreateMyTask
          openDrawer={popupToggle}
          dropDownValues={myTask.filterList}
          onClose={handlePopupClose}
          task={currentTaskData}
        />
      )}

      {/*<Snackbar*/}
      {/*  open={openSnackBar}*/}
      {/*  autoHideDuration={2000}*/}
      {/*  onClose={handleSnackbarClose}*/}
      {/*  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}*/}
      {/*>*/}
      {/*  <Alert*/}
      {/*    elevation={6}*/}
      {/*    variant="filled"*/}
      {/*    onClose={handleSnackbarClose}*/}
      {/*    severity="success"*/}
      {/*  >*/}
      {/*    {task?.taskId ? 'Task update successfully!' : 'New Task added!'}*/}
      {/*  </Alert>*/}
      {/*</Snackbar>*/}
    </div>
  );
};

export default StartTaskDrawer;
