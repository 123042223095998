
export const getSequenceColor = (seq) => {
  let color = '';

  switch (seq.status) {
    case 1:
      color = '#d1f4ef';
      break;
    case 4:
      color = '#f1e8bf';
      break;
    case 3:
      color = '#eaeaea';
      break;
    default:
      color = '';
      break;
  }
  return color;
};
