import React, { useState, useEffect } from 'react';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';
import FacebookIcon from '@material-ui/icons/Facebook';

import PageHeader from '../../../components/PageHeader';
import Card from '../../../components/Card';
import PageSearchBox from '../../../components/SearchBox/PageSearchBox';
import IntegrationDrawer from '../IntegrationDrawer';
import TwilioIntegrationDrawer from '../TwilioIntegration/TwilioDrawer';
import BullhornDrawer from '../BullhornIntegration/BullhornDrawer';
import CommonIntegration from '../CommonIntegration/CommonIntegrationDrawer';
import Spinner from '../../../layout/Spinner';
import SnackBar from '../../../components/CustomSnackBar';

import { fetchIntegration } from '../../../actions/admin/integration';
import { convertQueryString } from '../../../utils/convertQueryString';
import api from '../../../utils/api';

import BullhornIcon from '../../../../../img/icons/bullhorn-icon.svg';
import TwilioIcon from '../../../../../img/icons/twilio-icon.svg';
import LushaIcon from '../../../../../img/icons/lusha-icon.svg';
import { colorCodeConstant } from '../../../utils/styleConstant';

// IntegrationList styling
const useStyles = makeStyles(({ custom }) => ({
  integrationListMainSection: {},
  integrationsCardsWrapper: {
    padding: '10px 0 20px 0',
    margin: '10px 0',
  },
  mainCardIntegrations: {
    height: '100%',
    // boxShadow: 'unset',
    // border: `2px solid ${custom.colorCode.blueLightShade5}`,
  },
  cardContentIntegrationCard: {
    paddingBottom: 16,
    height: '100%',
    position: 'relative',
  },
  cardHeader: {
    marginBottom: 10,
    '& .title': {
      fontSize: 20,
      fontWeight: 500,
    },
    '& .value': {
      fontSize: 13,
      fontWeight: 300,
      color: custom.colorCode.pinkShade1,
    },
  },
  gridContent: {
    marginBottom: 30,
  },
  gridContentRowData: {
    fontSize: 16,
    fontWeight: 300,
  },
  gridFooterRowData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .installation': {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      color: custom.colorCode.lightGreyColorShade5,
    },
    '& .connect': {
      cursor: 'pointer',
      fontSize: 13,
      fontWeight: 300,
      color: custom.colorCode.pinkShade1,
      '& .manage-link': {
        color: '#5780ab',
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
  },
  cardFooter: {
    bottom: 24,
    position: 'absolute',
    width: 'calc(100% - 34px)',
  },
  cardImageHeader: {
    marginBottom: 10,
    '& svg': {
      width: 50,
      height: 50,
    },
  },
  integrationPageSubHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
    '& .first': {},
    '& .second': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  connectIntegrationDialog: {
    textAlign: 'center',
  },
  connectIntegrationDialogImg: {
    marginBottom: 10,
    '& img': {},
  },
  connectIntegrationDialogText: {
    color: custom.colorCode.fontBlack,
  },
  noMatch: {
    display: 'flex',
    fontSize: 14,
    justifyContent: 'center',
  },
}));

// This component will show the tools
// which can be configured with your application
const IntegrationList = (props) => {
  const classes = useStyles();

  const [drawerToggle, setDrawerToggle] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [integrationList, setIntegrationList] = useState([]);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  const [selectedIntegrationData, setSelectedIntegrationData] = useState({});
  const [
    integrationSelectedDataList,
    setIntegrationSelectedDataList,
  ] = useState({});
  const [showTwilioDrawer, setShowTwilioDrawer] = useState(false);
  const [showBullhornDrawer, setShowBullhornDrawer] = useState(false);
  const [showIntegrationDrawer, setShowIntegrationDrawer] = useState(false);
  const [manageAnchorElement, setManageAnchorElement] = useState(null);
  const [searchBoxValue, setSearchBoxValue] = useState('');

  const dispatch = useDispatch();
  const integration = useSelector((state) => state.integration);

  useEffect(() => {
    // did mount
    // call the integration api and set data in reducer
    dispatch(fetchIntegration(true));
  }, []);

  // this useeffect will be use to store data in the state for filtration
  useEffect(() => {
    if (integration.integrationList?.length) {
      setIntegrationList(integration.integrationList);
    }
  }, [integration.integrationList]);

  useEffect(() => {
    if (integration.listMounted) {
      const searchData = convertQueryString(props.history.location.search);
      if (searchData.integration) {
        const selectedData = integration.integrationList.find((i) =>
          i.plugin_name.toLocaleLowerCase().includes(searchData.integration),
        );
        if (selectedData && selectedData.status && !selectedData.ads_account) {
          setDrawerToggle(true);
          setSelectedIntegrationData(selectedData);
        }
      }
    }
  }, [integration.listMounted]);

  // handle function to close add/edit integration drawer
  const handleDrawerClose = () => {
    setDrawerToggle(false);
    setSelectedIntegrationData({});
  };

  // close twilio drawer
  const handleTwilioDrawerClose = () => {
    setShowTwilioDrawer(false);
  };

  // close bullhorn drawer
  const handleBullhornDrawerClose = () => {
    setShowBullhornDrawer(false);
    setIntegrationSelectedDataList({});
  };

  // close integration common drawer
  const handleIntegrationDrawerClose = () => {
    setShowIntegrationDrawer(false);
    setIntegrationSelectedDataList({});
  };

  // handle click on the inter data and redirect if not connected
  const handleConnectClick = (integrationData) => (event) => {
    switch (integrationData.plugin_name.toLocaleLowerCase()) {
      case 'facebook': {
        location.href = `${location.origin}/admin/integrations/fblogin`;
        break;
      }
      case 'bullhorn': {
        // show bullhorn slider
        setShowBullhornDrawer(true);
        setIntegrationSelectedDataList(integrationData);
        break;
      }
      case 'lusha': {
        // show Lush slider
        setShowIntegrationDrawer(true);
        setIntegrationSelectedDataList(integrationData);
        break;
      }
      case 'twilio': {
        // show bullhorn slider
        setShowTwilioDrawer(true);
        break;
      }
      default: {
        break;
      }
    }
  };

  // close the menage menu item
  const handleManageMenuClose = (selectedData) => (event) => {
    if (selectedData) {
      if (selectedData.plugin_name.toLocaleLowerCase() === 'facebook') {
        if (event.target.id === 'add-account') {
          setDrawerToggle(true);
          setSelectedIntegrationData(selectedData);
        }
      }
      if (selectedData.plugin_name.toLocaleLowerCase() === 'bullhorn') {
        if (event.target.id === 'manage-account') {
          props.history.push(`/admin/integrations/detail/${selectedData.id}`);
        }
      }
      if (event.target.id === 'dis-connect') {
        // Remove connect account for the application
        api
          .delete(`/integrations/delink/${selectedData.id}`)
          .then((resp) => {
            setOpenSnackBar(true);
            setOpenSnackBarMessage({
              message: 'Account Disconnect successfully',
              status: 'success',
            });
            setTimeout(() => {
              dispatch(fetchIntegration());
            }, 10);
          })
          .catch((error) => {
            setOpenSnackBar(true);
            setOpenSnackBarMessage({
              message: error?.response?.data?.message || 'Something went wrong',
              status: 'error',
            });
          });
      }
    }
    setManageAnchorElement(null);
    setIntegrationSelectedDataList({});
  };

  // handle manage click to open menu options
  const handleMangeClick = (selectedData) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIntegrationSelectedDataList(selectedData);
    setManageAnchorElement(event.currentTarget);
  };

  // this will close the snackbar
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  // this will handle input box change in search
  const handleSearch = (event) => {
    setSearchBoxValue(event.target.value);

    const newIntegrationList = integration.integrationList.filter((iData) => {
      return event.target.value
        ? (iData.plugin_name || '')
            .toLocaleLowerCase()
            .includes(event.target.value.toLocaleLowerCase())
        : true;
    });
    setIntegrationList(newIntegrationList);
  };

  // Show loader
  if (integration.getListLoading) {
    return <Spinner />;
  }

  // to show the dynamic icon with switch
  const integrationIcon = (interData) => {
    let interIcon = <></>;

    switch ((interData.plugin_name || '').toLocaleLowerCase()) {
      case 'facebook':
        interIcon = (
          <FacebookIcon
            fontSize={'large'}
            style={{ fill: colorCodeConstant.fbIconColor }}
          />
        );
        break;
      case 'bullhorn':
        interIcon = (
          <img src={BullhornIcon} alt="Bullhorn" height="50" width="50" />
        );
        break;
      case 'twilio':
        interIcon = (
          <img src={TwilioIcon} alt="Twilio" height="50" width="50" />
        );
        break;
      case 'lusha':
        interIcon = (
          <img src={LushaIcon} alt="Lusha" height="50" width="50" />
        );
        break;
      // eslint-disable-next-line no-fallthrough
      default:
        interIcon = <></>;
    }

    return interIcon;
  };

  const getMenuListItem = (integrationItem) => {
    let menuItems = [];

    switch (integrationItem.plugin_name.toLocaleLowerCase()) {
      case 'facebook': {
        !integrationItem.ads_account &&
          menuItems.push({
            id: 'add-account',
            title: 'Add Account',
            click: handleManageMenuClose(integrationItem),
          });
        menuItems.push({
          id: 'dis-connect',
          title: 'Disconnect',
          click: handleManageMenuClose(integrationItem),
        });
        break;
      }
      case 'bullhorn': {
        menuItems = [
          {
            id: 'manage-account',
            title: 'Manage Account',
            click: handleManageMenuClose(integrationItem),
          },
          {
            id: 'dis-connect',
            title: 'Disconnect',
            click: handleManageMenuClose(integrationItem),
          },
        ];
        break;
      }
      case 'twilio': {
        menuItems = [
          {
            id: 'dis-connect',
            title: 'Disconnect',
            click: handleManageMenuClose(integrationItem),
          },
        ];
        break;
      }
      case 'lusha': {
        menuItems = [
          {
            id: 'dis-connect',
            title: 'Disconnect',
            click: handleManageMenuClose(integrationItem),
          },
        ];
        break;
      }
      default: {
        break;
      }
    }

    return menuItems;
  };

  return (
    <div className={classes.integrationListMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Integrations'}
        subTitle={'View and connect application against your account.'}
        itemCount={integrationList?.length || 0}
      />

      <div className={classes.integrationPageSubHeaderWrapper}>
        <div className="first">
          <PageSearchBox
            placeholder="Search Integration"
            handleChange={handleSearch}
            value={searchBoxValue}
          />
        </div>

        <div className="second" />
      </div>

      <div className={classes.integrationsCardsWrapper}>
        <Grid container spacing={4}>
          {/*show the list of card to the user*/}
          {integrationList.map((interList, index) => (
            <Grid
              item
              xs={8}
              sm={4}
              key={`interList-${interList.mainTitle}-${interList.id}-${index}`}
            >
              <Card
                className={classes.mainCardIntegrations}
                cardContent={{
                  className: classes.cardContentIntegrationCard,
                  children: (
                    <>
                      <div className={classes.cardImageHeader}>
                        {integrationIcon(interList)}
                      </div>

                      <div className={classes.cardHeader}>
                        <div className="title">{interList.plugin_name}</div>
                        {/*<div className="value">seqCardData.value</div>*/}
                      </div>

                      <div className={classes.gridContent}>
                        <div className={classes.gridContentRowData}>
                          {interList.description}
                        </div>
                      </div>

                      <div className={classes.cardFooter}>
                        <div className={classes.gridFooterRowData}>
                          {/*status*/}
                          {interList.status ? (
                            <div
                              className="installation"
                              style={{ cursor: 'unset' }}
                            >
                              Connected
                            </div>
                          ) : (
                            <div
                              className="installation"
                              onClick={handleConnectClick(interList)}
                            >
                              Connect <ChevronRightIcon fontSize={'small'} />
                            </div>
                          )}

                          <div className="connect">
                            {interList.status && (
                              <div
                                onClick={handleMangeClick(interList)}
                                className="manage-link"
                              >
                                Manage
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                }}
              />
            </Grid>
          ))}
          {manageAnchorElement && (
            <Menu
              anchorEl={manageAnchorElement}
              id={'integration-list-manage-link'}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(manageAnchorElement)}
              onClose={handleManageMenuClose()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {getMenuListItem(integrationSelectedDataList).map(
                (menuItem, index) => (
                  <MenuItem
                    key={menuItem.id + index}
                    id={menuItem.id}
                    onClick={menuItem.click}
                  >
                    {menuItem.title}
                  </MenuItem>
                ),
              )}
            </Menu>
          )}

          {!integrationList.length && (
            <Grid item sm={12} className={classes.noMatch}>
              Sorry, no matching records found
            </Grid>
          )}
        </Grid>
      </div>

      <IntegrationDrawer
        integrationData={selectedIntegrationData}
        openDrawer={drawerToggle}
        onClose={handleDrawerClose}
      />
      <BullhornDrawer
        integrationData={integrationSelectedDataList}
        openDrawer={showBullhornDrawer}
        onClose={handleBullhornDrawerClose}
      />
      <CommonIntegration
        integrationData={integrationSelectedDataList}
        openDrawer={showIntegrationDrawer}
        onClose={handleIntegrationDrawerClose}
      />
      <TwilioIntegrationDrawer
        integrationData={integrationSelectedDataList}
        openDrawer={showTwilioDrawer}
        onClose={handleTwilioDrawerClose}
      />
      <SnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </div>
  );
};

export default IntegrationList;
