import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import EditIcon from '@material-ui/icons/Edit';

import UserInfo from '../../admin/myTask/StartTaskDrawer/UserInfo';
import Collapse from '../../components/Collapse';
import ContactDetail from '../../admin/myTask/StartTaskDrawer/ContactDetail';
import ProfileDetail from './ProfileDetail';
import AssignDetail from './AssignDetail';
import ProfileEditDrawer from './ProfileEditDrawer';
import api from '../../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
  },
  profileViewWrapper: {
    padding: 16,
  },
  collapseWrapper: {
    marginTop: '2.25em',
  },
  collapseHeaderText: {
    fontWeight: 'bold',
  },
  collapseEditText: {
    marginLeft: 16,
    fontSize: 16,
    color: custom.colorCode.blueLightShade8,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  contentWrapper: {
    borderBottom: `1px solid ${custom.colorCode.lightGreyColorShade23}`,
  },
  collapseContentWrapper: {
    paddingTop: 8,
    paddingBottom: 20,
  },
  titleWrapper: {
    '& svg': {
      marginLeft: 8,
      fill: custom.colorCode.lightGreyColorShade8,
      cursor: 'pointer',
    },
  },
}));

const SucceededProfile = ({
  userData,
  profileType,
  profileId,
  profileDropDownData,
}) => {
  const classes = useStyles();

  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [assignLoading, setAssignLoading] = useState(true);
  const [contactProfileData, setContactProfileData] = useState({});
  const [assignUser, setAssignUser] = useState([]);
  const [drawerToggle, setDrawerToggle] = useState(false);
  const [drawerType, setDrawerType] = useState({});

  useEffect(() => {
    if (profileId) {
      if (profileType === 'candidate') {
        api
          .get(`/candidate/get-contact-profile/${profileId}`)
          .then((resp) => {
            setContactProfileData(resp.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else if (profileType === 'contact') {
        // api
        //   .get(`/candidate/get-contact-profile/${profileId}`)
        //   .then((resp) => {
        //     setContactProfileData(resp.data);
        //     setLoading(false);
        //   })
        //   .catch(() => {
        //     setLoading(false);
        //   });
        setLoading(false);
      }
    }
  }, [profileId]);

  useEffect(() => {
    getProfileData();
  }, [userData]);

  const getProfileData = () => {
    if (!isEmpty(userData) && profileType) {
      if (profileType === 'candidate') {
        setProfileData({
          commonName: `${userData?.candidate_details?.fname || ''} ${
            userData?.candidate_details?.lastname || ''
          }`,
          jobTitle: userData?.workhistory?.job_title || '',
          commonAccName: userData?.workhistory?.employer || '',
          userImg: userData?.workhistory?.imageUrl || '',
          bhId: userData.bh_id,
          bhURL: userData.bh_url,
          userId: profileId,
          ...userData,
        });

        api
          .get(`/candidate/get-assigend-user?candidate_id=${profileId}`)
          .then((resp) => {
            setAssignUser(resp.data?.details_list || []);
            setAssignLoading(false);
          })
          .catch(() => {
            setAssignLoading(false);
          });
      } else if (profileType === 'contact') {
        setProfileData({
          commonName: `${userData.fname} ${userData.lname}`,
          jobTitle: userData.job_title,
          commonAccName: userData.company_name,
          userImg: userData.imageUrl,
          bhId: userData.bh_id,
          bhURL: userData.bh_url,
          userId: userData.company_user_id,
          ...userData,
        });
        api
          .get(`/dm/get-dm-assigned-user/${profileId}`)
          .then((resp) => {
            setAssignUser(resp.data?.details_list || []);
            setAssignLoading(false);
          })
          .catch(() => {
            setAssignLoading(false);
          });
      }
    }
  };

  /**
   * Handle function to close the drawer
   */
  const handlePopupClose = (data) => {
    setDrawerToggle(false);
    if (data.data) {
      const oldProfileData = profileData;
      setProfileData({});

      setTimeout(() => {
        setProfileData({ ...oldProfileData });
      });
      api.get(`/candidate/get-contact-profile/${profileId}`).then((resp) => {
        setContactProfileData(resp.data);
      });
    }
  };

  const handleEditClick = (data) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    setDrawerType(data);
    setDrawerToggle(true);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.profileViewWrapper}>
        <UserInfo
          userName={profileData.commonName}
          type={profileType === 'candidate' ? 'Candidate' : 'Contact'}
          position={profileData.jobTitle}
          region={profileData.commonAccName}
          imgUrl={profileData.userImg}
          bhUrl={profileData.bhId}
          bhId={profileData.bhURL}
        />

        <Box className={classes.collapseWrapper}>
          <Box className={classes.contentWrapper}>
            <Collapse
              title={
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.titleWrapper}
                >
                  Contact Details{' '}
                  <EditIcon
                    onClick={handleEditClick({ name: 'contact' })}
                    fontSize="small"
                  />
                </Box>
              }
              defaultOpen
              timeout="auto"
              unmountOnExit
            >
              <Box className={classes.collapseContentWrapper}>
                {!isEmpty(profileData) && (
                  <ContactDetail
                    taskData={{
                      dmId: profileType === 'contact' && profileData.userId,
                      candidateId:
                        profileType === 'candidate' && profileData.userId,
                    }}
                  />
                )}
              </Box>
            </Collapse>
          </Box>

          {profileType === 'candidate' && (
            <Box className={classes.contentWrapper}>
              <Collapse
                title={
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.titleWrapper}
                  >
                    Profile{' '}
                    <EditIcon
                      onClick={handleEditClick({ name: 'profile' })}
                      fontSize="small"
                    />
                  </Box>
                }
                defaultOpen
                timeout="auto"
                unmountOnExit
              >
                <Box className={classes.collapseContentWrapper}>
                  <ProfileDetail
                    profileDetail={contactProfileData}
                    loading={loading}
                  />
                </Box>
              </Collapse>
            </Box>
          )}

          <Box className={classes.contentWrapper}>
            <Collapse title={'Assign'} defaultOpen>
              <Box className={classes.collapseContentWrapper}>
                <AssignDetail
                  profileId={profileId}
                  assignData={assignUser}
                  loading={assignLoading}
                />
              </Box>
            </Collapse>
          </Box>

          {/*<Box className={classes.contentWrapper}>*/}
          {/*  <Collapse title={'Assign'} defaultOpen>*/}
          {/*    <Box className={classes.collapseContentWrapper}>*/}
          {/*      <ProfileDetail />*/}
          {/*    </Box>*/}
          {/*  </Collapse>*/}
          {/*</Box>*/}
          <ProfileEditDrawer
            drawerType={drawerType}
            openDrawer={drawerToggle}
            profileType={profileType}
            profileId={profileId}
            profileDetail={contactProfileData}
            profileDropDownData={profileDropDownData}
            onClose={handlePopupClose}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SucceededProfile;
