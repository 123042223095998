import React, { useEffect, useState } from 'react';
import { Box, Grid, Menu, MenuItem } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../../components/Card';
import PageHeader from '../../components/PageHeader';
import PageSearchBox from '../../components/SearchBox/PageSearchBox';
import IntegrationDrawer from '../../admin/integrations/IntegrationDrawer';
import SnackBar from '../../components/CustomSnackBar';
import Spinner from '../../layout/Spinner';

import { fetchIntegration } from '../../actions/admin/profileIntegration';
import { convertQueryString } from '../../utils/convertQueryString';
import api from '../../utils/api';

import Office365 from '../../../../img/icons/office-365.png';
import Gmail from '../../../../img/icons/gmail.png';

// IntegrationList styling
const useStyles = makeStyles(({ custom }) => ({
  integrationListMainSection: {},
  integrationsCardsWrapper: {
    padding: '10px 0 20px 0',
    margin: '10px 0',
  },
  mainCardIntegrations: {
    height: '100%',
    // boxShadow: 'unset',
    // border: `2px solid ${custom.colorCode.blueLightShade5}`,
  },
  cardContentIntegrationCard: {
    paddingBottom: 16,
    height: '100%',
    position: 'relative',
  },
  cardHeader: {
    marginBottom: 10,
    '& .title': {
      fontSize: 20,
      fontWeight: 500,
    },
    '& .value': {
      fontSize: 13,
      fontWeight: 300,
      color: custom.colorCode.pinkShade1,
    },
  },
  gridContent: {
    marginBottom: 30,
  },
  gridContentRowData: {
    fontSize: 16,
    fontWeight: 300,
  },
  gridFooterRowData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .installation': {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      color: custom.colorCode.lightGreyColorShade5,
    },
    '& .connect': {
      cursor: 'pointer',
      fontSize: 13,
      fontWeight: 300,
      color: custom.colorCode.pinkShade1,
      '& .manage-link': {
        color: '#5780ab',
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
  },
  cardFooter: {
    bottom: 24,
    position: 'absolute',
    width: 'calc(100% - 34px)',
  },
  cardImageHeader: {
    marginBottom: 10,
    '& svg': {
      width: 50,
      height: 50,
    },
  },
  integrationPageSubHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
    '& .first': {},
    '& .second': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  connectIntegrationDialog: {
    textAlign: 'center',
  },
  connectIntegrationDialogImg: {
    marginBottom: 10,
    '& img': {},
  },
  connectIntegrationDialogText: {
    color: custom.colorCode.fontBlack,
  },
  noMatch: {
    display: 'flex',
    fontSize: 14,
    justifyContent: 'center',
  },
}));

// This component will show the tools for the particular user only
// which can be configured with your user use
const ProfileIntegrationList = (props) => {
  const classes = useStyles();

  const [drawerToggle, setDrawerToggle] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [integrationList, setIntegrationList] = useState([]);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  const [selectedIntegrationData, setSelectedIntegrationData] = useState({});
  const [
    integrationSelectedDataList,
    setIntegrationSelectedDataList,
  ] = useState({});
  const [manageAnchorElement, setManageAnchorElement] = useState(null);
  const [searchBoxValue, setSearchBoxValue] = useState('');

  const dispatch = useDispatch();
  const integration = useSelector((state) => state.profileIntegration);
  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    // did mount
    // call the integration api and set data in reducer
    dispatch(fetchIntegration(true));
  }, []);

  // this useeffect will be use to store data in the state for filtration
  useEffect(() => {
    if (integration.integrationList?.length) {
      setIntegrationList(integration.integrationList);
    }
  }, [integration.integrationList]);

  useEffect(() => {
    if (integration.listMounted) {
      const searchData = convertQueryString(
        props.history?.location?.search || '',
      );
      if (searchData.integration) {
        const selectedData = integration.integrationList.find((i) =>
          i.plugin_name.toLocaleLowerCase().includes(searchData.integration),
        );
        if (selectedData && selectedData.status && !selectedData.ads_account) {
          setDrawerToggle(true);
          setSelectedIntegrationData(selectedData);
        }
      }
    }
  }, [integration.listMounted]);

  // handle function to close add/edit integration drawer
  const handleDrawerClose = () => {
    setDrawerToggle(false);
    setSelectedIntegrationData({});
  };

  // handle click on the inter data and redirect if not connected
  const handleConnectClick = (integrationData) => (event) => {
    switch (integrationData.plugin_name.toLocaleLowerCase()) {
      case 'gmail': {
       /*  api
          .get(`/integrations/nylas/connect?provider=gmail`)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          }); */

        //  location.href = `${location.origin}/integrations/nylas/connect?provider=gmail&userName=${userData?.username || ''}`;
        location.href = integrationData.provider_url;
        break;
      }
      case 'office365': {
        /* api
          .get(`/integrations/nylas/connect?provider=office365`)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          }); */

        // location.href = `${location.origin}/integrations/nylas/connect?provider=office365&userName=${userData?.username || ''}`;
        location.href = integrationData.provider_url;
        break;
      }
      // case 'lusha': {
      //   // show Lush slider
      //   setShowIntegrationDrawer(true);
      //   setIntegrationSelectedDataList(integrationData);
      //   break;
      // }
      default: {
        break;
      }
    }
  };

  // close the menage menu item
  const handleManageMenuClose = (selectedData) => (event) => {
    if (selectedData) {
      if (
        selectedData.plugin_name.toLocaleLowerCase() === 'office365' ||
        selectedData.plugin_name.toLocaleLowerCase() === 'gmail'
      ) {
        /* if (event.target.id === 'add-account') {
          setDrawerToggle(true);
          setSelectedIntegrationData(selectedData);
        } */
      }
      if (event.target.id === 'dis-connect') {
        // Remove connect account for the application
        const payload = {
          plugin_scope: 'user',
          plugin_name: selectedData.plugin_name,
        };
        api
          .delete(`/integrations/nylas-revoke/${selectedData.plugin_auth_id}?plugin_scope=user&plugin_name=${selectedData.plugin_name}`)
          .then((resp) => {
            setOpenSnackBar(true);
            setOpenSnackBarMessage({
              message: 'Account Disconnect successfully',
              status: 'success',
            });
            setTimeout(() => {
              dispatch(fetchIntegration());
            }, 10);
          })
          .catch((error) => {
            setOpenSnackBar(true);
            setOpenSnackBarMessage({
              message: error?.response?.data?.message || 'Something went wrong',
              status: 'error',
            });
          });
      }
    }
    setManageAnchorElement(null);
    setIntegrationSelectedDataList({});
  };

  // handle manage click to open menu options
  const handleMangeClick = (selectedData) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIntegrationSelectedDataList(selectedData);
    setManageAnchorElement(event.currentTarget);
  };

  // this will close the snackbar
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  // this will handle input box change in search
  const handleSearch = (event) => {
    setSearchBoxValue(event.target.value);

    const newIntegrationList = integration.integrationList.filter((iData) => {
      return event.target.value
        ? (iData.plugin_name || '')
            .toLocaleLowerCase()
            .includes(event.target.value.toLocaleLowerCase())
        : true;
    });
    setIntegrationList(newIntegrationList);
  };

  // Show loader
  if (integration.getListLoading) {
    return <Spinner />;
  }

  // to show the dynamic icon with switch
  const integrationIcon = (interData) => {
    let interIcon = <></>;

    switch ((interData.plugin_name || '').toLocaleLowerCase()) {
      case 'gmail':
        interIcon = <img src={Gmail} alt="Gmail" height="50" width="50" />;
        break;
      case 'office365':
        interIcon = (
          <img src={Office365} alt="Office 365" height="50" width="50" />
        );
        break;
      // eslint-disable-next-line no-fallthrough
      default:
        interIcon = <></>;
    }

    return interIcon;
  };

  const getMenuListItem = (integrationItem) => {
    let menuItems = [];

    switch (integrationItem.plugin_name.toLocaleLowerCase()) {
      case 'gmail': {
        /* !integrationItem.ads_account &&
          menuItems.push({
            id: 'add-account',
            title: 'Add Account',
            click: handleManageMenuClose(integrationItem),
          }); */
        menuItems.push({
          id: 'dis-connect',
          title: 'Disconnect',
          click: handleManageMenuClose(integrationItem),
        });
        break;
      }
      case 'office365': {
        /* !integrationItem.ads_account &&
          menuItems.push({
            id: 'add-account',
            title: 'Add Account',
            click: handleManageMenuClose(integrationItem),
          }); */
        menuItems.push({
          id: 'dis-connect',
          title: 'Disconnect',
          click: handleManageMenuClose(integrationItem),
        });
        break;
      }
      default: {
        break;
      }
    }

    return menuItems;
  };

  return (
    <Box className={classes.integrationListMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Integrations'}
        subTitle={'View and connect application against your account.'}
        itemCount={integrationList?.length || 0}
      />

      <div className={classes.integrationPageSubHeaderWrapper}>
        <div className="first">
          <PageSearchBox
            placeholder="Search Integration"
            handleChange={handleSearch}
            value={searchBoxValue}
          />
        </div>

        <div className="second" />
      </div>

      <div className={classes.integrationsCardsWrapper}>
        <Grid container spacing={4}>
          {/*show the list of card to the user*/}
          {integrationList.map((interList, index) => (
            <Grid
              item
              xs={8}
              sm={4}
              key={`interList-${interList.mainTitle}-${interList.id}-${index}`}
            >
              <Card
                className={classes.mainCardIntegrations}
                cardContent={{
                  className: classes.cardContentIntegrationCard,
                  children: (
                    <>
                      <div className={classes.cardImageHeader}>
                        {integrationIcon(interList)}
                      </div>

                      <div className={classes.cardHeader}>
                        <div className="title">{interList.plugin_name}</div>
                        {/*<div className="value">seqCardData.value</div>*/}
                      </div>

                      <div className={classes.gridContent}>
                        <div className={classes.gridContentRowData}>
                          {interList.description}
                        </div>
                      </div>

                      <div className={classes.cardFooter}>
                        <div className={classes.gridFooterRowData}>
                          {/*status*/}
                          {interList.status === 1 ? (
                            <div
                              className="installation"
                              style={{ cursor: 'unset' }}
                            >
                              Connected
                            </div>
                          ) : (
                            <div
                              className="installation"
                              onClick={handleConnectClick(interList)}
                            >
                              Connect <ChevronRightIcon fontSize={'small'} />
                            </div>
                          )}

                          <div className="connect">
                            {interList.status === 1 && (
                              <div
                                onClick={handleMangeClick(interList)}
                                className="manage-link"
                              >
                                Manage
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                }}
              />
            </Grid>
          ))}
          {manageAnchorElement && (
            <Menu
              anchorEl={manageAnchorElement}
              id={'integration-list-manage-link'}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(manageAnchorElement)}
              onClose={handleManageMenuClose()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {getMenuListItem(integrationSelectedDataList).map(
                (menuItem, index) => (
                  <MenuItem
                    key={menuItem.id + index}
                    id={menuItem.id}
                    onClick={menuItem.click}
                  >
                    {menuItem.title}
                  </MenuItem>
                ),
              )}
            </Menu>
          )}

          {!integrationList.length && (
            <Grid item sm={12} className={classes.noMatch}>
              Sorry, no matching records found
            </Grid>
          )}
        </Grid>
      </div>

      <IntegrationDrawer
        integrationData={selectedIntegrationData}
        openDrawer={drawerToggle}
        onClose={handleDrawerClose}
      />

      <SnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ''}
        severity={openSnackBarMessage?.status || 'success'}
      />
    </Box>
  );
};

export default ProfileIntegrationList;
