import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchLeads } from '../actions/lead';
import { createTab, fetchTabs } from '../actions/tabs';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GridData from './GridData';
import GridTabs from '../controlled-component/grid-component/GridTabs';

import PageHeader from '../components/PageHeader';

const Leadgrid = (props) => {
  const { match, history, tabs } = props;
  const { params } = match;
  const { tab } = params;

  //Calling api to get lead tabs
  useEffect(() => {
    props.fetchTabs('lead');
  }, [props.fetchTabs]);

  const [counts, setCounts] = useState(0);
  const [tabrecords, setTabrecords] = useState([]);
  const [tabvalue, setTabvalue] = useState(Number(tab));

  //Tab handle change function
  const handleChange = (event, newValue) => {
    setTabvalue(newValue);
    history.push(`/leads/views/${newValue}`);
  };

  /**
   * Handle function when select tab from all views popup
   */
  const handleTabClick = (newValue, handleClose) => {
    /* let tabrecords = tabs.tabs.length < 5 ? tabs.tabs : [];
    if (tabs.tabs.length > 4) {
      //if tabs are more than 5 then pick only 5 tabs
      for (let i = 0; i < 5; i++) {
        tabrecords.push(tabs.tabs[i]);
      }
      //check selected tab in first 5 tabs
      const selectedTab = tabrecords.find(
        (element) => element.id === Number(newValue),
      );
      //if selected tab not found in first 5 tabs then remove last tab and add selected tab
      if (selectedTab === undefined) {
        const selectedTab = tabs.tabs.find(
          (element) => element.id === Number(newValue),
        );
        tabrecords.pop();
        tabrecords.push(selectedTab);
      }
    }
    setTabrecords(tabrecords); */
    morphedTabs(newValue, tabs.tabs);
    handleClose();
    setTabvalue(newValue);
    history.push(`/leads/views/${newValue}`);
  };

  /**
   * Function to handle save new view of this grid
   * @param {*} values
   */
  const handlCreateNewView = (values, handleClose) => {
    let filters =
      props.lead.selected_filters.length > 0
        ? JSON.parse(props.lead.selected_filters)
        : [];

    const params = {
      ...values,
      gridParams: props.lead.grid_params,
      filters: filters,
    };
    props.createTab(params, history, handleClose, setTabvalue);
  };

  const morphedTabs = (newval, tabs) => {
    if (tabs.length > 0) {
      let tabrecords = tabs.length < 5 ? tabs : [];
      if (tabs.length > 4) {
        //if tabs are more than 5 then pick only 5 tabs
        for (let i = 0; i < 5; i++) {
          tabrecords.push(tabs[i]);
        }
        //check selected tab in first 5 tabs
        const selectedTab = tabrecords.find(
          (element) => element.id === Number(newval),
        );
        //if selected tab not found in first 5 tabs then remove last tab and add selected tab
        if (selectedTab === undefined) {
          const selectedTab = tabs.find(
            (element) => element.id === Number(newval),
          );
          tabrecords.pop();
          tabrecords.push(selectedTab);
        }
      }
      setTabrecords(tabrecords);
    }
  };

  /**
   * Hook to catch tabs responce and set in state variable
   */
  useEffect(() => {
    if (tabs.tabs.length > 0) {
      let tabval = tab ? tab : 1;
      morphedTabs(tabval, tabs.tabs);
      setTabvalue(tabval);
    }
  }, [tabs.tabs]);

  return (
    <div style={{ maxWidth: '100%' }}>
      <PageHeader mainTitle={'Leads'} subTitle={''} itemCount={counts} />

      {/*<section className="content-head">*/}
      {/*  <div className="Users_title">*/}
      {/*    <h1>*/}
      {/*      Leads <span className="text-muted">({counts})</span>*/}
      {/*    </h1>*/}
      {/*    /!* <p className="title_inner">*/}
      {/*      Use leads to automate your follows-ups and process.*/}
      {/*    </p> *!/*/}
      {/*  </div>*/}
      {/*</section>*/}

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
        <GridTabs
          labelId="lead-grid-tabs"
          selectionFollowsFocus
          onChange={handleChange}
          value={Number(tabvalue)}
          tabsdata={tabrecords}
          setTabvalue={setTabvalue}
          object={'lead'}
          tabs={tabs.tabs}
          handleTabClick={handleTabClick}
          handleSave={handlCreateNewView}
          {...props}
        />
      </div>
      <div style={{ maxWidth: '100%', float: 'left' }}>
        <GridData
          tabvalue={tabvalue}
          setTabvalue={setTabvalue}
          setCounts={setCounts}
          {...props}
        />
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

Leadgrid.propTypes = {
  auth: PropTypes.object.isRequired,
  fetchTabs: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lead: state.lead,
  tabs: state.tabs,
});

const mapDispatchToProps = {
  fetchTabs,
  fetchLeads,
  createTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(Leadgrid);
