import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu,
  MenuItem,
  Snackbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import PageHeader from '../../components/PageHeader';
import CustomDataGrid from '../../controlled-component/customGridComponent';
import AddOfficeDrawer from '../AddOfficeDrawer/index';
import OfficeCustomToolbar from './OfficeCustomToolbar';
import LoadingTableBody from '../../components/LoadingTableBody';
import { ActiveIcon, InActiveIcon } from '../../components/GridIcons';

import { fetchOffices } from '../../actions/admin/Offices';
import api from '../../utils/api';

import 'react-toastify/dist/ReactToastify.css';

// Office styling
const useStyles = makeStyles(() => ({
  officeGridMainSection: {},
  OfficeGridWrapper: {
    padding: '10px 0 20px 0',
  },
}));

const OfficesGrid = (props) => {
  const classes = useStyles();

  // Local state variables
  const [popupToggle, setPopupToggle] = useState(false);
  // const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);
  const [editOfficeData, setEditOfficeData] = useState(null);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const offices = useSelector((state) => state.adminOffices);

  // handle function to open/close add office pop up
  const handlePopupOpen = () => {
    setPopupToggle(!popupToggle);
  };

  // handle function to open/close add office pop up
  const handlePopupClose = () => {
    setPopupToggle(false);
    setEditOfficeData({});
  };

  // handle the edit office pop up
  const handleEditPopupOpen = (id) => {
    setPopupToggle(!popupToggle);
    setEditOfficeData(
      offices.officeList.find((office) => office.id === id) || {},
    );
  };

  // update status with reducer
  const updateStatus = (officeId, rowData) => {
    const payload = {
      status: rowData.status ? 0 : 1,
    };
    api
      .put(`/automation/offices/update-status/${officeId}`, payload)
      .then((resp) => {
        dispatch(fetchOffices());
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          status: 'success',
          message: rowData.status
            ? 'Office deactivate successfully.'
            : 'Office activate successfully.',
        });
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setOpenSnackBarMessage({
          status: 'error',
          message: error.response?.data?.error || 'Something went wrong',
        });
      });
  };

  useEffect(() => {
    // did mount
    // call the offices api and set data in reducer
    dispatch(fetchOffices());
  }, []);

  // open edit pop up when click on the name
  const handleNameClick = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    handleEditPopupOpen(data.id);
  };

  // close snake bar and remove error
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  // columns of the table
  const columns = useMemo(
    () => [
      {
        name: 'office_name',
        label: 'Office Name',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = offices.officeList[dataIndex];
            return (
              <Tooltip title={rData.office_name} arrow>
                <div
                  className={`sort-by td-content-ellipsis td-anchor-content-ellipsis`}
                  onClick={(e) => handleNameClick(e, rData)}
                >
                  {rData.office_name}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: 'town',
        label: 'City',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'country_name',
        label: 'Country',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'totalUsers',
        label: 'Users',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'status_label',
        label: 'Status',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return value === 'Active' ? (
              <ActiveIcon fontSize="small" />
            ) : (
              <InActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row =
              (offices?.officeList || []).find((tData) => tData.id === value) ||
              {};
            return (
              <TemplateGridActions
                temp_id={row?.id}
                handleEditPopupOpen={handleEditPopupOpen}
                status={row?.is_published}
                row={row}
                updatestatus={updateStatus}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [offices.officeList],
  );

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={offices.loading} {...props} />,
    [offices.loading],
  );

  /**
   * Return function to render and return UI elements
   */
  return (
    <div className={classes.officeGridMainSection}>
      {/*common page header*/}
      <PageHeader
        mainTitle={'Offices'}
        subTitle={'Create, edit and remove offices against your account.'}
        itemCount={offices?.officeList?.length || 0}
      />
      <div className={classes.OfficeGridWrapper}>
        <CustomDataGrid
          columns={columns}
          data={offices?.officeList || []}
          options={{
            pagination: Boolean(offices?.officeList?.length),
            selectableRows: 'multiple',
            filterType: 'dropdown',
            responsive: 'simple',
            searchPlaceholder: 'Search offices',
            selectToolbarPlacement: 'none',
            searchOpen: true,
            search: true,
            customToolbar: () => {
              // Toolbar for search and add
              return <OfficeCustomToolbar addOfficeClick={handlePopupOpen} />;
            },
          }}
          components={{ TableBody: BodyComponent }}
        />
        <AddOfficeDrawer
          openDrawer={popupToggle}
          onClose={handlePopupClose}
          editData={editOfficeData}
        />
      </div>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={openSnackBarMessage?.status || 'success'}
        >
          {openSnackBarMessage?.message || ''}
        </Alert>
      </Snackbar>
    </div>
  );
};

// custom grid action menu
const TemplateGridActions = (props) => {
  const { auth, row } = props;
  const { user } = auth;
  const admin = props.admin ? props.admin : false;
  const [anchorElement, setAnchorElement] = useState(null);

  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);
    switch (type) {
      case 'deactivateOffice': {
        props.updatestatus(props.temp_id, row);
        break;
      }
      case 'editOffice': {
        props.handleEditPopupOpen(props.temp_id);
        break;
      }
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: 'Edit',
      itemType: 'editOffice',
    },
    {
      title: 'Change Status',
      itemType: 'deactivateOffice',
    },
  ];

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default OfficesGrid;
