import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, isArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

import FormAutoCompleteSelect from '../../../components/FormAutoCompleteSelect';
import DatePicker from '../../../components/DateTimePickers/DatePicker';
import LookupAutoComplete from '../../../components/FormAutoCompleteSelect/LookupAutoComplete';
import Spinner from '../../../layout/Spinner';

import { fetchMyTask } from '../../../actions/admin/myTask';
import api from '../../../utils/api';
import { english } from '../../../utils/language';
import { getArrayOfTImeWithInterval } from '../../../utils/getTimeInternal';
import {
  getCandidatesLookup,
  getCandidatesDMLookup,
  getAssignToLookup,
} from '../../../actions/candidates';
import { convertHtmlToString } from '../../../utils/convertHtmlToString';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 145px)',
    overflow: 'auto',
  },
  formWrapper: {
    padding: '20px 40px',
  },
  formHeading: {
    fontSize: 20,
    fontWeight: 500,
    paddingBottom: 10,
  },
  formFooter: {
    fontWeight: 500,
    padding: '20px 40px',
    color: custom.colorCode.black,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
  },
  nextButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    backgroundColor: custom.colorCode.blueLightShade8,
    color: custom.colorCode.white,
    border: 'none',

    '&:hover': {
      border: `none`,
      backgroundColor: custom.colorCode.blueLightShade8,
    },
    '&:focus': {
      border: `none`,
      outline: 'none',
      backgroundColor: custom.colorCode.blueLightShade8,
    },
  },
}));

const MyTaskForm = (props) => {
  const classes = useStyles();

  const { editMyTask, closeDrawer, dropDownValues } = props;

  const userData = useSelector((state) => state.auth.user);

  // Local state variables
  const [formData, setFormData] = useState({
    dueTime: '09:00',
    dueDate: moment().format('YYYY-MM-DD'),
    assignedTo: !editMyTask?.taskId ? userData.id : '',
  });

  const [disabledButton, setDisabledButton] = useState(false);
  const [taskType, setTaskType] = useState(dropDownValues.taskTypeList || []);
  const [assignedToOption, setAssignedToOption] = useState(
    !editMyTask?.taskId
      ? [
          {
            id: userData.id,
            value: `${userData.fname} ${userData.lname}`,
          },
        ]
      : [],
  );
  const [candidatesOption, setCandidatesOption] = useState([]);
  const [priorityList, setPriorityList] = useState(
    dropDownValues.priorityList || [],
  );
  const [contactTypeList] = useState([
    {
      name: 'dm',
      title: 'Client Contact',
    },
    {
      name: 'contact',
      title: 'Candidate',
    },
  ]);
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [openErrorSnackBarMsg, setOpenErrorSnackBarMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  let [contactType, setContactType] = useState('');
  const contactTypeRef = useRef('');

  const currentTabState = useSelector((state) => state.myTask.currentTab);
  const myTaskFilterState = useSelector(
    (state) => state.myTask[currentTabState.id]?.filterState,
  );
  const gridParamsState = useSelector(
    (state) => state.myTask[currentTabState.id]?.gridParams,
  );

  useEffect(() => {
    if (editMyTask?.taskId) {
      setIsLoading(true);

      api
        .get(`/automation/get-task-detail/${editMyTask?.taskId}`)
        .then((resp) => {
          const editTaskData = resp.data[0];

          setFormData({
            taskType:
              taskType.find((taskT) => taskT.name === editTaskData.taskTypeName)
                ?.id || editTaskData.type_id,
            freeTextNotes: convertHtmlToString(editTaskData.free_text_notes),
            priority: editTaskData.priority,
            dueDate: moment(editTaskData.due_date).format('YYYY-MM-DD'),
            dueTime: editTaskData.due_time,
            assignedTo: editTaskData?.assigned_to,
            candidates: editTaskData.dmId || editTaskData.candidateId,
          });
          setContactType(editTaskData.dmId ? 'dm' : 'contact');
          contactTypeRef.current = editTaskData.dmId ? 'dm' : 'contact';
          setCandidatesOption([
            {
              id: editTaskData.dmId || editTaskData.candidateId,
              value: editTaskData.dmName || editTaskData.canName,
            },
          ]);
          setAssignedToOption([
            {
              id: editTaskData?.assigned_to,
              value: editTaskData?.assigned_to_name,
            },
          ]);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (dropDownValues) {
      setPriorityList([...(dropDownValues?.priorityList || [])]);
      setTaskType([...(dropDownValues?.taskTypeList || [])]);
    }
  }, [dropDownValues]);

  // Code to subscribe timezone, country and entity data from reducer for drop down
  const dispatch = useDispatch();

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = [
      'candidates',
      'taskType',
      'freeTextNotes',
      'priority',
      'assignedTo',
      'dueDate',
    ];
    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = isArray(formData[e]) ? isEmpty(formData[e]) : !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function handle change event of the inputs
   * @param {*} event
   * @returns
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Handle function of create/update team form
   * It call server API to save data into tables
   */
  const handleSubmit = () => {
    if (disabledNextButton() === false) {
      setDisabledButton(true);
      setIsLoading(true);

      // create payload for api
      const payload = {
        taskType: formData.taskType,
        candidateId: contactTypeRef.current === 'dm' ? '' : formData.candidates,
        dmId: contactTypeRef.current === 'dm' ? formData.candidates : '',
        freeTextNotes: formData.freeTextNotes,
        priority: formData.priority,
        dueDate: formData.dueDate
          ? moment(formData.dueDate).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        dueTime: formData.dueTime,
        assignedTo: formData.assignedTo,
        jobId: '',
        sequence_id: '',
        step_id: '',
        contact_id: '',
        noteType: '',
      };

      // hit edit api if id is present
      if (editMyTask?.taskId) {
        payload.taskId = editMyTask?.taskId;

        api
          .post(`/automation/my-task`, payload)
          .then(() => {
            closeDrawer({ taskCreated: true });
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);

            dispatch(
              fetchMyTask(myTaskFilterState, gridParamsState, currentTabState),
            );
            setIsLoading(false);
          })
          .catch((error) => {
            setOpenErrorSnackBar(true);
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);

            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
            setIsLoading(false);
          });
      } else {
        api
          .post('/automation/my-task', payload)
          .then((res) => {
            if (res) {
              closeDrawer({ taskCreated: true });
            }
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);

            dispatch(
              fetchMyTask({
                ...(myTaskFilterState || {}),
              }),
            );
            setIsLoading(false);
          })
          .catch((error) => {
            setOpenErrorSnackBar(true);
            setTimeout(() => {
              setDisabledButton(false);
            }, 10);
            setOpenErrorSnackBarMsg(
              error.response?.data?.error || 'Something went wrong',
            );
            setIsLoading(false);
          });
      }
    }
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    if (name === 'contactType') {
      setFormData({
        ...formData,
        candidates: [],
      });
      setTimeout(() => {
        contactTypeRef.current = tag[key];
        setContactType(tag[key]);
      }, 5);
      return;
    }
    if (key) {
      setFormData({
        ...formData,
        [name]: tag[key],
      });
    } else {
      setFormData({
        ...formData,
        [name]: tag,
      });
    }
  };

  /**
   * function to handle date and time change
   * @param {*} name
   * @returns
   */
  const handleDateTimeChange = (name) => (value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /**
   * function will call after the search of candidates
   * to set the options in the dropdown
   */
  const searchCandidates = async (search, lookupType) => {
    let res = [];

    if (lookupType === 'dm') {
      res = await getCandidatesDMLookup(search, []);
    } else if (lookupType === 'contact') {
      res = await getCandidatesLookup(search, []);
    } else if (lookupType === 'assignedTo') {
      res = await getAssignToLookup(search, 'id');
    }

    return res.data || [];
  };

  // time dropdown value
  const timeOptions = () => {
    const timeIntervalList = getArrayOfTImeWithInterval();
    return timeIntervalList.map((e) => ({
      name: e,
      value: e,
    }));
  };

  /**
   * function to close toaster after server side error
   */
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackBar(false);
  };

  // show loader before the api fetched the data
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> Task Details </h6>*/}
          <div>
            <Grid container spacing={2}>
              {/*Task Text*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Task Note *"
                  onChange={handleChange}
                  name="freeTextNotes"
                  value={formData?.freeTextNotes || ''}
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter task text']}
                />
              </Grid>

              {/*Task Type*/}
              <Grid item xs={6} sm={6}>
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('taskType', 'id')}
                  name="taskType"
                  id="taskType"
                  disableClearable
                  value={
                    formData.taskType
                      ? taskType.find((c) => c.id === formData?.taskType) || ''
                      : ''
                  }
                  options={[...(taskType || [])]}
                  getOptionLabel={(option) => option.name || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Task Type *"
                      value={formData.taskType}
                      name="type"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select task type']}
                    />
                  )}
                />
              </Grid>

              {/* Priority */}
              <Grid item xs={12} sm={6}>
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('priority', 'id')}
                  name="priority"
                  id="priority"
                  disableClearable
                  value={
                    formData.priority
                      ? priorityList.find((c) => c.id === formData?.priority) ||
                        ''
                      : ''
                  }
                  options={[...(priorityList || [])]}
                  getOptionLabel={(option) => option.name || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Priority *"
                      value={formData.priority}
                      name="priority"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select priority']}
                    />
                  )}
                />
              </Grid>

              {/*contact type*/}
              <Grid item xs={12} sm={6}>
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('contactType', 'name')}
                  name="contactType"
                  id="contactType"
                  disableClearable
                  value={
                    contactType
                      ? contactTypeList.find((c) => c.name === contactType) ||
                        ''
                      : ''
                  }
                  disabled={Boolean(editMyTask?.taskId)}
                  options={contactTypeList || []}
                  getOptionLabel={(option) => option.title || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Person Type *"
                      value={contactType}
                      name="contactType"
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Please select contact type']}
                    />
                  )}
                />
              </Grid>

              {/*contact*/}
              <Grid item xs={12} sm={6}>
                {contactType === 'dm' && (
                  <LookupAutoComplete
                    onChange={handleSelectChange('candidates', 'id')}
                    name="candidates"
                    id="candidates"
                    disableClearable
                    remoteMethod={(val) =>
                      searchCandidates(val, contactTypeRef.current)
                    }
                    options={candidatesOption}
                    getOptionLabel={(option) => option.value || ''}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    disabled={Boolean(editMyTask?.taskId)}
                    value={formData?.candidates}
                    inputProps={{
                      value: formData?.candidates || '',
                      label: 'Client Contact *',
                      name: 'dmcandidates',
                      variant: 'outlined',
                      fullWidth: true,
                      validators: ['required'],
                      errorMessages: [english.specTemplateForm.selectCandidate],
                    }}
                  />
                )}{' '}
                {contactType === 'contact' && (
                  <LookupAutoComplete
                    onChange={handleSelectChange('candidates', 'id')}
                    name="candidates"
                    id="candidates"
                    disableClearable
                    remoteMethod={(val) =>
                      searchCandidates(val, contactTypeRef.current)
                    }
                    disabled={Boolean(editMyTask?.taskId)}
                    options={candidatesOption}
                    getOptionLabel={(option) => option.value || ''}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={formData?.candidates}
                    inputProps={{
                      value: formData?.candidates || '',
                      label: 'Candidates *',
                      name: 'candidates',
                      variant: 'outlined',
                      fullWidth: true,
                      validators: ['required'],
                      errorMessages: [english.specTemplateForm.selectCandidate],
                    }}
                  />
                )}
              </Grid>

              {/*Assigned to*/}
              <Grid item xs={12} sm={12}>
                <LookupAutoComplete
                  onChange={handleSelectChange('assignedTo', 'id')}
                  name="assignedTo"
                  id="assignedTo"
                  disableClearable
                  remoteMethod={(val) => searchCandidates(val, 'assignedTo')}
                  options={assignedToOption}
                  getOptionLabel={(option) => option.value || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={formData?.assignedTo}
                  defaultValue={editMyTask?.taskId ? formData?.assignedTo : ''}
                  inputProps={{
                    value: formData?.assignedTo || '',
                    label: 'Assigned To *',
                    name: 'assignedTo',
                    variant: 'outlined',
                    fullWidth: true,
                    validators: ['required'],
                    errorMessages: ['Please select any assignee'],
                  }}
                />
              </Grid>

              {/*Due Date*/}
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label={'Due Date *'}
                  value={formData.dueDate}
                  variant={'outlined'}
                  onChange={handleDateTimeChange('dueDate')}
                />
              </Grid>

              {/*Due Time*/}
              <Grid item xs={12} sm={6}>
                <FormAutoCompleteSelect
                  onChange={handleSelectChange('dueTime', 'value')}
                  name="dueTime"
                  id="dueTime"
                  disableClearable
                  value={
                    formData.dueTime
                      ? timeOptions().find(
                          (c) => c.name === formData.dueTime,
                        ) || ''
                      : ''
                  }
                  options={timeOptions() || []}
                  getOptionLabel={(option) => option.name || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Due Time"
                      value={contactType}
                      name="dueTime"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div />
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledNextButton()}
            >
              {editMyTask?.taskId ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </ValidatorForm>
      <Snackbar
        open={openErrorSnackBar}
        autoHideDuration={2000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleErrorSnackbarClose}
          severity="error"
        >
          {openErrorSnackBarMsg || ''}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MyTaskForm;
