import React, { useState } from 'react';
import { Box, Typography, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BluetoothConnectedIcon from '@material-ui/icons/BluetoothConnected';
import MailIcon from '@material-ui/icons/Mail';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import ListIcon from '@material-ui/icons/List';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(({ custom }) => ({
  listViewTabWrapper: {},
  listViewTab: {
    textAlign: 'center',
    cursor: 'pointer',
    padding: '16px 8px 8px 8px',
    '&.active': {
      '& .MuiTypography-root': {
        color: custom.colorCode.blueLightShade14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& svg': {
        fill: custom.colorCode.blueLightShade14,
      },
    },
    '& svg': {
      fill: custom.colorCode.lightGreyColorShade16,
    },
  },
  listViewTabAction: {
    background: custom.colorCode.blueLightShade14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& .MuiTypography-root': {
      color: custom.colorCode.white,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& svg': {
      fill: custom.colorCode.white,
    },
  },
  listViewTabContainerWrapper: {
    borderBottom: `2px solid ${custom.colorCode.blueLightShade14}`,
  },
}));

const listTabItems = [
  {
    id: 1,
    title: 'Notes',
  },
  {
    id: 2,
    title: 'Mail',
  },
  {
    id: 3,
    title: 'Column',
  },
  {
    id: 4,
    title: 'List',
  },
];

const SucceededListView = () => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(1);

  const getItemIcon = (listTab) => {
    let icon = <></>;

    switch (listTab.id) {
      case 1: {
        icon = <BluetoothConnectedIcon fontSize="large" />;
        break;
      }
      case 2: {
        icon = <MailIcon fontSize="large" />;
        break;
      }
      case 3: {
        icon = <ViewColumnIcon fontSize="large" />;
        break;
      }
      case 4: {
        icon = <ListIcon fontSize="large" />;
        break;
      }
      default: {
        icon = <></>;
        break;
      }
    }
    return icon;
  };

  const handleTabClick = (listTab) => () => {
    if (!listTab?.id) {
      return;
    }
    if (setActiveTab(listTab?.id)) {
      setActiveTab(listTab.id);
    }
  };

  return (
    <Box className={classes.listViewTabWrapper}>
      <Grid container>
        <Grid item xs={9} className={classes.listViewTabContainerWrapper}>
          <Grid container>
            {listTabItems.map((listTab, index) => (
              <Grid item xs={3} key={`list-tab-items-${listTab.id}-${index}`}>
                <Box
                  className={`${classes.listViewTab} ${
                    activeTab === listTab.id ? 'active' : ''
                  }`}
                  key={`${listTab.id}-${index}`}
                  onClick={handleTabClick(listTab)}
                >
                  {getItemIcon(listTab)}
                  {activeTab === listTab.id && (
                    <Tooltip title={listTab.title} arrow>
                      <Typography variant="body1"> {listTab.title} </Typography>
                    </Tooltip>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.listViewTabAction}>
          <Box onClick={handleTabClick()} textAlign="center">
            <AddCircleIcon fontSize="small" />
            <Typography variant="h6">Action</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SucceededListView;
