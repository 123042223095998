import React from 'react';
import MuiRadio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

import useStyles from './style';

const RadioButton = (props) => {
  const classes = useStyles();
  const {
    radioGroupProps,
    radioProps,
    formControlProps,
    name = 'radio-position',
    value = '',
    radioData = [],
    handleChange = () => {},
  } = props;

  const handleRadioChange = (event) => {
    handleChange(event);
  };

  return (
    <MuiRadioGroup
      row
      className={classes.radioGroup}
      name={name}
      value={value}
      aria-label="position"
      onChange={handleRadioChange}
      {...radioGroupProps}
    >
      {radioData.map((rD, index) => (
        <MuiFormControlLabel
          key={rD.value + index}
          value={rD.value}
          className={classes.controlLabel}
          control={
            <MuiRadio
              classes={{
                checked: classes.radioChecked,
              }}
              {...radioProps}
            />
          }
          label={rD.title}
          {...formControlProps}
        />
      ))}
    </MuiRadioGroup>
  );
};

export default RadioButton;
