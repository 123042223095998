import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Button,
  FormGroup,
  Grid,
  Tooltip,
  Box,
  Typography,
  Menu,
  MenuItem,
  Fade,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { isArray } from "lodash";
import useStyles from "./CreateTemplateFormStyle";
import { english } from "../../utils/language";
import FormAutoCompleteSelect from "../../components/FormAutoCompleteSelect";
import { OutlinedButton } from "../../components/Button";
import Spinner from "../../layout/Spinner";
import FileUpload from "../../utils/Fileupload";
import TextEditor from "../../utils/Editor";
import UploadService from "../../utils/UploadService";
import { getUserTemplate } from "../../actions/template";
import api from "../../utils/api";
import Dialog from '../../components/Dialog';
import { addTemplateToEmailStep } from "../../actions/sequence";
import { fetchUsersTeams } from "../../actions/users";
import { toast } from "react-toastify";

const CreateTemplateForm = (props) => {
  const {
    templateId,
    closeDrawer,
    admin,
    page,
    getRecords,
    cloneTemplate,
  } = props;

  const classes = useStyles();

  const [formData, setFormData] = useState({
    uuid: uuidv4(),
  });
  const [templateFilters, setTemplateFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);
  const [teamToggle, setTeamToggle] = useState(false);
  const [userToggle, setUserToggle] = useState(false);
  const [showPersonalisedDropDown, setShowPersonalisedDropDown] = useState(
    false
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [createNewTemplate, setCreateNewTemplate] = useState(false);

  const users = useSelector((state) => state.users);
  const authUser = useSelector((state) => state.auth.user);
  const defaultVariables = useSelector((state) => state.auth.replacementVariables);

  const dispatch = useDispatch();

  // users options
  const usersData = users.users ? users.users : null;
  const usersOptions = (usersData || []).map((user) => ({
    value: user.company_user_id,
    label: user.name,
  }));

  // teams options
  const teamsData = users.teams ? users.teams : null;
  const teamsOptions = (teamsData || []).map((team) => ({
    value: team.id,
    label: team.name,
  }));

  const formRef = useRef();
  const inputRef = useRef();
  const [selectionStart, setSelectionStart] = React.useState();
  const updateSelectionStart = () =>
    setSelectionStart(inputRef.current.selectionStart);

  // update form state
  useEffect(() => {
    let formDataClone = { ...formData };
    if (users.user_id) {
      formDataClone = {
        ...formDataClone,
        shared_with_users: isArray(users.user_id)
          ? users.user_id.map((sTeam) => sTeam.value)
          : [users.user_id.value],
      };
    }
    if (users.team_id) {
      formDataClone = {
        ...formDataClone,
        shared_with_teams: isArray(users.team_id)
          ? users.team_id.map((sTeam) => sTeam.value)
          : [users.team_id.value],
      };
    }
    setFormData({
      ...formDataClone,
    });
  }, [users.user_id, users.team_id]);

  useEffect(() => {
    // did mount
    getTemplateFilters();
    dispatch(fetchUsersTeams());
  }, []);

  useEffect(() => {
    if (templateFilters?.ownerList?.length) {
      setAddEditTemplateData();
    }
  }, [templateFilters]);

  /**
   * Api call for getting template filter option
   */
  const getTemplateFilters = () => {
    api
      .get("/automation/template-filters")
      .then((res) => {
        if (res.status === 200) {
          setTemplateFilters(res.data);
        }
      })
      .catch(() => {});
  };

  /**
   * will hit post api to add or edit template in the db
   */
  const handleSubmit = (event, forceUpdate = false) => {
    if (!formData.content) {
      return;
    }
    if (disabledNextButton() === false) {
      setDisabledButton(true);

      let payload = {
        name: formData.name,
        content: formData.content,
        subject: formData.subject,
        access_type: formData.access_type,
        category_id: formData.category_id,
        owner: formData.owner,
        uuid: formData.uuid,
        template_type: formData.category_id,
        forceUpdate: forceUpdate,
        clone: cloneTemplate ? cloneTemplate : createNewTemplate ? true : null,
      };

      if (formData.access_type === 1) {
        payload = {
          ...payload,
          shared_with_users: usersOptions.filter((uO) =>
            formData.shared_with_users.includes(uO.value)
          ),
        };
      } else if (formData.access_type === 2) {
        payload = {
          ...payload,
          shared_with_teams: teamsOptions.filter((tO) =>
            formData.shared_with_teams.includes(tO.value)
          ),
        };
      }

      if (!cloneTemplate && templateId && !createNewTemplate) {
        api
          .put(`/automation/template/${templateId}`, {
            values: payload,
          })
          .then((response) => {
            setDisabledButton(false);
            if (response.status === 200) {
              closeDrawer("editTemplate");
              /* 'getRecords' in props
                ? props.getRecords(
                    undefined,
                    undefined,
                    props.admin ? props.admin : null,
                  )
                : null; */
              /* if (props.updateName) {
                props.updateName(formData.name);
              } */
              //return <Redirect to='/sequence/steps/' />;
            }
          })
          .catch((err) => {
            if(err?.response?.status === 403) {
              let msg = err?.response?.data?.msg;
              setDialogOpen(true);
              setDialogMsg(msg);
            } else if (err?.response?.status === 409) {
              let msg = err?.response?.data?.error;
              // closeDrawer("error", true, msg ? msg : null);
              toast.error(msg ? msg : "Something went wrong", {
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
              });
            } else {
              closeDrawer("error", false);
            }
            setDisabledButton(false);
          });
      } else {
        api
          .post("/automation/template", {
            values: payload,
          })
          .then((response) => {
            setDisabledButton(false);
            if (response.status === 201) {
              if (page === "step") {
                dispatch(getUserTemplate(1));
                props.toSelect &&
                  props.toSelect({
                    value: response.data.id,
                    label: response.data.name,
                  });
                props.setValue && props.setValue(response.data.id);
                toast.success('Email Template Added Successfully', {
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                });
                closeDrawer("addEmailTemplate");
              } else if (cloneTemplate) {
                if (props.sequenceId) {
                  // call API to add the template in Step as A/B template
                  const emails = [response.data.id];
                  const selectedTemplates = {
                    value: response.data.id,
                    label: response.data.name,
                  };
                  let steps = {
                    step_type: formData.template_type
                      ? formData.template_type
                      : 1,
                    step_event: props.step_event ? props.step_event : "email",
                    emails: emails,
                    selectedTemplates: selectedTemplates,
                  };
                  //Save step templates into database
                  dispatch(
                    addTemplateToEmailStep(
                      props.sequenceId,
                      props.step.id,
                      props.handleClose,
                      steps
                    )
                  );
                } else {
                  closeDrawer("cloneTemplate");
                }
              } else {
                closeDrawer("addTemplate");
              }
            }
          })
          .catch((err) => {
            if(err?.response?.status === 403) {
              let msg = err?.response?.data?.msg;
              setDialogOpen(true);
              setDialogMsg(msg);
            } else if (err?.response?.status === 409) {
              let msg = err?.response?.data?.error;
              // closeDrawer("error", true, msg ? msg : null);
              toast.error(msg ? msg : "Something went wrong", {
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
              });
            } else {
              closeDrawer("error", false);
            }
            setDisabledButton(false);
          });
      }
      setCreateNewTemplate(false);
    }
  };

  // handle the Dialog pop up close
  const handleDialogClose = () => {
    setDialogOpen(!dialogOpen);
    setDialogMsg('');
  };

  // handle dialog confirm button click
  const editSubmit = () => {
    setDialogOpen(!dialogOpen);
    setDialogMsg('');
    handleSubmit(null, true);
  }

  const createNewTemplateClick = () => {
    setCreateNewTemplate(true);
    UploadService.getFiles(
      formData.uuid,
      templateId ? templateId : null,
      true,
      null
    ).then((response) => {
      formRef.current.submit();
    });
  }

  /**
   * handle tinymce editor change event
   * @param name
   * @returns {function(...[*]=)}
   */
  const handleEditorChange = (name) => (value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /**
   * handle the input change
   */
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * function to set the initial data in the state
   * @returns {Promise<void>}
   */
  const setAddEditTemplateData = async () => {
    if (templateId) {
      setIsLoading(true);
      await api
        .get(`/automation/template/${templateId}`)
        .then((response) => {
          setFormData({
            ...formData,
            name: cloneTemplate
              ? `${response.data.name} (clone)`
              : response.data.name,
            subject: response.data.subject,
            content: response.data.content,
            access_type: response.data.access_type,
            category_id: response.data.template_type,
            shared_with_users:
              response.data.shared_with_users !== null
                ? isArray(response.data.shared_with_users)
                  ? response.data.shared_with_users.map((sTeam) => sTeam.value)
                  : response.data.shared_with_users.value
                : [response.data.user.value],
            shared_with_teams:
              response.data.shared_with_teams !== null
                ? isArray(response.data.shared_with_teams)
                  ? response.data.shared_with_teams.map((sTeam) => sTeam.value)
                  : response.data.shared_with_teams.value
                : [response.data.team.value],
            owner:
              response.data.owner_id !== null
                ? templateFilters?.ownerList?.find(
                    (c) => c.id === response.data.owner_id
                  ) || ""
                : "",
          });

          if (response.data.access_type == 1) {
            setUserToggle(true);
          } else if (response.data.access_type == 2) {
            setTeamToggle(true);
          }
          if (response.data.created_by === authUser.id) {
            setOwnerOfTemplate(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    setIsLoading(false);
  };

  /**
   * Function check required field is filled
   * @param {*} keysData
   * @returns
   */
  const validateData = (keysData) => {
    let isValid = false;
    keysData.forEach((e) => {
      if (!isValid) {
        isValid = !formData[e];
      }
    });
    return isValid;
  };

  /**
   * Function to set enable/disable button
   * @returns
   */
  const disabledNextButton = () => {
    const formKeys = ["name", "subject", "content"];

    if (validateData(formKeys)) {
      return true;
    } else {
      return disabledButton;
    }
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    if (name === "category_id") {
      return setFormData({
        ...formData,
        [name]: tag.id,
      });
    }

    if (name === "owner") {
      return setFormData({
        ...formData,
        [name]: tag,
      });
    }

    if (name === "shared_with_users" || name === "shared_with_teams") {
      return setFormData({
        ...formData,
        [name]: tag.map((e) => e[key]),
      });
    }
    let formDataClone = { ...formData };

    if (name === "access_type") {
      if (tag[key] === 1) {
        setUserToggle(true);
        setTeamToggle(false);
        formDataClone = {
          ...formDataClone,
          shared_with_users: isArray(users.user_id)
            ? users.user_id.map((sTeam) => sTeam.value)
            : [users.user_id.value],
          [name]: tag[key],
        };
        return setFormData({
          ...formDataClone,
        });
      } else if (tag[key] === 2) {
        setUserToggle(false);
        setTeamToggle(true);
        formDataClone = {
          ...formDataClone,
          shared_with_teams: isArray(users.team_id)
            ? users.team_id.map((sTeam) => sTeam.value)
            : [users.team_id.value],
          [name]: tag[key],
        };
        return setFormData({
          ...formDataClone,
        });
      } else {
        setUserToggle(false);
        setTeamToggle(false);
      }
    }
    setFormData({
      ...formData,
      [name]: tag[key],
    });
  };

  /**
   * Hanle personalized field change event
   */
  const handleOnChange = (name, tag) => {
    let cursorPosition = selectionStart || formData?.subject?.length || 0;

    let value = tag.value.substring(6, tag.value.length - 6);
    let selectionPosition = value.length;
    if (formData?.subject) {
      let textBeforeCursorPosition = formData?.subject?.substring(
        0,
        cursorPosition
      );
      selectionPosition += textBeforeCursorPosition?.length;
      let textAfterCursorPosition = formData?.subject?.substring(
        cursorPosition,
        formData?.subject?.length
      );
      value = textBeforeCursorPosition + value + textAfterCursorPosition;
    }

    const subjectText = `${value}`;
    setFormData({
      ...formData,
      subject: subjectText,
      option: tag,
    });
    handleClose();
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.selectionStart = selectionPosition;
      inputRef.current.selectionEnd = selectionPosition;
    }, 100);
  };

  /**
   * Handle Choosen close event
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handle Choosen open event on click
   */
  const handleBoxClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // show spinner
  if (isLoading) {
    return <Spinner style={{ width: 40, height: 40 }} />;
  }

  return (
    <div className={classes.root}>
      <ValidatorForm ref={(r) => (formRef.current = r)} onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          {/*<h6 className={classes.formHeading}> Email Template Details </h6>*/}
          <div>
            <Grid container spacing={3}>
              {/*Sequence Name && Sequence audience*/}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  onChange={handleChange}
                  label={`${english.emailTemplateForm.name} *`}
                  name="name"
                  value={formData?.name || ""}
                  variant="outlined"
                  fullWidth
                  validators={["required"]}
                  errorMessages={[english.emailTemplateForm.nameValidate]}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange("category_id", "id")}
                  name="category_id"
                  id="category_id"
                  key={"category_id"}
                  disableClearable
                  options={[...(templateFilters?.categoryList || [])]}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    formData?.category_id
                      ? templateFilters?.categoryList?.find(
                          (c) => c.id === formData?.category_id
                        ) || ""
                      : ""
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label={`Template Category *`}
                      className={classes.selectBoxStyle}
                      name="category_id"
                      value={formData?.category_id || ""}
                      variant="outlined"
                      fullWidth
                      validators={["required"]}
                      errorMessages={["Select Template Category"]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-3">
                <FormAutoCompleteSelect
                  onChange={handleSelectChange("owner", "id")}
                  name="owner"
                  id="owner"
                  key={"owner"}
                  disableClearable
                  options={[...(templateFilters?.ownerList || [])]}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    formData?.owner
                      ? templateFilters?.ownerList?.find(
                          (c) => c.id === formData?.owner?.id
                        ) || ""
                      : ""
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label={`Template Owner *`}
                      className={classes.selectBoxStyle}
                      name="owner_id"
                      value={formData?.owner || ""}
                      variant="outlined"
                      fullWidth
                      validators={["required"]}
                      errorMessages={["Select Template Owner"]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextValidator
                  label={`Template Subject *`}
                  onChange={handleChange}
                  name="subject"
                  value={formData?.subject || ""}
                  variant="outlined"
                  fullWidth
                  maxLength={"255"}
                  validators={["required", "maxStringLength:255"]}
                  errorMessages={[
                    english.emailTemplateForm.subjectValidate,
                    "Limit exceeded, Only 255 characters allowded",
                  ]}
                  inputRef={inputRef}
                  onSelect={updateSelectionStart}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                className={
                  showPersonalisedDropDown ? "" : classes.OutlineContainer
                }
              >
                <Tooltip title={"Click here to personalize the subject line."}>
                  <Button
                    onClick={handleBoxClick}
                    // dataTarget={`personalize`}
                    variant="outlined"
                    className={classes.outLineButton}
                  >
                    {`{} Personalize `}
                  </Button>
                </Tooltip>
                {/* <FormAutoCompleteSelect
                    onChange={handleOnChange('option')}
                    name='option'
                    id='option'
                    disableClearable
                    options={defaultVariables}
                    getOptionLabel={(option) => option?.text || ''}
                    value={formData.option}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label='Personalize *'
                        value={formData?.option || ''}
                        name='option'
                        variant='outlined'
                        fullWidth
                        // validators={['required']}
                        // errorMessages={['Please select at-least option']}
                      />
                    )}
                  /> */}
                <Menu
                  id={"sequence-menu-id"}
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  TransitionComponent={Fade}
                  classes={{
                    paper: classes.menuPaper,
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {defaultVariables.length ? (
                    <Box className={classes.menuItemWrapper}>
                      {defaultVariables.map((option, index) => (
                        <MenuItem
                          key={`option-menu-check-${index}`}
                          value={option["id"]}
                        >
                          <Box
                            onClick={() => handleOnChange("option", option)}
                            style={{ width: "100%" }}
                          >
                            {option["text"]}
                          </Box>
                        </MenuItem>
                      ))}
                    </Box>
                  ) : (
                    <MenuItem disabled>
                      <Typography>No options.</Typography>
                    </MenuItem>
                  )}
                </Menu>
              </Grid>

              {/*html content base */}
              <Grid item xs={12} sm={12}>
                {/* <div className={classes.multipleSelectHeading}>
                  {english.emailTemplateForm.content}
                </div> */}
                <FormGroup>
                  <TextEditor
                    onChange={handleEditorChange("content")}
                    initialvalue={formData.content}
                    unsubscribe={true}
                    toolBar="bold italic underline | placeholder | unsubscribe | fontselect fontsizeselect forecolor |
                              alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |
                              link image code  | backcolor removeformat"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormAutoCompleteSelect
                  onChange={handleSelectChange("access_type", "id")}
                  name="access_type"
                  id="access_type"
                  key={"access_type"}
                  disableClearable
                  options={[...(templateFilters?.accessLevelList || [])]}
                  getOptionLabel={(option) => option.name || ""}
                  getOptionSelected={(option, value) => option.value === value}
                  value={
                    formData.access_type
                      ? templateFilters?.accessLevelList?.find(
                          (c) => c.id === formData?.access_type
                        ) || ""
                      : ""
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label={`Access Type *`}
                      className={classes.selectBoxStyle}
                      name="access_type"
                      value={formData?.access_type || ""}
                      variant="outlined"
                      fullWidth
                      validators={["required"]}
                      errorMessages={["Select Access Type"]}
                    />
                  )}
                />
              </Grid>

              {userToggle && (
                <Grid item xs={12} sm={12}>
                  <div className={classes.multipleSelectHeading}>
                    {english.seqUser}
                  </div>
                  <FormAutoCompleteSelect
                    multiple
                    onChange={handleSelectChange("shared_with_users", "value")}
                    name="shared_with_users"
                    id="shared_with_users"
                    disableClearable
                    options={usersOptions}
                    getOptionLabel={(option) => option.label || ""}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      formData?.shared_with_users?.length
                        ? (usersOptions || []).filter((c) =>
                            formData?.shared_with_users.includes(c.value)
                          )
                        : []
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label=""
                        name="shared_with_users"
                        fullWidth
                        value={formData?.shared_with_users || ""}
                        variant="outlined"
                        validators={["required"]}
                        errorMessages={[english.seqUserReq]}
                      />
                    )}
                  />
                </Grid>
              )}

              {teamToggle && (
                <Grid item xs={12} sm={12}>
                  <div className={classes.multipleSelectHeading}>
                    {english.seqTeam}
                  </div>
                  <FormAutoCompleteSelect
                    multiple
                    onChange={handleSelectChange("shared_with_teams", "value")}
                    name="shared_with_teams"
                    id="shared_with_teams"
                    disableClearable
                    options={teamsOptions}
                    getOptionLabel={(option) => option.label || ""}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      formData?.shared_with_teams?.length
                        ? (teamsOptions || []).filter((c) =>
                            formData?.shared_with_teams.includes(c.value)
                          )
                        : []
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label=""
                        name="shared_with_teams"
                        fullWidth
                        value={formData?.shared_with_teams || ""}
                        variant="outlined"
                        validators={["required"]}
                        errorMessages={[english.seqTeamReq]}
                      />
                    )}
                  />
                </Grid>
              )}

              {/*file upload*/}
              <Grid item xs={12} sm={12}>
                <FileUpload
                  uuid={formData.uuid}
                  object_id={templateId ? templateId : null}
                  clone={cloneTemplate}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.formFooter}>
          <div>
            {templateId && !cloneTemplate &&<Button
              variant="contained"
              className={classes.createNewButton}
              onClick={createNewTemplateClick}
              disabled={disabledNextButton()}
            >
              {"Create New"}
            </Button>}
          </div>
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledNextButton()}
            >
              {templateId
                ? cloneTemplate
                  ? english.clone
                  : english.update
                : english.save}
            </Button>
          </div>
        </div>
      </ValidatorForm>
      <Dialog
          objectId={templateId}
          open={dialogOpen}
          title="Warning!"
          content={dialogMsg}
          handleClick={editSubmit}
          onClose={handleDialogClose}
          buttonText={`Update`}
        />
    </div>
  );
};

export default CreateTemplateForm;
