import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

import SucceededProfile from './SucceededProfile';
import SucceededDetailView from './SucceededDetailView';
import SucceededListView from './SucceededListView';

import api from '../utils/api';

const useStyles = makeStyles(({ custom }) => ({
  root: {
    backgroundColor: custom.colorCode.white,
    margin: -20,
    height: '100vh',
    overflow: 'auto',
  },
  rootContainer: {
    height: '100%',
  },
  centerView: {
    backgroundColor: custom.colorCode.lightGreyColorShade7,
  },
}));

const SucceededView = (props) => {
  const classes = useStyles();

  const [contactProfileData, setContactProfileData] = useState();
  const [profileType, setProfileType] = useState();
  const [profileId, setProfileId] = useState();
  const [profileDropDownData, setProfileDropDownData] = useState();

  useEffect(() => {
    if (props.match?.params?.type && props.match?.params?.id) {
      setProfileType(props.match?.params?.type);
      setProfileId(props.match?.params?.id);
      if (props.match?.params?.type === 'candidate') {
        api
          .get(`/candidate/get-profile-detail/${props.match?.params?.id}`)
          .then((resp) => {
            setContactProfileData(resp.data);
          });
      } else if (props.match?.params?.type === 'contact') {
        api.get(`/dm/get-dm-detail/${props.match?.params?.id}`).then((resp) => {
          setContactProfileData(resp.data);
        });
      }
    }
    getProfileDropValues();
    // if (!props.history.location.pathname.includes('recipient-profile')) {
    //   dispatch(openCloseProfileAlert());
    // }
    return () => {
      // dispatch(openCloseProfileAlert());
    };
  }, []);

  const getProfileDropValues = () => {
    api.get(`/candidate/get-profile-options`).then((resp) => {
      setProfileDropDownData({
        ...resp.data,
        jobroleList: resp.data.jobroleList.filter((e) => e.category_id),
      });
    });
  };

  return (
    <Box className={classes.root}>
      <Grid container className={classes.rootContainer}>
        <Grid item xs={12} sm={3}>
          <SucceededProfile
            userData={contactProfileData}
            profileType={profileType}
            profileId={profileId}
            profileDropDownData={profileDropDownData}
          />
        </Grid>

        <Grid item xs={12} sm={6} className={classes.centerView}>
          <SucceededDetailView
            profileType={profileType}
            profileId={profileId}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <SucceededListView profileType={profileType} profileId={profileId} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SucceededView;
