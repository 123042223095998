import React from "react";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import OnboardingView from "./OnboardingView";

export const OnboardingContainer = (props) => {
  const classes = useStyles();

  return <OnboardingView {...props} />;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingContainer);
