import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import { Menu, MenuItem, Typography, Tooltip } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import LoadingTableBody from "../../components/LoadingTableBody";
import CustomDataGrid from "../../controlled-component/customGridComponent";
import TaskTableToolbar from "../../controlled-component/customGridComponent/CustomToolbar/TableToolbar";
import { ActiveIcon, InActiveIcon } from "../../components/GridIcons";
import AddButton from "../../components/CreateToolbarButton";
import CreateTemplate from "../CreateTemplate/CreateTemplate";
import Dialog from "../../components/Dialog";
import {
  fetchTemplateGridData,
  fetchGridFilters,
  getUserTemplate,
  updateTemplateStatus,
} from "../../actions/template";
import { makeProperNamingStatement } from "../../utils";

// template styling
const useStyles = makeStyles(({ custom }) => ({
  templateGridMainSection: {},
  templateGridWrapper: {
    padding: "10px 0 10px 0",
  },
  gridCenter: {
    textAlign: "center",
    paddingRight: "30px",
  },
}));

/**
 * Template grid component
 */
const TemplateGrid = (props) => {
  const classes = useStyles();
  const [facetdata, setFacetdata] = useState({});
  const [facetfields, setFacetfields] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);

  const [popupToggle, setPopupToggle] = useState(false);
  const [templateId, setTemplateId] = useState();

  const [clonePopupToggle, setClonePopupToggle] = useState(false);
  const [editPopupToggle, setEditPopupToggle] = useState(false);
  const [reloadGrid, setReloadGrid] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const filterStateRef = useRef({});
  const gridParams = useRef({});
  const gridColParams = useRef({});
  const filterStateParams = useRef({});
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.template[props.tabProps.id]);
  const userData = useSelector((state) => state.auth.user);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    gridParams.current = templates.gridParams;
  }, [templates.gridParams]);

  useEffect(() => {
    filterStateParams.current = templates.filterState;
  }, [templates.filterState]);

  useEffect(() => {
    if (!isEmpty(templates.columns)) {
      gridColParams.current = templates.columns;
    }
  }, [templates.columns]);

  useEffect(() => {
    // to update the template option
    if (templates.templateDataList.length && templates.myFilterData.length) {
      setFacetdata({});
      setFacetfields(
        templates.myFilterData.map((e) => ({
          label: e.name,
          value: e.title,
        }))
      );
    }
    filterStateRef.current = templates.filterState;
  }, [templates]);

  useEffect(() => {
    // did mount
    dispatch(fetchGridFilters(props.tabProps));
  }, []);

  useEffect(() => {
    if (!isEmpty(templates.filterList) && userData?.id) {
      // call the fetch template api and set data in reducer
      dispatch(
        fetchTemplateGridData(
          undefined,
          templates.gridParams
            ? templates.gridParams
            : { pageNo: 0, perPage: 25, searchText: null },
          props.tabProps,
          undefined
        )
      );
    }
  }, [templates.filterList, userData]);

  useEffect(() => {
    if (reloadGrid) {
      dispatch(
        fetchTemplateGridData(
          undefined,
          templates.gridParams
            ? templates.gridParams
            : { pageNo: 0, perPage: 25, searchText: null },
          props.tabProps,
          undefined
        )
      );
      setReloadGrid(false);
    }
  }, [reloadGrid]);

  /**
   * Default columns of table
   */
  const columns = useMemo(
    () => [
      {
        name: "name",
        label: "Template Name",
        options: {
          filter: false,
          sort: true,
          draggable: false,
          setCellHeaderProps: () => ({
            style: { minWidth: 300, width: 300, maxWidth: 300 },
          }),
          setCellProps: () => ({
            style: { minWidth: 300, width: 300, maxWidth: 300 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = templates.templateDataList[dataIndex];
            return (
              <Box>
                <Tooltip title={rData.name || ""} arrow>
                  <div className={"td-content-ellipsis"}>
                    <Link
                      to={`/email/detail/${rData.id}`}
                      className="text-truncate td-anchor-content-ellipsis"
                    >
                      {rData.name}
                    </Link>{" "}
                    ({rData.id})
                  </div>
                </Tooltip>
              </Box>
            );
          },
        },
      },
      {
        label: "Subject",
        name: "subject",
        options: {
          filter: true,
          sort: true,
          draggable: false,
          setCellHeaderProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          setCellProps: () => ({
            style: { minWidth: 200, width: 200, maxWidth: 200 },
          }),
          customBodyRender: (value) => {
            return (
              <Box>
                <Tooltip title={value || ""} arrow>
                  <div className={`td-content-ellipsis`}>{value}</div>
                </Tooltip>
              </Box>
            );
          },
        },
      },
      {
        label: "Sequences",
        name: "sequence_count",
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: false,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: "Owner",
        name: "created_by_user",
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{makeProperNamingStatement(value || "")}</div>;
          },
        },
      },

      {
        label: "Delivered",
        name: "total_delivered",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: "Opened",
        name: "total_opened",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: "Clicked",
        name: "total_clicked",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || 0}</div>;
          },
        },
      },
      {
        label: "Bounces",
        name: "total_bounces",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: "Status",
        name: "is_published",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return value === "Un-Published" ? (
              <InActiveIcon fontSize="small" />
            ) : (
              <ActiveIcon fontSize="small" />
            );
          },
        },
      },
      {
        label: "Date Created",
        name: "created_at",
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: false,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        label: "Last Send Date",
        name: "last_sent_date",
        options: {
          filter: true,
          sort: true,
          draggable: true,
          display: false,
          setCellProps: () => ({ style: { minWidth: 30, maxWidth: 30 } }),
          customBodyRender: (value) => {
            return <div>{value || null}</div>;
          },
        },
      },
      {
        name: "id",
        label: " ",
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = templates.templateDataList.find(
              (tData) => tData.id === value
            );
            return (
              <GridActions
                temp_id={row?.id}
                status={row?.status}
                row={row}
                handleEditPopupOpen={handleEditPopupOpen}
                handleClonePopupOpen={handleClonePopupOpen}
                updateStatus={changeStatus}
                shareTemplate={handleShareTemplate}
                auth={auth}
                {...props}
              />
            );
          },
        },
      },
    ],
    [templates.templateDataList, templates.myFilterData]
  );

  /**
   * Refetch the template grid data using filters options
   */
  const handleReFetch = (filterObject) => {
    dispatch(
      fetchTemplateGridData(
        {
          ...(isEmpty(filterObject) ? {} : filterObject),
        },
        {
          ...gridParams.current,
          pageNo: 0,
          perPage: 25,
        },
        props.tabProps,
        gridColParams.current
      )
    );
  };

  /**
   * Custom loader in grid body
   */
  const BodyComponent = useMemo(
    () => (props) =>
      <LoadingTableBody loading={templates.loading} {...props} />,
    [templates.loading]
  );

  /**
   * Tool Bar for grid
   */
  const TableToolbar = useMemo(
    () => (props) => {
      return (
        <TaskTableToolbar
          refetch={handleReFetch}
          myFilterData={templates.myFilterData}
          defaultFilterState={templates.defaultFilterState}
          filterState={templates.filterState}
          visibleFilter={2}
          filterDrawerName={"Filters"}
          showEditCol
          onColumnChange={handleColumnChange()}
          loading={templates.loading}
          defaultUnSetFilters={'assigned_to'}
          {...props}
        />
      );
    },
    [templates.myFilterData, templates.defaultFilterState]
  );

  /**
   * Handle page change
   */
  const handlePerPageChange = (params) => {
    // dispatch(
    //   fetchSequenceCandidateData(
    //     {
    //       ...myTask.filterState,
    //       perPage: params.rowsPerPage,
    //     },
    //     undefined,
    //     props.tabProps,
    //   ),
    // );
  };

  /**
   * Handle column change
   */
  const handleColumnChange = (showLoader) => (paramColumns) => {
    dispatch(
      fetchTemplateGridData(
        {
          ...filterStateParams.current,
        },
        gridParams.current,
        props.tabProps,
        paramColumns,
        showLoader
      )
    );
  };

  /**
   * Handle table change event
   */
  const handleTableChange = (params) => {
    dispatch(
      fetchTemplateGridData(
        {
          ...templates.filterState,
        },
        params,
        props.tabProps,
        gridColParams.current
      )
    );
  };

  // handle function to open add template popup
  const handlePopupOpen = () => {
    setTemplateId(false);
    setPopupToggle(!popupToggle);
  };

  // handle function to close add template popup
  const handlePopupClose = (data) => {
    if (data && data !== "error") {
      setReloadGrid(true);
    }
    setPopupToggle(false);
    setTemplateId(false);
    setEditPopupToggle(false);
    setClonePopupToggle(false);
  };

  // handle the edit template pop up
  const handleEditPopupOpen = (id, templateRow) => {
    setTemplateId(id);
    if (props.admin) {
      setEditPopupToggle(!editPopupToggle);
    } else if (userData?.id == templateRow?.owner_id) {
      setEditPopupToggle(!editPopupToggle);
    } else {
      handleDialogOpen();
    }
    /* setEditPopupToggle(!editPopupToggle); */
    // setPopupToggle(!popupToggle);
  };

  // handle the Dialog pop up open
  const handleDialogOpen = () => {
    setDialogOpen(!dialogOpen);
  };

  // handle the Dialog pop up close
  const handleDialogClose = () => {
    setDialogOpen(!dialogOpen);
    setTemplateId(false);
  };

  // handle dialog confirm button click
  const editOrCloneTemplate = (templateId) => {
    // setTemplateId(templateId);
    setClonePopupToggle(!clonePopupToggle);
    setDialogOpen(!dialogOpen);
  };

  // handle the clone template pop up
  const handleClonePopupOpen = (id, templateRow) => {
    setTemplateId(id);
    setClonePopupToggle(!clonePopupToggle);
  };

  // update status with reducer
  const changeStatus = (newTemplateId, statusText) => {
    const status = statusText === "Un-Published" ? 1 : 2;

    props.updateTemplateStatus(
      newTemplateId,
      status,
      "grid",
      props.admin ? props.admin : null,
      props.tabProps
    );
  };

  // make copy and share of the selected template
  const handleShareTemplate = (newTemplateId, templateRow) => {
    // TODO: check it
    // setTemplateId(newTemplateId);
    // setClonePopupToggle(!clonePopupToggle);
  };

  return (
    <div className={classes.templateGridMainSection}>
      <div className={classes.templateGridWrapper}>
        {/*Custom Grid*/}
        <CustomDataGrid
          columns={columns.map((col, index) => ({
            ...col,
            options: templates?.columns?.columns?.length
              ? { ...col.options, ...templates?.columns?.columns[index] }
              : col.options,
          }))}
          data={templates.templateDataList}
          onTableRowPerPageChange={handlePerPageChange}
          onTableChange={handleTableChange}
          onColumnChange={handleColumnChange(false)}
          paperHeight={"295px"}
          options={{
            columnOrder: templates?.columns?.columnsOrder,
            pagination: Boolean(templates?.count),
            page: templates?.gridParams?.pageNo || 0,
            rowsPerPage: templates?.gridParams?.perPage || 25,
            searchText: templates?.gridParams?.searchText,
            selectableRows: "multiple",
            serverSide: true,
            filterType: "dropdown",
            responsive: "simple",
            searchPlaceholder: "Search User",
            filterOptions: facetfields,
            facetdata: facetdata,
            gridParams: gridParams,
            searchOpen: true,
            search: true,
            filter: true,
            download: true,
            viewColumns: true,
            count: templates?.count,
            selectToolbarPlacement: "replace",
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
              setRowsSelected(allRows.map((row) => row.dataIndex));
            },
            sortOrder: templates?.gridParams?.sortOrder
              ? templates?.gridParams?.sortOrder
              : undefined,
            customToolbar: () => {
              // Toolbar for search and add
              return (
                <AddButton
                  handleClick={handlePopupOpen}
                  title={`Create Template`}
                  dataTarget={`createTemplate`}
                />
              );
            },
          }}
          components={{
            TableBody: BodyComponent,
            TableToolbar: TableToolbar,
          }}
        />
        <ToastContainer autoClose={2000} />
      </div>
      {/*Create/edit Template*/}
      <CreateTemplate
        openDrawer={popupToggle || editPopupToggle || clonePopupToggle}
        onClose={handlePopupClose}
        admin={props.admin ? props.admin : false}
        page={"grid"}
        getRecords={props.getUserTemplate}
        templateId={templateId}
        cloneTemplate={clonePopupToggle}
      />
      <Dialog
        objectId={templateId}
        open={dialogOpen}
        title="Edit or Clone Template Confirmation"
        content="Template could be edited by owner or Admin only. You can clone it."
        handleClick={editOrCloneTemplate}
        onClose={handleDialogClose}
        buttonText={`Clone`}
      />
    </div>
  );
};

// custom grid action menu
const GridActions = (props) => {
  const { auth, row } = props;
  const { user } = auth;
  const admin = props.admin ? props.admin : false;
  const [anchorElement, setAnchorElement] = useState(null);

  const editable = user.id === row.created_by;

  const menuItem =
    editable || admin
      ? [
          {
            title: "Edit",
            itemType: "editPopUp",
          },
          {
            title: "Change Status",
            itemType: "changeStatus",
          },
          {
            title: "Clone",
            itemType: "cloneTemplate",
          },
          // {
          //   title: 'Share',
          //   itemType: 'shareTemplate',
          // },
        ]
      : [];

  //const editable = user.id === row.created_by;
  const menuId = "action-menu-id";

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);

    switch (type) {
      case "editPopUp": {
        props.handleEditPopupOpen(props.temp_id, props.row);
        break;
      }
      case "changeStatus": {
        props.updateStatus(props.temp_id, props.row.is_published);
        break;
      }
      case "shareTemplate": {
        props.shareTemplate(props.temp_id, props.row);
        break;
      }
      case "cloneTemplate": {
        props.handleClonePopupOpen(props.temp_id, props.row);
        break;
      }
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Typography
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleActionsMenuOpen}
      >
        <MoreVertIcon style={{ fill: "#5780ab" }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem
            key={mItem.itemType + index}
            onClick={handleMenuClose(mItem.itemType)}
          >
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getUserTemplate,
  updateTemplateStatus,
};

export default connect(null, mapDispatchToProps)(TemplateGrid);
