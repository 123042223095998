import React, { useState } from "react";

import CustomDrawer from "../../components/CustomDrawer";
import DrawerFormHeader from "../../components/DrawerFormHeader";
import CustomSnackBar from "../../components/CustomSnackBar";
import CreateTemplateForm from "./CreateTemplateForm";
import { english } from "../../utils/language";

// create and edit email template slider wrapper component
const CreateTemplate = (props) => {
  const { openDrawer, onClose, templateId, cloneTemplate } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState(null);

  /**
   * Handle function to close the drawer popup
   * @param {*} data
   */
  const handleClose = (data, closeDrawer = true, errorMsg = null) => {
    if (data && data === "addTemplate") {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: english.createTemplateSuccessMsg,
        status: "success",
      });
    }
    if (data && data === "editTemplate") {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: english.editTemplateSuccessMsg,
        status: "success",
      });
    }

    if (data && data === "cloneTemplate") {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: english.cloneTemplateSuccessMsg,
        status: "success",
      });
    }

    if (data && data === "error") {
      setOpenSnackBar(true);
      setOpenSnackBarMessage({
        message: errorMsg ? errorMsg :english.commonErrorMsg,
        status: "error",
      });
    }

    if (closeDrawer) {
      onClose(data);
    }
  };

  /**
   * Handle function to close the toaster popup
   */
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
    setOpenSnackBarMessage(null);
  };

  return (
    <div>
      <CustomDrawer anchor={"right"} open={openDrawer} onClose={onClose}>
        <DrawerFormHeader
          handleCloseClick={handleClose}
          titleText={
            templateId
              ? props.cloneTemplate
                ? english.emailTemplateCloneFormHeading
                : english.emailTemplateEditFormHeading
              : english.emailTemplateAddFormHeading
          }
        >
          <CreateTemplateForm
            openDrawer={openSnackBar}
            closeDrawer={handleClose}
            cloneTemplate={cloneTemplate}
            templateId={templateId}
            admin={props.admin ? props.admin : false}
            page={props.page}
            getRecords={props.getRecords}
            setValue={props.setValue}
            toSelect={props.toSelect}
            sequenceId={props.sequenceId}
            step={props.step}
          />
        </DrawerFormHeader>
      </CustomDrawer>

      <CustomSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        barMessage={openSnackBarMessage?.message || ""}
        severity={openSnackBarMessage?.status || "success"}
      />
    </div>
  );
};

export default CreateTemplate;
