import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PageHeader from '../../components/PageHeader';
import ActivityReportGrid from './ActivityReportGrid';
import Spinner from '../../layout/Spinner';
import { fetchTabs } from '../../actions/admin/activityReport';

const useStyles = makeStyles(() => ({
  mainHeader: {
    '& .heading-title': {
      marginBottom: 0,
    },
  },
}));

const ActivityReportTabs = (props) => {
  const classes = useStyles();
  const activityReportCount = useSelector(
    (state) => state.activityReports.count
  );
  const activityReportTab = useSelector(
    (state) => state.activityReports.reportTab
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setTabs();
  }, []);

  const setTabs = async () => {
    await dispatch(fetchTabs());
  };

  if (activityReportTab.loading) {
    return <Spinner />;
  }

  return (
    <div>
      <PageHeader
        mainTitle={'Activity Report'}
        subTitle={null}
        showCount={false}
        itemCount={activityReportCount}
        className={classes.mainHeader}
      />
      {activityReportTab?.tabs && (
        <ActivityReportGrid {...props} tabProps={activityReportTab.tabs[0]} />
      )}
    </div>
  );
};

export default ActivityReportTabs;
