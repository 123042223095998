import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { isArray } from 'lodash';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import Spinner from '../../../layout/Spinner';
import FormAutoCompleteSelect, {
  AutoCompleteRenderInfoOption,
} from '../../../components/FormAutoCompleteSelect';
import RadioButton from '../../../components/RadioButton';
import SimpleSwitch from '../../../components/SimpleSwitch';

import api from '../../../utils/api';
import { updateSequence } from '../../../actions/sequence';
import { fetchUsersTeams } from '../../../actions/users';
import { english } from '../../../utils/language';
import {
  audienceTypeOption,
  sequenceAccessOption,
} from '../../../utils/applicationConstant';

import styles from './style';
import ErrorIcon from '@material-ui/icons/Error';

const enrollmentSettingsOption = [
  {
    value: 1, // allow multiple enrollments
    title: 'Multiple enrollments',
  },
  {
    value: 2, // not allowed multiple enrollments
    title: 'Single enrollment',
  },
];

// Edit sequence tags will update the sequence data
const EditSequence = (props) => {
  const classes = styles();

  const [formData, setFormData] = useState({});
  const [seqData, setSeqData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [teamToggle, setTeamToggle] = useState(false);
  const [userToggle, setUserToggle] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [settingRadio, setSettingRadio] = useState('globalSetting');

  const formRef = useRef();

  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  // users options
  const usersData = users.users ? users.users : null;
  const usersOptions = (usersData || []).map((user) => ({
    value: user.company_user_id,
    label: user.name,
  }));

  // teams options
  const teamsData = users.teams ? users.teams : null;
  const teamsOptions = (teamsData || []).map((team) => ({
    value: team.id,
    label: team.name,
  }));

  useEffect(() => {
    // did mount
    setEditSequenceData();
  }, []);

  /**
   * this will call get edit api and set data in form
   * @returns {Promise<void>}
   */
  const setEditSequenceData = async () => {
    if (props.seq_id) {
      await api
        .get(`/automation/sequence/get/${props.seq_id}`)
        .then((response) => {
          let enrollment_settings =
            response.data.customSettings?.enrollment_rule !== null
              ? response.data.customSettings
                ? parseInt(response.data.customSettings.enrollment_rule)
                : parseInt(
                    response.data.globalSettings.Enrollment.enrollment_rule,
                  )
              : parseInt(
                  response.data.globalSettings.Enrollment.enrollment_rule,
                );

          setSettingRadio(
            response.data.customSettings?.status === 1
              ? 'customSetting'
              : 'globalSetting',
          );

          const settingPayload =
            response.data?.customSettings?.status === 1
              ? {
                  numberEmails: response.data?.customSettings?.max_emails,
                  winner_email_percent:
                    response.data?.customSettings?.winner_email_percent,
                  variance_percent:
                    response.data?.customSettings?.variance_percent,
                }
              : {
                  numberEmails:
                    response.data?.globalSettings?.ABTemplate?.max_emails,
                  winner_email_percent:
                    response.data?.globalSettings?.ABTemplate
                      ?.winner_email_percent,
                  variance_percent:
                    response.data?.globalSettings?.ABTemplate?.variance_percent,
                };

          setFormData({
            ...formData,
            name: response.data.name,
            audience_type: response.data.audience_type,
            access_type: response.data.access_type,
            description: response.data.description,
            shared_with_users:
              response.data.shared_with_users !== null
                ? isArray(response.data.shared_with_users)
                  ? response.data.shared_with_users.map((sTeam) => sTeam.value)
                  : response.data.shared_with_users.value
                : [response.data.user.value],
            shared_with_teams:
              response.data.shared_with_teams !== null
                ? isArray(response.data.shared_with_teams)
                  ? response.data.shared_with_teams.map((sTeam) => sTeam.value)
                  : response.data.shared_with_teams.value
                : [response.data.team.value],
            ...settingPayload,
            enrollment_settings: enrollment_settings,
            enableOpenTracking:
              response.data?.sequenceEmailTrackingSettings?.track_open !== null
                ? response.data?.sequenceEmailTrackingSettings?.track_open
                : true,
            enableClickTracking:
              response.data?.sequenceEmailTrackingSettings?.track_click !== null
                ? response.data?.sequenceEmailTrackingSettings?.track_click
                : true,
          });

          setSeqData(response.data);
          if (response.data.access_type === 1) {
            setUserToggle(true);
          } else if (response.data.access_type === 2) {
            setTeamToggle(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    await dispatch(fetchUsersTeams());
    setIsLoading(false);
  };

  /**
   * will hit edit sequence api and will save data
   * @returns {Promise<void>}
   */
  const handleSubmit = async () => {
    setDisabledButton(true);

    let payload = {
      access_type: formData.access_type,
      description: formData.description,
      name: formData.name,
      settings: settingRadio === 'customSetting' ? 'custom' : 'global',
      enrollment_rule: formData.enrollment_settings,
      emailTracking: {
        track_click: formData.enableClickTracking,
        track_open: formData.enableOpenTracking,
      },
    };

    if (formData.access_type === 1) {
      payload = {
        ...payload,
        shared_with_users: usersOptions.filter((uO) =>
          formData.shared_with_users.includes(uO.value),
        ),
      };
      setUserToggle(true);
      setTeamToggle(false);
    } else if (formData.access_type === 2) {
      payload = {
        ...payload,
        shared_with_teams: teamsOptions.filter((tO) =>
          formData.shared_with_teams.includes(tO.value),
        ),
      };
    }

    if (settingRadio === 'customSetting') {
      const settingPayload = {
        max_emails: formData.numberEmails,
        winner_email_percent: formData.winner_email_percent,
        variance_percent: formData.variance_percent,
      };

      payload = {
        ...payload,
        ...settingPayload,
      };
    }

    await dispatch(
      updateSequence(
        props.seq_id,
        payload,
        props.history,
        () => {},
        props.page,
      ),
    );
    setDisabledButton(false);
    setEditSequenceData();
  };

  /**
   * will handle input box and will update data
   * @param event
   */
  const handleChange = (event) => {
    if (event.target.name === 'enableOpenTracking') {
      setFormData({
        ...formData,
        [event.target.name]: !formData.enableOpenTracking,
      });
    } else if (event.target.name === 'enableClickTracking') {
      setFormData({
        ...formData,
        [event.target.name]: !formData.enableClickTracking,
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  /**
   * handle the radio button value
   * @param event
   */
  const handleRadioChange = (event) => {
    setSettingRadio(event.target.value);
    let settingPayload = {};
    if (event.target.value === 'globalSetting') {
      settingPayload = {
        numberEmails: seqData.globalSettings.ABTemplate.max_emails,
        winner_email_percent:
          seqData.globalSettings.ABTemplate.winner_email_percent,
        variance_percent: seqData.globalSettings.ABTemplate.variance_percent,
      };
    }
    if (event.target.value === 'customSetting') {
      settingPayload = {
        numberEmails: seqData.customSettings?.max_emails || '',
        winner_email_percent:
          seqData.customSettings?.winner_email_percent || '',
        variance_percent: seqData.customSettings?.variance_percent || '',
      };
    }
    setFormData({
      ...formData,
      ...settingPayload,
    });
  };

  /**
   * function to handle the select element change event
   * @param {*} name
   * @returns
   */
  const handleSelectChange = (name, key) => (event, tag) => {
    if (name === 'shared_with_users' || name === 'shared_with_teams') {
      return setFormData({
        ...formData,
        [name]: tag.map((e) => e[key]),
      });
    }
    if (name === 'access_type') {
      if (tag[key] === 1) {
        setUserToggle(true);
        setTeamToggle(false);
      } else if (tag[key] === 2) {
        setUserToggle(false);
        setTeamToggle(true);
      } else {
        setUserToggle(false);
        setTeamToggle(false);
      }
    }

    setFormData({
      ...formData,
      [name]: tag[key],
    });
  };

  // show spinner
  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner style={{ width: 40, height: 40, position: 'unset' }} />
      </div>
    );
  }

  return (
    <div className={classes.mainFormValidator}>
      <ValidatorForm ref={(r) => (formRef.current = r)} onSubmit={handleSubmit}>
        <div className={classes.formWrapper}>
          <Grid container spacing={3}>
            {/*Sequence Name and Sequence Audience*/}
            <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
              <Typography variant="h6">Basic Info</Typography>
            </Grid>

            <Grid item xs={12} sm={6} className="pr-3">
              <TextValidator
                label={`${english.sequenceName} *`}
                onChange={handleChange}
                name="name"
                variant="outlined"
                value={formData?.name || ''}
                fullWidth
                validators={['required']}
                errorMessages={[english.sequenceNameReq]}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="pl-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('audience_type', 'value')}
                name="audience_type"
                id="audience_type"
                key={'audience_type'}
                disableClearable
                options={audienceTypeOption}
                disabled
                getOptionLabel={(option) => option.title || ''}
                getOptionSelected={(option, value) => option.value === value}
                value={
                  formData.audience_type
                    ? audienceTypeOption?.find(
                        (c) => c.value === formData?.audience_type,
                      ) || ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label={`${english.seqAudience} *`}
                    className={classes.selectBoxStyle}
                    name="audience_type"
                    value={formData?.audience_type || ''}
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={[english.seqAudienceReq]}
                  />
                )}
              />
            </Grid>
            {/*Sequence Description and Sequence access*/}
            <Grid item xs={12} sm={12}>
              <TextValidator
                label={`${english.seqDesc} *`}
                onChange={handleChange}
                name="description"
                type="description"
                value={formData?.description || ''}
                variant="outlined"
                fullWidth
                multiline
                rowsMax={4}
                validators={['required']}
                errorMessages={[english.seqDescReq]}
              />
            </Grid>
            {/* access type and enrollment settings */}
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">Permission</Typography>
            </Grid>
            <Grid item xs={12} sm={6} className="pr-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('access_type', 'value')}
                name="access_type"
                id="access_type"
                key={'access_type'}
                disableClearable
                options={sequenceAccessOption}
                getOptionLabel={(option) => option.title || ''}
                getOptionSelected={(option, value) => option.value === value}
                value={
                  formData.access_type
                    ? sequenceAccessOption?.find(
                        (c) => c.value === formData?.access_type,
                      ) || ''
                    : ''
                }
                renderOption={({ title, helperText }) => {
                  return (
                    <AutoCompleteRenderInfoOption
                      title={title}
                      helperText={helperText}
                    />
                  );
                }}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label={`${english.seqAccess} *`}
                    className={classes.selectBoxStyle}
                    name="access_type"
                    value={formData?.access_type || ''}
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={[english.seqAccessReq]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="pl-3">
              <FormAutoCompleteSelect
                onChange={handleSelectChange('enrollment_settings', 'value')}
                name="enrollment_settings"
                id="enrollment_settings"
                key={'enrollment_settings'}
                disableClearable
                options={enrollmentSettingsOption}
                // disabled
                getOptionLabel={(option) => option.title || ''}
                getOptionSelected={(option, value) => option.value === value}
                value={
                  formData.enrollment_settings
                    ? enrollmentSettingsOption?.find(
                        (c) => c.value === formData?.enrollment_settings,
                      ) || ''
                    : ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label={`${english.seqEnrollmentSettings} *`}
                    className={classes.selectBoxStyle}
                    name="enrollment_settings"
                    value={formData?.enrollment_settings || ''}
                    variant="outlined"
                    fullWidth
                    validators={['required']}
                    errorMessages={[english.seqEnrollmentSettingsReq]}
                  />
                )}
              />
            </Grid>
            {userToggle && (
              <Grid item xs={12} sm={12}>
                <div className={classes.multipleSelectHeading}>
                  {english.seqUser}
                </div>
                <FormAutoCompleteSelect
                  multiple
                  onChange={handleSelectChange('shared_with_users', 'value')}
                  name="shared_with_users"
                  id="shared_with_users"
                  disableClearable
                  options={usersOptions}
                  getOptionLabel={(option) => option.label || ''}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  value={
                    formData?.shared_with_users?.length
                      ? (usersOptions || []).filter((c) =>
                          formData?.shared_with_users.includes(c.value),
                        )
                      : []
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label=""
                      name="shared_with_users"
                      fullWidth
                      value={
                        formData?.shared_with_users?.length
                          ? formData?.shared_with_users
                          : ''
                      }
                      variant="outlined"
                      validators={['required']}
                      errorMessages={[english.seqUserReq]}
                    />
                  )}
                />
              </Grid>
            )}
            {teamToggle && (
              <Grid item xs={12} sm={12}>
                <div className={classes.multipleSelectHeading}>
                  {english.seqTeam}
                </div>
                <FormAutoCompleteSelect
                  multiple
                  onChange={handleSelectChange('shared_with_teams', 'value')}
                  name="shared_with_teams"
                  id="shared_with_teams"
                  disableClearable
                  options={teamsOptions}
                  getOptionLabel={(option) => option.label || ''}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  value={
                    formData?.shared_with_teams?.length
                      ? (teamsOptions || []).filter((c) =>
                          formData?.shared_with_teams.includes(c.value),
                        )
                      : []
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label=""
                      name="shared_with_teams"
                      fullWidth
                      value={
                        formData?.shared_with_teams?.length
                          ? formData?.shared_with_teams
                          : ''
                      }
                      variant="outlined"
                      validators={['required']}
                      errorMessages={[english.seqTeamReq]}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">A/B Testing</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <RadioButton
                name="sequence-setting"
                value={settingRadio}
                handleChange={handleRadioChange}
                radioData={[
                  {
                    title: 'Global Setting',
                    value: 'globalSetting',
                  },
                  {
                    title: 'Custom Setting',
                    value: 'customSetting',
                  },
                ]}
              />
            </Grid>
            {/*max no email and winning percentage*/}
            <Grid item xs={12} sm={6} className="pr-3">
              <TextValidator
                label={`${english.seqNumberEmail} *`}
                onChange={handleChange}
                name="numberEmails"
                value={formData?.numberEmails || ''}
                variant="outlined"
                fullWidth
                disabled={settingRadio === 'globalSetting'}
                validators={['required']}
                errorMessages={[english.seqNumberEmailReq]}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="pl-3">
              <TextValidator
                label={`${english.seqWinPer} *`}
                onChange={handleChange}
                name="winner_email_percent"
                value={formData?.winner_email_percent || ''}
                variant="outlined"
                fullWidth
                disabled={settingRadio === 'globalSetting'}
                validators={['required']}
                errorMessages={[english.seqWinPerReq]}
              />
            </Grid>
            {/*variance percentage to keep*/}
            <Grid item xs={12} sm={6} className="pr-3">
              <TextValidator
                label={`${english.seqVarPer}  *`}
                onChange={handleChange}
                name="variance_percent"
                value={formData?.variance_percent || ''}
                variant="outlined"
                fullWidth
                disabled={settingRadio === 'globalSetting'}
                validators={['required']}
                errorMessages={[english.seqVarPerReq]}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">Tracking</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box display="flex" className={classes.mainSwitchWrapper}>
                <SimpleSwitch
                  checked={formData.enableOpenTracking}
                  handleChange={handleChange}
                  rightLabelText={`${
                    formData.enableOpenTracking
                      ? 'Disable open tracking'
                      : 'Enable open tracking'
                  }`}
                  name={'enableOpenTracking'}
                />
                <Box display="flex" className={classes.warningMessage}>
                  <ErrorIcon />
                  <span>
                    Disable email opens tracking by removing the invisible pixel
                    from your emails. This could improve the deliverability rate
                    of your emails and make them look more personal as your will
                    contain plain HTML without any additional technologies.
                  </span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box display="flex" className={classes.mainSwitchWrapper}>
                <SimpleSwitch
                  checked={formData.enableClickTracking}
                  handleChange={handleChange}
                  rightLabelText={`${
                    formData.enableClickTracking
                      ? 'Disable click tracking'
                      : 'Enable click tracking'
                  }`}
                  name={'enableClickTracking'}
                />
                <Box display="flex" className={classes.warningMessage}>
                  <ErrorIcon />
                  <span>
                    If you enable link tracking, all links inside of your emails
                    will be wrapped in an intermediate Reply's tracking link.
                    Please be aware that enabling link tracking might decrease
                    your email deliverability rate.
                  </span>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>

        <div className={classes.formFooter}>
          <div>
            <Button
              variant="contained"
              className={classes.nextButton}
              type="submit"
              disabled={disabledButton}
            >
              {english.update}
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default EditSequence;
