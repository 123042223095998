import React, { Component } from 'react';
//import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  MovableCardWrapper,
  CardHeader,
  CardRightContent,
  CardTitle,
  Detail,
  Footer,
} from 'react-trello/dist/styles/Base';
//import InlineInput from 'react-trello/dist/widgets/InlineInput';
import Tag from './Card/Tag';
import DeleteButton from 'react-trello/dist/widgets/DeleteButton';

class Card extends Component {
  onDelete = (e) => {
    this.props.onDelete();
    e.stopPropagation();
  };

  render() {
    const {
      showDeleteButton,
      style,
      tagStyle,
      onClick,
      //onDelete,
      onChange,
      className,
      id,
      title,
      label,
      //description,
      tags,
      cardDraggable,
      editable,
      //t,
    } = this.props;

    /* const updateCard = (card) => {
      onChange({ ...card, id });
    }; */

    /* const textMarkup = (val) => {
      return { __html: val };
    }; */

    const contact_type = this.props.contact_type == 1 ? `DM` : `Candidate`;
    const url =
      this.props.contact_type == 1
        ? `/edmgr/dm/detail/dmId/${btoa(this.props.contact_id)}`
        : `/edmgr/candidate/candidate-profile/candidate_id/${btoa(
          this.props.contact_id,
        )}`;
    const base_company_id =
      this.props.contact_type == 1
        ? this.props.dm_company_id
        : this.props.cand_company_id
          ? this.props.cand_company_id
          : null;
    const name = this.props.dm_name ? this.props.dm_name : this.props.cand_name;
    const job_title = this.props.dm_job_title
      ? this.props.dm_job_title
      : this.props.cand_job_title;
    const account_name = this.props.dm_account_name
      ? this.props.dm_account_name
      : this.props.cand_account_name;

    return (
      <MovableCardWrapper
        data-id={id}
        onClick={onClick}
        style={style}
        className={className}
      >
        <CardHeader>
          <CardTitle draggable={cardDraggable}>
            <a href={this.props.domain + url} target="_blank" rel="noreferrer">
              {name ? name : title}
            </a>
          </CardTitle>
          <CardRightContent> {editable ? label : label} </CardRightContent>
          {showDeleteButton && <DeleteButton onClick={this.onDelete} />}
        </CardHeader>
        <Detail>
          <div>{contact_type}</div>
          <div>{job_title}</div>
          <div>
            <a
              href={
                base_company_id
                  ? this.props.domain +
                    '/edmgr/account/account-detail/id/' +
                    btoa(base_company_id)
                  : '#'
              }
              target="_blank"
              rel="noreferrer"
            >
              {account_name}
            </a>
          </div>
        </Detail>
        {tags && tags.length > 0 && (
          <Footer>
            {tags.map((tag) => (
              <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
            ))}
          </Footer>
        )}
      </MovableCardWrapper>
    );
  }
}

Card.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
};

Card.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  tagStyle: {},
  title: 'no title',
  description: '',
  label: '',
  tags: [],
  className: '',
};

export default Card;
